import {useEffect, useState} from 'react';

export const useCalculateReceived = (typeorder: string, watch: any, lineItemId: number) => {
    const [receivedSum, setReceivedSum] = useState(0);
    const [receivedDifferenceNumber, setReceivedDifferenceNumber] = useState(0);
    const [receivedDifference, setReceivedDifference] = useState('');
    const [receivedText, setReceivedText] = useState('');

    useEffect(() => {
        const lineItem = watch(`lineItems[${lineItemId}]`);
        const lineItemQuantity = lineItem.remaining;

        if (typeorder !== 'CORE') {
            const lineItemBatches = lineItem.batches || [];
            const sumReceived = lineItemBatches.reduce(
                (acc: number, batch: {received?: number}) => {
                    return acc + (Number(batch.received) || 0);
                },
                0
            );

            setReceivedSum(Math.min(sumReceived, lineItemQuantity));
            setReceivedDifferenceNumber(Math.abs(lineItemQuantity - sumReceived));
            setReceivedDifference(
                sumReceived > lineItemQuantity
                    ? `+${sumReceived - lineItemQuantity}`
                    : `-${lineItemQuantity - sumReceived}`
            );
            setReceivedText(
                sumReceived > lineItemQuantity ? 'Cantidad excedente' : 'Cantidad rechazadas'
            );
        } else {
            const received = lineItem.received || 0;

            setReceivedSum(Math.min(received, lineItemQuantity));
            setReceivedDifferenceNumber(Math.abs(lineItemQuantity - received));
            setReceivedDifference(
                received > lineItemQuantity
                    ? `+${received - lineItemQuantity}`
                    : `-${lineItemQuantity - received}`
            );
            setReceivedText(
                received > lineItemQuantity ? 'Cantidad excedente' : 'Cantidad rechazadas'
            );
        }
    }, [typeorder, watch, lineItemId]);

    return {receivedSum, receivedDifferenceNumber, receivedDifference, receivedText};
};

export const useCalculateReceivedGenesis = (item: any) => {
    const [receivedSum, setReceivedSum] = useState(0);
    const [receivedDifferenceNumber, setReceivedDifferenceNumber] = useState(0);
    const [receivedDifference, setReceivedDifference] = useState('');
    const [receivedText, setReceivedText] = useState('');

    useEffect(() => {
        const lineItemQuantity = item.remaining;

        if (item.isOpenBatch) {
            const lineItemBatches = item.batches || [];
            const sumReceived = lineItemBatches.reduce(
                (acc: number, batch: {received?: number}) => {
                    return acc + (Number(batch.received) || 0);
                },
                0
            );

            setReceivedSum(Math.min(sumReceived, lineItemQuantity));
            setReceivedDifferenceNumber(Math.abs(lineItemQuantity - sumReceived));
            setReceivedDifference(
                sumReceived > lineItemQuantity
                    ? `+${sumReceived - lineItemQuantity}`
                    : `-${lineItemQuantity - sumReceived}`
            );
            setReceivedText(
                sumReceived > lineItemQuantity ? 'Cantidad excedente' : 'Cantidad rechazadas'
            );
        } else {
            const received = Number(item.received) || 0;

            setReceivedSum(Math.min(received, lineItemQuantity));
            setReceivedDifferenceNumber(Math.abs(lineItemQuantity - received));
            setReceivedDifference(
                received > lineItemQuantity
                    ? `+${received - lineItemQuantity}`
                    : `-${lineItemQuantity - received}`
            );
            setReceivedText(
                received > lineItemQuantity ? 'Cantidad excedente' : 'Cantidad rechazadas'
            );
        }
    }, [item]);

    return {receivedSum, receivedDifferenceNumber, receivedDifference, receivedText};
};
