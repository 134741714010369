import {
    GET_CSFABI,
    GET_CSFABI_SUCCESS,
    GET_CSFABI_ERROR,
    GET_CSFABI_BYID,
    GET_CSFABI_BYID_SUCCESS,
    GET_CSFABI_BYID_ERROR,
    UPDATE_CSFABI,
    UPDATE_CSFABI_SUCCESS,
    UPDATE_CSFABI_ERROR,
    CREATE_FILE_CSFABI,
    CREATE_FILE_CSFABI_SUCCESS,
    CREATE_FILE_CSFABI_ERROR,
    POST_CSFABI,
    POST_CSFABI_SUCCESS,
    POST_CSFABI_ERROR,
    GET_CSFABI_DOWNLOAD,
    GET_CSFABI_DOWNLOAD_SUCCESS,
    GET_CSFABI_DOWNLOAD_ERROR,
    GET_DOWNLOAD_LISTCSF,
    GET_DOWNLOAD_LISTCSF_SUCCESS,
    GET_DOWNLOAD_LISTCSF_ERROR,
    PATCH_FILE_CSF,
    PATCH_FILE_CSF_SUCCESS,
    PATCH_FILE_CSF_ERROR,
    PUT_CSFABI,
    PUT_CSFABI_SUCCESS,
    PUT_CSFABI_ERROR,
} from '@components/csf/csfABI/redux/types/CsfABITypes';

const initialState = {
    csfAbi: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
    fileData: {},
    errorFile: false,
};

const csfAbi = (state = initialState, action) => {
    switch (action.type) {
        case GET_CSFABI:
            return {
                ...state,
                loading: true,
            };
        case GET_CSFABI_SUCCESS:
            return {
                ...state,
                csfAbi: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_CSFABI_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_CSFABI_BYID:
            return {
                ...state,
                loading: true,
            };
        case GET_CSFABI_BYID_SUCCESS:
            return {
                ...state,
                csfAbi: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
            };
        case GET_CSFABI_BYID_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_CSFABI:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CSFABI_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_CSFABI_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CREATE_FILE_CSFABI:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FILE_CSFABI_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CREATE_FILE_CSFABI_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_CSFABI:
            return {
                ...state,
                loading: true,
            };
        case POST_CSFABI_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_CSFABI_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_CSFABI_DOWNLOAD:
            return {
                ...state,
                loading: true,
            };
        case GET_CSFABI_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_CSFABI_DOWNLOAD_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_DOWNLOAD_LISTCSF:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_LISTCSF_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_DOWNLOAD_LISTCSF_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case PATCH_FILE_CSF:
            return {
                ...state,
                loading: true,
            };
        case PATCH_FILE_CSF_SUCCESS:
            return {
                ...state,
                loading: false,
                fileData: action.value,
            };
        case PATCH_FILE_CSF_ERROR:
            return {
                ...state,
                errorFile: action.value,
                loading: false,
            };
        case PUT_CSFABI:
            return {
                ...state,
                loading: true,
            };
        case PUT_CSFABI_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_CSFABI_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default csfAbi;
