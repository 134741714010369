export const GET_BINNACLES = 'GET_BINNACLES';
export const GET_BINNACLES_SUCCESS = 'GET_BINNACLES_SUCCESS';
export const GET_BINNACLES_ERROR = 'GET_BINNACLES_ERROR';
export const GET_BINNACLES_BYID = 'GET_BINNACLES_BYID';
export const GET_BINNACLES_BYID_SUCCESS = 'GET_BINNACLES_BYID_SUCCESS';
export const GET_BINNACLES_BYID_ERROR = 'GET_BINNACLES_BYID_ERROR';
export const GET_BINNACLES_DETAILS = 'GET_BINNACLES_DETAILS';
export const GET_DOWNLOAD_BINNACLES = 'GET_DOWNLOAD_BINNACLES';
export const GET_DOWNLOAD_BINNACLES_SUCCESS = 'GET_DOWNLOAD_BINNACLES_SUCCESS';
export const GET_DOWNLOAD_BINNACLES_ERROR = 'GET_DOWNLOAD_BINNACLES_ERROR';
export const GET_DOWNLOAD_BINNACLES_BYID = 'GET_DOWNLOAD_BINNACLES_BYID';
export const GET_DOWNLOAD_BINNACLES_BYID_SUCCESS = 'GET_DOWNLOAD_BINNACLES_BYID_SUCCESS';
export const GET_DOWNLOAD_BINNACLES_BYID_ERROR = 'GET_DOWNLOAD_BINNACLES_BYID_ERROR';

export const GET_TERMS_CONDITIONS = 'GET_TERMS_CONDITIONS';
export const GET_TERMS_CONDITIONS_SUCCESS = 'GET_TERMS_CONDITIONS_SUCCESS';
export const GET_TERMS_CONDITIONS_ERROR = 'GET_TERMS_CONDITIONS_ERROR';
export const GET_DOWNLOAD_TERMS_CONDITIONS = 'GET_DOWNLOAD_TERMS_CONDITIONS';
export const GET_DOWNLOAD_TERMS_CONDITIONS_SUCCESS = 'GET_DOWNLOAD_TERMS_CONDITIONS_SUCCESS';
export const GET_DOWNLOAD_TERMS_CONDITIONS_ERROR = 'GET_DOWNLOAD_TERMS_CONDITIONS_ERROR';
export const GET_TERMS_CONDITIONS_DETAILS_INFO = 'GET_TERMS_CONDITIONS_DETAILS_INFO';
export const HIDE_TERMS_CONDITIONS_DETAILS_INFO = 'HIDE_TERMS_CONDITIONS_DETAILS_INFO';

export const GET_TERMS_CONDITIONS_DETAILS = 'GET_TERMS_CONDITIONS_DETAILS';
export const GET_TERMS_CONDITIONS_DETAILS_SUCCESS = 'GET_TERMS_CONDITIONS_DETAILS_SUCCESS';
export const GET_TERMS_CONDITIONS_DETAILS_ERROR = 'GET_TERMS_CONDITIONS_DETAILS_ERROR';
export const GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS = 'GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS';
export const GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_SUCCESS =
    'GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_SUCCESS';
export const GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_ERROR =
    'GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_ERROR';
export const SET_CURRENT_INFO_NOTIFICATION = 'SET_CURRENT_INFO_NOTIFICATION';
