'use client';
import React, {useState} from 'react';

import {GenericModal} from '@/components/UI/atoms/modals/GenericModal';

import {DetailsFilesXmlAgents} from '@/components/orderSimply/components/sctions-uplpad-expend-account/DetailsFilesXmlAgents';
import {UploadFilesAgent} from '@/components/orderSimply/components/sctions-uplpad-expend-account/UploadFilesAgent';
import {ValidateXmlInvoicesAgents} from '@/components/orderSimply/components/sctions-uplpad-expend-account/ValidateXmlInvoicesAgents';
import {resetFormUploadFilesAgentsError} from '@/components/orderSimply/redux/actions/OrderAgentsActions';
import {AppDispatch, RootState} from '@/config/store';
import {Divider, Step, StepLabel, Stepper} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

export type ModalUploadInvoiceAgentsProps = {
    isModalOpen: boolean;
    closeModal: () => void;
};

const ModalUploadInvoiceAgents: React.FC<ModalUploadInvoiceAgentsProps> = ({
    isModalOpen,
    closeModal,
}) => {
    const {t} = useTranslation();
    const [happyPath, setHappyPath] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const activeStep = useSelector((state: RootState) => state.orderssimply.stepOrderAgents);

    const Steps = {
        0: t('uploadComplement.upload_files'),
        1: t('uploadComplement.validate_xml'),
        2: t('view_details'),
    };
    const stepSection = () => {
        switch (activeStep) {
            case 0:
                return <UploadFilesAgent closeModal={closeModalUploadInvoiceAgents} />;
            case 1:
                return (
                    <ValidateXmlInvoicesAgents
                        closeModal={closeModalUploadInvoiceAgents}
                        happyPath={happyPath}
                    />
                );
            case 2:
            case 3:
                return <DetailsFilesXmlAgents closeModal={closeModalUploadInvoiceAgents} />;
            default:
                return <></>;
        }
    };

    const closeModalUploadInvoiceAgents = () => {
        closeModal();
        dispatch(resetFormUploadFilesAgentsError());
        setHappyPath(false);
    };

    return (
        <GenericModal
            isModalOpen={isModalOpen}
            closeModal={closeModalUploadInvoiceAgents}
            title="Upload XML Invoice Agents"
        >
            <Stepper
                activeStep={activeStep}
                sx={{
                    marginTop: '20px',
                    marginBottom: '10px',
                }}
                alternativeLabel
            >
                {Object.entries(Steps).map(([key, value]) => (
                    <Step key={key} completed={activeStep > Number(key)}>
                        <StepLabel>{`${value}`}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Divider />

            {stepSection()}
        </GenericModal>
    );
};

export default ModalUploadInvoiceAgents;
