import React, {useEffect, useState} from 'react';
import {XmlFileHeader} from '@components/orderSimply/modals/components/XmlFileHeader';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Skeleton,
} from '@mui/material';
import {TypographyStyled600} from '@components/faqs/StyledFAQ';
import {CheckCircleOutline, ErrorOutlineOutlined} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {getValidationError} from '@components/orderSimply/constants/complement-errors';
import {useTranslation} from 'react-i18next';

export const ValidateComplement = () => {
    const {t} = useTranslation();
    const loading = useSelector((state) => state.complement.loadingValidations);
    const errorValidations = useSelector((state) => state.complement.errorValidations);

    const [validations, setValidations] = useState([]);
    const [totalErrors, setTotalErrors] = useState(0);

    const parseErrors = () => {
        if (errorValidations && errorValidations.length > 0) {
            const newValidations = [];
            errorValidations.forEach((e) => {
                const error = getValidationError(e);
                const index = newValidations.findIndex((nv) => nv.header === error.header);
                if (index !== -1) {
                    newValidations[index].items.push(error.message);
                } else {
                    newValidations.push({
                        header: error.header,
                        items: [error.message],
                    });
                }
            });
            setTotalErrors(errorValidations.length);
            setValidations(newValidations);
        }
    };

    useEffect(parseErrors, [errorValidations]);

    return (
        <Box sx={{}}>
            <XmlFileHeader />
            <TypographyStyled600>{t('uploadComplement.validation_summary')}</TypographyStyled600>
            <List
                sx={{
                    border: '2px solid #c5d1d8',
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    '& ul': {padding: 0},
                    margin: '10px 0px',
                    padding: '0px',
                }}
                subheader={<li />}
                dense
            >
                {loading && (
                    <li key={`section-`}>
                        <ul>
                            <ListSubheader
                                sx={{
                                    borderTop: '1px solid #c5d1d8',
                                    borderBottom: '1px solid #c5d1d8',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '48px',
                                        padding: '16px 0px',
                                    }}
                                >
                                    <Skeleton
                                        variant="body1"
                                        sx={{fontSize: '1rem', width: '200px'}}
                                    />
                                </Box>
                            </ListSubheader>
                            {[0, 1].map((item) => (
                                <ListItem key={`item-0-${item}`}>
                                    <ListItemIcon>
                                        <Skeleton variant="circular">
                                            <Box sx={{width: '24px', height: '24px'}} />
                                        </Skeleton>
                                    </ListItemIcon>
                                    <Skeleton
                                        variant="text"
                                        sx={{fontSize: '1rem', width: '300px'}}
                                    />
                                </ListItem>
                            ))}
                        </ul>
                    </li>
                )}
                {!loading &&
                    validations.length > 0 &&
                    validations.map(({header, items}) => (
                        <li key={`section-${header}`}>
                            <ul>
                                <ListSubheader
                                    sx={{
                                        borderTop: '1px solid #c5d1d8',
                                        borderBottom: '1px solid #c5d1d8',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {t(header)}
                                        <Box sx={{flex: '1 1 auto'}} />
                                        {`${items.length} ${t('errors')}`}
                                    </Box>
                                </ListSubheader>
                                {items.map((item) => (
                                    <ListItem key={`item-${header}-${item}`}>
                                        <ListItemIcon>
                                            <ErrorOutlineOutlined color="error" />
                                        </ListItemIcon>
                                        <ListItemText primary={`${t(item)}`} />
                                    </ListItem>
                                ))}
                            </ul>
                        </li>
                    ))}
                {!loading && validations.length === 0 && (
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutline color="success" />
                        </ListItemIcon>
                        <ListItemText primary={t('uploadComplement.complement_without_errors')} />
                    </ListItem>
                )}
            </List>
            {totalErrors !== 0 && (
                <TypographyStyled600>{`${t(
                    'uploadComplement.total_errors'
                )}: ${totalErrors}`}</TypographyStyled600>
            )}
        </Box>
    );
};
