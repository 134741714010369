import {
    GET_MANUALS,
    GET_MANUALS_SUCCESS,
    GET_MANUALS_ERROR,
    POST_MANUALS,
    POST_MANUALS_SUCCESS,
    POST_MANUALS_ERROR,
    UPDATE_MANUALS,
    UPDATE_MANUALS_SUCCESS,
    UPDATE_MANUALS_ERROR,
    DELETE_MANUALS,
    DELETE_MANUALS_SUCCESS,
    DELETE_MANUALS_ERROR,
    CREATE_FILE_MANUALS,
    CREATE_FILE_MANUALS_SUCCESS,
    CREATE_FILE_MANUALS_ERROR,
    OPEN_MODAL_PREVIEW,
    CLOSE_MODAL_PREVIEW,
    GET_MANUALS_BYID_SUCCESS,
} from '@/components/manuals/redux/types/ManualsTypes';

const initialState = {
    manuals: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    showModalPreview: false,
    mediaType: '',
    mediaSrc: '',
    mediaAlt: '',
    limit: 10,
    file: null,
};

const manuals = (state = initialState, action) => {
    switch (action.type) {
        case GET_MANUALS:
            return {
                ...state,
                loading: true,
            };
        case GET_MANUALS_SUCCESS:
            return {
                ...state,
                manuals: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_MANUALS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_MANUALS:
            return {
                ...state,
                loading: true,
            };
        case POST_MANUALS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_MANUALS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_MANUALS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_MANUALS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_MANUALS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case DELETE_MANUALS:
            return {
                ...state,
                loading: true,
            };
        case DELETE_MANUALS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_MANUALS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CREATE_FILE_MANUALS:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FILE_MANUALS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CREATE_FILE_MANUALS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case OPEN_MODAL_PREVIEW:
            return {
                ...state,
                showModalPreview: true,
                mediaType: action.Mediatype,
                mediaSrc: action.src,
                mediaAlt: action.alt,
            };
        case CLOSE_MODAL_PREVIEW:
            return {
                ...state,
                showModalPreview: false,
                mediaType: '',
                mediaSrc: '',
                mediaAlt: '',
            };
        case GET_MANUALS_BYID_SUCCESS:
            return {
                ...state,
                file: action.value,
            };
        default:
            return state;
    }
};

export default manuals;
