import {styled} from '@mui/material/styles';

export const ColorButton = styled('button')(({theme}) => ({
    backgroundColor: '#E5B611',
    borderRadius: '4px',
    color: 'black',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: '40px',
    height: '40px',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
}));

export const ColorButtonMobile = styled('button')({
    backgroundColor: '#E5B611',
    borderRadius: '10px',
    color: '#FFF',
    fontSize: '38px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginTop: '70px',
    height: '120px',
    width: '100%',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
});

export const ColorButtonModal = styled('button')({
    backgroundColor: '#E5B611',
    borderRadius: '10px',
    color: '#FFF',
    fontSize: '25px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginTop: '25px',
    height: '60px',
    width: '100%',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
});

export const AddButton = styled('button')(({theme}) => ({
    width: '151px',
    backgroundColor: '#00ACFF',
    borderRadius: '5px',
    color: '#F1F1F1',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    margin: ' 0',
    height: '35px',
    border: 'none',
    alignSelf: 'end',
    '&:hover': {
        cursor: 'pointer',
    },
}));

export const SaveButton = styled('button')(({theme}) => ({
    width: '151px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    color: '#F1F1F1',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    height: '35px',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
    '&:disabled': {
        cursor: 'none',
        opacity: 0.5,
    },
}));

export const OrderButton = styled('button')({
    width: '100%',
    backgroundColor: '#D1A33C',
    borderRadius: '5px',
    color: '#F8FAFC',
    fontSize: '38px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    margin: '60px 0',
    height: '100px',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
});

export const BackButton = styled('button')({
    width: '100%',
    backgroundColor: '#F0F1F4',
    borderRadius: '5px',
    color: '#CACFD5',
    fontSize: '38px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    margin: '60px 0',
    height: '100px',
    border: '1px solid #CACFD5',
    '&:hover': {
        cursor: 'pointer',
    },
});
