import React, {useEffect} from 'react';
import {fetchGetLegalAgreements} from '@components/legalAgreements/redux/actions/LegalAgreementsActions';
import {useDispatch, useSelector} from 'react-redux';

const ViewPDF = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.logins.accessToken);
    const legalAgreements = useSelector((state) => state.legalAgreements.legalAgreements);

    useEffect(() => {
        if (token) {
            dispatch(fetchGetLegalAgreements(token));
        }
    }, [token]);

    return (
        <div
            style={{
                position: 'relative',
                width: '95%',
                minHeight: '100%',
                margin: '20px',
                overflow: 'auto',
            }}
        >
            <object
                data={legalAgreements?.urlAttachment}
                type="application/pdf"
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default ViewPDF;
