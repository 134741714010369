import {Nav, SaveButton, StyledModal} from '@/components/faqs/StyledFAQ';
import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import {ImgIcon, ButtonCancel} from '@components/faqs/StyledFAQ';
import AlertDelete from '@components/UI/atoms/icons/AlertDelete.svg';

const ModalConfirmChanges = ({open, closeCancel, setModalCancel}) => {
    const theme = useTheme();
    return (
        <StyledModal open={open} onClose={closeCancel}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    height: '501px',
                    zIndex: '100',
                }}
            >
                <Nav />
                <Box
                    sx={{
                        display: 'flex',
                        padding: '15px',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '24px',
                            fontWeight: 400,
                            marginTop: '30px',
                            alignSelf: 'center',
                        }}
                    >
                        ¿Está seguro que desea cancelar la edición?
                    </Typography>
                    <ImgIcon src={AlertDelete} />
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '16px',
                            fontWeight: 500,
                            marginTop: '30px',
                            alignSelf: 'center',
                        }}
                    >
                        No se han guardado los cambios. Se perderá la información ingresada
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        marginTop: '95px',
                        justifyContent: 'flex-end',
                        background: '#FFFFF',
                    }}
                >
                    <ButtonCancel
                        onClick={() => setModalCancel((prev) => !prev)}
                        sx={{marginRight: '30px'}}
                    >
                        CANCELAR
                    </ButtonCancel>
                    <SaveButton sx={{marginRight: '20px'}} onClick={closeCancel}>
                        ACEPTAR
                    </SaveButton>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default ModalConfirmChanges;
