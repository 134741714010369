'use client';
import {RootState} from '@/config/store';
import React from 'react';
import {useSelector} from 'react-redux';

import ContentAccepted from '@/components/UI/templates/components/ContentAceppted';

import Footer from '@/components/UI/templates/components/Footer';
import Header from '@/components/UI/templates/components/Header';

export type AcceptedTermcConditionsPOProps = {
    // types...
};

const AcceptedTermcConditionsPO: React.FC<AcceptedTermcConditionsPOProps> = ({}) => {
    const data = useSelector((state: RootState) => state.binnacles.viewCurrenNofitication);

    return (
        <div style={{backgroundColor: '#f1f1f1'}}>
            <table align="center" style={{width: '100%', backgroundColor: '#f1f1f1'}}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{margin: '0 auto', maxWidth: '100%'}}>
                                <table align="center" style={{width: '100%'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign: 'center', padding: 0}}>
                                                <Header />
                                                <ContentAccepted data={data} />
                                                <Footer />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AcceptedTermcConditionsPO;
