import React from 'react';
import {SxProps, Typography, useTheme} from '@mui/material';
import {Nav} from '@/components/faqs/StyledFAQ';

interface ModalHeaderProps {
    title: string;
    navSx?: SxProps;
}

export default function ModalHeader({title, navSx}: ModalHeaderProps) {
    const theme = useTheme();
    return (
        <Nav sx={{...navSx}}>
            <Typography
                sx={{
                    fontSize: '18px',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 700,
                    color: 'white',
                    marginLeft: '10px',
                }}
            >
                {title}
            </Typography>
        </Nav>
    );
}
