import {Accordion, AccordionSummary, Box, Skeleton} from '@mui/material';
import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BillingStatementLoader: React.FC = () => {
    const items = [{id: 1}, {id: 2}, {id: 3}];

    return (
        <>
            {items.map((item) => (
                <Accordion key={item.id} expanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                padding: '15px',
                                background: '#fff',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '150px',
                                    marginLeft: '30px',
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{fontSize: '1rem', width: '300px', marginLeft: '30px'}}
                            />
                        </Box>
                    </AccordionSummary>
                </Accordion>
            ))}
        </>
    );
};

export default BillingStatementLoader;
