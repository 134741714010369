import {Box, Checkbox, DialogContent, FormControlLabel, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {SuccessToast} from '@/components/auth/componenents/toasts';
import {StyledModal} from '@/components/faqs/StyledFAQ';
import {ModalActions, ModalHeader, RejectTermsModal} from '@/components/terms/modals';
import {patchAcceptTerms} from '@/components/orderSimply/redux/actions/OrdersSimplyActions';
import {RootState} from '@/config/store';
import {customScrollbar} from '@/components/UI/helpers/custom-scrollbar';

interface TermsModalProps {
    isOpen: boolean;
    termsData: {
        externalIds: string[];
        purchaseOrderIds: string[];
    };
    onClose: () => void;
    onFulfill?: () => void;
    afterAction?: () => void;
    afterAcceptMessage?: string;
    afterRejectMessage?: string;
}
interface IHandleAction {
    purchaseOrderIds: string[];
    accepted: boolean;
    runTask: () => void;
    toastMessage: string;
}

export default function TermsModal(data: TermsModalProps) {
    const {
        isOpen,
        termsData,
        onClose,
        onFulfill,
        afterAction,
        afterAcceptMessage,
        afterRejectMessage,
    } = data;

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const token = useSelector((state: RootState) => state.logins.accessToken);
    const {loading} = useSelector((state: RootState) => state.orderssimply);

    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [isRejectTermsModalOpen, setIsRejectTermsModalOpen] = useState(false);
    const [externalIds, setExternalIds] = useState<string[]>([]);
    const [purchaseOrderIds, setPurchaseOrderIds] = useState<string[]>([]);

    useEffect(() => {
        setExternalIds(termsData.externalIds);
        setPurchaseOrderIds(termsData.purchaseOrderIds);
    }, [termsData]);

    const handleCancelButtonClick = () => {
        setIsRejectTermsModalOpen(true);
    };

    const handleButtonClick = async ({
        purchaseOrderIds,
        accepted,
        runTask: runAfterAcceptanceTask,
        toastMessage,
    }: IHandleAction) => {
        // eslint-disable-next-line no-useless-catch
        try {
            await handleAcceptTermsRequest(purchaseOrderIds, accepted);
            SuccessToast({title: toastMessage});
            runAfterAcceptanceTask();
            setIsTermsAccepted(false);
            onClose();
        } catch (err) {
            throw err;
        }
    };

    const handleAcceptTermsRequest = async (purchaseOrderIds: string[], accepted: boolean) => {
        const errorMessage = t(
            `confirmposimply.${accepted ? 'terms_modal' : 'reject_terms_modal'}.toast_error_msg`
        );
        await dispatch<any>(patchAcceptTerms({token, accepted, purchaseOrderIds, errorMessage}));
    };

    const handleAcceptButtonClick = (purchaseOrderIds: string[]) =>
        handleButtonClick({
            purchaseOrderIds,
            accepted: true,
            runTask: () => {
                if (onFulfill) onFulfill();
                onClose();
            },
            toastMessage:
                afterAcceptMessage ?? t('confirmposimply.terms_modal.toast_confirmation_msg'),
        });

    const handleRejectButtonClick = () => {
        handleButtonClick({
            purchaseOrderIds,
            accepted: false,
            runTask: () => {
                handleAfterAction();
            },
            toastMessage:
                afterRejectMessage ??
                t('confirmposimply.reject_terms_modal.toast_confirmation_msg'),
        });
    };

    const handleAfterAction = () => {
        onClose();
        if (afterAction) afterAction();
    };

    if (!isOpen) return null;

    return (
        <Box>
            <StyledModal
                open={isOpen}
                onClose={() => preventCloseModal()}
                disableEscapeKeyDown={true}
                disableEnforceFocus={true}
            >
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '3px',
                        width: '665px',
                        minHeight: '370px',
                        zIndex: '100',
                    }}
                >
                    <ModalHeader title={t('confirmposimply.terms_modal.title')} />
                    <ModalContent
                        externalIds={externalIds}
                        isTermsAccepted={isTermsAccepted}
                        setIsTermsAccepted={setIsTermsAccepted}
                    />
                    <ModalActions
                        acceptButtonText={t(
                            'confirmposimply.terms_modal.buttons.accept'
                        ).toUpperCase()}
                        cancelButtonText={t(
                            'confirmposimply.terms_modal.buttons.reject'
                        ).toUpperCase()}
                        enabledAcceptButton={isTermsAccepted && !loading}
                        onAccept={() => handleAcceptButtonClick(purchaseOrderIds)}
                        onCancel={() => handleCancelButtonClick()}
                        loading={loading}
                    />
                </Box>
            </StyledModal>

            {isRejectTermsModalOpen && (
                <RejectTermsModal
                    isOpen={isRejectTermsModalOpen}
                    externalIds={externalIds}
                    onClose={() => setIsRejectTermsModalOpen(false)}
                    onReject={handleRejectButtonClick}
                    handleCloseModal={preventCloseModal}
                    loading={loading}
                />
            )}
        </Box>
    );
}

const preventCloseModal = (event?: {preventDefault: () => void}, reason?: string) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
        event?.preventDefault();
    }
};

const ModalContent = ({
    externalIds,
    isTermsAccepted,
    setIsTermsAccepted,
}: {
    externalIds: string[];
    isTermsAccepted: boolean;
    setIsTermsAccepted: (value: boolean) => void;
}) => {
    const {t} = useTranslation();

    const openPopup = (url: string, externalIds: string[] = []) => {
        const validUrl = new URL(url, window.location.origin);
        validUrl.searchParams.append('ids', externalIds.join(','));
        window.open(validUrl.toString(), '_blank', 'noopener,noreferrer');
    };

    const handleOpenTerms = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        externalIds: string[]
    ) => {
        event.preventDefault();
        openPopup('/terms', externalIds);
    };

    if (!externalIds) return null;

    return (
        <DialogContent sx={{flexGrow: 1}}>
            <Box sx={{marginBottom: '15px'}}>
                <Typography component="span">
                    {`${t(`confirmposimply.terms_modal.subtitle.part_1`)} `}
                    <Typography
                        component="a"
                        href="#"
                        onClick={(e) => {
                            handleOpenTerms(e, externalIds);
                        }}
                        rel="noopener noreferrer"
                    >
                        {t(`confirmposimply.terms_modal.subtitle.part_2`)}
                    </Typography>
                    {` ${t(`confirmposimply.terms_modal.subtitle.part_3`)} ${t(
                        `confirmposimply.terms_modal.${
                            externalIds.length > 1
                                ? 'subtitle.part_4_plural'
                                : 'subtitle.part_4_singular'
                        }`
                    )} ${externalIds.length > 1 ? '' : externalIds}`}
                </Typography>
            </Box>
            {externalIds.length > 1 && (
                <Box
                    sx={{
                        maxHeight: '62px',
                        overflowY: 'auto',
                        marginBottom: '15px',
                        display: 'block',
                        ...customScrollbar,
                    }}
                >
                    <Box component="ul" sx={{margin: 0}}>
                        {externalIds.map((id, index) => (
                            <li key={`${index}-${id}`}>
                                <Typography>{id}</Typography>
                            </li>
                        ))}
                    </Box>
                </Box>
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isTermsAccepted}
                        onChange={(e) => setIsTermsAccepted(e.target.checked)}
                    />
                }
                label={
                    <span>
                        <a
                            href="#"
                            onClick={(e) => {
                                handleOpenTerms(e, externalIds);
                            }}
                            rel="noopener noreferrer"
                        >
                            {t('confirmposimply.terms_modal.accept_label')}
                        </a>
                    </span>
                }
            />
        </DialogContent>
    );
};
