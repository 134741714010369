export default class StringUtils {
    static jsonToQueryString(obj) {
        let pairs = [];
        for (let key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (key && obj[key] !== '') {
                    pairs.push(key + '=' + obj[key]);
                }
            }
        }

        if (pairs.length) {
            return '?' + pairs.join('&');
        }
        return '';
    }
}
