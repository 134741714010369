import {Box, Skeleton, Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import {styled} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {TypographyStyled600} from '@components/faqs/StyledFAQ';
import {useDispatch, useSelector} from 'react-redux';
import {formatMoney} from '@/utils/utils';
import {ErrorOutlineRounded} from '@mui/icons-material';
import {setBillsErrorFlag} from '@components/orderSimply/redux/actions/LoadComplementActions';
import {XmlFileHeader} from '@components/orderSimply/modals/components/XmlFileHeader';
import {useTranslation} from 'react-i18next';
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#EBF0F2',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

export const ConfirmComplememntBills = () => {
    const bills = useSelector((state) => state.complement.bills);
    const errorBillsFlag = useSelector((state) => state.complement.errorBillsFlag);
    const [notFound, setNotFound] = useState(0);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const columns = [
        {id: 'status', label: '', minWidth: 10},
        {id: 'uuid', label: `${t('uploadComplement.bill')}`, minWidth: 350},
        {
            id: 'purchaseOrders',
            label: `${t('uploadComplement.purchase_order')}`,
            minWidth: 100,
            maxWidth: 250,
        },
        {
            id: 'amount',
            label: `${t('uploadComplement.amount')}`,
            minWidth: 200,
            align: 'right',
            format: (value) => formatMoney(value, 'MXN'),
        },
    ];

    useEffect(() => {
        let errors = 0;
        bills.forEach((bill) => {
            if (bill.status === 'not-found') errors++;
        });
        setNotFound(errors);
        dispatch(setBillsErrorFlag(errors > 0));
    }, [bills]);

    return (
        <Box sx={{}}>
            <XmlFileHeader />
            <Box
                sx={{
                    display: 'flex',
                    margin: '5px 0px',
                    gap: '5px',
                }}
            >
                <TypographyStyled600>
                    {t('uploadComplement.invoice_summary_supplement')}:
                </TypographyStyled600>
                {errorBillsFlag && (
                    <TypographyStyled600 color={'error'}>
                        {`${t('uploadComplement.bills_not_found')} (${notFound})`}
                    </TypographyStyled600>
                )}
            </Box>
            <TableContainer sx={{maxHeight: 300, border: '2px solid #c5d1d8'}}>
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <StyledTableCell
                                    key={col.id}
                                    align={col.align}
                                    style={{minWidth: col.minWidth, maxWidth: col.maxWidth}}
                                    sx={{
                                        padding: '6px 10px',
                                    }}
                                >
                                    {col.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bills &&
                            bills.length > 0 &&
                            bills.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.uuid}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <StyledTableCell
                                                    key={`${column.id}-${row.uuid}`}
                                                    align={column.align}
                                                    sx={{
                                                        minWidth: column.minWidth,
                                                        maxWidth: column.maxWidth,
                                                        padding:
                                                            value === 'not-found'
                                                                ? '4px 10px 0px 10px'
                                                                : '6px 10px',
                                                    }}
                                                >
                                                    {value === 'not-found' && (
                                                        <ErrorOutlineRounded color="error" />
                                                    )}

                                                    {column.id !== 'status' &&
                                                        (column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : Array.isArray(value)
                                                            ? value.join(', ')
                                                            : value)}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        {bills &&
                            bills.length === 0 &&
                            [0, 1, 2].map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.uuid}>
                                        {columns.map((column) => {
                                            return (
                                                <StyledTableCell
                                                    key={`${column.id}-${row.uuid}`}
                                                    align={column.align}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{fontSize: '1rem'}}
                                                    />
                                                </StyledTableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
