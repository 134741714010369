import {Paper, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

export const TableCellChildren = styled(Paper)({
    padding: '10px',
    borderRadius: '0',
    borderBottom: '1px solid #C2D1D9',
    boxShadow: 'none',
    background: '#FFFFFF',
    minHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'anywhere',
});

export const TableCellChildrenSmall = styled(Paper)({
    paddingLeft: '2px',
    paddingRight: '2px',
    borderRadius: '0',
    boxShadow: 'none',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'anywhere',
});

export const TypographyStyled400 = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    fontWeight: 400,
}));
