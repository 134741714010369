import logins from '@/components/auth/redux/reducers/LoginReducer';
import billingStatement from '@/components/billingStatement/redux/reducers/BillingStatementReducers';
import configuration from '@/components/configuration/redux/reducers/ConfigurationReducers';
import csfAbi from '@/components/csf/csfABI/redux/reducers/CsfABIReducers';
import csfSupplier from '@/components/csf/csfSupplier/redux/reducers/CsfSupplierReducers';
import country from '@/components/general/country/redux/reducers/CountryReducers';
import homeReducers from '@/components/home/redux/reducers/HomeReducers';
import complement from '@/components/orderSimply/redux/reducers/LoadComplementReducers';

import banners from '@/components/banners/redux/reducers/BannersReducers';
import legalAgreements from '@/components/legalAgreements/redux/reducers/LegalAgreementsReducers';
import binnacles from '@/components/log/redux/reducers/BinnaclesReducers';
import manuals from '@/components/manuals/redux/reducers/ManualsReducers';
import order from '@/components/order/redux/reducers/OrderReducers';
import orderrecept from '@/components/orderRecept/redux/reducers/OrderReceptReducers';
import orderssimply from '@/components/orderSimply/redux/reducers/OrdersSimplyReducers';
import perfil from '@/components/perfil/redux/reducers/PerfilReducers';
import purchaceOrderReducers from '@/components/purchase-order/redux/reducers/PurchaceOrderReducers';
import rolesManager from '@/components/roles/redux/reducers/RolesReducers';
import taxRequirements from '@/components/taxRequirements/redux/reducers/TaxRequirementsReducers';
import ticketReducers from '@/components/tickets/redux/reducers/TicketReducers';
import urls from '@/components/urls/redux/reducers/UrlsReducers';
import user from '@/components/user/redux/reducers/UserReducers';
import candidate from '@components/candidates/redux/reducers/CandidateReducers';
import faqs from '@components/faqs/redux/reducers/FAQsReducers';
import roles from '@components/general/roles/redux/reducers/RolesReducers';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import modelorama from '@/components/modelorama/redux/reducers/ModeloramaReducers';
import notificationReducers from '@/components/notifications/redux/reducers/notification-reducer';
import orderConfirm from '@/components/orderConfirm/redux/reducers/OrderConfirmReducers';
import orderGenesis from '@/components/orderGenesis/redux/reducers/OrderReducers';
import {termsMiddleware} from '@/middlewares/termsMiddleware';
import poTermsAcceptance from '@/components/terms/redux/reducers/TermsAcceptanceReducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'logins',
        'order',
        'roles',
        'country',
        'binnacles',
        'configuration',
        'perfil',
        'orderGenesis',
    ],
};

const reducers = combineReducers({
    logins: logins,
    urls: urls,
    manuals: manuals,
    banners: banners,
    order: order,
    orderGenesis: orderGenesis,
    faqs: faqs,
    binnacles: binnacles,
    legalAgreements: legalAgreements,
    taxRequirements: taxRequirements,
    country: country,
    roles: roles,
    orderssimply: orderssimply,
    csfAbi: csfAbi,
    configuration: configuration,
    csfSupplier: csfSupplier,
    rolesManager: rolesManager,
    perfil: perfil,
    user: user,
    orderrecept: orderrecept,
    billingStatement: billingStatement,
    purchaceOrderReducers: purchaceOrderReducers,
    home: homeReducers,
    complement: complement,
    tickets: ticketReducers,
    modelorama: modelorama,
    candidate: candidate,
    notifications: notificationReducers,
    orderConfirm: orderConfirm,
    poTermsAcceptance: poTermsAcceptance,
});
export type RootState = ReturnType<typeof reducers>;
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk), applyMiddleware(termsMiddleware))
);

export type AppDispatch = typeof store.dispatch;
export default store;
