import React, {useEffect, startTransition, useCallback} from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

const LANGUAGES = {
    ENGLISH: 'en',
    SPANISH: 'es',
};

const COLORS = {
    ACTIVE: 'red',
    INACTIVE: 'black',
};

const LanguageSwitcher: React.FC = () => {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    useEffect(() => {
        const userLang = localStorage.getItem('user-lang') || LANGUAGES.SPANISH;
        startTransition(() => {
            i18n.changeLanguage(userLang);
        });
    }, [i18n]);
    useEffect(() => {
        changeLanguage(LANGUAGES.SPANISH);
    }, []);

    const changeLanguage = useCallback(
        (lng: string) => {
            startTransition(() => {
                i18n.changeLanguage(lng);
                localStorage.setItem('user-lang', lng);
            });
        },
        [i18n]
    );

    return (
        <div style={{display: 'flex', alignItems: 'center', alignSelf: 'flex-end'}}>
            <Typography
                sx={{
                    fontSize: '12px',
                    marginRight: '10px',
                }}
                aria-label={t('language')}
            >
                {t('language')}
            </Typography>
            <Typography
                sx={{
                    marginRight: '5px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    color: currentLanguage === LANGUAGES.SPANISH ? COLORS.ACTIVE : COLORS.INACTIVE,
                }}
                onClick={() => changeLanguage(LANGUAGES.SPANISH)}
                aria-label="Switch to Spanish"
                role="button"
            >
                ESP
            </Typography>
            <Typography
                sx={{
                    marginRight: '5px',
                    cursor: 'pointer',
                }}
                aria-hidden="true"
            >
                |
            </Typography>
            <Typography
                sx={{
                    marginRight: '5px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    color: currentLanguage === LANGUAGES.ENGLISH ? COLORS.ACTIVE : COLORS.INACTIVE,
                }}
                onClick={() => changeLanguage(LANGUAGES.ENGLISH)}
                aria-label="Switch to English"
                role="button"
            >
                ENG
            </Typography>
        </div>
    );
};

export default LanguageSwitcher;
