import React from 'react';
import Swal from 'sweetalert2';
import {green} from '@mui/material/colors';

interface ToastProps {
    title: string;
}

const SuccessToast: React.FC<ToastProps> = ({title}) => {
    Swal.fire({
        customClass: {
            container: 'swal-over-all',
        },
        icon: 'success',
        iconColor: green[500],
        title,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    });

    return null;
};

export default SuccessToast;
