import {IconButton, Tooltip} from '@mui/material';
import React from 'react';

interface IBtnReject {
    actionFn: (...params: any[]) => any;
    title?: string;
}
const BtnReject = (props: IBtnReject) => {
    const {actionFn, title = ''} = props;
    return (
        <Tooltip title={title}>
            <IconButton aria-label="delete" onClick={actionFn}>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#921A28" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.2859 12.3125C12.539 12.0594 12.8824 11.9173 13.2403 11.9173C13.5983 11.9173 13.9416 12.0594 14.1948 12.3125L19.9903 18.1081L25.7859 12.3125C25.9104 12.1836 26.0594 12.0807 26.2241 12.01C26.3888 11.9392 26.5659 11.902 26.7452 11.9004C26.9245 11.8989 27.1022 11.933 27.2681 12.0009C27.434 12.0688 27.5848 12.169 27.7115 12.2958C27.8383 12.4226 27.9385 12.5733 28.0064 12.7392C28.0743 12.9051 28.1084 13.0829 28.1068 13.2621C28.1053 13.4414 28.0681 13.6185 27.9973 13.7832C27.9266 13.9479 27.8237 14.0969 27.6948 14.2214L21.8993 20.017L27.6948 25.8126C27.9406 26.0672 28.0767 26.4082 28.0738 26.7622C28.0706 27.1161 27.9286 27.4547 27.6783 27.705C27.428 27.9553 27.0895 28.0973 26.7355 28.1003C26.3815 28.1034 26.0405 27.9673 25.7859 27.7215L19.9903 21.9259L14.1948 27.7215C13.9402 27.9673 13.5992 28.1034 13.2452 28.1003C12.8912 28.0973 12.5526 27.9553 12.3023 27.705C12.052 27.4547 11.9101 27.1161 11.907 26.7622C11.9039 26.4082 12.04 26.0672 12.2859 25.8126L18.0814 20.017L12.2859 14.2214C12.0328 13.9683 11.8906 13.6249 11.8906 13.267C11.8906 12.909 12.0328 12.5657 12.2859 12.3125Z"
                        fill="#921A28"
                    />
                </svg>
            </IconButton>
        </Tooltip>
    );
};

export default BtnReject;
