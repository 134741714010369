import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const useClearLocalStorage = (route: {pathname: string; search: string}) => {
    const location = useLocation();

    useEffect(() => {
        try {
            if (!isValidRoute(route)) {
                // eslint-disable-next-line
                console.error(
                    `Invalid route object: url=${route?.pathname ?? ''}${route?.search ?? ''}`
                );
                return;
            }

            const shouldClearCache =
                location.pathname === route.pathname && location.search.startsWith(route.search);

            if (shouldClearCache) {
                localStorage.clear();
            }
        } catch (error) {
            // eslint-disable-next-line
            console.error('Error clearing local storage:', error);
        }
    }, [location.pathname, location.search, route]);
};

const isValidRoute = (route: {pathname: string; search: string} | undefined): boolean => {
    return (
        route !== undefined &&
        typeof route.pathname === 'string' &&
        typeof route.search === 'string'
    );
};

export default useClearLocalStorage;
