import {
    GET_LEGALAGREEMENTS,
    GET_LEGALAGREEMENTS_SUCCESS,
    GET_LEGALAGREEMENTS_ERROR,
    UPDATE_LEGALAGREEMENTS,
    UPDATE_LEGALAGREEMENTS_SUCCESS,
    UPDATE_LEGALAGREEMENTS_ERROR,
    CREATE_FILE_LEGALAGREEMENTS,
    CREATE_FILE_LEGALAGREEMENTS_SUCCESS,
    CREATE_FILE_LEGALAGREEMENTS_ERROR,
} from '@components/legalAgreements/redux/types/LegalAgreementsTypes';

import axios from 'axios';

export const getLegalAgreements = () => {
    return {
        type: GET_LEGALAGREEMENTS,
    };
};

export const getLegalAgreementsSuccess = (result) => {
    return {
        type: GET_LEGALAGREEMENTS_SUCCESS,
        value: result,
    };
};

export const getLegalAgreementsError = (error, result) => {
    return {
        type: GET_LEGALAGREEMENTS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetLegalAgreements(token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getLegalAgreements());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/policy`,
                {headers}
            );
            await dispatch(getLegalAgreementsSuccess(response.data));
            await dispatch(getLegalAgreementsError(false));
        } catch (error) {
            dispatch(getLegalAgreementsError(true));
        }
    };
}

export const putLegalAgreements = () => {
    return {
        type: UPDATE_LEGALAGREEMENTS,
    };
};

export const putLegalAgreementsSuccess = (result) => {
    return {
        type: UPDATE_LEGALAGREEMENTS_SUCCESS,
        value: result,
    };
};

export const putLegalAgreementsError = (error, result) => {
    return {
        type: UPDATE_LEGALAGREEMENTS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutLegalAgreements(token, data, file) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putLegalAgreements());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/policy`,
                data,
                {headers}
            );
            await dispatch(putLegalAgreementsSuccess(response.data));
            if (file && file.type) {
                await dispatch(fetchPostFileLegalAgreements(response.data.data.url, file));
            }
            await dispatch(fetchGetLegalAgreements(token));
            await dispatch(putLegalAgreementsError(false));
        } catch (error) {
            dispatch(putLegalAgreementsError(true));
        }
    };
}

export const postFileLegalAgreements = () => {
    return {
        type: CREATE_FILE_LEGALAGREEMENTS,
    };
};

export const postFileLegalAgreementsSuccess = (result) => {
    return {
        type: CREATE_FILE_LEGALAGREEMENTS_SUCCESS,
        value: result,
    };
};

export const postFileLegalAgreementsError = (error, result) => {
    return {
        type: CREATE_FILE_LEGALAGREEMENTS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostFileLegalAgreements(URL, data) {
    return async (dispatch) => {
        dispatch(postFileLegalAgreements());
        try {
            const response = await axios.put(`${URL}`, data, {
                headers: {'Content-Type': data.type},
            });
            dispatch(postFileLegalAgreementsSuccess(response.data.data));
        } catch (error) {
            dispatch(postFileLegalAgreementsError(true));
        }
    };
}
