import React from 'react';

export type MediaTypes = 'Imágenes';

interface MediaComponentProps {
    type: string;
    src: string;
    alt?: string;
}

const MediaComponent: React.FC<MediaComponentProps> = ({type, src, alt = ''}) => {
    switch (type) {
        case 'Imágenes':
            return <img src={src} style={{maxWidth: '100%', height: 'auto'}} />;
        default:
            return (
                <div style={{backgroundColor: '#fff'}}>
                    <p>Tipo de medio no soportado.</p>
                </div>
            );
    }
};

export default MediaComponent;
