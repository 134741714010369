import {styled} from '@mui/system';
import {LocalizationProvider} from '@mui/x-date-pickers';

export const StyledLocalizationProvider = styled(LocalizationProvider)({
    width: '60%',
});

export const StyledControllerWrapper = styled('div')({
    height: '60px',
    width: '60%',
});

export const StyledLocalizationProviders = styled(LocalizationProvider)({
    width: '38%',
});

export const StyledControllerWrappers = styled('div')({
    height: '60px',
    width: '38%',
});
