import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useTheme,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Nav} from '@components/faqs/StyledFAQ';
import {useDispatch, useSelector} from 'react-redux';
import {LoadComplement} from '@components/orderSimply/modals/components/LoadComplement';
import {
    closeDialog,
    setActiveStep,
} from '@components/orderSimply/redux/actions/LoadComplementActions';
import {ConfirmComplememntBills} from '@components/orderSimply/modals/components/ConfirmComplementBills';
import {ValidateComplement} from '@components/orderSimply/modals/components/ValidateComplement';
import {validateXml} from '@components/orderSimply/redux/actions/LoadComplementActions';
import {UploadComplementFiles} from '@components/orderSimply/modals/components/UploadComplementFiles';
import {useTranslation} from 'react-i18next';
import {ButtonCancel} from '@/components/UI/atoms/buttons/ButtonAlternative';
import {fetchFiles} from '@components/orderSimply/redux/actions/LoadComplementActions';

export const UploadComplement = ({open}) => {
    const {t} = useTranslation();
    const [disableNext, setDisableNext] = useState(true);
    const [disabledBack, setDisabledBack] = useState(true);
    const token = useSelector((state) => state.logins.accessToken);
    const activeStep = useSelector((state) => state.complement.activeStep);
    const loading = useSelector((state) => state.orderssimply.loadingValidateComplement);
    const pdfFile = useSelector((state) => state.complement.pdfFile);
    const xmlFile = useSelector((state) => state.complement.xmlFile);
    const errorFiles = useSelector((state) => state.complement.errorFiles);
    const loadingBills = useSelector((state) => state.complement.loadingBills);
    const errorBillsFlag = useSelector((state) => state.complement.errorBillsFlag);
    const loadingValidations = useSelector((state) => state.complement.loadingValidations);
    const errorValidations = useSelector((state) => state.complement.errorValidations);
    const uploadFilesClicked = useSelector((state) => state.complement.uploadFilesClicked);
    const uploadingFiles = useSelector((state) => state.complement.uploadingFiles);
    const uploadFilesStatus = useSelector((state) => state.complement.uploadFilesStatus);
    const bills = useSelector((state) => state.complement.bills);

    const Steps = {
        0: t('uploadComplement.upload_files'),
        2: t('uploadComplement.invoice_confirmation'),
        3: t('uploadComplement.validate_xml'),
        4: t('upload_files'),
    };

    const happyPath = false;

    const dispatch = useDispatch();

    const theme = useTheme();

    const onClose = () => {
        dispatch(closeDialog());
    };

    const handleNext = () => {
        if (activeStep === 1) dispatch(validateXml(token, xmlFile));
        dispatch(setActiveStep(activeStep + 1));
    };

    const handleBack = () => {
        if (activeStep > 0) dispatch(setActiveStep(activeStep - 1));
    };

    const handleUpload = (event) => {
        event.stopPropagation();
        const referenceIds = bills.flatMap((bl) => bl.purchaseOrders);
        dispatch(fetchFiles({token, xmlFile, pdfFile, referenceIds}));
    };

    const allowedBack = () => {
        let disabled = true;
        if (activeStep > 0) disabled = false;
        setDisabledBack(disabled || uploadFilesClicked);
    };

    const allowedNext = () => {
        let disabled = true;
        if (activeStep === 0) {
            disabled = !pdfFile || pdfFile.error || !xmlFile || xmlFile.error || errorFiles;
        }
        if (happyPath) {
            if (activeStep > 0) disabled = false;
        } else {
            if (activeStep === 1) disabled = loadingBills || errorBillsFlag;
            if (activeStep === 2)
                disabled =
                    loadingValidations || (errorValidations && errorValidations.length !== 0);
        }

        setDisableNext(disabled);
    };

    useEffect(allowedNext, [
        pdfFile,
        xmlFile,
        activeStep,
        loadingBills,
        errorBillsFlag,
        happyPath,
        loadingValidations,
    ]);
    useEffect(allowedBack, [activeStep, uploadFilesClicked]);

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <Nav>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 700,
                        color: 'white',
                        marginLeft: '10px',
                    }}
                >
                    {t('uploadComplement.upload_payment_plugin')}
                </Typography>
            </Nav>
            <Stepper
                activeStep={activeStep}
                sx={{
                    marginTop: '20px',
                    marginBottom: '10px',
                }}
                alternativeLabel
            >
                {Object.entries(Steps).map(([key, value]) => (
                    <Step key={key}>
                        <StepLabel>{`${value}`}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Divider />
            <DialogContent
                sx={{
                    padding: '10px 24px',
                }}
            >
                {/* <Box display={'flex'} justifyContent={'end'}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={happyPath}
                                    onChange={(event) => setHappyPath(event.target.checked)}
                                />
                            }
                            label="Happy path"
                        />
                    </FormGroup>
                </Box> */}
                {activeStep === 0 && <LoadComplement />}
                {activeStep === 1 && <ConfirmComplememntBills />}
                {activeStep === 2 && <ValidateComplement />}
                {activeStep === 3 && <UploadComplementFiles />}
            </DialogContent>
            <DialogActions sx={{padding: '20px 24px'}}>
                <ButtonCancel
                    variant="contained"
                    onClick={() => handleBack()}
                    disabled={disabledBack}
                >
                    {t('back').toUpperCase()}
                </ButtonCancel>

                <Box sx={{flex: '1 1 auto'}} />
                <ButtonCancel
                    variant="contained"
                    onClick={() => onClose()}
                    disabled={uploadFilesClicked}
                    sx={{mr: 1}}
                >
                    {t('cancel').toUpperCase()}
                </ButtonCancel>
                {activeStep < 3 && (
                    <Button
                        variant="contained"
                        onClick={() => handleNext()}
                        disabled={disableNext || loading}
                        sx={{color: '#FFFFFF', width: '150px'}}
                    >
                        {t('next')}
                    </Button>
                )}
                {activeStep === 3 ? (
                    uploadFilesStatus === 'success' ? (
                        <Button variant="contained" onClick={() => onClose()} disabled={loading}>
                            {t('finish')}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={(e) => handleUpload(e)}
                            disabled={uploadingFiles}
                        >
                            {t('upload_files')}
                        </Button>
                    )
                ) : null}
            </DialogActions>
        </Dialog>
    );
};
