import {
    GET_CANDIDATE,
    GET_CANDIDATE_SUCCESS,
    GET_CANDIDATE_ERROR,
    SET_CANDIDATE_STATUS,
    SET_CANDIDATE_STATUS_SUCCESS,
    SET_CANDIDATE_STATUS_ERROR,
    GET_DOWNLOAD_CANDIDATE,
    GET_DOWNLOAD_CANDIDATE_SUCCESS,
    GET_DOWNLOAD_CANDIDATE_ERROR,
    DELETE_CANDIDATE,
    DELETE_CANDIDATE_SUCCESS,
    DELETE_CANDIDATE_ERROR,
} from '@components/candidates/redux/types/CandidateTypes';

const initialState = {
    list: [],
    loading: true,
    count: 0,
    page: 1,
    limit: 10,
    totalPages: 1,
    error: '',
    candidate: null,
    deleteCandidate: false,
};

const candidate = (state = initialState, action) => {
    switch (action.type) {
        case GET_CANDIDATE:
            return {
                ...state,
                loding: true,
            };
        case GET_CANDIDATE_SUCCESS:
            return {
                ...state,
                list: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                limit: action.value.metadata.limit,
                loading: false,
            };
        case GET_CANDIDATE_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case SET_CANDIDATE_STATUS:
            return {
                ...state,
                loding: true,
            };
        case SET_CANDIDATE_STATUS_SUCCESS:
            return {
                ...state,
                candidate: action.value.data,
                loading: false,
            };
        case SET_CANDIDATE_STATUS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_DOWNLOAD_CANDIDATE:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_DOWNLOAD_CANDIDATE_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case DELETE_CANDIDATE:
            return {
                ...state,
                loading: true,
                deleteCandidate: false,
            };
        case DELETE_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteCandidate: action.value,
            };
        case DELETE_CANDIDATE_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default candidate;
