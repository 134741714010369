import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface PositionsTitleProps {
    title: string;
    purchaseOrderId: string;
    addressCode: string;
}

const PositionsTitle: React.FC<PositionsTitleProps> = ({title, purchaseOrderId, addressCode}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    return (
        <>
            <Box
                alignItems="baseline"
                p={2}
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    borderTopLeftRadius: 'inherit',
                    borderTopRightRadius: 'inherit',
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2f3c50' : '#fff'),
                }}
            >
                <Box sx={{flexGrow: 1, mr: 1}}>
                    <Typography variant="h6">
                        <b>{title}</b>
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: '#CD9F28',
                            marginLeft: '10px',
                        }}
                    >
                        # {purchaseOrderId}
                    </Typography>
                </Box>
            </Box>
            {addressCode && (
                <Box
                    alignItems="baseline"
                    padding="0px 15px 20px 15px"
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2f3c50' : '#fff'),
                    }}
                >
                    <Box>
                        <Typography>
                            <b>
                                {t('confirmpoaction.dispatch_center')}: {addressCode}
                            </b>
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default PositionsTitle;
