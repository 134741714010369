import React from 'react';
import {SxProps} from '@mui/material';

import PositionsList from '@components/orderSimply/components/PositionsList';
import PositionsTitle from '@components/orderSimply/components/PositionsTitle';

export interface Position {
    productName: string;
    position: number;
    deliveryDate: string;
    unit: string;
    quantity: number;
    unitPrice: number;
}

export interface PositionsProps {
    title: string;
    purchaseOrderId: string;
    addressCode?: string;
    positions: Position[];
    sxPositionsList?: SxProps;
}

const Positions: React.FC<PositionsProps> = (data) => {
    const {title, purchaseOrderId, addressCode, positions, sxPositionsList} = data;
    return (
        <>
            <PositionsTitle
                title={title}
                purchaseOrderId={purchaseOrderId}
                addressCode={addressCode!}
            />
            <PositionsList sx={sxPositionsList} positions={positions} />
        </>
    );
};

export default Positions;
