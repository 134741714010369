import {RequestPageOutlined} from '@mui/icons-material';
import {Box, Tooltip, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getFileSize} from '@/utils/utils';
import {UploadOutlined} from '@mui/icons-material';
import {TypographyStyled600} from '@components/faqs/StyledFAQ';
import CircularProgress from '@mui/material/CircularProgress';
import {TaskAltOutlined} from '@mui/icons-material';
import {ErrorOutlineOutlined} from '@mui/icons-material';
import {PendingActionsOutlined} from '@mui/icons-material';
import {UploadComplemementResultDialog} from '@components/orderSimply/modals/UploadComplemementResultDialog';
import {useTranslation} from 'react-i18next';

export const UploadComplementFiles = () => {
    const {t} = useTranslation();
    const xmlFile = useSelector((state) => state.complement.xmlFile);
    const pdfFile = useSelector((state) => state.complement.pdfFile);
    const uploadingFiles = useSelector((state) => state.complement.uploadingFiles);
    const uploadFilesStatus = useSelector((state) => state.complement.uploadFilesStatus);

    const [openTimeout, setOpenTimeout] = useState(false);

    const handleTimeout = () => {
        if (uploadFilesStatus !== '') setOpenTimeout(true);
    };

    const handleClose = () => {
        setOpenTimeout(false);
    };

    useEffect(handleTimeout, [uploadFilesStatus]);

    const FileUploated = ({file}) => {
        return (
            <>
                <Box
                    sx={{
                        border: '1px solid #c5d1d8',
                        borderRadius: '6px',
                        margin: '10px 0px',
                        paddingRight: '10px',
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <RequestPageOutlined sx={{margin: '5px', fontSize: 50}} />
                        <Box display={'grid'}>
                            <Typography variant="subtitle2">{file.name}</Typography>
                            <Box>
                                <Typography
                                    variant="caption"
                                    color={file.error ? 'error' : ''}
                                    sx={{paddingRight: '5px'}}
                                >
                                    {getFileSize(file)}
                                </Typography>
                                <Typography variant="caption" color="error">
                                    {file.error}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{flex: '1 1 auto'}} />
                    </Box>
                </Box>
            </>
        );
    };

    return (
        <>
            <TypographyStyled600>{`${t(
                'uploadComplement.files_ready_to_upload'
            )}:`}</TypographyStyled600>
            <Box display={'flex'} gap={'10px'} alignItems={'center'} minHeight={'80px'}>
                <FileUploated file={pdfFile} />
                <FileUploated file={xmlFile} />
                <Box sx={{flex: '1 1 auto'}} />
                {uploadingFiles && (
                    <Box
                        sx={{
                            margin: '10px 0px',
                            paddingTop: '5px',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {!uploadingFiles && uploadFilesStatus !== '' && (
                    <Box
                        sx={{
                            margin: '10px 0px',
                            paddingTop: '5px',
                        }}
                    >
                        {uploadFilesStatus === 'timeout' && (
                            <PendingActionsOutlined color={'warning'} fontSize="large" />
                        )}
                        {uploadFilesStatus === 'success' && (
                            <TaskAltOutlined color={'success'} fontSize="large" />
                        )}
                        {uploadFilesStatus !== 'timeout' && uploadFilesStatus !== 'success' && (
                            <ErrorOutlineOutlined color={'error'} fontSize="large" />
                        )}
                    </Box>
                )}
                {!uploadingFiles && uploadFilesStatus === '' && (
                    <Tooltip title={`${t('uploadComplement.upload_files')}`} placement="left" arrow>
                        <UploadOutlined fontSize="large" />
                    </Tooltip>
                )}
                <UploadComplemementResultDialog
                    open={openTimeout}
                    type={uploadFilesStatus}
                    handleClose={handleClose}
                />
            </Box>
        </>
    );
};
