import {Box, Button, TextField, Typography, useTheme} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import ConfirmPurchaseOrderTotalTable from '@/components/purchase-order/confirmTotal/ConfirmPOTable';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {ILineDetail, IItemSplit} from '@/components/purchase-order/confirmTotal/interface';
import {
    fetchPutPurchaseOrder,
    clearPurchaseOrderActive,
    fetchGetPurchaseOrder,
} from '@/components/purchase-order/redux/actions/PurchaceOrderActions';
import {DateTime} from 'luxon';
import ConfirmPOModal from '@/components/purchase-order/confirmTotal/ConfirmModal';
import {useTranslation} from 'react-i18next';

import {IErrorSplit, IItemSplitMarket} from '@/components/purchase-order/confirmPartial/interface';
import ConfirmPurchaseOrderTableMarket from '@/components/purchase-order/confirmTotal/ConfirmPOTableMarket';
const PurchaseOrderConfirmTotal = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const {orderId, group} = useParams();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const navigate = useNavigate();
    const lineItems: ILineDetail[] = useSelector(
        (state: RootState) => state.purchaceOrderReducers.lineItems
    );
    const purchaseOrder: any = useSelector(
        (state: RootState) => state.purchaceOrderReducers.purchaseOrder
    );
    const purchaseOrderUpdate = useSelector(
        (state: RootState) => state.purchaceOrderReducers.purchaseOrderUpdate
    );
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [lines, setLines] = useState<{[key: string]: IItemSplit}>({});
    const [splitsMarket, setSplitMarket] = useState<{[key: string]: IItemSplitMarket[]}>({});
    const [errorsSplits, setErrorsSplits] = useState<IErrorSplit[]>([]);
    const [errorsLines, setErrorsLines] = useState<IErrorSplit[]>([]);

    const onChange = (event: any) => {
        setDeliveryDate(new Date(event));
    };

    const checkItemsError = () => {
        let errors: IErrorSplit[] = [];
        for (const item of lineItems) {
            if (lines[item.id] && lines[item.id].batch === '' && !splitsMarket[item.id]) {
                errors.push({
                    id: item.id,
                    message: 'El número de lote es requerido.',
                });
            } else {
                errors = errors.filter((error) => error.id !== item.id);
                setErrorsLines(errors);
            }
            if (lines[item.id] && splitsMarket[item.id]) {
                checkSplitHasBatch(splitsMarket[item.id], item.id, errors);
            }
        }
        setErrorsLines(errors);
        return errors.length > 0;
    };

    const checkSplitHasBatch = (split: IItemSplitMarket[], id: string, errors: IErrorSplit[]) => {
        const isEnpty = split.some((item: IItemSplitMarket) => item.batch === '');
        if (isEnpty) {
            errors.push({
                id: id,
                message: 'Los números de lote son requeridos.',
            });
        } else {
            errors = errors.filter((error) => error.id !== id);
            setErrorsLines(errors);
        }
    };

    const confirmPOFull = () => {
        const data = {
            type: group === 'CORE' ? 'CONFIRMATION_TOTAL' : 'CONFIRMATION_TOTAL_MARKET_PLACE',
            deliveryDate: DateTime.fromISO(deliveryDate.toISOString()).toFormat('yyyy/MM/dd'),
            lineItems: lineItems
                ? lineItems.map((item: ILineDetail) => {
                      return {
                          pos: item.position.toString(),
                          quantity: Number(item.quantity),
                          deliveryDate: DateTime.fromISO(deliveryDate.toISOString()).toFormat(
                              'yyyy/MM/dd'
                          ),
                          rejected: false,
                          split: splitsMarket[item.id] || null,
                          concept: '',
                          observation: '',
                          batch: lines[item.id].batch || null,
                          expirationDate: lines[item.id].expirationDate || null,
                          id: item.id,
                      };
                  })
                : [],
        };
        dispatch(fetchPutPurchaseOrder(token, data, orderId));
    };

    const closeModal = () => {
        setShowConfirmModal(false);
    };

    const gotToBack = () => {
        if (token && orderId) {
            dispatch(fetchGetPurchaseOrder(token, orderId));
        }
        dispatch(clearPurchaseOrderActive());
        navigate(-1);
    };

    useEffect(() => {
        if (lineItems && lineItems.length > 0) {
            for (const line of lineItems) {
                setLines((prevLines) => ({
                    ...prevLines,
                    [line.id]: {
                        batch: line.batches && line.batches.length > 0 ? line.batches[0].batch : '',
                        expirationDate:
                            line.batches && line.batches.length > 0
                                ? new Date(line.batches[0].dateExpire)
                                : new Date(line.deliveryDate),
                    },
                }));
            }
        }
    }, [lineItems]);

    const openModal = () => {
        if (group === 'CORE') {
            setShowConfirmModal(true);
        } else {
            if (!checkItemsError()) setShowConfirmModal(true);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '40px',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '16px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('confirmposimply.full_delivery_confirmation')}
                </Typography>
                {purchaseOrderUpdate && !showConfirmModal && (
                    <span
                        style={{
                            marginLeft: '10px',
                            backgroundColor: '#4BBC68',
                            padding: '4px 4px',
                            borderRadius: '4px',
                            color: 'white',
                        }}
                    >
                        {t('accepted').toUpperCase()}
                    </span>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    border: '1px solid #C2D1D9',
                    marginTop: '20px',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    marginBottom: '20px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#EBF0F2',
                        borderBottom: '1px solid #C2D1D9',
                        padding: '10px',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                    }}
                >
                    {t('confirmposimply.order_details')}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        backgroundColor: 'white',
                        padding: '30px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={t('receptpo.table.headers.delivery_date')}
                            value={deliveryDate}
                            onChange={onChange}
                            disabled={purchaseOrder && purchaseOrder.quantityDelivery >= 2}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: '40px',
                                            marginRight: '10px',
                                        },
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    {purchaseOrder && purchaseOrder.quantityDelivery >= 2 && (
                        <Typography
                            sx={{
                                color: '#921A28',
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 400,
                                margin: '0 10px 10px 10px',
                            }}
                        >
                            {t('confirmposimply.can_only_delivery_twice')}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={gotToBack}
                            sx={{
                                marginRight: '10px',
                            }}
                            color="info"
                        >
                            {t('back')}
                        </Button>
                        <Button
                            onClick={openModal}
                            disabled={
                                errorsLines.length > 0 ||
                                errorsSplits.length > 0 ||
                                !lineItems ||
                                lineItems.length === 0 ||
                                purchaseOrderUpdate ||
                                (purchaseOrder && purchaseOrder.quantityDelivery >= 2)
                            }
                            color="primary"
                            variant="outlined"
                        >
                            {t('confirm')}
                        </Button>
                    </Box>
                </Box>
            </Box>
            {group === 'CORE' ? (
                <ConfirmPurchaseOrderTotalTable internalId={orderId} />
            ) : (
                <ConfirmPurchaseOrderTableMarket
                    splits={splitsMarket}
                    setSplit={setSplitMarket}
                    internalId={orderId}
                    lines={lines}
                    setLines={setLines}
                    errorsLines={errorsLines}
                    setErrorsLines={setErrorsLines}
                    errorsSplits={errorsSplits}
                    setErrorsSplits={setErrorsSplits}
                />
            )}
            <ConfirmPOModal
                isModalOpen={showConfirmModal}
                closeModal={closeModal}
                deliveryDate={deliveryDate}
                updatedOrderFn={confirmPOFull}
            />
        </Box>
    );
};

export default PurchaseOrderConfirmTotal;
