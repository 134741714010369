import Axios, {AxiosError, AxiosResponse} from 'axios';

import store, {AppDispatch} from '@/config/store';
import {warningMessages} from '@config/constants/warningMessages';

import {resetOrderSuccess} from '@/components/order/redux/actions/OrderActions';
import {getCfdiValidation} from '@/components/orderSimply/utils/cfdiValidations';
import {getLogout} from '@components/auth/redux/actions/LoginActions';

import ErrorToast from '@/components/auth/componenents/ErrorToast';
import WarningToast from '@/components/auth/componenents/WarningToast';

export const InterceptorApp = (dispatch: AppDispatch) => {
    const accessToken = store.getState().logins.accessToken;
    let title = accessToken ? warningMessages.expiredSession : warningMessages.unauthorized;
    const USER_NOT_ACTIVE = 'user.not_active';

    // TODO: Refactor, this interceptor is managing too many things
    Axios.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error: AxiosError) => {
            const errorResponse = error.response;
            if (error.message.includes('401')) {
                const errorData = errorResponse?.data as any;
                const errorDetails = errorData?.data ?? errorData;
                const errorMessage =
                    (errorDetails.error || errorDetails.name) ??
                    errorDetails.message ??
                    errorDetails.errors?.message;
                if (errorMessage === USER_NOT_ACTIVE) title = 'El usuario se encuentra desactivado';
                dispatch(getLogout());
                dispatch(resetOrderSuccess());
                WarningToast({title});
            }
            if (error.message.includes('400')) {
                if (error.code === 'ERR_BAD_REQUEST') {
                    return Promise.reject(error);
                }
                const errorData = errorResponse?.data as any;
                const errorDetails = errorData?.data ?? errorData;
                const errorMessage =
                    (errorDetails.error || errorDetails.name) ??
                    errorDetails.message ??
                    errorDetails.errors?.message;
                const errorDesc = getCfdiValidation(errorMessage).description;
                ErrorToast({title: errorDesc});
            }
            return Promise.reject(error);
        }
    );
};
