import {TextButtonDetails} from '@/components/UI/molecules/textButton/textButtons';
import {TypographyStyledUser} from '@components/faqs/StyledFAQ';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import React from 'react';
export interface IButtonDownloadProps {
    handleChangeDownload: (format: any) => void;
    format: string;
    disabled: boolean;
}
const ButtonDownload: React.FC<IButtonDownloadProps> = ({
    handleChangeDownload,
    format,
    disabled,
}) => {
    return (
        <TextButtonDetails
            onClick={() => {
                handleChangeDownload(format);
            }}
            disabled={disabled}
        >
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={IconDownload} />
                <TypographyStyledUser sx={{marginLeft: '5px'}}>
                    {format.toUpperCase()}
                </TypographyStyledUser>
            </div>
        </TextButtonDetails>
    );
};

export default ButtonDownload;
