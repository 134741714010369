import React from 'react';
import {DialogContent, Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {StyledModal} from '@/components/faqs/StyledFAQ';
import {ModalActions, ModalHeader} from '@/components/terms/modals';
import {customScrollbar} from '@/components/UI/helpers/custom-scrollbar';

interface RejectTermsModalProps {
    isOpen: boolean;
    externalIds: string[];
    onClose: () => void;
    onReject: () => void;
    handleCloseModal: (event?: {preventDefault: () => void}, reason?: string) => void;
    loading: boolean;
}

export default function RejectTermsModal({
    isOpen,
    externalIds,
    onClose,
    onReject,
    handleCloseModal,
    loading,
}: RejectTermsModalProps) {
    const {t} = useTranslation();

    if (!isOpen) return null;

    return (
        <Box>
            <StyledModal
                open={isOpen}
                onClose={() => handleCloseModal()}
                disableEscapeKeyDown={true}
                disableEnforceFocus={true}
            >
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '3px',
                        width: '665px',
                        minHeight: '370px',
                        zIndex: '100',
                    }}
                >
                    <ModalHeader
                        title={t('confirmposimply.reject_terms_modal.title')}
                        navSx={{
                            backgroundColor: '#921A28',
                        }}
                    />
                    <ModalContent externalIds={externalIds} />
                    <ModalActions
                        acceptButtonText={t(
                            'confirmposimply.reject_terms_modal.buttons.reject'
                        ).toUpperCase()}
                        cancelButtonText={t(
                            'confirmposimply.reject_terms_modal.buttons.cancel'
                        ).toUpperCase()}
                        acceptSx={{
                            backgroundColor: '#921A28',
                            border: `1.5px solid #D3D3D3`,
                            color: '#FFFFFF',
                            margin: '0 10px',
                            textTransform: 'none',
                        }}
                        cancelSx={{
                            color: '#000000',
                            border: `1.5px solid #000000`,
                            backgroundColor: '#FFFFFF',
                        }}
                        enabledAcceptButton={!loading}
                        onAccept={onReject}
                        onCancel={onClose}
                        loading={loading}
                    />
                </Box>
            </StyledModal>
        </Box>
    );
}

const ModalContent = ({externalIds}: {externalIds: string[]}) => {
    const {t} = useTranslation();
    return (
        <DialogContent>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <Typography component="span" sx={{paddingBottom: '10px'}}>
                    {t(`confirmposimply.reject_terms_modal.subtitle.part_1`)}
                    {t(
                        `confirmposimply.reject_terms_modal.subtitle.${
                            externalIds.length > 1 ? 'part_2_plural' : 'part_2_singular'
                        }`
                    )}
                    {t(`confirmposimply.reject_terms_modal.subtitle.part_3`)}
                </Typography>
                <Typography sx={{paddingBottom: '10px'}} fontWeight={'bold'}>
                    {t(
                        `confirmposimply.reject_terms_modal.rejected_${
                            externalIds?.length === 1 ? 'order' : 'orders'
                        }`
                    )}
                    :
                </Typography>
                {externalIds.length > 0 && (
                    <Box
                        sx={{
                            maxHeight: '70px',
                            overflowY: 'auto',
                            marginBottom: '15px',
                            display: 'block',
                            ...customScrollbar,
                        }}
                    >
                        <Box component="ul" sx={{margin: 0}}>
                            {externalIds.map((id, index) => (
                                <li key={`${index}-${id}`}>
                                    <Typography>{id}</Typography>
                                </li>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </DialogContent>
    );
};
