import IconAlertDone from '@components/UI/atoms/icons/IconAlertDone.svg';
import {ButtonCancel, ImgIcon, StyledModal, Nav, ImgIconClose} from '@components/faqs/StyledFAQ';
import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import IconCloseModal from '@components/UI/atoms/icons/IconCloseModal.svg';

const SuccessUpdateModal = ({isModalOpen, closeModal, message = '', title = ''}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    height: '501px',
                    zIndex: '100',
                }}
            >
                <Nav sx={{justifyContent: 'end'}}>
                    <ImgIconClose
                        src={IconCloseModal}
                        onClick={closeModal}
                        sx={{paddingRight: '15px'}}
                    />
                </Nav>
                <Box
                    sx={{
                        display: 'flex',
                        padding: '15px',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '24px',
                            fontWeight: 400,
                            marginTop: '30px',
                            alignSelf: 'center',
                        }}
                    >
                        {title || t('update_modal_title')}
                    </Typography>
                    <ImgIcon src={IconAlertDone} />
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '16px',
                            fontWeight: 500,
                            marginTop: '30px',
                            alignSelf: 'center',
                        }}
                    >
                        {message || t('update_modal_subtitle')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        marginTop: '95px',
                        justifyContent: 'flex-end',
                        background: '#EBF0F2',
                    }}
                >
                    <ButtonCancel onClick={closeModal}>{t('close').toUpperCase()}</ButtonCancel>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default SuccessUpdateModal;
