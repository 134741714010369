import {
    GET_URL,
    GET_URL_SUCCESS,
    GET_URL_ERROR,
    POST_URL,
    POST_URL_SUCCESS,
    POST_URL_ERROR,
    UPDATE_URL,
    UPDATE_URL_SUCCESS,
    UPDATE_URL_ERROR,
    DELETE_URL,
    DELETE_URL_SUCCESS,
    DELETE_URL_ERROR,
} from '@/components/urls/redux/types/UrlsTypes';

const initialState = {
    urls: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
};

const urls = (state = initialState, action) => {
    switch (action.type) {
        case GET_URL:
            return {
                ...state,
                loading: true,
            };
        case GET_URL_SUCCESS:
            return {
                ...state,
                urls: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_URL_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_URL:
            return {
                ...state,
                loading: true,
            };
        case POST_URL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_URL_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_URL:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_URL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_URL_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case DELETE_URL:
            return {
                ...state,
                loading: true,
            };
        case DELETE_URL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_URL_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default urls;
