import {Box, Grid} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {TableCellChildrenSmall} from '@/components/terms//styles/styles';
import LineItemRow from '@/components/terms/components/LineItemRow';
import PurchaseOrdersLoader from '@/components/terms/components/PurchaseOrdersLoader';
import {IConProp} from '@/components/terms/interfaces/interface';
import {ILineItem} from '@/components/terms/interfaces/terms-purchase-order.interface';
import {fetchGetPoLineItems} from '@/components/terms/redux/actions/TermsAcceptanceActions';
import CustomPagination from '@/components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@/config/store';
import {TableCellHead, TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import {customScrollbar} from '@/components/orderSimply/constants/custom-scrollbar';

interface ILineItemsTable {
    purchaseOrderId: string;
}

export const LineItemsTable: React.FC<ILineItemsTable> = ({purchaseOrderId}) => {
    const {t} = useTranslation();

    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loadingData = useSelector((state: RootState) => state.poTermsAcceptance.detailsLoading);
    const currentPage = useSelector((state: RootState) => state.poTermsAcceptance.detailsPage);
    const limit = useSelector((state: RootState) => state.poTermsAcceptance.detailsLimit);
    const lineItemsDetails: ILineItem[] = useSelector(
        (state: RootState) => state.poTermsAcceptance.detailsData
    );
    const detailsTotalPages = useSelector(
        (state: RootState) => state.poTermsAcceptance.detailsTotalPages
    );
    const filters = useSelector((state: RootState) => state.poTermsAcceptance.filters);

    const colProps: IConProp[] = [
        {
            id: 0,
            size: 1,
            align: 'center',
            name: t('terms_acceptance.table.headers.details.position'),
        },
        {
            id: 1,
            size: 2,
            align: 'center',
            name: t('terms_acceptance.table.headers.details.sku'),
        },
        {
            id: 2,
            size: 4,
            align: 'center',
            name: t('terms_acceptance.table.headers.details.description'),
        },
        {
            id: 3,
            size: 1,
            align: 'center',
            name: t('terms_acceptance.table.headers.details.unit'),
        },
        {
            id: 4,
            size: 2,
            align: 'center',
            name: t('terms_acceptance.table.headers.details.amount'),
        },
        {
            id: 5,
            size: 2,
            align: 'center',
            name: t('terms_acceptance.table.headers.details.delivery_date'),
        },
    ];

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(
            fetchGetPoLineItems({
                token,
                page: value,
                limit,
                filters: {
                    ...filters,
                    purchaseOrderId: filters.purchaseOrderId ?? purchaseOrderId,
                    endDate: DateTime.fromJSDate(new Date(filters.dateEnd)).toFormat('yyyy-MM-dd'),
                    initDate: DateTime.fromJSDate(new Date(filters.dateInit)).toFormat(
                        'yyyy-MM-dd'
                    ),
                },
            })
        );
    };

    const handleChangeRowsPerPage = (event: any) => {
        dispatch(
            fetchGetPoLineItems({
                token,
                page: 1,
                limit: event.target.value,
                filters: {
                    ...filters,
                    purchaseOrderId: filters.purchaseOrderId ?? purchaseOrderId,
                    endDate: DateTime.fromJSDate(new Date(filters.dateEnd)).toFormat('MM-dd-yyyy'),
                    initDate: DateTime.fromJSDate(new Date(filters.dateInit)).toFormat(
                        'MM-dd-yyyy'
                    ),
                },
            })
        );
    };

    const getHeadcellStile = (key: number) => {
        const stile = {
            borderLeft: '',
            borderRight: '',
        };

        const last = colProps.slice(-1)[0];
        if (key === colProps[0].id) stile.borderLeft = '1px solid #C2D1D9';
        else if (last && key === last.id) stile.borderRight = '1px solid #C2D1D9';
        return stile;
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            overflowX: {xs: 'auto', md: 'hidden'},
                            display: 'block',
                            ...customScrollbar,
                        }}
                    >
                        <TableContainer
                            container
                            sx={{
                                width: {xs: 'max-content', md: '100%'},
                                borderRight: '1px solid #C2D1D9',
                            }}
                        >
                            {colProps.map((col) => (
                                <Grid
                                    key={col.id}
                                    item
                                    xs={col.size}
                                    display="grid"
                                    sx={{
                                        ...getHeadcellStile(col.id),
                                        borderRight: '1px solid #C2D1D9',
                                    }}
                                >
                                    <TableCellHead
                                        sx={{
                                            textAlign: col.align,
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        <Box sx={{width: '100%'}}>{col.name}</Box>
                                    </TableCellHead>
                                </Grid>
                            ))}

                            <Grid item xs={12} display="grid">
                                <TableCellChildrenSmall
                                    sx={{
                                        borderLeft: '1px solid #C2D1D9',
                                        minHeight: '19px',
                                    }}
                                >
                                    <Box sx={{width: '100%'}} />
                                </TableCellChildrenSmall>
                            </Grid>

                            {loadingData ? (
                                <PurchaseOrdersLoader colProps={colProps} />
                            ) : lineItemsDetails ? (
                                lineItemsDetails
                                    .filter((item: ILineItem) => item !== null)
                                    .map((item: ILineItem, key: number) => {
                                        return (
                                            <LineItemRow
                                                key={`${item.id}-${key}`}
                                                item={item}
                                                colProps={colProps}
                                            />
                                        );
                                    })
                            ) : null}
                            <Grid item xs={12} display="grid">
                                <TableCellChildrenSmall
                                    sx={{
                                        borderLeft: '1px solid #C2D1D9',
                                        borderBottom: '1px solid #C2D1D9',
                                        minHeight: '19px',
                                    }}
                                >
                                    <Box sx={{width: '100%'}} />
                                </TableCellChildrenSmall>
                            </Grid>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
            {!loadingData && detailsTotalPages > 1 && (
                <CustomPagination
                    totalPages={detailsTotalPages}
                    currentPage={currentPage}
                    handleChange={handleChangePage}
                    limit={limit}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </>
    );
};
