import React from 'react';

import Paragraph from '@/components/terms/components/Paragraph';
import {TableParagraph} from '@/components/terms/components/TableParagraph';

interface TableCell {
    value: string;
    rowSpan?: number;
}
interface tableParagraph {
    type: string;
    header: string[];
    body: TableCell[][];
    mergeCells?: boolean;
}
type Paragraph = string | tableParagraph;
interface IContent {
    lang: {
        title: string;
        paragraphs: Paragraph[];
    }[];
}

function LangContent({lang}: IContent): React.ReactElement {
    return (
        <>
            {lang.map((term, index) => (
                <div key={index}>
                    <h2>{term.title}</h2>
                    {term.paragraphs.map((content, index) => {
                        if (content instanceof Object) {
                            return (
                                <TableParagraph
                                    key={index}
                                    header={content.header}
                                    body={content.body}
                                />
                            );
                        } else {
                            return <Paragraph key={index}>{content}</Paragraph>;
                        }
                    })}
                </div>
            ))}
        </>
    );
}

export default LangContent;
