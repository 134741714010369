import {Box, Typography, useTheme} from '@mui/material';
import React, {useEffect} from 'react';
import logoAbi from '@login/images/logoAbi.svg';
import background from '@login/images/laptop_abi.svg';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ButtonAlternative} from '@/components/UI/atoms/buttons/ButtonAlternative';
import TextField from '@mui/material/TextField';
import {Controller} from 'react-hook-form';
import {ColorButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import {
    fetchPostCreateMdWeb,
    setMDPreloadData,
    getInfoUsers,
} from '@login/redux/actions/LoginActions';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import AuthFooter from '@/components/UI/organisms/footer/AuthFooter';

const atLeastOneNum = /[0-9]/;
const atLeastOneSpecial = /[\W|_]/;
const atLeastOneLowLetter = /[a-z]/;
const atLeastOneCapLetter = /[A-Z]/;
const atOnlyChartersAndSpaces = /^[a-zA-Z\s]+$/;
const isValidRfcRegexp =
    /* eslint-disable-next-line */
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const isValidPhone = /^\d{10}$/;

export const ButtonBack = styled('button')(({theme}) => ({
    backgroundColor: '#c5c9c6',
    borderRadius: '4px',
    color: 'black',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: '40px',
    height: '40px',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
}));

interface IFormEnterprise {
    name: string;
    phone: string;
    email: string;
    password: string;
    taxId: string;
    passwordConfirmation: string;
    industry: string;
}

const Mdweb = () => {
    const navigate = useNavigate();
    const mdPreloadData = useSelector((state: RootState) => state.logins.mdPreloadData);
    const flagPerfil = useSelector((state: RootState) => state.perfil.flagPerfil);
    const tokenflag = useSelector((state: RootState) => state.logins.tokenflag);
    const dispatch: AppDispatch = useDispatch();
    const error = useSelector((state: RootState) => state.logins.error);
    const theme = useTheme();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const schemaFormModal = yup.object({
        name: yup
            .string()
            .required('Nombre requerido')
            .matches(atOnlyChartersAndSpaces, 'Solo caracteres y espacios (Sin números)'),
        phone: yup
            .string()
            .required('Teléfono requerido')
            .matches(isValidPhone, 'Formato de telefono inválido'),
        email: yup
            .string()
            .required('Correo requerido')
            .matches(isValidEmail, 'No es un correo válido.'),
        industry: yup
            .string()
            .required('Actividad economica requerida')
            .matches(atOnlyChartersAndSpaces, 'Solo caracteres y espacios (Sin números)'),
        taxId: yup
            .string()
            .required('RFC requerido')
            .matches(isValidRfcRegexp, 'Formato de RFC incorrecto'),
        password: yup
            .string()
            .min(8, 'Longitud mínima de 8 caracteres')
            .max(30, 'Longitud máxima de 30 caracteres')
            .required('Contraseña requerida')
            .matches(atLeastOneNum, 'Incluye al menos un número')
            .matches(atLeastOneLowLetter, 'Incluye al menos una minúscula')
            .matches(atLeastOneCapLetter, 'Incluye al menos una mayúscula')
            .matches(atLeastOneSpecial, 'Incluye al menos un caracter especial'),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), undefined], 'Las contraseñas deben ser iguales')
            .required('Confirma tu contraseña'),
    });

    const {
        handleSubmit,
        control,
        formState: {errors},
        setValue,
    } = useForm<IFormEnterprise>({resolver: yupResolver(schemaFormModal)});

    const onSubmit = (data: IFormEnterprise) => {
        const dataToSend = {
            email: data.email,
            name: data.name,
            rfc: data.taxId,
            phone: data.phone,
            industry: data.industry,
            password: data.password,
            type: mdPreloadData.type,
        };
        dispatch(fetchPostCreateMdWeb({payload: dataToSend}));
    };

    const gotToLogin = () => {
        dispatch(setMDPreloadData(null));
        window.location.href = 'login';
    };

    useEffect(() => {
        if (mdPreloadData) {
            setValue('name', mdPreloadData.name);
            setValue('taxId', mdPreloadData.taxId);
            setValue('email', mdPreloadData.email);
        }
    }, [mdPreloadData]);

    useEffect(() => {
        if (flagPerfil) {
            navigate('/home');
        }
    }, [flagPerfil]);

    useEffect(() => {
        if (tokenflag) {
            dispatch(getInfoUsers(token));
        }
    }, [tokenflag]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            }}
        >
            <Box
                sx={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    '@media (min-height: 800px)': {
                        height: 'calc(100vh - 70px)',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '51vw',
                        background: theme.palette.primary.main,
                        paddingTop: '80px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#FFFFFF',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 500,
                        }}
                    >
                        Bienvenido al
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '40px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                        }}
                    >
                        Portal de proveedores
                    </Typography>
                    <img src={background} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '30px 0 0 0',
                        width: '49vw',
                    }}
                >
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <img width="184px" src={logoAbi} />
                        <Typography
                            sx={{
                                fontSize: '23px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 600,
                                marginTop: '28px',
                            }}
                        >
                            Información para MD Web
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 400,
                                margin: ' 10px 0 28px 0',
                            }}
                        >
                            Por favor captura los siguientes datos:{' '}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                width: '100%',
                            }}
                        >
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Nombre o Razón Social"
                                        placeholder="Inserta una respuesta"
                                        size="small"
                                    />
                                )}
                                name="name"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.name?.message}
                            </Typography>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="RFC"
                                        placeholder="Inserta una respuesta"
                                        size="small"
                                    />
                                )}
                                name="taxId"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.taxId?.message}
                            </Typography>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        id="outlined-required"
                                        label="Actividad Economica"
                                        placeholder="Inserta una respuesta"
                                        size="small"
                                    />
                                )}
                                name="industry"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.industry?.message}
                            </Typography>
                            <Controller
                                render={({field}) => (
                                    <TextField {...field} label="Correo electrónico" size="small" />
                                )}
                                name="email"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.email?.message}
                            </Typography>
                            <Controller
                                render={({field}) => (
                                    <TextField {...field} label="Teléfono" size="small" />
                                )}
                                name="phone"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.phone?.message}
                            </Typography>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Contraseña"
                                        size="small"
                                        type="password"
                                    />
                                )}
                                name="password"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.password?.message}
                            </Typography>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Confirmar contraseña"
                                        size="small"
                                        type="password"
                                    />
                                )}
                                name="passwordConfirmation"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.passwordConfirmation?.message}
                            </Typography>

                            {error && (
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 300,
                                        color: 'red',
                                        marginBottom: '14px',
                                    }}
                                >
                                    {error.message}
                                </Typography>
                            )}
                        </Box>
                        <ColorButton type={'submit'} style={{marginTop: '10px'}}>
                            Enviar
                        </ColorButton>
                        <ButtonAlternative onClick={() => gotToLogin()}>
                            Iniciar sesión
                        </ButtonAlternative>
                    </Box>
                </Box>
            </Box>
            <AuthFooter />
        </Box>
    );
};

export default Mdweb;
