import {TypographyStyled500} from '@/components/faqs/StyledFAQ';
import {getCfdiValidation} from '@components/orderSimply/utils/cfdiValidations';
import {Box} from '@mui/system';
import React from 'react';
import {useTranslation} from 'react-i18next';

type ValidationErrorProps = {
    errorInfo: ErrorInfo;
};

export interface ErrorInfo {
    poReferenceId: number;
    entrySheets: EntrySheet[];
}

export interface EntrySheet {
    entrySheet: any;
    errors: any[];
}

const ValidationErrorMassiveJob: React.FC<ValidationErrorProps> = ({errorInfo}) => {
    const {t} = useTranslation();
    const {poReferenceId, entrySheets} = errorInfo;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                minHeight: '20px',
                padding: '10px',
                borderBottom: '1px solid grey',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    minHeight: '50px',
                }}
            >
                <TypographyStyled500 sx={{marginLeft: '10px', minWidth: '180px'}}>
                    {poReferenceId}
                </TypographyStyled500>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {entrySheets.map((item, index) => (
                    <div
                        key={item.entrySheet.position ?? item.entrySheet}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderBottom:
                                index === entrySheets.length - 1 ? 'none' : '1px solid #c5d1d8',
                            alignContent: 'space-between',
                            padding: '10px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <TypographyStyled500>
                                {item.entrySheet.position ?? item.entrySheet}
                            </TypographyStyled500>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                                marginLeft: '6%',
                            }}
                        >
                            {item.errors.length === 0 ? (
                                <span key="success">
                                    ○ {t('modal_massive_job.successful_validation')}
                                </span>
                            ) : (
                                item.errors.map((error: string, index) => {
                                    const errorString = error.includes('invalid_item_status-')
                                        ? error.split('-')[0]
                                        : error;
                                    const errorInfo = getCfdiValidation(errorString);
                                    return (
                                        <span key={index}>
                                            ○ {t(errorInfo.description) ?? errorInfo.description}
                                        </span>
                                    );
                                })
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Box>
    );
};

export default ValidationErrorMassiveJob;
