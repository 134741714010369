import {IFilter, IUser} from '@/components/user/interface';
import {
    DELETE_USER,
    DELETE_USER_ERROR,
    DELETE_USER_SUCCESS,
    GET_USER,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    POST_USER,
    POST_USER_ERROR,
    POST_USER_SUCCESS,
    SET_FILTERS_SUPPLIER,
    SET_FILTERS_USER,
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_STATUS,
    UPDATE_USER_STATUS_ERROR,
    UPDATE_USER_STATUS_SUCCESS,
    UPDATE_USER_SUCCESS,
} from '@/components/user/redux/types/UserTypes';

interface IInitialState {
    users: IUser[];
    user: IUser | null;
    count: number;
    page: number;
    totalPages: number;
    loading: boolean;
    error: boolean;
    limit: number;
    filtersUser: IFilter;
    filtersSupplier: IFilter;
}

const initialState: IInitialState = {
    users: [],
    user: null,
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
    filtersUser: {},
    filtersSupplier: {},
};

const user = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                loading: true,
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_USERS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_USER:
            return {
                ...state,
                loading: true,
            };
        case POST_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_USER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_USER:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_USER_STATUS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_USER_STATUS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case DELETE_USER:
            return {
                ...state,
                loading: true,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_USER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_USER:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.value.data,
                loading: false,
            };
        case GET_USER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case SET_FILTERS_USER:
            return {
                ...state,
                filtersUser: action.value,
            };
        case SET_FILTERS_SUPPLIER:
            return {
                ...state,
                filtersSupplier: action.value,
            };
        default:
            return state;
    }
};

export default user;
