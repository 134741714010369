export const GET_USER_VALIDATION_ADMIN = 'GET_USER_VALIDATION_ADMIN';
export const GET_USER_VALIDATION_ADMIN_SUCCESS = 'GET_USER_VALIDATION_ADMIN_SUCCESS';
export const GET_USER_VALIDATION_ADMIN_ERROR = 'GET_USER_VALIDATION_ADMIN_ERROR';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const GET_FORGOT_PASSWORD = 'GET_FORGOT_PASSWORD';
export const GET_FORGOT_PASSWORD_SUCCESS = 'GET_FORGOT_PASSWORD_SUCCESS';
export const GET_FORGOT_PASSWORD_ERROR = 'GET_FORGOT_PASSWORD_ERROR';
export const PUT_UPDATE_PASSWORD = 'PUT_UPDATE_PASSWORD';
export const PUT_UPDATE_PASSWORD_SUCCESS = 'PUT_UPDATE_PASSWORD_SUCCESS';
export const PUT_UPDATE_PASSWORD_ERROR = 'PUT_UPDATE_PASSWORD_ERROR';
export const POST_ENTERPRISE = 'POST_ENTERPRISE';
export const POST_ENTERPRISE_SUCCESS = 'POST_ENTERPRISE_SUCCESS';
export const POST_ENTERPRISE_ERROR = 'POST_ENTERPRISE_ERROR';
export const LOGOUT = 'LOGOUT';
export const GET_USER_INFO_ABI = 'GET_USER_INFO_ABI';
export const GET_HISTORY_URL = 'GET_HISTORY_URL';
export const SET_CREATE_ENTERPRISE_SUCCESS = 'SET_CREATE_ENTERPRISE_SUCCESS';
export const SET_ERROR = 'SET_ERROR';
export const SET_MD_PRELOAD_DATA = 'SET_MD_PRELOAD_DATA';
export const POST_MDWEB = 'POST_MDWEB';
export const POST_MDWEB_SUCCESS = 'POST_MDWEB_SUCCESS';
export const POST_MDWEB_ERROR = 'POST_MDWEB_ERROR';
export const SET_MODAL_ALERT = 'SET_MODAL_ALERT';
export const SET_ORDER_ALERT = 'SET_ORDER_ALERT';
export const SET_CSF_ALERT = 'SET_CSF_ALERT';
export const NEX_STEP_SUPPLIER = 'NEX_STEP_SUPPLIER';
