import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import AlertDelete from '@components/UI/atoms/icons/AlertDelete.svg';
import {
    ImgIcon,
    ButtonCancel,
    SaveButton,
    StyledModal,
    Nav,
    ImgIconClose,
} from '@components/faqs/StyledFAQ';
import {useTranslation} from 'react-i18next';
import IconCloseModal from '@components/UI/atoms/icons/IconCloseModal.svg';

const DeleteModal = ({isModalOpen, closeModal, onSubmitDelete, message = ''}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    height: '501px',
                    zIndex: '100',
                }}
            >
                <Nav sx={{justifyContent: 'end'}}>
                    <ImgIconClose
                        src={IconCloseModal}
                        onClick={closeModal}
                        sx={{paddingRight: '15px'}}
                    />
                </Nav>
                <Box
                    sx={{
                        display: 'flex',
                        padding: '40px',
                        flexDirection: 'column',
                        height: '150px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 400,
                            color: '#000000',
                            textAlign: 'center',
                        }}
                    >
                        {message || t('faqs.confirm_delete_questions')}
                    </Typography>
                    <ImgIcon src={AlertDelete} />
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 500,
                            color: '#515151',
                            textAlign: 'center',
                            marginTop: '20px',
                        }}
                    >
                        {t('faqs.cannot_repeat')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginTop: '60px',
                        }}
                    >
                        <ButtonCancel onClick={closeModal}>
                            {t('cancel').toUpperCase()}
                        </ButtonCancel>
                        <SaveButton onClick={onSubmitDelete}>
                            {t('delete').toUpperCase()}
                        </SaveButton>
                    </Box>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default DeleteModal;
