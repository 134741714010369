'use client';
import {ButtonCancel} from '@/components/orderSimply/Styled';
import {RootState} from '@/config/store';
import {useFormatNumber} from '@hooks/useFormatNumber';
import {CheckCircleOutline} from '@mui/icons-material';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Skeleton,
} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

export type DetailsFilesXmlAgentsProps = {
    closeModal: () => void;
};
interface responseValidation {
    uuid: string;
    amounts: number;
    customRequestNumber: string;
    errors: Record<string, any>;
}
const DetailsFilesXmlAgents: React.FC<DetailsFilesXmlAgentsProps> = ({closeModal}) => {
    const xmlsOrderAgents = useSelector((state: RootState) => state.orderssimply.xmlsOrderAgents);
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const {t} = useTranslation();
    const formatNumber = useFormatNumber();

    return (
        <>
            <Box
                display="flex"
                p={1}
                mt={2}
                flexDirection={'column'}
                sx={{justifyContent: 'space-between'}}
                minWidth={'600px'}
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                }}
            >
                <List
                    sx={{
                        border: '2px solid #c5d1d8',
                        width: '100%',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 200,
                        '& ul': {padding: 0},
                        margin: '10px 0px',
                        padding: '0px',
                    }}
                    subheader={<li />}
                    dense
                >
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutline color="success" />
                        </ListItemIcon>
                        <ListItemText primary={t('poagents.without_errors')} />
                    </ListItem>
                </List>
                <List
                    sx={{
                        border: '2px solid #c5d1d8',
                        width: '100%',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 200,
                        '& ul': {padding: 0},
                        margin: '10px 0px',
                        padding: '0px',
                    }}
                    subheader={<li />}
                    dense
                >
                    {loading && (
                        <li key={`section-`}>
                            <ul>
                                <ListSubheader
                                    sx={{
                                        borderTop: '1px solid #c5d1d8',
                                        borderBottom: '1px solid #c5d1d8',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            height: '48px',
                                            padding: '16px 0px',
                                        }}
                                    >
                                        <Skeleton sx={{fontSize: '1rem', width: '200px'}} />
                                    </Box>
                                </ListSubheader>
                                {[0, 1].map((item) => (
                                    <ListItem key={`item-0-${item}`}>
                                        <ListItemIcon>
                                            <Skeleton variant="circular">
                                                <Box sx={{width: '24px', height: '24px'}} />
                                            </Skeleton>
                                        </ListItemIcon>
                                        <Skeleton
                                            variant="text"
                                            sx={{fontSize: '1rem', width: '300px'}}
                                        />
                                    </ListItem>
                                ))}
                            </ul>
                        </li>
                    )}
                    {!loading &&
                        xmlsOrderAgents.length > 0 &&
                        xmlsOrderAgents.map(
                            ({uuid, amounts, customRequestNumber, errors}: responseValidation) => (
                                <li key={`section-${uuid}`}>
                                    <ul>
                                        <ListSubheader
                                            sx={{
                                                borderTop: '1px solid #c5d1d8',
                                                borderBottom: '1px solid #c5d1d8',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={t('uuid')}
                                                    sx={{textAlign: 'center', width: '33%'}}
                                                />
                                                <ListItemText
                                                    primary={t('pediment')}
                                                    sx={{textAlign: 'center', width: '33%'}}
                                                />
                                                <ListItemText
                                                    primary={t('amount')}
                                                    sx={{textAlign: 'center', width: '33%'}}
                                                />
                                            </Box>
                                        </ListSubheader>

                                        <ListItem key={`item-${uuid}-`}>
                                            <ListItemText primary={uuid} sx={{width: '33%'}} />

                                            <ListItemText
                                                primary={customRequestNumber}
                                                sx={{width: '33%', textAlign: 'center'}}
                                            />

                                            <ListItemText
                                                primary={formatNumber(amounts)}
                                                sx={{width: '33%', textAlign: 'center'}}
                                            />
                                        </ListItem>
                                    </ul>
                                </li>
                            )
                        )}
                </List>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <ButtonCancel onClick={closeModal}>{t('close')}</ButtonCancel>
                {/* {hasErrors() && (
                    <SaveButton disabled={loading} onClick={onSubmit} sx={{marginRight: '30px'}}>
                        {loading ? <Loader size={24} /> : 'Ver Detalles'}
                    </SaveButton>
                )} */}
            </Box>
        </>
    );
};

export default DetailsFilesXmlAgents;
