import {CandidatesEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import {ACLObj} from '@components/auth/guard/AclGuard';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Box, Tab} from '@mui/material';
import React from 'react';
import PendingTable from '@components/candidates/PendingTable';
import ApprovedTable from '@components/candidates/ApprovedTable';
import RejectedTable from '@components/candidates/RejectedTable';
import {useTranslation} from 'react-i18next';
import {Can} from '@/context/AbilityContext';
import useCheckAbilities from '@/hooks/useCheckAbilities';
const Candidates: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const {allowed} = useCheckAbilities();
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab
                        label={t('candidates.pending')}
                        value="1"
                        disabled={
                            !allowed(
                                FeatureCodeEnum.CANDIDATES,
                                CandidatesEnum.VIEW_PENDING_CANDIDATES
                            )
                        }
                    />
                    <Tab
                        label={t('candidates.approved')}
                        value="2"
                        disabled={
                            !allowed(
                                FeatureCodeEnum.CANDIDATES,
                                CandidatesEnum.VIEW_APPROVED_CANDIDATES
                            )
                        }
                    />
                    <Tab
                        label={t('candidates.rejected')}
                        value="3"
                        disabled={
                            !allowed(
                                FeatureCodeEnum.CANDIDATES,
                                CandidatesEnum.VIEW_REJECTED_CANDIDATES
                            )
                        }
                    />
                </TabList>
            </Box>
            <Can I={CandidatesEnum.VIEW_PENDING_CANDIDATES} a={FeatureCodeEnum.CANDIDATES}>
                <TabPanel value="1">
                    <PendingTable />
                </TabPanel>
            </Can>
            <Can I={CandidatesEnum.VIEW_APPROVED_CANDIDATES} a={FeatureCodeEnum.CANDIDATES}>
                <TabPanel value="2">
                    <ApprovedTable />
                </TabPanel>
            </Can>
            <Can I={CandidatesEnum.VIEW_REJECTED_CANDIDATES} a={FeatureCodeEnum.CANDIDATES}>
                <TabPanel value="3">
                    <RejectedTable />
                </TabPanel>
            </Can>
        </TabContext>
    );
};
Candidates.acl = {
    action: CandidatesEnum.VIEW_CANDIDATES,
    subject: FeatureCodeEnum.CANDIDATES,
};

export default Candidates;
