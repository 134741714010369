import {styled} from '@mui/system';
import React from 'react';
const StyledStatus = styled('div')(({color, bgColor}) => ({theme}) => ({
    color: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: bgColor,
    fontSize: '9.5px',
    fontWeight: 600,
    fontFamily: theme?.typography?.fontFamily || 'Poppins',
    width: '90px',
    height: '30px',
    borderRadius: '4px',
}));

const StatusTextGenecic = ({status, text}) => {
    const getColor = (status) => {
        const statusColors = {
            DELIVERED: '#00ACFF',
            CREATED: '#694D00',
            REJECTED: '#FF5722',
            PROCESS_PENDING: '#fff',
            BILL_VALIDATED: '#fff',
            CLOSED: 'rgba(146, 26, 40, 1)',
            INVOICE_IN_PROCESS: 'rgba(0, 172, 255, 1)',
            PARTIALLY_DELIVERED: '#262626',
            SCHEDULED_TO_PAY: '#263238',
            PAID: '#fdfefe',

            REJECTED_INVOICE: '#260C05',
        };
        return statusColors[status] || '#4BBC68';
    };

    const color = getColor(status);

    const getColorFromHash = (status) => {
        const hashColors = {
            DELIVERED: 'rgba(0, 172, 255, 0.2)',
            CREATED: 'rgba(252, 248, 231, 0.82)',
            REJECTED: '#FFDDD3',
            BILL_VALIDATED: '#45B08C',
            CLOSED: 'rgba(244, 232, 233, 1)',
            INVOICE_IN_PROCESS: 'rgba(229, 247, 255, 1)',
            PARTIALLY_DELIVERED: '#FCF8E7',
            SCHEDULED_TO_PAY: '#aed6f1',
            PAID: '#7dcea0',

            REJECTED_INVOICE: '#FF7474',
        };
        return hashColors[status] || '#DBF2E1';
    };

    const bgColor = getColorFromHash(status);
    const borderColor = getColor(status);

    return (
        <StyledStatus color={color} bgColor={bgColor} borderColor={borderColor}>
            {text}
        </StyledStatus>
    );
};

export default StatusTextGenecic;
