import {IconButton, Tooltip} from '@mui/material';
import React from 'react';

interface IBtnSplit {
    actionFn: (...params: any[]) => any;
    title?: string;
}
const BtnSplit = (props: IBtnSplit) => {
    const {actionFn, title = ''} = props;
    return (
        <Tooltip title={title}>
            <IconButton aria-label="delete" onClick={actionFn}>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.5"
                        y="0.5"
                        width="39"
                        height="39"
                        rx="7.5"
                        fill="#D1A33C"
                        fillOpacity="0.2"
                    />
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#D1A33C" />
                    <g clipPath="url(#clip0_2699_28007)">
                        <path
                            d="M27.9036 24.8703C27.5574 24.6127 27.068 24.6847 26.8105 25.0308C26.6689 25.2212 26.5173 25.4079 26.36 25.5859C26.0742 25.9091 26.1046 26.4029 26.4278 26.6886C26.5765 26.82 26.7611 26.8846 26.945 26.8846C27.1611 26.8846 27.3762 26.7954 27.5306 26.6208C27.7169 26.41 27.8964 26.1889 28.0641 25.9634C28.3217 25.6172 28.2498 25.1278 27.9036 24.8703Z"
                            fill="#D1A33C"
                        />
                        <path
                            d="M29.2164 21.2065C28.795 21.1148 28.3786 21.3821 28.2868 21.8037C28.2363 22.0356 28.1753 22.268 28.1053 22.4944C27.978 22.9066 28.2089 23.3441 28.6211 23.4714C28.698 23.4951 28.7756 23.5064 28.852 23.5064C29.1856 23.5064 29.4945 23.291 29.5982 22.9556C29.6812 22.6869 29.7537 22.4112 29.8136 22.136C29.9053 21.7145 29.638 21.2983 29.2164 21.2065Z"
                            fill="#D1A33C"
                        />
                        <path
                            d="M24.1451 27.3727C23.9376 27.4885 23.7229 27.5967 23.5068 27.6943C23.1135 27.8719 22.9387 28.3346 23.1163 28.7278C23.2468 29.0167 23.5311 29.1877 23.8288 29.1877C23.9363 29.1877 24.0455 29.1654 24.1499 29.1183C24.4062 29.0025 24.6608 28.8742 24.9068 28.7369C25.2835 28.5266 25.4184 28.0507 25.208 27.6739C24.9977 27.2973 24.5218 27.1624 24.1451 27.3727Z"
                            fill="#D1A33C"
                        />
                        <path
                            d="M19.2186 13.75V19.6764L16.3538 22.5412C16.0487 22.8463 16.0487 23.3409 16.3538 23.646C16.5064 23.7986 16.7063 23.8748 16.9063 23.8748C17.1062 23.8748 17.3062 23.7986 17.4587 23.646L20.5523 20.5524C20.6988 20.4059 20.7811 20.2072 20.7811 20V13.75C20.7811 13.3185 20.4313 12.9688 19.9999 12.9688C19.5684 12.9688 19.2186 13.3185 19.2186 13.75Z"
                            fill="#D1A33C"
                        />
                        <path
                            d="M29.2188 11.6797C28.7873 11.6797 28.4375 12.0295 28.4375 12.4609V14.6303C26.6191 11.7784 23.4434 10 20 10C17.3289 10 14.8177 11.0402 12.9289 12.9289C11.0402 14.8177 10 17.3289 10 20C10 22.6711 11.0402 25.1823 12.9289 27.0711C14.8177 28.9598 17.3289 30 20 30C20.0066 30 20.013 29.9992 20.0195 29.999C20.0261 29.9992 20.0325 30 20.0391 30C20.3206 30 20.605 29.9881 20.8843 29.9648C21.3142 29.9287 21.6336 29.551 21.5976 29.1211C21.5616 28.6911 21.1843 28.3716 20.7539 28.4077C20.5178 28.4275 20.2773 28.4375 20.0391 28.4375C20.0325 28.4375 20.0261 28.4383 20.0195 28.4385C20.013 28.4383 20.0066 28.4375 20 28.4375C15.3475 28.4375 11.5625 24.6525 11.5625 20C11.5625 15.3475 15.3475 11.5625 20 11.5625C22.9984 11.5625 25.7561 13.1607 27.2631 15.7031H25.115C24.6835 15.7031 24.3337 16.0529 24.3337 16.4844C24.3337 16.9159 24.6835 17.2656 25.115 17.2656H27.5C27.9758 17.2656 28.4209 17.1319 28.7999 16.9003C28.8243 16.8864 28.8477 16.8714 28.87 16.8553C29.55 16.408 30 15.6386 30 14.7656V12.4609C30 12.0295 29.6502 11.6797 29.2188 11.6797Z"
                            fill="#D1A33C"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_2699_28007">
                            <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(10 10)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </IconButton>
        </Tooltip>
    );
};

export default BtnSplit;
