import {mockPostNotificationFetch} from '@/components/notifications/data/mock-data';
import {
    GET_NOTIFICATION,
    GET_NOTIFICATION_ERROR,
    GET_NOTIFICATION_SUCCESS,
    NotificationItemType,
} from '@components/notifications/redux/type/notification-type';
import axios from 'axios';

export const getNotifications = () => {
    return {
        type: GET_NOTIFICATION,
    };
};

export const getNotificationsSuccess = (result: any) => {
    return {
        type: GET_NOTIFICATION_SUCCESS,
        value: result,
    };
};

export const getNotificationsError = (error: any, result?: any) => {
    return {
        type: GET_NOTIFICATION_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetNotifications(userType: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        try {
            dispatch(getNotifications());

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/sse/notifications/${userType}`,
                {
                    headers,
                }
            );
            dispatch(getNotificationsSuccess(response.data));
        } catch (error) {
            dispatch(getNotificationsError(false));
        }
    };
}

export function fetchPatchNotifications(token: string, userType: string) {
    return async (dispatch: any) => {
        try {
            dispatch(getNotifications());

            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/sse/notifications/view/all`,
                {},
                {headers: {Authorization: 'Bearer ' + token}}
            );
            dispatch(fetchGetNotifications(userType, token));
        } catch (error) {
            dispatch(getNotificationsError(false));
        }
    };
}
export function fetchPatchNotification(id: string, userType: string, token: string) {
    return async (dispatch: any) => {
        try {
            dispatch(getNotifications());

            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/sse/notifications/view`,
                {
                    id,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            );
            dispatch(fetchGetNotifications(userType, token));
        } catch (error) {
            dispatch(getNotificationsError(false));
        }
    };
}
export function postNotificationFetch(body: NotificationItemType) {
    return async (dispatch: any) => {
        try {
            dispatch(getNotifications());

            const response = await mockPostNotificationFetch(body).then((res) => res.json());
            dispatch(getNotificationsSuccess(response.data));
        } catch (error) {
            dispatch(getNotificationsError(false));
        }
    };
}
