import {AcceptedTermcConditionsPO} from '@/components/UI/templates/AcceptedTermcConditionsPO';
import RejectTermcConditionsPO from '@/components/UI/templates/RejectTermcConditionsPO/RejectTermcConditionsPO';
import {RootState} from '@/config/store';
import IconCloseModal from '@components/UI/atoms/icons/IconCloseModal.svg';

import {ImgIconClose, Nav, StyledModal} from '@components/tickets/StyledTicket';
import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';

interface ModalTicketProps {
    isModalOpen: boolean;
    closeModal: () => void;
    method: string;
    message: string;
    listDelete: number[];
    item: any;
}
const ModalViewNotifications: React.FC<ModalTicketProps> = ({isModalOpen, closeModal}) => {
    const theme = useTheme();
    const data = useSelector((state: RootState) => state.binnacles.viewCurrenNofitication);
    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#EBF0F2',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: 'auto',
                    maxWidth: '740px',
                    height: '50%',
                    zIndex: '100',
                }}
            >
                <Nav sx={{justifyContent: 'space-between', height: '50px'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            paddingLeft: '15px',
                        }}
                    />
                    <ImgIconClose
                        src={IconCloseModal}
                        onClick={closeModal}
                        sx={{paddingRight: '15px'}}
                    />
                </Nav>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                    }}
                >
                    {data.acceptedTerms ? (
                        <AcceptedTermcConditionsPO />
                    ) : (
                        <RejectTermcConditionsPO />
                    )}
                </div>
            </Box>
        </StyledModal>
    );
};
export default ModalViewNotifications;
