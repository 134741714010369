import {
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_ERROR,
    GET_COUNTRY,
} from '@/components/general/country/redux/types/CountryTypes';

import axios from 'axios';
export const getCountry = () => {
    return {
        type: GET_COUNTRY,
    };
};
export const getCountrySuccess = (result) => {
    return {
        type: GET_COUNTRY_SUCCESS,
        value: result,
    };
};
export const getCountryError = (error) => {
    return {
        type: GET_COUNTRY_ERROR,
        value: error,
    };
};

export function fetchGetCountry(token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getCountry());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/catalogs/countries`,
                {headers}
            );
            dispatch(getCountrySuccess(response.data));
        } catch (error) {
            dispatch(getCountryError(true));
        }
    };
}
