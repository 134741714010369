export const POST_ORDER = 'POST_ORDER';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_ERROR = 'POST_ORDER_ERROR';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';
export const GET_ORDER_INCOMPLETE = 'GET_ORDER_INCOMPLETE';
export const GET_ORDER_INCOMPLETE_SUCCESS = 'GET_ORDER_INCOMPLETE_SUCCESS';
export const GET_ORDER_INCOMPLETE_ERROR = 'GET_ORDER_INCOMPLETE_ERROR';
export const RESET_ORDER_INCOMPLETE_SUCCESS = 'RESET_ORDER_INCOMPLETE_SUCCESS';
export const RESET_ORDER_SUCCESS = 'RESET_ORDER_SUCCESS';
