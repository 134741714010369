import {
    GET_PERFIL,
    GET_PERFIL_ERROR,
    GET_PERFIL_SUCCESS,
    UPDATE_PERFIL,
    UPDATE_PERFIL_ERROR,
    UPDATE_PERFIL_SUCCESS,
    SET_LOAD_PROFILE_OFF,
} from '@/components/perfil/redux/types/PerfilTypes';

import axios from 'axios';
export const getPerfil = () => {
    return {
        type: GET_PERFIL,
    };
};
export const getPerfilSuccess = (result) => {
    return {
        type: GET_PERFIL_SUCCESS,
        value: result.data,
    };
};
export const getPerfilError = (error, result) => {
    return {
        type: GET_PERFIL_ERROR,
        value: error,
        payload: result,
    };
};

export const setLoadProfileOff = () => {
    return {
        type: SET_LOAD_PROFILE_OFF,
        value: false,
    };
};

export function fetchGetPerfil(token, id) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getPerfil());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/${id}/settings`,
                {headers}
            );
            dispatch(getPerfilSuccess(response.data));
        } catch (error) {
            dispatch(getPerfilError(true));
        }
    };
}

export const putPerfil = () => {
    return {
        type: UPDATE_PERFIL,
    };
};
export const putPerfilSuccess = (result) => {
    return {
        type: UPDATE_PERFIL_SUCCESS,
        value: result,
    };
};
export const putPerfilError = (error, result) => {
    return {
        type: UPDATE_PERFIL_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutPerfil(token, enterpriseId, catalogId, data) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putPerfil());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/${enterpriseId}/settings/${catalogId}`,
                data,
                {headers}
            );
            dispatch(putPerfilSuccess(response.data.data));
            dispatch(fetchGetPerfil(token, enterpriseId));
        } catch (error) {
            dispatch(putPerfilError(true));
        }
    };
}
