import {AppDispatch, RootState} from '@/config/store';
import {Box, Button, CircularProgress, Typography, useTheme} from '@mui/material';
import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {TypographyStyled600} from '@/components/faqs/StyledFAQ';
import {ITermsModalConfirm} from '@/components/orderSimply/interfaces/download-terms.interface';
import {TermsModal} from '@/components/terms/modals';
import {
    HIDE_TERMS_MODAL,
    VERIFY_TERMS_AND_COMPLETE_ACTION,
} from '@/components/orderSimply/redux/types/OrdersSimplyTypes';
import {ConfirmDeliveryScheduleInvoiceEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {FF_SHOW_PO_DETAILS} from '@/utils/envvars';
import {UserType} from '@/components/user/interface';

interface ConfirmActionDefaultProps {
    acceptedTerms: boolean;
    purchaseOrderId: string;
    isDisableAction: boolean;
    setIsRejectModalOpen: (value: boolean) => void;
    afterAction?: () => void;
}

const ConfirmActionDefault: FunctionComponent<ConfirmActionDefaultProps> = ({
    acceptedTerms,
    purchaseOrderId,
    isDisableAction,
    setIsRejectModalOpen,
    afterAction,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation();
    const theme = useTheme();
    const {orderId, group} = useParams();
    const navigate = useNavigate();
    const {notAllowed} = useCheckAbilities();

    const {termsModal}: {orderssimply: any; termsModal: ITermsModalConfirm} = useSelector(
        (state: RootState) => state.orderssimply
    );
    const {user} = useSelector((state: RootState) => state.logins);
    const isSupplier = user.type === UserType.SUPPLIER;

    const [isServiceOrder, setIsServiceOrder] = React.useState<boolean>(false);
    const {purchaseOrder, loading} = useSelector(
        (state: RootState) => state.purchaceOrderReducers ?? {purchaseOrder: {}, loading: false}
    );
    const materialType = purchaseOrder ? purchaseOrder.materialType : null;

    const [onFulfill, setOnFulfill] = React.useState<() => void>(() => () => {});

    const goToUrl = (url: string) => {
        navigate(url);
    };

    const hideTermsModal = (event?: {preventDefault: () => void}, reason?: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            if (event) {
                event.preventDefault();
            }
            return;
        }
        dispatch({type: HIDE_TERMS_MODAL});
    };

    const handleActionFulfillment = (
        externalId: string,
        acceptedTerms: boolean,
        completeAction: () => void
    ) => {
        if (isSupplier) {
            handleSupplierActionFulfillment(externalId, acceptedTerms, completeAction);
            return;
        }
        completeAction();
    };

    const handleSupplierActionFulfillment = (
        externalId: string,
        acceptedTerms: boolean,
        completeAction: () => void
    ) => {
        dispatch({
            type: VERIFY_TERMS_AND_COMPLETE_ACTION,
            payload: {
                externalIds: [externalId],
                purchaseOrderIds: [orderId],
                isTermsConditionsAccepted: acceptedTerms,
            },
            completeAction: completeAction,
        });
        setOnFulfill(() => completeAction);
    };

    useEffect(() => {
        if (materialType !== null) {
            setIsServiceOrder('SERVICE' === materialType);
        }
    }, [materialType]);
    const reviewCaption = isServiceOrder
        ? t('confirmposimply.review_caption_service')
        : t('confirmposimply.review_caption');

    return (
        <Box
            sx={{
                height: '75vh',
                minWidth: '580px',
                maxWidth: '100%',
                padding: '15px 23px',
                width: '100%',
            }}
        >
            {!FF_SHOW_PO_DETAILS && (
                <Box display="flex" justifyContent="flex-start" sx={{paddingTop: '50px'}}>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                        }}
                    >
                        {t('confirmposimply.new_purchase_order')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: '#CD9F28',
                            marginLeft: '10px',
                        }}
                    >
                        # {purchaseOrderId}
                    </Typography>
                </Box>
            )}
            <Box
                sx={{
                    borderRadius: '3px',
                    border: '1px solid #C2D1D9',
                    height: '218px',
                    background: '#FFFFFF',
                    marginTop: '30px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 700,
                        textAlign: 'center',
                        marginTop: '50px',
                    }}
                >
                    {t('confirmposimply.estimated_provider')}:
                </Typography>
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                        marginTop: '20px',
                        textAlign: 'center',
                        padding: '0 50px',
                    }}
                >
                    {reviewCaption}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" sx={{marginTop: '30px'}}>
                {loading && <CircularProgress size="3rem" />}
                {!loading && !isServiceOrder && (
                    <Box
                        sx={{
                            display: loading ? 'none' : 'block',
                        }}
                        visibility={loading ? 'hidden' : 'visible'}
                    >
                        <Button
                            onClick={() =>
                                handleActionFulfillment(purchaseOrderId, acceptedTerms, () =>
                                    goToUrl(`/purchase-order/${orderId}/confirm/${group}`)
                                )
                            }
                            sx={{
                                backgroundColor: '#4BBC68',
                                textTransform: 'none',
                                color: '#FFFFFF',
                                width: '182px',
                                margin: '0 10px',
                            }}
                            disabled={
                                notAllowed(
                                    FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                    ConfirmDeliveryScheduleInvoiceEnum.REJECT_CONFIRM
                                ) || isDisableAction
                            }
                        >
                            <TypographyStyled600 sx={{margin: '5px'}}>
                                {t('confirmposimply.full_confirmation')}
                            </TypographyStyled600>
                        </Button>
                        <Button
                            onClick={() =>
                                handleActionFulfillment(purchaseOrderId, acceptedTerms, () =>
                                    goToUrl(`/purchase-order/${orderId}/partial/${group}`)
                                )
                            }
                            sx={{
                                backgroundColor: '#F5E003',
                                textTransform: 'none',
                                color: '#000000',
                                width: '182px',
                                margin: '0 10px',
                            }}
                            disabled={isDisableAction}
                        >
                            <TypographyStyled600 sx={{margin: '5px'}}>
                                {t('confirmposimply.partial_confirmation')}
                            </TypographyStyled600>
                        </Button>
                        <Button
                            onClick={() => setIsRejectModalOpen(true)}
                            sx={{
                                backgroundColor: '#921A28',
                                textTransform: 'none',
                                color: '#FFFFFF',
                                width: '182px',
                                margin: '0 10px',
                            }}
                            disabled={
                                notAllowed(
                                    FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                    ConfirmDeliveryScheduleInvoiceEnum.REJECT_CONFIRM
                                ) || isDisableAction
                            }
                        >
                            <TypographyStyled600 sx={{margin: '5px'}}>
                                {' '}
                                {t('decline')}
                            </TypographyStyled600>
                        </Button>
                        {group !== 'MARKETPLACE' && (
                            <Button
                                onClick={() =>
                                    handleActionFulfillment(purchaseOrderId, acceptedTerms, () =>
                                        goToUrl(`/purchase-order/${orderId}/confirm/success`)
                                    )
                                }
                                sx={{
                                    border: '2px solid #D1A33C',
                                    backgroundColor: '#FFFFFF',
                                    textTransform: 'none',
                                    color: '#D1A33C',
                                    margin: '0 10px',
                                }}
                                disabled={isDisableAction}
                            >
                                <TypographyStyled600 sx={{margin: '5px'}}>
                                    {t('confirmposimply.full_confirmation_according_po')}
                                </TypographyStyled600>
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
            <TermsModal
                isOpen={termsModal?.showTermsModal}
                onClose={() => hideTermsModal()}
                termsData={{
                    externalIds: termsModal?.selectedItem?.externalIds,
                    purchaseOrderIds: termsModal?.selectedItem?.purchaseOrderIds,
                }}
                onFulfill={() => onFulfill()}
                afterAction={afterAction}
            />
        </Box>
    );
};

export default ConfirmActionDefault;
