import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {TextButton, TextButtonDetails} from '@/components/UI/molecules/textButton/textButtons';
import CustomPagination from '@/components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@/config/store';
import {CandidatesEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import {ACLObj} from '@components/auth/guard/AclGuard';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {
    fetchGetCandidate,
    fetchGetDownloadCandidates,
    deleteCandidateSuccess,
} from '@components/candidates/redux/actions/CandidateActions';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Grid, IconButton, TextField, Tooltip, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import {ICandidate} from '@components/candidates/interface';
import CandidateDeleteModal from '@components/candidates/CandidateDeleteModal';
import CandidateStatusModal from '@components/candidates/CandidateStatusModal';
import {Can} from '@/context/AbilityContext';
import {TypographyStyledUser} from '@components/faqs/StyledFAQ';
const ApprovedTable: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const list = useSelector((state: RootState) => state.candidate.list);
    const currentPage = useSelector((state: RootState) => state.candidate.page);
    const limit = useSelector((state: RootState) => state.candidate.limit);
    const totalPages = useSelector((state: RootState) => state.candidate.totalPages);
    const loading = useSelector((state: RootState) => state.candidate.loading);
    const [candidate, setCandidate] = useState<ICandidate | null>(null);
    const [isModalStatusOpen, setModalStatusOpen] = useState<boolean>(false);
    const [isModalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const isCandidateDelete = useSelector((state: RootState) => state.candidate.deleteCandidate);
    const schemaForm = yup.object({
        social: yup.string(),
    });

    useEffect(() => {
        if (isCandidateDelete && candidate) {
            init();
            dispatch(deleteCandidateSuccess(false));
        }
    }, [isCandidateDelete]);

    const {handleSubmit, control, watch} = useForm({
        resolver: yupResolver(schemaForm),
    });

    const watchForm = watch();
    const handleChangeDownload = (format: any) => {
        const filters = watchForm;
        dispatch(fetchGetDownloadCandidates(token, format, filters, 'APPROVED'));
    };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const data: any = {
            page: value,
            limit: limit,
            status: 'APPROVED',
        };

        if (watchForm.social) {
            data['social'] = watchForm.social;
        }
        dispatch(fetchGetCandidate(token, data));
    };

    const handleChangeRowsPerPage = (event: any) => {
        const data: any = {
            page: 1,
            limit: event.target.value,
            status: 'APPROVED',
        };
        if (watchForm.social) {
            data['social'] = watchForm.social;
        }

        dispatch(fetchGetCandidate(token, data));
    };

    const onSubmit = () => {
        const json = {
            social: watchForm.social ? watchForm.social : null,
            page: 1,
            limit: limit,
            status: 'APPROVED',
        };
        dispatch(fetchGetCandidate(token, json));
    };
    useEffect(() => {
        init();
    }, [dispatch, token]);

    const init = () => {
        if (token) {
            const json = {
                page: 1,
                status: 'APPROVED',
            };
            dispatch(fetchGetCandidate(token, json));
        }
    };

    const setShowDeleteModal = (item: ICandidate) => {
        setCandidate(item);
        setModalDeleteOpen(true);
    };

    const setShowStatusModal = (item: ICandidate, action: string) => {
        setCandidate(item);
        setAction(action);
        setModalStatusOpen(true);
    };

    const closeDeleteModal = () => {
        setModalDeleteOpen(false);
    };

    const closeStatusModal = () => {
        setModalStatusOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('candidates.title1')}
                </Typography>
            </Box>
            <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        padding: '15px',
                        paddingBottom: '0',
                        height: '73px',
                        background: '#fff',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                    }}
                >
                    <Box sx={{display: 'flex', height: '50px', alignItems: 'center'}}>
                        <Controller
                            name="entrySheet"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('receptpo.table.headers.business_name')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                        <Can I={CandidatesEnum.FILTER_CANDIDATES} a={FeatureCodeEnum.CANDIDATES}>
                            {' '}
                            <TextButton type="submit" onClick={onSubmit}>
                                {t('components.filter_btn.title').toUpperCase()}
                            </TextButton>
                        </Can>
                    </Box>

                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                        <Can I={CandidatesEnum.EXPORT_CANDIDATES} a={FeatureCodeEnum.CANDIDATES}>
                            <TextButtonDetails
                                type="button"
                                onClick={() => {
                                    handleChangeDownload('csv');
                                }}
                            >
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src={IconDownload} />
                                    <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                        CSV
                                    </TypographyStyledUser>
                                </div>
                            </TextButtonDetails>
                        </Can>
                        <Can I={CandidatesEnum.EXPORT_CANDIDATES} a={FeatureCodeEnum.CANDIDATES}>
                            <TextButtonDetails
                                type="button"
                                onClick={() => {
                                    handleChangeDownload('xlsx');
                                }}
                            >
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src={IconDownload} />
                                    <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                        XLSX
                                    </TypographyStyledUser>
                                </div>
                            </TextButtonDetails>
                        </Can>
                    </Box>
                </Box>
            </Box>
            <TableContainerBinnance container>
                <TableHeadRow container>
                    <Grid item xs={2}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('candidates.name_or_business_name')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('industry')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('candidates.product_or_services')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('email')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('phone')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('candidates.approved_by')}
                        </TableCellHead>
                    </Grid>

                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('document')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHeadFinal sx={{fontSize: '9.5px'}}>
                            {t('actions')}
                        </TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading && (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                )}
                {!loading &&
                    list.map((item: ICandidate, index: number) => {
                        return (
                            <>
                                <Grid item xs={2}>
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.socialReason}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={2}>
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.industry}</TableCell>
                                </Grid>
                                <Grid item xs={1}>
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.product}</TableCell>
                                </Grid>
                                <Grid item xs={2}>
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.email}</TableCell>
                                </Grid>
                                <Grid item xs={1}>
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.phone}</TableCell>
                                </Grid>
                                <Grid item xs={2}>
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.approvedBy}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={1}>
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.url && (
                                            <Tooltip
                                                title={t(
                                                    'roles.modals.create_role_permission.download'
                                                )}
                                            >
                                                <a href={item.url} target="_blank" rel="noreferrer">
                                                    <DescriptionIcon sx={{color: '#00ACFF'}} />
                                                </a>
                                            </Tooltip>
                                        )}{' '}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={1}>
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        <Can
                                            I={CandidatesEnum.REJECTED_CANDIDATES}
                                            a={FeatureCodeEnum.CANDIDATES}
                                        >
                                            <Tooltip
                                                title={t(
                                                    'roles.modals.create_role_permission.rejected'
                                                )}
                                            >
                                                <IconButton
                                                    color="warning"
                                                    onClick={() =>
                                                        setShowStatusModal(item, 'REJECTED')
                                                    }
                                                >
                                                    <ThumbDownAltIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Can>
                                        <Can
                                            I={CandidatesEnum.DELETE_CANDIDATES}
                                            a={FeatureCodeEnum.CANDIDATES}
                                        >
                                            <Tooltip
                                                title={t(
                                                    'roles.modals.create_role_permission.delete'
                                                )}
                                            >
                                                <IconButton
                                                    aria-label="delete"
                                                    color="error"
                                                    onClick={() => setShowDeleteModal(item)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Can>
                                    </TableCell>
                                </Grid>
                            </>
                        );
                    })}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <CandidateStatusModal
                isModalOpen={isModalStatusOpen}
                closeModal={closeStatusModal}
                candidate={candidate}
                action={action}
            />
            <CandidateDeleteModal
                isModalOpen={isModalDeleteOpen}
                closeModal={closeDeleteModal}
                candidate={candidate}
            />
        </Box>
    );
};
ApprovedTable.acl = {
    action: CandidatesEnum.VIEW_APPROVED_CANDIDATES,
    subject: FeatureCodeEnum.CANDIDATES,
};
export default ApprovedTable;
