import React from 'react';
import {Grid, styled} from '@mui/material';

import {TableCell} from '@/components/UI/molecules/tables/TablePrincipal';

export const StyledTableCell = styled(TableCell)({
    fontSize: '9.5px',
    backgroundColor: 'transparent',
});

interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
    xs?: number;
    children: React.ReactNode;
    sx?: any;
}

const GridTableCell: React.FC<GridItemProps> = ({xs = undefined, children, ...props}) => {
    const {sx} = props;
    return (
        <Grid item xs={xs} display="grid" sx={sx} {...props}>
            <StyledTableCell>{children}</StyledTableCell>
        </Grid>
    );
};

export default GridTableCell;
