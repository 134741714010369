import {DateTime} from 'luxon';

export const parseDate = (date: Date | string | DateTime): DateTime => {
    let dateTime: DateTime = DateTime.now().startOf('month');
    if (date && date instanceof DateTime) dateTime = date;

    if (date && date instanceof Date) dateTime = DateTime.fromJSDate(date);
    if (date && typeof date === 'string') dateTime = DateTime.fromISO(date);

    return dateTime;
};
