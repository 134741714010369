import {FeatureCodeEnum, SupportStatusEnum} from '@/enums/permissions.enum';
import {ACLObj} from '@components/auth/guard/AclGuard';
import SkeletonRow from '@components/UI/molecules/tables/SkeletonRow';
import {
    TableCellHead,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
} from '@components/UI/molecules/tables/TablePrincipal';
import {TextButton} from '@components/UI/molecules/textButton/textButtons';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    Link,
    ListItemText,
    MenuItem,
    OutlinedInput,
    TableCell,
    Typography,
    useTheme,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {AppDispatch, RootState} from '@/config/store';
import {ModalTicket} from '@components/tickets/components/modals';

import {STATUS_TICKETS} from '@/components/tickets/constants/constants';
import {IFilter, ITicket} from '@/components/tickets/interfaces';
import {Can} from '@/context/AbilityContext';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import ModalHistoryTicket from '@components/tickets/components/modals/ModalHistoryTicket';
import {getActionKey, performActionValues, statusTextValues} from '@components/tickets/enums';
import {fetchGetTicket, fetchGetTickets} from '@components/tickets/redux/actions/TicketActions';
import {InputLabelRol, StyledSelect} from '@components/tickets/StyledTicket';
import {AddButton} from '@components/UI/atoms/buttons/ButtonPrincipal';
import StatusTextTicket from '@components/UI/atoms/buttons/StatusTextTicket';
import {yupResolver} from '@hookform/resolvers/yup';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';

interface ITable {
    question: string;
    answer: string;
}

export const defaultProcessString = (inputString: string, values = statusTextValues) => {
    const recordsKeys = inputString.split(',');
    const records = recordsKeys.map((key: string) => {
        return values[key];
    });

    const firstRecord = records[0];
    const remainingRecordsCount = records.length - 1;
    let remainingRecordsMessage = '';
    if (remainingRecordsCount > 0) {
        remainingRecordsMessage = `(+${remainingRecordsCount})`;
    }
    return {firstRecord, remainingRecordsMessage};
};

// The original response for the processString was maintained for consistence.
export const processString = (inputString: string) => {
    const data = defaultProcessString(inputString);
    return data.firstRecord + data.remainingRecordsMessage;
};

const tableStatusString = (inputString: string, t: any) => {
    const data = defaultProcessString(inputString);
    return t(`ticket.filter_status.${data.firstRecord}`) + data.remainingRecordsMessage;
};

const Tickets: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [checkAllStatus, setCheckAllStatus] = useState(false);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isModalOpenComment, setModalOpenComment] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const reqId = location.search && new URLSearchParams(location.search).get('id');

    const [listDelete] = useState<number[]>([]);
    const [method, setMethod] = useState<string>('');
    const [modifyItem] = useState<ITable>();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const tickets = useSelector((state: RootState) => state.tickets.tickets);
    const totalPages = useSelector((state: RootState) => state.tickets.totalPages);
    const detailscurrentPage = useSelector((state: RootState) => state.tickets.page);
    const loading = useSelector((state: RootState) => state.tickets.loading);
    const limit = useSelector((state: RootState) => state.tickets.limit);
    const [selectedStates, setSelectedStates] = useState<string[]>(Object.keys(statusTextValues));
    const {allowed} = useCheckAbilities();

    const schemaFormModal = yup.object({
        status: yup.string(),
    });
    const {handleSubmit, control, watch, setValue} = useForm<IFilter>({
        resolver: yupResolver(schemaFormModal),
        defaultValues: {
            status: Object.keys(statusTextValues).join(','),
        },
    });
    const watchForm = watch();
    const json = {
        status: watchForm.status ?? '',
    };
    const onSubmit = () => {
        dispatch(fetchGetTickets(token, 1, limit, json));
    };
    const openModal = (type: string) => {
        setModalOpen(true);
        setMethod(type);
    };
    const openModalComment = (item: any) => {
        removeQueryUrl();
        dispatch(fetchGetTicket(token, item.id));
        setModalOpenComment(true);
    };

    const closeModal = () => {
        removeQueryUrl();
        setModalOpen(false);
    };
    const closeModalComment = () => {
        setModalOpenComment(false);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetTickets(token, value, limit, json));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetTickets(token, detailscurrentPage, event.target.value, json));
    };

    const hanldeCheckAllStatus = () => {
        setCheckAllStatus((prev) => {
            cleanFilterStatus(!prev ? Object.keys(statusTextValues) : []);
            return !prev;
        });
    };
    const handleChangeStatus = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterStatus(value);
    };
    const cleanFilterStatus = (value: any) => {
        const selectedStatus =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));
        setSelectedStates(selectedStatus);
        let newArray = [];
        if (selectedStatus.length > 0) {
            newArray = selectedStatus.map((item: string) => {
                return Object.keys(statusTextValues).find((e: string) => e === item);
            });
        }
        setValue('status', newArray.length > 0 ? newArray.join(',') : '');
    };

    useEffect(() => {
        if (token) {
            setValue('status', Object.keys(statusTextValues).join(','));
            dispatch(
                fetchGetTickets(token, detailscurrentPage, limit, {
                    status: Object.keys(statusTextValues).join(','),
                })
            );
        }
    }, [token]);

    useEffect(() => {
        setCheckAllStatus(selectedStates.length === Object.keys(statusTextValues).length);
    }, [selectedStates]);
    useEffect(() => {
        if (reqId && tickets && tickets.length > 0) {
            dispatch(fetchGetTicket(token, reqId));
            setModalOpenComment(true);
        }
    }, [reqId, tickets]);
    const removeQueryUrl = () => {
        navigate(location.pathname);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'10px'}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px 0',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 400,
                        }}
                    >
                        {t('ticket.submit_request')}
                    </Typography>
                </Box>
                <Box>
                    <Can I={SupportStatusEnum.CREATE} a={FeatureCodeEnum.SUPPORT}>
                        <AddButton sx={{marginBottom: '20px'}} onClick={() => openModal('Nueva')}>
                            {t('ticket.new_request').toUpperCase()}
                        </AddButton>
                    </Can>
                </Box>
            </Grid>
            <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'20px'}
            >
                <Can I={SupportStatusEnum.FILTER} a={FeatureCodeEnum.SUPPORT}>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '15px',
                            height: 'auto',
                            background: '#fff',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <Box
                                component="form"
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{width: '100%'}}
                            >
                                <Controller
                                    render={({field, fieldState}) => (
                                        <FormControl>
                                            <InputLabelRol>
                                                {t('components.status_selector.title')}{' '}
                                            </InputLabelRol>
                                            <StyledSelect
                                                {...field}
                                                multiple
                                                value={selectedStates}
                                                onChange={handleChangeStatus}
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected: any) =>
                                                    tableStatusString(selected.join(','), t)
                                                }
                                                MenuProps={{
                                                    sx: {
                                                        '&& .Mui-selected': {
                                                            backgroundColor: '#FFFF',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: 'transparent',
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    value={'notCheck'}
                                                    onClick={() => {
                                                        setTimeout(() => {
                                                            hanldeCheckAllStatus();
                                                        }, 500);
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={checkAllStatus}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        }}
                                                    />
                                                    <ListItemText
                                                        primary={t(
                                                            'ticket.filter_status.SELECT_ALL'
                                                        )}
                                                    />
                                                </MenuItem>
                                                {Object.keys(statusTextValues).map(
                                                    (key: string, index: number) => (
                                                        <MenuItem key={index} value={key}>
                                                            <Checkbox
                                                                checked={
                                                                    selectedStates.indexOf(key) > -1
                                                                }
                                                                sx={{
                                                                    '&.Mui-checked': {
                                                                        color: theme.palette.primary
                                                                            .main,
                                                                    },
                                                                }}
                                                            />
                                                            <ListItemText
                                                                primary={t(
                                                                    `ticket.filter_status.${statusTextValues[key]}`
                                                                )}
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}
                                            </StyledSelect>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="status"
                                />
                            </Box>
                            <Box>
                                <TextButton type="submit" onClick={onSubmit}>
                                    {t('components.filter_btn.title').toUpperCase()}
                                </TextButton>
                            </Box>
                        </Box>
                    </Box>
                </Can>
            </Grid>

            <TableContainerBinnance container>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={2}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('ticket.application_no')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {' '}
                            {t('ticket.create_date')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('ticket.action_to_do')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('ticket.status')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('ticket.last_modified')}
                        </TableCellHead>
                    </Grid>

                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px', borderRight: '1px solid #C2D1D9'}}>
                            {' '}
                            {t('ticket.comments')}
                        </TableCellHead>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    tickets &&
                    tickets.length > 0 &&
                    tickets.map((item: ITicket, index: number) => (
                        <>
                            <Grid item xs={2} display="grid">
                                <TableCell sx={{fontSize: '9.5px', background: '#FFFFFF'}}>
                                    {item.id}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCell sx={{fontSize: '9.5px', background: '#FFFFFF'}}>
                                    {DateTime.fromISO(item.createdAt).toFormat(
                                        'dd-MM-yyyy HH:mm:ss'
                                    )}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCell sx={{fontSize: '9.5px', background: '#FFFFFF'}}>
                                    {t(
                                        `ticket.perform_action.${
                                            performActionValues[getActionKey(item.action)]
                                        }`
                                    )}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCell sx={{fontSize: '9.5px', background: '#FFFFFF'}}>
                                    <StatusTextTicket status={STATUS_TICKETS[item.status]} />
                                </TableCell>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCell sx={{fontSize: '9.5px', background: '#FFFFFF'}}>
                                    {DateTime.fromISO(item.updatedAt).toFormat(
                                        'dd-MM-yyyy HH:mm:ss'
                                    )}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCell sx={{fontSize: '9.5px', background: '#FFFFFF'}}>
                                    <Link
                                        onClick={() => {
                                            const isCreatedStatus =
                                                STATUS_TICKETS.created ===
                                                STATUS_TICKETS[item.status];

                                            if (
                                                allowed(
                                                    FeatureCodeEnum.SUPPORT,
                                                    SupportStatusEnum.EDIT
                                                ) &&
                                                !isCreatedStatus
                                            )
                                                openModalComment(item);
                                        }}
                                        sx={{
                                            color: '#00ACFF',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {item?.comments && item?.comments?.length > 0
                                                ? item?.comments[item?.comments?.length - 1]
                                                      ?.message
                                                : ''}
                                        </Typography>
                                    </Link>
                                </TableCell>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={detailscurrentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <ModalTicket
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                method={method}
                listDelete={listDelete}
                item={modifyItem}
                message={''}
            />
            {/* <ModalComment
                isModalOpen={isModalOpenComment}
                closeModal={closeModalComment}
                method={method}
                listDelete={listDelete}
                item={modifyItem}
                message={''}
            /> */}
            <ModalHistoryTicket
                isModalOpen={isModalOpenComment}
                closeModal={closeModalComment}
                method={method}
                listDelete={listDelete}
                item={modifyItem}
                message={''}
            />
        </Box>
    );
};

Tickets.acl = {
    action: SupportStatusEnum.SEE_LIST,
    subject: FeatureCodeEnum.SUPPORT,
};

export default Tickets;
