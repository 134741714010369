import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {styled, useTheme} from '@mui/material/styles';
import {
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {fetchGetPurchaseOrder} from '@/components/purchase-order/redux/actions/PurchaceOrderActions';
import {AppDispatch, RootState} from '@config/store';
import {Box, Button, Grid, TextField, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IItemSplit} from '@/components/purchase-order/confirmTotal/interface';
import {
    ILineDetail,
    IErrorSplit,
    IItemSplitMarket,
} from '@/components/purchase-order/confirmPartial/interface';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers';
import BtnReject from '@/components/purchase-order/confirmPartial/BtnReject';
import {useTranslation} from 'react-i18next';
import BtnSplit from '@/components/purchase-order/confirmPartial/BtnSplit';
import {DateTime} from 'luxon';
interface IConfirmPurchaseOrderPartialTableProps {
    internalId?: string;
    splits: {[key: string]: IItemSplitMarket[]};
    setSplit: React.Dispatch<
        React.SetStateAction<{
            [key: string]: IItemSplitMarket[];
        }>
    >;
    lines: {[key: string]: IItemSplit};
    setLines: React.Dispatch<
        React.SetStateAction<{
            [key: string]: IItemSplit;
        }>
    >;
    errorsLines: IErrorSplit[];
    setErrorsLines: React.Dispatch<React.SetStateAction<IErrorSplit[]>>;
    errorsSplits: IErrorSplit[];
    setErrorsSplits: React.Dispatch<React.SetStateAction<IErrorSplit[]>>;
}

const ConfirmPurchaseOrderTableMarket: React.FC<IConfirmPurchaseOrderPartialTableProps> = ({
    ...props
}) => {
    const {
        internalId,
        lines,
        setLines,
        errorsLines,
        setErrorsLines,
        splits,
        setSplit,
        errorsSplits,
        setErrorsSplits,
    } = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const lineItems = useSelector((state: RootState) => state.purchaceOrderReducers.lineItems);
    const loading = useSelector((state: RootState) => state.purchaceOrderReducers.loading);
    const inputLinesRefs = useRef<(HTMLInputElement | null)[][]>([]);
    const [poputate, setPopulate] = useState(false);
    const [showIsSplit, setShowIsSplit] = useState<string[]>([]);
    const inputRefs = useRef<(HTMLInputElement | null)[][]>([]);
    useEffect(() => {
        if (token && internalId) {
            setPopulate(false);
            dispatch(fetchGetPurchaseOrder(token, internalId));
        }
    }, [dispatch, token]);

    useEffect(() => {
        const newErrorsLines = errorsLines.map((item) => {
            const obj = Object.assign({});
            obj.id = item.id;
            obj.message = t('partial_conf_po.core.required_number_lote');
            return obj;
        });
        setErrorsLines(newErrorsLines);

        const newErrorsSplits = errorsSplits.map((item) => {
            const obj = Object.assign({});
            obj.id = item.id;
            obj.message = t('confirmposimply.amount_equal_requested');
            return obj;
        });
        setErrorsSplits(newErrorsSplits);
    }, [i18n.language]);

    const populateInputRefs = () => {
        lineItems.forEach(() => {
            const group: (HTMLInputElement | null)[] = [null, null, null];
            inputLinesRefs.current.push(group);
        });
        setPopulate(true);
    };

    useEffect(() => {
        if (lineItems && lineItems.length > 0) populateInputRefs();
    }, [lineItems]);

    const TableContainer = styled(Grid)(({width = 'calc(100vw - 80px)'}: {width?: string}) => ({
        width: width,
    }));

    const onChangeInputValue = (
        event: any,
        item: ILineDetail,
        idx: number,
        field: string,
        position: number
    ) => {
        setErrorsLines([]);
        if (event === null) return;

        const currentLine = lines[item.id];

        currentLine[field] = event.target.value;

        setLines((prevSplits) => ({
            ...prevSplits,
            [item.id]: currentLine,
        }));

        setTimeout(() => focusInputLine(idx, position), 100);
    };

    const onChangeLineExpirationDate = (event: Date | null, itemId: string) => {
        if (event === null) return;

        const currentLine = lines[itemId];

        currentLine.expirationDate = new Date(event);

        setLines((prevSplits) => ({
            ...prevSplits,
            [itemId]: currentLine,
        }));
    };

    const itemHasLinesError = (itemId: string) => {
        return errorsLines.find((item) => item.id === itemId);
    };

    const focusInputLine = (index: number, position: number) => {
        if (inputLinesRefs.current[index][position]) {
            inputLinesRefs.current[index][position]?.focus();
        }
    };

    const styleCellData = {padding: '25px 0 25px 20px'};

    const getIsSplit = (id: string) => {
        return showIsSplit.find((item: string) => item === id);
    };

    const addIsSplits = (id: string) => {
        setShowIsSplit([...showIsSplit, id]);
        if (!splits[id] || splits[id].length === 0) {
            setSplit((prevSplits) => ({
                ...prevSplits,
                [id]: [
                    {
                        batch: '',
                        quantitySupplied: 0,
                        expirationDate: new Date(),
                        buildDate: new Date(),
                    },
                ],
            }));
            inputRefs.current.push([null, null]);
        }
    };

    const addSplits = (itemId: string) => {
        const currentSplits = [
            ...splits[itemId],
            {
                batch: '',
                quantitySupplied: 0,
                expirationDate: new Date(),
                buildDate: new Date(),
            },
        ];
        setSplit((prevSplits) => ({
            ...prevSplits,
            [itemId]: currentSplits,
        }));
        inputRefs.current.push([null, null]);
    };

    const focusInput = (index: number, position: number) => {
        if (inputRefs.current[index][position]) {
            inputRefs.current[index][position]?.focus();
        }
    };

    const onChangeAmount = (
        event: any,
        item: ILineDetail,
        field: string,
        currenIndex: number,
        position: number
    ) => {
        if (event === null) return;

        const currentSplit = splits[item.id][currenIndex];

        currentSplit[field] =
            field === 'quantitySupplied' ? Number(event.target.value) : event.target.value;

        const others = splits[item.id].map((item, index) => {
            if (index === currenIndex) {
                return currentSplit;
            }
            return item;
        });

        setSplit((prevSplits) => ({
            ...prevSplits,
            [item.id]: others,
        }));
        cuantityErrorSplitController(others, item);
        checkSplitHasBatch(others, item.id);
        setTimeout(() => focusInput(currenIndex, position), 100);
    };

    const checkSplitHasBatch = (split: IItemSplitMarket[], id: string) => {
        const isEnpty = split.some((item: IItemSplitMarket) => item.batch === '');
        if (isEnpty) {
            setErrorsLines([
                ...errorsLines,
                {
                    id: id,
                    message: t('partial_conf_po.core.required_number_lote'),
                },
            ]);
        } else {
            const errors = errorsLines.filter((error) => error.id !== id);
            setErrorsLines(errors);
        }
    };

    const cuantityErrorSplitController = (split: IItemSplitMarket[], item: ILineDetail) => {
        const currentQuantity = split.reduce((carry, quan) => {
            return carry + quan.quantitySupplied;
        }, 0);
        if (currentQuantity !== item.quantity) {
            const found = errorsSplits.find((error) => error.id === item.id);
            if (!found) {
                setErrorsSplits([
                    ...errorsSplits,
                    {
                        id: item.id,
                        message: t('confirmposimply.amount_equal_requested'),
                    },
                ]);
            }
        } else {
            const errors = errorsSplits.filter((error) => error.id !== item.id);
            setErrorsSplits(errors);
        }
    };

    const onChangeDate = (
        event: Date | null,
        itemId: string,
        currenIndex: number,
        field: string
    ) => {
        if (event === null) return;

        const currentSplit = splits[itemId][currenIndex];

        currentSplit[field] = new Date(event);

        const currentSplits = splits[itemId].filter((_, index) => index !== currenIndex);

        const newsSplits = [...currentSplits, currentSplit];

        setSplit((prevSplits) => ({
            ...prevSplits,
            [itemId]: prevSplits[itemId]
                ? newsSplits
                : [
                      {
                          batch: '',
                          quantitySupplied: 0,
                          expirationDate: new Date(),
                          buildDate: new Date(),
                      },
                  ],
        }));
    };

    const deleteSplit = (item: ILineDetail, currenIndex: number) => {
        const currentSplits = splits[item.id].filter((_, index) => index !== currenIndex);
        if (currentSplits.length === 0) {
            delete splits[item.id];
            setSplit(splits);
            setShowIsSplit(showIsSplit.filter((itemShow) => itemShow !== item.id));
        } else {
            setSplit((prevSplits) => ({
                ...prevSplits,
                [item.id]: currentSplits,
            }));
        }
    };

    const getIsSplitReject = (item: ILineDetail) => {
        const currentSupplied = splits[item.id].reduce((carry, quan) => {
            return carry + quan.quantitySupplied;
        }, 0);
        return currentSupplied < item.quantity;
    };

    const itemHasSplitError = (itemId: string) => {
        return errorsSplits.find((item) => item.id === itemId);
    };

    return (
        <Box>
            <TableContainer>
                <TableHeadRow container>
                    <Grid item xs={0.5}>
                        <TableCellHeadInitial>Pos</TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={1.8}>
                        <TableCellHead>SKU - {t('description')}</TableCellHead>
                    </Grid>
                    <Grid item xs={0.7}>
                        <TableCellHead>{t('requested_amount')}</TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead> {t('unit')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('quantity_supplied')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.8}>
                        <TableCellHead>{t('receptpo.table.headers.delivery_date')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.2}>
                        <TableCellHead>Lote</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('date_expiry')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('unit_price')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('amount')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHeadFinal>{t('actions')}</TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    <Box sx={{height: '85vh', overflowY: 'auto'}}>
                        {poputate &&
                            lineItems &&
                            lineItems.map((item: ILineDetail, idx: number) => (
                                <Box
                                    key={item.id}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '0',
                                        borderBottom: '1px solid #C2D1D9',
                                        boxShadow: 'none',
                                        minHeight: '50px',
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        color: '#515151',
                                        background: 'white',
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    <Grid
                                        container
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '0',
                                            boxShadow: 'none',
                                            minHeight: '50px',
                                        }}
                                    >
                                        <Grid item xs={0.5} sx={styleCellData}>
                                            {item.position}
                                        </Grid>
                                        <Grid item xs={1.8} sx={styleCellData}>
                                            {item.productDetails[0].productName}
                                        </Grid>
                                        <Grid item xs={0.7} sx={styleCellData}>
                                            {item.quantity}
                                        </Grid>
                                        <Grid item xs={0.5} sx={styleCellData}>
                                            {item.productDetails[0].unit}
                                        </Grid>

                                        <Grid item xs={1} sx={styleCellData}>
                                            {item.quantity}
                                        </Grid>
                                        <Grid item xs={1} sx={styleCellData}>
                                            {DateTime.fromISO(item.deliveryDate).toFormat(
                                                'dd/MM/yyyy'
                                            )}
                                        </Grid>
                                        <Grid item xs={1.2} sx={styleCellData}>
                                            <TextField
                                                id="outlined-basic"
                                                label=""
                                                size="small"
                                                variant="outlined"
                                                value={lines[item.id]?.batch}
                                                onChange={(event) =>
                                                    onChangeInputValue(event, item, idx, 'batch', 0)
                                                }
                                                inputRef={(input) =>
                                                    (inputLinesRefs.current[idx][0] = input)
                                                }
                                                disabled={!!getIsSplit(item.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={1.8} sx={styleCellData}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label={t('date_expiry')}
                                                    value={lines[item.id]?.expirationDate}
                                                    onChange={(event) =>
                                                        onChangeLineExpirationDate(event, item.id)
                                                    }
                                                    disabled={
                                                        item.quantityDelivery >= 2 ||
                                                        !!getIsSplit(item.id)
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{
                                                                '& .MuiInputBase-root': {
                                                                    height: '40px',
                                                                    marginRight: '10px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    minDate={lines[item.id]?.deliveryDate}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={1} sx={styleCellData}>
                                            {item.productDetails[0].price}
                                        </Grid>
                                        <Grid item xs={1} sx={styleCellData}>
                                            {item.totalAmount.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                maximumFractionDigits: 2,
                                            })}
                                        </Grid>
                                        <Grid item xs={1.5} sx={{display: 'flex'}}>
                                            {!getIsSplit(item.id) && (
                                                <BtnSplit actionFn={() => addIsSplits(item.id)} />
                                            )}
                                        </Grid>
                                    </Grid>

                                    {item.quantityDelivery >= 2 && (
                                        <Typography
                                            sx={{
                                                color: '#921A28',
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 400,
                                                margin: '0 10px 10px 10px',
                                            }}
                                        >
                                            Solo puede establecer la fecha de entrega en dos
                                            ocasiones
                                        </Typography>
                                    )}
                                    {getIsSplit(item.id) && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                margin: '10px 0 20px 0',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 400,
                                                    margin: '0 10px 10px 10px',
                                                }}
                                            >
                                                {t('partial_conf_po.core.partial_delivery')}
                                            </Typography>
                                            {splits[item.id] &&
                                                splits[item.id].map((split, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                margin: '0 10px 10px 10px',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '10px',
                                                            }}
                                                        >
                                                            <span>
                                                                {t('partial_conf_po.core.delivery')}{' '}
                                                                {index + 1}
                                                            </span>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <TextField
                                                                    sx={{
                                                                        height: '40px',
                                                                        marginRight: '10px',
                                                                    }}
                                                                    id="outlined-basic"
                                                                    label={t(
                                                                        'partial_conf_po.core.lot'
                                                                    )}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    value={split.batch}
                                                                    onChange={(event) =>
                                                                        onChangeAmount(
                                                                            event,
                                                                            item,
                                                                            'batch',
                                                                            index,
                                                                            0
                                                                        )
                                                                    }
                                                                    inputRef={(input) =>
                                                                        (inputRefs.current[
                                                                            index
                                                                        ][0] = input)
                                                                    }
                                                                />
                                                                <LocalizationProvider
                                                                    dateAdapter={AdapterDateFns}
                                                                >
                                                                    <DatePicker
                                                                        label={t(
                                                                            'partial_conf_po.core.due_date'
                                                                        )}
                                                                        value={split.expirationDate}
                                                                        onChange={(event) =>
                                                                            onChangeDate(
                                                                                event,
                                                                                item.id,
                                                                                index,
                                                                                'expirationDate'
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            item.quantityDelivery >=
                                                                            2
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                sx={{
                                                                                    '& .MuiInputBase-root':
                                                                                        {
                                                                                            height: '40px',
                                                                                        },
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                                <BtnReject
                                                                    actionFn={() =>
                                                                        deleteSplit(item, index)
                                                                    }
                                                                />
                                                                {splits[item.id].length - 1 ===
                                                                    index &&
                                                                    getIsSplitReject(item) && (
                                                                        <Button
                                                                            onClick={() =>
                                                                                addSplits(item.id)
                                                                            }
                                                                            color="info"
                                                                            variant="outlined"
                                                                        >
                                                                            {`${t(
                                                                                'partial_conf_po.core.add'
                                                                            )} +`}
                                                                        </Button>
                                                                    )}
                                                            </Box>
                                                            <Box>
                                                                <LocalizationProvider
                                                                    dateAdapter={AdapterDateFns}
                                                                >
                                                                    <DatePicker
                                                                        label={t(
                                                                            'partial_conf_po.core.fab_date'
                                                                        )}
                                                                        value={split.buildDate}
                                                                        onChange={(event) =>
                                                                            onChangeDate(
                                                                                event,
                                                                                item.id,
                                                                                index,
                                                                                'buildDate'
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            item.quantityDelivery >=
                                                                            2
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                sx={{
                                                                                    '& .MuiInputBase-root':
                                                                                        {
                                                                                            height: '40px',
                                                                                            marginRight:
                                                                                                '10px',
                                                                                        },
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    label={t(
                                                                        'partial_conf_po.core.total_lote'
                                                                    )}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    value={split.quantitySupplied}
                                                                    onChange={(event) =>
                                                                        onChangeAmount(
                                                                            event,
                                                                            item,
                                                                            'quantitySupplied',
                                                                            index,
                                                                            1
                                                                        )
                                                                    }
                                                                    inputRef={(input) =>
                                                                        (inputRefs.current[
                                                                            index
                                                                        ][1] = input)
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            {itemHasSplitError(item.id) && (
                                                <Typography
                                                    sx={{
                                                        color: '#921A28',
                                                        fontSize: '12px',
                                                        fontFamily: theme.typography.fontFamily,
                                                        fontWeight: 400,
                                                        margin: '0 0 10px 10px',
                                                    }}
                                                >
                                                    {itemHasSplitError(item.id)?.message}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    {itemHasLinesError(item.id) && (
                                        <Typography
                                            sx={{
                                                color: '#921A28',
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 400,
                                                margin: '0 0 10px 10px',
                                            }}
                                        >
                                            {itemHasLinesError(item.id)?.message}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                    </Box>
                )}
            </TableContainer>
        </Box>
    );
};

export default ConfirmPurchaseOrderTableMarket;
