import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {styled} from '@mui/material/styles';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {fetchGetPurchaseOrder} from '@/components/purchase-order/redux/actions/PurchaceOrderActions';
import {AppDispatch, RootState} from '@config/store';
import {Box, Grid} from '@mui/material';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ILineDetail} from '@/components/purchase-order/confirmTotal/interface';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';

interface IConfirmPurchaseOrderTotalTableProps {
    internalId?: string;
}
const ConfirmPurchaseOrderTotalTable: React.FC<IConfirmPurchaseOrderTotalTableProps> = ({
    ...props
}) => {
    const {t} = useTranslation();
    const {internalId} = props;
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const lineItems = useSelector((state: RootState) => state.purchaceOrderReducers.lineItems);
    const loading = useSelector((state: RootState) => state.purchaceOrderReducers.loading);
    useEffect(() => {
        if (token && internalId) {
            dispatch(fetchGetPurchaseOrder(token, internalId));
        }
    }, [dispatch, token]);

    const TableContainer = styled(Grid)(({width = 'calc(100vw - 80px)'}: {width?: string}) => ({
        width: '100%',
    }));

    return (
        <Box>
            <TableContainer>
                <TableHeadRow container>
                    <Grid item xs={1} sx={{boxShadow: 0}}>
                        <TableCellHeadInitial>Pos</TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2.5} sx={{boxShadow: 0}}>
                        <TableCellHead>SKU - {t('description')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.5} sx={{boxShadow: 0}}>
                        <TableCellHead>{t('requested_amount')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('unit')}</TableCellHead>
                    </Grid>

                    <Grid item xs={1.5}>
                        <TableCellHead>{t('quantity_supplied')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHead>{t('receptpo.table.headers.delivery_date')}</TableCellHead>
                    </Grid>
                    {/* <Grid item xs={1}>
                        <TableCellHead>Lote</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('date_expiry')}</TableCellHead>
                    </Grid> */}
                    <Grid item xs={1.5}>
                        <TableCellHead>{t('unit_price')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHeadFinal>{t('amount')}</TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    lineItems &&
                    lineItems.map((item: ILineDetail) => (
                        <Grid container key={item.id}>
                            <Grid item xs={1}>
                                <TableCell>{item.position}</TableCell>
                            </Grid>
                            <Grid item xs={2.5}>
                                <TableCell>{item.productDetails[0].productName}</TableCell>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TableCell>{item.quantity}</TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>{item.productDetails[0].unit}</TableCell>
                            </Grid>

                            <Grid item xs={1.5}>
                                <TableCell>{item.quantity}</TableCell>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TableCell>
                                    {DateTime.fromISO(item.deliveryDate).toFormat('dd/MM/yyyy')}
                                </TableCell>
                            </Grid>
                            {/* <Grid item xs={1}>
                                <TableCell>{item.batches[0]}</TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>-</TableCell>
                            </Grid> */}
                            <Grid item xs={1.5}>
                                <TableCell>
                                    {item.productDetails[0].price.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 2,
                                    })}
                                </TableCell>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TableCell sx={{color: '#00ACFF'}}>
                                    {item.totalAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 2,
                                    })}
                                </TableCell>
                            </Grid>
                        </Grid>
                    ))
                )}
            </TableContainer>
        </Box>
    );
};

export default ConfirmPurchaseOrderTotalTable;
