export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const GET_PURCHASE_ORDERS_SUCCESS = 'GET_PURCHASE_ORDERS_SUCCESS';
export const GET_PURCHASE_ORDERS_ERROR = 'GET_PURCHASE_ORDERS_ERROR';
export const GET_PO_LINE_ITEMS = 'GET_PO_LINE_ITEMS';
export const GET_PO_LINE_ITEMS_SUCCESS = 'GET_PO_LINE_ITEMS_SUCCESS';
export const GET_PO_LINE_ITEMS_ERROR = 'GET_PO_LINE_ITEMS_ERROR';
export const SELECT_ALL_PURCHASE_ORDERS = 'SELECT_ALL_PURCHASE_ORDERS';
export const SELECT_PURCHASE_ORDERS = 'SELECT_PURCHASE_ORDERS';
export const SELECT_EXTERNAL_IDS = 'SELECT_EXTERNAL_IDS';
export const SHOW_TERMS_MODAL = 'SHOW_TERMS_MODAL';
export const HIDE_TERMS_MODAL = 'HIDE_TERMS_MODAL';
export const CLOSE_PO_ACCORDION = 'CLOSE_PO_ACCORDION';
