import {
    GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS,
    GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_ERROR,
    GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_SUCCESS,
    GET_TERMS_CONDITIONS_DETAILS,
    GET_TERMS_CONDITIONS_DETAILS_ERROR,
    GET_TERMS_CONDITIONS_DETAILS_SUCCESS,
    SET_CURRENT_INFO_NOTIFICATION,
} from '@/components/log/redux/types/BinnaclesTypes';
import StringUtils from '@utils/StringUtils';
import axios from 'axios';

export const getTermsConditionsDetails = () => {
    return {
        type: GET_TERMS_CONDITIONS_DETAILS,
    };
};
export const getTermsConditionsDetailsSuccess = (result) => {
    return {
        type: GET_TERMS_CONDITIONS_DETAILS_SUCCESS,
        value: result,
    };
};
export const getTermsConditionsDetailsError = (error) => {
    return {
        type: GET_TERMS_CONDITIONS_DETAILS_ERROR,
        value: error,
    };
};

export function fetchGetTermsConditionsDetails(
    vendorBranchOfficeId,
    token,
    page,
    limit,
    filters = {}
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
                user: filters.user,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getTermsConditionsDetails());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/binnacles/terms-conditions/branch-office/${vendorBranchOfficeId}${queryString}`,
                {headers}
            );
            dispatch(getTermsConditionsDetailsSuccess(response.data));
        } catch (error) {
            dispatch(getTermsConditionsDetailsError(true));
        }
    };
}

export const getTermsConditionsDownload = () => {
    return {
        type: GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS,
    };
};
export const getTermsConditionsDownloadSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_SUCCESS,
        value: result,
    };
};
export const getTermsConditionsDownloadError = (error) => {
    return {
        type: GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_ERROR,
        value: error,
    };
};

const typeData = {
    csv: 'text/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export function fetchGetTermsConditionsDetailsDownload(
    vendorBranchOfficeId,
    token,
    format,
    filters = {}
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                ...filters,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getTermsConditionsDownload());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/binnacles/terms-conditions/download/branch-office/${vendorBranchOfficeId}/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(
                new Blob([response.data], {type: `${typeData[format]};charset=utf-8`})
            );

            const a = document.createElement('a');
            a.href = url;
            a.download = `terms-conditions.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getTermsConditionsDownloadSuccess());
        } catch (error) {
            dispatch(getTermsConditionsDownloadError(true));
        }
    };
}
export const setInfoCurrentNotification = (data) => {
    return {
        type: SET_CURRENT_INFO_NOTIFICATION,
        value: {
            ...data,
        },
    };
};
