import {ButtonAlternative} from '@/components/UI/atoms/buttons/ButtonAlternative';
import {ColorButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import background from '@login/images/laptop_abi.svg';
import logoAbi from '@login/images/logoAbi.svg';
import {
    fetchPostCreateEnterprise,
    fetchPostValidateEnterprise,
    getInfoUsers,
    setNexStepSupplier,
} from '@login/redux/actions/LoginActions';
import {Box, Typography, useTheme} from '@mui/material';
import TextField from '@mui/material/TextField';
import React, {useEffect, useState} from 'react';
import {Control, Controller, FieldErrors, useForm, useWatch} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
// import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import {UploadDragAndDrop} from '@/components/UI/atoms/inputs/UploadDragAndDrop';
import {
    ALLOWED_FILE_TYPES_PDF,
    MAX_FILE_SIZE_MB,
} from '@components/UI/atoms/inputs/UploadDragAndDrop/UploadDragAndDrop';
import {styled} from '@mui/material/styles';

import AuthFooter from '@/components/UI/organisms/footer/AuthFooter';
import LanguageSwitcher from '@login/componenents/LanguageSwitcher';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const atLeastOneNum = /[0-9]/;
const atLeastOneSpecial = /[\W|_]/;
const atLeastOneLowLetter = /[a-z]/;
const atLeastOneCapLetter = /[A-Z]/;
const atOnlyChartersAndSpaces = /^[a-zA-ZñÑ\s]+$/;
const isValidRfcRegexp =
    /* eslint-disable-next-line */
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const isValidPhone = /^\d{10}$/;

export const ButtonBack = styled('button')(({theme}) => ({
    backgroundColor: '#c5c9c6',
    borderRadius: '4px',
    color: 'black',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: '40px',
    height: '40px',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
}));
export interface IFormEnterprise {
    name: string;
    phone: string;
    email: string;
    password: string;
    rfc: string;
    passwordConfirmation: string;
    industry: string;
    products: string;
    pdf?: File[];
}

const FileManager: React.FC<{
    control: Control<IFormEnterprise>;
    errors: FieldErrors<IFormEnterprise>;
}> = ({control, errors}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    return (
        <Box
            sx={{
                marginTop: '20px',
                display: 'flex',
                border: '1px dashed grey',
                padding: '20px 0 20px 0',
                borderRadius: '4px',
                strokeDasharray: '20',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
                cursor: 'pointer',
            }}
        >
            <Typography
                sx={{
                    position: 'absolute',
                    fontSize: '14px',
                    fontFamily: theme.typography.fontFamily,
                    top: -10,
                    left: 10,
                    background: 'white',
                }}
            >
                {t('auth.form.presentation')}
            </Typography>
            <UploadDragAndDrop
                name={'pdf'}
                control={control}
                accept={ALLOWED_FILE_TYPES_PDF}
                errors={errors}
                MaxFileSizeMb={MAX_FILE_SIZE_MB}
                showBorder={false}
            />
        </Box>
    );
};

const Enterprise = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const enterpriceSuccess = useSelector(
        (state: RootState) => state.logins.createEnterpriceSuccess
    );
    const token = useSelector((state: RootState) => state.logins.accessToken);
    // const [errorFile, setErrorFile] = useState<string>('');
    const [step, setStep] = useState<number>(1);
    // const [fileName, setFileName] = useState<string>('');
    // const [file, setFile] = useState<File | null>(null);
    const dispatch: AppDispatch = useDispatch();
    const flagPerfil = useSelector((state: RootState) => state.perfil.flagPerfil);
    const mdPreloadData = useSelector((state: RootState) => state.logins.mdPreloadData);
    const nextStepSupplier = useSelector((state: RootState) => state.logins.nextStepSupplier);
    const error = useSelector((state: RootState) => state.logins.error);
    const theme = useTheme();

    const schemaFormModal = yup.object({
        name: yup
            .string()
            .required(t('auth.form.name_required'))
            .matches(atOnlyChartersAndSpaces, t('auth.form.name_match')),
        phone: yup
            .string()
            .required(t('auth.form.phone_required'))
            .matches(isValidPhone, t('auth.form.phone_match')),
        email: yup
            .string()
            .required(t('auth.form.email_required'))
            .matches(isValidEmail, t('auth.form.email_match')),
        industry: yup
            .string()
            .required(t('auth.form.industry_required'))
            .matches(atOnlyChartersAndSpaces, t('auth.form.industry_match')),
        products: yup
            .string()
            .required(t('auth.form.products_required'))
            .matches(atOnlyChartersAndSpaces, t('auth.form.products_match')),
        rfc: yup
            .string()
            .required(t('auth.form.rfc_required'))
            .matches(isValidRfcRegexp, t('auth.form.rfc_match')),
        password: yup
            .string()
            .min(8, t('auth.form.password_min'))
            .max(30, t('auth.form.password_max'))
            .required(t('auth.form.password_required'))
            .matches(atLeastOneNum, t('auth.form.password_number'))
            .matches(atLeastOneLowLetter, t('auth.form.password_lowcase'))
            .matches(atLeastOneCapLetter, t('auth.form.password_uppercase'))
            .matches(atLeastOneSpecial, t('auth.form.password_character')),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), undefined], t('auth.form.password_equal'))
            .required(t('auth.form.password_confirm')),
        pdf: yup.mixed().required(t('auth.form.presentation_required')),
    });

    const {
        handleSubmit,
        trigger,
        control,
        reset,
        formState: {errors},
        getValues,
    } = useForm<IFormEnterprise>({resolver: yupResolver(schemaFormModal)});

    const onSubmit = (data: IFormEnterprise) => {
        const fdata = new FormData();
        let file: File | null = null;
        if (data?.pdf && data.pdf.length > 0) {
            file = data.pdf[0]!;
            delete data.pdf;
        }

        fdata.append('payload', JSON.stringify(data));
        fdata.append('pdf', file!);
        dispatch(fetchPostCreateEnterprise(fdata));
    };

    const valorName = useWatch({
        control,
        name: 'name',
        defaultValue: '',
    });
    const valorIndustry = useWatch({
        control,
        name: 'industry',
        defaultValue: '',
    });
    const valorProduct = useWatch({
        control,
        name: 'products',
        defaultValue: '',
    });

    const managerLoging = () => {
        if (!enterpriceSuccess) {
            window.location.href = 'login';
        } else {
            dispatch(getInfoUsers(token));
        }
    };
    const validateData = async () => {
        const isValid = await trigger(['rfc', 'industry', 'products', 'name']);
        if (isValid) {
            const data = getValues();

            dispatch(fetchPostValidateEnterprise({payload: JSON.stringify(data)}));
        }
    };

    const goToBack = (e: any) => {
        e.preventDefault();
        setStep(1);
    };
    useEffect(() => {
        if (flagPerfil) {
            navigate('/home');
        }
    }, [flagPerfil]);
    useEffect(() => {
        if (mdPreloadData) {
            navigate('/auth/mdweb');
        }
        if (nextStepSupplier) {
            setStep(2);
            reset({
                ...getValues(),
            });
            dispatch(setNexStepSupplier(false));
        }
    }, [mdPreloadData, nextStepSupplier]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    height: step === 1 ? '100vh' : '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        display: 'flex',
                        '@media (min-height: 800px)': {
                            height: 'calc(100vh - 70px)',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '51vw',
                            background: theme.palette.primary.main,
                            paddingTop: '80px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: '#FFFFFF',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '23px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 500,
                            }}
                        >
                            {t('auth.title')}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '40px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                            }}
                        >
                            {t('auth.subtitle')}
                        </Typography>
                        <img src={background} />
                    </Box>
                    {enterpriceSuccess ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                padding: '20px 0 0 0',
                                width: '49vw',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '50%',
                                }}
                            >
                                <img width="184px" src={logoAbi} />
                                <svg
                                    style={{marginTop: '40px'}}
                                    width="60"
                                    height="60"
                                    viewBox="0 0 120 120"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="57" cy="57" r="57" fill="#4BBC68" />
                                    <path
                                        d="M26 57L44.8675 75.8675C45.493 76.493 46.507 76.493 47.1325 75.8675L87 36"
                                        stroke="white"
                                        strokeWidth="5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 600,
                                        marginTop: '48px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('auth.enterprise.data_register')}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 400,
                                        margin: '20px 0 28px 0',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('auth.enterprise.approved_email')}
                                </Typography>
                                <ButtonAlternative
                                    style={{width: '50%'}}
                                    onClick={() => managerLoging()}
                                >
                                    {t('auth.login')}
                                </ButtonAlternative>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                padding: '30px 0 0 0',
                                width: '49vw',
                            }}
                        >
                            <Box
                                component="form"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                }}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '50%',
                                        marginTop: '5vh',
                                        marginBottom: '5vh',
                                        alignSelf: 'flex-end',
                                    }}
                                >
                                    <LanguageSwitcher />
                                </Box>
                                <img width="184px" src={logoAbi} />
                                <Typography
                                    sx={{
                                        fontSize: '23px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 600,
                                        marginTop: '28px',
                                    }}
                                >
                                    {t('auth.enterprise.info_enterprise')}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 400,
                                        margin: ' 10px 0 28px 0',
                                    }}
                                >
                                    {t('auth.enterprise.enter_data')}
                                </Typography>

                                <Box
                                    sx={{
                                        display: step === 1 ? 'flex' : 'none',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.name_enterprise')}
                                                placeholder={t('auth.form.enter_response')}
                                                size="small"
                                            />
                                        )}
                                        name="name"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.name?.message}
                                    </Typography>
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.rfc')}
                                                placeholder={t('auth.form.enter_response')}
                                                size="small"
                                            />
                                        )}
                                        name="rfc"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.rfc?.message}
                                    </Typography>
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.industry')}
                                                placeholder={t('auth.form.enter_response')}
                                                size="small"
                                            />
                                        )}
                                        name="industry"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.industry?.message}
                                    </Typography>
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.products_services')}
                                                size="small"
                                                placeholder={t('auth.form.enter_response')}
                                            />
                                        )}
                                        name="products"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.products?.message}
                                    </Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 6,
                                            marginTop: '20px',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '3px',
                                                background: 'orange',
                                                width: '50px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <div
                                            style={{
                                                padding: '3px',
                                                background: 'grey',
                                                width: '50px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            {' '}
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        style={{
                                            marginTop: '30px',
                                            padding: '10px 10px 10px 10px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={validateData}
                                        disabled={!valorName || !valorIndustry || !valorProduct}
                                    >
                                        {t('auth.form.next')}
                                    </button>
                                </Box>

                                <Box
                                    sx={{
                                        display: step === 2 ? 'flex' : 'none',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.email')}
                                                size="small"
                                            />
                                        )}
                                        name="email"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.email?.message}
                                    </Typography>
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.password')}
                                                size="small"
                                                type="password"
                                            />
                                        )}
                                        name="password"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.password?.message}
                                    </Typography>
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.confirm_password')}
                                                size="small"
                                                type="password"
                                            />
                                        )}
                                        name="passwordConfirmation"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.passwordConfirmation?.message}
                                    </Typography>
                                    <Controller
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id="outlined-required"
                                                label={t('auth.form.phone')}
                                                size="small"
                                            />
                                        )}
                                        name="phone"
                                        control={control}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                        }}
                                    >
                                        {errors?.phone?.message}
                                    </Typography>
                                    <FileManager errors={errors} control={control} />

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.pdf?.message}
                                    </Typography>

                                    {error && (
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 300,
                                                color: 'red',
                                                marginBottom: '14px',
                                            }}
                                        >
                                            {error.message}
                                        </Typography>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 6,
                                            marginTop: '20px',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '3px',
                                                background: 'grey',
                                                width: '50px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <div
                                            style={{
                                                padding: '3px',
                                                background: 'orange',
                                                width: '50px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            {' '}
                                        </div>
                                    </div>
                                    <ButtonBack onClick={(e) => goToBack(e)}>
                                        {t('auth.form.return')}
                                    </ButtonBack>
                                    <ColorButton style={{marginTop: '10px'}}>
                                        {t('auth.form.send')}
                                    </ColorButton>
                                </Box>

                                <ButtonAlternative
                                    onClick={() => {
                                        window.location.href = 'login';
                                    }}
                                    style={{marginTop: '10px', marginBottom: '20px'}}
                                >
                                    {t('auth.login')}
                                </ButtonAlternative>
                            </Box>
                        </Box>
                    )}
                </Box>

                <AuthFooter />
            </Box>
        </Box>
    );
};

export default Enterprise;
