import {
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    Modal,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';

export const Loader = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
`;

export const ButtonCancel = styled('button')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    color: 'black',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    height: '35px',
    width: '150px',
    '&:hover': {
        cursor: 'pointer',
    },
    background: '#FFFFFF',
    margin: '20px',
}));

export const ImgIcon = styled('img')`
    height: 114px;
    margin-top: 30px;
`;

export const ImgIconClose = styled('img')`
    height: 20px;
`;

export const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#011B34',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
    '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
});

export const SaveButton = styled('button')(({theme}) => ({
    width: '150px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    color: '#F1F1F1',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    height: '35px',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
    '&:disabled': {
        cursor: 'none',
        opacity: 0.5,
    },
}));

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40vw;
    border-radius: 10px;
`;

export const Nav = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    height: '59px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    color: '#FFF',
    borderRadius: '3px 3px 0 0',
}));

export const TableCellPrincipal = styled(Paper)(({theme}) => ({
    padding: '10px',
    borderRadius: '0',
    border: 'none',
    boxShadow: 'none',
    borderTop: '1px solid #C2D1D9',
    borderBottom: '1px solid #C2D1D9',
    background: '#EBF0F2',
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    minHeight: '50px',
    overflowWrap: 'anywhere',
}));

export const TableCellChildren = styled(Paper)({
    padding: '10px',
    borderRadius: '0',
    borderBottom: '1px solid #C2D1D9',
    boxShadow: 'none',
    background: '#FFFFFF',
    minHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'anywhere',
});

export const TypographyStyled400 = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    fontWeight: 400,
}));

export const TypographyStyled500 = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    fontWeight: 500,
}));

export const TypographyStyled600 = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    fontWeight: 600,
}));

export const TypographyStyledUser = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    fontWeight: 500,
    color: '#00ACFF',
}));

export const EditListButton = styled(Button)(({theme}) => ({
    width: '151px',
    height: '35px',
    borderRadius: '5px',
    color: '#00ACFF',
    border: '1px solid #00ACFF',
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    alignSelf: 'end',
    '&:hover': {
        cursor: 'pointer',
    },
    margin: '20px',
}));

export const DeleteListButton = styled(Button)(({theme}) => ({
    width: '151px',
    height: '35px',
    borderRadius: '5px',
    color: '#FF5722',
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    alignSelf: 'end',
    '&:hover': {
        cursor: 'pointer',
    },
    margin: '20px',
}));

export const StyledFormControlRol = styled(FormControl)({
    width: '259px',
    height: '40px',
});

export const InputLabelRol = styled(InputLabel)({
    '&.Mui-focused': {
        color: '#011B34',
    },
    color: '#011B34',
});

export const RedText = styled('span')({
    color: 'red',
});
export const StyledSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
});

export const StyledFormControlSelect = styled(FormControl)({
    width: '320px',
    height: '40px',
});

export const ButtonMoreItems = styled(Button)({
    '&:hover': {
        cursor: 'pointer',
    },
    color: '#00ACFF',
    textAlign: 'left',
    backgroundColor: 'white',
    border: 'none',
    padding: 0,
    minWidth: '40px',
    fontSize: '12px',
});

export const ItemsList = styled('div')`
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    height: auto;
    width: auto;
    color: black;
    overflow: auto;
`;

export const FileUploadContainer = styled('div')({
    border: '1px solid #c5d1d8',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    overflow: 'auto',
});

export const StyledInput = styled('input')`
    display: none;
`;

export const StyledFileLabelButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const StyledLabelFileUpload = styled(Typography)`
    font-family: Mulish;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
`;
