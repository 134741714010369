import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
export const PrivateRoute = ({children}) => {
    const tokenflag = useSelector((state) => state.logins.tokenflag);
    const location = useLocation();
    const regex = /\/order\/POR_[a-zA-Z0-9]+/;

    const orderId = regex.test(location.pathname)
        ? location.pathname.split('/')[location.pathname.split('/').length - 1]
        : null;

    const urllogin = `/auth/login${orderId ? `?orderId=${orderId}` : ''}`;
    const isSAMLUrl = location.pathname === '/v2/auth/sso/saml/login';

    return isSAMLUrl ? (
        children
    ) : tokenflag ? (
        children
    ) : (
        <Navigate to={urllogin} state={{from: location}} />
    );
};
