import {IModule, IRole} from '@/components/roles/interface';
import {
    DELETE_ROLES,
    DELETE_ROLES_ERROR,
    DELETE_ROLES_SUCCESS,
    GET_MODULES,
    GET_MODULES_ERROR,
    GET_MODULES_SUCCESS,
    GET_PERMISSIONS,
    GET_PERMISSIONS_ERROR,
    GET_PERMISSIONS_SUCCESS,
    GET_ROLE_BYID,
    GET_ROLE_BYID_ERROR,
    GET_ROLE_BYID_SUCCESS,
    GET_ROLES,
    GET_ROLES_ERROR,
    GET_ROLES_SUCCESS,
    POST_ROLES,
    POST_ROLES_ERROR,
    POST_ROLES_SUCCESS,
    UPDATE_ROLE_STATUS,
    UPDATE_ROLE_STATUS_ERROR,
    UPDATE_ROLE_STATUS_SUCCESS,
    UPDATE_ROLES,
    UPDATE_ROLES_ERROR,
    UPDATE_ROLES_SUCCESS,
} from '@/components/roles/redux/types/RolesTypes';
import {moveModule} from '@/components/roles/utils';

interface IInitialState {
    roles: IRole[];
    role: IRole | null;
    modules: IModule[];
    count: number;
    page: number;
    totalPages: number;
    loading: boolean;
    error: boolean;
    limit: number;
}

const initialState: IInitialState = {
    roles: [],
    role: null,
    modules: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
};

const rolesManager = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MODULES:
            return {
                ...state,
                loading: true,
            };
        case GET_MODULES_SUCCESS:
            action.value.data = moveModule(
                action.value.data ?? [],
                'supplier_tax_status_record',
                'abi_tax_status_record'
            );
            return {
                ...state,
                modules: action.value.data,
                loading: false,
            };
        case GET_MODULES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_PERMISSIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: action.value.data,
                loading: false,
            };
        case GET_PERMISSIONS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_ROLES:
            return {
                ...state,
                loading: true,
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_ROLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_ROLES:
            return {
                ...state,
                loading: true,
            };
        case POST_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_ROLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_ROLES:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_ROLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_ROLE_STATUS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ROLE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_ROLE_STATUS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case DELETE_ROLES:
            return {
                ...state,
                loading: true,
            };
        case DELETE_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_ROLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_ROLE_BYID:
            return {
                ...state,
                loading: true,
            };
        case GET_ROLE_BYID_SUCCESS:
            return {
                ...state,
                role: action.value.data,
                loading: false,
            };
        case GET_ROLE_BYID_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default rolesManager;
