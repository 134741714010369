import {TextButton} from '@components/UI/molecules/textButton/textButtons';
import {
    TableCellChildren,
    TableCellPrincipal,
    TypographyStyled400,
    TypographyStyledUser,
} from '@components/faqs/StyledFAQ';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, Grid, Typography, useTheme, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@/components/UI/molecules/modals/WarningUpdateModal';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, SupplierTaxStatusRecordEnum} from '@/enums/permissions.enum';
import SkeletonRow from '@components/UI/molecules/tables/SkeletonRow';
import {TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import ModalCSF from '@components/csf/csfABI/modals/ModalCSF';
import {
    fetchGetCsfSupplier,
    fetchGetCsfSupplierDownload,
    fetchGetDownloadListCsfSupplier,
} from '@components/csf/csfSupplier/redux/actions/CsfSupplierActions';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {DateTime} from 'luxon';

const CSFSupplier: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    interface IRFC {
        rfc: string;
    }
    interface attachmentObject {
        createdAt: string;
    }

    interface addressObject {
        zipCode: string;
    }

    interface taxProfiles {
        businessName: string;
        expeditionDate: string;
        expeditionPlace: string;
        expirationDate: string;
        id: string;
        taxId: string;
        address: addressObject;
        curp: string;
        taxRegimes: string[];
        attachment: attachmentObject;
        createdAt: Date;
        lastStatusChange: Date;
    }

    interface companies {
        taxProfiles: taxProfiles[];
    }
    interface enterprise {
        companies: companies[];
    }
    interface ITable {
        name: string;
        enterprise: enterprise;
    }

    const token = useSelector((state: RootState) => state.logins.accessToken);
    const csfSupplier = useSelector((state: RootState) => state.csfSupplier.csfSupplier);
    const currentPage = useSelector((state: RootState) => state.csfSupplier.page);
    const totalPages = useSelector((state: RootState) => state.csfSupplier.totalPages);
    const loading = useSelector((state: RootState) => state.csfSupplier.loading);
    const error = useSelector((state: RootState) => state.csfSupplier.error);
    const limit = useSelector((state: RootState) => state.csfSupplier.limit);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [item, setItem] = useState<ITable>();
    const [method, setMethod] = useState<string>('');
    const [isModalOpenValidation, setModalOpenValidation] = useState<boolean>(false);
    const [responseValidation, setResponseValidation] = useState<boolean>(false);
    const [flag, setFlag] = useState<boolean>(false);

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetCsfSupplier(token, value, limit));
    };

    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetCsfSupplier(token, 1, event.target.value));
    };

    const schemaFormModal = yup.object({
        rfc: yup.string().max(13, 'RFC inválido'),
    });

    const {handleSubmit, control, getValues} = useForm<IRFC>({
        resolver: yupResolver(schemaFormModal),
    });

    const onSubmit = (data: IRFC) => {
        dispatch(fetchGetCsfSupplier(token, 1, limit, data));
    };

    const downloadCsf = (item: any) => {
        dispatch(fetchGetCsfSupplierDownload(token, item.enterprise.companies[0].id));
    };

    const closeModal = () => {
        setModalOpen(false);
        setFlag((prev) => !prev);
    };

    const editModal = (item: any) => {
        setModalOpen((prev) => !prev);
        setItem(item);
        setMethod('editar');
    };

    const closeModalValidation = () => {
        setModalOpenValidation(false);
    };

    const handleChangeDownload = (format: string) => {
        const rfc = getValues('rfc');
        dispatch(fetchGetDownloadListCsfSupplier(token, format, rfc));
    };

    useEffect(() => {
        if (!loading && flag) {
            if (error) {
                setResponseValidation(true);
                setModalOpenValidation(true);
            } else if (!error) {
                setResponseValidation(false);
                setModalOpenValidation(true);
            }
            setFlag((prev) => !prev);
        }
    }, [error, loading]);

    useEffect(() => {
        if (token) {
            dispatch(fetchGetCsfSupplier(token, currentPage, limit));
        }
    }, [token]);

    return (
        <>
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '24px',
                    fontWeight: 400,
                }}
            >
                {t('csf.proof_suppliers')}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    padding: '15px',
                    background: '#fff',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                    <Can
                        I={SupplierTaxStatusRecordEnum.FIND_RFC}
                        a={FeatureCodeEnum.SUPPLIER_TAX_STATUS_RECORD}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box display={'flex'}>
                                <Box>
                                    <Controller
                                        render={({field: {onChange, value}}) => {
                                            return (
                                                <TextField
                                                    value={value}
                                                    onChange={onChange}
                                                    label={'RFC ' + t('supplier')}
                                                    size="small"
                                                />
                                            );
                                        }}
                                        name="rfc"
                                        control={control}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <TextButton>
                                        {t('components.filter_btn.title').toUpperCase()}
                                    </TextButton>
                                </Box>
                            </Box>
                        </form>
                    </Can>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                    <Can
                        I={SupplierTaxStatusRecordEnum.EXPORT_EXCEL_CSV}
                        a={FeatureCodeEnum.ABI_TAX_STATUS_RECORD}
                    >
                        <Button
                            sx={{
                                borderRadius: '5px',
                                border: '1px solid #00ACFF',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                                marginLeft: '10px',
                                height: '35px',
                            }}
                            onClick={() => handleChangeDownload('csv')}
                        >
                            <img src={IconDownload} />
                            <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                CSV
                            </TypographyStyledUser>
                        </Button>
                        <Button
                            sx={{
                                borderRadius: '5px',
                                border: '1px solid #00ACFF',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                                marginLeft: '10px',
                                height: '35px',
                            }}
                            onClick={() => handleChangeDownload('xlsx')}
                        >
                            <img src={IconDownload} />
                            <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                XLSX
                            </TypographyStyledUser>
                        </Button>
                    </Can>
                </Box>
            </Box>

            <TableContainer container sx={{marginTop: '20px', width: 'auto'}}>
                <Grid item xs={1} display="grid">
                    <TableCellPrincipal
                        sx={{
                            borderLeft: '1px solid #C2D1D9',
                        }}
                    >
                        RFC
                    </TableCellPrincipal>
                </Grid>
                <Grid item xs={1} display="grid">
                    <TableCellPrincipal>CURP</TableCellPrincipal>
                </Grid>
                <Grid item xs={2} display="grid">
                    <TableCellPrincipal>{t('name')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={2} display="grid">
                    <TableCellPrincipal>{t('csf.place_date')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={2} display="grid">
                    <TableCellPrincipal>{t('regime')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={1} display="grid">
                    <TableCellPrincipal>{t('zip_code')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={1} display="grid">
                    <TableCellPrincipal>{t('csf.last_status_change')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={1} display="grid">
                    <TableCellPrincipal>{t('csf.update_date')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={1} display="grid">
                    <TableCellPrincipal
                        sx={{
                            borderRight: '1px solid #C2D1D9',
                        }}
                    >
                        {t('actions')}
                    </TableCellPrincipal>
                </Grid>
                {loading && csfSupplier.length === 0 ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    csfSupplier.map((item: ITable, index: number) => (
                        <>
                            <Grid item xs={1} display="grid" key={index}>
                                <TableCellChildren
                                    sx={{
                                        borderLeft: '1px solid #C2D1D9',
                                    }}
                                >
                                    <TypographyStyled400>
                                        {item.enterprise.companies[0].taxProfiles.length > 0
                                            ? item.enterprise.companies[0].taxProfiles[0].taxId
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.enterprise.companies[0].taxProfiles.length > 0
                                            ? item.enterprise.companies[0].taxProfiles[0].curp
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>{item.name}</TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.enterprise.companies[0].taxProfiles.length > 0
                                            ? item.enterprise.companies[0].taxProfiles[0]
                                                  .expeditionPlace
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.enterprise.companies[0].taxProfiles.length > 0
                                            ? item.enterprise.companies[0].taxProfiles[0].taxRegimes
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.enterprise.companies[0].taxProfiles.length > 0
                                            ? item.enterprise.companies[0].taxProfiles[0].address
                                                  ?.zipCode
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.enterprise.companies[0].taxProfiles.length > 0 &&
                                        item.enterprise.companies[0].taxProfiles[0].lastStatusChange
                                            ? DateTime.fromISO(
                                                  String(
                                                      item.enterprise.companies[0].taxProfiles[0]
                                                          .lastStatusChange
                                                  ),
                                                  {zone: 'utc'}
                                              ).toFormat('dd/MM/yyyy')
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.enterprise.companies[0].taxProfiles.length > 0 &&
                                        item.enterprise.companies[0].taxProfiles[0].expeditionDate
                                            ? DateTime.fromISO(
                                                  item.enterprise.companies[0].taxProfiles[0]
                                                      .expeditionDate,
                                                  {zone: 'utc'}
                                              ).toFormat('dd/MM/yyyy')
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCellChildren
                                    sx={{
                                        borderRight: '1px solid #C2D1D9',
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <Can
                                        I={SupplierTaxStatusRecordEnum.UPLOAD}
                                        a={FeatureCodeEnum.SUPPLIER_TAX_STATUS_RECORD}
                                    >
                                        <Box
                                            sx={{
                                                '&:hover': {cursor: 'pointer'},
                                                textAlign: 'center',
                                            }}
                                        >
                                            <img
                                                onClick={() => editModal(item)}
                                                src={IconDownload}
                                                style={{
                                                    transform: 'rotate(180deg)',
                                                }}
                                                title={t('update')}
                                            />
                                        </Box>
                                    </Can>
                                    <Can
                                        I={SupplierTaxStatusRecordEnum.DOWNLOAD}
                                        a={FeatureCodeEnum.SUPPLIER_TAX_STATUS_RECORD}
                                    >
                                        <Box
                                            sx={{
                                                '&:hover': {cursor: 'pointer'},
                                                marginLeft: '5px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <img
                                                onClick={() => downloadCsf(item)}
                                                src={IconDownload}
                                                title={t('download')}
                                            />
                                        </Box>
                                    </Can>
                                </TableCellChildren>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <ModalCSF
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                item={item}
                method={method}
                type={'supplier'}
            />
            {isModalOpenValidation && (
                <>
                    {responseValidation ? (
                        <WarningUpdateModal
                            isModalOpen={isModalOpenValidation}
                            closeModal={closeModalValidation}
                        />
                    ) : (
                        <SuccessUpdateModal
                            isModalOpen={isModalOpenValidation}
                            closeModal={closeModalValidation}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default CSFSupplier;
