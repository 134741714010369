import {styled} from '@mui/material/styles';

export const ButtonAlternative = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    color: 'black',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: '14px',
    height: '40px',
    '&:hover': {
        cursor: 'pointer',
    },
}));

export const ButtonAlternativeMobile = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    color: 'black',
    fontSize: '38px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginTop: '60px',
    height: '120px',
    '&:hover': {
        cursor: 'pointer',
    },
}));

export const ButtonAlternativeModal = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    color: 'black',
    fontSize: '25px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginTop: '20px',
    height: '60px',
    '&:hover': {
        cursor: 'pointer',
    },
}));

export const ButtonOrderMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    color: '#D1A33C',
    fontSize: '38px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginTop: '80px',
    height: '120px',
    width: '100%',
    '&:hover': {
        cursor: 'pointer',
    },
});
export const ButtonOrder = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    color: '#D1A33C',
    fontSize: '25px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginTop: '40px',
    height: '60px',
    width: '50%',
    '&:hover': {
        cursor: 'pointer',
    },
});

export const ButtonMicrosoftMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1.5px solid #C7C7C7',
    borderRadius: '10px',
    height: '120px',
    '&:hover': {
        cursor: 'pointer',
    },
});

export const ButtonMicrosoft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1.5px solid #C7C7C7',
    borderRadius: '4px',
    height: '40px',
    '&:hover': {
        cursor: 'pointer',
    },
});

export const ButtonCancel = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    color: 'black',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    height: '35px',
    width: '150px',
    marginRight: '20px',
    '&:hover': {
        cursor: 'pointer',
    },
}));
