export const removeUrlParams = (params: string[]): void => {
    const url = new URL(window.location.href);
    params.forEach((param) => {
        removeUrlParam(url, param);
    });
    window.history.pushState({}, document.title, url.toString());
};

const removeUrlParam = (url: URL, param: string): void => {
    url.searchParams.delete(param);
};
