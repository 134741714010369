import {SaveButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import {AppDispatch, RootState} from '@/config/store';
import Image from '@components/perfil/components/Image/Image';
import {UploadDragAndDrop} from '@components/perfil/components/UploadDragAndDrop';
import {fetchPutPerfil} from '@components/perfil/redux/actions/PerfilActions';
import {ButtonCancel} from '@components/UI/atoms/buttons/ButtonAlternative';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, CircularProgress, Modal, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/system';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40vw;
    border-radius: 10px;
`;

const Loader = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px; /* Ajusta según el tamaño del CircularProgress */
    margin-left: -12px; /* Ajusta según el tamaño del CircularProgress */
`;

export const Nav = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main, // Usando color principal del tema
    height: '59px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    color: '#FFF',
    borderRadius: '3px 3px 0 0',
}));
export interface ILogoCatalog {
    value: string;
    catalogId: number;
    id: number;
}

interface ModalProps {
    open: boolean;
    onClose: () => void;
    logo: ILogoCatalog;
}
interface ILogo {
    file: any;
}
const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
export const ModalLogo: React.FC<ModalProps> = ({open, onClose, logo}) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.perfil.loading);
    const enterpriseId = useSelector((state: RootState) => state.logins.user.enterprise.id);
    const theme = useTheme();
    const schemaForm3 = yup.object({
        file: yup.mixed().required('Archivo requerido'),
    });
    const {handleSubmit, control, setValue, reset} = useForm<ILogo>({
        resolver: yupResolver(schemaForm3),
        mode: 'onChange',
    });

    const onSubmit = async (data: any) => {
        dispatch(
            fetchPutPerfil(token, enterpriseId, logo.id, {
                value: await toBase64(data.file),
            })
        );
        reset();
        onClose();
    };
    return (
        <StyledModal open={open} onClose={onClose}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                    width: '700px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('branding.change_profile_picture')}
                    </Typography>
                </Nav>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 700,

                        marginLeft: '10px',
                    }}
                >
                    {t('branding.current_profile_picture')}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 10px 0 10px',
                        flexDirection: 'column',
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    {logo && <Image src={logo.value} />}
                </Box>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 700,
                        color: 'black',
                        marginLeft: '10px',
                    }}
                >
                    {t('branding.new_photo')}
                </Typography>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '15px 10px',
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <UploadDragAndDrop control={control} setValue={setValue} />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '40px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonCancel onClick={onClose}>
                            {t('branding.delete_cancel_button').toUpperCase()}
                        </ButtonCancel>
                        <SaveButton disabled={loading}>
                            {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                        </SaveButton>
                    </Box>
                </Box>
            </Box>
        </StyledModal>
    );
};
