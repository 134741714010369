import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const useConfigurationUrl = () => {
    const location = useLocation();
    const [hasConfiguration, setHasConfiguration] = useState(false);

    useEffect(() => {
        const url = location.pathname.toLowerCase();
        setHasConfiguration(url.includes('configuration'));
    }, [location]);

    return hasConfiguration;
};

export default useConfigurationUrl;
