import {TextButton} from '@components/UI/molecules/textButton/textButtons';
import {
    TableCellChildren,
    TableCellPrincipal,
    TypographyStyled400,
    TypographyStyledUser,
} from '@components/faqs/StyledFAQ';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, Grid, Typography, useTheme, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@/components/UI/molecules/modals/WarningUpdateModal';
import {Can} from '@/context/AbilityContext';
import {AbiTaxStatusRecordEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {AddButton} from '@components/UI/atoms/buttons/ButtonPrincipal';
import SkeletonRow from '@components/UI/molecules/tables/SkeletonRow';
import {TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import ModalCSF from '@components/csf/csfABI/modals/ModalCSF';
import {
    fetchGetCsfAbi,
    fetchGetCsfAbiDownload,
    fetchGetDownloadListCsf,
} from '@components/csf/csfABI/redux/actions/CsfABIActions';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
export interface IRFC {
    rfc: string;
    society: string;
}
interface attachmentObject {
    createdAt: string;
}

interface taxProfiles {
    taxId: string;
    attachment: attachmentObject;
    expeditionDate: Date;
    createdAt: Date;
}
interface ITable {
    id: string;
    name: string;
    externalId: string;
    taxProfiles: taxProfiles[];
}
const CSFABI: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();

    const token = useSelector((state: RootState) => state.logins.accessToken);
    const csfAbi = useSelector((state: RootState) => state.csfAbi.csfAbi);
    const currentPage = useSelector((state: RootState) => state.csfAbi.page);
    const totalPages = useSelector((state: RootState) => state.csfAbi.totalPages);
    const loading = useSelector((state: RootState) => state.csfAbi.loading);
    const limit = useSelector((state: RootState) => state.csfAbi.limit);
    const error = useSelector((state: RootState) => state.csfAbi.error);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [item, setItem] = useState<ITable>();
    const [method, setMethod] = useState<string>('');
    const [isModalOpenValidation, setModalOpenValidation] = useState<boolean>(false);
    const [responseValidation, setResponseValidation] = useState<boolean>(false);
    const [flag, setFlag] = useState<boolean>(false);
    const {notAllowed} = useCheckAbilities();

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetCsfAbi(token, value, limit));
    };

    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetCsfAbi(token, 1, event.target.value));
    };

    const schemaFormModal = yup.object({
        rfc: yup.string().max(13, 'RFC inválido'),
    });

    const {handleSubmit, control, getValues} = useForm<IRFC>({
        resolver: yupResolver(schemaFormModal),
    });

    const onSubmit = (data: IRFC) => {
        dispatch(fetchGetCsfAbi(token, 1, limit, data));
    };

    const downloadCsf = (item: any) => {
        dispatch(fetchGetCsfAbiDownload(token, item.id));
    };

    const closeModal = () => {
        setModalOpen(false);
        setFlag(false);
    };

    const closeModalValidation = () => {
        setModalOpenValidation(false);
    };

    const handleOpenModal = () => {
        setModalOpen((prev) => !prev);
        setMethod('nueva');
    };

    const editModal = (item: any) => {
        setModalOpen((prev) => !prev);
        setItem(item);
        setMethod('editar');
    };

    const handleChangeDownload = (format: string) => {
        const rfc = getValues('rfc');
        const society = getValues('society');
        dispatch(fetchGetDownloadListCsf(token, format, {society, rfc}));
    };

    useEffect(() => {
        if (!loading && flag) {
            if (error) {
                setResponseValidation(true);
                setModalOpenValidation(true);
            } else if (!error) {
                setResponseValidation(false);
                setModalOpenValidation(true);
            }
            setFlag((prev) => !prev);
        }
    }, [error, loading]);

    useEffect(() => {
        if (token) {
            dispatch(fetchGetCsfAbi(token, currentPage, limit));
        }
    }, [token]);

    return (
        <>
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '24px',
                    fontWeight: 400,
                }}
            >
                {t('csf.title')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    padding: '15px',
                    background: '#fff',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                    <Can
                        I={AbiTaxStatusRecordEnum.FIND_RFC}
                        a={FeatureCodeEnum.ABI_TAX_STATUS_RECORD}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box display={'flex'}>
                                <Box>
                                    <Controller
                                        render={({field: {onChange, value}}) => {
                                            return (
                                                <TextField
                                                    value={value}
                                                    onChange={onChange}
                                                    label={t('confirmposimply.filters.society')}
                                                    size="small"
                                                />
                                            );
                                        }}
                                        name="society"
                                        control={control}
                                    />
                                </Box>
                                <Box sx={{marginLeft: '10px'}}>
                                    <Controller
                                        render={({field: {onChange, value}}) => {
                                            return (
                                                <TextField
                                                    value={value}
                                                    onChange={onChange}
                                                    label="RFC"
                                                    size="small"
                                                />
                                            );
                                        }}
                                        name="rfc"
                                        control={control}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.ABI_TAX_STATUS_RECORD,
                                            AbiTaxStatusRecordEnum.FIND_RFC
                                        )}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <TextButton
                                        disabled={notAllowed(
                                            FeatureCodeEnum.ABI_TAX_STATUS_RECORD,
                                            AbiTaxStatusRecordEnum.FIND_RFC
                                        )}
                                    >
                                        {t('components.filter_btn.title').toUpperCase()}
                                    </TextButton>
                                </Box>
                            </Box>
                        </form>
                    </Can>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                    <Can I={AbiTaxStatusRecordEnum.LOAD} a={FeatureCodeEnum.ABI_TAX_STATUS_RECORD}>
                        <AddButton
                            onClick={handleOpenModal}
                            disabled={notAllowed(
                                FeatureCodeEnum.ABI_TAX_STATUS_RECORD,
                                AbiTaxStatusRecordEnum.LOAD
                            )}
                        >
                            {t('csf.new_csf').toUpperCase()}
                        </AddButton>
                    </Can>
                    <Can
                        I={AbiTaxStatusRecordEnum.EXPORT_EXCEL_CSV}
                        a={FeatureCodeEnum.ABI_TAX_STATUS_RECORD}
                    >
                        <Button
                            sx={{
                                borderRadius: '5px',
                                border: '1px solid #00ACFF',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                                marginLeft: '10px',
                                height: '35px',
                            }}
                            onClick={() => handleChangeDownload('csv')}
                            disabled={notAllowed(
                                FeatureCodeEnum.ABI_TAX_STATUS_RECORD,
                                AbiTaxStatusRecordEnum.EXPORT_EXCEL_CSV
                            )}
                        >
                            <img src={IconDownload} />
                            <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                CSV
                            </TypographyStyledUser>
                        </Button>
                        <Button
                            sx={{
                                borderRadius: '5px',
                                border: '1px solid #00ACFF',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                                marginLeft: '10px',
                                height: '35px',
                            }}
                            onClick={() => handleChangeDownload('xlsx')}
                            disabled={notAllowed(
                                FeatureCodeEnum.ABI_TAX_STATUS_RECORD,
                                AbiTaxStatusRecordEnum.EXPORT_EXCEL_CSV
                            )}
                        >
                            <img src={IconDownload} />
                            <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                XLSX
                            </TypographyStyledUser>
                        </Button>
                    </Can>
                </Box>
            </Box>
            <TableContainer container sx={{marginTop: '20px', width: 'auto'}}>
                <Grid item xs={1} display="grid">
                    <TableCellPrincipal
                        sx={{
                            borderLeft: '1px solid #C2D1D9',
                        }}
                    >
                        {t('confirmposimply.filters.society')}
                    </TableCellPrincipal>
                </Grid>
                <Grid item xs={3} display="grid">
                    <TableCellPrincipal>RFC</TableCellPrincipal>
                </Grid>
                <Grid item xs={4} display="grid">
                    <TableCellPrincipal> {t('csf.business_unit')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={2} display="grid">
                    <TableCellPrincipal>{t('csf.update_date')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={2} display="grid">
                    <TableCellPrincipal
                        sx={{
                            borderRight: '1px solid #C2D1D9',
                        }}
                    >
                        {t('actions')}
                    </TableCellPrincipal>
                </Grid>

                {loading && csfAbi.length === 0 ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    csfAbi.map((item: ITable, index: number) => (
                        <>
                            <Grid item xs={1} key={index} display="grid">
                                <TableCellChildren
                                    sx={{
                                        borderLeft: '1px solid #C2D1D9',
                                    }}
                                >
                                    <TypographyStyled400>{item.externalId}</TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={3} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.taxProfiles.length > 0
                                            ? item.taxProfiles[0].taxId
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={4} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>{item.name}</TypographyStyled400>
                                </TableCellChildren>
                            </Grid>
                            <Grid item xs={2} display="grid">
                                <TableCellChildren>
                                    <TypographyStyled400>
                                        {item.taxProfiles.length > 0
                                            ? `${DateTime.fromISO(
                                                  String(item.taxProfiles[0].expeditionDate),
                                                  {zone: 'utc'}
                                              ).toFormat('dd/MM/yyyy')}`
                                            : ''}
                                    </TypographyStyled400>
                                </TableCellChildren>
                            </Grid>

                            <Grid item xs={1} display="grid">
                                <TableCellChildren sx={{textAlign: 'center'}}>
                                    <Can
                                        I={AbiTaxStatusRecordEnum.UPLOAD}
                                        a={FeatureCodeEnum.ABI_TAX_STATUS_RECORD}
                                    >
                                        <Box sx={{'&:hover': {cursor: 'pointer'}}}>
                                            <img
                                                onClick={() => editModal(item)}
                                                src={IconDownload}
                                                style={{
                                                    transform: 'rotate(180deg)',
                                                }}
                                                title={t('update')}
                                            />
                                        </Box>
                                    </Can>
                                </TableCellChildren>
                            </Grid>

                            <Grid item xs={1} display="grid">
                                <TableCellChildren
                                    sx={{
                                        borderRight: '1px solid #C2D1D9',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Can
                                        I={AbiTaxStatusRecordEnum.DOWNLOAD}
                                        a={FeatureCodeEnum.ABI_TAX_STATUS_RECORD}
                                    >
                                        <Box sx={{'&:hover': {cursor: 'pointer'}}}>
                                            <img
                                                onClick={() => downloadCsf(item)}
                                                src={IconDownload}
                                                title={t('download')}
                                            />
                                        </Box>
                                    </Can>
                                </TableCellChildren>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <ModalCSF
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                item={item}
                method={method}
                type={'abi'}
            />
            {isModalOpenValidation && (
                <>
                    {responseValidation ? (
                        <WarningUpdateModal
                            isModalOpen={isModalOpenValidation}
                            closeModal={closeModalValidation}
                        />
                    ) : (
                        <SuccessUpdateModal
                            isModalOpen={isModalOpenValidation}
                            closeModal={closeModalValidation}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default CSFABI;
