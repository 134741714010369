import React from 'react';
import {styled} from '@mui/system';
import {CircularProgress, Typography} from '@mui/material';
import {useIsMobile} from '@hooks/useIsMobile';

const LoaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh', // Ajusta la altura como desees
});

interface LoaderProps {
    text: string;
}

const Loader: React.FC<LoaderProps> = ({text}) => {
    const isMobile = useIsMobile();
    return (
        <LoaderContainer>
            <CircularProgress
                color="primary"
                sx={{width: isMobile ? '240px' : '150px', height: isMobile ? '240px' : '150px'}}
            />
            <Typography
                sx={{
                    fontSize: isMobile ? '30px' : '20px',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    color: '#011B34',
                }}
            >
                {text}{' '}
            </Typography>
        </LoaderContainer>
    );
};

export default Loader;
