export const GET_CANDIDATE = 'GET_CANDIDATE';
export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS';
export const GET_CANDIDATE_ERROR = 'GET_CANDIDATE_ERROR';
export const SET_CANDIDATE_STATUS = 'SET_CANDIDATE_STATUS';
export const SET_CANDIDATE_STATUS_SUCCESS = 'SET_CANDIDATE_STATUS_SUCCESS';
export const SET_CANDIDATE_STATUS_ERROR = 'SET_CANDIDATE_STATUS_ERROR';
export const GET_DOWNLOAD_CANDIDATE = 'GET_DOWNLOAD_CANDIDATE';
export const GET_DOWNLOAD_CANDIDATE_SUCCESS = 'GET_DOWNLOAD_CANDIDATE_SUCCESS';
export const GET_DOWNLOAD_CANDIDATE_ERROR = 'GET_DOWNLOAD_CANDIDATE_ERROR';
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_ERROR = 'DELETE_CANDIDATE_ERROR';
