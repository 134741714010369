export const GENESIS_POST_ORDER = 'GENESIS_POST_ORDER';
export const GENESIS_POST_ORDER_SUCCESS = 'GENESIS_POST_ORDER_SUCCESS';
export const GENESIS_POST_ORDER_ERROR = 'GENESIS_POST_ORDER_ERROR';
export const GENESIS_GET_ORDER = 'GENESIS_GET_ORDER';
export const GENESIS_GET_ORDER_SUCCESS = 'GENESIS_GET_ORDER_SUCCESS';
export const GENESIS_GET_ORDER_ERROR = 'GENESIS_GET_ORDER_ERROR';
export const GENESIS_GET_ORDER_INCOMPLETE = 'GENESIS_GET_ORDER_INCOMPLETE';
export const GENESIS_GET_ORDER_INCOMPLETE_SUCCESS = 'GENESIS_GET_ORDER_INCOMPLETE_SUCCESS';
export const GENESIS_GET_ORDER_INCOMPLETE_ERROR = 'GENESIS_GET_ORDER_INCOMPLETE_ERROR';
export const GENESIS_RESET_ORDER_INCOMPLETE_SUCCESS = 'GENESIS_RESET_ORDER_INCOMPLETE_SUCCESS';
export const GENESIS_RESET_ORDER_SUCCESS = 'GENESIS_RESET_ORDER_SUCCESS';
