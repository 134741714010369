import * as yup from 'yup';

export const orderSchema = yup.object().shape({
    lineItems: yup.array().of(
        yup.object().shape({
            rejectedBy: yup.string(),
            details: yup.string(),
            diff: yup
                .number()
                .required()
                .test(
                    'is-zero',
                    'Si diff es 0, rejectedBy y details son opcionales',
                    function (value) {
                        const {rejectedBy, details} = this.parent;
                        if (value === 0) {
                            return rejectedBy === undefined && details === undefined;
                        }
                        return true;
                    }
                )
                .nullable(),
        })
    ),
    billNumber: yup.string().required('Factura requerida'),
});
