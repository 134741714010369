import {IRole} from '@/components/roles/interface';
import {
    ButtonMoreItems,
    Container,
    ItemsList,
    TypographyStyled400,
} from '@components/UI/molecules/LineClamp/styled-components/index.styled';
import React, {useState} from 'react';

interface ILineClampProps {
    items: any[];
}

const LineClamp: React.FC<ILineClampProps> = ({items}) => {
    const [openList, setOpenList] = useState(false);
    const itemFilter = items.filter((item: IRole) => item.enabled);
    const getList = () => {
        return itemFilter
            .slice(1, itemFilter.length)
            .map((item: IRole) => item.name)
            .join(', ');
    };

    if (itemFilter.length === 0) return <>(sin rol)</>;
    if (itemFilter.length === 1) return <span key={itemFilter[0].id}>{itemFilter[0].name}</span>;
    return (
        <Container>
            <TypographyStyled400>
                <span key={itemFilter[0].id}>{itemFilter[0].name}</span>
                <ButtonMoreItems onClick={() => setOpenList((prev) => !prev)}>
                    (+{itemFilter.length - 1})
                </ButtonMoreItems>
                {openList && <ItemsList>{getList()}</ItemsList>}
            </TypographyStyled400>
        </Container>
    );
};

export default LineClamp;
