export function assignBackgroundColor(
    prevOrderFindId: string,
    orderFindId: string,
    previousColor: string,
    prevItemBackgroundColor: string
) {
    let backgroundColor = previousColor || '#FFFFFF';
    if (prevOrderFindId) {
        if (orderFindId === prevOrderFindId) {
            backgroundColor = prevItemBackgroundColor;
        } else {
            backgroundColor = backgroundColor === '#F0F8FF' ? '#FFFFFF' : '#F0F8FF';
        }
    }
    previousColor = backgroundColor;
    prevItemBackgroundColor = backgroundColor;
    return {backgroundColor, previousColor, prevItemBackgroundColor};
}
