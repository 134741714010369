export const POST_MANUALS = 'POST_MANUALS';
export const POST_MANUALS_SUCCESS = 'POST_MANUALS_SUCCESS';
export const POST_MANUALS_ERROR = 'POST_MANUALS_ERROR';
export const GET_MANUALS = 'GET_USER_INFO';
export const GET_MANUALS_SUCCESS = 'GET_MANUALS_SUCCESS';
export const GET_MANUALS_ERROR = 'GET_MANUALS_ERROR';
export const GET_MANUALS_FILE = 'GET_MANUALS_FILE';
export const GET_MANUALS_FILE_SUCCESS = 'GET_MANUALS_FILE_SUCCESS';
export const GET_MANUALS_FILE_ERROR = 'GET_MANUALS_FILE_ERROR';
export const UPDATE_MANUALS = 'UPDATE_MANUALS';
export const UPDATE_MANUALS_SUCCESS = 'UPDATE_MANUALS_SUCCESS';
export const UPDATE_MANUALS_ERROR = 'UPDATE_MANUALS_ERROR';
export const DELETE_MANUALS = 'DELETE_MANUALS';
export const DELETE_MANUALS_SUCCESS = 'DELETE_MANUALS_SUCCESS';
export const DELETE_MANUALS_ERROR = 'DELETE_MANUALS_ERROR';
export const CREATE_FILE_MANUALS = 'CREATE_FILE_MANUALS';
export const CREATE_FILE_MANUALS_SUCCESS = 'CREATE_FILE_MANUALS_SUCCESS';
export const CREATE_FILE_MANUALS_ERROR = 'CREATE_FILE_MANUALS_ERROR';
export const GET_MANUALS_BYID = 'GET_MANUALS_BYID';
export const GET_MANUALS_BYID_SUCCESS = 'GET_MANUALS_BYID_SUCCESS';
export const GET_MANUALS_BYID_ERROR = 'GET_MANUALS_BYID_ERROR';
export const OPEN_MODAL_PREVIEW = 'OPEN_MODAL_PREVIEW';
export const CLOSE_MODAL_PREVIEW = 'CLOSE_MODAL_PREVIEW';
