import React from 'react';
import {Box} from '@mui/system';
import {TypographyStyled500} from '@/components/faqs/StyledFAQ';

interface StatusMessageProps {
    message: string;
    iconSrc?: string;
    color: string;
}

const StatusMessage: React.FC<StatusMessageProps> = ({message, iconSrc, color}) => {
    return (
        <Box sx={{padding: '10px'}}>
            <Box
                sx={{
                    marginLeft: 'center',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {iconSrc && <img alt="Status" src={iconSrc} style={{marginRight: '5px'}} />}
                <TypographyStyled500 sx={{color, marginRight: '10px'}}>
                    {message}
                </TypographyStyled500>
            </Box>
        </Box>
    );
};

export default StatusMessage;
