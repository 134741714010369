import {AnyAction, Dispatch, Middleware} from 'redux';

import {IItemNew} from '@/components/orderSimply/interfaces/item-new.interface';
import {fetchDownloadOrder} from '@/components/orderSimply/redux/actions/OrderSimplyDownloadActions';
import {
    CHECK_TERMS_AND_DOWNLOAD,
    SHOW_TERMS_MODAL,
    VERIFY_TERMS_AND_COMPLETE_ACTION,
} from '@/components/orderSimply/redux/types/OrdersSimplyTypes';
import {isEnabledTermsAndConditions} from '@/utils/envvars';

interface VerifyTermsAndCompleteAction {
    type: typeof VERIFY_TERMS_AND_COMPLETE_ACTION;
    payload: {externalIds: string[]; isTermsConditionsAccepted: boolean};
    completeAction: () => void;
}

interface CheckTermsAndDownloadAction {
    type: typeof CHECK_TERMS_AND_DOWNLOAD;
    payload: IItemNew | null;
}

interface ShowTermsModalAction {
    type: typeof SHOW_TERMS_MODAL;
    payload: IItemNew | null;
}

type TermsMiddlewareActions =
    | CheckTermsAndDownloadAction
    | ShowTermsModalAction
    | VerifyTermsAndCompleteAction;

interface State {
    logins: {accessToken: string};
    orderssimply: {orderssimply: IItemNew[]};
}

export const termsMiddleware: Middleware =
    ({dispatch, getState}) =>
    (next) =>
    (action: TermsMiddlewareActions) => {
        switch (action.type) {
            case CHECK_TERMS_AND_DOWNLOAD:
                handleCheckTermsAndDownload(dispatch, getState(), action);
                break;
            case VERIFY_TERMS_AND_COMPLETE_ACTION:
                handleValidateTermsAndFulfill(dispatch, action);
                break;
            default:
                return next(action);
        }
    };

const handleCheckTermsAndDownload = (
    dispatch: Dispatch<AnyAction>,
    state: State,
    action: CheckTermsAndDownloadAction
) => {
    const token = getToken(state);
    const rowData = action.payload;

    if (rowData) {
        if (!isEnabledTermsAndConditions || rowData.is_terms_conditions_accepted) {
            fetchDownloadOrder(rowData.purchase_order_id, token)(dispatch);
        } else {
            dispatch({type: SHOW_TERMS_MODAL, payload: rowData});
        }
    }
};

const getToken = (state: State): string => state.logins.accessToken;

const handleValidateTermsAndFulfill = (
    dispatch: Dispatch<AnyAction>,
    action: VerifyTermsAndCompleteAction
) => {
    const data = action.payload;
    const {completeAction} = action;

    if (!isEnabledTermsAndConditions) {
        runAction(completeAction);
        return;
    }

    if (data && data.isTermsConditionsAccepted) {
        if (completeAction) completeAction();
    } else {
        dispatch({type: SHOW_TERMS_MODAL, payload: data});
    }
};

const runAction = (action: () => void) => {
    if (action) action();
};
