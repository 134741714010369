import {parseDateFormat} from '@/utils/FormatDate';
import {Box, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface PositionsRowProps {
    productName: string;
    position: number;
    deliveryDate: string;
    unit: string;
    quantity: number;
    unitPrice: number;
}

const PositionsRow: React.FC<PositionsRowProps> = (data): React.JSX.Element => {
    const {t} = useTranslation();
    const {productName, position, deliveryDate, unit, quantity, unitPrice} = data;
    const textStyle = {
        fontSize: '16px',
    };

    return (
        <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: 2, p: 2}}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    minWidth: 0,
                    gap: 1,
                    alignItems: 'center',
                    flexGrow: 1,
                    '& > *': {minWidth: 'clamp(0px, (240px + 1px - 100%) * 999, 100%)'},
                }}
            >
                <Box sx={{flexGrow: 1}}>
                    <Typography
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                            fontWeight: 600,
                            fontSize: '1rem',
                            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#122740'),
                            paddingBottom: '5px',
                        }}
                    >
                        <b>{productName}</b>
                    </Typography>
                    <Typography noWrap variant="body2" sx={textStyle}>
                        {t('confirmpoaction.positions.position')}: {position}
                    </Typography>
                    <Typography noWrap variant="body2" sx={textStyle}>
                        {t('confirmpoaction.positions.delivery_date')}:{' '}
                        {parseDateFormat(deliveryDate, 'es')}
                    </Typography>
                    <Typography noWrap variant="body2" sx={textStyle}>
                        {t('confirmpoaction.positions.unit')}: {unit}
                    </Typography>
                    <Typography noWrap variant="body2" sx={textStyle}>
                        {t('confirmpoaction.positions.quantity')}: {quantity}
                    </Typography>
                    <Typography noWrap variant="body2" sx={textStyle}>
                        {t('confirmpoaction.positions.unit_price')}:{' '}
                        {unitPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PositionsRow;
