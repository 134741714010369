import axios from 'axios';
import {
    GET_PURCHASE_ORDER,
    GET_PURCHASE_ORDER_SUCCESS,
    GET_PURCHASE_ORDER_ERROR,
    UPDATED_PURCHASE_ORDER,
    UPDATED_PURCHASE_ORDER_SUCCESS,
    UPDATED_PURCHASE_ORDER_ERROR,
    CLEAR_PURCHASE_ORDER,
} from '@/components/purchase-order/redux/types/PurchaseOrder';

export const getGetPurchaseOrder = () => {
    return {
        type: GET_PURCHASE_ORDER,
    };
};
export const getGetPurchaseOrderSuccess = (result: any) => {
    return {
        type: GET_PURCHASE_ORDER_SUCCESS,
        value: result,
    };
};
export const getGetPurchaseOrderError = (error: boolean, result?: any) => {
    return {
        type: GET_PURCHASE_ORDER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetPurchaseOrder(token: string, id: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(getGetPurchaseOrder());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${id}`,
                {headers}
            );
            dispatch(getGetPurchaseOrderSuccess(response.data));
        } catch (error) {
            dispatch(getGetPurchaseOrderError(true));
        }
    };
}

export function fetchGetPurchaseOrderToConfirm(token: string, id: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(getGetPurchaseOrder());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/to-confirm/${id}`,
                {headers}
            );
            dispatch(getGetPurchaseOrderSuccess(response.data));
        } catch (error) {
            dispatch(getGetPurchaseOrderError(true));
        }
    };
}

export const getUpdatePurchaseOrder = () => {
    return {
        type: UPDATED_PURCHASE_ORDER,
    };
};
export const getUpdatePurchaseOrderSuccess = (result: any) => {
    return {
        type: UPDATED_PURCHASE_ORDER_SUCCESS,
        value: result,
    };
};
export const getUpdatePurchaseOrderError = (error: boolean, result?: any) => {
    return {
        type: UPDATED_PURCHASE_ORDER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutPurchaseOrder(token: string, data: any, id?: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(getUpdatePurchaseOrder());
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${id}/confirm`,
                {...data},
                {headers}
            );
            dispatch(getUpdatePurchaseOrderSuccess(response.data));
        } catch (error) {
            dispatch(getUpdatePurchaseOrderError(true));
        }
    };
}

export const clearPurchaseOrderActive = () => {
    return {
        type: CLEAR_PURCHASE_ORDER,
    };
};
