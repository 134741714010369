import {fetchGetPurchaseOrderToConfirm} from '@/components/purchase-order/redux/actions/PurchaceOrderActions';
import {AppDispatch, RootState} from '@/config/store';
import RejectModal from '@components/orderSimply/modals/RejectModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, IconButton, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {FF_SHOW_PO_DETAILS} from '@/utils/envvars';
import {Position} from '@/components/orderSimply/components/Positions';
import ConfirmActionDefault from '@/components/orderSimply/components/ConfirmActionDefault';
import ConfirmActionWithDetails from '@/components/orderSimply/components/ConfirmActionWithDetails';

const OrderConfirmAction = ({orderNumber = ''}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {orderId, group} = useParams();
    const navigate = useNavigate();

    const goToUrl = (url: string) => {
        navigate(url);
    };
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.purchaceOrderReducers.loading);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
    const [rejectedBy, setRejectedBy] = useState<string>('');
    const [isDisableAction, setIdDisableAction] = useState<boolean>(false);
    const [orderNum, setOrderNum] = useState<string>('');
    const [positions, setPositions] = useState<Position[]>([]);

    const confirmModalRejectFn = () => {
        setIsRejectModalOpen(false);
        goToUrl(`/purchase-order/${orderId}/reject/${group}?rejectedBy=${rejectedBy}`);
    };

    const purchaseOrder: any = useSelector(
        (state: RootState) => state.purchaceOrderReducers.purchaseOrder
    );
    const handleGoBack = () => {
        navigate(-1);
    };

    const goBackOrHome = () => {
        if (window.history?.length && window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/confirmposimply', {replace: true});
        }
    };

    useEffect(() => {
        if (token && orderId) {
            dispatch(fetchGetPurchaseOrderToConfirm(token, orderId));
        }
    }, [dispatch, token]);

    useEffect(() => {
        if (purchaseOrder) {
            const lineItems = processLineItems();

            setIdDisableAction(purchaseOrder.iterations >= 2);
            setOrderNum(orderNumber || purchaseOrder.referenceId);
            setPositions(lineItems);
        }

        function processLineItems() {
            return purchaseOrder.lineItems.map((item: any) => {
                const product = item.productDetails[0];
                return {
                    productName: product?.productName,
                    position: item.position,
                    deliveryDate: item.deliveryDate,
                    unit: product?.unit,
                    quantity: item.quantity,
                    unitPrice: product?.price,
                };
            });
        }
    }, [purchaseOrder]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 400,
                }}
            >
                <IconButton onClick={handleGoBack} aria-label="Go back">
                    <ArrowBackIcon />
                </IconButton>{' '}
                {t('confirmposimply.title')}
            </Typography>
            {FF_SHOW_PO_DETAILS ? (
                <ConfirmActionWithDetails
                    acceptedTerms={purchaseOrder && purchaseOrder.isTermsConditionsAccepted}
                    addressCode={purchaseOrder && purchaseOrder.addressCode}
                    purchaseOrderId={orderNumber || orderNum}
                    positions={positions}
                    isDisableAction={isDisableAction || loading}
                    setIsRejectModalOpen={setIsRejectModalOpen}
                    afterAction={goBackOrHome}
                />
            ) : (
                <ConfirmActionDefault
                    acceptedTerms={purchaseOrder && purchaseOrder.isTermsConditionsAccepted}
                    purchaseOrderId={orderNumber || orderNum}
                    isDisableAction={isDisableAction || loading}
                    setIsRejectModalOpen={setIsRejectModalOpen}
                    afterAction={goBackOrHome}
                />
            )}
            <RejectModal
                isModalOpen={isRejectModalOpen}
                closeModal={() => setIsRejectModalOpen(false)}
                rejectedBy={rejectedBy}
                setRejectedBy={setRejectedBy}
                confirmModalRejectFn={confirmModalRejectFn}
            />
        </Box>
    );
};

export default OrderConfirmAction;
