import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {DateTime} from 'luxon';
import * as yup from 'yup';
import {
    ButtonCancel,
    Loader,
    Nav,
    SaveButton,
    StyledModal,
    StyledTextField,
    TypographyStyled500,
} from '@/components/faqs/StyledFAQ';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@components/legalAgreements/StyledLegalAgreements';
import {
    fetchPatchFileCsf,
    fetchPostCsfAbi,
    fetchPostFileCsf,
    fetchPutCsfAbi,
    patchFileCsfError,
    patchFileCsfSuccess,
} from '@components/csf/csfABI/redux/actions/CsfABIActions';
import {fetchPostCsfSupplier} from '@components/csf/csfSupplier/redux/actions/CsfSupplierActions';
import {useTranslation} from 'react-i18next';
import ErrorToast from '@/components/auth/componenents/ErrorToast';

const MAX_FILE_SIZE_MB = 10;
const ALLOWED_FILE_TYPES = ['application/pdf'];
const TEXT_NEW = 'nueva';
const TEXT_ABI = 'abi';

const ModalCSF = ({isModalOpen, closeModal, item = {}, method, type}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.csfAbi.loading);
    const loadingSupplier = useSelector((state) => state.csfSupplier.loading);
    const fileData = useSelector((state) => state.csfAbi.fileData);
    const errorFileData = useSelector((state) => state.csfAbi.errorFile);
    const token = useSelector((state) => state.logins.accessToken);
    const user = useSelector((state) => state.logins.user);
    const [errorFile, setErrorFile] = useState('');

    const schemaFormModal = yup.object({
        externalId: yup.string().required(t('csf.form_actions.external_id')),
        rfc: yup.string(),
        businessName: yup.string(),
        file: yup.mixed().required(t('csf.form_actions.file')),
    });

    const schemaFormModalSupplier = yup.object({
        file: yup.mixed().required(t('csf.form_actions.file')),
        taxRegimen: yup.string().required(t('csf.form_actions.tax_regime')),
        economyActivity: yup.string().required(t('csf.data_csf.economic_activities')),
    });

    const {handleSubmit, control, reset} = useForm({
        resolver: yupResolver(type === TEXT_ABI ? schemaFormModal : schemaFormModalSupplier),
    });

    const handleFileChange = (event, type) => {
        event.stopPropagation();
        const file = type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0];
        if (!file) {
            return;
        } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setErrorFile(t('csf.validation_messages.invalid_pdf_file'));
            reset({
                rfc: '',
                businessName: '',
                file: null,
            });
            dispatch(patchFileCsfSuccess({}));
        } else if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(
                `${t('csf.validation_messages.file_size_1')} ${MAX_FILE_SIZE_MB} ${t(
                    'csf.validation_messages.file_size_2'
                )}`
            );
            reset({
                rfc: '',
                businessName: '',
                file: null,
            });
            dispatch(patchFileCsfSuccess({}));
        } else {
            setErrorFile('');
            dispatch(fetchPatchFileCsf(token, file));
        }
    };

    const fetchSuppliersModalData = (item, fileData) => {
        const defaultTaxProfile = item?.enterprise?.companies[0]?.taxProfiles[0] || {};
        const defaultAddress = defaultTaxProfile.address || {};
        const EMPTY_STRING = '';

        const getData = (fileKey, profileKey = fileKey, source = defaultTaxProfile) => {
            return fileData?.[fileKey] ?? source[profileKey] ?? EMPTY_STRING;
        };

        return {
            curp: getData('curp'),
            rfc: getData('taxId'),
            businessName: getData('businessName'),
            taxRegime: getData('taxRegimes'),
            zipCode: getData('zipCode', 'zipCode', defaultAddress),
        };
    };

    const onSubmit = async (data) => {
        const commonProfile = {
            businessName: fileData.businessName,
            taxId: fileData.taxId,
        };

        const formattedAddress = {
            streetName: fileData.address.nameRoad,
            streetNumber: fileData.address.outdoorNumber,
            interiorNumber: '',
            neighborhood: fileData.address.nameColony,
            zipCode: fileData.zipCode,
            municipality: fileData.address.municipalityName,
            city: fileData.address.entityName,
            country: 'Mexico', //TODO: remove this hardcode
            state: fileData.address.entityName,
            betweenStreets:
                fileData.address.nameBetweenStreet + ' y ' + fileData.address.streetName,
            references: '',
        };

        const json =
            type === TEXT_ABI
                ? {
                      name: fileData.businessName,
                      externalId: data.externalId,
                      taxProfiles: [
                          {
                              ...commonProfile,
                              address: formattedAddress,
                              taxRegimen: data.taxRegimen,
                              economyActivity: data.economyActivity,
                          },
                      ],
                  }
                : {
                      taxProfile: [
                          {
                              ...commonProfile,
                              curp: fileData.curp,
                              address: formattedAddress,
                              expeditionDate: fileData.expeditionDate || '',
                              taxRegimes: [data.taxRegimen],
                              expeditionPlace: fileData.expeditionPlace,
                              lastStatusChange: fileData.lastStatusChange,
                              economyActivities: [data.economyActivity],
                          },
                      ],
                  };

        try {
            if (type === TEXT_ABI) {
                await handleAbiSubmission(data, json);
            } else {
                await handleSupplierSubmission(data, json);
            }
            handleCloseModal();
        } catch (error) {
            if (error.message.includes('409')) {
                if (error.response.data.error === 'enterprise.already_exists') {
                    ErrorToast({title: t('csf.company_already_exists')});
                }
            }
            return error;
        }
    };

    const handleAbiSubmission = async (data, json) => {
        if (method === TEXT_NEW) {
            await dispatch(fetchPostCsfAbi(token, json, data.file, user));
        } else if (hasData(item.taxProfiles) && item.taxProfiles[0].taxId) {
            await dispatch(fetchPostFileCsf(token, item.id, data.file, user));
        } else {
            const aux = {
                taxProfiles: [
                    {
                        businessName: item.name,
                        taxId: fileData.taxId,
                    },
                ],
            };
            await dispatch(fetchPutCsfAbi(token, item.id, aux, data.file, user));
        }
    };

    const handleSupplierSubmission = async (data, json) => {
        await dispatch(
            fetchPostCsfSupplier(token, item.enterprise.companies[0].id, json, data.file)
        );
    };

    const handleCloseModal = () => {
        closeModal();
        reset({
            externalId: '',
            rfc: '',
            businessName: '',
            file: null,
        });
        setErrorFile('');
        dispatch(patchFileCsfSuccess({}));
        dispatch(patchFileCsfError(false));
    };

    const hasData = (data) => Object.keys(data).length > 0;
    const getBusinessName = () => {
        return hasData(fileData) ? fileData.businessName : '';
    };

    const isNewFile = method !== TEXT_NEW;
    useEffect(() => {
        const expeditionDate = DateTime.fromISO(fileData.expeditionDate, {zone: 'utc'});
        const elapsedMonths = Math.abs(expeditionDate.diffNow('months').months);

        const isExpired = elapsedMonths > 6;

        if (isExpired) {
            setErrorFile(isExpired ? t('csf.validation_messages.expired_csf') : '');
            if (isExpired) {
                reset({
                    file: null,
                });
            }
            return;
        }
        if (isNewFile && hasData(fileData) && item.taxProfiles?.length > 0) {
            const isContentDiferent =
                item.name !== fileData.businessName && item.taxProfiles[0].taxId !== fileData.taxId;
            setErrorFile(isContentDiferent ? t('csf.validation_messages.invalid_csf_1') : '');
            if (isContentDiferent) {
                reset({
                    file: null,
                });
            }
        }
    }, [fileData, isNewFile]);

    useEffect(() => {
        if (errorFileData) {
            setErrorFile(t('csf.validation_messages.invalid_csf_2'));
            reset({
                rfc: '',
                businessName: '',
                file: null,
            });
        }
    }, [errorFileData]);

    useEffect(() => {
        if (item && hasData(item) && isModalOpen && method !== TEXT_NEW) {
            reset({
                externalId: item.externalId,
            });
        }
    }, [item, isModalOpen]);

    const dataCsf = () => {
        if (type === TEXT_ABI) {
            return (
                <>
                    <Controller
                        render={({field, fieldState}) => (
                            <StyledTextField
                                {...field}
                                id="outlined-required"
                                label={t('csf.data_csf.society_id')}
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                                disabled={method !== TEXT_NEW}
                            />
                        )}
                        name="externalId"
                        control={control}
                    />

                    <StyledTextField
                        id="outlined-disabled"
                        disabled
                        value={
                            method === TEXT_NEW
                                ? hasData(fileData)
                                    ? fileData.taxId
                                    : ''
                                : item?.name
                        }
                        sx={{marginTop: '10px'}}
                    />
                    <StyledTextField
                        id="outlined-disabled"
                        disabled
                        value={
                            method === TEXT_NEW
                                ? getBusinessName()
                                : item?.taxProfiles?.length > 0
                                ? item.taxProfiles[0].taxId
                                : hasData(fileData)
                                ? fileData.taxId
                                : ''
                        }
                        sx={{marginTop: '10px'}}
                    />
                </>
            );
        } else {
            const suppliersModalData = fetchSuppliersModalData(item, fileData);
            return (
                <>
                    <Controller
                        name="taxRegimen"
                        control={control}
                        render={({field, fieldState}) => (
                            <FormControl sx={{margin: '10px 0 10px 0'}}>
                                <InputLabel>{t('csf.data_csf.regimes')}</InputLabel>
                                <Select
                                    label={t('csf.data_csf.regime')}
                                    {...field}
                                    error={!!fieldState.error}
                                >
                                    {fileData &&
                                        fileData.taxRegimes &&
                                        fileData.taxRegimes.length > 0 &&
                                        fileData.taxRegimes.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="economyActivity"
                        control={control}
                        render={({field, fieldState}) => (
                            <FormControl>
                                <InputLabel>{t('csf.data_csf.economic_activities')}</InputLabel>
                                <Select
                                    label={t('csf.data_csf.economic_activities')}
                                    {...field}
                                    error={!!fieldState.error}
                                >
                                    {fileData &&
                                        fileData.economyActivities &&
                                        fileData.economyActivities.length > 0 &&
                                        fileData.economyActivities.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <StyledTextField
                        id="outlined-disabled"
                        disabled
                        value={suppliersModalData.curp}
                        sx={{marginTop: '10px'}}
                        label={t('csf.data_csf.curp')}
                        size="small"
                    />
                    <StyledTextField
                        id="outlined-disabled"
                        disabled
                        value={suppliersModalData.rfc}
                        sx={{marginTop: '10px'}}
                        label={t('csf.data_csf.rfc')}
                        size="small"
                    />
                    <StyledTextField
                        id="outlined-disabled"
                        disabled
                        value={suppliersModalData.businessName}
                        sx={{marginTop: '10px'}}
                        label={t('csf.data_csf.company_name')}
                        size="small"
                    />
                    <StyledTextField
                        id="outlined-disabled"
                        disabled
                        value={suppliersModalData.taxRegime}
                        sx={{marginTop: '10px'}}
                        label={t('csf.data_csf.regime')}
                        size="small"
                    />
                    <StyledTextField
                        id="outlined-disabled"
                        disabled
                        value={suppliersModalData.zipCode}
                        sx={{marginTop: '10px'}}
                        label={t('csf.data_csf.zip_code')}
                        size="small"
                    />
                </>
            );
        }
    };

    return (
        <StyledModal open={isModalOpen} onClose={handleCloseModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    height: '700px',
                    zIndex: '100',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {method === TEXT_NEW ? t('csf.new') : t('csf.edit')} {t('csf.csf_title')}
                    </Typography>
                </Nav>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '20px 30px',
                            height: '450px',
                        }}
                    >
                        <TypographyStyled500>{t('csf.upload_your_csf')}</TypographyStyled500>
                        <Controller
                            name="file"
                            control={control}
                            defaultValue={null}
                            render={({field, fieldState}) => (
                                <FileUploadContainer
                                    sx={{
                                        minHeight: '100px',
                                        margin: '10px 0',
                                        borderColor: fieldState.error ? 'red' : 'none',
                                    }}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        const file = e.dataTransfer.files[0];
                                        if (file) field.onChange(file);
                                        handleFileChange(e, 'drop');
                                    }}
                                    onClick={() => document.getElementById('fileInput')?.click()}
                                >
                                    <StyledInput
                                        id="fileInput"
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) field.onChange(file);
                                            handleFileChange(e, '');
                                        }}
                                    />
                                    {field.value && !errorFile ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography variant="subtitle2">
                                                {t('csf.selected_file')}: {field.value.name}
                                            </Typography>
                                            <IconButton
                                                aria-label={t('csf.buttons.delete')}
                                                onClick={(e) => {
                                                    field.onChange(null);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <StyledFileLabelButton variant="outlined" as="span">
                                                <img src={CloudIcon} />
                                                <StyledLabelFileUpload>
                                                    {t('drag_upload_files')}
                                                </StyledLabelFileUpload>
                                            </StyledFileLabelButton>
                                            <Typography variant="caption" color="error">
                                                {errorFile}
                                            </Typography>
                                        </Box>
                                    )}
                                </FileUploadContainer>
                            )}
                        />
                        {dataCsf()}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height: '56px',
                            alignItems: 'center',
                            marginTop: type === TEXT_ABI ? '120px' : '160px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonCancel onClick={handleCloseModal}>
                            {t('csf.buttons.cancel').toUpperCase()}
                        </ButtonCancel>
                        <SaveButton
                            sx={{marginRight: '30px'}}
                            disabled={
                                (!hasData(fileData) && !errorFile === '') ||
                                loading ||
                                loadingSupplier
                            }
                        >
                            {loading || loadingSupplier ? (
                                <Loader size={24} />
                            ) : (
                                t('csf.buttons.save').toUpperCase()
                            )}
                        </SaveButton>
                    </Box>
                </form>
            </Box>
        </StyledModal>
    );
};

export default ModalCSF;
