import {Box, Button, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

export const FileUploadContainer = styled('div')`
    display: flex;
    border: 1px solid #c5d1d8;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledFileLabelButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledLabelFileUpload = styled(Typography)`
    font-family: Mulish;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
`;

export const StyledBoxData = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    borderRadius: '0',
    borderBottom: '1px solid #C2D1D9',
    borderLeft: '1px solid #C2D1D9',
    borderRight: '1px solid #C2D1D9',
    boxShadow: 'none',
    background: '#FFFFFF',
});

export const StyledInput = styled('input')`
    display: none;
`;
