import {styled} from '@mui/material/styles';

export const MenuItem = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#2D313F',
    height: '50px',
    boxSizing: 'border-box',
    paddingLeft: '13px',
    '&:hover': {
        cursor: 'pointer',
        background: theme.palette.primary.main,
        color: '#000000',
    },
}));
export const MenuItemBorder = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#2D313F',
    height: '50px',
    boxSizing: 'border-box',
    paddingLeft: '8px',
    '&:hover': {
        cursor: 'pointer',
        background: theme.palette.primary.main,
        color: '#000000',
    },
}));
export const MenuItemLight = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'white',
    height: '50px',
    boxSizing: 'border-box',
    paddingLeft: '13px',
    '&:hover': {
        cursor: 'pointer',
        background: '#C1C1C1',
        color: '#101223',
        opacity: '0.6',
    },
});
