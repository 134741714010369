import {AddButton} from '@components/UI/atoms/buttons/ButtonPrincipal';
import SkeletonRow from '@components/UI/molecules/tables/SkeletonRow';
import {TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import {
    DeleteListButton,
    EditListButton,
    SaveButton,
    TableCellPrincipal,
} from '@components/faqs/StyledFAQ';
import ModalFAQ from '@components/faqs/modals/ModalFAQ';
import FAQPopper from '@components/faqs/popper/FAQPopper';
import FAQPopperComunication from '@components/faqs/popper/FAQPopperComunication';
import {fetchGetFaqs} from '@components/faqs/redux/actions/FAQsActions';
import {fetchGetRoles} from '@components/roles/redux/actions/RolesActions';
import {AppDispatch, RootState} from '@config/store';
import {Box, Divider, Grid, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, FrequentQuestionsEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {ACLObj} from '@components/auth/guard/AclGuard';
import {useTranslation} from 'react-i18next';
interface ITable {
    question: string;
    answer: string;
    roles: IRole[];
    countryId: string;
}
interface IRole {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
}

interface IDelete {
    id: number;
    checked: boolean;
}
const FAQs: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const hasConfiguration = useConfigurationUrl();

    const token = useSelector((state: RootState) => state.logins.accessToken);
    const faqs = useSelector((state: RootState) => state.faqs.faqs);
    const currentPage = useSelector((state: RootState) => state.faqs.page);
    const totalPages = useSelector((state: RootState) => state.faqs.totalPages);
    const loading = useSelector((state: RootState) => state.faqs.loading);
    const limit = useSelector((state: RootState) => state.faqs.limit);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [method, setMethod] = useState<string>('');
    const [editList, setEditList] = useState<boolean>(false);
    const [listDelete, setlistDelete] = useState<number[]>([]);
    const [modifyItem, setModifyItem] = useState<ITable>();

    const {notAllowed} = useCheckAbilities();

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetFaqs(token, value, limit));
    };

    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetFaqs(token, 1, event.target.value));
    };

    const openModal = (type: string) => {
        setModalOpen(true);
        setMethod(type);
    };

    const closeModal = () => {
        setModalOpen(false);
        setMethod('');
    };

    const setItemDelete = (item: IDelete) => {
        if (item.checked) {
            setlistDelete((prev) => [...prev, item.id]);
        } else {
            const filterArray = listDelete.filter((e) => e !== item.id);
            setlistDelete(filterArray);
        }
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchGetFaqs(token, currentPage, limit));
            dispatch(fetchGetRoles(token, 1, 0));
        }
    }, [token]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '27px 23px',
                overflow: 'auto',
            }}
        >
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '24px',
                    fontWeight: 400,
                }}
            >
                {t('faqs.title')}
            </Typography>
            {hasConfiguration && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <Can I={FrequentQuestionsEnum.EDIT} a={FeatureCodeEnum.FREQUENT_QUESTIONS}>
                            <EditListButton onClick={() => setEditList((prev) => !prev)}>
                                {t('faqs.edit_listing').toUpperCase()}
                            </EditListButton>
                        </Can>
                        <Can
                            I={FrequentQuestionsEnum.CREATE}
                            a={FeatureCodeEnum.FREQUENT_QUESTIONS}
                        >
                            <AddButton
                                sx={{marginBottom: '20px'}}
                                onClick={() => openModal('Nueva')}
                                disabled={notAllowed(
                                    FeatureCodeEnum.FREQUENT_QUESTIONS,
                                    FrequentQuestionsEnum.CREATE
                                )}
                            >
                                {t('faqs.new_question').toUpperCase()}
                            </AddButton>
                        </Can>
                    </Box>
                    <Divider sx={{borderBottomWidth: '1px', background: '#000000'}} />
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '20px',
                            fontWeight: 400,
                            color: '#000000',
                            margin: '20px 0px',
                        }}
                    >
                        {t('faqs.question_editing')}
                    </Typography>
                </>
            )}

            <TableContainer
                container
                sx={{marginTop: !hasConfiguration ? '30px' : '0px'}}
                width={hasConfiguration ? '120%' : '100%'}
            >
                {hasConfiguration && (
                    <>
                        {editList && (
                            <Grid item xs={1}>
                                <TableCellPrincipal
                                    sx={{
                                        borderLeft: '1px solid #C2D1D9',
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={2}>
                            <TableCellPrincipal
                                sx={{
                                    borderLeft: editList ? '0px' : '1px solid #C2D1D9',
                                }}
                            >
                                {t('faqs.question')}
                            </TableCellPrincipal>
                        </Grid>
                        <Grid item xs={4}>
                            <TableCellPrincipal>{t('faqs.response')}</TableCellPrincipal>
                        </Grid>
                        <Grid item xs={1}>
                            <TableCellPrincipal>{t('faqs.assigned_role')}</TableCellPrincipal>
                        </Grid>
                        <Grid item xs={1}>
                            <TableCellPrincipal>{t('country')}</TableCellPrincipal>
                        </Grid>
                        {editList ? (
                            <Grid item xs={1}>
                                <TableCellPrincipal
                                    sx={{
                                        borderRight: '1px solid #C2D1D9',
                                    }}
                                />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={1}>
                                    <TableCellPrincipal>{t('csf.update_date')}</TableCellPrincipal>
                                </Grid>
                                <Grid item xs={1}>
                                    <TableCellPrincipal
                                        sx={{
                                            borderRight: '1px solid #C2D1D9',
                                        }}
                                    >
                                        {t('user')}
                                    </TableCellPrincipal>
                                </Grid>
                            </>
                        )}
                    </>
                )}

                {loading && (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                )}
                {faqs.map((item: ITable, index: number) => {
                    return hasConfiguration ? (
                        <FAQPopper
                            key={index}
                            isModalOpen={isModalOpen}
                            openModal={openModal}
                            item={item}
                            editList={editList}
                            setItemDelete={setItemDelete}
                            setModifyItem={setModifyItem}
                        />
                    ) : (
                        <Can
                            key={index}
                            I={FrequentQuestionsEnum.SEE_LIST}
                            a={FeatureCodeEnum.FREQUENT_QUESTIONS}
                        >
                            <FAQPopperComunication key={index} item={item} />
                        </Can>
                    );
                })}
            </TableContainer>

            {editList ? (
                <Box sx={{textAlign: 'end'}}>
                    <DeleteListButton
                        sx={{
                            border:
                                listDelete.length > 0 ? '1px solid #00ACFF' : '1px solid #A3B0BB',
                            color: '#00ACFF',
                        }}
                        disabled={listDelete.length > 0 ? false : true}
                        onClick={() => openModal('Eliminar')}
                    >
                        {t('faqs.delete_list')}
                    </DeleteListButton>
                    <SaveButton onClick={() => setEditList((prev) => !prev)}>
                        {' '}
                        {t('save').toUpperCase()}
                    </SaveButton>
                </Box>
            ) : (
                <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChange={handleChange}
                    limit={limit}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
            <ModalFAQ
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                method={method}
                listDelete={listDelete}
                setlistDelete={setlistDelete}
                item={modifyItem}
            />
        </Box>
    );
};
export default FAQs;
