'use client';
import iconDefaultImage from '@/images/iconDefaultImage.svg';
import {styled} from '@mui/system';
import React, {useEffect, useState} from 'react';
export type ImageProps = {
    src: string;
};
export const ContainerImage = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '185px',
    width: ' 185px',
    border: ' 1px solid #ccc',
    borderRadius: '6px',
    margin: '20px',
}));
const Image: React.FC<ImageProps> = ({src}) => {
    const [srcLocal, setSrcLocal] = useState('');

    const handleError = () => {
        setSrcLocal(iconDefaultImage);
    };
    useEffect(() => {
        setSrcLocal(src);
    }, [src]);
    return (
        <ContainerImage>
            <img
                src={srcLocal}
                alt="ABInBev Logo"
                className="logo"
                style={{width: '100%', height: '100%'}}
                onError={handleError}
            />
        </ContainerImage>
    );
};

export default Image;
