import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@/components/UI/molecules/modals/WarningUpdateModal';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, FiscalRequirementsEnum} from '@/enums/permissions.enum';
import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import IconEdit from '@components/UI/atoms/icons/IconEdit.svg';
import {TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import {ButtonCancel, Loader, SaveButton, TableCellPrincipal} from '@components/faqs/StyledFAQ';
import {StyledBoxData} from '@components/legalAgreements/StyledLegalAgreements';
import ViewHtml from '@components/taxRequirements/ViewHtml';
import {
    fetchGetTaxRequirements,
    fetchPostTaxRequirements,
    fetchPutTaxRequirements,
} from '@components/taxRequirements/redux/actions/TaxRequirementsActions';
import {AppDispatch, RootState} from '@config/store';
import {Box, Divider, Grid, Typography, useTheme} from '@mui/material';
import {ContentState, EditorState, convertToRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, {useEffect, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

const TaxRequirements: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const hasConfiguration = useConfigurationUrl();

    const token = useSelector((state: RootState) => state.logins.accessToken);
    const taxRequirements = useSelector(
        (state: RootState) => state.taxRequirements.taxRequirements
    );
    const loading = useSelector((state: RootState) => state.taxRequirements.loading);
    const error = useSelector((state: RootState) => state.taxRequirements.error);

    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [responseValidation, setResponseValidation] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
    const [flag, setFlag] = useState<boolean>(false);

    const editRequirements = () => {
        setEdit((prev) => !prev);
        if (taxRequirements && Object.keys(taxRequirements).length > 0) {
            const blocksFromHtml = htmlToDraft(taxRequirements.text);
            const {contentBlocks, entityMap} = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        } else {
            setEditorState(EditorState.createEmpty());
        }
    };

    const onSubmit = async () => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        try {
            if (taxRequirements && taxRequirements.id) {
                await dispatch(fetchPutTaxRequirements(token, taxRequirements.id, html.toString()));
            } else {
                await dispatch(fetchPostTaxRequirements(token, html.toString()));
            }
            setEdit((prev) => !prev);
        } catch (error) {
            setResponseValidation(true);
            setModalOpen(true);
        } finally {
            setFlag((prev) => !prev);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setResponseValidation(false);
    };

    useEffect(() => {
        if (!loading && flag) {
            if (error) {
                setResponseValidation(true);
                setModalOpen(true);
            } else if (!error) {
                setResponseValidation(false);
                setModalOpen(true);
            }
            setFlag((prev) => !prev);
        }
    }, [error, loading]);

    useEffect(() => {
        if (token) {
            dispatch(fetchGetTaxRequirements(token));
        }
    }, [token]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '27px 23px',
                overflow: 'auto',
            }}
        >
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '24px',
                    fontWeight: 400,
                }}
            >
                {t('taxrequirements.title')}
            </Typography>
            {hasConfiguration && (
                <Divider
                    sx={{borderBottomWidth: '1px', background: '#000000', marginTop: '40px'}}
                />
            )}
            <TableContainer container sx={{paddingTop: '30px', width: 'auto'}}>
                <Grid item xs={12}>
                    <TableCellPrincipal
                        sx={{
                            borderLeft: '1px solid #C2D1D9',
                            borderRight: '1px solid #C2D1D9',
                        }}
                    >
                        {hasConfiguration ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                {t('taxrequirements.edit')}
                                <Can
                                    I={FiscalRequirementsEnum.EDIT}
                                    a={FeatureCodeEnum.FISCAL_REQUIREMENTS}
                                >
                                    <img
                                        onClick={editRequirements}
                                        src={IconEdit}
                                        title={t('manuals.modals.edit')}
                                    />
                                </Can>
                            </Box>
                        ) : (
                            <span> {t('taxrequirements.title')}</span>
                        )}
                    </TableCellPrincipal>
                </Grid>
                <Grid item xs={12}>
                    <StyledBoxData>
                        {edit ? (
                            <div className="editor" style={{border: '1px solid #C5D1D8'}}>
                                <Editor
                                    defaultEditorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    localization={{
                                        locale: 'es',
                                    }}
                                />
                            </div>
                        ) : (
                            <ViewHtml html={taxRequirements?.text} />
                        )}
                    </StyledBoxData>
                </Grid>
            </TableContainer>
            {edit && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={() => setEdit((prev) => !prev)}>
                        {' '}
                        {t('cancel').toUpperCase()}
                    </ButtonCancel>
                    <SaveButton disabled={loading} onClick={onSubmit}>
                        {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                    </SaveButton>
                </Box>
            )}
            {isModalOpen && (
                <>
                    {responseValidation ? (
                        <WarningUpdateModal isModalOpen={isModalOpen} closeModal={closeModal} />
                    ) : (
                        <SuccessUpdateModal isModalOpen={isModalOpen} closeModal={closeModal} />
                    )}
                </>
            )}
        </Box>
    );
};

export default TaxRequirements;
