import React from 'react';
import {RequestPageOutlined} from '@mui/icons-material';
import {Box, Typography} from '@mui/material';
import {useSelector} from 'react-redux';

export const XmlFileHeader = () => {
    const xmlFile = useSelector((state) => state.complement.xmlFile);
    return (
        <Box
            sx={{
                display: 'flex',
                border: '2px solid #c5d1d8',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px 0px',
                padding: '10px',
            }}
        >
            <RequestPageOutlined fontSize="large" />
            <Typography variant="subtitle2">{xmlFile.name && xmlFile.name}</Typography>
        </Box>
    );
};
