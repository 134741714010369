import {bannersEndpoint} from '@/components/banners/constants/actions';

import {
    GET_BANNERS,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_ERROR,
    POST_BANNERS,
    POST_BANNERS_SUCCESS,
    POST_BANNERS_ERROR,
    UPDATE_BANNERS,
    UPDATE_BANNERS_SUCCESS,
    UPDATE_BANNERS_ERROR,
    DELETE_BANNERS,
    DELETE_BANNERS_SUCCESS,
    DELETE_BANNERS_ERROR,
    CREATE_FILE_BANNERS,
    CREATE_FILE_BANNERS_SUCCESS,
    CREATE_FILE_BANNERS_ERROR,
    GET_BANNERS_BYID,
    GET_BANNERS_BYID_SUCCESS,
    GET_BANNERS_BYID_ERROR,
    CLOSE_MODAL_PREVIEW,
    OPEN_MODAL_PREVIEW,
} from '@/components/banners/redux/types/BannersTypes';

import axios from 'axios';
export const getBanners = () => {
    return {
        type: GET_BANNERS,
    };
};
export const getBannersSuccess = (result) => {
    return {
        type: GET_BANNERS_SUCCESS,
        value: result,
    };
};
export const getBannersError = (error, result) => {
    return {
        type: GET_BANNERS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetBanners(token, page, limit) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getBanners());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}${bannersEndpoint}?limit=${limit}&page=${page}&sortOrder=DESC`,
                {headers}
            );
            dispatch(getBannersSuccess(response.data));
        } catch (error) {
            dispatch(getBannersError(true));
        }
    };
}

export const postFileBanners = () => {
    return {
        type: CREATE_FILE_BANNERS,
    };
};
export const postFileBannersSuccess = (result) => {
    return {
        type: CREATE_FILE_BANNERS_SUCCESS,
        value: result,
    };
};
export const postFileBannersError = (error, result) => {
    return {
        type: CREATE_FILE_BANNERS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostFileBanners(token, URL, data) {
    return async (dispatch) => {
        dispatch(postFileBanners());
        try {
            const response = await axios.put(`${URL}`, data, {
                headers: {'Content-Type': data.type},
            });
            dispatch(postFileBannersSuccess(response.data.data));
        } catch (error) {
            dispatch(postFileBannersError(true));
        }
    };
}

export const postBanners = () => {
    return {
        type: POST_BANNERS,
    };
};
export const postBannersSuccess = (result) => {
    return {
        type: POST_BANNERS_SUCCESS,
        value: result,
    };
};
export const postBannersError = (error, result) => {
    return {
        type: POST_BANNERS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostBanners(token, data, dataform, file) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postBanners());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}${bannersEndpoint}`,
                data,
                {
                    headers,
                }
            );
            dispatch(postBannersSuccess(response.data.data));
            dispatch(fetchPostFileBanners(token, response.data.data.url, file));
            dispatch(postBannersError(false));
        } catch (error) {
            dispatch(postBannersError(true));
        }
    };
}

export const putBanners = () => {
    return {
        type: UPDATE_BANNERS,
    };
};
export const putBannersSuccess = (result) => {
    return {
        type: UPDATE_BANNERS_SUCCESS,
        value: result,
    };
};
export const putBannersError = (error, result) => {
    return {
        type: UPDATE_BANNERS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutBanners(bannerId, token, data, file) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putBanners());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}${bannersEndpoint}${bannerId}`,
                data,
                {headers}
            );
            dispatch(putBannersSuccess(response.data.data));
            if (file !== '') {
                dispatch(fetchPostFileBanners(token, response.data.data.url, file));
            }
            dispatch(putBannersError(false));
        } catch (error) {
            dispatch(putBannersError(true));
        }
    };
}

export const deleteBanners = () => {
    return {
        type: DELETE_BANNERS,
    };
};
export const deleteBannersSuccess = (result) => {
    return {
        type: DELETE_BANNERS_SUCCESS,
        value: result,
    };
};
export const deleteBannersError = (error, result) => {
    return {
        type: DELETE_BANNERS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchDeleteBanners(bannerId, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(deleteBanners());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}${bannersEndpoint}${bannerId}`,
                {headers}
            );
            dispatch(deleteBannersSuccess(response.data.data));
            dispatch(deleteBannersError(false));
        } catch (error) {
            dispatch(deleteBannersError(true));
        }
    };
}
export const getMediaType = (Mediatype, src) => {
    return {
        type: OPEN_MODAL_PREVIEW,
        Mediatype: Mediatype,
        src: src,
    };
};
export const getCloseMediaType = () => {
    return {
        type: CLOSE_MODAL_PREVIEW,
    };
};
export const getBannersByid = () => {
    return {
        type: GET_BANNERS_BYID,
    };
};
export const getBannersByidSuccess = (result) => {
    return {
        type: GET_BANNERS_BYID_SUCCESS,
        value: result,
    };
};
export const getBannersByidError = (error, result) => {
    return {
        type: GET_BANNERS_BYID_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetBannersByid(token, bannerId, type) {
    const headers = {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getBannersByid());
        try {
            const URL =
                type === 1
                    ? `${process.env.REACT_APP_BASE_URL}${bannersEndpoint}${bannerId}/?view=true`
                    : `${process.env.REACT_APP_BASE_URL}${bannersEndpoint}${bannerId}`;
            const response = await axios.get(URL, {headers});

            dispatch(getBannersByidSuccess(response.data));
            if (type === 1) {
                dispatch(getMediaType(response.data.data.type, response.data.data.urlBanner));
            } else {
                event.preventDefault();

                const bannerUrl = response.data.data.urlBanner;
                const downloadLink = document.createElement('a');
                downloadLink.href = bannerUrl;
                downloadLink.download = 'nombre_del_archivo';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            dispatch(getBannersByidError(true));
        }
    };
}

export function fetchGetBannersByidInfo(token, bannerId) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getBannersByid());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}${bannersEndpoint}${bannerId}`,
                {headers}
            );
            const {id, mediaType, fileName, createdAt, extension} = response.data.data.attachment;
            const formattedResponse = {
                id: id,
                type: mediaType,
                name: fileName,
                createdAt,
                extension,
                size: -1,
            };
            dispatch(getBannersByidSuccess(formattedResponse));
        } catch (error) {
            dispatch(getBannersByidError(true));
        }
    };
}
