import {
    GET_ORDER_CONFIRM,
    GET_ORDER_CONFIRM_SUCCESS,
    GET_ORDER_CONFIRM_ERROR,
    SET_ORDER_CONFIRM_STATUS,
    SET_ORDER_CONFIRM_STATUS_SUCCESS,
    SET_ORDER_CONFIRM_STATUS_ERROR,
    GET_DOWNLOAD_ORDER_CONFIRM,
    GET_DOWNLOAD_ORDER_CONFIRM_SUCCESS,
    GET_DOWNLOAD_ORDER_CONFIRM_ERROR,
} from '@components/orderConfirm/redux/types/OrderConfirmTypes';

const initialState = {
    list: [],
    loading: true,
    count: 0,
    page: 1,
    limit: 10,
    totalPages: 1,
    error: '',
    order: null,
};

const orderConfirm = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER_CONFIRM:
            return {
                ...state,
                loding: true,
            };
        case GET_ORDER_CONFIRM_SUCCESS:
            return {
                ...state,
                list: action.value,
                count: action.metadata.count,
                page: action.metadata.currentPage,
                totalPages: action.metadata.totalPages,
                limit: action.metadata.limit,
                loading: false,
                order: null,
            };
        case GET_ORDER_CONFIRM_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
                order: null,
            };
        case SET_ORDER_CONFIRM_STATUS:
            return {
                ...state,
                loding: true,
            };
        case SET_ORDER_CONFIRM_STATUS_SUCCESS:
            return {
                ...state,
                order: action.value.data,
                loading: false,
            };
        case SET_ORDER_CONFIRM_STATUS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_DOWNLOAD_ORDER_CONFIRM:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_ORDER_CONFIRM_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_DOWNLOAD_ORDER_CONFIRM_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default orderConfirm;
