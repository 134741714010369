import {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {getRequestZipMassive} from '@/components/orderSimply/redux/actions/OrdersSimplyActions';

interface IAfterMassive {
    massiveJobId: string | null;
}

export const useModalMassiveJob = ({massiveJobId}: IAfterMassive) => {
    const dispatch = useDispatch();
    const {accessToken} = useSelector((state: RootState) => state.logins);

    const isMassiveJobIdValid =
        massiveJobId &&
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(
            massiveJobId
        );
    const [isModalMassiveJobOpen, setIsModalMassiveJobOpen] = useState(false);

    useEffect(() => {
        if (isMassiveJobIdValid) {
            handleOpenAfterMassiveModal();
            handleRequestData();
        }
    }, [isMassiveJobIdValid]);

    function handleOpenAfterMassiveModal() {
        setIsModalMassiveJobOpen(true);
    }

    function handleCloseModalMassiveJob() {
        setIsModalMassiveJobOpen(false);
    }

    async function handleRequestData() {
        dispatch(getRequestZipMassive(accessToken, massiveJobId));
    }

    return {
        isModalMassiveJobOpen,
        handleCloseModalMassiveJob,
    };
};
