import React from 'react';
import {Box, Container} from '@mui/material';

import TinkerBell from '@/components/notifications/components/TinkerBell';
import AvatarWithMenu from '@/components/UI/molecules/avatar/Avatar';
import LanguageSelector from '@/components/UI/molecules/language/LanguageSelector';

interface GlobalHeaderProps {
    isOrder: boolean;
    isHovered: boolean;
}

function GlobalHeader({isOrder, isHovered}: GlobalHeaderProps) {
    const width = isOrder
        ? '100vw'
        : isHovered
        ? 'calc(100vw - 253px)'
        : !isOrder && !isHovered
        ? '100%'
        : 'calc(100vw - 50px)';

    return (
        <Box
            sx={{
                display: 'flex',
                background: 'white',
                justifyContent: 'space-between',
                height: '61px',
                alignItems: 'center',
                position: 'relative',
                width,
            }}
        >
            <Container>
                <Box />
            </Container>
            <TinkerBell />
            <LanguageSelector />
            <AvatarWithMenu />
        </Box>
    );
}

GlobalHeader.defaultProps = {
    isOrder: false,
    isHovered: false,
};

export default GlobalHeader;
