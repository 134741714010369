import React, {useState, useEffect} from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface LanguageSelectorProps {
    language: string;
}

function LanguageSelector({language}: LanguageSelectorProps) {
    const {t, i18n} = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(language);

    useEffect(() => {
        const userLang = localStorage.getItem('user-lang') || language;
        i18n.changeLanguage(userLang);
        setCurrentLanguage(userLang);
    }, [i18n, language]);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('user-lang', lng);
        setCurrentLanguage(lng);
    };
    return (
        <>
            <Typography
                sx={{
                    fontSize: '12px',
                    marginRight: '10px',
                }}
            >
                {t('header.language_selector.label')}
            </Typography>
            <Typography
                sx={{
                    marginRight: '5px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    color: currentLanguage === 'es' ? 'red' : 'black',
                }}
                onClick={() => changeLanguage('es')}
            >
                {t('header.language_selector.language.spanish')}
            </Typography>
            <Typography
                sx={{
                    marginRight: '5px',
                    cursor: 'pointer',
                }}
            >
                |
            </Typography>
            <Typography
                sx={{
                    marginRight: '5px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    color: currentLanguage === 'en' ? 'red' : 'black',
                }}
                onClick={() => changeLanguage('en')}
            >
                {t('header.language_selector.language.english')}
            </Typography>
        </>
    );
}

LanguageSelector.defaultProps = {
    language: 'es',
};

export default LanguageSelector;
