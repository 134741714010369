import {AppDispatch, RootState} from '@/config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {Search} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Button, Checkbox, TextField, Tooltip} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

import {IFormBillingStatement} from '@/components/terms/interfaces/interface';
import {IPurchaseOrder} from '@/components/terms/interfaces/terms-purchase-order.interface';
import {TermsModal} from '@/components/terms/modals';
import {fetchGetPurchaseOrders} from '@/components/terms/redux/actions/TermsAcceptanceActions';
import {
    HIDE_TERMS_MODAL,
    SELECT_ALL_PURCHASE_ORDERS,
    SELECT_EXTERNAL_IDS,
    SELECT_PURCHASE_ORDERS,
    SHOW_TERMS_MODAL,
} from '@/components/terms/redux/types/TermsAcceptanceTypes';
import {TypographyStyled600} from '@/components/UI/atoms/modals/Styled';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, TermsConditionsEnum} from '@/enums/permissions.enum';
interface IPoTerms {
    orders: IPurchaseOrder[];
    ordersLimit: number;
    ordersLoading: boolean;
    ordersPage: number;
    showTermsModal: boolean;
    allPurchaseOrders: boolean;
    selectedPurchaseOrders: string[];
    selectedExternalIds: string[];
    closeAccordion: () => void;
}

interface IFiltersProps {
    onSuccess: () => void;
}

export const Filters: React.FC<IFiltersProps> = ({onSuccess}) => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const {
        orders,
        ordersLimit: limit,
        ordersLoading,
        ordersPage: page,
        showTermsModal,
        selectedPurchaseOrders,
        selectedExternalIds,
        closeAccordion,
    } = useSelector((state: RootState): IPoTerms => state.poTermsAcceptance);

    const [allPOs, setAllPOs] = useState<boolean | undefined>(undefined);
    const formRef = useRef<HTMLFormElement>(null);

    const schemaFormModal = yup.object({
        id: yup.string(),
        referenceId: yup.string(),
        dateInit: yup.date(),
        dateEnd: yup.date(),
    });

    const {handleSubmit, control, setValue} = useForm<IFormBillingStatement>({
        resolver: yupResolver(schemaFormModal),
    });

    const dateInit = useWatch({
        control,
        name: 'dateInit',
    });

    const dateEnd = useWatch({
        control,
        name: 'dateEnd',
    });

    useEffect(() => {
        if (dateInit && dateEnd) {
            const initDate = DateTime.fromJSDate(dateInit);
            const endDate = DateTime.fromJSDate(dateEnd);

            if (initDate > endDate) {
                setValue('dateEnd', initDate.toJSDate());
            } else if (endDate < initDate) {
                setValue('dateInit', endDate.toJSDate());
            }
        }
    }, [dateInit, dateEnd, setValue]);

    const onSubmit = (info: IFormBillingStatement) => {
        if (closeAccordion) closeAccordion();
        dispatch(
            fetchGetPurchaseOrders(token, 1, limit, {
                ...info,
                dateInit: DateTime.fromJSDate(info.dateInit).toFormat('yyyy-MM-dd'),
                dateEnd: DateTime.fromJSDate(info.dateEnd).toFormat('yyyy-MM-dd'),
            })
        );
    };

    const handleMultipleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        if (selectedPurchaseOrders.length === orders.length) {
            checked = false;
        }

        setAllPOs(checked);

        dispatch({
            type: SELECT_PURCHASE_ORDERS,
            value: checked ? orders.map((order) => order.id) : [],
        });

        dispatch({
            type: SELECT_EXTERNAL_IDS,
            value: checked ? orders.map((order) => order.referenceId) : [],
        });
    };

    const handleShowTermsModal = () => {
        dispatch({type: SHOW_TERMS_MODAL});
    };

    const handleCloseModal = () => {
        dispatch({type: HIDE_TERMS_MODAL});
    };

    const handleCleanModalData = () => {
        dispatch({
            type: SELECT_PURCHASE_ORDERS,
            value: [],
        });

        dispatch({
            type: SELECT_EXTERNAL_IDS,
            value: [],
        });

        onSuccess();
    };

    const triggerSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        }
    };

    useEffect(() => {
        dispatch(
            fetchGetPurchaseOrders(token, page, limit, {
                dateInit: DateTime.now().minus({day: 1}).toFormat('yyyy-MM-dd'),
                dateEnd: DateTime.now().toFormat('yyyy-MM-dd'),
            })
        );
    }, [token]);

    useEffect(() => {
        dispatch({type: SELECT_ALL_PURCHASE_ORDERS, value: allPOs});
    }, [allPOs]);

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '20px',
                    padding: '15px',
                    height: '73px',
                    background: '#fff',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <Can
                        I={TermsConditionsEnum.ACCEPT_REJECT_TERMS_CONDITIONS}
                        a={FeatureCodeEnum.TERMS_CONDITIONS}
                    >
                        <Checkbox
                            title={t('terms_acceptance.filters.select_all')}
                            disabled={ordersLoading}
                            onChange={handleMultipleAcceptTerms}
                        />
                    </Can>
                    <Can I={TermsConditionsEnum.FILTER} a={FeatureCodeEnum.TERMS_CONDITIONS}>
                        <Controller
                            name="referenceId"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    id="id"
                                    label={`${t('terms_acceptance.filters.po_external_id')}`}
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            defaultValue={''}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateInit"
                                control={control}
                                defaultValue={DateTime.now().minus({day: 1}).toJSDate()}
                                render={({field}) => (
                                    <DatePicker
                                        label={t('terms_acceptance.filters.start_date')}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        maxDate={DateTime.now().toJSDate()}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEnd"
                                control={control}
                                defaultValue={DateTime.now().toJSDate()}
                                render={({field}) => (
                                    <DatePicker
                                        label={t('terms_acceptance.filters.end_date')}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        minDate={dateInit || DateTime.now().toJSDate()}
                                        maxDate={DateTime.now().toJSDate()}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Tooltip title={t('terms_acceptance.filters.search')}>
                            <LoadingButton
                                variant="outlined"
                                loading={false}
                                type="submit"
                                color="secondary"
                                sx={{
                                    minWidth: '40px',
                                    height: '40px',
                                    padding: '5px 5px',
                                }}
                            >
                                <Search />
                            </LoadingButton>
                        </Tooltip>
                    </Can>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Can
                        I={TermsConditionsEnum.ACCEPT_REJECT_TERMS_CONDITIONS}
                        a={FeatureCodeEnum.TERMS_CONDITIONS}
                    >
                        <Button
                            onClick={handleShowTermsModal}
                            disabled={selectedPurchaseOrders.length === 0}
                            variant="contained"
                            sx={{
                                display: 'block',
                                width: '180px',
                                color: '#FFFFFF',
                            }}
                        >
                            <TypographyStyled600 sx={{margin: '5px'}}>
                                {t('terms_acceptance.buttons.accept_reject')}
                            </TypographyStyled600>
                        </Button>
                    </Can>
                </Box>
            </Box>
            {showTermsModal && (
                <TermsModal
                    isOpen={showTermsModal}
                    onClose={() => handleCloseModal()}
                    termsData={{
                        externalIds: selectedExternalIds,
                        purchaseOrderIds: selectedPurchaseOrders,
                    }}
                    onFulfill={handleCleanModalData}
                    afterAction={triggerSubmit}
                />
            )}
        </Box>
    );
};
