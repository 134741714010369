import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select'; // Importar SelectChangeEvent
import MenuItem from '@mui/material/MenuItem';

interface CustomPaginationProps {
    totalPages: number;
    currentPage: number;
    handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    limit: number;
    handleChangeRowsPerPage: (event: SelectChangeEvent<number>) => void; // Usar SelectChangeEvent
}

const CustomPagination: FC<CustomPaginationProps> = ({
    totalPages,
    currentPage,
    handleChange,
    limit,
    handleChangeRowsPerPage,
}: CustomPaginationProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0',
            }}
        >
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                color="primary"
            />
            <FormControl
                sx={{
                    height: '30px',
                }}
            >
                <Select
                    labelId="rows-per-page-label"
                    id="rows-per-page"
                    value={limit}
                    sx={{
                        height: '30px',
                    }}
                    onChange={(event: SelectChangeEvent<number>) => handleChangeRowsPerPage(event)} // Usar SelectChangeEvent
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default CustomPagination;
