import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {RootState} from '@/config/store';
import TabContext from '@mui/lab/TabContext';
import {Box, Typography, useTheme} from '@mui/material';
import IconLoading from '@components/UI/atoms/icons/IconLoading.svg';
import {removeUrlParams} from '@/components/UI/helpers/remove-url-param';
import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import {ErrorType} from '@components/modelorama/redux/actions/ModeloramaActions';
import {FileUploadContainer} from '@/components/legalAgreements/StyledLegalAgreements';
import FileValidationsMassiveJob from '@/components/orderSimply/components/FileValidationsMassiveJob';
import {
    Nav,
    SaveButton,
    StyledModal,
    TypographyStyled500,
    TypographyStyled600,
} from '@/components/faqs/StyledFAQ';

interface ModalAfterMassiveProps {
    isModalOpen: boolean;
    closeModal: () => void;
    loading?: boolean;
}

const MASSIVE_JOB_PARAM = 'mjob';

const ModalMassiveJob = ({isModalOpen, closeModal, loading = false}: ModalAfterMassiveProps) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [showValidations, setShowValidations] = useState<boolean>(false);
    const [totalErrors, setTotalErrors] = useState<number>(0);

    const {domainErrors, errors, error} = useSelector((state: RootState) => state.orderssimply);

    const VALIDATION_SUCCESS = error === ErrorType.NONE;
    const VALIDATION_SUCCESS_WITH_SAP_ERROR =
        error === ErrorType.RULE &&
        Array.isArray(domainErrors) &&
        domainErrors.some((error) => error === 'sap.confirm_error');

    const handleCloseModal = () => {
        removeUrlParams([MASSIVE_JOB_PARAM]);
        closeModal();
    };

    const hasPositions = (err: any[]) => {
        return err.some((item) => item.hasPositions);
    };

    const calculateTotalValidationErrors = (errors: any[]) => {
        let totalErrors = 0;
        errors.forEach((item) => {
            if (Array.isArray(item.entrySheets)) {
                const entrySheetErrors = item.entrySheets.filter(
                    (sheet: any) => sheet.errors?.length > 0
                ).length;
                if (entrySheetErrors > 0) {
                    totalErrors += 1;
                }
            }
        });
        return totalErrors;
    };

    const totalPurchaseOrders = errors.length;
    const totalSuccess = totalPurchaseOrders - totalErrors;

    useEffect(() => {
        const hasErrors = Array.isArray(errors);
        if (!loading && hasErrors) {
            setTotalErrors(calculateTotalValidationErrors(errors));
            setShowValidations(true);
        } else {
            setTotalErrors(0);
        }
    }, [errors, loading, VALIDATION_SUCCESS, VALIDATION_SUCCESS_WITH_SAP_ERROR]);

    return (
        <StyledModal
            disableEnforceFocus={true}
            disableEscapeKeyDown={true}
            onClose={() => closeModal}
            open={isModalOpen}
        >
            <Box
                sx={{
                    background: '#FFF',
                    borderRadius: '3px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '600px',
                    width: '865px',
                    zIndex: '100',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            color: 'white',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '18px',
                            fontWeight: 700,
                            marginLeft: '10px',
                        }}
                    >
                        {t('load_massive_invoices')}
                    </Typography>
                </Nav>
                <TabContext value="1">
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList>
                            <Tab
                                disabled={VALIDATION_SUCCESS}
                                label={t('validation')}
                                sx={{textTransform: 'none'}}
                                value="1"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <>
                            {!loading && (
                                <TypographyStyled600>
                                    {t('modal_massive_job.sumary')}
                                </TypographyStyled600>
                            )}
                            {loading ? (
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        fontSize: '20px',
                                        gap: '20px',
                                        height: '240px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <StatusMessage
                                        color="#3276c3"
                                        iconSrc={IconLoading}
                                        message="Cargando..."
                                    />
                                </Box>
                            ) : (
                                <>
                                    {errors && errors.length > 0 && (
                                        <>
                                            <FileUploadContainer
                                                sx={{
                                                    borderBottomLeftRadius: '0',
                                                    borderBottomRightRadius: '0',
                                                    borderBottomStyle: 'none',
                                                    height: '40px',
                                                    justifyContent: 'flex-start',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <TypographyStyled500
                                                    sx={{
                                                        marginLeft: '10px',
                                                        minWidth: '180px',
                                                    }}
                                                >
                                                    {t('modal_massive_job.table.purchase_order')}
                                                </TypographyStyled500>
                                                <TypographyStyled500
                                                    sx={{
                                                        marginLeft: '10px',
                                                        minWidth: '180px',
                                                    }}
                                                >
                                                    {!hasPositions(errors)
                                                        ? t('modal_massive_job.table.positions')
                                                        : t('modal_massive_job.table.entry_sheets')}
                                                </TypographyStyled500>
                                                <TypographyStyled500
                                                    sx={{
                                                        marginLeft: '10px',
                                                        minWidth: '180px',
                                                    }}
                                                >
                                                    {t('modal_massive_job.table.comments')}
                                                </TypographyStyled500>
                                            </FileUploadContainer>
                                            <FileUploadContainer
                                                sx={{
                                                    borderTopLeftRadius: '0',
                                                    borderTopRightRadius: '0',
                                                    display: 'block',
                                                    height: '300px',
                                                    justifyContent: 'flex-start',
                                                    overflow: 'hidden',
                                                    overflowY: 'scroll',
                                                }}
                                            >
                                                <FileValidationsMassiveJob
                                                    errors={errors}
                                                    loading={loading}
                                                    showValidations={showValidations}
                                                />
                                            </FileUploadContainer>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </TabPanel>
                </TabContext>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingX: '20px',
                    }}
                >
                    <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                        <Box sx={{minWidth: '180px'}}>
                            <StatusMessage
                                message={`${t('modal_massive_job.total_purchase_orders')}: ${
                                    errors.length
                                }`}
                                color="#000000"
                            />
                        </Box>
                        <Box sx={{minWidth: '150px'}}>
                            <StatusMessage
                                message={`${t('modal_massive_job.total_success')}: ${totalSuccess}`}
                                color="#388e3c"
                            />
                        </Box>
                        <Box sx={{minWidth: '160px'}}>
                            <StatusMessage
                                message={`${t('modal_massive_job.total_errors')}: ${totalErrors}`}
                                color="#f50057"
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '60px',
                            justifyContent: 'space-around',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                marginLeft: 'auto',
                            }}
                        >
                            <SaveButton
                                sx={{padding: '10px', marginLeft: 'auto'}}
                                onClick={() => handleCloseModal()}
                            >
                                {t('close').toUpperCase()}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </StyledModal>
    );
};

ModalMassiveJob.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default React.memo(ModalMassiveJob);
