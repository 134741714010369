import {
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainer,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {
    fetchGetModeloramaOrders,
    postOrderFilters,
    setFileUploadStatus,
} from '@/components/modelorama/redux/actions/ModeloramaActions';
import {AppDispatch, RootState} from '@/config/store';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, SupplierEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import ActionTextButton from '@components/UI/atoms/buttons/ActionTextButton';
import StatusText from '@components/UI/atoms/buttons/StatusText';
import SkeletonRow from '@components/UI/molecules/tables/SkeletonRow';
import {TextButton} from '@components/UI/molecules/textButton/textButtons';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {ACLObj} from '@components/auth/guard/AclGuard';
import {TitlePageComponent} from '@components/billingStatement/components/titleComponent';
import ModalFileUpload from '@components/modelorama/ModalFileUpload';
import GridTableCell from '@components/orderSimply/components/GridTableCell';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Checkbox, Grid, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {ButtonCancel} from '@/components/faqs/StyledFAQ';
import * as yup from 'yup';
import {statusTextValues} from '@components/UI/atoms/buttons/constant/status-text';
import ModalAlertCsf from '@/routers/ModalAlertCsfGo';

export interface IForm {
    orderId: string;
}

export interface IItem {
    id: string;
    entrySheet: string;
    orderId: string;
    position: number;
    monthOwed: string;
    dueYear: number;
    status: string;
    billNumber: string;
    message: string;
}

const ModeloramaPositions: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const {field, bussinesId} = useParams();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [type, setType] = useState<string>('');
    const [modalPurchaseOrderId, setModalPurchaseOrderId] = useState<string>('');
    const [modalEntrySheets, setModalEntrySheets] = useState<string[]>([]);
    const [entrySheets, setEntrySheets] = useState<string[]>([]);
    const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<string | null>(null);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.modelorama.loading);
    const filters = useSelector((state: RootState) => state.orderssimply.filters);
    const lineItems = useSelector((state: RootState) => state.modelorama.lineItems);
    const currentPage = useSelector((state: RootState) => state.modelorama.page);
    const totalPages = useSelector((state: RootState) => state.modelorama.totalPages);
    const limit = useSelector((state: RootState) => state.modelorama.limit);
    const {allowed} = useCheckAbilities();
    const [isModalOpenAlert, setIsModalOpenAlert] = useState(false);
    const allowedCheckboxStatus = ['CONFIRMED', 'DELIVERED', 'REJECTED_INVOICE'];
    const csfAlert = useSelector((state: RootState) => state.logins.csfAlert);
    useEffect(() => {
        if (bussinesId && field) {
            dispatch(
                fetchGetModeloramaOrders(token, field, bussinesId, currentPage, limit, filters)
            );
        }
    }, [dispatch, token]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (bussinesId && field) {
            dispatch(fetchGetModeloramaOrders(token, field, bussinesId, value, limit, filters));
        }
    };
    const handleChangeRowsPerPage = (event: any) => {
        if (bussinesId && field) {
            dispatch(
                fetchGetModeloramaOrders(token, field, bussinesId, 1, event.target.value, filters)
            );
        }
    };

    const schemaForm = yup.object({
        orderId: yup.string(),
    });

    const {handleSubmit, control, watch} = useForm<IForm>({
        resolver: yupResolver(schemaForm),
    });
    const watchForm = watch();

    const onSubmit = () => {
        const filters = {
            orderId: watchForm.orderId,
        };
        dispatch(postOrderFilters(filters));
        if (field) {
            dispatch(
                fetchGetModeloramaOrders(
                    token,
                    field,
                    watchForm.orderId,
                    currentPage,
                    limit,
                    filters
                )
            );
        }
    };

    const handleCloseModal = (event: {preventDefault: () => void}, reason: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            event.preventDefault();
        }
        setModalOpen(false);
    };

    const handleCloseAndReload = (event: {preventDefault: () => void}, reason: string) => {
        onSubmit();
        setEntrySheets([]);
        handleCloseModal(event, reason);
    };

    const handleOpenModal = (type: string, purchaseOrderId: string = '', entrySheets: string[]) => {
        dispatch(setFileUploadStatus(false));
        setModalOpen((prev) => !prev);
        setType(type);
        setModalPurchaseOrderId(purchaseOrderId);
        setModalEntrySheets(entrySheets);
    };

    const handleEntrySheetSelection = (
        event: React.ChangeEvent<HTMLInputElement>,
        entrySheet: string,
        orderId: string
    ) => {
        const {checked} = event.target;
        if (checked) {
            if (selectedPurchaseOrderId === null || selectedPurchaseOrderId === orderId) {
                setEntrySheets((prevEntrySheets) => [...prevEntrySheets, entrySheet]);
                setSelectedPurchaseOrderId(orderId);
            } else {
                setEntrySheets([entrySheet]);
                setSelectedPurchaseOrderId(orderId);
            }
        } else {
            setEntrySheets((prevEntrySheets) => {
                const updatedEntrySheets = prevEntrySheets.filter((item) => item !== entrySheet);
                if (updatedEntrySheets.length === 0) {
                    setSelectedPurchaseOrderId(null);
                }
                return updatedEntrySheets;
            });
        }
    };

    const gotToBack = () => {
        dispatch(setFileUploadStatus(false));
        navigate(-1);
    };

    const closeModalAlert = () => {
        setIsModalOpenAlert(false);
        navigate('/csf');
    };

    useEffect(() => {
        if (csfAlert) {
            setIsModalOpenAlert(true);
        }
    }, [csfAlert]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <TitlePageComponent
                    title={'Modelorama - ' + t('components.load_invoice_btn.title')}
                />
                <ButtonCancel
                    onClick={gotToBack}
                    sx={{
                        marginRight: '10px',
                        border: '1px solid rgba(1, 27, 52, 0.5)',
                        color: '#011B34',
                        fontWeight: 500,
                    }}
                    color="info"
                >
                    {t('back').toUpperCase()}
                </ButtonCancel>
            </Box>

            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                {allowed(FeatureCodeEnum.SUPPLIER, SupplierEnum.UPLOAD_BILL) ||
                allowed(FeatureCodeEnum.SUPPLIER, SupplierEnum.FILTER) ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '15px',
                            paddingBottom: '15px',

                            background: '#fff',
                            gap: '10px',
                            marginBottom: '10px',
                        }}
                    >
                        <Can I={SupplierEnum.FILTER} a={FeatureCodeEnum.SUPPLIER}>
                            <Controller
                                name="orderId"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('consultpo.table.headers.purchase_order')}
                                            size="small"
                                        />
                                    );
                                }}
                            />

                            <TextButton type="submit">
                                {t('components.filter_btn.title').toUpperCase()}
                            </TextButton>
                        </Can>
                        <Can I={SupplierEnum.UPLOAD_BILL} a={FeatureCodeEnum.SUPPLIER}>
                            <ActionTextButton
                                onClick={() =>
                                    handleOpenModal(
                                        'Factura',
                                        selectedPurchaseOrderId!,
                                        entrySheets
                                    )
                                }
                                type="button"
                                disabled={entrySheets.length === 0}
                                enabledColour="#D1A33C"
                            >
                                {t('components.load_invoice_btn.title').toUpperCase()}
                            </ActionTextButton>
                        </Can>
                    </Box>
                ) : null}
            </Box>

            <TableContainer container>
                <TableHeadRow container>
                    <Grid item xs={2}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.purchase_order')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>Pos</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('debt_month')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('debt_year')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('state')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHeadFinal sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.message')}
                        </TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    lineItems &&
                    lineItems.length > 0 &&
                    lineItems.map((item: IItem, index: number) => {
                        /*
                         * Modelorama doesn't use the CONFIMED status,
                         * so we decided not to change this logic in the backend.
                         * Instead, we changed the status here to match the desired behavior.
                         * This ensures consistency between the frontend and backend.
                         * // [ ] Tag: MODELORA-CONFIRMED-STATUS
                         */
                        const verifiedStatus = item.billNumber
                            ? statusTextValues.INVOICE_IN_PROCESS
                            : item.status === 'CONFIRMED'
                            ? 'DELIVERED'
                            : item.status;
                        return (
                            <div
                                key={index}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'normal',
                                    background: 'white',
                                }}
                            >
                                <GridTableCell sx={{backgroundColor: 'transparent'}} xs={2}>
                                    <Can I={SupplierEnum.UPLOAD_BILL} a={FeatureCodeEnum.SUPPLIER}>
                                        <Checkbox
                                            disabled={!allowedCheckboxStatus.includes(item.status)}
                                            size="small"
                                            onChange={(e) =>
                                                handleEntrySheetSelection(e, item.id, item.orderId)
                                            }
                                            checked={entrySheets.includes(item.id)}
                                        />
                                    </Can>
                                    {item.orderId}
                                </GridTableCell>
                                <GridTableCell xs={2}>{item.position}</GridTableCell>
                                <GridTableCell xs={2}>
                                    {item.monthOwed === 'nd' ? '' : item.monthOwed.toUpperCase()}
                                </GridTableCell>
                                <GridTableCell xs={2}>{item.dueYear}</GridTableCell>
                                <GridTableCell xs={2}>
                                    <StatusText status={verifiedStatus} />
                                </GridTableCell>
                                <GridTableCell xs={2}>{item.message}</GridTableCell>
                            </div>
                        );
                    })
                )}
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <ModalFileUpload
                isModalOpen={isModalOpen}
                closeModal={handleCloseModal}
                closeAndReload={handleCloseAndReload}
                loading={loading}
                type={type}
                purchaseOrderId={modalPurchaseOrderId}
                entrySheets={modalEntrySheets}
            />
            <ModalAlertCsf isModalOpen={isModalOpenAlert} closeModal={closeModalAlert} />
        </Box>
    );
};
ModeloramaPositions.acl = {
    action: SupplierEnum.SEE_LIST,
    subject: FeatureCodeEnum.SUPPLIER,
};
export default ModeloramaPositions;
