import {ACLObj} from '@/components/auth/guard/AclGuard';
import {fetchGetModeloramaBussines} from '@/components/modelorama/redux/actions/ModeloramaActions';
import {AppDispatch, RootState} from '@/config/store';
import {FeatureCodeEnum, SupplierEnum} from '@/enums/permissions.enum';
import {TitlePageComponent} from '@components/billingStatement/components/titleComponent';
import SkeletonRow from '@components/UI/molecules/tables/SkeletonRow';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {Box, Grid, Paper, TableContainer, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {TextButton} from '@components/UI/molecules/textButton/textButtons';
import {useTitle} from '@/hooks/useTitle';

export const TableCellHead = styled(Paper)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    borderRadius: '0',
    border: '1px solid #C2D1D9',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    boxShadow: 'none',
    background: '#EBF0F2',
    height: '45px',
}));

const styleCellData = {
    border: '1px solid #C2D1D9',
    padding: '25px 0 25px 20px',
    background: 'white',
    cursor: 'pointer',
};

const styleCellLink = {
    border: '1px solid #C2D1D9',
    padding: '25px 0 25px 20px',
    background: 'white',
    cursor: 'pointer',
    color: 'rgb(0, 172, 255)',
};

export interface IForm {
    askedNumber: string;
    businessNumber: string;
}

const Modelorama: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.modelorama.loading);
    const bussines = useSelector((state: RootState) => state.modelorama.bussines);
    const currentPage = useSelector((state: RootState) => state.modelorama.page);
    const totalPages = useSelector((state: RootState) => state.modelorama.totalPages);
    const limit = useSelector((state: RootState) => state.orderssimply.limit);
    const user = useSelector((state: RootState) => state.logins.user);
    const {setPath, title} = useTitle();
    useEffect(() => {
        dispatch(fetchGetModeloramaBussines(token, currentPage, limit));
    }, [dispatch, token]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetModeloramaBussines(token, value, limit));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetModeloramaBussines(token, 1, event.target.value));
    };

    const showLoadInvoice = (field: string, id: string) => {
        navigate(`/modelorama/positions/${field}/${id}`);
    };

    const schemaForm = yup.object({
        orderId: yup.string(),
    });
    const {handleSubmit, control, watch} = useForm<IForm>({
        resolver: yupResolver(schemaForm),
    });

    const watchForm = watch();

    const onSubmit = () => {
        dispatch(
            fetchGetModeloramaBussines(
                token,
                currentPage,
                limit,
                watchForm.businessNumber,
                watchForm.askedNumber
            )
        );
    };

    useEffect(() => {
        setPath('/modelorama');
    }, [t]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '15px 23px',
            }}
        >
            <TitlePageComponent title={title} />
            {user.type === 'INTERNAL_USER' && (
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '15px',
                            paddingBottom: '15px',

                            background: '#fff',
                            gap: '10px',
                            marginBottom: '10px',
                        }}
                    >
                        <Controller
                            name="businessNumber"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('modelorama.table.headers.business_number')}
                                        size="small"
                                    />
                                );
                            }}
                        />

                        <Controller
                            name="askedNumber"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('modelorama.table.headers.ask_number')}
                                        size="small"
                                    />
                                );
                            }}
                        />

                        <TextButton type="submit">
                            {t('components.filter_btn.title').toUpperCase()}
                        </TextButton>
                    </Box>
                </Box>
            )}
            <TableContainer>
                <Grid container>
                    <Grid item xs={3}>
                        <TableCellHead>
                            {t('modelorama.table.headers.business_number')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHead>{t('modelorama.table.headers.ask_number')}</TableCellHead>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHead>Modelorama</TableCellHead>
                    </Grid>
                </Grid>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    <Box>
                        {bussines &&
                            bussines.map((item: any, idx: number) => (
                                <Box
                                    key={item.id}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '0',

                                        boxShadow: 'none',
                                        minHeight: '50px',
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        color: '#515151',
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    <Grid
                                        container
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '0',
                                            boxShadow: 'none',
                                            minHeight: '50px',
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                            sx={styleCellLink}
                                            onClick={() =>
                                                showLoadInvoice(
                                                    'businessNumber',
                                                    item.businessNumber
                                                )
                                            }
                                        >
                                            {item.businessNumber}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={styleCellLink}
                                            onClick={() =>
                                                showLoadInvoice('askedNumber', item.askedNumber)
                                            }
                                        >
                                            {item.askedNumber}
                                        </Grid>
                                        <Grid item xs={3} sx={styleCellData}>
                                            {item.businessName}
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                    </Box>
                )}
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};
Modelorama.acl = {
    action: SupplierEnum.SEE_LIST,
    subject: FeatureCodeEnum.SUPPLIER,
};

export default Modelorama;
