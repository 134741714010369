import {styled} from '@mui/material/styles';
import {Typography} from '@mui/material';

export const TextSuccess = styled(Typography)`
  display: inline-block;
  padding: 3.5px 16px;
  background: rgba(75, 188, 104, 0.2); 
  color: #4BBC68; 
  fontFamily: Montserrat,
  fontWeight:600,
  fontSize: 13px,
  width:98px,
  height:31px,
  borderRadius: 4px;
  cursor: default; 
`;

export const TextError = styled(Typography)`
  display: inline-block;
  padding: 3.5px 16px;
  background: rgba(255, 87, 34, 0.2); 
  color: #FF5722; 
  fontFamily: Montserrat,
  fontWeight:600,
  fontSize: 13px,
  width:98px,
  height:31px,
  borderRadius: 4px;
  cursor: default; 
`;

export const TextButton = styled('button')(({theme}) => ({
    backgroundColor: '#fff',
    height: '31px',
    width: '102px',
    borderRadius: '5px',
    color: '#00ACFF',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    marginLeft: '20px',
    border: '1px solid #00ACFF',
    '&:hover': {
        cursor: 'pointer',
    },
}));

export const TextButtonDetails = styled('button')(({theme}) => ({
    backgroundColor: '#fff',
    height: '31px',
    minWidth: '102px',
    borderRadius: '5px',
    color: '#00ACFF',
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    border: '1px solid #00ACFF',
    '&:hover': {
        cursor: 'pointer',
    },
}));
