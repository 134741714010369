import {Box, Grid, Skeleton} from '@mui/material';
import React from 'react';
import {IConProp} from '@/components/terms/interfaces/interface';
import {TableCellChildrenSmall} from '@/components/terms/styles/styles';

type Props = {colProps: IConProp[]};

const PurchaseOrdersLoader: React.FC<Props> = ({colProps}) => {
    type CellProps = {
        id: number;
        align: string;
        size: number;
    };
    const LoaderCell: React.FC<CellProps> = ({align, id, size}) => {
        return (
            <Grid item xs={size} display="grid">
                <TableCellChildrenSmall sx={getStile(id)}>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: align}}>
                        <Skeleton variant="text" sx={{fontSize: '1rem', width: '80%'}} />
                    </Box>
                </TableCellChildrenSmall>
            </Grid>
        );
    };

    const getStile = (key: number) => {
        const stile = {
            borderLeft: '',
        };
        if (key === colProps[0].id) stile.borderLeft = '1px solid #C2D1D9';
        return stile;
    };

    return (
        <Grid container>
            {colProps.map((col) => (
                <LoaderCell key={`1${col.id}`} id={col.id} size={col.size} align={col.align} />
            ))}
            {colProps.map((col) => (
                <LoaderCell key={`2${col.id}`} id={col.id} size={col.size} align={col.align} />
            ))}
            {colProps.map((col) => (
                <LoaderCell key={`3${col.id}`} id={col.id} size={col.size} align={col.align} />
            ))}
        </Grid>
    );
};

export default PurchaseOrdersLoader;
