import {
    GET_PERFIL,
    GET_PERFIL_ERROR,
    GET_PERFIL_SUCCESS,
    UPDATE_PERFIL,
    UPDATE_PERFIL_ERROR,
    UPDATE_PERFIL_SUCCESS,
    SET_LOAD_PROFILE_OFF,
} from '@/components/perfil/redux/types/PerfilTypes';

const initialState = {
    menu: null,
    menuconfig: null,
    font: null,
    colors: null,
    logo: null,
    loading: false,
    error: false,
    flagPerfil: false,
};

const perfil = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOAD_PROFILE_OFF:
            return {
                ...state,
                flagPerfil: false,
            };
        case GET_PERFIL:
            return {
                ...state,
                loading: true,
            };
        case GET_PERFIL_SUCCESS:
            return {
                ...state,
                menu: action.value
                    .filter((item) => item.value.startsWith('MENU'))
                    .map((item) => ({
                        id: item.id,
                        value: item.name,
                        img: item.img,
                        name: item.value.slice(4).split('-EN-')[0],
                        nameEN: item.value.slice(4).split('-EN-')[1],
                    })),
                menuconfig: action.value
                    .filter((item) => item.value.startsWith('CONFIG'))
                    .map((item) => ({
                        id: item.id,
                        value: item.name,
                        name: item.value.slice(6).split('-EN-')[0],
                        nameEN: item.value.slice(6).split('-EN-')[1],
                    })),
                font: action.value.filter((item) => item.name === 'font'),
                logo: action.value.find((item) => item.name === 'logo'),
                colors: action.value.filter((item) => item.name.endsWith('primaryColor')),
                loading: false,
                flagPerfil: true,
            };
        case GET_PERFIL_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_PERFIL:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_PERFIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_PERFIL_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default perfil;
