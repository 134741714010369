import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Typography,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DateTime} from 'luxon';

import {AddButton, SaveButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import IconAlertDelete from '@/components/UI/atoms/icons/IconAlertDelete.svg';
import IconDelete from '@/components/UI/atoms/icons/IconDelete.svg';
import IconEdit from '@/components/UI/atoms/icons/IconEdit.svg';
import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@/components/UI/molecules/modals/WarningUpdateModal';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainer,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {Can} from '@/context/AbilityContext';
import {BannersEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import {getCountryId, getCountryName} from '@/utils/utils';
import {ButtonCancel} from '@components/UI/atoms/buttons/ButtonAlternative';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import {ACLObj} from '@components/auth/guard/AclGuard';
import {fetchGetRoles} from '@components/roles/redux/actions/RolesActions';
import store, {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import MediaComponent from '@/components/banners/components/Media';
import {
    fetchDeleteBanners,
    fetchGetBanners,
    fetchGetBannersByid,
    fetchGetBannersByidInfo,
    fetchPostBanners,
    fetchPutBanners,
    getCloseMediaType,
} from '@components/banners/redux/actions/BannersActions';

import type {ICountry, IForm, IRole, ITable} from '@/components/banners/interfaces/banners';
import {
    FileLabelButton,
    FileUploadContainer,
    ImgIcon,
    Input,
    InputLabelRol,
    LabelFileUpload,
    Loader,
    Modal,
    Nav,
    Select,
} from '@/components/banners/components/Styled';
import {ALLOWED_FILE_TYPES, MAX_FILE_SIZE_MB} from '@/components/banners/constants/modal';
import {schemaFormModal} from '@/components/banners/constants/schema';
import {extractLanguage, traslateColumnValue} from '@/config/constants';

const Banners: React.FC & {acl?: ACLObj} = () => {
    const {notAllowed} = useCheckAbilities();
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const dispatch: AppDispatch = useDispatch();
    const hasConfiguration = useConfigurationUrl();
    const theme = useTheme();

    const {showModalPreview, mediaType, mediaSrc} = useSelector(
        (state: RootState) => state.banners
    );
    const banners = useSelector((state: RootState) => state.banners.banners);
    const countryCatalog = useSelector((state: RootState) => state.country.country);
    const currentPage = useSelector((state: RootState) => state.banners.page);
    const error = useSelector((state: RootState) => state.banners.error);
    const limit = useSelector((state: RootState) => state.banners.limit);
    const loading = useSelector((state: RootState) => state.banners.loading);
    const roles = useSelector((state: RootState) => state.rolesManager.roles);
    const token = useSelector((state: RootState) => state.logins.accessToken);

    const [banner, setBanner] = useState<string>('');
    const [bannerId, setBannerId] = useState<string>('');
    const [checkAllCountry, setCheckAllCountry] = useState(false);
    const [checkAllRole, setCheckAllRole] = useState(false);
    const [errorFile, setErrorFile] = useState<string>('');
    const [flag, setFlag] = useState<boolean>(false);
    const [inputCountry, setInputCountry] = useState<string[]>([]);
    const [inputRole, setInputRole] = useState<string[]>([]);
    const [isAdd, setisAdd] = useState<number>(0);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isModalOpenDelete, setModalOpenDelete] = useState<boolean>(false);
    const [method, setMethod] = useState<string>('');
    const [modalOpen, setModalCatchResult] = useState<boolean>(false);
    const [responseValidation, setResponseValidation] = useState<boolean>(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        setValue,
    } = useForm<IForm>({resolver: yupResolver(schemaFormModal(t)), mode: 'onChange'});
    const onSubmitDelete = async () => {
        try {
            if (isAdd === 3) {
                await dispatch(fetchDeleteBanners(bannerId, token));
            }
            reset({
                name: '',
                name_en: '',
                content: '',
                content_en: '',
                file: null,
            });
            closeModalDelete();
        } catch (error) {
            // Maybe log the error?
        } finally {
            dispatch(fetchGetBanners(token, currentPage, limit));
            setFlag((prev) => !prev);
        }
    };

    const onSubmit = async (data: IForm) => {
        if (errorFile === '') {
            const formData = new FormData();
            if (data.file && data.file.size !== -1) {
                formData.append('attachment[name]', data.name);
                formData.append('attachment[fileName]', data.file.name);
                formData.append('attachment[mediaType]', data.file.type);
                formData.append('attachment[extension]', data.file.type.split('/')[1]);
                formData.append('attachment[size]', data.file.size.toString());
                formData.append('attachment[file]', data.file);
            }
            const json = {
                name: `[es]${data.name}[/es][en]${data.name_en ? `${data.name_en}` : ''}[/en]`,
                content: `[es]${data.content}[/es][en]${
                    data.content_en ? `${data.content_en}` : ''
                }[/en]`,
                ...(data?.file?.size !== -1 && {
                    attachment: {
                        name: data?.file?.name,
                        fileName: data?.file?.name,
                        mediaType: data?.file?.type,
                        extension: data?.file?.type.split('/')[1],
                    },
                }),
                countryIds: data.countries,
                roles: data.roles,
            };
            try {
                if (isAdd === 1) {
                    await dispatch(fetchPostBanners(token, json, formData, data.file));
                }
                if (isAdd === 2) {
                    await dispatch(
                        fetchPutBanners(
                            bannerId,
                            token,
                            json,
                            data?.file?.size !== -1 ? data.file : ''
                        )
                    );
                }
                if (isAdd === 3) {
                    await dispatch(fetchDeleteBanners(bannerId, token));
                }
                reset({
                    name: '',
                    name_en: '',
                    content: '',
                    content_en: '',
                    file: null,
                });
                closeModal();
                setInputRole([]);
                setInputCountry([]);
            } catch (error) {
                // Maybe log the error?
            } finally {
                dispatch(fetchGetBanners(token, currentPage, limit));
                setFlag((prev) => !prev);
            }
        }
    };

    const handleChangeDownload = (value: any, type: number) => {
        dispatch(fetchGetBannersByid(token, value, type));
    };

    const openModal = () => {
        setModalOpen(true);
    };
    const openModalDelete = () => {
        setModalOpenDelete(true);
    };
    const addModal = () => {
        openModal();
        setisAdd(1);
        setMethod(i18n.language === 'es' ? 'Nuevo' : 'New');
        setInputRole([]);
        setInputCountry([]);
        reset({
            name: '',
            name_en: '',
            content: '',
            content_en: '',
            file: null,
        });
        setErrorFile('');
    };
    const editModal = (item: any) => {
        const name = extractLanguage(item.name);
        const content = extractLanguage(item.content);

        store.dispatch(fetchGetBannersByidInfo(token, item.id));
        const rolesMapperIds = item.roles
            ? item.roles.filter((item: IRole) => item.enabled).map((item: IRole) => item.id)
            : [];
        const rolesMapperNames = item.roles
            ? item.roles.filter((item: IRole) => item.enabled).map((item: IRole) => item.name)
            : [];
        openModal();
        setisAdd(2);
        setMethod(i18n.language === 'es' ? 'Editar' : 'Edit');
        setBannerId(item.id);
        reset({
            name: name.es,
            name_en: name.en,
            content: content.es,
            content_en: content.en,
            file: null,
        });
        setInputRole(rolesMapperNames);
        setInputCountry(getCountryName(item.countries));
        setValue('roles', rolesMapperIds);
        setValue('countries', getCountryId(item.countries));
        setErrorFile('');
        store.subscribe(() => {
            const state = store.getState();
            if (state.banners.file !== null && state.banners.file !== undefined) {
                setValue('file', state.banners.file);
            }
        });
    };
    const deleteModal = (item: any) => {
        openModalDelete();
        setisAdd(3);
        setMethod(i18n.language === 'es' ? 'Eliminar' : 'Delete');
        setBanner(item.name);
        setBannerId(item.id);
    };
    const closeModal = () => {
        setModalOpen(false);
        setModalCatchResult(false);
    };
    const closeModalDelete = () => {
        setModalOpenDelete(false);
    };
    const closeModalMedia = () => {
        dispatch(getCloseMediaType());
    };
    const handleChangeRole = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterRole(value);
    };
    const handleChangeCountry = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterCountry(value);
    };
    const hanldeCheckAllCountry = () => {
        setCheckAllCountry((prev) => {
            cleanFilterCountry(!prev ? countryCatalog.map((item: any) => item.countryName) : []);
            return !prev;
        });
    };
    const hanldeCheckAllRole = () => {
        setCheckAllRole((prev) => {
            cleanFilterRole(
                !prev
                    ? roles.filter((role: IRole) => role.enabled).map((item: IRole) => item.name)
                    : []
            );
            return !prev;
        });
    };

    const cleanFilterCountry = (value: any) => {
        const selectedCountry =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));
        setInputCountry(selectedCountry);
        let newArray = [];
        if (selectedCountry.length > 0) {
            newArray = selectedCountry.map((item: string) => {
                return countryCatalog.find((e: ICountry) => e.countryName === item);
            });
        }
        setValue(
            'countries',
            newArray.map((item: ICountry) => {
                return item.id;
            })
        );
    };
    const cleanFilterRole = (value: any) => {
        const selectedRole =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));

        let newArray = [];

        setInputRole(selectedRole);

        if (selectedRole.length > 0) {
            newArray = selectedRole.map((item: string) => {
                return roles.find((e: IRole) => e.name === item);
            });
        }
        setValue(
            'roles',
            newArray.map((item: IRole) => {
                return item.id;
            })
        );
    };

    const handleFileChange = (event: any, type: string) => {
        event.stopPropagation();
        const file = type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0];
        if (!file) {
            setErrorFile(t('manuals.file.error.required_file'));
            return;
        }
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setErrorFile(t('manuals.file.error.invalid_file_type'));
            return;
        } else if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(
                `${t('manuals.file.error.invalid_file_size_part_1')} ${MAX_FILE_SIZE_MB} MB. ${t(
                    'manuals.file.error.invalid_file_size_part_2'
                )}`
            );
            return;
        } else {
            setErrorFile('');
        }
    };
    useEffect(() => {
        setCheckAllCountry(inputCountry.length === countryCatalog.length);
    }, [inputCountry]);
    useEffect(() => {
        setCheckAllRole(inputRole.length === roles.filter((role: IRole) => role.enabled).length);
    }, [inputRole]);
    useEffect(() => {
        if (token) {
            dispatch(fetchGetBanners(token, currentPage, limit));
            dispatch(fetchGetRoles(token, 1, 0));
        }
    }, [dispatch, token]);
    useEffect(() => {
        if (!loading && flag) {
            if (error) {
                setResponseValidation(true);
                setModalCatchResult(true);
            } else if (!error) {
                setResponseValidation(false);
                setModalCatchResult(true);
            }
            setFlag((prev) => !prev);
        }
    }, [error, loading]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '20px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('banners.title')}
                </Typography>
                {hasConfiguration && (
                    <Can I={BannersEnum.CREATE} a={FeatureCodeEnum.BANNERS}>
                        <AddButton
                            onClick={addModal}
                            disabled={
                                notAllowed(FeatureCodeEnum.BANNERS, BannersEnum.CREATE) ||
                                loading ||
                                banners.length > 5
                            }
                            sx={{
                                backgroundColor:
                                    banners.length > 5 ? '#D9D9D9' : theme.palette.primary.main,
                            }}
                        >
                            {t('banners.new_banner').toUpperCase()}
                        </AddButton>
                    </Can>
                )}
            </Box>
            <TableContainer
                container
                width={hasConfiguration ? 'calc(100vw - 230px)' : 'calc(100vw - 56px)'}
            >
                <Grid item xs={!hasConfiguration ? 6 : 3} sx={{boxShadow: 0}}>
                    <TableCellHeadInitial> {t('banners.modal.columns.name')}</TableCellHeadInitial>
                </Grid>
                <Grid item xs={!hasConfiguration ? 4 : 3}>
                    <TableCellHead>{t('banners.modal.columns.content')}</TableCellHead>
                </Grid>
                {hasConfiguration ? (
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('banners.modal.columns.modification_date')}
                        </TableCellHead>
                    </Grid>
                ) : null}
                {hasConfiguration ? (
                    <Grid item xs={2}>
                        <TableCellHead>{t('banners.modal.columns.user')}</TableCellHead>
                    </Grid>
                ) : null}

                <Grid item xs={1}>
                    <TableCellHeadFinal>{t('banners.modal.columns.actions')}</TableCellHeadFinal>
                </Grid>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    banners.map((item: ITable) => (
                        <>
                            <Grid item xs={!hasConfiguration ? 6 : 3} display="grid">
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 600,
                                            color: '#00ACFF',
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                cursor: 'pointer',
                                            },
                                        }}
                                        onClick={() => {
                                            handleChangeDownload(item.id, 1);
                                        }}
                                    >
                                        {traslateColumnValue(item.name, currentLanguage)}
                                    </Typography>
                                </TableCell>
                            </Grid>
                            <Grid item xs={!hasConfiguration ? 4 : 3} display="grid">
                                <TableCell>
                                    {traslateColumnValue(item.content, currentLanguage)}
                                </TableCell>
                            </Grid>
                            {hasConfiguration ? (
                                <Grid item xs={2} display="grid">
                                    <TableCell>
                                        {DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy')}
                                    </TableCell>
                                </Grid>
                            ) : null}
                            {hasConfiguration ? (
                                <Grid item xs={2} display="grid">
                                    <TableCell>{item.modifiedBy}</TableCell>
                                </Grid>
                            ) : null}

                            <Grid item xs={1} display="grid">
                                <TableCellIcons>
                                    {hasConfiguration && (
                                        <>
                                            <Can I={BannersEnum.EDIT} a={FeatureCodeEnum.BANNERS}>
                                                <img
                                                    onClick={() => {
                                                        editModal(item);
                                                    }}
                                                    src={IconEdit}
                                                    title={t('manuals.modals.edit')}
                                                />
                                            </Can>
                                            <Can I={BannersEnum.DELETE} a={FeatureCodeEnum.BANNERS}>
                                                <img
                                                    onClick={() => {
                                                        deleteModal(item);
                                                    }}
                                                    src={IconDelete}
                                                    title={t('manuals.modals.delete')}
                                                />
                                            </Can>
                                        </>
                                    )}
                                </TableCellIcons>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainer>
            <Modal open={isModalOpen} onClose={closeModal}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {method} {t('banners.modal.title_part_2')}
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box
                            display="flex"
                            sx={{maxWidth: '634px'}}
                            justifyContent="space-between"
                            paddingBottom="10px"
                        >
                            <Controller
                                render={({field, fieldState}) => (
                                    <FormControl sx={{width: '47%'}}>
                                        <InputLabelRol>
                                            {t('banners.modal.fields.role')}
                                        </InputLabelRol>
                                        <Select
                                            {...field}
                                            multiple
                                            value={inputRole}
                                            onChange={handleChangeRole}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected: any) => selected.join(', ')}
                                            error={!!fieldState.error}
                                            MenuProps={{
                                                sx: {
                                                    '&& .Mui-selected': {
                                                        backgroundColor: '#FFFF',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                value={'notCheck'}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        hanldeCheckAllRole();
                                                    }, 500);
                                                }}
                                            >
                                                <Checkbox
                                                    checked={checkAllRole}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: theme.palette.primary.main,
                                                        },
                                                    }}
                                                />
                                                <ListItemText
                                                    primary={t('banners.modal.fields.select_all')}
                                                />
                                            </MenuItem>
                                            {roles
                                                .filter((item: IRole) => item.enabled)
                                                .map((item: IRole, index: number) => (
                                                    <MenuItem key={index} value={item?.name}>
                                                        <Checkbox
                                                            checked={
                                                                inputRole !== undefined &&
                                                                inputRole.length > 0 &&
                                                                inputRole.indexOf(item.name) > -1
                                                            }
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: theme.palette.primary
                                                                        .main,
                                                                },
                                                            }}
                                                        />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {fieldState.error ? (
                                            <FormHelperText error>
                                                {fieldState.error?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                )}
                                control={control}
                                name="roles"
                            />

                            <Controller
                                render={({field, fieldState}) => (
                                    <FormControl sx={{width: '47%'}}>
                                        <InputLabelRol>
                                            {t('banners.modal.fields.country')}
                                        </InputLabelRol>
                                        <Select
                                            {...field}
                                            multiple
                                            value={inputCountry}
                                            onChange={handleChangeCountry}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected: any) => selected.join(', ')}
                                            error={!!fieldState.error}
                                            MenuProps={{
                                                sx: {
                                                    '&& .Mui-selected': {
                                                        backgroundColor: '#FFFF',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                value={'notCheck'}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        hanldeCheckAllCountry();
                                                    }, 500);
                                                }}
                                            >
                                                <Checkbox
                                                    checked={checkAllCountry}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: theme.palette.primary.main,
                                                        },
                                                    }}
                                                />
                                                <ListItemText
                                                    primary={t('banners.modal.fields.select_all')}
                                                />
                                            </MenuItem>
                                            {countryCatalog.map((item: ICountry, index: number) => (
                                                <MenuItem key={index} value={item.countryName}>
                                                    <Checkbox
                                                        checked={
                                                            inputCountry.indexOf(item.countryName) >
                                                            -1
                                                        }
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        }}
                                                    />
                                                    <ListItemText primary={item.countryName} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {fieldState.error ? (
                                            <FormHelperText error>
                                                {fieldState.error?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                )}
                                control={control}
                                name="countries"
                            />
                        </Box>
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={`${t('banners.modal.fields.name')}*`}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: fieldState.error ? '22px' : '0px', mb: '10px'}}
                                />
                            )}
                            name="name"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={`${t('banners.modal.fields.name_en')}*`}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: fieldState.error ? '22px' : '0px', mb: '5px'}}
                                />
                            )}
                            name="name_en"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="content"
                                    label={`${t('banners.modal.fields.content')}*`}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{margin: fieldState.error ? '0px' : '7px 0px', mb: '5px'}}
                                />
                            )}
                            name="content"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="content"
                                    label={`${t('banners.modal.fields.content_en')}*`}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{margin: fieldState.error ? '0px' : '7px 0px', mb: '5px'}}
                                />
                            )}
                            name="content_en"
                            control={control}
                        />
                        <Controller
                            name="file"
                            control={control}
                            defaultValue={null}
                            render={({field, fieldState}) => (
                                <Box>
                                    <FileUploadContainer
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            if (
                                                e.dataTransfer.files &&
                                                e.dataTransfer.files.length > 0
                                            ) {
                                                field.onChange(e.dataTransfer.files[0]);
                                                handleFileChange(e, 'drop');
                                            }
                                        }}
                                        onClick={() => {
                                            const fileInput = document.getElementById('fileInput');
                                            if (fileInput) {
                                                fileInput.click();
                                            }
                                        }}
                                        sx={{
                                            marginTop: fieldState.error ? '0px' : '14px',
                                            borderColor: fieldState.error ? 'red' : 'none',
                                        }}
                                    >
                                        <Input
                                            id="fileInput"
                                            type="file"
                                            onChange={(e) => {
                                                const file = e.target.files
                                                    ? e.target.files[0]
                                                    : null;
                                                if (file) field.onChange(file);
                                                handleFileChange(e, 'change');
                                            }}
                                        />
                                        {field.value && !errorFile ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography variant="body1" sx={{mr: 1}}>
                                                    {field.value.name}
                                                </Typography>
                                                <IconButton
                                                    aria-label={t(
                                                        'banners.modal.buttons.delete_file'
                                                    )}
                                                    onClick={(e) => {
                                                        field.onChange(null);
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <FileLabelButton variant="outlined" as="span">
                                                    <img src={CloudIcon} />
                                                    <LabelFileUpload>
                                                        {t('drag_upload_files')}
                                                    </LabelFileUpload>
                                                </FileLabelButton>
                                                <Typography variant="caption" color="error">
                                                    {errorFile}
                                                </Typography>
                                            </Box>
                                        )}
                                    </FileUploadContainer>
                                </Box>
                            )}
                        />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '14px',
                            }}
                        >
                            {errors?.file?.message}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '56px',
                                alignItems: 'center',
                                marginTop: '20px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModal}>
                                {t('banners.modal.buttons.update_cancel_button').toUpperCase()}
                            </ButtonCancel>
                            <SaveButton disabled={loading}>
                                {loading ? (
                                    <Loader size={24} />
                                ) : (
                                    t('banners.modal.buttons.update_save_button').toUpperCase()
                                )}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal open={isModalOpenDelete} onClose={closeModalDelete}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {method} {t('banners.modal.title_part_2')}
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ImgIcon src={IconAlertDelete} />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 400,
                                marginBottom: '14px',
                                color: '#13111a',
                            }}
                        >
                            {t('banners.modal.delete_question_part_1')} {banner}
                            {t('banners.modal.delete_question_part_2')}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                height: '56px',
                                alignItems: 'center',
                                marginTop: '20px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModalDelete}>
                                {t('banners.modal.buttons.delete_cancel_button').toUpperCase()}
                            </ButtonCancel>
                            <SaveButton disabled={loading} onClick={onSubmitDelete}>
                                {loading ? (
                                    <Loader size={24} />
                                ) : (
                                    t('banners.modal.buttons.delete_accept_button').toUpperCase()
                                )}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>

            <Modal onClose={closeModalMedia} open={showModalPreview}>
                <Box sx={{width: '50vw'}}>
                    <MediaComponent type={mediaType} src={mediaSrc} />
                </Box>
            </Modal>
            {modalOpen && (
                <>
                    {responseValidation ? (
                        <WarningUpdateModal isModalOpen={modalOpen} closeModal={closeModal} />
                    ) : (
                        <SuccessUpdateModal isModalOpen={modalOpen} closeModal={closeModal} />
                    )}
                </>
            )}
        </Box>
    );
};
Banners.acl = {
    action: BannersEnum.SEE_LIST,
    subject: FeatureCodeEnum.BANNERS,
};
export default Banners;
