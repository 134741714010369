import React from 'react';

import {LangContent} from '@/components/terms/components/content';
import {englishPrivacyPolicy} from '@/components/terms/constants/privacy-policy.constant';
import {englishTerms} from '@/components/terms/constants/terms.constant';

function SpanishContent(): React.ReactElement {
    return <LangContent lang={[...englishTerms, ...englishPrivacyPolicy]} />;
}

export default SpanishContent;
