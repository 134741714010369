import {Box, IconButton} from '@mui/material';

import React from 'react';

import {ACLObj} from '@/components/auth/guard/AclGuard';
import {Can} from '@/context/AbilityContext';
import {AccountStatusEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import {BillingStatementData} from '@components/billingStatement/components/billingStatementData';
import {BillingStatementFilters} from '@components/billingStatement/components/filtersComponent';
import {TitlePageComponent} from '@components/billingStatement/components/titleComponent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
const BillingStatement: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {state} = useLocation();
    const handleGoBack = () => {
        navigate(-1);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <TitlePageComponent title={t('billingStatement.title')}>
                {state && state.from === 'home' && (
                    <IconButton onClick={handleGoBack} aria-label="Go back">
                        <ArrowBackIcon />
                    </IconButton>
                )}
            </TitlePageComponent>
            <Can I={AccountStatusEnum.FILTER} a={FeatureCodeEnum.ACCOUNT_STATUS}>
                <BillingStatementFilters />
            </Can>
            <BillingStatementData />
        </Box>
    );
};
BillingStatement.acl = {
    action: AccountStatusEnum.SEE_LIST,
    subject: FeatureCodeEnum.ACCOUNT_STATUS,
};

export default BillingStatement;
