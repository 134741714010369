import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {CustomsAgentsEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import IconNewInvoice from '@components/UI/atoms/icons/IconNewInvoice.svg';
import {TextButton, TextButtonDetails} from '@components/UI/molecules/textButton/textButtons';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {IFormDate} from '@components/orderSimply/interface';
import {fetchGetDownloadOrdersAgents} from '@components/orderSimply/redux/actions/OrderSimplyDownloadActions';
import {
    fetchGetOrdersAgents,
    postOrderFilters,
} from '@components/orderSimply/redux/actions/OrdersSimplyActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

import {statusTextValuesAgents} from '@/components/UI/atoms/buttons/constant/status-text';

import {InputLabelRol, StyledSelect, TypographyStyledUser} from '@/components/faqs/StyledFAQ';
import {Can} from '@/context/AbilityContext';
import {parseDate} from '@/utils/date.util';
import {ACLObj} from '@components/auth/guard/AclGuard';
import ModalUploadInvoiceAgents from '@components/orderSimply/modals/ModalUploadInvoiceAgents';
import {setCurrentAgent} from '@components/orderSimply/redux/actions/OrderAgentsActions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import StatusTextGenecic from '@/components/UI/atoms/buttons/StatusTextGeneric';
const processString = (inputString: string, t: any) => {
    const recordsKeys = inputString.split(',');
    const records = recordsKeys.map((key: string) => {
        return statusTextValuesAgents[key];
    });

    const firstRecord = records[0];
    const remainingRecordsCount = records.length - 1;
    let remainingRecordsMessage = '';
    if (remainingRecordsCount > 0) {
        remainingRecordsMessage = `(+${remainingRecordsCount})`;
    }

    return t(`poagents.status.${firstRecord}`) + remainingRecordsMessage;
};

const OrderAgents: React.FC & {acl?: ACLObj} = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const orderssimply = useSelector((state: RootState) => state.orderssimply.orderssimply);

    const currentPage = useSelector((state: RootState) => state.orderssimply.page);
    const limit = useSelector((state: RootState) => state.orderssimply.limit);
    const filters = useSelector((state: RootState) => state.orderssimply.filters);
    const totalPages = useSelector((state: RootState) => state.orderssimply.totalPages);
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const {notAllowed, allowed} = useCheckAbilities();
    const [selectedStates, setSelectedStates] = useState<string[]>(
        Object.keys(statusTextValuesAgents)
    );
    const [checkAllStatus, setCheckAllStatus] = useState(false);
    const schemaFormModal = yup.object({
        dateIni: yup.date().required('Fecha inicio requerido'),
        dateEnd: yup.date().required('Fecha final requerido'),
        year: yup.string(),
        society: yup.string(),
        pediment: yup.string(),
        folio: yup.string(),
        status: yup.string(),
        aa: yup.string(),
    });
    const {handleSubmit, control, watch, setValue} = useForm<IFormDate>({
        resolver: yupResolver(schemaFormModal),
    });
    const watchForm = watch();
    const handleChangeStatus = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterStatus(value);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetOrdersAgents(token, value, limit, filters));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetOrdersAgents(token, 1, event.target.value, filters));
    };
    const handleOpenModal = (type: string) => {
        setModalOpen((prev) => !prev);
    };
    const closeModal = () => {
        dispatch(setCurrentAgent({}));
        setModalOpen(false);
    };

    const onSubmit = () => {
        const dateIni =
            typeof watchForm.dateIni === 'string'
                ? watchForm.dateIni
                : DateTime.fromISO(watchForm.dateIni.toISOString()).toFormat('MM-dd-yyyy');
        const dateEnd =
            typeof watchForm.dateEnd === 'string'
                ? watchForm.dateEnd
                : DateTime.fromISO(watchForm.dateEnd.toISOString()).toFormat('MM-dd-yyyy');
        const json = {
            dateIni,
            dateEnd,
            society: watchForm.society ?? '',
            pediment: watchForm.pediment ?? '',
            folio: watchForm.folio ?? '',
            year: watchForm.year ?? '',
            status: watchForm.status ?? selectedStates.join(','),
            aa: watchForm.aa ?? '',
        };
        dispatch(postOrderFilters(json));

        dispatch(fetchGetOrdersAgents(token, currentPage, limit, json));
    };
    const handleChangeDownload = (format: any) => {
        const json = {
            dateIni: filters.dateIni ?? DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
            dateEnd: filters.dateEnd ?? DateTime.now().toFormat('yyyy-MM-dd'),
            society: filters.society ?? watchForm.society ?? '',
            pediment: filters.pediment ?? watchForm.pediment ?? '',
            folio: filters.folio ?? watchForm.folio ?? '',
            year: filters.year ?? watchForm.year ?? '',
            status: filters.status ?? watchForm.status ?? selectedStates.join(','),
            aa: filters.aa ?? watchForm.aa ?? '',
        };
        dispatch(fetchGetDownloadOrdersAgents(token, format, json));
    };
    const hanldeCheckAllStatus = () => {
        setCheckAllStatus((prev) => {
            cleanFilterStatus(!prev ? Object.keys(statusTextValuesAgents) : []);
            return !prev;
        });
    };

    const cleanFilterStatus = (value: any) => {
        const selectedStatus =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));
        setSelectedStates(selectedStatus);
        let newArray = [];
        if (selectedStatus.length > 0) {
            newArray = selectedStatus.map((item: string) => {
                return Object.keys(statusTextValuesAgents).find((e: string) => e === item);
            });
        }
        setValue('status', newArray.length > 0 ? newArray.join(',') : '');
    };
    useEffect(() => {
        if (token) {
            const json = {
                ...filters,
                dateIni: filters.dateIni ?? DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
                dateEnd: filters.dateEnd ?? DateTime.now().toFormat('yyyy-MM-dd'),
                society: watchForm.society ?? '',
                pediment: watchForm.pediment ?? '',
                folio: watchForm.folio ?? '',
                year: watchForm.year ?? '',
                status: watchForm.status ?? selectedStates.join(','),
                aa: watchForm.aa ?? '',
            };
            if (filters.dateIni)
                setValue('dateIni', DateTime.fromJSDate(new Date(json.dateIni)).toJSDate());

            if (filters.dateEnd)
                setValue('dateEnd', DateTime.fromJSDate(new Date(json.dateEnd)).toJSDate());

            dispatch(fetchGetOrdersAgents(token, currentPage, limit, json));
        }
    }, [dispatch, token]);
    useEffect(() => {
        setCheckAllStatus(selectedStates.length === Object.keys(statusTextValuesAgents).length);
    }, [selectedStates]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('poagents.customs_agents')}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Can I={CustomsAgentsEnum.FILTER} a={FeatureCodeEnum.CUSTOMS_AGENTS}>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '15px',
                            paddingBottom: '0',

                            background: '#fff',
                            gap: '10px',
                        }}
                    >
                        <Can I={CustomsAgentsEnum.FIND_SUPPLIER} a={FeatureCodeEnum.CUSTOMS_AGENTS}>
                            <Controller
                                name="society"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('confirmposimply.filters.society')}
                                            size="small"
                                        />
                                    );
                                }}
                                disabled={notAllowed(
                                    FeatureCodeEnum.CUSTOMS_AGENTS,
                                    CustomsAgentsEnum.FIND_SUPPLIER
                                )}
                            />
                        </Can>
                        <Controller
                            name="pediment"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('poagents.filters.ref_request')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                        <Can I={CustomsAgentsEnum.FILTER_AA} a={FeatureCodeEnum.CUSTOMS_AGENTS}>
                            <Controller
                                name="aa"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('poagents.filters.aa_number')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                        </Can>
                        <Controller
                            name="folio"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('poagents.filters.gc_noun')}
                                        size="small"
                                    />
                                );
                            }}
                        />

                        <Controller
                            render={({field, fieldState}) => (
                                <FormControl>
                                    <InputLabelRol>
                                        {t('components.status_selector.title')}
                                    </InputLabelRol>
                                    <StyledSelect
                                        {...field}
                                        multiple
                                        value={selectedStates}
                                        onChange={handleChangeStatus}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected: any) =>
                                            processString(selected.join(','), t)
                                        }
                                        sx={{minWidth: '100px'}}
                                        MenuProps={{
                                            sx: {
                                                '&& .Mui-selected': {
                                                    backgroundColor: '#FFFF',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            },
                                        }}
                                        size="small"
                                    >
                                        <MenuItem
                                            value={'notCheck'}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    hanldeCheckAllStatus();
                                                }, 500);
                                            }}
                                        >
                                            <Checkbox
                                                onChange={hanldeCheckAllStatus}
                                                checked={checkAllStatus}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: theme.palette.primary.main,
                                                    },
                                                }}
                                            />
                                            <ListItemText primary={'Seleccionar todo'} />
                                        </MenuItem>
                                        {Object.keys(statusTextValuesAgents).map(
                                            (key: string, index: number) => (
                                                <MenuItem key={index + key} value={key}>
                                                    <Checkbox
                                                        checked={selectedStates.indexOf(key) > -1}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        }}
                                                    />

                                                    <ListItemText
                                                        primary={t(`poagents.status.${key}`)}
                                                    />
                                                </MenuItem>
                                            )
                                        )}
                                    </StyledSelect>
                                </FormControl>
                            )}
                            control={control}
                            name="status"
                        />
                    </Box>
                </Can>
                <Box
                    sx={{
                        display: 'flex',
                        padding: '15px',
                        paddingTop: '0',
                        height: '73px',
                        background: '#fff',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <Can I={CustomsAgentsEnum.FILTER} a={FeatureCodeEnum.CUSTOMS_AGENTS}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                                <Controller
                                    name="dateIni"
                                    control={control}
                                    defaultValue={DateTime.now().startOf('month').toJSDate()}
                                    render={({field: {onChange, value}}) => (
                                        <DatePicker
                                            label={t('logbook_and_details.start_date')}
                                            value={parseDate(value)}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                            marginRight: '10px',
                                                        },
                                                    }}
                                                />
                                            )}
                                            maxDate={watchForm.dateEnd}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                                <Controller
                                    name="dateEnd"
                                    control={control}
                                    defaultValue={DateTime.now().toJSDate()}
                                    render={({field: {onChange, value}}) => (
                                        <DatePicker
                                            label={t('logbook_and_details.end_date')}
                                            value={parseDate(value)}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                            marginRight: '10px',
                                                        },
                                                    }}
                                                />
                                            )}
                                            minDate={watchForm.dateIni}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <Controller
                                name="year"
                                control={control}
                                defaultValue={2024}
                                render={({field, fieldState}) => (
                                    <FormControl sx={{marginLeft: '10px', height: '40px'}}>
                                        <InputLabel>
                                            {t('components.year_selector.title')}
                                        </InputLabel>
                                        <Select
                                            label={t('components.year_selector.title')}
                                            {...field}
                                            error={!!fieldState.error}
                                            sx={{width: '100px', height: '40px'}}
                                        >
                                            <MenuItem value={2024}>2024</MenuItem>
                                            <MenuItem value={2023}>2023</MenuItem>
                                            <MenuItem value={2022}>2022</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            <TextButton
                                type="submit"
                                onClick={onSubmit}
                                disabled={notAllowed(
                                    FeatureCodeEnum.CUSTOMS_AGENTS,
                                    CustomsAgentsEnum.FILTER
                                )}
                            >
                                {t('components.filter_btn.title').toUpperCase()}
                            </TextButton>
                        </Can>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                        <Can
                            I={CustomsAgentsEnum.EXPORT_EXCEL_CSV}
                            a={FeatureCodeEnum.CUSTOMS_AGENTS}
                        >
                            <TextButtonDetails
                                type="button"
                                onClick={() => {
                                    handleChangeDownload('csv');
                                }}
                                disabled={notAllowed(
                                    FeatureCodeEnum.CUSTOMS_AGENTS,
                                    CustomsAgentsEnum.EXPORT_EXCEL_CSV
                                )}
                            >
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src={IconDownload} />
                                    <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                        CSV
                                    </TypographyStyledUser>
                                </div>
                            </TextButtonDetails>
                            <TextButtonDetails
                                type="button"
                                onClick={() => {
                                    handleChangeDownload('xlsx');
                                }}
                                disabled={notAllowed(
                                    FeatureCodeEnum.CUSTOMS_AGENTS,
                                    CustomsAgentsEnum.EXPORT_EXCEL_CSV
                                )}
                            >
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src={IconDownload} />
                                    <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                        XLSX
                                    </TypographyStyledUser>
                                </div>
                            </TextButtonDetails>
                        </Can>
                    </Box>
                </Box>
            </Box>
            <TableContainerBinnance container>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={1}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.society_rfc')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.filters.aa_number')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.filters.ref_request')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.request')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.filters.gc_noun')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('components.status_selector.title')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('description')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('expense_account')}
                        </TableCellHead>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    orderssimply.map((item: any) => {
                        const verifiedStatus =
                            item.status === 'CREATED' ? 'DELIVERED' : item.status;
                        return (
                            <>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.taxId}</TableCell>
                                </Grid>
                                <Grid item xs={2} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.customsAgentCode}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={2} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.requestReference}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={2} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.request}</TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.folio}</TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        <StatusTextGenecic
                                            status={verifiedStatus}
                                            text={t(`poagents.status.${verifiedStatus}`)}
                                        />
                                    </TableCell>
                                </Grid>
                                <Grid item xs={2} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.description}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        <Can
                                            I={CustomsAgentsEnum.UPLOAD_BILLS}
                                            a={FeatureCodeEnum.CUSTOMS_AGENTS}
                                        >
                                            <img
                                                title={t('poagents.table.actions.expense_account')}
                                                src={IconNewInvoice}
                                                onClick={() => {
                                                    if (
                                                        allowed(
                                                            FeatureCodeEnum.CUSTOMS_AGENTS,
                                                            CustomsAgentsEnum.UPLOAD_BILLS
                                                        )
                                                    ) {
                                                        dispatch(setCurrentAgent(item));
                                                        handleOpenModal('Factura');
                                                    }
                                                }}
                                            />
                                            {item.folio && (
                                                <ArrowForwardIcon
                                                    sx={{cursor: 'pointer', marginLeft: '10px'}}
                                                    fontSize="small"
                                                    color="secondary"
                                                    onClick={() => {
                                                        navigate(
                                                            '/poagents/' +
                                                                item.customsAgentCode +
                                                                '/custom-request/' +
                                                                item.request
                                                        );
                                                    }}
                                                    titleAccess={t('view_details')}
                                                />
                                            )}
                                        </Can>
                                    </TableCell>
                                </Grid>
                            </>
                        );
                    })
                )}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <ModalUploadInvoiceAgents isModalOpen={isModalOpen} closeModal={closeModal} />
        </Box>
    );
};
OrderAgents.acl = {
    action: CustomsAgentsEnum.VIEW_LIST,
    subject: FeatureCodeEnum.CUSTOMS_AGENTS,
};
export default OrderAgents;
