import {
    GET_CSFABI,
    GET_CSFABI_SUCCESS,
    GET_CSFABI_ERROR,
    PUT_CSFABI,
    PUT_CSFABI_SUCCESS,
    PUT_CSFABI_ERROR,
    CREATE_FILE_CSFABI,
    CREATE_FILE_CSFABI_SUCCESS,
    CREATE_FILE_CSFABI_ERROR,
    POST_CSFABI,
    POST_CSFABI_SUCCESS,
    POST_CSFABI_ERROR,
    GET_CSFABI_DOWNLOAD,
    GET_CSFABI_DOWNLOAD_SUCCESS,
    GET_CSFABI_DOWNLOAD_ERROR,
    GET_DOWNLOAD_LISTCSF,
    GET_DOWNLOAD_LISTCSF_SUCCESS,
    GET_DOWNLOAD_LISTCSF_ERROR,
    PATCH_FILE_CSF,
    PATCH_FILE_CSF_SUCCESS,
    PATCH_FILE_CSF_ERROR,
} from '@components/csf/csfABI/redux/types/CsfABITypes';
import StringUtils from '@/utils/StringUtils';
import axios from 'axios';
export const getCsfAbi = () => {
    return {
        type: GET_CSFABI,
    };
};

export const getCsfAbiSuccess = (result) => {
    return {
        type: GET_CSFABI_SUCCESS,
        value: result,
    };
};

export const getCsfAbiError = (error, result) => {
    return {
        type: GET_CSFABI_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetCsfAbi(token, page, limit, filters) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        const queryString = StringUtils.jsonToQueryString({
            society: filters?.society ? filters.society : '',
            rfc: filters?.rfc ? filters.rfc : '',
            limit,
            page,
            sortOrder: 'DESC',
            enterpriseRole: 'buyer',
        });
        dispatch(getCsfAbi());

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/branch-offices${queryString}`,
                {headers}
            );
            dispatch(getCsfAbiSuccess(response.data));
        } catch (error) {
            dispatch(getCsfAbiError(true));
        }
    };
}

export const postCsfAbi = () => {
    return {
        type: POST_CSFABI,
    };
};

export const postCsfAbiSuccess = (result) => {
    return {
        type: POST_CSFABI_SUCCESS,
        value: result,
    };
};

export const postCsfAbiError = (error, result) => {
    return {
        type: POST_CSFABI_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostCsfAbi(token, data, file, user) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postCsfAbi());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/branch-office`,
                data,
                {headers}
            );
            await dispatch(fetchPostFileCsf(token, response.data.data.id, file, user));
            await dispatch(postCsfAbiSuccess(response.data));
            await dispatch(patchFileCsfSuccess({}));
            await dispatch(fetchGetCsfAbi(token, 1, 10));
        } catch (error) {
            dispatch(postCsfAbiError(true));
            throw error;
        }
    };
}

export const postFileCsf = () => {
    return {
        type: CREATE_FILE_CSFABI,
    };
};

export const postFileCsfSuccess = (result) => {
    return {
        type: CREATE_FILE_CSFABI_SUCCESS,
        value: result,
    };
};

export const postFileCsfError = (error, result) => {
    return {
        type: CREATE_FILE_CSFABI_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostFileCsf(token, id, data, user) {
    const formData = new FormData();
    formData.append('file', data);
    return async (dispatch) => {
        dispatch(postFileCsf());
        try {
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/v2/enterprise/${user.enterprise.id}/branch-office/${id}/csf`,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                data: formData,
            };
            const response = await axios.request(config);
            const responsePut = await axios.put(`${response.data.data.url}`, data, {
                headers: {'Content-Type': data.type},
            });
            dispatch(postFileCsfSuccess(responsePut.data.data));
        } catch (error) {
            dispatch(postFileCsfError(true));
        }
    };
}

export const getCsfAbiDownload = () => {
    return {
        type: GET_CSFABI_DOWNLOAD,
    };
};

export const getCsfAbiDownloadSuccess = (result) => {
    return {
        type: GET_CSFABI_DOWNLOAD_SUCCESS,
        value: result,
    };
};

export const getCsfAbiDownloadError = (error, result) => {
    return {
        type: GET_CSFABI_DOWNLOAD_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetCsfAbiDownload(token, id) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getCsfAbiDownload());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/enterprise/branch-office/${id}`,
                method: 'GET',
                headers: headers,
            });

            const url = response.data.data.taxProfiles[0].attachment.url;
            const a = document.createElement('a');
            a.href = url;
            a.download = `CSF-${response.data.data.taxProfiles[0].taxId}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            dispatch(getCsfAbiDownloadSuccess());
        } catch (error) {
            dispatch(getCsfAbiDownloadError(true));
        }
    };
}

export const getDownloadListCsf = () => {
    return {
        type: GET_DOWNLOAD_LISTCSF,
    };
};

export const getDownloadListCsfSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_LISTCSF_SUCCESS,
        value: result,
    };
};

export const getDownloadListCsfError = (error) => {
    return {
        type: GET_DOWNLOAD_LISTCSF_ERROR,
        value: error,
    };
};

export function fetchGetDownloadListCsf(token, format, filters) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        const queryString = StringUtils.jsonToQueryString({
            society: filters?.society ? filters.society : '',
            rfc: filters?.rfc ? filters.rfc : '',
            enterpriseRole: 'buyer',
        });
        dispatch(getDownloadListCsf());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/enterprise/branch-offices/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `csfAbi.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getDownloadListCsfSuccess());
        } catch (error) {
            dispatch(getDownloadListCsfError(true));
        }
    };
}

export const patchFileCsf = () => {
    return {
        type: PATCH_FILE_CSF,
    };
};

export const patchFileCsfSuccess = (result) => {
    return {
        type: PATCH_FILE_CSF_SUCCESS,
        value: result,
    };
};

export const patchFileCsfError = (error, result) => {
    return {
        type: PATCH_FILE_CSF_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPatchFileCsf(token, data) {
    const formData = new FormData();
    formData.append('file', data);
    return async (dispatch) => {
        dispatch(patchFileCsf());
        try {
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/v2/utils/pdf-parser/csf`,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                data: formData,
            };
            const response = await axios.request(config);
            dispatch(patchFileCsfSuccess(response.data.data));
            dispatch(patchFileCsfError(false));
        } catch (error) {
            dispatch(patchFileCsfSuccess({}));
            dispatch(patchFileCsfError(true));
        }
    };
}

export const putCsfAbi = () => {
    return {
        type: PUT_CSFABI,
    };
};

export const putCsfAbiSuccess = (result) => {
    return {
        type: PUT_CSFABI_SUCCESS,
        value: result,
    };
};

export const putCsfAbiError = (error, result) => {
    return {
        type: PUT_CSFABI_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutCsfAbi(token, id, data, file, user) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putCsfAbi());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/branch-office/${id}`,
                data,
                {headers}
            );
            await dispatch(fetchPostFileCsf(token, response.data.data.id, file, user));
            await dispatch(postCsfAbiSuccess(response.data));
            await dispatch(patchFileCsfSuccess({}));
            await dispatch(fetchGetCsfAbi(token, 1, 10));
        } catch (error) {
            dispatch(putCsfAbiError(true));
            throw error;
        }
    };
}
