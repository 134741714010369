import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from '@App';
import store from '@config/store';
import {Provider} from 'react-redux';
import {I18nextProvider} from 'react-i18next';
import i18n from '@/i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18n.on('initialized', () => {
    root.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <App />
                    </Suspense>
                </Provider>
            </I18nextProvider>
        </React.StrictMode>
    );
});
