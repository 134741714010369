import React, {useState, useRef, useEffect} from 'react';
import {AppBar, Toolbar, Typography, Container, Box} from '@mui/material';
import {ThemeProvider, useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

import TermsContent from '@/components/terms/components/content/TermsContent';
import Paragraph from '@/components/terms/components/Paragraph';
import {FullPageHeader} from '@/components/UI/organisms/header';

export default function TermsPage() {
    const theme = useTheme();
    const {t, i18n} = useTranslation();

    const queryParams = new URLSearchParams(location.search);
    const ids = queryParams.get('ids') || '';
    const currentLanguage = i18n.language;

    const [externalIds, setExternalIds] = useState<string[]>([]);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ids) {
            const idArray = ids.split(',');
            const isValid = idArray.every((id) => {
                const num = Number(id);
                return Number.isInteger(num) && num > 0;
            });
            if (isValid) {
                setExternalIds(idArray);
            }
        }
    }, [ids]);

    return (
        <ThemeProvider theme={theme}>
            <FullPageHeader />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '90vh',
                    overflow: 'hidden',
                }}
            >
                <AppBar position="static">
                    <Toolbar>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                            }}
                            variant="h6"
                        >
                            {t('terms.title')}{' '}
                            {externalIds.length === 1
                                ? ` ${t('terms.from_order')} #${externalIds}`
                                : ''}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    ref={scrollContainerRef}
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '0.4em',
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,.1)',
                            outline: '1px solid slategrey',
                        },
                    }}
                >
                    <Container sx={{py: 4}}>
                        <Box
                            sx={{
                                maxWidth: 'md',
                                mx: 'auto',
                                fontSize: {xs: '14px', sm: '15px', md: '16px'},
                            }}
                        >
                            {externalIds.length > 1 ? (
                                <Paragraph>
                                    {t('terms.from_orders')} {externalIds.join(', ') ?? ''}
                                </Paragraph>
                            ) : null}
                            <TermsContent language={currentLanguage} />
                        </Box>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
