import {OrderStatusEnum} from '@/components/orderSimply/utils/orderConfirmHelpers';

const ErrorType = {
    AUTH: 'AUTH',
    NETWORK: 'NETWORK',
    NONE: 'NONE',
    RULE: 'RULE',
    SYSTEM: 'SYSTEM',
};

const getNetImport = (type, status, materialType, item, entry, po) => {
    // const baseAmount = item.quantity * item.productDetails[0].price;
    // const taxAmount = Number(item.taxes.reduce((acc, tax) => acc + Number(tax.value), 0));
    if (
        status === OrderStatusEnum.CREATED ||
        status === OrderStatusEnum.CONFIRMED ||
        status === OrderStatusEnum.PARTIALLY_DELIVERED ||
        status === OrderStatusEnum.DELIVERED
    ) {
        return '-';
    }
    const hasEntryNationalBillsReceipt = item.entries.some((e) =>
        e.bills.some((bill) => bill.jsonNational !== null)
    );
    if (hasEntryNationalBillsReceipt) {
        const total = item.entries
            .flatMap((item) => item.bills)
            .reduce((carry, item) => +item.jsonNational?.total + carry, 0);
        return total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        });
    }

    const hasEntryNationalBills = item.entries.some((e) =>
        e.bills.some((bill) => bill.jsonCfdi !== null)
    );
    const hasEntryForeignBills = item.entries.some((e) =>
        e.bills.some((bill) => bill.foreignInvoice !== null)
    );

    if (getStatusByEntry(entry, status, po.vendorCompany?.websiteType) === 'DELIVERED') {
        return '-';
    }

    // NATIONAL
    if (item.bill && item.bill.jsonCfdi) {
        return Number(item.bill.jsonCfdi.Total).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        });
    }
    //NATIONAL NEW
    if (!item.bill && hasEntryNationalBills) {
        const total = item.entries
            .flatMap((item) => item.bills)
            .reduce(
                (carry, item) => +item.jsonCfdi?.Total || +item.foreignInvoice?.total + carry,
                0
            );
        return total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        });
    }

    // FOREING
    if (!item.bill && hasEntryForeignBills) {
        const total = item.entries
            .flatMap((item) => item.bills)
            .reduce((carry, item) => item.foreignInvoice?.amount + carry, 0);
        return total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        });
    }
    return '-';

    // if (status === 'CONFIRMED' || status === 'CREATED') {
    //     return type === 'FO' ? baseAmount + taxAmount : '-';
    // }

    // if (type === 'FO' || materialType === 'SERVICE') {
    //     return baseAmount + taxAmount;
    // }

    // if (materialType === 'PRODUCT') {
    //     return item.received * item.productDetails[0].price + taxAmount;
    // }
};

const getBillData = (item, entry) => {
    let billingRef = item.billingRef;

    const hasEntryNationalBillsReceipt = item.entries.some((e) =>
        e.bills.some((bill) => bill.jsonNational !== null)
    );

    billingRef = entry?.bills[0]?.jsonCfdi
        ? entry?.bills[0]?.jsonCfdi?.Serie
            ? entry?.bills[0]?.jsonCfdi?.Serie + entry?.bills[0]?.jsonCfdi?.Folio
            : entry?.bills[0]?.jsonCfdi?.Folio
        : entry?.bills[0]?.foreignInvoice?.invoiceNumber;

    let billingDate = item.billingDate;
    billingDate = entry?.bills[0]?.jsonCfdi
        ? entry?.bills[0]?.jsonCfdi?.Fecha
        : entry?.bills[0]?.foreignInvoice?.invoiceDate;
    if (hasEntryNationalBillsReceipt) {
        billingDate = entry?.bills[0]?.jsonNational?.invoiceDate;
        billingRef = entry.bills[0]?.jsonNational?.invoiceAccount;
    }

    return {billingRef, billingDate};
};

const createFormattedObject = (
    po,
    item,
    {lineItembillValidations = [], isBillValidated = false, entry = null, newStatus} = {}
) => ({
    orderId: po.referenceId,
    externalId: po.buyerBranchOffice?.externalId,
    societyName: po.buyerBranchOffice?.name,
    billReference: getBillData(item, entry).billingRef,
    billValidated: isBillValidated,
    paymentDetails: po.paymentDetails,
    textDescription: `${item.productDetails[0].productName}${
        item.productDetails[0].description || ''
    }`, //falta
    billAmount: getNetImport(po.type, po.status, po.materialType, item, entry, po),
    currency: item.productDetails[0].currency,
    sku: item.productDetails[0].sku,
    billingDate: getBillData(item, entry).billingDate,
    pos: item.position,
    priceUnit: item.productDetails[0].price,
    status: getStatusByEntry(entry, newStatus, po.vendorCompany?.websiteType),
    paymentDate: item.paymentDate,
    productName: item.productDetails[0].productName,
    entrySheet: entry?.number || item.entrySheet,
    goodsReceiptReference: entry?.goodsReceiptReference || '---',
    orderFindId: po.id,
    companyGroup: po.buyerCompany?.companyGroup,
    remaining: item.remaining, //Falta, parece que no se utiliza en esta visual
    iterations: po.iterations,
    lineItemId: item.id,
    errors: lineItembillValidations, //Falta, como lo obtenemos?
    error: lineItembillValidations.length > 0 ? ErrorType.RULE : ErrorType.NONE, //Falta, parece que no se utiliza
    message: item.details,
    materialType: po.materialType,
    orderType: po.type,
    websiteType: po.vendorCompany?.websiteType,
    quantity: entry?.receivedQuantity || item.quantity,
    postingDate: item.postingDate || null, //Falta, vista detallada
    rejectedBy: item.rejectedBy || null,
    withholdingAmount: item.withholdingAmount || null, //Falta, como tendriamos que calcularlo?
    retentionPercentage: item.billingRetPercentage || null, //Falta, parece que no se utiliza
    taxId: item.bill?.jsonCfdi?.Receptor?.Rfc || '',
    hasEntriesAndBills: item.entries.some((e) => e.bills.length > 0),
    bills: item.entries.some((e) => e.bills.length > 0) ? item.entries.map((e) => e.bills) : [],
    itemAmount:
        item.quantity * item.productDetails[0].price +
        Number(
            item.taxes.reduce((acc, tax) => {
                return acc + Number(tax.value);
            }, 0)
        ),
    itemAmountNotTaxes: item.quantity * item.productDetails[0].price,
    vendorCompany: po.vendorCompany.name,
    vendorId: po.vendorId,
});

const getNewStatus = (item) => {
    return item.frontStatus;
};

const getStatusByEntry = (entry, status, type) => {
    const entryIsPaid = entry?.bills?.some((bill) =>
        bill.validations.some((val) => val.validationStatus === 'APPROVED')
    );

    const hasEntryNationalBillsReceipt = entry?.bills?.some((bill) => bill.jsonNational !== null);

    if (hasEntryNationalBillsReceipt) {
        return OrderStatusEnum.INVOICE_IN_PROCESS;
    }
    if (!entry) {
        return status;
    }
    if (entry.bills.length > 0 && entryIsPaid) {
        return status;
    }
    if (entry.bills.length > 0 && type === 'FOREIGN') {
        return status;
    }
    if (entry.bills.length > 0 && !entryIsPaid) {
        return 'DELIVERED';
    }
    return 'DELIVERED';
};

const formatOrders = (orders) =>
    orders.flatMap((po) =>
        po.lineItems.flatMap((item) => {
            const lineItembillValidations = item.bill?.validations[0]?.validationData || [];
            const isBillValidated = false;

            const newStatus = getNewStatus(item);
            return item.entries.length === 0
                ? [
                      createFormattedObject(po, item, {
                          lineItembillValidations,
                          isBillValidated,
                          newStatus,
                      }),
                  ]
                : item.entries.map((entry) =>
                      createFormattedObject(po, item, {
                          lineItembillValidations,
                          isBillValidated,
                          entry,
                          newStatus,
                      })
                  );
        })
    );

const formatOrdersDetailed = (orders) =>
    orders.flatMap((po) =>
        po.lineItems.flatMap((item) =>
            item.entries.length === 0
                ? [createFormattedObject(po, item)]
                : item.entries.map((entry) => createFormattedObject(po, item, {entry}))
        )
    );

export {ErrorType, formatOrders, formatOrdersDetailed, getNetImport};
