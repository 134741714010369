import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {FeatureCodeEnum, GuestEnum} from '@/enums/permissions.enum';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {
    fetchGetExpenseAccounts,
    fetchGetOrdersAgents,
} from '@components/orderSimply/redux/actions/OrdersSimplyActions';
import {AppDispatch, RootState} from '@config/store';
import {Box, Grid, IconButton, Typography, useTheme} from '@mui/material';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ACLObj} from '@components/auth/guard/AclGuard';
import {DateTime} from 'luxon';

import {detailExpendAccount} from '@components/orderSimply/redux/types/OrderAgentsTypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

const DetailExpendAccount: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {customsAgentCode, customRequest} = useParams();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);

    const expenseAccountList = useSelector(
        (state: RootState) => state.orderssimply.expenseAccountList
    );
    const currentPage = useSelector((state: RootState) => state.orderssimply.pageExpenseAccount);
    const limit = useSelector((state: RootState) => state.orderssimply.limitExpenseAccount);

    const totalPages = useSelector(
        (state: RootState) => state.orderssimply.totalPagesExpenseAccount
    );
    const loading = useSelector((state: RootState) => state.orderssimply.loadingExpenseAccount);

    const handleGoBack = () => {
        navigate('/poagents'); // Esta función navegará a la página anterior en el historial
    };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetOrdersAgents(token, value, limit));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(
            fetchGetExpenseAccounts(customsAgentCode, customRequest, token, 1, event.target.value)
        );
    };

    useEffect(() => {
        if (token && customsAgentCode) {
            dispatch(
                fetchGetExpenseAccounts(customsAgentCode, customRequest, token, currentPage, limit)
            );
        }
    }, [dispatch, token]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    <IconButton onClick={handleGoBack} aria-label="Go back">
                        <ArrowBackIcon />
                    </IconButton>
                    {t('logbook_and_details.details')}:
                </Typography>
            </Box>

            <TableContainerBinnance container>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={0.5}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.cve_import')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.description')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.mx_import')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.id_iva')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.iva')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.factor_retention')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.retention')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.brute')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.num_supplier')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.name_supplier')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.8}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.num_invoice')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.8}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.date_invoice')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.9}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('poagents.table.headers.message')}
                        </TableCellHead>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    expenseAccountList &&
                    expenseAccountList.length > 0 &&
                    expenseAccountList.map((item: detailExpendAccount) => (
                        <>
                            <Grid item xs={0.5} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.importKeys}</TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.descriptions}</TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.amounts}</TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.taxIds}</TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.taxesIva}</TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    {item.retentionFactor}
                                </TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.retentions}</TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.grossAmounts}</TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    {item.supplierNumbers}
                                </TableCell>
                            </Grid>
                            <Grid item xs={1} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.supplierName}</TableCell>
                            </Grid>
                            <Grid item xs={0.8} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.invoiceNumber}</TableCell>
                            </Grid>
                            <Grid item xs={0.8} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    {item.invoiceDate
                                        ? DateTime.fromISO(item.invoiceDate).toFormat('dd/MM/yyyy')
                                        : '---'}
                                </TableCell>
                            </Grid>
                            <Grid item xs={0.9} display="grid">
                                <TableCell sx={{fontSize: '9.5px'}}>{item.message}</TableCell>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};
DetailExpendAccount.acl = {
    action: GuestEnum.SEE_LIST,
    subject: FeatureCodeEnum.CUSTOMS_AGENTS,
};
export default DetailExpendAccount;
