import {styled} from '@mui/material/styles';
import {Grid, Paper} from '@mui/material';
export const TableContainer = styled(Grid)(({width = 'calc(100vw - 96px)'}) => ({
    width: width,
}));
export const TableContainerBinnance = styled(Grid)(({width = 'calc(100vw - 96px)'}) => ({
    width: width,
    maxHeight: '90vh',
    overflowY: 'auto',
    transition: 'width 0.3s ease',
}));
export const TableContainerUrl = styled(Grid)(({width = 'calc(100vw - 96px)'}) => ({
    width: width,
    maxHeight: '95vh',
    overflowY: 'auto',
    transition: 'width 0.3s ease',
}));
export const TableContainerBinnanceDetails = styled(Grid)(({width = 'calc(100vw - 96px)'}) => ({
    width: width,
    maxHeight: '70vh',
    overflowY: 'auto',
    transition: 'width 0.3s ease',
}));
export const TableContainerDetailOrder = styled(Grid)(({width = 'calc(100vw - 96px)'}) => ({
    width: '100%',
    overflowX: 'auto',
    transition: 'width 0.3s ease',
    maxWidth: '100%',
}));
export const TableCell = styled(Paper)(({theme}) => ({
    display: 'flex', // Establecer el contenedor como flexbox
    alignItems: 'center',
    paddingLeft: '10px',
    borderRadius: '0',
    borderBottom: '1px solid #C2D1D9',
    boxShadow: 'none',
    minHeight: '50px',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    color: '#515151',
    overflowWrap: 'anywhere',
}));
export const TableCellDetail = styled(Paper)(({theme}) => ({
    display: 'flex', // Establecer el contenedor como flexbox
    alignItems: 'center',
    paddingLeft: '10px',
    borderRadius: '0',
    borderBottom: '1px solid #C2D1D9',
    boxShadow: 'none',
    minHeight: '50px',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    color: '#515151',
    overflowWrap: 'anywhere',
    minWidth: '6%',
    backgroundColor: 'transparent',
}));
export const TableCellHead = styled(Paper)(({fontSize = '12px', theme}) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    borderRadius: '0',
    border: 'none',
    fontSize: fontSize, // Usar el valor proporcionado o 12px por defecto
    fontWeight: 600, // Usar el valor proporcionado o 600 por defecto
    fontFamily: theme.typography.fontFamily,
    boxShadow: 'none',
    borderTop: '1px solid #C2D1D9',
    borderBottom: '1px solid #C2D1D9',
    background: '#EBF0F2',
    height: '45px',
    overflowWrap: 'anywhere',
}));
export const TableCellHeadDetail = styled(Paper)(({fontSize = '12px', theme}) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    borderRadius: '0',
    border: 'none',
    fontSize: fontSize, // Usar el valor proporcionado o 12px por defecto
    fontWeight: 600, // Usar el valor proporcionado o 600 por defecto
    fontFamily: theme.typography.fontFamily,
    boxShadow: 'none',
    borderTop: '1px solid #C2D1D9',
    borderBottom: '1px solid #C2D1D9',
    background: '#EBF0F2',
    height: '45px',
    minWidth: '6%',
}));
export const TableHeadRow = styled(Grid)`
    position: sticky;
    top: 0;
    background-color: #ffffff; /* Ajusta el color de fondo según tu diseño */
    z-index: 1; /* Asegura que la fila de encabezado esté por encima del contenido al desplazarse */
    flex-wrap: nowrap;
`;
export const TableRow = styled(Grid)`
    flex-wrap: nowrap;
`;
export const TableCellHeadInitial = styled(Paper)(({theme}) => ({
    display: 'flex', // Establecer el contenedor como flexbox
    alignItems: 'center',
    paddingLeft: '10px',
    borderRadius: '0',
    border: 'none',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    boxShadow: 'none',
    borderTop: '1px solid #C2D1D9',
    borderLeft: '1px solid #C2D1D9',
    borderBottom: '1px solid #C2D1D9',
    background: '#EBF0F2',
    height: '45px',
}));
export const TableCellHeadFinal = styled(Paper)(({theme}) => ({
    display: 'flex', // Establecer el contenedor como flexbox
    alignItems: 'center', // Centrar verticalmente el contenido
    borderRadius: '0',
    paddingLeft: '10px',
    border: 'none',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    boxShadow: 'none',
    borderTop: '1px solid #C2D1D9',
    borderRight: '1px solid #C2D1D9',
    borderBottom: '1px solid #C2D1D9',
    background: '#EBF0F2',
    height: '45px',
    overflowWrap: 'anywhere',
}));
export const TableCellIcons = styled(Paper)(({theme}) => ({
    display: 'flex', // Establecer el contenedor como flexbox
    alignItems: 'center',
    paddingLeft: '10px',
    borderRadius: '0',
    borderBottom: '1px solid #C2D1D9',
    borderRight: '1px solid #C2D1D9',
    boxShadow: 'none',
    minHeight: '50px',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
}));
