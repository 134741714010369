import StringUtils from '@/utils/StringUtils';
import {
    DOWNLOAD_BILLING_STATEMENT,
    DOWNLOAD_BILLING_STATEMENT_ERROR,
    DOWNLOAD_BILLING_STATEMENT_SUCCESS,
    GET_SOCIETIES,
    GET_SOCIETIES_ERROR,
    GET_SOCIETIES_SUCCESS,
    GET_SOCIETY_DETAILS,
    GET_SOCIETY_DETAILS_ERROR,
    GET_SOCIETY_DETAILS_SUCCESS,
} from '@components/billingStatement/redux/types/BillingStatementTypes';
import axios from 'axios';
export const getSocieties = (result) => {
    return {
        type: GET_SOCIETIES,
        value: result,
    };
};

export const getSocietiesSuccess = (result) => {
    return {
        type: GET_SOCIETIES_SUCCESS,
        value: {
            ...result,
            data: result.data.map((item) => ({
                ...item,
                uxId: generateUUIDa(),
            })),
        },
    };
};

export const getSocietiesError = (error) => {
    return {
        type: GET_SOCIETIES_ERROR,
        value: error,
    };
};

export const getSocietyDetails = (result) => {
    return {
        type: GET_SOCIETY_DETAILS,
        value: result,
    };
};

export const getSocietyDetailsSuccess = (result) => {
    return {
        type: GET_SOCIETY_DETAILS_SUCCESS,
        value: result,
    };
};

export const getSocietyDetailsError = (error) => {
    return {
        type: GET_SOCIETY_DETAILS_ERROR,
        value: error,
    };
};

export const downloadBillingStatement = () => {
    return {
        type: DOWNLOAD_BILLING_STATEMENT,
    };
};
export const downloadBillingStatementSuccess = () => {
    return {
        type: DOWNLOAD_BILLING_STATEMENT_SUCCESS,
    };
};

export const downloadBillingStatementError = (error) => {
    return {
        type: DOWNLOAD_BILLING_STATEMENT_ERROR,
        value: error,
    };
};

export function fetchGetSocieties(token, page, limit, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let id = filters.id ? filters.id : undefined;
        const params = {
            societyId: id ?? '',
            societyName: filters.societyName ?? '',
            initDate: filters.dateInit,
            endDate: filters.dateEnd,
            page,
            limit,
        };
        dispatch(getSocieties({page, limit, filters}));

        const queryString = StringUtils.jsonToQueryString(params);
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/account-statement/buyers-sellers${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getSocietiesSuccess(data));
        } catch (error) {
            dispatch(getSocietiesError(true));
        }
    };
}

export function fetchSocietyDetails({token, page, limit, filters = {}}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        const params = {
            societyId: filters.society,
            supplierId: filters.supplierId,
            initDate: filters.dateInit,
            endDate: filters.dateEnd,
            page,
            limit,
        };
        dispatch(getSocietyDetails({page, limit, filters}));
        const queryString = StringUtils.jsonToQueryString(params);
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/account-statement/details${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getSocietyDetailsSuccess(data));
        } catch (error) {
            dispatch(getSocietyDetailsError(error));
        }
    };
}

export function fetchBillingStatement({token, format, filters = {}}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                societyId: filters.society,
                supplierId: filters.supplierId,
                initDate: filters.initDate,
                endDate: filters.endDate,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(downloadBillingStatement());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/account-statement/details/download/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `estado-de-cuenta-${filters.society}-${filters.supplierId}.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            dispatch(downloadBillingStatementSuccess());
        } catch (error) {
            dispatch(downloadBillingStatementError(error));
        }
    };
}

const generateUUIDa = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};
