import IconCloseModal from '@components/UI/atoms/icons/IconCloseModal.svg';

import HistoryRequest from '@components/tickets/components/HistoryRequest';
import {ImgIconClose, Nav, StyledModal} from '@components/tickets/StyledTicket';
import {Box, Typography, useTheme} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface ModalTicketProps {
    isModalOpen: boolean;
    closeModal: () => void;
    method: string;
    message: string;
    listDelete: number[];
    item: any;
}

const ModalHistoryTicket: FC<ModalTicketProps> = ({isModalOpen, closeModal}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#EBF0F2',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: 'auto',
                    maxWidth: '740px',

                    zIndex: '100',
                }}
            >
                <Nav sx={{justifyContent: 'space-between'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            paddingLeft: '15px',
                        }}
                    >
                        {t('modal_history_ticket.request_comments')}
                    </Typography>
                    <ImgIconClose
                        src={IconCloseModal}
                        onClick={closeModal}
                        sx={{paddingRight: '15px'}}
                    />
                </Nav>
                <HistoryRequest closeModal={closeModal} />
            </Box>
        </StyledModal>
    );
};

export default ModalHistoryTicket;
