import React from 'react';
import {Box} from '@mui/system';
import {TypographyStyled500} from '@/components/faqs/StyledFAQ';
import IconEstatusProblem from '@components/UI/atoms/icons/IconEstatusProblem.svg';

type ErrorInfo = {
    name: string;
    description: string;
    showInList: boolean;
};

type ValidationErrorProps = {
    errorInfo: ErrorInfo;
};

const ValidationError: React.FC<ValidationErrorProps> = ({errorInfo}) => {
    const {description, name, showInList} = errorInfo;
    return showInList ? (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                minHeight: '20px',
                padding: '10px',
            }}
        >
            <TypographyStyled500 sx={{marginLeft: '10px', minWidth: '180px'}}>
                {name}
            </TypographyStyled500>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '80px',
                }}
            >
                <img alt={description} src={IconEstatusProblem} style={{marginRight: '5px'}} />
            </Box>
            <TypographyStyled500 sx={{marginLeft: '10px'}}>{description}</TypographyStyled500>
        </Box>
    ) : null;
};

export default ValidationError;
