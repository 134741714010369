export const options = [
    {value: 'Wrong price', label: 'Precio incorrecto'},
    {value: 'Damaged Product', label: 'Producto dañado'},
    {value: 'Wrong Product', label: 'Producto erróneo'},
    {value: 'Wrong Delivery', label: 'Entrega errónea'},
    {value: 'Damaged Unit', label: 'Unidad dañada'},
    {value: 'Missing Product', label: 'Producto faltante'},
    {value: 'Shrunk Product', label: 'Producto mermado'},
    {value: 'Extra Product', label: 'Producto de más'},
    {value: 'Wrong Agency', label: 'Agencia errónea'},
    {value: 'Expired Product', label: 'Producto caducado'},
    {value: 'Product Without Lot', label: 'Producto sin lote'},
    {value: 'Changed Product', label: 'Producto cambiado'},
];
