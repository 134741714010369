import React from 'react';
import PropTypes from 'prop-types';
import {TextButton} from '@components/UI/molecules/textButton/textButtons';

const getButtonStyles = (disabled, disabledColour, enabledColour) => {
    return {
        color: disabled ? disabledColour : enabledColour,
        border: `1px solid ${disabled ? disabledColour : enabledColour}`,
        '&:hover': {
            cursor: disabled ? 'not-allowed' : 'pointer',
        },
        width: 'auto',
    };
};

const ActionTextButton = ({
    disabledColour = '#00000042',
    enabledColour = '#00ACFF',
    children,
    ...props
}) => {
    const {disabled, sx} = props;
    const customSx = {
        ...sx,
        ...getButtonStyles(disabled, disabledColour, enabledColour),
        padding: '0px 10px',
    };

    return (
        <TextButton sx={customSx} {...props}>
            {children}
        </TextButton>
    );
};

ActionTextButton.propTypes = {
    enabledColour: PropTypes.string,
    disabledColour: PropTypes.string,
    sx: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.node, // Add children prop type
};

ActionTextButton.defaultProps = {
    disabledColour: '#00000042',
    enabledColour: '#00ACFF',
};

export default ActionTextButton;
