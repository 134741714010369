import {
    GET_CANDIDATE,
    GET_CANDIDATE_SUCCESS,
    GET_CANDIDATE_ERROR,
    SET_CANDIDATE_STATUS,
    SET_CANDIDATE_STATUS_SUCCESS,
    SET_CANDIDATE_STATUS_ERROR,
    GET_DOWNLOAD_CANDIDATE,
    GET_DOWNLOAD_CANDIDATE_SUCCESS,
    GET_DOWNLOAD_CANDIDATE_ERROR,
    DELETE_CANDIDATE,
    DELETE_CANDIDATE_SUCCESS,
    DELETE_CANDIDATE_ERROR,
} from '@components/candidates/redux/types/CandidateTypes';

import axios from 'axios';

export const getCandidate = () => {
    return {
        type: GET_CANDIDATE,
    };
};

export const getCandidateSuccess = (result) => {
    return {
        type: GET_CANDIDATE_SUCCESS,
        value: result.data,
    };
};

export const getCandidateError = () => {
    return {
        type: GET_CANDIDATE_ERROR,
    };
};

export function fetchGetCandidate(token, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = filters;
        dispatch(getCandidate());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/candidates/suppliers`,
                {
                    headers: headers,
                    params: params,
                }
            );
            dispatch(getCandidateSuccess(response));
        } catch (error) {
            dispatch(getCandidateError());
        }
    };
}

export const setCandidateStatus = () => {
    return {
        type: SET_CANDIDATE_STATUS,
    };
};

export const setCandidateStatusSuccess = (result) => {
    return {
        type: SET_CANDIDATE_STATUS_SUCCESS,
        value: result.data,
    };
};

export const setCandidateStatusError = () => {
    return {
        type: SET_CANDIDATE_STATUS_ERROR,
    };
};

export function fetchPutStatusCandidate(token, candidate, action) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(setCandidateStatus());
        try {
            const data = {
                status: action,
            };
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/${candidate.id}/supplier`,
                data,
                {headers}
            );
            dispatch(setCandidateStatusSuccess(response.data));
            dispatch(deleteCandidateSuccess(true));
        } catch (error) {
            dispatch(setCandidateStatusError());
        }
    };
}

export const getCandidateDownload = () => {
    return {
        type: GET_DOWNLOAD_CANDIDATE,
    };
};

export const getCandidateDownloadSuccess = () => {
    return {
        type: GET_DOWNLOAD_CANDIDATE_SUCCESS,
    };
};

export const getCandidateDownloadError = () => {
    return {
        type: GET_DOWNLOAD_CANDIDATE_ERROR,
    };
};

export function fetchGetDownloadCandidates(token, format, filters, status) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = filters;
        dispatch(getCandidateDownload());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/download/${format}/${status}`,
                {
                    headers: headers,
                    params: params,
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `candidatos.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            dispatch(getCandidateDownloadSuccess());
        } catch (error) {
            dispatch(getCandidateDownloadError());
        }
    };
}

export const deleteCandidate = () => {
    return {
        type: DELETE_CANDIDATE,
    };
};
export const deleteCandidateSuccess = (result) => {
    return {
        type: DELETE_CANDIDATE_SUCCESS,
        value: result,
    };
};
export const deleteCandidateError = (error, result) => {
    return {
        type: DELETE_CANDIDATE_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchDeleteCandidate(token, candidateId) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(deleteCandidate());
        try {
            await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/${candidateId}/supplier`,
                {headers}
            );
            dispatch(deleteCandidateSuccess(true));
        } catch (error) {
            dispatch(deleteCandidateError(true));
        }
    };
}
