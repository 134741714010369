import Profile from '@account/components/profile';
import Security from '@account/components/security';
import {Box, Tab, Tabs} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

const Account = () => {
    const {t} = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    const hash = location.hash ? location.hash.substring(1) : '';

    const handleChange = (event: any, newValue: any) => {
        setTabIndex(newValue);
    };
    useEffect(() => {
        setTabIndex(hash ? 1 : 0);
    }, [location]);
    return (
        <Box sx={{width: '100%'}}>
            <Tabs value={tabIndex} onChange={handleChange}>
                <Tab label={t('account.tabs.account')} />
                <Tab label={t('account.tabs.perfil')} />
            </Tabs>
            {tabIndex === 0 && <Security />}
            {tabIndex === 1 && <Profile />}
        </Box>
    );
};

export default Account;
