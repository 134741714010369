import {Box, Button, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ConfirmPurchaseOrderPartialTableCore from '@/components/purchase-order/confirmPartial/ConfirmPOPartialTableCore';
import ConfirmPurchaseOrderPartialTableMarket from '@/components/purchase-order/confirmPartial/ConfirmPOPartialTableMarket';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {ILineDetail} from '@/components/purchase-order/confirmTotal/interface';
import {
    fetchPutPurchaseOrder,
    clearPurchaseOrderActive,
    fetchGetPurchaseOrder,
} from '@/components/purchase-order/redux/actions/PurchaceOrderActions';
import ConfirmPOPartialModal from '@/components/purchase-order/confirmPartial/ConfirmPartialModal';
import {
    IItemSplit,
    IErrorSplit,
    IItemSplitCore,
    IItemSplitMarket,
} from '@/components/purchase-order/confirmPartial/interface';
import {useTranslation} from 'react-i18next';
const PurchaseOrderConfirmPartial = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const {orderId, group} = useParams();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const navigate = useNavigate();
    const lineItems: ILineDetail[] = useSelector(
        (state: RootState) => state.purchaceOrderReducers.lineItems
    );
    const purchaseOrderUpdate = useSelector(
        (state: RootState) => state.purchaceOrderReducers.purchaseOrderUpdate
    );
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [lines, setLines] = useState<{[key: string]: IItemSplit}>({});
    const [rejects, setRejects] = useState<{[key: string]: string}>({});
    const [splits, setSplit] = useState<{[key: string]: IItemSplitCore[]}>({});
    const [splitsMarket, setSplitMarket] = useState<{[key: string]: IItemSplitMarket[]}>({});
    const [errorsSplits, setErrorsSplits] = useState<IErrorSplit[]>([]);
    const [errorsLines, setErrorsLines] = useState<IErrorSplit[]>([]);

    useEffect(() => {
        if (lineItems && lineItems.length > 0) {
            for (const line of lineItems) {
                if (line.rejectedBy) {
                    setRejects((prevRejects) => ({
                        ...prevRejects,
                        [line.id]: line.rejectedBy,
                    }));
                }
                setLines((prevLines) => ({
                    ...prevLines,
                    [line.id]: {
                        quantitySupplied: line.quantity,
                        deliveryDate: new Date(line.deliveryDate),
                        buildDate: new Date(line.deliveryDate),
                        unitPrice: line.productDetails[0].price,
                        batch: line.batches && line.batches.length > 0 ? line.batches[0].batch : '',
                        expirationDate:
                            line.batches && line.batches.length > 0
                                ? new Date(line.batches[0].dateExpire)
                                : new Date(line.deliveryDate),
                    },
                }));
            }
        }
    }, [lineItems]);

    const checkAmountInSplits = () => {
        setErrorsSplits([]);
        setErrorsLines([]);
        const custonErros: IErrorSplit[] = [];
        const custonLineErros: IErrorSplit[] = [];
        for (const line of lineItems) {
            if (lines[line.id] && lines[line.id].quantitySupplied > line.quantity) {
                custonLineErros.push({
                    id: line.id,
                    message: t('confirmposimply.quantity_equal_less_requested'),
                });
            }
            if (
                lines[line.id] &&
                lines[line.id].batch === '' &&
                group !== 'CORE' &&
                !rejects[line.id] &&
                !splitsMarket[line.id]
            ) {
                custonLineErros.push({
                    id: line.id,
                    message: 'El número de lote es requerido',
                });
            }
            if (splits[line.id] && splits[line.id].length > 0) {
                const totalSplitAmout = splits[line.id].reduce((carrie, item: IItemSplitCore) => {
                    return item.quantitySupplied + carrie;
                }, 0);
                if (totalSplitAmout !== line.quantity && !rejects[line.id]) {
                    custonErros.push({
                        id: line.id,
                        message: t('confirmposimply.rejection_reason'),
                    });
                }
            }
        }
        setErrorsSplits(custonErros);
        setErrorsLines(custonLineErros);
        if (custonLineErros.length > 0) return;
        if (custonErros.length > 0) return;
        setShowConfirmModal(true);
    };

    const confirmPOPartial = () => {
        const mapslineItems = [];
        for (const line of lineItems) {
            mapslineItems.push({
                id: line.id,
                pos: line.position.toString(),
                quantity: Number(line.quantity),
                quantitySupplied: Number(lines[line.id].quantitySupplied),
                deliveryDate: lines[line.id].deliveryDate,
                buildDate: lines[line.id].buildDate,
                rejected: !!rejects[line.id],
                rejectedBy: rejects[line.id] || null,
                split: splits[line.id] || splitsMarket[line.id] || null,
                batch: lines[line.id].batch,
                expirationDate: lines[line.id].expirationDate,
                unitPrice: Number(lines[line.id].unitPrice),
                concept: '',
                observation: '',
            });
            // if (rejects[line.id] || splits[line.id]) {
            //     mapslineItems.push({
            //         id: line.id,
            //         pos: line.position.toString(),
            //         quantity: Number(line.quantity),
            //         quantitySupplied: Number(lines[line.id].quantitySupplied),
            //         deliveryDate: lines[line.id].deliveryDate,
            //         rejected: !!rejects[line.id],
            //         rejectedBy:  rejects[line.id] || null,
            //         split: splits[line.id] || null,
            //         batch: lines[line.id].batch,
            //               expirationDate: lines[line.id].expirationDate,
            //               unitPrice: Number(lines[line.id].unitPrice),
            //         concept: '',
            //         observation: '',
            //     });
            //     continue;
            // }
            // const linealData =
            //     group === 'CORE'
            //         ? {
            //               id: line.id,
            //               pos: line.position.toString(),
            //               quantity: Number(line.quantity),
            //               quantitySupplied: Number(lines[line.id].quantitySupplied),
            //               deliveryDate: lines[line.id].deliveryDate,
            //               rejected: false,
            //               concept: '',
            //               observation: '',
            //           }
            //         : {
            //               id: line.id,
            //               pos: line.position.toString(),
            //               quantity: Number(line.quantity),
            //               quantitySupplied: Number(lines[line.id].quantitySupplied),
            //               deliveryDate: lines[line.id].deliveryDate,
            //               rejected: false,
            //               batch: lines[line.id].batch,
            //               expirationDate: lines[line.id].expirationDate,
            //               unitPrice: Number(lines[line.id].unitPrice),
            //               concept: '',
            //               observation: '',
            //           };
            //mapslineItems.push(linealData);
        }
        const data = {
            type: group === 'CORE' ? 'CONFIRMATION_PARTIAL' : 'CONFIRMATION_PARTIAL_MARKET_PLACE',
            lineItems: mapslineItems,
        };
        dispatch(fetchPutPurchaseOrder(token, data, orderId));
    };

    const closeModal = () => {
        setShowConfirmModal(false);
    };

    const gotToBack = () => {
        if (token && orderId) {
            dispatch(fetchGetPurchaseOrder(token, orderId));
        }
        dispatch(clearPurchaseOrderActive());
        navigate(-1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '16px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('confirmposimply.partial_delivery_confirmation')}
                </Typography>
                {purchaseOrderUpdate && !showConfirmModal && (
                    <span
                        style={{
                            marginLeft: '10px',
                            backgroundColor: '#4BBC68',
                            padding: '4px 4px',
                            borderRadius: '4px',
                            color: 'white',
                        }}
                    >
                        {t('accepted').toUpperCase()}
                    </span>
                )}
            </Box>
            {group === 'CORE' ? (
                <ConfirmPurchaseOrderPartialTableCore
                    internalId={orderId}
                    rejects={rejects}
                    setRejects={setRejects}
                    splits={splits}
                    setSplit={setSplit}
                    lines={lines}
                    setLines={setLines}
                    errorsSplits={errorsSplits}
                    errorsLines={errorsLines}
                    setErrorsLines={setErrorsLines}
                    setErrorsSplit={setErrorsSplits}
                />
            ) : (
                <ConfirmPurchaseOrderPartialTableMarket
                    splits={splitsMarket}
                    setSplit={setSplitMarket}
                    internalId={orderId}
                    rejects={rejects}
                    setRejects={setRejects}
                    lines={lines}
                    setLines={setLines}
                    errorsLines={errorsLines}
                    setErrorsLines={setErrorsLines}
                    errorsSplits={errorsSplits}
                    setErrorsSplit={setErrorsSplits}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    marginTop: '20px',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="outlined"
                    onClick={gotToBack}
                    sx={{
                        marginRight: '10px',
                    }}
                    color="info"
                >
                    {t('back')}
                </Button>
                <Button
                    onClick={() => checkAmountInSplits()}
                    disabled={
                        errorsSplits.length > 0 ||
                        errorsLines.length > 0 ||
                        lineItems.length === 0 ||
                        purchaseOrderUpdate
                    }
                    color="primary"
                    variant="outlined"
                >
                    {t('confirm')}
                </Button>
            </Box>
            <ConfirmPOPartialModal
                isModalOpen={showConfirmModal}
                closeModal={closeModal}
                updatedOrderFn={confirmPOPartial}
            />
        </Box>
    );
};

export default PurchaseOrderConfirmPartial;
