import {
    GET_FISCALREQUIREMENTS,
    GET_FISCALREQUIREMENTS_SUCCESS,
    GET_FISCALREQUIREMENTS_ERROR,
    POST_FISCALREQUIREMENTS,
    POST_FISCALREQUIREMENTS_SUCCESS,
    POST_FISCALREQUIREMENTS_ERROR,
    UPDATE_FISCALREQUIREMENTS,
    UPDATE_FISCALREQUIREMENTS_SUCCESS,
    UPDATE_FISCALREQUIREMENTS_ERROR,
} from '@/components/taxRequirements/redux/types/TaxRequirementsTypes';

import axios from 'axios';

export const getTaxRequirements = () => {
    return {
        type: GET_FISCALREQUIREMENTS,
    };
};

export const getTaxRequirementsSuccess = (result) => {
    return {
        type: GET_FISCALREQUIREMENTS_SUCCESS,
        value: result,
    };
};

export const getTaxRequirementsError = (error, result) => {
    return {
        type: GET_FISCALREQUIREMENTS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetTaxRequirements(token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getTaxRequirements());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/fiscal-requirements`,
                {headers}
            );
            await dispatch(getTaxRequirementsSuccess(response.data.data));
            await dispatch(getTaxRequirementsError(false));
        } catch (error) {
            dispatch(getTaxRequirementsError(true));
        }
    };
}

export const postTaxRequirements = () => {
    return {
        type: POST_FISCALREQUIREMENTS,
    };
};

export const postTaxRequirementsSuccess = (result) => {
    return {
        type: POST_FISCALREQUIREMENTS_SUCCESS,
        value: result,
    };
};

export const postTaxRequirementsError = (error, result) => {
    return {
        type: POST_FISCALREQUIREMENTS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostTaxRequirements(token, text) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postTaxRequirements());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/fiscal-requirements`,
                {text: text},
                {headers}
            );
            await dispatch(postTaxRequirementsSuccess(response.data.data));
            await dispatch(fetchGetTaxRequirements(token));
            await dispatch(postTaxRequirementsError(false));
        } catch (error) {
            dispatch(postTaxRequirementsError(true));
        }
    };
}

export const putTaxRequirements = () => {
    return {
        type: UPDATE_FISCALREQUIREMENTS,
    };
};

export const putTaxRequirementsSuccess = (result) => {
    return {
        type: UPDATE_FISCALREQUIREMENTS_SUCCESS,
        value: result,
    };
};

export const putTaxRequirementsError = (error, result) => {
    return {
        type: UPDATE_FISCALREQUIREMENTS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutTaxRequirements(token, id, text) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putTaxRequirements());
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/fiscal-requirements/${id}`,
                {text: text},
                {headers}
            );
            await dispatch(putTaxRequirementsSuccess(response.data.data));
            await dispatch(fetchGetTaxRequirements(token));
            await dispatch(putTaxRequirementsError(false));
        } catch (error) {
            dispatch(putTaxRequirementsError(true));
        }
    };
}
