/* eslint-disable no-unused-vars */
'use client';

import {Action, getActionKey} from '@/components/tickets/enums';
import {FormValues} from '@/components/tickets/interfaces';
import {
    InputLabelRol,
    StyledFormControlSelect,
    StyledSelect,
} from '@/components/tickets/StyledTicket';

import {FormHelperText, ListItemText, MenuItem, OutlinedInput} from '@mui/material';
import React, {useState} from 'react';
import {Control, Controller, FieldErrors, UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {defaultProcessString} from '@components/tickets';
import {performActionValues} from '@components/tickets/enums';

export type SelecteActionsProps = {
    setValue: UseFormSetValue<FormValues>;
    errors: FieldErrors<FormValues>;
    control: Control<FormValues, any>;
};

export const performActionString = (inputString: string, t: any) => {
    const data = defaultProcessString(inputString, performActionValues);
    return t(`ticket.perform_action.${data.firstRecord}`) + data.remainingRecordsMessage;
};

const SelecteActions: React.FC<SelecteActionsProps> = ({setValue, errors, control}) => {
    const [inputAction, setInputAction] = useState<string>('');
    const {t} = useTranslation();
    const handleChangeAction = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterAction(value);
    };
    const cleanFilterAction = (value: any) => {
        const selectedAction = Object.values(Action).find((e: string) => e === value);
        setInputAction(selectedAction ?? '');

        const performAction = performActionValues[getActionKey(selectedAction as string)];
        setValue('actions', performAction ?? '');
    };

    return (
        <Controller
            render={({field, fieldState}) => (
                <StyledFormControlSelect
                    style={{margin: '10px'}}
                    sx={{
                        marginBottom: errors?.actions?.message ? '15px' : '',
                    }}
                >
                    <InputLabelRol>{t('ticket.action_to_do')}</InputLabelRol>
                    <StyledSelect
                        {...field}
                        value={inputAction}
                        onChange={handleChangeAction}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected: any) =>
                            t(
                                `ticket.perform_action.${
                                    performActionValues[getActionKey(selected)]
                                }`
                            )
                        }
                        MenuProps={{
                            sx: {
                                '&& .Mui-selected': {
                                    backgroundColor: '#FFFF',
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            },
                        }}
                        fullWidth
                    >
                        {Object.values(Action).map((item: string, index: number) => (
                            <MenuItem key={index} value={item}>
                                <ListItemText
                                    primary={t(
                                        `ticket.perform_action.${
                                            performActionValues[getActionKey(item)]
                                        }`
                                    )}
                                />
                            </MenuItem>
                        ))}
                    </StyledSelect>
                    {fieldState.error ? (
                        <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                    ) : null}
                </StyledFormControlSelect>
            )}
            control={control}
            name="actions"
        />
    );
};

export default SelecteActions;
