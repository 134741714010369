import React, {useEffect, useState} from 'react';
import logoAbi from '@order/images/logoAbi.svg';
import logoOrderDelivered from '@order/images/logoOrderDelivered.svg';

import {Typography, Box, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

function esDispositivoMovil() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}
const OrderDeliveredGenesis = () => {
    const order = useSelector((state: RootState) => state.orderGenesis.order);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(esDispositivoMovil());
    }, []);
    const sortOrderByEntrySheet = order.lineItems.sort(
        (a: any, b: any) => Number(b.entrySheet) - Number(a.entrySheet)
    );
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                background: '#F1F1F1',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    height: isMobile ? '100px' : '61px',
                    background: '#000',
                    boxSizing: 'border-box',
                    paddingLeft: isMobile ? '60px' : '0px',
                    alignItems: 'center',
                    justifyContent: isMobile ? 'flex-start' : 'center',
                }}
            >
                <img src={logoAbi} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 70px)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: '#fff',
                        padding: isMobile ? '60px 60px 52px 60px' : '30px 20%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '45px' : '22px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#011B34',
                            }}
                        >
                            Orden de compra
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '45px' : '22px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#D1A33C',
                                marginLeft: '15px',
                            }}
                        >
                            #{order.referenceId}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '22.4px',
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            color: '#011B34',
                        }}
                    >
                        En este apartado encontrarás los detalles de tu orden, así cómo su estatus.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '22.4px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            color: '#011B34',
                            borderTop: '1px solid #000000',
                            marginTop: '20px',
                            paddingTop: '15px',
                        }}
                    >
                        Detalles de proveedor
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                color: '#011B34',
                                marginTop: '5px',
                            }}
                        >
                            Nro. Proveedor:
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                color: '#011B34',
                                maxWidth: '500px',
                                overflow: 'hidden',
                                marginLeft: '10px',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {order.vendor.externalId}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                color: '#011B34',
                                marginTop: '5px',
                            }}
                        >
                            Razón social:
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: isMobile ? 500 : 400,
                                color: '#011B34',
                                marginLeft: '10px',
                            }}
                        >
                            {order.buyer.name}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                color: '#011B34',
                                marginRight: '20px',
                            }}
                        >
                            No. de Factura
                        </Typography>

                        <TextField
                            color="success"
                            id={`billNumber`}
                            label=""
                            value={order.billNumber}
                            sx={{
                                width: '70%',
                                '& input': {
                                    fontSize: isMobile ? '31px' : '22px',
                                },
                            }}
                            placeholder="00000000"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: isMobile ? '60px' : '10px 20%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '38px' : '25px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            color: '#011B34',
                            alignSelf: 'flex-start',
                        }}
                    >
                        Resumen de orden
                    </Typography>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '23px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                color: '#011B34',
                                marginTop: isMobile ? '20px' : '0px',
                            }}
                            component="div"
                            variant="body1"
                        >
                            Se ha generado la hoja de entrada {sortOrderByEntrySheet[0].entrySheet}{' '}
                            relacionada a la orden de compra{' '}
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '33.6px' : '23px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    color: '#011B34',
                                    display: 'inline', // Esto asegura que el texto esté en la misma línea
                                }}
                                component="span"
                                variant="body1"
                            >
                                {order.referenceId}
                            </Typography>{' '}
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '33.6px' : '23px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    color: '#011B34',
                                    display: 'inline', // Esto asegura que el texto esté en la misma línea
                                }}
                                component="span"
                                variant="body1"
                            >
                                con {order.lineItems.length} posiciones.
                            </Typography>
                        </Typography>
                    </Box>
                    <img src={logoOrderDelivered} width={isMobile ? '600px' : '350px'} />
                </Box>
            </Box>
        </Box>
    );
};

export default OrderDeliveredGenesis;
