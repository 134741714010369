import {FeatureCodeEnum, LegalAgreementsEnum} from '@/enums/permissions.enum';
import {TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import {ACLObj} from '@components/auth/guard/AclGuard';
import {StyledTextField, TableCellPrincipal} from '@components/faqs/StyledFAQ';
import {StyledBoxData} from '@components/legalAgreements/StyledLegalAgreements';
import ViewPDF from '@components/legalAgreements/ViewPDF';
import {RootState} from '@config/store';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

const LegalAgreementsAdmin: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const legalAgreements = useSelector(
        (state: RootState) => state.legalAgreements.legalAgreements
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '27px 23px',
                overflow: 'auto',
                width: 'auto',
            }}
        >
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '24px',
                    fontWeight: 400,
                }}
            >
                {t('legalagreementsAdmin.title')}
            </Typography>
            {legalAgreements.description !== '' && (
                <TableContainer container sx={{margin: '20px', width: '95%'}}>
                    <Grid item xs={12}>
                        <TableCellPrincipal
                            sx={{
                                borderLeft: '1px solid #C2D1D9',
                                borderRight: '1px solid #C2D1D9',
                            }}
                        >
                            {t('legalagreementsAdmin.legal_agreement')}
                        </TableCellPrincipal>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledBoxData>
                            <StyledTextField
                                id="outlined-required"
                                value={legalAgreements.title}
                                sx={{width: '500px'}}
                                disabled={true}
                            />
                            <StyledTextField
                                value={legalAgreements.description}
                                disabled={true}
                                sx={{marginTop: '20px', overflowWrap: 'anywhere'}}
                                multiline
                                rows={10}
                            />
                        </StyledBoxData>
                    </Grid>
                </TableContainer>
            )}
            <ViewPDF />
        </Box>
    );
};
LegalAgreementsAdmin.acl = {
    action: LegalAgreementsEnum.VISUALIZE,
    subject: FeatureCodeEnum.LEGAL_AGREEMENTS,
};

export default LegalAgreementsAdmin;
