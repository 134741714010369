import React from 'react';
import {Box} from '@mui/material';

const Mdweb: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '20px 23px',
            }}
        >
            <iframe src="https://www.orimi.com/pdf-test.pdf" height="90%" />
        </Box>
    );
};

export default Mdweb;
