import {
    GET_ROLES_SUCCESS_OLD,
    GET_ROLES_ERROR_OLD,
    GET_ROLES_OLD,
} from '@/components/general/roles/redux/types/RolesTypes';

const initialState = {
    roles: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
};

const roles = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLES_OLD:
            return {
                ...state,
                loading: true,
            };
        case GET_ROLES_SUCCESS_OLD:
            return {
                ...state,
                roles: action.value.filter((item) => item.status === true),
                loading: false,
            };
        case GET_ROLES_ERROR_OLD:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default roles;
