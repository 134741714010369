export const POST_BANNERS = 'POST_BANNERS';
export const POST_BANNERS_SUCCESS = 'POST_BANNERS_SUCCESS';
export const POST_BANNERS_ERROR = 'POST_BANNERS_ERROR';
export const GET_BANNERS = 'GET_USER_INFO';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_ERROR = 'GET_BANNERS_ERROR';
export const GET_BANNERS_FILE = 'GET_BANNERS_FILE';
export const GET_BANNERS_FILE_SUCCESS = 'GET_BANNERS_FILE_SUCCESS';
export const GET_BANNERS_FILE_ERROR = 'GET_BANNERS_FILE_ERROR';
export const UPDATE_BANNERS = 'UPDATE_BANNERS';
export const UPDATE_BANNERS_SUCCESS = 'UPDATE_BANNERS_SUCCESS';
export const UPDATE_BANNERS_ERROR = 'UPDATE_BANNERS_ERROR';
export const DELETE_BANNERS = 'DELETE_BANNERS';
export const DELETE_BANNERS_SUCCESS = 'DELETE_BANNERS_SUCCESS';
export const DELETE_BANNERS_ERROR = 'DELETE_BANNERS_ERROR';
export const CREATE_FILE_BANNERS = 'CREATE_FILE_BANNERS';
export const CREATE_FILE_BANNERS_SUCCESS = 'CREATE_FILE_BANNERS_SUCCESS';
export const CREATE_FILE_BANNERS_ERROR = 'CREATE_FILE_BANNERS_ERROR';
export const GET_BANNERS_BYID = 'GET_BANNERS_BYID';
export const GET_BANNERS_BYID_SUCCESS = 'GET_BANNERS_BYID_SUCCESS';
export const GET_BANNERS_BYID_ERROR = 'GET_BANNERS_BYID_ERROR';
export const OPEN_MODAL_PREVIEW = 'OPEN_MODAL_PREVIEW';
export const CLOSE_MODAL_PREVIEW = 'CLOSE_MODAL_PREVIEW';
