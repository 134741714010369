import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCellHead,
    TableContainer,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {fetchGetEntrySheet} from '@/components/orderSimply/redux/actions/OrdersSimplyActions';
import {ButtonCancel, Nav} from '@components/faqs/StyledFAQ';
import {AppDispatch, RootState} from '@config/store';
import {Box, Grid, Modal, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

interface IDetailProps {
    isModalOpen: boolean;
    closeModal: any;
    item: any;
}

const styleCellData = {
    padding: '10px 0 10px 10px',
    fontSize: '9.5px',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
};

const rowStyle = {borderBottom: '1px solid #C2D1D9', width: '100%'};

const DetailModal = (props: IDetailProps) => {
    const {t} = useTranslation();
    const {isModalOpen, closeModal, item} = props;
    const theme = useTheme();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const entrysheets = useSelector((state: RootState) => state.orderssimply.entrysheets);
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (item && token) {
            dispatch(fetchGetEntrySheet(token, item.entry_sheet_number));
        }
    }, [item]);

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {item && (
                            <span>{`${t('confirmposimply.entry_sheet_modal.entry_sheet')} #${
                                item.entry_sheet_number
                            }`}</span>
                        )}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            color: 'black',
                            margin: '10px 0 10px 0',
                        }}
                    >
                        {item && (
                            <span>{`${t('confirmposimply.entry_sheet_modal.order_detail')} #${
                                item.external_id
                            }`}</span>
                        )}
                    </Typography>

                    <TableContainer container>
                        <TableHeadRow container>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.position')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.material')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.description')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.delivery_date')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.unit')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.currency')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.quantity')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.unit_price')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.discount')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.iva')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.ieps')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={0.5}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.amount')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.part_no_manufacturer')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.mat_no_supplier')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.reason_for_rejection')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHead sx={{fontSize: '9.5px'}}>
                                    {t('confirmposimply.entry_sheet_modal.comments')}
                                </TableCellHead>
                            </Grid>
                        </TableHeadRow>
                        {loading ? (
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            <div style={{height: '350px', width: '100%', overflow: 'auto'}}>
                                {entrysheets &&
                                    entrysheets.length > 0 &&
                                    entrysheets.map((item: any, index: number) => {
                                        return (
                                            <Grid container key={index} sx={rowStyle}>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.position}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {item.material}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {item.description}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {DateTime.fromISO(
                                                        new Date(item.deliveryDate).toISOString()
                                                    ).toFormat('dd/MM/yyyy')}
                                                </Grid>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.unit}
                                                </Grid>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.currency}
                                                </Grid>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.quantity}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {item.unitPrice}
                                                </Grid>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.discount}
                                                </Grid>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.iva}
                                                </Grid>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.ieps}
                                                </Grid>
                                                <Grid item xs={0.5} sx={styleCellData}>
                                                    {item.amount
                                                        ? Number(item.amount).toLocaleString(
                                                              'en-US',
                                                              {
                                                                  style: 'currency',
                                                                  currency: 'USD',
                                                              }
                                                          )
                                                        : '-'}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {item.manufacturerPartNumber}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {item.supplierMaterialNumber}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {item.rejectedBy ? item.rejectedBy : ''}
                                                </Grid>
                                                <Grid item xs={1} sx={styleCellData}>
                                                    {item.comments ? item.comments : ''}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                            </div>
                        )}
                    </TableContainer>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}>{t('close').toUpperCase()}</ButtonCancel>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default DetailModal;
