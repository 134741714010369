export const parseBoolean = (value: any): boolean => {
    if (value === undefined || value === null) {
        return false;
    }

    if (typeof value === 'boolean') {
        return value;
    }

    if (typeof value === 'string') {
        const normalized = value.trim().toLowerCase();
        if (['true', '1'].includes(normalized)) return true;
        if (['false', '0'].includes(normalized)) return false;
    }

    if (typeof value === 'number') {
        return value === 1;
    }

    return false;
};
