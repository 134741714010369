import {fetchGetInfoPO} from '@/components/home/redux/actions/HomeActions';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
    TableRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {TextButtonDetails} from '@/components/UI/molecules/textButton/textButtons';
import {AppDispatch, RootState} from '@/config/store';
import {ConfirmDeliveryScheduleInvoiceEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {Box, Grid, Link, Typography, useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
export type PurchaseOrderProps = {
    // types...
};

const PurchaseOrder: React.FC<PurchaseOrderProps> = ({}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const loadingPO = useSelector((state: RootState) => state.home.loadingPO);
    const orders = useSelector((state: RootState) => state.home.orders);
    const {allowed} = useCheckAbilities();

    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const redirect = (id: any, type: any) => {
        navigate(`/confirmpoaction/${id}/${type}`);
    };
    const init = async () => {
        if (token) {
            const data = {
                limit: 4,
            };
            dispatch(fetchGetInfoPO(token, data));
        }
    };
    useEffect(() => {
        init();
    }, [token]);

    return (
        <>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 300,
                }}
            >
                {t('home.purchase_orders')}
            </Typography>
            <TableContainerBinnance xs={12} container>
                <TableHeadRow container xs={12}>
                    <Grid item xs={3}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>No. PO</TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.filters.society_name')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('home.emit_date')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHeadFinal sx={{fontSize: '9.5px'}}>
                            {' '}
                            {t('actions')}
                        </TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {allowed(
                    FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                    ConfirmDeliveryScheduleInvoiceEnum.VIEW_LIST
                ) ? (
                    loadingPO ? (
                        <>
                            <SkeletonRow columns={4} width={180} />
                            <SkeletonRow columns={4} width={180} />
                            <SkeletonRow columns={4} width={180} />
                        </>
                    ) : (
                        orders.map((item: any, index: any) => {
                            return (
                                <TableRow xs={12} container key={`row-order-${index}`}>
                                    <Grid item xs={3}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            <Link
                                                onClick={redirect.bind(
                                                    this,
                                                    item.purchase_order_id,
                                                    item.company_group
                                                )}
                                                underline="hover"
                                                sx={{
                                                    color: '#00ACFF',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {item.external_id}
                                            </Link>
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            {item.society_name}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            {item.created_at
                                                ? DateTime.fromISO(item.created_at).toFormat(
                                                      'dd/MM/yyyy'
                                                  )
                                                : '---'}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            <TextButtonDetails
                                                type="button"
                                                onClick={redirect.bind(
                                                    this,
                                                    item.purchase_order_id,
                                                    item.company_group
                                                )}
                                            >
                                                {t('view_details')}
                                            </TextButtonDetails>
                                        </TableCell>
                                    </Grid>
                                </TableRow>
                            );
                        })
                    )
                ) : (
                    <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Typography
                            component={'h1'}
                            sx={{
                                fontSize: '16px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                textAlign: 'center',
                            }}
                        >
                            {t('not_allowed_authorization')}
                        </Typography>
                    </Box>
                )}
            </TableContainerBinnance>
        </>
    );
};

export default PurchaseOrder;
