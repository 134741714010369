import {
    SET_XML,
    SET_PDF,
    SET_ERROR_FILES,
    SET_ACTIVE_STEP,
    OPEN_DIALOG,
    CLOSE_DIALOG,
    SET_FETCH_BILLS,
    SET_FETCH_BILLS_SUCCESS,
    SET_FETCH_BILLS_ERROR,
    CLEAR_BILLS_ERROR,
    SET_BILLS_ERROR_FLAG,
    CLEAR_BILLS_ERROR_FLAG,
    VALIDATE_COMPLEMENT,
    VALIDATE_COMPLEMENT_SUCCESS,
    VALIDATE_COMPLEMENT_ERROR,
    UPLOAD_FILES,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_ERROR,
    UPLOAD_PDF_2WM,
    UPLOAD_PDF_2WM_ERROR,
    UPLOAD_PDF_2WM_SUCCESS,
} from '@components/orderSimply/redux/types/LoadComplementTypes';

const initialState = {
    openDialog: false,
    activeStep: 0,
    loadingValidations: false,
    errorValidations: [],
    xmlFile: null,
    pdfFile: null,
    errorFiles: false,
    bills: [],
    loadingBills: false,
    errorBills: '',
    errorBillsFlag: false,
    uploadFilesClicked: false,
    uploadingFiles: false,
    uploadFilesStatus: '',
    uploadPDF2WM: false,
    update2WM: false,
    response2WM: {},
};

const complement = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.value,
            };
        case SET_XML:
            return {
                ...state,
                xmlFile: action.value,
            };
        case SET_PDF:
            return {
                ...state,
                pdfFile: action.value,
            };
        case SET_ERROR_FILES:
            return {
                ...state,
                errorFiles: action.value,
            };
        case OPEN_DIALOG:
            return {
                ...state,
                openDialog: true,
            };
        case CLOSE_DIALOG:
            return {
                ...initialState,
            };
        case SET_FETCH_BILLS:
            return {
                ...state,
                loadingBills: true,
                bills: [],
                errorBills: '',
                errorBillsFlag: false,
            };
        case SET_FETCH_BILLS_SUCCESS:
            return {
                ...state,
                loadingBills: false,
                bills: action.value.bills,
                errorBills: '',
                errorBillsFlag: false,
                errorFiles: false,
            };
        case CLEAR_BILLS_ERROR:
            return {
                ...state,
                loadingBills: false,
                bills: [],
                errorBills: '',
                errorBillsFlag: false,
            };
        case SET_FETCH_BILLS_ERROR:
            return {
                ...state,
                loadingBills: false,
                errorFiles: true,
                errorBills: action.value,
            };
        case SET_BILLS_ERROR_FLAG:
            return {
                ...state,
                errorBillsFlag: action.value,
            };
        case CLEAR_BILLS_ERROR_FLAG:
            return {
                ...state,
                errorBillsFlag: false,
            };
        case VALIDATE_COMPLEMENT:
            return {
                ...state,
                loadingValidations: true,
                errorValidations: [],
            };
        case VALIDATE_COMPLEMENT_SUCCESS:
            return {
                ...state,
                loadingValidations: false,
            };
        case VALIDATE_COMPLEMENT_ERROR:
            return {
                ...state,
                loadingValidations: false,
                errorValidations: action.value.errors,
            };
        case UPLOAD_FILES:
            return {
                ...state,
                uploadingFiles: true,
                uploadFilesClicked: true,
                uploadFilesStatus: '',
            };
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                uploadingFiles: false,
                uploadFilesStatus: 'success',
            };
        case UPLOAD_FILES_ERROR:
            return {
                ...state,
                uploadingFiles: false,
                uploadFilesStatus: action.value,
            };
        case UPLOAD_PDF_2WM:
            return {
                ...state,
                uploadPDF2WM: true,
                update2WM: false,
                errorValidations: [],
            };
        case UPLOAD_PDF_2WM_SUCCESS:
            return {
                ...state,
                uploadPDF2WM: false,
                update2WM: true,
                response2WM: action.value,
                errorValidations: [],
            };
        case UPLOAD_PDF_2WM_ERROR:
            return {
                ...state,
                uploadPDF2WM: false,
                update2WM: false,
                errorValidations: action.value,
            };
        default:
            return state;
    }
};

export default complement;
