import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {ACLObj} from '@/components/auth/guard/AclGuard';
import {Can} from '@/context/AbilityContext';
import {CheckStatusOrdersEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import StatusText from '@components/UI/atoms/buttons/StatusText';
import {modeloramaStatusTextValuesReal} from '@components/UI/atoms/buttons/constant/status-text';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {IFormDate} from '@components/orderSimply/interface';
import {UploadComplement} from '@components/orderSimply/modals/UploadComplement';
import {openDialog} from '@components/orderSimply/redux/actions/LoadComplementActions';
import {fetchGetDownloadOrdersModelo} from '@components/orderSimply/redux/actions/OrderSimplyDownloadActions';
import {
    fetchGetOrdersSimply,
    postOrderFilters,
} from '@components/orderSimply/redux/actions/OrdersSimplyActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    DescriptionOutlined,
    InsertDriveFileOutlined,
    Menu as MenuIcon,
    PaidOutlined,
    Search,
} from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    OutlinedInput,
    Select,
    styled,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
const InputLabelRol = styled(InputLabel)({
    '&.Mui-focused': {
        color: '#011B34',
    },
    color: '#011B34',
    marginTop: '-5px',
});

export const StyledSelect = styled(Select)({
    height: '40px',
    minWidth: '100px',
    width: 'auto',
    marginLeft: '10px',
});
export const processString = (inputString: string, t: any) => {
    const recordsKeys = inputString.split(',');
    const records = recordsKeys.map((key: string) => {
        return modeloramaStatusTextValuesReal[key];
    });

    const firstRecord = records[0];
    const remainingRecordsCount = records.length - 1;
    let remainingRecordsMessage = '';
    if (remainingRecordsCount > 0) {
        remainingRecordsMessage = `(+${remainingRecordsCount})`;
    }
    return t(`modelorama_real.table.status.${firstRecord}`) + remainingRecordsMessage;
};
const OrdersSimply: React.FC & {acl?: ACLObj} = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const [checkAllStatus, setCheckAllStatus] = useState(false);
    const openCompDialog = useSelector((state: RootState) => state.complement.openDialog);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const websiteType = useSelector((state: RootState) => state.logins.user.company.websiteType);
    const userType = useSelector((state: RootState) => state.logins.user.type);
    const orderssimply = useSelector((state: RootState) => state.orderssimply.orderssimply);
    const currentPage = useSelector((state: RootState) => state.orderssimply.page);
    const limit = useSelector((state: RootState) => state.orderssimply.limit);
    const filters = useSelector((state: RootState) => state.orderssimply.filters);
    const totalPages = useSelector((state: RootState) => state.orderssimply.totalPages);
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const [selectedStates, setSelectedStates] = useState<string[]>(
        Object.keys(modeloramaStatusTextValuesReal)
    );
    const {notAllowed} = useCheckAbilities();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetOrdersSimply(token, value, limit, filters, selectedStates));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetOrdersSimply(token, 1, event.target.value, filters, selectedStates));
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const schemaFormModal = yup.object({
        dateIni: yup.date().required('Fecha inicio requerido'),
        dateEnd: yup.date().required('Fecha final requerido'),
        status: yup.string(),
        year: yup.string(),
        contractBusinessNumber: yup.number().optional(),
        referenceId: yup.string().optional(),
    });

    const {handleSubmit, control, watch, setValue} = useForm<IFormDate>({
        resolver: yupResolver(schemaFormModal),
    });
    const watchForm = watch();
    const parseDate = (date: Date | string | DateTime): DateTime => {
        let dateTime: DateTime = DateTime.now().startOf('month');
        if (date && date instanceof DateTime) dateTime = date;
        if (date && date instanceof Date) dateTime = DateTime.fromJSDate(date);
        if (date && typeof date === 'string') dateTime = DateTime.fromISO(date);

        return dateTime;
    };
    const onSubmit = () => {
        const json = {
            dateIni: parseDate(watchForm.dateIni).toFormat('yyyy-MM-dd'),
            dateEnd: parseDate(watchForm.dateEnd).toFormat('yyyy-MM-dd'),
            status: watchForm.status ?? selectedStates.join(','),
            year: watchForm.year,
            modelorama: true,
            contractBusinessNumber: watchForm.contractBusinessNumber,
            referenceId: watchForm.purchaseOrder ?? '',
        };
        dispatch(postOrderFilters(json));
        dispatch(fetchGetOrdersSimply(token, 1, limit, json, selectedStates));
    };
    const handleChangeStatus = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterStatus(value);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const cleanFilterStatus = (value: any) => {
        const selectedStatus =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));
        setSelectedStates(selectedStatus);
        let newArray = [];
        if (selectedStatus.length > 0) {
            newArray = selectedStatus.map((item: string) => {
                return Object.keys(modeloramaStatusTextValuesReal).find((e: string) => e === item);
            });
        }
        setValue('status', newArray.length > 0 ? newArray.join(',') : '');
    };
    const handleChangeDownload = (format: any) => {
        const json = {
            dateIni: parseDate(watchForm.dateIni).toFormat('yyyy-MM-dd'),
            dateEnd: parseDate(watchForm.dateEnd).toFormat('yyyy-MM-dd'),
            status: watchForm.status ?? selectedStates.join(','),
            year: watchForm.year,
            modelorama: true,
            contractBusinessNumber: watchForm.contractBusinessNumber,
            referenceId: watchForm.purchaseOrder ?? '',
        };
        dispatch(fetchGetDownloadOrdersModelo(token, format, json));
    };
    const hanldeCheckAllStatus = () => {
        setCheckAllStatus((prev) => {
            cleanFilterStatus(!prev ? Object.keys(modeloramaStatusTextValuesReal) : []);
            return !prev;
        });
    };
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (token) {
            const status = watchForm.status ?? selectedStates.join(',');
            const json = {
                dateIni: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
                dateEnd: DateTime.now().toFormat('yyyy-MM-dd'),
                status,
                year: watchForm.year ?? DateTime.now().year,
                modelorama: true,
                contractBusinessNumber: watchForm.contractBusinessNumber ?? '',
                referenceId: watchForm.purchaseOrder ?? '',
            };
            setSelectedStates(status.split(','));
            setCheckAllStatus(true);
            dispatch(postOrderFilters(json));
            dispatch(fetchGetOrdersSimply(token, currentPage, limit, json, selectedStates));
        }
    }, [dispatch, token]);

    const setOpenCompDialog = () => {
        dispatch(openDialog());
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('consultpo.title')}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '20px',
                        padding: '15px',
                        height: '73px',
                        background: '#fff',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateIni"
                                control={control}
                                defaultValue={DateTime.now().startOf('month').toJSDate()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.start_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        maxDate={watchForm.dateEnd}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEnd"
                                control={control}
                                defaultValue={new Date()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.end_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        minDate={watchForm.dateIni}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Controller
                            name="year"
                            control={control}
                            defaultValue={2024}
                            render={({field, fieldState}) => (
                                <FormControl sx={{marginLeft: '10px', height: '40px'}}>
                                    <InputLabel>{t('components.year_selector.title')}</InputLabel>
                                    <Select
                                        label="Motivo de Rechazo"
                                        {...field}
                                        error={!!fieldState.error}
                                        sx={{width: '100px', height: '40px'}}
                                    >
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2023}>2023</MenuItem>
                                        <MenuItem value={2022}>2022</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="contractBusinessNumber"
                            control={control}
                            render={({field: {onChange, value = ''}}) => {
                                return (
                                    <TextField
                                        label={t('consultpo.filters.contract_business_number')}
                                        onChange={onChange}
                                        size="small"
                                        value={value}
                                        sx={{marginLeft: '10px', width: '160px', height: '40px'}}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="purchaseOrder"
                            control={control}
                            render={({field: {onChange, value = ''}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('consultpo.table.headers.purchase_order')}
                                        size="small"
                                        sx={{marginLeft: '10px', width: '160px', height: '40px'}}
                                    />
                                );
                            }}
                        />
                        <Can
                            I={CheckStatusOrdersEnum.FILTER_STATE}
                            a={FeatureCodeEnum.CHECK_STATUS_ORDERS}
                        >
                            <Controller
                                render={({field, fieldState}) => (
                                    <FormControl>
                                        <InputLabelRol>
                                            {t('components.status_selector.title')}
                                        </InputLabelRol>
                                        <StyledSelect
                                            {...field}
                                            multiple
                                            value={selectedStates}
                                            onChange={handleChangeStatus}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected: any) =>
                                                processString(selected.join(','), t)
                                            }
                                            MenuProps={{
                                                sx: {
                                                    '&& .Mui-selected': {
                                                        backgroundColor: '#FFFF',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                },
                                            }}
                                            disabled={notAllowed(
                                                FeatureCodeEnum.CHECK_STATUS_ORDERS,
                                                CheckStatusOrdersEnum.FILTER_STATE
                                            )}
                                        >
                                            <MenuItem
                                                value={'notCheck'}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        hanldeCheckAllStatus();
                                                    }, 500);
                                                }}
                                            >
                                                <Checkbox
                                                    checked={checkAllStatus}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: theme.palette.primary.main,
                                                        },
                                                    }}
                                                />
                                                <ListItemText
                                                    primary={t(`consultpo.filters.select_all`)}
                                                />
                                            </MenuItem>
                                            {Object.keys(modeloramaStatusTextValuesReal)
                                                .filter((name) => {
                                                    if (userType === 'SUPPLIER') {
                                                        return websiteType !== 'FOREIGN'
                                                            ? name !== 'INVOICE_IN_VALIDATION'
                                                            : name !== 'BILL_VALIDATED';
                                                    }
                                                    return true;
                                                })
                                                .map((key: string, index: number) => (
                                                    <MenuItem key={index} value={key}>
                                                        <Checkbox
                                                            checked={
                                                                selectedStates.indexOf(key) > -1
                                                            }
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: theme.palette.primary
                                                                        .main,
                                                                },
                                                            }}
                                                        />
                                                        <ListItemText
                                                            primary={t(
                                                                `modelorama_real.table.status.${modeloramaStatusTextValuesReal[key]}`
                                                            )}
                                                        />
                                                    </MenuItem>
                                                ))}
                                        </StyledSelect>
                                    </FormControl>
                                )}
                                control={control}
                                name="status"
                            />
                        </Can>
                        <Tooltip title={t('components.filter_btn.title')} placement="bottom" arrow>
                            <Button
                                onClick={onSubmit}
                                type="submit"
                                aria-label="submit"
                                color="secondary"
                                variant="outlined"
                                sx={{
                                    minWidth: '40px',
                                    height: '36px',
                                    padding: '5px 5px',
                                    marginLeft: '20px',
                                }}
                            >
                                <Search />
                            </Button>
                        </Tooltip>
                    </Box>
                    <Tooltip title={t('options')} placement="bottom" arrow>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleOpenMenu}
                            id="menu-button"
                            aria-controls={openMenu ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            sx={{
                                minWidth: '40px',
                                height: '36px',
                                padding: '5px 5px',
                                marginLeft: '10px',
                            }}
                        >
                            <MenuIcon />
                        </Button>
                    </Tooltip>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuList>
                            <MenuItem
                                onClick={() => {
                                    setOpenCompDialog();
                                    handleCloseMenu();
                                }}
                            >
                                <ListItemIcon>
                                    <PaidOutlined fontSize="medium" />
                                </ListItemIcon>
                                <ListItemText>
                                    {t('load')} {t('complement')}
                                </ListItemText>
                            </MenuItem>

                            <Can
                                I={CheckStatusOrdersEnum.EXPORT_EXCEL_CSV}
                                a={FeatureCodeEnum.CHECK_STATUS_ORDERS}
                            >
                                {' '}
                                <MenuItem
                                    onClick={() => {
                                        handleChangeDownload('csv');
                                        handleCloseMenu();
                                    }}
                                    disabled={notAllowed(
                                        FeatureCodeEnum.CHECK_STATUS_ORDERS,
                                        CheckStatusOrdersEnum.EXPORT_EXCEL_CSV
                                    )}
                                >
                                    <ListItemIcon>
                                        <DescriptionOutlined fontSize="medium" />
                                    </ListItemIcon>
                                    <ListItemText>{t('download')} CSV</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleChangeDownload('xlsx');
                                        handleCloseMenu();
                                    }}
                                    disabled={notAllowed(
                                        FeatureCodeEnum.CHECK_STATUS_ORDERS,
                                        CheckStatusOrdersEnum.EXPORT_EXCEL_CSV
                                    )}
                                >
                                    <ListItemIcon>
                                        <InsertDriveFileOutlined fontSize="medium" />
                                    </ListItemIcon>
                                    <ListItemText>{t('download')} XLSX</ListItemText>
                                </MenuItem>
                            </Can>
                        </MenuList>
                    </Menu>
                </Box>
            </Box>
            <TableContainerBinnance container>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={1}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.purchase_order')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.company_name')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.6}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.scheduled_month_to_pay')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.6}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.scheduled_year_to_pay')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.pos')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.8}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.framework_contract_amount')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.filters.contract_business_number')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.6}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.invoice_reference')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.posting_date')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.invoice_amount')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('money')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('state')}</TableCellHead>
                    </Grid>
                    <Grid item xs={0.7}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.pay_day')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1.7}>
                        <TableCellHeadFinal sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.message')}
                        </TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    orderssimply.map((item: any, index: number) => {
                        /*
                         * Modelorama doesn't use the DELIVERED status,
                         * so we decided not to change this logic in the backend.
                         * Instead, we changed the status here to match the desired behavior.
                         * This ensures consistency between the frontend and backend.
                         * // [ ] Tag: MODELORA-CONFIRMED-STATUS
                         */
                        const verifiedStatus =
                            item.status === 'CREATED' ? 'DELIVERED' : item.status;
                        const defaultPlaceholder = '---';
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.orderId}</TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.societyName}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={0.6} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.scheduledPaymentMonth}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={0.6} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.scheduledPaymentYear}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={0.5} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.pos}</TableCell>
                                </Grid>
                                <Grid item xs={0.8} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.frameworkContractAmount}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.contractBusinessNumber}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={0.6} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.billReference}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.postingDate
                                            ? DateTime.fromISO(item.postingDate).toFormat(
                                                  'dd/MM/yyyy'
                                              )
                                            : defaultPlaceholder}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.billAmount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={0.5} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.currency}</TableCell>
                                </Grid>
                                <Grid item xs={1} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        <StatusText status={verifiedStatus} />
                                    </TableCell>
                                </Grid>
                                <Grid item xs={0.7} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.paymentDate
                                            ? DateTime.fromISO(item.paymentDate).toFormat(
                                                  'dd/MM/yyyy'
                                              )
                                            : defaultPlaceholder}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={1.7} display="grid">
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.message ? item.message : defaultPlaceholder}
                                    </TableCell>
                                </Grid>
                            </React.Fragment>
                        );
                    })
                )}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <UploadComplement open={openCompDialog} />
        </Box>
    );
};
OrdersSimply.acl = {
    action: CheckStatusOrdersEnum.VIEW_LIST,
    subject: FeatureCodeEnum.CHECK_STATUS_ORDERS,
};
export default OrdersSimply;
