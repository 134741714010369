import * as React from 'react';

import {Link} from '@mui/material';
import Image, {ImageProps} from '@/components/UI/atoms/images/Image';

interface ImageWithActionProps extends ImageProps {
    action: () => void;
}

function ImageWithAction({imgSrc, alt, width, height, action}: ImageWithActionProps) {
    if (!imgSrc) return null;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        action();
    };

    return (
        <Link component="a" href="" onClick={handleClick}>
            <Image imgSrc={imgSrc} alt={alt} width={width} height={height} />
        </Link>
    );
}

ImageWithAction.defaultProps = {
    action: () => {},
};

export default ImageWithAction;
