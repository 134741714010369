import React from 'react';
const SocialIcons = () => (
    <div style={{padding: '10px 0'}}>
        <img
            src="http://cdn.mcauto-images-production.sendgrid.net/118c29486c8e22ee/516ae1ca-ddac-41ff-ae2b-d6cb88c7a00f/20x20.png"
            alt="Icon1"
        />
        <img
            src="http://cdn.mcauto-images-production.sendgrid.net/118c29486c8e22ee/db7a019b-74fa-4353-88e5-29206669cb01/24x24.png"
            alt="Icon2"
            style={{paddingLeft: '5%'}}
        />
        <img
            src="http://cdn.mcauto-images-production.sendgrid.net/118c29486c8e22ee/cdd936b6-00a1-4802-bdb1-ad4ffeece841/20x20.png"
            alt="Icon3"
            style={{paddingLeft: '5%'}}
        />
        <img
            src="http://cdn.mcauto-images-production.sendgrid.net/118c29486c8e22ee/2854c79f-0440-4157-8ead-65a66a589f0a/20x20.png"
            alt="Icon4"
            style={{paddingLeft: '5%'}}
        />
        <img
            src="http://cdn.mcauto-images-production.sendgrid.net/118c29486c8e22ee/a79e34a8-0030-419d-9f83-aa1ab9f1c4d8/20x20.png"
            alt="Icon5"
            style={{paddingLeft: '5%'}}
        />
    </div>
);
const ContentAccepted: React.FC<{data: any}> = ({data}) => (
    <table
        align="center"
        style={{background: '#fff', marginTop: '20px', borderRadius: '8px', width: '80%'}}
    >
        <tbody>
            <tr>
                <td style={{padding: '30px 25px 80px 35px'}}>
                    <h2 style={{fontFamily: 'Poppins', fontWeight: 700}}>
                        Estimado proveedor {data.vendorName}:
                    </h2>
                    <p style={{fontFamily: 'Poppins', fontWeight: 400}}>
                        Nos complace informarte que has aceptado satisfactoriamente los términos,
                        condiciones y aviso de privacidad anexos a la orden de compra número{' '}
                        <b>{data.orderReferenceId}</b>.
                    </p>
                    <div style={{fontFamily: 'Poppins', fontWeight: 400}}>
                        <h4>Detalles de la aceptación:</h4>
                        <ul>
                            <li>
                                <b>Nombre del usuario:</b> {data.userFullName}
                            </li>
                            <li>
                                <b>Empresa:</b> {data.companyName}
                            </li>
                            <li>
                                <b>Fecha y hora de rechazo:</b> {data.actionDate}
                            </li>
                            <li>
                                <b>Número de Orden de Compra:</b> {data.orderReferenceId}
                            </li>
                        </ul>
                    </div>
                    <p style={{fontFamily: 'Poppins', fontWeight: 400}}>
                        Gracias por tu colaboración en formalizar este acuerdo. A partir de ahora,
                        puedes continuar con el proceso de suministro conforme a las condiciones
                        acordadas.
                    </p>
                    <p style={{fontFamily: 'Poppins', fontWeight: 400}}>
                        Si tienes alguna pregunta o requieres asistencia adicional, no dudes en
                        ponerte en contacto con nuestro equipo de soporte.
                    </p>
                    <p style={{fontFamily: 'Poppins', fontWeight: 400}}>
                        <b>¿Tienes dudas?</b> Si necesitas más información o tienes alguna pregunta,
                        no dudes en ponerte en contacto con nosotros vía correo electrónico a{' '}
                        <u>CSC_proveedores@ab-inbev.com</u>
                    </p>
                    <SocialIcons />
                </td>
            </tr>
        </tbody>
    </table>
);

export default ContentAccepted;
