import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, IconButton} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import {ACLObj} from '@/components/auth/guard/AclGuard';
import {Filters} from '@/components/terms/components/Filters';
import {PurchaseOrdersList} from '@/components/terms/components/PurchaseOrdersList';
import {Title} from '@/components/terms/components/Title';
import {FeatureCodeEnum, TermsConditionsEnum} from '@/enums/permissions.enum';

const TermsAcceptance: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {state} = useLocation();

    const [selectedPOrders, setSelectedPOrders] = useState<string[]>([]);
    const [selectedRefs, setSelectedRefs] = useState<string[]>([]);

    const handleCleanModalData = () => {
        setSelectedPOrders([]);
        setSelectedRefs([]);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Title title={t('terms_acceptance.title')}>
                {state && state.from === 'home' && (
                    <IconButton onClick={handleGoBack} aria-label="Go back">
                        <ArrowBackIcon />
                    </IconButton>
                )}
            </Title>

            <Filters onSuccess={handleCleanModalData} />

            <PurchaseOrdersList
                pos={{selectedPOrders, setSelectedPOrders}}
                refs={{selectedRefs, setSelectedRefs}}
            />
        </Box>
    );
};
TermsAcceptance.acl = {
    action: TermsConditionsEnum.SEE_LIST,
    subject: FeatureCodeEnum.TERMS_CONDITIONS,
};

export default TermsAcceptance;
