import {
    GET_BINNACLES,
    GET_BINNACLES_BYID,
    GET_BINNACLES_BYID_ERROR,
    GET_BINNACLES_BYID_SUCCESS,
    GET_BINNACLES_DETAILS,
    GET_BINNACLES_ERROR,
    GET_BINNACLES_SUCCESS,
    GET_DOWNLOAD_BINNACLES,
    GET_DOWNLOAD_BINNACLES_BYID,
    GET_DOWNLOAD_BINNACLES_BYID_ERROR,
    GET_DOWNLOAD_BINNACLES_BYID_SUCCESS,
    GET_DOWNLOAD_BINNACLES_ERROR,
    GET_DOWNLOAD_BINNACLES_SUCCESS,
} from '@/components/log/redux/types/BinnaclesTypes';
import StringUtils from '@utils/StringUtils';
import axios from 'axios';
export const getBinnaclesDetailsName = (types, name, id) => {
    return (dispatch) => {
        dispatch({
            type: GET_BINNACLES_DETAILS,
            types: types,
            name: name,
        });
        window.location.href = `/binnacles/user/${id}`;
    };
};
export const getBinnacles = () => {
    return {
        type: GET_BINNACLES,
    };
};
export const getBinnaclesSuccess = (result) => {
    return {
        type: GET_BINNACLES_SUCCESS,
        value: result,
    };
};
export const getBinnaclesError = (error) => {
    return {
        type: GET_BINNACLES_ERROR,
        value: error,
    };
};

export function fetchGetBinnacles(token, page, limit, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
                userName: filters.user,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getBinnacles());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/binnacles${queryString}`,
                {headers}
            );
            dispatch(getBinnaclesSuccess(response.data));
        } catch (error) {
            dispatch(getBinnaclesError(true));
        }
    };
}

export const getBinnaclesDownload = () => {
    return {
        type: GET_DOWNLOAD_BINNACLES,
    };
};
export const getBinnaclesDownloadSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_BINNACLES_SUCCESS,
        value: result,
    };
};
export const getBinnaclesDownloadError = (error) => {
    return {
        type: GET_DOWNLOAD_BINNACLES_ERROR,
        value: error,
    };
};

const typeData = {
    csv: 'text/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export function fetchGetBinnaclesDownload(token, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                ...filters,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getBinnaclesDownload());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/binnacles/download/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(
                new Blob([response.data], {type: `${typeData[format]};charset=utf-8`})
            );

            const a = document.createElement('a');
            a.href = url;
            a.download = `binnacles.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getBinnaclesDownloadSuccess());
        } catch (error) {
            dispatch(getBinnaclesDownloadError(true));
        }
    };
}
export const getBinnaclesDownloadById = () => {
    return {
        type: GET_DOWNLOAD_BINNACLES_BYID,
    };
};
export const getBinnaclesDownloadByIdSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_BINNACLES_BYID_SUCCESS,
        value: result,
    };
};
export const getBinnaclesDownloadByIdError = (error) => {
    return {
        type: GET_DOWNLOAD_BINNACLES_BYID_ERROR,
        value: error,
    };
};

export function fetchGetBinnaclesDownloadById(token, userId, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getBinnaclesDownloadById());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/binnacles/download/user/${userId}/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `binnacles.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getBinnaclesDownloadByIdSuccess());
        } catch (error) {
            dispatch(getBinnaclesDownloadByIdError(true));
        }
    };
}

export const getBinnaclesDetails = () => {
    return {
        type: GET_BINNACLES_BYID,
    };
};
export const getBinnaclesDetailsSuccess = (result) => {
    return {
        type: GET_BINNACLES_BYID_SUCCESS,
        value: result,
    };
};
export const getBinnaclesDetailsError = (error) => {
    return {
        type: GET_BINNACLES_BYID_ERROR,
        value: error,
    };
};

export function fetchGetBinnaclesDetails(token, userId, page, limit, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getBinnaclesDetails());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/binnacles/user/${userId}${queryString}`,
                {headers}
            );
            dispatch(getBinnaclesDetailsSuccess(response.data));
        } catch (error) {
            dispatch(getBinnaclesDetailsError(true));
        }
    };
}
