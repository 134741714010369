import {TextError, TextSuccess} from '@/components/UI/molecules/textButton/textButtons';
import {UserStatus} from '@/components/user/interface';
import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {fetchPutStatusUser} from '@components/user/redux/actions/UserActions';
import {AppDispatch, RootState} from '@config/store';
import {Box, Modal, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

interface IRoleModalProps {
    isModalOpen: boolean;
    closeModal: any;
}

const UserStatusModal = (props: IRoleModalProps) => {
    const {t} = useTranslation();
    const {isModalOpen, closeModal} = props;
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.user.loading);
    // const error = useSelector((state: RootState) => state.rolesManager.error);
    const user = useSelector((state: RootState) => state.user.user);

    const updateRoleStatus = () => {
        if (token && user) {
            const dataUser = {
                ...user,
                status:
                    user.status === UserStatus.ACTIVATED
                        ? UserStatus.DISABLED
                        : UserStatus.ACTIVATED,
            };
            dispatch(fetchPutStatusUser(token, dataUser));
        }
        closeModal();
    };

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('roles.modals.user_deactivate.title')}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {' '}
                            {t('roles.modals.user_deactivate.user')}:
                        </span>{' '}
                        {user && user?.profile
                            ? `${user.profile.firstLastName} ${user.profile.secondLastName}`
                            : '-'}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {' '}
                            {t('roles.modals.user_deactivate.status')}:
                        </span>{' '}
                        {user && user.status === 'ACTIVATED' ? (
                            <TextSuccess> {t('roles.modals.user_deactivate.active')}</TextSuccess>
                        ) : (
                            <TextError> {t('roles.modals.user_deactivate.inactive')}</TextError>
                        )}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {t('roles.modals.user_deactivate.confirm_change')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {user && user.status === 'DISABLED' ? (
                            <TextSuccess> {t('roles.modals.user_deactivate.active')}</TextSuccess>
                        ) : (
                            <TextError> {t('roles.modals.user_deactivate.inactive')}</TextError>
                        )}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}>{t('cancel').toUpperCase()}</ButtonCancel>
                    <SaveButton disabled={loading} onClick={updateRoleStatus}>
                        {loading ? <Loader size={24} /> : t('apply').toUpperCase()}
                    </SaveButton>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default UserStatusModal;
