import {
    GET_LEGALAGREEMENTS,
    GET_LEGALAGREEMENTS_SUCCESS,
    GET_LEGALAGREEMENTS_ERROR,
    UPDATE_LEGALAGREEMENTS,
    UPDATE_LEGALAGREEMENTS_SUCCESS,
    UPDATE_LEGALAGREEMENTS_ERROR,
    CREATE_FILE_LEGALAGREEMENTS,
    CREATE_FILE_LEGALAGREEMENTS_SUCCESS,
    CREATE_FILE_LEGALAGREEMENTS_ERROR,
} from '@components/legalAgreements/redux/types/LegalAgreementsTypes';

const initialState = {
    legalAgreements: {},
    loading: false,
    error: false,
};

const legalAgreements = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEGALAGREEMENTS:
            return {
                ...state,
                loading: true,
            };
        case GET_LEGALAGREEMENTS_SUCCESS:
            return {
                ...state,
                legalAgreements: action.value.data,
                loading: false,
            };
        case GET_LEGALAGREEMENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_LEGALAGREEMENTS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_LEGALAGREEMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_LEGALAGREEMENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CREATE_FILE_LEGALAGREEMENTS:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FILE_LEGALAGREEMENTS_SUCCESS:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FILE_LEGALAGREEMENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default legalAgreements;
