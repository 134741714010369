import IconEdit from '@/components/UI/atoms/icons/IconEdit.svg';
import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@/components/UI/molecules/modals/WarningUpdateModal';
import {FeatureCodeEnum, LegalAgreementsEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {getTimeHour} from '@/utils/FormatDate';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import IconPDF from '@components/UI/atoms/icons/IconPDF.svg';
import {TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import {
    ButtonCancel,
    Loader,
    SaveButton,
    StyledTextField,
    TableCellChildren,
    TableCellPrincipal,
} from '@components/faqs/StyledFAQ';
import {
    FileUploadContainer,
    StyledBoxData,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@components/legalAgreements/StyledLegalAgreements';
import ModalConfirmChanges from '@components/legalAgreements/modals/ModalConfirmChanges';
import {
    fetchGetLegalAgreements,
    fetchPutLegalAgreements,
} from '@components/legalAgreements/redux/actions/LegalAgreementsActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, Divider, Grid, IconButton, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';

const MAX_FILE_SIZE_MB = 10;
const ALLOWED_FILE_TYPES = ['application/pdf'];

const LegalAgreements: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();

    interface IAgreement {
        title: string;
        description: string;
        lastUpdatedBy: object;
        updatedAt: Date;
        file: File | null;
    }
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const legalAgreements = useSelector(
        (state: RootState) => state.legalAgreements.legalAgreements
    );
    const loading = useSelector((state: RootState) => state.legalAgreements.loading);
    const error = useSelector((state: RootState) => state.legalAgreements.error);

    const [edit, setEdit] = useState<boolean>(false);
    const [errorFile, setErrorFile] = useState<string>('');
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [responseValidation, setResponseValidation] = useState<boolean>(false);
    const [flag, setFlag] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const {allowed} = useCheckAbilities();

    const schemaFormModal = yup.object({
        title: yup.string().required('Nombre requerido'),
        description: yup.string(),
        file: yup
            .mixed()
            .nullable()
            .when('description', {
                is: (description: string) => description === '',
                then: (schema) => schema.required('Descripción o archivo requerido'),
            }),
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        getValues,
    } = useForm<IAgreement>({resolver: yupResolver(schemaFormModal)});

    const editAgreement = () => {
        setEdit((prev) => !prev);
    };

    const handleFileChange = (event: any, type: string) => {
        event.stopPropagation();
        const file = type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0];
        if (file && !ALLOWED_FILE_TYPES.includes(file.type)) {
            setErrorFile('Archivo inválido. Por favor sube un archivo PDF.');
            return;
        } else if (file && file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(
                `El tamaño del archivo supera los ${MAX_FILE_SIZE_MB} MB. Elija un archivo más pequeño.`
            );
            return;
        } else {
            setErrorFile('');
        }
    };

    const onSubmit = async (data: IAgreement) => {
        if (!modalCancel) {
            const json = {
                title: data.title,
                description: data.description,
                ...(data.file &&
                    data.file.type && {
                        attachment: {
                            name: data.file?.name.split('.')[0],
                            fileName: data.file?.name,
                            mediaType: data.file?.type,
                            extension: data.file?.type.split('/')[1],
                        },
                    }),
            };
            try {
                await dispatch(fetchPutLegalAgreements(token, json, data.file));
                reset({
                    title: legalAgreements.title,
                    description: legalAgreements.description,
                    lastUpdatedBy: legalAgreements.lastUpdatedBy,
                    updatedAt: legalAgreements.updatedAt,
                    file: null,
                });
                setEdit((prev) => !prev);
            } catch (error) {
                setResponseValidation(true);
                setModalOpen(true);
            } finally {
                setFlag((prev) => !prev);
            }
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setResponseValidation(false);
    };

    const handleCancel = () => {
        setModalCancel((prev) => !prev);
        setEdit((prev) => !prev);
        reset({
            title: legalAgreements.title,
            description: legalAgreements.description ? legalAgreements.description : '',
            lastUpdatedBy: legalAgreements.lastUpdatedBy,
            updatedAt: legalAgreements.updatedAt,
            file: legalAgreements.attachment || null,
        });
        setErrorFile('');
    };

    const handleCancelClick = (event: any) => {
        event.stopPropagation();
        const {title, description, file} = getValues();
        const isContentModified =
            legalAgreements.title !== title ||
            legalAgreements.description !== description ||
            (file === null && legalAgreements.urlAttachment) ||
            (file !== null && file.type);
        if (isContentModified) {
            setModalCancel((prev) => !prev);
        } else {
            setEdit((prev) => !prev);
        }
    };

    useEffect(() => {
        if (!loading && flag) {
            if (error) {
                setResponseValidation(true);
                setModalOpen(true);
            } else if (!error) {
                setResponseValidation(false);
                setModalOpen(true);
            }
            setFlag((prev) => !prev);
        }
    }, [error, loading]);

    useEffect(() => {
        if (legalAgreements && Object.keys(legalAgreements).length > 0) {
            reset({
                title: legalAgreements.title,
                description: legalAgreements.description ? legalAgreements.description : '',
                lastUpdatedBy: legalAgreements.lastUpdatedBy,
                updatedAt: legalAgreements.updatedAt,
                file: legalAgreements.attachment || null,
            });
        }
    }, [legalAgreements]);

    useEffect(() => {
        if (token) {
            dispatch(fetchGetLegalAgreements(token));
        }
    }, [token]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '27px 23px',
                overflow: 'auto',
            }}
        >
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '24px',
                    fontWeight: 400,
                }}
            >
                {t('legalagreementsAdmin.title')}
            </Typography>
            <Divider sx={{borderBottomWidth: '1px', background: '#000000', marginTop: '40px'}} />
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '20px',
                    fontWeight: 400,
                    margin: '30px 0px',
                }}
            >
                {t('legalagreementsAdmin.last_modification')}
            </Typography>

            <TableContainer container>
                <Grid item xs={4}>
                    <TableCellPrincipal sx={{borderLeft: '1px solid #C2D1D9'}}>
                        {t('legalagreementsAdmin.author_modification')}
                    </TableCellPrincipal>
                </Grid>
                <Grid item xs={3}>
                    <TableCellPrincipal> {t('date')}</TableCellPrincipal>
                </Grid>
                <Grid item xs={3}>
                    <TableCellPrincipal sx={{borderRight: '1px solid #C2D1D9'}}>
                        {t('hour')}
                    </TableCellPrincipal>
                </Grid>

                <Grid item xs={4}>
                    <TableCellChildren sx={{borderLeft: '1px solid #C2D1D9'}}>
                        {legalAgreements?.lastUpdatedBy?.profile?.firstLastName}
                    </TableCellChildren>
                </Grid>
                <Grid item xs={3}>
                    <TableCellChildren>
                        {DateTime.fromISO(legalAgreements?.updatedAt).toFormat('dd/MM/yyyy')}
                    </TableCellChildren>
                </Grid>
                <Grid item xs={3}>
                    <TableCellChildren sx={{borderRight: '1px solid #C2D1D9'}}>
                        {getTimeHour(legalAgreements?.updatedAt)}
                    </TableCellChildren>
                </Grid>
            </TableContainer>

            <TableContainer container sx={{paddingTop: '30px'}}>
                <Grid item xs={10}>
                    <TableCellPrincipal
                        sx={{
                            borderLeft: '1px solid #C2D1D9',
                            borderRight: '1px solid #C2D1D9',
                        }}
                    >
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            {t('legalagreementsAdmin.legal_agreement')}
                            {allowed(
                                FeatureCodeEnum.LEGAL_AGREEMENTS,
                                LegalAgreementsEnum.CREATE
                            ) ||
                            allowed(FeatureCodeEnum.LEGAL_AGREEMENTS, LegalAgreementsEnum.EDIT) ||
                            allowed(
                                FeatureCodeEnum.LEGAL_AGREEMENTS,
                                LegalAgreementsEnum.DELETE
                            ) ? (
                                <img
                                    onClick={editAgreement}
                                    src={IconEdit}
                                    title={t('manuals.modals.edit')}
                                />
                            ) : null}
                        </Box>
                    </TableCellPrincipal>
                </Grid>

                <Grid item xs={10}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledBoxData>
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledTextField
                                        {...field}
                                        id="outlined-required"
                                        label={
                                            edit && !getValues('title')
                                                ? t('legalagreementsAdmin.title_name')
                                                : ''
                                        }
                                        sx={{width: '500px'}}
                                        disabled={!edit}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error ? fieldState.error.message : ''
                                        }
                                    />
                                )}
                                name="title"
                                control={control}
                            />
                            <Controller
                                render={({field}) => (
                                    <StyledTextField
                                        {...field}
                                        id="outlined-required"
                                        label={
                                            edit && !legalAgreements.description
                                                ? t('description')
                                                : ''
                                        }
                                        multiline
                                        rows={8}
                                        disabled={!edit}
                                        sx={{marginTop: '20px'}}
                                    />
                                )}
                                name="description"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '10px',
                                }}
                            >
                                {errors?.file?.message}
                            </Typography>
                            {edit && (
                                <Box sx={{marginTop: '20px'}}>
                                    <Typography
                                        sx={{
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            paddingBottom: '10px',
                                        }}
                                    >
                                        {t('new_file')}
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between">
                                        <Controller
                                            name="file"
                                            control={control}
                                            defaultValue={null}
                                            render={({field}) => (
                                                <Box>
                                                    <FileUploadContainer
                                                        sx={{width: '614px', height: '124px'}}
                                                        onDragOver={(e) => e.preventDefault()}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            const file = e.dataTransfer.files[0];
                                                            if (file) field.onChange(file);
                                                            handleFileChange(e, 'drop');
                                                        }}
                                                        onClick={() =>
                                                            document
                                                                .getElementById('fileInput')
                                                                ?.click()
                                                        }
                                                    >
                                                        <StyledInput
                                                            id="fileInput"
                                                            type="file"
                                                            onChange={(e) => {
                                                                const file = e.target.files
                                                                    ? e.target.files[0]
                                                                    : null;
                                                                if (file) field.onChange(file);
                                                                handleFileChange(e, 'change');
                                                            }}
                                                        />
                                                        {field.value && !errorFile ? (
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography variant="subtitle2">
                                                                    {t(
                                                                        'legalagreementsAdmin.selected_file'
                                                                    ) + ': '}
                                                                    {field.value.name}
                                                                </Typography>
                                                                <IconButton
                                                                    aria-label="Eliminar"
                                                                    onClick={(e) => {
                                                                        field.onChange(null);
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                <StyledFileLabelButton
                                                                    variant="outlined"
                                                                    as="span"
                                                                >
                                                                    <img src={CloudIcon} />
                                                                    <StyledLabelFileUpload>
                                                                        {t('drag_upload_files')}
                                                                    </StyledLabelFileUpload>
                                                                </StyledFileLabelButton>
                                                                <Typography
                                                                    variant="caption"
                                                                    color="error"
                                                                >
                                                                    {errorFile}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </FileUploadContainer>
                                                </Box>
                                            )}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '60px',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <ButtonCancel onClick={handleCancelClick}>
                                                {t('cancel').toUpperCase()}
                                            </ButtonCancel>
                                            <SaveButton disabled={loading}>
                                                {loading ? (
                                                    <Loader size={24} />
                                                ) : (
                                                    t('save').toUpperCase()
                                                )}
                                            </SaveButton>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            {legalAgreements.attachment && !edit && (
                                <FileUploadContainer
                                    sx={{
                                        width: '614px',
                                        height: '124px',
                                        cursor: 'default',
                                        marginTop: '15px',
                                    }}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <img src={IconPDF} />
                                        <Typography
                                            sx={{
                                                color: 'rgba(0, 0, 0, 0.38)',
                                                fontSize: '16px',
                                                marginLeft: '5px',
                                            }}
                                        >
                                            {`${legalAgreements.attachment?.name}.${legalAgreements.attachment?.extension}`}
                                        </Typography>
                                    </Box>
                                </FileUploadContainer>
                            )}
                        </StyledBoxData>
                    </form>
                </Grid>
            </TableContainer>
            {isModalOpen && (
                <>
                    {responseValidation ? (
                        <WarningUpdateModal isModalOpen={isModalOpen} closeModal={closeModal} />
                    ) : (
                        <SuccessUpdateModal
                            isModalOpen={isModalOpen}
                            closeModal={closeModal}
                            message={t('legalagreementsAdmin.consulta_greements_menu')}
                        />
                    )}
                </>
            )}
            <ModalConfirmChanges
                open={modalCancel}
                closeCancel={handleCancel}
                setModalCancel={setModalCancel}
            />
        </Box>
    );
};

export default LegalAgreements;
