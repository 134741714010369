import StatusText from '@/components/UI/atoms/buttons/StatusText';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {TextButton, TextButtonDetails} from '@/components/UI/molecules/textButton/textButtons';
import CustomPagination from '@/components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@/config/store';
import {FeatureCodeEnum, MerchandiseReceptionEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {ACLObj} from '@components/auth/guard/AclGuard';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {
    fetchGetDownloadOrdersRecept,
    fetchGetOrdersRecept,
} from '@components/orderRecept/redux/actions/OrderReceptActions';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Grid, TextField, Typography, useTheme} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {TypographyStyledUser} from '@components/faqs/StyledFAQ';

const OrderRecept: React.FC & {acl?: ACLObj} = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const [dateInitConfirmPrev, setDateInitConfirmPrev] = useState<string>('');
    const [dateEndConfirmPrev, setDateEndConfirmPrev] = useState<string>('');
    const [dateInitDeliveryPrev, setDateInitDeliveryPrev] = useState<string>('');
    const [dateEndDeliveryPrev, setDateEndDeliveryPrev] = useState<string>('');
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const orderrecept = useSelector((state: RootState) => state.orderrecept.list);
    const currentPage = useSelector((state: RootState) => state.orderrecept.page);
    const limit = useSelector((state: RootState) => state.orderrecept.limit);
    const totalPages = useSelector((state: RootState) => state.orderrecept.totalPages);
    const loading = useSelector((state: RootState) => state.orderrecept.loading);
    const {notAllowed, atLeastOnePermissionMerchandiseReceptionQR} = useCheckAbilities();

    const schemaForm = yup.object({
        dateInitConfirm: yup.date(),
        dateEndConfirm: yup.date(),
        dateInitDelivery: yup.date(),
        dateEndDelivery: yup.date(),
        entrySheet: yup.string(),
        storer: yup.string(),
        addressCode: yup.string(),
        referenceId: yup.string(),
    });

    const {handleSubmit, control, watch, setValue} = useForm({
        resolver: yupResolver(schemaForm),
    });

    const watchForm = watch();
    const handleChangeDownload = (format: any) => {
        const filters = watchForm;
        dispatch(fetchGetDownloadOrdersRecept(token, format, filters));
    };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const data: any = {
            page: value,
            limit: limit,
            ...convertDate(),
        };

        if (watchForm.entrySheet) {
            data['entrySheet'] = watchForm.entrySheet;
        }

        if (watchForm.storer) {
            data['storer'] = watchForm.storer;
        }

        if (watchForm.addressCode) {
            data['addressCode'] = watchForm.addressCode;
        }

        if (watchForm.referenceId) {
            data['referenceId'] = watchForm.referenceId;
        }
        dispatch(fetchGetOrdersRecept(token, data));
    };

    const handleChangeRowsPerPage = (event: any) => {
        const data: any = {
            page: 1,
            limit: event.target.value,
            ...convertDate(),
        };

        if (watchForm.entrySheet) {
            data['entrySheet'] = watchForm.entrySheet;
        }

        if (watchForm.storer) {
            data['storer'] = watchForm.storer;
        }

        if (watchForm.addressCode) {
            data['addressCode'] = watchForm.addressCode;
        }

        if (watchForm.referenceId) {
            data['referenceId'] = watchForm.referenceId;
        }

        dispatch(fetchGetOrdersRecept(token, data));
    };

    const convertDate = () => {
        let {dateInitConfirm, dateEndConfirm, dateInitDelivery, dateEndDelivery} = watchForm;
        const data: any = {};
        if (dateInitConfirm) {
            if (typeof dateInitConfirm !== 'string')
                dateInitConfirm = DateTime.fromJSDate(dateInitConfirm).toFormat('yyyy-MM-dd');
            data['dateInitConfirm'] = DateTime.fromFormat(dateInitConfirm, 'yyyy-MM-dd').toFormat(
                'yyyy-MM-dd'
            );
        }
        if (dateEndConfirm) {
            if (typeof dateEndConfirm !== 'string')
                dateEndConfirm = DateTime.fromJSDate(dateEndConfirm).toFormat('yyyy-MM-dd');
            data['dateEndConfirm'] = DateTime.fromFormat(dateEndConfirm, 'yyyy-MM-dd').toFormat(
                'yyyy-MM-dd'
            );
        }
        if (dateInitDelivery) {
            if (typeof dateInitDelivery !== 'string')
                dateInitDelivery = DateTime.fromJSDate(dateInitDelivery).toFormat('yyyy-MM-dd');
            data['dateInitDelivery'] = DateTime.fromFormat(dateInitDelivery, 'yyyy-MM-dd').toFormat(
                'yyyy-MM-dd'
            );
        }
        if (dateEndDelivery) {
            if (typeof dateEndDelivery !== 'string')
                dateEndDelivery = DateTime.fromJSDate(dateEndDelivery).toFormat('yyyy-MM-dd');
            data['dateEndDelivery'] = DateTime.fromFormat(dateEndDelivery, 'yyyy-MM-dd').toFormat(
                'yyyy-MM-dd'
            );
        }

        return data;
    };
    const onSubmit = () => {
        const json = {
            ...convertDate(),
            entrySheet: watchForm.entrySheet ? watchForm.entrySheet : null,
            storer: watchForm.storer ? watchForm.storer : null,
            addressCode: watchForm.addressCode ? watchForm.addressCode : null,
            referenceId: watchForm.referenceId ? watchForm.referenceId : null,
            page: 1,
            limit: limit,
        };
        dispatch(fetchGetOrdersRecept(token, json));
    };
    useEffect(() => {
        init();
    }, [dispatch, token]);

    const init = () => {
        const dateInitLuxon = DateTime.now().startOf('month');
        const initDate = dateInitLuxon.toFormat('yyyy-MM-dd');
        const endDate = dateInitLuxon.plus({month: 1}).toFormat('yyyy-MM-dd');
        if (token) {
            const json = {
                dateInitConfirm: initDate,
                dateEndConfirm: endDate,
                dateInitDelivery: initDate,
                dateEndDelivery: endDate,
                page: currentPage ?? 1,
                limit: limit ?? 10,
            };
            dispatch(fetchGetOrdersRecept(token, json));
        }
    };
    useEffect(() => {
        if (token) {
            const dateInitLuxon = DateTime.now().startOf('month');
            const initDate = dateInitLuxon.toFormat('yyyy-MM-dd');

            const endDate = dateInitLuxon.plus({month: 1}).toFormat('yyyy-MM-dd');
            const json = {
                dateInitConfirm: initDate,
                dateEndConfirm: endDate,
                dateInitDelivery: initDate,
                dateEndDelivery: endDate,
                page: currentPage ?? 1,
                limit: limit ?? 10,
            };
            setValue('dateInitConfirm', initDate);
            setValue('dateEndConfirm', endDate);
            setValue('dateInitDelivery', initDate);
            setValue('dateEndDelivery', endDate);

            dispatch(fetchGetOrdersRecept(token, json));
        }
    }, [dispatch, token]);

    useEffect(() => {
        let {dateInitConfirm} = watchForm;
        if (dateInitConfirm && typeof dateInitConfirm !== 'string')
            dateInitConfirm = DateTime.fromJSDate(dateInitConfirm).toFormat('yyyy-MM-dd');
        if (dateInitConfirm && dateInitConfirm !== dateInitConfirmPrev) {
            const dateEndConfirm = DateTime.fromFormat(dateInitConfirm, 'yyyy-MM-dd')
                .plus({
                    month: 1,
                })
                .toFormat('yyyy-MM-dd');
            setValue('dateEndConfirm', dateEndConfirm);
            setDateEndConfirmPrev(dateEndConfirm);
        }
    }, [watchForm.dateInitConfirm]);
    useEffect(() => {
        let {dateEndConfirm} = watchForm;

        if (dateEndConfirm && typeof dateEndConfirm !== 'string')
            dateEndConfirm = DateTime.fromJSDate(dateEndConfirm).toFormat('yyyy-MM-dd');
        if (dateEndConfirm && dateEndConfirm !== dateEndConfirmPrev) {
            const dateInitConfirm = DateTime.fromFormat(dateEndConfirm, 'yyyy-MM-dd')
                .minus({
                    month: 1,
                })
                .toFormat('yyyy-MM-dd');

            setValue('dateInitConfirm', dateInitConfirm);
            setDateInitConfirmPrev(dateInitConfirm);
        }
    }, [watchForm.dateEndConfirm]);

    useEffect(() => {
        let {dateInitDelivery} = watchForm;
        if (dateInitDelivery && typeof dateInitDelivery !== 'string')
            dateInitDelivery = DateTime.fromJSDate(dateInitDelivery).toFormat('yyyy-MM-dd');
        if (dateInitDelivery && dateInitDelivery !== dateInitDeliveryPrev) {
            const dateEndDelivery = DateTime.fromFormat(dateInitDelivery, 'yyyy-MM-dd')
                .plus({
                    month: 1,
                })
                .toFormat('yyyy-MM-dd');
            setValue('dateEndDelivery', dateEndDelivery);
            setDateEndDeliveryPrev(dateEndDelivery);
        }
    }, [watchForm.dateInitDelivery]);
    useEffect(() => {
        let {dateEndDelivery} = watchForm;

        if (dateEndDelivery && typeof dateEndDelivery !== 'string')
            dateEndDelivery = DateTime.fromJSDate(dateEndDelivery).toFormat('yyyy-MM-dd');
        if (dateEndDelivery && dateEndDelivery !== dateEndDeliveryPrev) {
            const dateInitDelivery = DateTime.fromFormat(dateEndDelivery, 'yyyy-MM-dd')
                .minus({
                    month: 1,
                })
                .toFormat('yyyy-MM-dd');

            setValue('dateInitDelivery', dateInitDelivery);
            setDateInitDeliveryPrev(dateInitDelivery);
        }
    }, [watchForm.dateEndDelivery]);

    return atLeastOnePermissionMerchandiseReceptionQR() ? (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('receptpo.title')}
                </Typography>
            </Box>
            <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        padding: '15px',
                        paddingBottom: '0',
                        height: '73px',
                        background: '#fff',
                    }}
                >
                    <Box sx={{marginRight: '5px', height: '50px'}}>
                        <Controller
                            name="entrySheet"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('receptpo.filters.entry_sheet')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                    </Box>
                    <Box sx={{marginRight: '5px', height: '50px'}}>
                        <Controller
                            name="storer"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('receptpo.filters.warehouse_clerk')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                    </Box>
                    {/* <Box sx={{marginLeft: '10px', height: '50px'}}>
                        <Controller
                            name="addressCode"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('receptpo.filters.stock')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                    </Box> */}
                    <Box sx={{marginRight: '5px', height: '50px'}}>
                        <Controller
                            name="referenceId"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('consultpo.table.headers.purchase_order')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '20px',
                        padding: '15px',
                        height: '73px',
                        background: '#fff',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateInitConfirm"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <DatePicker
                                            label={t('receptpo.filters.confirmation_date_start')}
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                        marginRight: '5px',
                                                    }}
                                                />
                                            )}
                                        />
                                    );
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEndConfirm"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <DatePicker
                                            label={t('receptpo.filters.confirmation_date_end')}
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                        marginRight: '5px',
                                                    }}
                                                />
                                            )}
                                        />
                                    );
                                }}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateInitDelivery"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <DatePicker
                                            label={t('receptpo.filters.delivery_date_start')}
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                        marginRight: '5px',
                                                    }}
                                                />
                                            )}
                                            maxDate={watchForm.dateEndDelivery}
                                        />
                                    );
                                }}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEndDelivery"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <DatePicker
                                            label={t('receptpo.filters.delivery_date_end')}
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                        marginRight: '5px',
                                                    }}
                                                />
                                            )}
                                            minDate={watchForm.dateInitDelivery}
                                        />
                                    );
                                }}
                            />
                        </LocalizationProvider>
                        <TextButton type="submit" onClick={onSubmit} sx={{marginLeft: 0}}>
                            {t('components.filter_btn.title').toUpperCase()}
                        </TextButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                        <TextButtonDetails
                            type="button"
                            onClick={() => {
                                handleChangeDownload('csv');
                            }}
                            disabled={notAllowed(
                                FeatureCodeEnum.MERCHANDISE_RECEIPT_QR,
                                MerchandiseReceptionEnum.EXPORT_CSV_QR
                            )}
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={IconDownload} />
                                <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                    CSV
                                </TypographyStyledUser>
                            </div>
                        </TextButtonDetails>
                        <TextButtonDetails
                            type="button"
                            onClick={() => {
                                handleChangeDownload('xlsx');
                            }}
                            disabled={notAllowed(
                                FeatureCodeEnum.MERCHANDISE_RECEIPT_QR,
                                MerchandiseReceptionEnum.EXPORT_CSV_QR
                            )}
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={IconDownload} />
                                <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                    XLSX
                                </TypographyStyledUser>
                            </div>
                        </TextButtonDetails>
                    </Box>
                </Box>
            </Box>
            <TableContainerBinnance container>
                <TableHeadRow container>
                    <Grid item xs={1.5}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.purchase_order')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('receptpo.table.headers.business_name')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('receptpo.table.headers.confirmation_date')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('receptpo.table.headers.delivery_date')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('receptpo.filters.entry_sheet')}
                        </TableCellHead>
                    </Grid>

                    <Grid item xs={1.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('receptpo.filters.warehouse_clerk')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHeadFinal sx={{fontSize: '9.5px'}}>
                            {t('components.status_selector.title')}
                        </TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading && (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                )}
                {!loading &&
                    orderrecept.map((item: any, index: any) => {
                        return [
                            <Grid item xs={1.5} key={`pos-0-${index}${item}`}>
                                <TableCell sx={{fontSize: '9.5px'}}>{item.referenceId}</TableCell>
                            </Grid>,
                            <Grid item xs={3} key={`pos-1-${index}${item}`}>
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    {item.vendorCompany.name}
                                </TableCell>
                            </Grid>,
                            <Grid item xs={1.5} key={`pos-2-${index}${item}`}>
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    {DateTime.fromISO(item.endDateValidity).toFormat('dd/MM/yyyy')}{' '}
                                </TableCell>
                            </Grid>,
                            <Grid item xs={1.5} key={`pos-3-${index}${item}`}>
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    {DateTime.fromISO(item.deliveryDate).toFormat('dd/MM/yyyy')}{' '}
                                </TableCell>
                            </Grid>,
                            <Grid item xs={1.5} key={`pos-4-${index}${item}`}>
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    {item.lineItems[0].entrySheet}
                                </TableCell>
                            </Grid>,

                            <Grid item xs={1.5} key={`pos-5-${index}${item}`}>
                                <TableCell sx={{fontSize: '9.5px'}}>{item.storer}</TableCell>
                            </Grid>,
                            <Grid item xs={1.5} key={`pos-6-${index}${item}`}>
                                <TableCell sx={{fontSize: '9.5px'}}>
                                    <StatusText status={item.status} />
                                </TableCell>
                            </Grid>,
                        ];
                    })}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    ) : null;
};
OrderRecept.acl = {
    action: MerchandiseReceptionEnum.LIST_RECEIPTS_QR,
    subject: FeatureCodeEnum.MERCHANDISE_RECEIPT_QR,
};
export default OrderRecept;
