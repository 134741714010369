import {TextError, TextSuccess} from '@/components/UI/molecules/textButton/textButtons';
import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {fetchPutStatusRole} from '@components/roles/redux/actions/RolesActions';
import {AppDispatch, RootState} from '@config/store';
import {Box, Modal, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

interface IRoleModalProps {
    isModalOpen: boolean;
    closeModal: any;
}

const RoleStatusModal = (props: IRoleModalProps) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {isModalOpen, closeModal} = props;
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.rolesManager.loading);
    // const error = useSelector((state: RootState) => state.rolesManager.error);
    const role = useSelector((state: RootState) => state.rolesManager.role);

    const updateRoleStatus = () => {
        if (token && role) {
            const dataRole = {...role, enabled: !role.enabled};
            dispatch(fetchPutStatusRole(token, dataRole));
        }
        closeModal();
    };

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('roles.modals.role_deactivate.title')}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {t('roles.modals.role_deactivate.role')}:
                        </span>{' '}
                        {role && role.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {t('roles.modals.role_deactivate.status')}:
                        </span>{' '}
                        {role && role.enabled ? (
                            <TextSuccess>{t('roles.modals.role_deactivate.active')}</TextSuccess>
                        ) : (
                            <TextError>{t('roles.modals.role_deactivate.inactive')}</TextError>
                        )}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {t('roles.modals.role_deactivate.confirm_change')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {role && role.enabled ? (
                            <TextError>{t('roles.modals.role_deactivate.inactive')}</TextError>
                        ) : (
                            <TextSuccess>{t('roles.modals.role_deactivate.active')}</TextSuccess>
                        )}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}>{t('cancel').toUpperCase()}</ButtonCancel>
                    <SaveButton disabled={loading} onClick={updateRoleStatus}>
                        {loading ? <Loader size={24} /> : t('apply').toUpperCase()}
                    </SaveButton>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default RoleStatusModal;
