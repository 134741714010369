export const GET_MODELORAMA_BUSSINES = 'GET_MODELORAMA_BUSSINES';
export const GET_MODELORAMA_BUSSINES_SUCCESS = 'GET_MODELORAMA_BUSSINES_SUCCESS';
export const GET_MODELORAMA_BUSSINES_ERROR = 'GET_MODELORAMA_BUSSINES_ERROR';

export const GET_MODELORAMA_ORDERS_BUSSINES = 'GET_MODELORAMA_ORDERS_BUSSINES';
export const GET_MODELORAMA_BUSSINES_ORDERS_SUCCESS = 'GET_MODELORAMA_BUSSINES_ORDERS_SUCCESS';
export const GET_MODELORAMA_BUSSINES_ORDERS_ERROR = 'GET_MODELORAMA_BUSSINES_ORDERS_ERROR';

export const GET_MODELORAMA_ORDERS_FILTERS = 'GET_MODELORAMA_ORDERS_FILTERS';

export const CREATE_FILE_XML_MODELORAMA = 'CREATE_FILE_XML_MODELORAMA';
export const CREATE_FILE_XML_MODELORAMA_ERROR = 'CREATE_FILE_XML_MODELORAMA_ERROR';
export const CREATE_FILE_XML_MODELORAMA_SUCCESS = 'CREATE_FILE_XML_MODELORAMA_SUCCESS';
export const SET_FILE_UPLOAD_STATUS = 'SET_FILE_UPLOAD_STATUS';
