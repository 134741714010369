import * as yup from 'yup';

export const schemaFormModal = (t: any) =>
    yup.object({
        roles: yup
            .array()
            .min(1, t('banners.modal.errors.at_least_one_role'))
            .of(yup.string())
            .required(t('banners.modal.errors.role_required')),
        countries: yup
            .array()
            .min(1, t('banners.modal.errors.at_least_one_country'))
            .of(yup.number())
            .required(t('banners.modal.errors.country_required')),
        name: yup
            .string()
            .required(t('banners.modal.errors.name_required'))
            .max(90, t('banners.modal.errors.name_max_length')),
        name_en: yup
            .string()
            .required(t('banners.modal.errors.name_required'))
            .max(90, t('banners.modal.errors.name_max_length')),
        content: yup
            .string()
            .required(t('banners.modal.errors.content_required'))
            .max(256, t('banners.modal.errors.content_max_length')),
        content_en: yup
            .string()
            .required(t('banners.modal.errors.content_required'))
            .max(256, t('banners.modal.errors.content_max_length')),
        file: yup.mixed().required(t('banners.modal.errors.file_required')),
    });
