import {
    GET_BANNERS,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_ERROR,
    POST_BANNERS,
    POST_BANNERS_SUCCESS,
    POST_BANNERS_ERROR,
    UPDATE_BANNERS,
    UPDATE_BANNERS_SUCCESS,
    UPDATE_BANNERS_ERROR,
    DELETE_BANNERS,
    DELETE_BANNERS_SUCCESS,
    DELETE_BANNERS_ERROR,
    CREATE_FILE_BANNERS,
    CREATE_FILE_BANNERS_SUCCESS,
    CREATE_FILE_BANNERS_ERROR,
    OPEN_MODAL_PREVIEW,
    CLOSE_MODAL_PREVIEW,
    GET_BANNERS_BYID_SUCCESS,
} from '@/components/banners/redux/types/BannersTypes';

const initialState = {
    banners: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    showModalPreview: false,
    mediaType: '',
    mediaSrc: '',
    mediaAlt: '',
    limit: 10,
    file: null,
};

const banners = (state = initialState, action) => {
    switch (action.type) {
        case GET_BANNERS:
            return {
                ...state,
                loading: true,
            };
        case GET_BANNERS_SUCCESS:
            return {
                ...state,
                banners: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_BANNERS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_BANNERS:
            return {
                ...state,
                loading: true,
            };
        case POST_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_BANNERS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_BANNERS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_BANNERS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case DELETE_BANNERS:
            return {
                ...state,
                loading: true,
            };
        case DELETE_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_BANNERS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CREATE_FILE_BANNERS:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FILE_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CREATE_FILE_BANNERS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case OPEN_MODAL_PREVIEW:
            return {
                ...state,
                showModalPreview: true,
                mediaType: 'Imágenes', // action.Mediatype,
                mediaSrc: action.src,
                mediaAlt: action.alt,
            };
        case CLOSE_MODAL_PREVIEW:
            return {
                ...state,
                showModalPreview: false,
                mediaType: '',
                mediaSrc: '',
                mediaAlt: '',
            };
        case GET_BANNERS_BYID_SUCCESS:
            return {
                ...state,
                file: action.value,
            };
        default:
            return state;
    }
};

export default banners;
