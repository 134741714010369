import React from 'react';
import {Link, useTheme} from '@mui/material';

interface IDownloadFileProps {
    text: string;
    disabled: boolean;
    handleDownloadClick: () => void;
}

const DownloadFile: React.FC<IDownloadFileProps> = ({text, disabled, handleDownloadClick}) => {
    const theme = useTheme();

    return (
        <>
            <Link
                component="button"
                underline="none"
                style={{cursor: 'pointer', color: theme.palette.secondary.main}}
                onClick={handleDownloadClick}
                disabled={disabled}
            >
                {text}
            </Link>
        </>
    );
};

export default React.memo(DownloadFile);
