import CustomPagination from '@/components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@/config/store';
import {DetailsTable} from '@components/billingStatement//components/detailsTableComponent';
import BillingStatementLoader from '@components/billingStatement/components/billingStatementLoader';
import {ISociety} from '@components/billingStatement/interface';
import {
    fetchGetSocieties,
    fetchSocietyDetails,
} from '@components/billingStatement/redux/reducers/actions/SocietyActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, Typography, useTheme} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

export const BillingStatementData: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const societyData = useSelector((state: RootState) => state.billingStatement.societiesData);
    const societiesLoading = useSelector(
        (state: RootState) => state.billingStatement.societiesLoading
    );
    const societiesLimit = useSelector((state: RootState) => state.billingStatement.societiesLimit);
    const currentPage = useSelector((state: RootState) => state.billingStatement.societiesPage);
    const totalPages = useSelector(
        (state: RootState) => state.billingStatement.societiesTotalPages
    );
    const detailsLimit = useSelector((state: RootState) => state.billingStatement.detailsLimit);

    const filters = useSelector((state: RootState) => state.billingStatement.filters);

    const [expandedSociety, setExpandedSociety] = useState<string>('');

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setExpandedSociety('');
        dispatch(fetchGetSocieties(token, value, societiesLimit, filters));
    };

    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetSocieties(token, 1, event.target.value, filters));
    };

    const handleExpand =
        (societyReq: string, supplierReq: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedSociety(isExpanded ? supplierReq + societyReq : '');
            if (isExpanded) {
                dispatch(
                    fetchSocietyDetails({
                        token,
                        page: 1,
                        limit: detailsLimit,
                        filters: {
                            society: societyReq,
                            supplierId: supplierReq,
                            ...filters,
                        },
                    })
                );
            }
        };

    const sxHeadTitle = {
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: theme.typography.fontFamily,
        paddingRight: '10px',
    };

    const sxHeadItem = {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: theme.typography.fontFamily,
        paddingRight: '30px',
    };

    return (
        <Box
            sx={{
                padding: '10px 0',
            }}
        >
            {societiesLoading ? (
                <BillingStatementLoader />
            ) : (
                societyData &&
                societyData.map((society: ISociety, index: number) => {
                    return (
                        <Accordion
                            key={`${society.supplierId}-${index}`}
                            expanded={
                                `${expandedSociety}` === society.supplierId + society.societyId
                            }
                            onChange={handleExpand(society.societyId, society.supplierId)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '15px 15px 15px 0',
                                        background: '#fff',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography sx={sxHeadTitle}>
                                        {`ID ${t('billingStatement.filters.society')}`}:
                                    </Typography>
                                    <Typography sx={sxHeadItem}>{society.societyId}</Typography>
                                    <Typography sx={sxHeadTitle}>
                                        {`${t('billingStatement.filters.society')}`}:
                                    </Typography>
                                    <Typography sx={sxHeadItem}>{society.societyName}</Typography>
                                    <Typography sx={sxHeadTitle}>{t('supplier')}:</Typography>
                                    <Typography sx={sxHeadItem}>{society.vendorName}</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DetailsTable
                                    societyId={society.societyId}
                                    supplierId={society.supplierId}
                                />
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            )}
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChangePage}
                limit={societiesLimit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};
