import {Box, CircularProgress, IconButton, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {TypographyStyled600} from '@components/faqs/StyledFAQ';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
} from '@components/legalAgreements/StyledLegalAgreements';
import {useDispatch, useSelector} from 'react-redux';
import {
    setXml,
    setPdf,
    verifyComplement,
    clearBillsError,
} from '@components/orderSimply/redux/actions/LoadComplementActions';
import DeleteIcon from '@mui/icons-material/Delete';
import {RequestPageOutlined} from '@mui/icons-material';
import {CloudUploadOutlined} from '@mui/icons-material';
import {getFileSize} from '@/utils/utils';
import {useTranslation} from 'react-i18next';
import {getComplementError} from '@components/orderSimply/constants/complement-errors';
const MAX_FILE_SIZE_MB = 10;
const ALLOWED_FILE_TYPES = ['application/pdf', 'text/xml'];

export const LoadComplement = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.logins.accessToken);
    const pdfFile = useSelector((state) => state.complement.pdfFile);
    const xmlFile = useSelector((state) => state.complement.xmlFile);
    const loadingBills = useSelector((state) => state.complement.loadingBills);
    const errorBills = useSelector((state) => state.complement.errorBills);
    const [fileError, setFileError] = useState('');
    const inputFileRef = useRef(null);
    const dispatch = useDispatch();

    const handleFileChange = (event, type) => {
        event.stopPropagation();
        const files = type === 'drop' ? event.dataTransfer.files : event.target.files;
        if (!files) {
            return;
        }
        let allowedFiles = [];
        for (const file of files) {
            if (allowedFiles.length === 2) break;
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                file['error'] = `El tamaño del archivo supera los ${MAX_FILE_SIZE_MB} MB.`;
            }
            if (file.type === ALLOWED_FILE_TYPES[0]) dispatch(setPdf(file));
            if (file.type === ALLOWED_FILE_TYPES[1]) {
                dispatch(verifyComplement(token, file));
            }
        }

        if (inputFileRef.current) {
            inputFileRef.current.value = '';
        }
    };

    const handleDelete = (event, file) => {
        event.stopPropagation();
        if (file.type === ALLOWED_FILE_TYPES[0]) dispatch(setPdf(null));
        if (file.type === ALLOWED_FILE_TYPES[1]) {
            dispatch(setXml(null));
            dispatch(clearBillsError());
        }
    };

    const setError = () => {
        const {message} = getComplementError(errorBills);
        setFileError(t(message));
    };

    useEffect(setError, [errorBills]);

    const FileUploated = ({file, type}) => {
        return (
            <>
                <Box
                    sx={{
                        border: '1px solid',
                        borderRadius: '6px',
                        borderColor: type === 'PDF' || errorBills === '' ? '#c5d1d8' : '#db3c30',
                        margin: '10px 0px',
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <RequestPageOutlined sx={{margin: '5px', fontSize: 50}} />
                        <Box display={'grid'}>
                            <Typography variant="subtitle2">
                                {file ? file.name : `${t('file')} ${type} ${t('required')}`}
                            </Typography>
                            <Box>
                                <Typography
                                    variant="caption"
                                    color={file && file.error ? 'error' : ''}
                                    sx={{paddingRight: '5px'}}
                                >
                                    {file ? getFileSize(file) : '(0.00 Mb)'}
                                </Typography>
                                {type === 'PDF' || errorBills === '' ? (
                                    <Typography variant="caption" color="error">
                                        {file && file.error}
                                    </Typography>
                                ) : (
                                    <Typography variant="caption" color="error">
                                        {fileError}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{flex: '1 1 auto'}} />
                        {file && loadingBills && type === 'XML' && (
                            <Box
                                sx={{
                                    margin: '10px 0px',
                                    padding: '8px 10px 0px 8px',
                                }}
                            >
                                <CircularProgress size={24} />
                            </Box>
                        )}
                        {file && (type === 'PDF' || !loadingBills) && (
                            <IconButton
                                aria-label="Eliminar"
                                onClick={(e) => {
                                    handleDelete(e, file);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </>
        );
    };

    return (
        <>
            <FileUploadContainer
                sx={{
                    minHeight: '230px',
                    border: '2px solid #c5d1d8',
                    margin: '10px 0',
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    handleFileChange(e, 'drop');
                }}
                onClick={() => document.getElementById('fileInput')?.click()}
            >
                <StyledInput
                    id="fileInput"
                    type="file"
                    multiple="multiple"
                    accept="application/pdf, text/xml"
                    onChange={(e) => {
                        handleFileChange(e, 'change');
                    }}
                    ref={inputFileRef}
                />
                <Box display={'grid'}>
                    <StyledFileLabelButton variant="outlined" as={'span'}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                        >
                            <CloudUploadOutlined fontSize="large" />
                            <Typography variant="subtitle2" sx={{margin: '10px'}}>
                                {t('drag_upload_files')}
                            </Typography>
                        </Box>
                    </StyledFileLabelButton>
                </Box>
            </FileUploadContainer>
            <TypographyStyled600>{t('selected_files')}:</TypographyStyled600>
            <Box>
                <FileUploated file={pdfFile} type={'PDF'} />
                <FileUploated file={xmlFile} type={'XML'} />
            </Box>
        </>
    );
};
