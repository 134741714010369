'use client';
import {Action as ActionEnum} from '@/components/tickets/enums';
import {FormValues, IInputsRequired} from '@/components/tickets/interfaces';
import {fetchPostTicket} from '@/components/tickets/redux/actions/TicketActions';
import {
    ButtonCancel,
    Loader,
    RedText,
    SaveButton,
    StyledTextField,
} from '@/components/tickets/StyledTicket';
import {AppDispatch, RootState} from '@/config/store';
import {
    SelectCountry,
    SelecteActions,
    SelectTypeDoubt,
    UploadDragAndDrop,
} from '@components/tickets/components/inputs';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Divider, TextField, Typography, useTheme} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {performActionValues as Action} from '@components/tickets/enums';

export type CreateProps = {
    closeModal: () => void;
};
const initialValuesRequired: IInputsRequired = {
    numberInvoice: false,
    codeAcreedor: false,
    purchaseOrder: false,
    entrySheet: false,
    doubts: false,
    society: false,
    importAmount: false,
    datePayment: false,
};
const initialValues: FormValues = {
    actions: '',
    country: '',
    numberInvoice: '',
    codeAcreedor: '',
    purchaseOrder: '',
    entrySheet: '',
    doubts: '',
    description: '',
    file: '',
    society: '',
    importAmount: '',
    datePayment: DateTime.now().toJSDate(),
};

const CreateTicket: React.FC<CreateProps> = ({closeModal}) => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const {t} = useTranslation();
    const lang = userLang === 'es' ? es : enUS;
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.tickets.loading);
    const user = useSelector((state: RootState) => state.logins.user);

    const [inputsRequired, setInputsRequired] = useState<IInputsRequired>(initialValuesRequired);
    const [schemaFormModal, setSchemaFormModal] = useState<yup.AnyObject>({
        country: yup.string().required(t('ticket.form_actions.country')),
        actions: yup.string().required(t('ticket.form_actions.actions')),
        description: yup.string().required(t('ticket.form_actions.description')),
        file: yup.mixed().required(t('ticket.form_actions.file')),
        society: yup.string().required(t('ticket.form_actions.society')),
        importAmount: yup.string().required(t('ticket.form_actions.import_mount')),
        datePayment: yup.date().required(t('ticket.form_actions.payment_date')),
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        setValue,
        watch,
        getValues,
    } = useForm<FormValues>({
        resolver: yupResolver(yup.object({...schemaFormModal})),
        defaultValues: initialValues,
        mode: 'onChange',
    });

    const watchForm = watch();
    const onSubmit = () => {
        dispatch(
            fetchPostTicket(token, {
                ...watchForm,
                actions: ActionEnum[watchForm.actions as keyof typeof ActionEnum],
                codeAcreedor: user?.externalId ?? '',
                datePayment:
                    Action.BANK_RECEIPT_PAYMENT === watchForm.actions
                        ? DateTime.now().toJSDate()
                        : null,
            })
        );
        reset(initialValues);
        closeModal();
    };

    useEffect(() => {
        const updateRequired = {
            ...initialValuesRequired,

            purchaseOrder:
                Action.INVOICE_STATUS === watchForm.actions ||
                Action.PAYMENT_DATE === watchForm.actions ||
                Action.PAYMENT_CONDITION === watchForm.actions ||
                Action.DETAIL_RETENTION === watchForm.actions,
            society: Action.BANK_RECEIPT_PAYMENT === watchForm.actions,
            importAmount: Action.BANK_RECEIPT_PAYMENT === watchForm.actions,
            datePayment: Action.BANK_RECEIPT_PAYMENT === watchForm.actions,
            doubts: Action.PORTAL_PROVIDER === watchForm.actions,
        };

        setInputsRequired(() => updateRequired);
        setSchemaFormModal((prev) => ({
            ...prev,
            numberInvoice: yup.string().test('', t('ticket.form_actions.bill_number'), (value) => {
                const val = value ?? '';
                if (updateRequired.numberInvoice) return val !== '';
                return true;
            }),

            purchaseOrder: yup
                .string()
                .test('', t('ticket.form_actions.purchase_order'), (value) => {
                    const val = value ?? '';
                    if (updateRequired.purchaseOrder) return val !== '';
                    return true;
                }),
            entrySheet: yup.string().test('', t('ticket.form_actions.entry_sheet'), (value) => {
                const val = value ?? '';
                if (updateRequired.entrySheet) return val !== '';
                return true;
            }),
            doubts: yup.string().test('', t('ticket.form_actions.doubt_type'), (value) => {
                const val = value ?? '';
                if (updateRequired.doubts) return val !== '';
                return true;
            }),
            society: yup.string().test('', t('ticket.form_actions.society'), (value) => {
                const val = value ?? '';
                if (updateRequired.society) return val !== '';
                return true;
            }),
            importAmount: yup.string().test('', t('ticket.form_actions.import_amount'), (value) => {
                const val = value ?? '';
                if (updateRequired.importAmount) return val !== '';
                return true;
            }),
            datePayment: yup.date().test('', t('ticket.form_actions.payment_date'), (value) => {
                const val = value ?? '';
                if (updateRequired.datePayment) return val !== '';
                return true;
            }),
        }));

        reset(getValues());
    }, [watchForm.actions]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 30px',
                    background: '#FFF',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '14px',
                        fontWeight: 600,
                    }}
                >
                    {t('ticket.fill_fields')}
                </Typography>
                <Box display="flex" flexWrap={'wrap'} sx={{justifyContent: 'space-between'}}>
                    <SelectCountry setValue={setValue} errors={errors} control={control} />

                    <SelecteActions setValue={setValue} errors={errors} control={control} />
                </Box>

                {watchForm.actions && (
                    <Divider
                        textAlign="left"
                        sx={{
                            '&::before': {width: '0%'},
                            '&::after': {width: '100%', borderColor: '#C5D1D8'},
                            margin: '30px 0 10px 0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                        >
                            {t('ticket.data_fill')} <RedText>{t('ticket.mandatory')}</RedText>
                        </Typography>
                    </Divider>
                )}

                <Box display="flex" p={1} flexWrap={'wrap'} sx={{justifyContent: 'space-between'}}>
                    {watchForm.actions === Action.INVOICE_STATUS ||
                    watchForm.actions === Action.PAYMENT_DATE ? (
                        <Controller
                            render={({field, fieldState}) => (
                                <StyledTextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('poagents.table.headers.num_invoice') + '*'}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{minWidth: '320px', marginBottom: '15px'}}
                                    required={inputsRequired.numberInvoice}
                                />
                            )}
                            name="numberInvoice"
                            control={control}
                        />
                    ) : null}

                    {watchForm.actions === Action.BANK_RECEIPT_PAYMENT ? (
                        <Controller
                            render={({field, fieldState}) => (
                                <StyledTextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('csf.society')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{minWidth: '320px', marginBottom: '15px'}}
                                    required={inputsRequired.society}
                                />
                            )}
                            name="society"
                            control={control}
                        />
                    ) : null}
                    {watchForm.actions === Action.BANK_RECEIPT_PAYMENT ? (
                        <Controller
                            render={({field, fieldState}) => (
                                <StyledTextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('amount')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{minWidth: '320px', marginBottom: '15px'}}
                                    required={inputsRequired.importAmount}
                                />
                            )}
                            name="importAmount"
                            control={control}
                        />
                    ) : null}
                    {watchForm.actions === Action.BANK_RECEIPT_PAYMENT ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="datePayment"
                                control={control}
                                defaultValue={DateTime.now().toJSDate()}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <DatePicker
                                        label={`Fecha de Pago`}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{minWidth: '320px', marginBottom: '15px'}}
                                                id="outlined-required"
                                                label={t('ticket.pay_date') + '*'}
                                                error={!!error}
                                                helperText={error ? error.message : ''}
                                                required={inputsRequired.datePayment}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    ) : null}

                    {watchForm.actions === Action.INVOICE_STATUS ||
                    watchForm.actions === Action.PAYMENT_DATE ||
                    watchForm.actions === Action.PAYMENT_CONDITION ||
                    watchForm.actions === Action.REQUIREMENT_AUDITOR ||
                    watchForm.actions === Action.PORTAL_PROVIDER ||
                    watchForm.actions === Action.DETAIL_RETENTION ||
                    watchForm.actions === Action.BANK_RECEIPT_PAYMENT ? (
                        <Controller
                            render={({field, fieldState}) => (
                                <StyledTextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('ticket.creditor_code')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{minWidth: '320px', marginBottom: '15px'}}
                                    required={inputsRequired.codeAcreedor}
                                    value={user?.externalId}
                                    defaultValue={user?.externalId}
                                />
                            )}
                            defaultValue={user?.externalId}
                            disabled
                            name="codeAcreedor"
                            control={control}
                        />
                    ) : null}
                    {watchForm.actions === Action.INVOICE_STATUS ||
                    watchForm.actions === Action.PAYMENT_DATE ||
                    watchForm.actions === Action.PAYMENT_CONDITION ||
                    watchForm.actions === Action.DETAIL_RETENTION ? (
                        <Controller
                            render={({field, fieldState}) => (
                                <StyledTextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('consultpo.table.headers.purchase_order')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{minWidth: '320px', marginBottom: '15px'}}
                                    required={inputsRequired.purchaseOrder}
                                />
                            )}
                            name="purchaseOrder"
                            control={control}
                        />
                    ) : null}
                    {watchForm.actions === Action.INVOICE_STATUS ||
                    watchForm.actions === Action.PAYMENT_DATE ||
                    watchForm.actions === Action.DETAIL_RETENTION ? (
                        <Controller
                            render={({field, fieldState}) => (
                                <StyledTextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('confirmposimply.entry_sheet_modal.entry_sheet')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{minWidth: '320px', marginBottom: '15px'}}
                                    required={inputsRequired.entrySheet}
                                />
                            )}
                            name="entrySheet"
                            control={control}
                        />
                    ) : null}
                    {watchForm.actions === Action.PORTAL_PROVIDER ? (
                        <SelectTypeDoubt
                            setValue={setValue}
                            errors={errors}
                            control={control}
                            required={inputsRequired.doubts}
                        />
                    ) : null}
                    <Box display="flex" justifyContent="space-between">
                        {watchForm.actions === Action.INVOICE_STATUS ||
                        watchForm.actions === Action.PAYMENT_DATE ||
                        watchForm.actions === Action.PAYMENT_CONDITION ||
                        watchForm.actions === Action.REQUIREMENT_AUDITOR ||
                        watchForm.actions === Action.PORTAL_PROVIDER ||
                        watchForm.actions === Action.DETAIL_RETENTION ||
                        watchForm.actions === Action.BANK_RECEIPT_PAYMENT ? (
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledTextField
                                        {...field}
                                        id="outlined-required"
                                        label={`${t('description')} *`}
                                        multiline
                                        rows={4}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error ? fieldState.error.message : ''
                                        }
                                        sx={{minWidth: '320px', marginBottom: '15px'}}
                                    />
                                )}
                                name="description"
                                control={control}
                            />
                        ) : null}
                        {watchForm.actions === Action.INVOICE_STATUS ||
                        watchForm.actions === Action.PAYMENT_DATE ||
                        watchForm.actions === Action.PAYMENT_CONDITION ||
                        watchForm.actions === Action.REQUIREMENT_AUDITOR ||
                        watchForm.actions === Action.PORTAL_PROVIDER ||
                        watchForm.actions === Action.DETAIL_RETENTION ||
                        watchForm.actions === Action.BANK_RECEIPT_PAYMENT ? (
                            <UploadDragAndDrop
                                setValue={setValue}
                                errors={errors}
                                control={control}
                            />
                        ) : null}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    height: '56px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    background: '#FFFFFF',
                }}
            >
                <ButtonCancel onClick={closeModal}>{t('cancel').toUpperCase()}</ButtonCancel>
                <SaveButton
                    disabled={loading}
                    sx={{marginRight: '40px'}}
                    onClick={handleSubmit(onSubmit)}
                >
                    {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                </SaveButton>
            </Box>
        </form>
    );
};

export default CreateTicket;
