import TermsConditions from '@/components/log/components/organisms/TermsConditions';
import {RootState} from '@/config/store';
import Binnacles from '@components/log/components/Binnacles';
import TermsConditionsDetails from '@components/log/components/organisms/TermsConditionsDetails';
import {hideTermsConditionsDetailsInfo} from '@components/log/redux/actions/TermsConditionActions';
import {Box, Tab, Tabs} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

const Logs = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const showTermsConditions = useSelector(
        (state: RootState) => state.binnacles.showTermconditions
    );
    const handleChange = (event: any, newValue: any) => {
        if (newValue === 0) {
            dispatch(hideTermsConditionsDetailsInfo());
        }
        setTabIndex(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Tabs value={tabIndex} onChange={handleChange}>
                <Tab label={t('logbook_and_details.title')} />
                <Tab label={t('terms_conditions.title')} />
            </Tabs>
            {tabIndex === 0 && <Binnacles />}
            {tabIndex === 1 ? (
                showTermsConditions ? (
                    <TermsConditionsDetails />
                ) : (
                    <TermsConditions />
                )
            ) : null}
        </Box>
    );
};

export default Logs;
