import {
    GET_FAQS,
    GET_FAQS_SUCCESS,
    GET_FAQS_ERROR,
    POST_FAQS,
    POST_FAQS_SUCCESS,
    POST_FAQS_ERROR,
    UPDATE_FAQS,
    UPDATE_FAQS_SUCCESS,
    UPDATE_FAQS_ERROR,
    DELETE_FAQS,
    DELETE_FAQS_SUCCESS,
    DELETE_FAQS_ERROR,
} from '@/components/faqs/redux/types/FAQsTypes';

const initialState = {
    faqs: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
};

const faqs = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAQS:
            return {
                ...state,
                loading: true,
            };
        case GET_FAQS_SUCCESS:
            return {
                ...state,
                faqs: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_FAQS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_FAQS:
            return {
                ...state,
                loading: true,
            };
        case POST_FAQS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_FAQS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_FAQS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_FAQS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_FAQS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case DELETE_FAQS:
            return {
                ...state,
                loading: true,
            };
        case DELETE_FAQS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_FAQS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default faqs;
