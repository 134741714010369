// Importaciones necesarias
import {AbilityProvider} from '@/context/AbilityContext';
import {fetchGetPerfil} from '@components/perfil/redux/actions/PerfilActions';
import store, {AppDispatch, RootState} from '@config/store';
import {CssBaseline, ThemeProvider, createTheme} from '@mui/material';
import {AppRouter} from '@routers/appRouter';
import React, {ReactNode, useEffect} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';

const persistor = persistStore(store);
interface Props {
    children: ReactNode;
}

const App = () => {
    const dispatch: AppDispatch = useDispatch();
    const tokenflag = useSelector((state: RootState) => state.logins.tokenflag);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const flagPerfil = useSelector((state: RootState) => state.perfil.flagPerfil);
    const id = useSelector((state: RootState) => {
        if (state.logins.user.length !== 0) {
            return state.logins.user.enterprise.id;
        }
    });
    useEffect(() => {
        persistor.persist();
        persistor.subscribe(() => {});

        if (
            tokenflag &&
            !flagPerfil &&
            id &&
            !window.location.pathname.includes('login') &&
            !window.location.pathname.includes('auth/enterprise')
        ) {
            dispatch(fetchGetPerfil(token, id));
        }
    }, [dispatch, token, id]);

    return (
        <AbilityProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <AppThemeProvider>
                            {' '}
                            <AppRouter />
                        </AppThemeProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </AbilityProvider>
    );
};

const AppThemeProvider: React.FC<Props> = ({children}) => {
    const color = useSelector((state: RootState) => state.perfil.colors);
    const font = useSelector((state: RootState) => state.perfil.font);

    const theme = createTheme({
        palette: {
            primary: {
                main: color !== null ? color[0].value : '#E5B611',
                light: '#E5B611',
                dark: '#A3B0BB',
            },
            secondary: {
                main: '#00ACFF',
                light: '#00ACFF',
            },
            text: {
                primary: '#011B34',
                secondary: '#000000',
            },
            success: {
                main: '#4BBC68',
            },
            info: {
                main: '#011B34',
            },
        },
        typography: {
            fontFamily: font !== null ? font[0].value : 'Arial',
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default App;
