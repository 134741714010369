import {AddButton, SaveButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import IconAlertDelete from '@/components/UI/atoms/icons/IconAlertDelete.svg';
import IconDelete from '@/components/UI/atoms/icons/IconDelete.svg';
import IconEdit from '@/components/UI/atoms/icons/IconEdit.svg';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainerUrl,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, URLenum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import {ButtonCancel} from '@components/UI/atoms/buttons/ButtonAlternative';
import SuccessUpdateModal from '@components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@components/UI/molecules/modals/WarningUpdateModal';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, CircularProgress, Grid, Link, Modal, Typography, useTheme} from '@mui/material';
import TextField from '@mui/material/TextField';
import {styled} from '@mui/system';
import {
    fetchDeleteUrls,
    fetchGetUrls,
    fetchPostUrls,
    fetchPutUrls,
} from '@urls/redux/actions/UrlsActions';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import ModalConfirmChanges from '@components/legalAgreements/modals/ModalConfirmChanges';

const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40vw;
    border-radius: 10px;
`;

const Loader = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
`;
const ImgIcon = styled('img')`
    height: 40px;
    margin-bottom: 30px;
`;
export const Nav = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main, // Usando color principal del tema
    height: '59px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    color: '#FFF',
    borderRadius: '3px 3px 0 0',
}));
const Urls: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    interface IForm {
        name: string;
        url: string;
        description: string;
    }
    interface ITable {
        name: string;
        url: string;
        description: string;
    }
    const schemaFormModal = yup.object({
        name: yup
            .string()
            .max(90, `${t('configuration.urls.validation.name_max_length')}`)
            .required(`${t('configuration.urls.validation.name_req')}`),
        url: yup.string().required(`${t('configuration.urls.validation.url_req')}`),
        description: yup
            .string()
            .required(`${t('configuration.urls.validation.description_req')}`)
            .max(256, `${t('configuration.urls.validation.desc_max_length')}`),
    });

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const hasConfiguration = useConfigurationUrl();
    const {
        handleSubmit,
        control,
        reset,
        formState: {errors, isDirty},
    } = useForm<IForm>({resolver: yupResolver(schemaFormModal), mode: 'onChange'});
    const onSubmitDelete = async () => {
        try {
            if (isAdd === 3) {
                await dispatch(fetchDeleteUrls(manualId, token));
            }
            reset({
                name: '',
                url: '',
                description: '',
            });
            setSelectedFile(null);
            closeModalDelete();
        } catch (error) {
            // Código para ejecutar si la acción falla
            // Puedes agregar lógica adicional en caso de error
        } finally {
            dispatch(fetchGetUrls(token, currentPage, limit));
            setFlag((prev) => !prev);
        }
    };
    const onSubmit = async (data: IForm) => {
        const formData = new FormData();
        if (selectedFile) formData.append('file', selectedFile);
        const json = {
            name: data.name,
            url: data.url.toLowerCase(),
            description: data.description,
        };
        try {
            if (isAdd === 1) {
                await dispatch(fetchPostUrls(token, json));
            }
            if (isAdd === 2) {
                await dispatch(fetchPutUrls(manualId, json, token));
            }
            if (isAdd === 3) {
                await dispatch(fetchDeleteUrls(manualId, token));
            }
            reset({
                name: '',
                url: '',
                description: '',
            });
            setSelectedFile(null);
            closeModal();
        } catch (error) {
            // Código para ejecutar si la acción falla
            // Puedes agregar lógica adicional en caso de error
        } finally {
            dispatch(fetchGetUrls(token, currentPage, limit));
            setFlag((prev) => !prev);
        }
    };
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const urls = useSelector((state: RootState) => state.urls.urls);
    const currentPage = useSelector((state: RootState) => state.urls.page);
    const totalPages = useSelector((state: RootState) => state.urls.totalPages);
    const loading = useSelector((state: RootState) => state.urls.loading);
    const limit = useSelector((state: RootState) => state.urls.limit);
    const error = useSelector((state: RootState) => state.urls.error);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isModalOpenDelete, setModalOpenDelete] = useState<boolean>(false);
    const [manualId, setManualId] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [isAdd, setisAdd] = useState<number>(0);
    const [method, setMethod] = useState<string>('');
    const [flag, setFlag] = useState<boolean>(false);
    const [responseValidation, setResponseValidation] = useState<boolean>(false);
    const [modalOpen, setModalCatchResult] = useState<boolean>(false);
    const [modalDirtyOpen, setModalDirtyOpen] = useState<boolean>(false);
    const {notAllowed} = useCheckAbilities();
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetUrls(token, value, limit));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetUrls(token, 1, event.target.value));
    };
    useEffect(() => {
        if (token) {
            dispatch(fetchGetUrls(token, currentPage, limit));
        }
    }, [dispatch, token]);

    useEffect(() => {
        if (!loading && flag) {
            if (error) {
                setResponseValidation(true);
                setModalCatchResult(true);
            } else if (!error) {
                setResponseValidation(false);
                setModalCatchResult(true);
            }
            setFlag((prev) => !prev);
        }
    }, [error, loading]);
    const openModal = () => {
        setModalOpen(true);
    };
    const openModalDelete = () => {
        setModalOpenDelete(true);
    };
    const addModal = () => {
        openModal();
        setisAdd(1);
        setMethod(`${t('configuration.urls.modals.new')}`);
    };
    const editModal = (item: any) => {
        openModal();
        setisAdd(2);
        setMethod(`${t('configuration.urls.modals.edit')}`);
        setManualId(item.id);
        reset({
            name: item.name,
            url: item.url,
            description: item.description,
        });
    };
    const deleteModal = (item: any) => {
        openModalDelete();
        setisAdd(3);
        setMethod(`${t('configuration.urls.modals.delete')}`);
        setUrl(item.name);
        setManualId(item.id);
    };
    const closeModal = () => {
        if (isDirty) {
            setModalDirtyOpen(true);
        } else {
            reset({
                name: '',
                url: '',
                description: '',
            });
            setModalOpen(false);
            setModalCatchResult(false);
        }
    };

    const setModalDirtyHandle = () => {
        reset({
            name: '',
            url: '',
            description: '',
        });
        setModalOpen(false);
        setModalCatchResult(false);
        setModalDirtyOpen(false);
    };

    const closeModalDelete = () => {
        setModalOpenDelete(false);
    };

    if (!hasConfiguration && notAllowed(FeatureCodeEnum.URL, URLenum.SEE_LIST)) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '27px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    URLs
                </Typography>
                {hasConfiguration && (
                    <Can I={URLenum.CREATE} a={FeatureCodeEnum.URL}>
                        <AddButton
                            onClick={addModal}
                            disabled={notAllowed(FeatureCodeEnum.URL, URLenum.CREATE)}
                        >
                            {t('configuration.urls.add_url_btn')}
                        </AddButton>
                    </Can>
                )}
            </Box>
            <TableContainerUrl
                container
                width={hasConfiguration ? 'calc(100vw - 330px)' : 'calc(100vw - 96px)'}
            >
                <Grid item xs={3}>
                    <TableCellHeadInitial>Link</TableCellHeadInitial>
                </Grid>
                <Grid item xs={3}>
                    <TableCellHead>{t('name')}</TableCellHead>
                </Grid>
                <Grid item xs={hasConfiguration ? 5 : 6}>
                    <TableCellHead>{t('description')}</TableCellHead>
                </Grid>
                {hasConfiguration && (
                    <Grid item xs={1}>
                        <TableCellHeadFinal>{t('actions')}</TableCellHeadFinal>
                    </Grid>
                )}
                {loading ? (
                    // Muestra el esqueleto de carga mientras se cargan los datos
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    urls.map((item: ITable, index: number) => (
                        <Grid key={index} container>
                            <Grid item xs={3} display="grid">
                                <TableCell sx={{padding: '10px 0 10px 10px'}}>
                                    <Link
                                        href={item.url}
                                        target="_blank"
                                        sx={{
                                            color: '#00ACFF',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {item.url}
                                        </Typography>
                                    </Link>{' '}
                                </TableCell>
                            </Grid>

                            <Grid item xs={3} display="grid">
                                <TableCell sx={{padding: '10px 0 10px 10px'}}>
                                    {' '}
                                    <Typography
                                        sx={{
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            fontSize: '13px',
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                </TableCell>
                            </Grid>
                            <Grid item xs={!hasConfiguration ? 6 : 5} display="grid">
                                <TableCell sx={{padding: '10px 0 10px 10px'}}>
                                    <Typography
                                        sx={{
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            fontSize: '13px',
                                        }}
                                    >
                                        {item.description}{' '}
                                    </Typography>
                                </TableCell>
                            </Grid>
                            {hasConfiguration && (
                                <Grid item xs={1} display="grid">
                                    <TableCellIcons sx={{padding: '10px 0 10px 10px'}}>
                                        {' '}
                                        <Can I={URLenum.EDIT} a={FeatureCodeEnum.URL}>
                                            <img
                                                onClick={() => {
                                                    editModal(item);
                                                }}
                                                src={IconEdit}
                                                title={t('manuals.modals.edit')}
                                            />
                                        </Can>{' '}
                                        <Can I={URLenum.DELETE} a={FeatureCodeEnum.URL}>
                                            <img
                                                onClick={() => {
                                                    deleteModal(item);
                                                }}
                                                src={IconDelete}
                                                title={t('manuals.modals.delete')}
                                            />
                                        </Can>
                                    </TableCellIcons>
                                </Grid>
                            )}
                        </Grid>
                    ))
                )}
            </TableContainerUrl>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <StyledModal open={isModalOpen} onClose={closeModal}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {method} url
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '20px 30px',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Controller
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={`${t('name')} *`}
                                    size="small"
                                />
                            )}
                            name="name"
                            control={control}
                        />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '14px',
                            }}
                        >
                            {errors?.name?.message}
                        </Typography>

                        <Controller
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Url *"
                                    size="small"
                                />
                            )}
                            name="url"
                            control={control}
                        />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '14px',
                            }}
                        >
                            {errors?.url?.message}
                        </Typography>
                        <Controller
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={`${t('description')} *`}
                                    multiline
                                    maxRows={4}
                                    size="small"
                                />
                            )}
                            name="description"
                            control={control}
                        />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '14px',
                            }}
                        >
                            {errors?.description?.message}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '56px',
                                alignItems: 'center',
                                marginTop: '20px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModal}>
                                {t('cancel').toUpperCase()}
                            </ButtonCancel>
                            <SaveButton disabled={loading}>
                                {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>
            <StyledModal open={isModalOpenDelete} onClose={closeModalDelete}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {method} url
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ImgIcon src={IconAlertDelete} />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 400,
                                marginBottom: '14px',
                                color: '#13111a',
                                textAlign: 'center',
                            }}
                        >
                            {`${t('configuration.urls.modals.sure_to_delete')} "${url}" ?`}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                height: '56px',
                                alignItems: 'center',
                                marginTop: '20px',
                                justifyContent: 'center',
                            }}
                        >
                            <ButtonCancel onClick={closeModalDelete}>
                                {t('cancel').toUpperCase()}
                            </ButtonCancel>
                            <SaveButton disabled={loading} onClick={onSubmitDelete}>
                                {loading ? <Loader size={24} /> : t('delete').toUpperCase()}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>
            {modalOpen && (
                <>
                    {responseValidation ? (
                        <WarningUpdateModal isModalOpen={modalOpen} closeModal={closeModal} />
                    ) : (
                        <SuccessUpdateModal isModalOpen={modalOpen} closeModal={closeModal} />
                    )}
                </>
            )}
            <ModalConfirmChanges
                open={modalDirtyOpen}
                setModalCancel={setModalDirtyOpen}
                closeCancel={setModalDirtyHandle}
            />
        </Box>
    );
};

export default Urls;
