export const getCountryId = (countries) => {
    return countries.map((item) => {
        return Number(item.id);
    });
};

export const getCountryName = (countries) => {
    return countries.map((item) => {
        return item.name;
    });
};

export function formatMoney(value, currency = 'MXN', sign = '$', decimal = 2) {
    const numericNumber = parseFloat(value);
    if (isNaN(numericNumber)) return '...';
    return `${sign}${parseFloat(value)
        .toFixed(decimal)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${currency}`;
}

export function formatDateToEsWithReducerDays(date, subtractDays = 0) {
    const newDate = new Date(date.getTime());
    if (subtractDays !== 0) {
        newDate.setDate(newDate.getDate() - subtractDays);
    }

    const day = newDate.getDate();
    const monthNumber = newDate.getMonth();
    const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];
    const monthName = months[monthNumber];
    const year = newDate.getFullYear();
    return `${day} de ${monthName} de ${year}.`;
}

export const getFileSize = ({size}) => {
    let newSize = size / 1024;
    if (newSize < 1024) {
        return `(${newSize.toFixed(2)} kB)`;
    } else {
        newSize = newSize / 2014;
        return `(${newSize.toFixed(2)} MB)`;
    }
};
