export function useFormatNumber(): (number: number) => string {
    return (number: number) => {
        return number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
}
