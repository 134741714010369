import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import PropTypes from 'prop-types';

import {Box, Typography, IconButton, useTheme} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    fetchPostFileXml3wm,
    ErrorType,
} from '@components/modelorama/redux/actions/ModeloramaActions';
import {
    MAX_FILE_SIZE_MB,
    ALLOWED_FILE_TYPES,
} from '@/components/orderSimply/utils/fileUploadConfig';
import {processFileName} from '@/components/orderSimply/utils/fileUploadHelpers';
import {useTranslation} from 'react-i18next';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import IconPDF from '@components/UI/atoms/icons/IconPDF.svg';
import IconXML from '@components/UI/atoms/icons/IconXML.svg';

import FileValidations from '@/components/orderSimply/components/FileValidations';
import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import {
    Nav,
    StyledModal,
    ButtonCancel,
    SaveButton,
    TypographyStyled400,
    TypographyStyled600,
    TypographyStyled500,
} from '@/components/faqs/StyledFAQ';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@/components/legalAgreements/StyledLegalAgreements';
const ModalFileUpload = ({
    isModalOpen,
    closeModal,
    closeAndReload,
    loading = false,
    type = '',
    purchaseOrderId = '',
    entrySheets = [],
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [value, setValue] = useState('1');
    const [errorFile, setErrorFile] = useState('');
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [showValidations, setShowValidations] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [ranValidations, setRanValidations] = useState(false);
    const [validationOk, setValidationOk] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const token = useSelector((state) => state.logins.accessToken);
    const errors = useSelector((state) => state.modelorama.errors);
    /**
     * @type {ErrorType}
     */
    const error = useSelector((state) => state.modelorama.error);

    const VALIDATION_NO_ERRORS = error === ErrorType.NONE;
    const VALIDATION_SUCCESS = ranValidations && VALIDATION_NO_ERRORS;
    const VALIDATION_SUCCESS_WITH_SAP_ERROR =
        ranValidations &&
        error === ErrorType.RULE &&
        Array.isArray(errors) &&
        errors.some((error) => error === 'sap.confirm_error');

    useEffect(() => {
        if (!loading && Array.isArray(errors) && errors.length > 0) {
            setValidationErrors(errors);
            setShowValidations(true);
            setValue('2');
        } else {
            setValidationErrors([]);
            setShowValidations(true);
        }
    }, [errors, loading, VALIDATION_SUCCESS, VALIDATION_SUCCESS_WITH_SAP_ERROR]);

    useEffect(() => {
        const newFileNames = files.map((file) => {
            if (file.name.length > 22) {
                return processFileName(file.name);
            }
            return file.name;
        });
        setFileNames(newFileNames);
    }, [files]);

    const schemaFormModal = yup.object({
        file: yup.mixed().required(t('modal_load_file.required_file')),
    });

    const {handleSubmit, control, reset} = useForm({
        resolver: yupResolver(schemaFormModal),
    });

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCloseAndReload = () => {
        resetData();
        closeAndReload();
    };

    const getSelectedFiles = (event, type) => {
        return type === 'drop' ? event.dataTransfer.files : event.target.files;
    };

    const handleFileChange = (event, type) => {
        event.stopPropagation();
        const uploadedFiles = getSelectedFiles(event, type);
        if (!uploadedFiles || uploadedFiles.length === 0 || uploadedFiles.length > 2) {
            setErrorFile(t('modal_load_file.invalid_file_count'));
            return;
        }

        const {newFiles, fileError} = validateSelectedFiles(uploadedFiles);

        if (fileError.length > 0) {
            setErrorFile(fileError);
            return;
        }

        setErrorFile('');
        const updatedFiles = updateFilesState(newFiles);
        const {isFullfilled, pendingExtension} = filesAnalysis(updatedFiles);

        if (isFullfilled) {
            setValue('2');
            setShowValidations(false);
        } else {
            setErrorFile(`${t('modal_load_file.penfile')} ${pendingExtension}.`);
            setValue('1');
        }
    };

    const updateFilesState = (newFiles) => {
        const stateFiles = [...files];

        newFiles.forEach((file) => {
            const existingFileIndex = files.findIndex((f) =>
                f.name.endsWith(file.name.split('.').pop() || '')
            );
            if (existingFileIndex !== -1) {
                stateFiles[existingFileIndex] = file;
            } else {
                stateFiles.push(file);
            }
        });

        setFiles(stateFiles);
        return stateFiles;
    };

    const filesAnalysis = (validFiles) => {
        const isFullfilled =
            validFiles.length === 2 && validFiles.some((f) => f.type === 'text/xml');
        const pdfCountFiles = validFiles.filter((f) => f.type === 'application/pdf').length;
        const pendingExtension = files.some((f) => f.type === 'text/xml') ? 'PDF' : 'XML';
        const totalFiles = validFiles.length;
        const xmlCountFiles = validFiles.filter((f) => f.type === 'text/xml').length;

        return {
            isFullfilled,
            pdfCountFiles,
            pendingExtension,
            totalFiles,
            xmlCountFiles,
        };
    };

    const validateSelectedFiles = (files) => {
        const newFiles = [];
        let fileError = '';
        let xmlCount = 0;
        let pdfCount = 0;

        Array.from(files).forEach((file) => {
            if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                fileError = t('modal_load_file.invalid_file');
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                fileError = t(
                    `${t('modal_load_file.file_size_exceeds')} ${MAX_FILE_SIZE_MB} MB. ${t(
                        'modal_load_file.smaller_file'
                    )}`
                );
                return;
            }
            if (file.type === 'text/xml') {
                xmlCount++;
            } else if (file.type === 'application/pdf') {
                pdfCount++;
            }
            newFiles.push(file);
        });

        return {newFiles, fileError, xmlCount, pdfCount};
    };

    const validateFile = async () => {
        try {
            const xml = files.find((f) => f.type === 'text/xml');
            const pdf = files.find((f) => f.type === 'application/pdf');

            dispatch(
                dispatch(fetchPostFileXml3wm(token, {xml, pdf}, purchaseOrderId, entrySheets))
            );

            setShowValidations(true);
            setRanValidations(true);
            setOpenModalSuccess(true);
            setValue('2');
        } catch (error) {
            setValidationOk(() => false);
            setRanValidations(false);
            setOpenModalSuccess(false);
        }
    };

    const onSubmit = async () => {
        //
    };

    const handleChangeDelete = (fileName) => {
        reset({file: null});
        const newFiles = fileNames.filter((name) => name !== fileName);
        setFileNames(newFiles);
        if (fileName.includes('.xml')) {
            setErrorFile(t('modal_load_file.xmlfile'));
        } else {
            setErrorFile(t('modal_load_file.pdffile'));
        }
        setFiles(files.filter((file) => file.name !== fileName));
        if (fileName.includes('.xml') || newFiles.length === 1) {
            handleCancelButton();
        }
    };

    const handleCancelButton = () => {
        if (value === '1') {
            handleCloseModal();
        } else if (value === '2') {
            setValidationErrors([]);
            handleTabChange(null, '1');
        }
    };

    const handleCloseModal = () => {
        resetData();
        closeModal();
    };

    const cleanup = () => {
        reset({file: null});
        setShowValidations(false);
        setValidationErrors([]);
        setValue('1');
    };

    const resetData = () => {
        cleanup();
        setErrorFile('');
        setFiles([]);
        setFileNames([]);
        setRanValidations(false);
        setValidationOk(false);
        setShowValidations(false);
    };

    // useEffect(() => {
    //     if (isUploadFile) {
    //         handleCloseAndReload();
    //         WarningToast({title: 'Archivos enviados con exito.'});
    //     }
    // }, [isUploadFile]);

    return (
        <>
            <StyledModal
                open={isModalOpen}
                onClose={() => closeModal}
                disableEscapeKeyDown={true}
                disableEnforceFocus={true}
            >
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '3px',
                        width: '665px',
                        height: '501px',
                        zIndex: '100',
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Nav>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 700,
                                    color: 'white',
                                    marginLeft: '10px',
                                }}
                            >
                                {t('components.load_invoice_btn.title')}
                            </Typography>
                        </Nav>
                        <TabContext value={value}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleTabChange}>
                                    <Tab
                                        disabled={validationOk}
                                        label={t('upload_files')}
                                        value="1"
                                        sx={{textTransform: 'none'}}
                                    />
                                    <Tab
                                        disabled={VALIDATION_SUCCESS || fileNames.length < 2}
                                        label={t('validation')}
                                        value="2"
                                        sx={{textTransform: 'none'}}
                                    />
                                </TabList>
                            </Box>
                            <TabPanel value="1" disabled={VALIDATION_SUCCESS}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Controller
                                        name="file"
                                        control={control}
                                        defaultValue={null}
                                        render={({field, fieldState}) => (
                                            <FileUploadContainer
                                                sx={{
                                                    minHeight: '230px',
                                                    margin: fieldState.error ? '0' : '10px 0',
                                                    borderColor: fieldState.error ? 'red' : 'none',
                                                }}
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    const file = e.dataTransfer.files[0];
                                                    if (file) field.onChange(file);
                                                    handleFileChange(e, 'drop');
                                                }}
                                                onClick={() =>
                                                    document.getElementById('fileInput')?.click()
                                                }
                                            >
                                                <StyledInput
                                                    id="fileInput"
                                                    type="file"
                                                    multiple="multiple"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file) field.onChange(file);
                                                        handleFileChange(e, 'change');
                                                    }}
                                                />
                                                {field.value && !errorFile ? (
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Typography variant="subtitle2">
                                                            {t('modal_load_file.last_file')}:{' '}
                                                            {field.value.name}
                                                        </Typography>
                                                        {!loading && (
                                                            <IconButton
                                                                disabled={validationOk}
                                                                aria-label="Eliminar"
                                                                onClick={(e) => {
                                                                    field.onChange(null);
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <StyledFileLabelButton
                                                            variant="outlined"
                                                            as="span"
                                                        >
                                                            <img src={CloudIcon} />
                                                            <StyledLabelFileUpload>
                                                                {t('modal_load_file.files_here')}
                                                            </StyledLabelFileUpload>
                                                        </StyledFileLabelButton>
                                                        <Typography variant="caption" color="error">
                                                            {errorFile}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </FileUploadContainer>
                                        )}
                                    />
                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <TypographyStyled400 sx={{fontSize: '16px'}}>
                                    {t('modal_load_file.following_files')}
                                </TypographyStyled400>
                                <FileUploadContainer
                                    sx={{
                                        height: '50px',
                                        cursor: 'default',
                                        marginTop: '15px',
                                    }}
                                >
                                    {fileNames && (
                                        <>
                                            {fileNames.map((fileName, index) => (
                                                <Box
                                                    key={index}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <img
                                                        src={
                                                            fileName.includes('xml')
                                                                ? IconXML
                                                                : IconPDF
                                                        }
                                                    />
                                                    <Typography
                                                        sx={{
                                                            color: 'rgba(0, 0, 0, 0.38)',
                                                            fontSize: '16px',
                                                            marginLeft: '5px',
                                                        }}
                                                    >
                                                        {fileName}
                                                    </Typography>
                                                    {!validationOk && (
                                                        <IconButton
                                                            aria-label="Eliminar"
                                                            onClick={() =>
                                                                handleChangeDelete(fileName)
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                </FileUploadContainer>
                                <TypographyStyled600 sx={{marginTop: '20px'}}>
                                    {t('modal_load_file.sumary')}
                                </TypographyStyled600>
                                <FileUploadContainer
                                    sx={{
                                        justifyContent: 'flex-start',
                                        height: '40px',
                                        marginTop: '10px',
                                        borderBottomStyle: 'none',
                                        borderBottomLeftRadius: '0',
                                        borderBottomRightRadius: '0',
                                    }}
                                >
                                    <TypographyStyled500 sx={{marginLeft: '30px'}}>
                                        {t('modal_load_file.element')}
                                    </TypographyStyled500>
                                    <TypographyStyled500 sx={{marginLeft: '110px'}}>
                                        {t('state')}
                                    </TypographyStyled500>
                                    <TypographyStyled500 sx={{marginLeft: '50px'}}>
                                        {t('modal_load_file.comments')}
                                    </TypographyStyled500>
                                </FileUploadContainer>
                                <FileUploadContainer
                                    sx={{
                                        borderTopLeftRadius: '0',
                                        borderTopRightRadius: '0',
                                        display: 'block',
                                        height: '80px',
                                        justifyContent: 'flex-start',
                                        overflow: 'hidden',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    <FileValidations
                                        errors={validationErrors}
                                        loading={loading}
                                        showValidations={showValidations}
                                        successMessage={t('validated_invoice')}
                                    />
                                </FileUploadContainer>
                            </TabPanel>
                        </TabContext>
                        <Box
                            sx={{
                                paddingX: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                                {ranValidations && validationErrors.length > 1 && value === '2' && (
                                    <StatusMessage
                                        message={`${t('modal_load_file.total_errors')}: ${
                                            validationErrors.length
                                        }`}
                                        color="#f50057"
                                    />
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '60px',
                                    alignItems: 'center',
                                    justifySelf: 'flex-end',
                                }}
                            >
                                <ButtonCancel
                                    sx={{
                                        color: validationOk ? '#D3D3D3' : null,
                                        border: `1.5px solid ${validationOk ? '#D3D3D3' : null}`,
                                    }}
                                    disabled={validationOk}
                                    onClick={() => handleCancelButton()}
                                >
                                    {value === '1'
                                        ? t('cancel').toUpperCase()
                                        : t('back').toUpperCase()}
                                </ButtonCancel>

                                {validationOk && (
                                    <SaveButton
                                        sx={{padding: '10px'}}
                                        onClick={() => handleCloseAndReload()}
                                    >
                                        {t('close').toUpperCase()}
                                    </SaveButton>
                                )}
                                {validationErrors.length === 0 && !showValidations ? (
                                    <SaveButton
                                        sx={{
                                            padding: '10px',
                                            display: `${validationOk ? 'none' : 'block'}`,
                                        }}
                                        disabled={files.length !== 2 || loading}
                                        onClick={() => validateFile()}
                                    >
                                        {t('send').toUpperCase()}
                                    </SaveButton>
                                ) : (
                                    <SaveButton
                                        sx={{padding: '10px'}}
                                        onClick={() => handleCloseAndReload()}
                                    >
                                        {t('close').toUpperCase()}
                                    </SaveButton>
                                )}
                            </Box>
                        </Box>
                    </form>
                </Box>
            </StyledModal>
            <SuccessUpdateModal
                isModalOpen={openModalSuccess}
                closeModal={() => {
                    setOpenModalSuccess(false);
                }}
                title={t('successValidation')}
                message=" "
            />
        </>
    );
};

ModalFileUpload.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    closeAndReload: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
    purchaseOrderId: PropTypes.string.isRequired,
    entrySheets: PropTypes.array,
    setLoadOrder: PropTypes.func,
};

export default React.memo(ModalFileUpload);
