import {IModule} from '@/components/roles/interface';
export const moveModule = (
    arr: IModule[],
    codeModule: string,
    codeModuleObjective: string
): IModule[] => {
    const indexMoveModule = arr.findIndex((module) => module.code === codeModule);
    const indexObjetiveModule = arr.findIndex((module) => module.code === codeModuleObjective);

    if (indexMoveModule === -1 || indexObjetiveModule === -1) {
        return arr;
    }

    const [element] = arr.splice(indexMoveModule, 1);

    const newIndex =
        indexMoveModule > indexObjetiveModule ? indexObjetiveModule + 1 : indexObjetiveModule;
    arr.splice(newIndex, 0, element);

    return arr;
};
