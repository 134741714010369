const spanishPrivacyPolicy = [
    {
        title: 'AVISO DE PRIVACIDAD INTEGRAL PROVEEDORES ',
        paragraphs: [
            `En cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “LFPD”), su Reglamento y otras disposiciones aplicables, y con el objeto de que pueda decidir sobre el tratamiento que podemos dar a su información, ponemos a su disposición este Aviso de Privacidad en el que explicamos cómo podemos usar sus datos personales, identificando a la entidad responsable; las finalidades que perseguimos; los terceros con quienes podemos o debemos compartir esta información; los mecanismos para que pueda ejercer sus derechos, entre otra información.`,
        ],
    },
    {
        title: '¿Quién es el responsable de sus datos personales?',
        paragraphs: [
            `Con respecto a sus datos personales, las siguientes entidades son Responsables individuales de los datos personales de los proveedores:`,
            {
                type: 'table',
                mergeCells: true,
                header: [
                    'DENOMINACIÓN DEL RESPONSABLE',
                    'DOMICILIO PARA OÍR Y RECIBIR NOTIFICACIONES',
                ],
                body: [
                    [
                        {value: 'Diblo Corporativo, S.A de C.V.'},
                        {
                            value: 'Cerrada Palomas 22-Piso 6, Colonia Reforma Social, Alcaldía Miguel Hidalgo, Código Postal 11650, Ciudad de México, México.',
                            rowSpan: 4,
                        },
                    ],
                    [
                        {
                            value: 'Multiservicios Nacionales Modelo, S. de R.L. de C.V. (conocido comercialmente como “Modeloramas”)',
                        },
                    ],
                    [
                        {
                            value: 'La Liga de la Cerveza, S. de R.L. de C.V. (conocido comercialmente como “TADA” o “Beerhouse”)',
                        },
                    ],
                    [
                        {
                            value: 'Cervecería Modelo De México, S. De R.L. de C.V. (conocido comercialmente como “BEES”)',
                        },
                    ],
                ],
            },
            `
            La identidad específica de la entidad que tratará sus datos personales (en adelante, el “Responsable”), consta en la documentación sobre el concurso en el que participa para ser proveedor o, en su caso, en la documentación sobre la contratación de sus productos o servicios.
            `,
        ],
    },
    {
        title: '¿Qué datos personales podemos recabar?',
        paragraphs: [
            `Para las finalidades indicadas en el presente Aviso de Privacidad, el Responsable podrá recabar las siguientes categorías de datos personales:
            
            ● Datos identificativos y de contacto.

            ● Datos identificativos y de contacto

            ● Datos académicos y profesionales.

            ● Datos laborales.

            ● Datos de información comercial y transacciones.

            ● Datos financieros y patrimoniales.`,
        ],
    },
    {
        title: '¿Solicitamos datos personales sensibles?',
        paragraphs: [
            `Le informamos que para cumplir con las finalidades señaladas en el presente Aviso de Privacidad el Responsable no solicitará ni tratará datos personales sensibles`,
        ],
    },
    {
        title: 'Datos personales de terceros',
        paragraphs: [
            `Si usted proporciona al Responsable datos personales de terceros (como referencias profesionales) para cumplir con las finalidades identificadas en este Aviso de Privacidad, es su responsabilidad informarles sobre el tratamiento de sus datos personales y proporcionarles el contenido de este Aviso de Privacidad. Al proporcionar datos personales de terceros, usted declara que ha obtenido previamente el consentimiento de los titulares para compartir su información con el Responsable, y que dichos datos son correctos y completos.`,
        ],
    },
    {
        title: '¿Para qué usaremos sus datos personales?',
        paragraphs: [
            `El Responsable podrá tratar los datos personales obtenidos para las siguientes finalidades:`,
        ],
    },
    {
        title: 'Finalidades primarias',
        paragraphs: [
            `
            1. Identificarlo como proveedor persona física o como representante de un proveedor persona moral.
            
            2. Gestión, control y administración del proceso de selección y alta de proveedores en los sistemas del Responsable.
            
            3. Administración y gestión para la elaboración de contratos.

            4. Gestión, control y administración de evaluaciones de calidad de servicios y/o productos de los proveedores conforme a los procedimientos establecidos por el Responsable.

            5. Gestión, control y administración de pago de servicios y/o productos proporcionados por proveedores.
            
            6. Cumplimiento de obligaciones de facturación y pagos relacionados con proveedores.
            
            7. Gestión, control y administración de los servicios y/o productos adquiridos por el Responsable de sus proveedores.
            
            8. Administración y gestión de documentos de proveedores bajo resguardo del Responsable.
            
            9. Gestión y control de comunicaciones entre el Responsable y sus proveedores.

            10. Garantizar el bienestar, salud y seguridad de todas las personas que desempeñen funciones y/o actividades dentro de las instalaciones.

            11. Cumplir con los lineamientos, protocolos y/o medidas internas de seguridad aplicables a las personas que ingresen a las instalaciones.

            12 .En su caso, alta de proveedores en los sistemas del Responsable.
            
            13. Gestión de procesos de debida diligencia o “Due Diligence”, incluyendo revisión de antecedentes en materia de anticorrupción mediante la implementación de procedimientos administrativos y técnicos establecidos para tales efectos.

            14. Estadística y registro histórico de proveedores.

            15. Para el cumplimiento de obligaciones previstas en la normativa aplicable.
            
            `,
            `Finalidades secundarias: No existen.`,
        ],
    },
    {
        title: '¿Con quién podremos compartir sus datos?',
        paragraphs: [
            `Sus datos personales podrán ser transferidos dentro de México o hacia el extranjero, a las siguientes categorías de destinatarios y para las finalidades identificadas:`,
            `● Compañías matrices, accionistas, afiliadas, subsidiarias y/o cualquier sociedad del mismo grupo corporativo del Responsable que operen bajo los mismos procesos y políticas internas, con finalidades de gestión, administración y resguardo centralizado de información, incluyendo acciones de control de altas, bajas y actualización de datos en los expedientes de proveedores; investigación de denuncias comunicadas a través de cualquier medio, incluida la línea de ayuda del Responsable, así como fines estadísticos y de registro histórico de proveedores.`,
            `● Administraciones y autoridades federales, estatales y/o municipales; comisiones; institutos, entidades reguladoras; autoridades judiciales o administrativas; y entidades gubernamentales nacionales y/o extranjeras, para el cumplimiento de obligaciones informativas, de transparencia y de prevención de fraudes, delitos u otras actividades ilícitas, así como para el cumplimiento de requerimientos judiciales o administrativos emitidos por autoridades competentes.`,
            `● Bancos u otras entidades financieras, para el cumplimiento de obligaciones de pago.`,
            `La Ley establece que las transferencias de datos antes indicadas no requieren de su consentimiento para poder ser efectuadas, pero el Responsable sí está obligado a informar sobre ellas. Cualquier transferencia de sus datos personales que sí requiera de su consentimiento será informada previamente, a través de la comunicación y actualización de este Aviso de Privacidad y de forma previa a la realización de esa comunicación de datos.`,
        ],
    },
    {
        title: 'Derechos ARCO',
        paragraphs: [
            `Los titulares de datos personales (proveedores) podrán ejercer en todo momento sus derechos ARCO, los cuales comprenden:`,
            '● Acceso: el derecho que tiene a conocer qué datos tenemos de usted, así como para saber cómo los usamos o compartimos.',
            '● Rectificación: el derecho que tiene para solicitar en todo momento la rectificación de sus datos, si por cualquier motivo están incorrectos, inexactos o incompletos en nuestras bases de datos.',
            '● Cancelación: el derecho a pedir que eliminemos su información, a partir de su bloqueo y posterior supresión definitiva',
            `● Oposición: el derecho que tiene a indicar una causa legítima por la cual debemos dejar de usar sus datos personales`,
            `Para ejercer cualquiera de los Derechos ARCO, deberá presentar una solicitud dirigida a nuestro Departamento de Datos Personales, a través del correo electrónico: datos.legal@ab-inbev.com. `,
            `La solicitud deberá contener o estar acompañada de:
            
                1.Su nombre y domicilio completos, u otro medio para comunicarle la respuesta a su solicitud.

                2.Copia de un documento que acredite su identidad y, en su caso, la representación legal si alguien ejerce el derecho en su nombre o desea ejercerlo en nombre de una persona fallecida.

                3.Una descripción clara y precisa del derecho que quiere ejercer y de los datos personales relacionados con su solicitud; y
                
                4.En su caso, cualquier otra información o documento que nos ayude a localizar los datos personales motivo de su solicitud.
            `,
            `El Responsable responderá a su solicitud dentro de los 20 (veinte) días hábiles siguientes a la fecha en que sea enviada y recibida. Si la solicitud resulta procedente, la haremos efectiva dentro de los 15 (quince) días hábiles siguientes a la fecha en que comunique la respuesta. En caso de que la información y/o documentación proporcionada en su solicitud resulten incompletas, erróneas y/o insuficientes, o bien, no se acompañen los documentos necesarios para acreditar su identidad o la representación legal correspondiente, le solicitaremos la corrección y subsanación de las deficiencias para poder dar trámite a dicha solicitud. Usted contará con 10 (diez) días hábiles para atender el requerimiento y corrección de la solicitud; en caso contrario ésta se tendrá por no presentada.`,
            `El uso de medios electrónicos para el ejercicio de los Derechos ARCO autoriza al Responsable para dar respuesta a la solicitud correspondiente a través del mismo medio, salvo que usted nos indique otro medio de contacto en su solicitud, de forma clara y expresa`,
            `El derecho de cancelación no es absoluto. Por favor tome en cuenta que el Responsable debe conservar información para cumplir con diversas obligaciones legales y que para hacerlo puede compartir sus datos personales con otras entidades u organismos. En tales casos, es posible que el derecho de cancelación deba solicitarse ante la entidad que recibió sus datos personales.`,
        ],
    },
    {
        title: '¿Quiere revocar su consentimiento?',
        paragraphs: [
            `En algunos casos, podrá revocar el consentimiento para el tratamiento de sus datos personales; sin embargo, esta revocación no puede tener efectos retroactivos, es decir, no puede afectar a situaciones, trámites o transferencias realizadas antes de la revocación de su consentimiento; así como tampoco en los casos en que dicha revocación suponga el incumplimiento de disposiciones generales de orden público que establezcan la obligación de mantener el tratamiento de sus datos personales durante determinado período`,
            `Si decide solicitar la revocación de su consentimiento, puede enviar una solicitud al correo electrónico datos.legal@ab-inbev.com, siguiendo las instrucciones aplicables al ejercicio de los Derechos ARCO`,
        ],
    },
    {
        title: `¿Cómo puede limitar el uso y divulgación de sus datos personales?`,
        paragraphs: [
            `Usted puede limitar el uso o divulgación de sus datos personales dirigiendo la solicitud correspondiente a nuestro Departamento de Datos Personales, siguiendo para tales efectos el procedimiento y las instrucciones aplicables al ejercicio de los Derechos ARCO.`,
            `El Responsable cuenta con medios y procedimientos para asegurar la inclusión de algunos de sus datos en listados de exclusión propios, para evitar que sus datos sean divulgados. En tales casos, otorgaremos a los titulares que soliciten su registro, la constancia de inscripción correspondiente.`,
        ],
    },
    {
        title: `Cambios a este Aviso de Privacidad`,
        paragraphs: [
            `El Responsable se reserva el derecho de modificar, actualizar, extender y/o cambiar el contenido y alcance de este Aviso de Privacidad en cualquier momento y bajo total discreción. En todo momento, podrá solicitar una copia de la última versión de este Aviso de Privacidad al Departamento de Datos Personales del Responsable a través del correo electrónico: datos.legal@ab-inbev.com.`,
        ],
    },
    {
        title: `Dudas o aclaraciones`,
        paragraphs: [
            `En caso de tener dudas o aclaraciones sobre el presente Aviso de Privacidad, puede contactar a nuestro Departamento de Datos Personales a través del correo electrónico: datos.legal@ab-inbev.com.`,
        ],
    },
];

const englishPrivacyPolicy = [
    {
        title: 'COMPREHENSIVE PRIVACY POLICY SUPPLIERS',
        paragraphs: [
            `In compliance with the Federal Law for the Protection of Personal Data in Possession of Private Parties or Ley Federal de Protección de Datos Personales en Posesión de los Particulares (hereinafter referred to as “LFPD”), in its Regulations and other applicable provisions, and with the purpose of allowing you to decide on your information, we provide you with this Privacy Notice in which we explain how we may use your personal data, identifying the responsible entity, the purposes we pursue, the third parties with whom we may or must share this information, the mechanisms for you to exercise your rights amongst other information.`,
        ],
    },
    {
        title: 'Who is responsible for your personal data?',
        paragraphs: [
            `With respect to your personal data, the following entities are individually responsible for suppliers’ personal data:`,
            {
                type: 'table',
                header: ['RESPONSIBLE PARTY’S NAME', 'ADDRESS TO HEAR AND RECEIVE NOTIFICATIONS'],
                mergeCells: true,
                body: [
                    [
                        {value: 'Diblo Corporativo, S.A de C.V.'},
                        {
                            value: 'Cerrada Palomas 22-Piso 6, Colonia Reforma Social, Alcaldía Miguel Hidalgo, Código Postal 11650, Ciudad de México, México.',
                            rowSpan: 4,
                        },
                    ],
                    [
                        {
                            value: 'Multiservicios Nacionales Modelo, S. de R.L. de C.V. (conocido comercialmente como “Modeloramas”)',
                        },
                    ],
                    [
                        {
                            value: 'La Liga de la Cerveza, S. de R.L. de C.V. (conocido comercialmente como “TADA” o “Beerhouse”)',
                        },
                    ],
                    [
                        {
                            value: 'Cervecería Modelo De México, S. De R.L. de C.V. (conocido comercialmente como “BEES”)',
                        },
                    ],
                ],
            },

            `
            The specific identity of the entity that will process your personal data, (hereinafter, the “Responsible Party”), is stated in the documentation on the contest you are participating in to become a supplier, or, as the case may be, in the documentation on the contracting of your products and services.
            `,
        ],
    },
    {
        title: 'What personal data can we collect?',
        paragraphs: [
            `For the purposes mentioned in the present Privacy Policy, the Responsible Party may collect the following categories of personal data:
            
            ● Identification and contact information.

            ● Academic and professional data.

            ● Employement data.

            ● Data regarding commercial information and transactions.

            ● Financial and patrimonial data.`,
        ],
    },
    {
        title: 'Do we request sensitive personal data?',
        paragraphs: [
            `We inform you that to comply with the purposes mentioned in the present Privacy Policy, the Responsible Party will not request or process sensitive personal data.`,
        ],
    },
    {
        title: 'Personal data pertaining to Third Parties ',
        paragraphs: [
            `If you provide the Responsible Party with personal data pertaining to third parties (such as professional references) to comply with the purposes mentioned in the present Privacy Policy, it is your responsibility to inform them of the process of their personal data and provide them with this Privacy Policy. By providing personal data of third parties, you declare that you previously obtained the consent of the owners to share their information with the Responsible Party, and that such data is complete and correct.`,
        ],
    },
    {
        title: 'What will we use your personal data for?',
        paragraphs: [
            `The Responsible Party can process the personal data obtained for the following purposes:`,
        ],
    },
    {
        title: 'Primary Purposes:',
        paragraphs: [
            `
            1. Identify you as an individual supplier or as a representative of a legal entity.
            
            2. Management, control, and administration of the selection process and the registration of suppliers in the Responsible Party’s systems.

            3.Administration and management for the preparation of contracts.

            4.Management, control, and administration of quality evaluations of products and/or services of the suppliers according to the procedures establish by the Responsible Party.

            5.Management, control, and administration of quality evaluations of products and/or services of the suppliers according to the procedures established by the Responsible Party.

            6.Fulfillment of invoicing and payment obligations related to suppliers.

            7.Management, control, and administration of products and/or services acquired by the Responsible Party’s custody.

            8.Administration and management of suppliers’ documents under the Responsible’s custody.

            9.Management and control of communications between the Responsible Party and its suppliers.

            10.Ensuring the welfare, health, and safety of all persons performing functions and/or activities within the facilities.

            11.Comply with the guidelines, protocols, and/or internal security measures applicable to persons entering the facilities.

            12.If applicable, registration of suppliers in the Responsible Party’s systems.

            13.Management of due diligence processes, including background checks on anti-corruption matters though the implementation of administrative or technical procedures established for such purposes.

            14.Statistics and historical record of suppliers.

            15.For compliance with the obligations set forth in the applicable regulations,
            
            `,
            `Secondary purposes: Does not apply.`,
        ],
    },
    {
        title: 'Who can we share your data with?',
        paragraphs: [
            `Your personal data may be transferred within Mexico or abroad, in the following categories and with the purposes mentioned:`,
            `● Parent companies, shareholders, affiliates, subsidiaries and/or any company of the same corporate group of the Responsible Party that operate under the same internal processes and policies, for purposes of management, administration, and centralized safeguarding of information, including action of control of registrations, cancellations, and updating of data in the suppliers’ files; investigation of complaints communicated through any means, including the Responsible Party’s helpline, as well as statistical purposes and historical record of suppliers.`,
            `● Federal, State, and/or Municipal Administrations and Authorities, Commissions; Institutes, Regulatory Entities, Judicial or Administrative Authorities; and National and/or Foreign Governmental Entities, for compliance with reporting obligations, transparency, and prevention of fraud, crimes or other unlawful activities, as well as for compliance with judicial or administrative requirements issued by competent authorities`,
            `● Banks or other financial institutions, for the fulfillment of payment obligations.`,
            `The Law establishes that the aforementioned data transfers do not require your consent in order to be carried out, but the Responsible Party is obligated to inform about them. Any transfer of your personal data the does require your consent will be previously informed, through the communication and updating of this Privacy Notice and prior to the realization of such data communication.`,
        ],
    },
    {
        title: 'ARCO Rights',
        paragraphs: [
            `The holder of personal data (suppliers) may at any time exercise their ARCO rights, which include:`,
            '● Access: the right to know what data we have about you, as well as to know how we use or share it.',
            '● Rectification: the right to request at any time the rectification of your data, if for any reason they are incorrect, inaccurate or incomplete in our databases.',
            '● Cancellation: the right to request that we delete your information, starting with its blocking and subsequent definitive deletion.',
            `● Opposition: the right to indicate a legitimate reason why we should stop using your personal data.`,
            `To exercise any of the ARCO Rights, you must submit a request addressed to our Personal Data Department, via e-mail: datos.legal@ab-inbev.com.`,
            `The request must contain or be accompanied by:
            
                1. Your full name and address, or other means to communicate the response to your request.

                2. Copy of a document proving your identity and, if applicable, legal representation if someone is exercising the right on your behalf or wishes to exercise the right on behalf of a deceased person.

                3. A clear and precise description of the right you wish to exercise and of the personal data related to your request; and
                
                4. If the case, any other information, or documents that will assist us in locating the personal data that is the subject of your request.
            `,
            `The Responsible Party will respond to your request within 20 (twenty) business days from the date it is sent and received. If the request is successful, it will be effective within 15 (fifteen) business days following the date on which the response is communicated. In case the information and/or documentation provided in your request is incomplete, erroneous, and/or insufficient, or if the necessary documents to prove your identity or the corresponding legal representation is not attached, we will request that it corrected and remedy the deficiencies in order to process the request. You will have 10 (ten) business days to complete the requirement and correct the request; otherwise, it will be considered as not submitted.`,
            `The use of electronic means for the exercise of ARCO Rights authorizes the Responsible Party to respond to the corresponding request through the same means, unless you clearly and expressly indicate another form of contact in your request.`,
            `The cancellation right is not absolute. Please note that the Responsible Party must retain information to comply with various legal obligations and may share your personal data with other entities or organizations to do so. In such cases, the cancellation right may need to be requested from the entity that received your personal data.`,
        ],
    },
    {
        title: 'Do you wish to withdraw your consent?',
        paragraphs: [
            `In some cases, you may withdraw your consent to the processing of your personal data; however, this withdrawal cannot have retroactive effects, that is, it cannot affect situations, procedures or transfers carried out prior to the withdrawal of your consent; nor in cases where such withdrawal involves the breach of general provisions of public order that establish the obligation to maintain the processing of your personal data for a determined period of time.`,
            `If you request the withdrawal of your consent, you can send a request to the following e-mail address: datos.legal@ab-inbev.com, following the applicable instructions to exercise your ARCO Rights.`,
        ],
    },
    {
        title: `How can you limit the use and disclosure of your personal data?`,
        paragraphs: [
            `You may limit the use or disclosure of your personal data by directing the corresponding requests to our Personal Data Department, following such purposes, the procedure, and instructions applicable to the exercise of the ARCO Rights.`,
            `The Responsible Party has the means and procedures to ensure the inclusion of some of your data in its own exclusion lists, to prevent your data from being disclosed. In such cases, we will provide the owners who request their registration with the corresponding registration certificate`,
        ],
    },
    {
        title: `Changes to this Privacy Policy`,
        paragraphs: [
            `The Responsible Party reserves the right to modify, update, extend, and/or change the content and scope of this Privacy Policy at any time and at its sole discretion. At any time, you may request a copy of the latest version of this Privacy Policy from the Responsible Party’s Personal Data Department, via e-mail: datos.legal@ab-inbev.com.`,
        ],
    },
    {
        title: `Questions or clarifications`,
        paragraphs: [
            `If you have any questions or desire any clarifications regarding this Privacy Policy, you can contact our Personal Data Department via e-mail: datos.legal@ab-inbev.com.`,
        ],
    },
];
export {englishPrivacyPolicy, spanishPrivacyPolicy};
