import {
    GET_PURCHASE_ORDERS,
    GET_PURCHASE_ORDERS_ERROR,
    GET_PURCHASE_ORDERS_SUCCESS,
    GET_PO_LINE_ITEMS,
    GET_PO_LINE_ITEMS_ERROR,
    GET_PO_LINE_ITEMS_SUCCESS,
    SELECT_ALL_PURCHASE_ORDERS,
    SELECT_PURCHASE_ORDERS,
    SELECT_EXTERNAL_IDS,
    SHOW_TERMS_MODAL,
    HIDE_TERMS_MODAL,
    CLOSE_PO_ACCORDION,
} from '@/components/terms/redux/types/TermsAcceptanceTypes';

const initialState = {
    detailsData: [],
    detailsLimit: 10,
    detailsLoading: false,
    detailsPage: 1,
    detailsTotalPages: 1,
    error: false,
    filters: {},
    loadingFile: false,
    orders: [],
    ordersLimit: 10,
    ordersLoading: false,
    ordersPage: 1,
    ordersTotalPages: 1,
    allPurchaseOrders: false,
    selectedPurchaseOrders: [],
    selectedExternalIds: [],
    showTermsModal: false,
    closeAccordion: () => {},
};

const poTermsAcceptance = (state = initialState, action) => {
    switch (action.type) {
        case GET_PURCHASE_ORDERS:
            return {
                ...state,
                ordersLoading: true,
                ordersPage: action.value.page,
                ordersLimit: action.value.limit,
                filters: action.value.filters,
                detailsData: [],
            };
        case GET_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.value.data,
                ordersTotalPages: action.value.metadata.totalPages,
                ordersLoading: false,
            };
        case GET_PURCHASE_ORDERS_ERROR:
            return {
                ...state,
                error: action.value,
                ordersLoading: false,
            };
        case GET_PO_LINE_ITEMS:
            return {
                ...state,
                detailsLoading: true,
                detailsPage: action.value.page,
                detailsLimit: action.value.limit,
            };
        case GET_PO_LINE_ITEMS_SUCCESS:
            return {
                ...state,
                detailsData: action.value.data,
                detailsTotalPages: action.value.metadata.totalPages,
                detailsLoading: false,
            };
        case GET_PO_LINE_ITEMS_ERROR:
            return {
                ...state,
                error: action.value,
                detailsLoading: false,
            };
        case SELECT_ALL_PURCHASE_ORDERS:
            return {
                ...state,
                allPurchaseOrders: action.value,
            };
        case SELECT_PURCHASE_ORDERS:
            return {
                ...state,
                selectedPurchaseOrders: action.value,
            };
        case SELECT_EXTERNAL_IDS:
            return {
                ...state,
                selectedExternalIds: action.value,
            };
        case SHOW_TERMS_MODAL: {
            return {
                ...state,
                showTermsModal: true,
            };
        }
        case HIDE_TERMS_MODAL: {
            return {...state, showTermsModal: false};
        }
        case CLOSE_PO_ACCORDION: {
            return {...state, closeAccordion: action.value};
        }
        default:
            return state;
    }
};

export default poTermsAcceptance;
