import {
    GET_FAQS,
    GET_FAQS_SUCCESS,
    GET_FAQS_ERROR,
    POST_FAQS,
    POST_FAQS_SUCCESS,
    POST_FAQS_ERROR,
    UPDATE_FAQS,
    UPDATE_FAQS_SUCCESS,
    UPDATE_FAQS_ERROR,
    DELETE_FAQS,
    DELETE_FAQS_SUCCESS,
    DELETE_FAQS_ERROR,
} from '@/components/faqs/redux/types/FAQsTypes';

import axios from 'axios';

export const getFaqs = () => {
    return {
        type: GET_FAQS,
    };
};

export const getFaqsSuccess = (result) => {
    return {
        type: GET_FAQS_SUCCESS,
        value: result,
    };
};

export const getFaqsError = (error, result) => {
    return {
        type: GET_FAQS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetFaqs(token, page, limit) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getFaqs());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/faqs?limit=${limit}&page=${page}&sortOrder=DESC`,
                {headers}
            );
            await dispatch(getFaqsSuccess(response.data));
            await dispatch(getFaqsError(false));
        } catch (error) {
            await dispatch(getFaqsError(true));
        }
    };
}
export const postFaqs = () => {
    return {
        type: POST_FAQS,
    };
};

export const postFaqsSuccess = (result) => {
    return {
        type: POST_FAQS_SUCCESS,
        value: result,
    };
};

export const postFaqsError = (error, result) => {
    return {
        type: POST_FAQS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostFaqs(token, data) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postFaqs());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v2/faqs`, data, {
                headers,
            });
            await dispatch(postFaqsSuccess(response.data.data));
            await dispatch(fetchGetFaqs(token, 1, 10));
            await dispatch(postFaqsError(false));
        } catch (error) {
            await dispatch(postFaqsError(true));
        }
    };
}

export const putFaqs = () => {
    return {
        type: UPDATE_FAQS,
    };
};

export const putFaqsSuccess = (result) => {
    return {
        type: UPDATE_FAQS_SUCCESS,
        value: result,
    };
};

export const putFaqsError = (error, result) => {
    return {
        type: UPDATE_FAQS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutFaqs(faqIds, token, data) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putFaqs());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/faqs/${faqIds}`,
                data,
                {headers}
            );
            await dispatch(putFaqsSuccess(response.data.data));
            await dispatch(fetchGetFaqs(token, 1, 10));
            await dispatch(putFaqsError(false));
        } catch (error) {
            await dispatch(putFaqsError(true));
        }
    };
}

export const deleteFaqs = () => {
    return {
        type: DELETE_FAQS,
    };
};

export const deleteFaqsSuccess = (result) => {
    return {
        type: DELETE_FAQS_SUCCESS,
        value: result,
    };
};

export const deleteFaqsError = (error, result) => {
    return {
        type: DELETE_FAQS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchDeleteFaqs(faqIds, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(deleteFaqs());
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/v2/faqs`, {
                data: faqIds,
                headers: headers,
            });
            await dispatch(deleteFaqsSuccess(response.data.data));
            await dispatch(fetchGetFaqs(token, 1, 10));
            await dispatch(deleteFaqsError(false));
        } catch (error) {
            await dispatch(deleteFaqsError(true));
        }
    };
}
