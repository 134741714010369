import {TypographyStyled500} from '@/components/faqs/StyledFAQ';
import {getCfdiValidation} from '@/components/orderSimply/utils/cfdiValidations';
import {Box} from '@mui/system';
import React from 'react';
import {useTranslation} from 'react-i18next';

type ValidationErrorProps = {
    errorInfo: ErrorInfo;
};

export interface ErrorInfo {
    poReferenceId: number;
    entrySheets: EntrySheet[];
}

export interface EntrySheet {
    entrySheet: number;
    errors: any[];
}

const ValidationErrorMassive: React.FC<ValidationErrorProps> = ({errorInfo}) => {
    const {t} = useTranslation();
    const {poReferenceId, entrySheets} = errorInfo;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                minHeight: '20px',
                padding: '10px',
                borderBottom: '1px solid grey',
            }}
        >
            <TypographyStyled500 sx={{marginLeft: '10px', minWidth: '180px'}}>
                <span> {poReferenceId}</span>
            </TypographyStyled500>
            <TypographyStyled500
                sx={{marginLeft: '10px', minWidth: '180px', flexDirection: 'column', gap: '20px'}}
            >
                {entrySheets.map((item) => (
                    <div key={item.entrySheet}>{item.entrySheet}</div>
                ))}
            </TypographyStyled500>

            <TypographyStyled500
                sx={{marginLeft: '10px', display: 'flex', flexDirection: 'column', gap: '20px'}}
            >
                {entrySheets.map((item) =>
                    item.errors.map((error, index) => {
                        const errorInfo = getCfdiValidation(error);
                        return (
                            <span key={index}>
                                ○ {t(errorInfo.description) ?? errorInfo.description}
                            </span>
                        );
                    })
                )}
            </TypographyStyled500>
        </Box>
    );
};

export default ValidationErrorMassive;
