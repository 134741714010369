export const URL = Object.freeze({
    urlLogin: {
        Login: 'Login',
    },
});

export const extractLanguage = (content: string) => {
    const regex = /\[es\](.*?)\[\/es\]\[en\](.*?)\[\/en\]/;
    const result = regex.exec(content);
    if (!result) {
        return {es: content, en: content};
    }

    const es = result ? result[1] : '';
    const en = result ? result[2] : '';
    return {es, en};
};

export const traslateColumnValue = (field: string, language: string) => {
    return extractLanguage(field)[language as keyof typeof extractLanguage] ?? field;
};
