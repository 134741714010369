import React from 'react';
import {Box} from '@mui/system';
import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import ValidationErrorMassive from '@/components/orderSimply/components/ValidationErrorMassive';
import IconEstatusOk from '@components/UI/atoms/icons/IconEstatusOk.svg';
import IconLoading from '@components/UI/atoms/icons/IconLoading.svg';

interface FileValidationsProps {
    errors: any[];
    loading: boolean;
    showValidations: boolean;
    successMessage: string;
    sx?: object;
}

const FileValidationsMassive: React.FC<FileValidationsProps> = ({
    errors,
    showValidations,
    successMessage,
    sx,
    loading,
}) => {
    return (
        <Box
            sx={{
                padding: errors.length > 1 ? 0 : '10px',
                paddingTop: errors.length > 0 ? '10px' : '20px',
                ...sx,
            }}
        >
            {loading ? (
                <StatusMessage message="Cargando..." iconSrc={IconLoading} color="#3276c3" />
            ) : (
                <>
                    {showValidations && errors.length === 0 && (
                        <StatusMessage
                            message={successMessage}
                            iconSrc={IconEstatusOk}
                            color="#18BA81"
                        />
                    )}
                    {showValidations &&
                        errors?.length > 0 &&
                        errors?.map((error) => {
                            return (
                                <React.Fragment key={error.poReferenceId}>
                                    <ValidationErrorMassive errorInfo={error} />
                                </React.Fragment>
                            );
                        })}
                </>
            )}
        </Box>
    );
};

export default FileValidationsMassive;
