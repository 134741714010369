export const GET_FAQS = 'GET_FAQS';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_ERROR = 'GET_FAQS_ERROR';
export const POST_FAQS = 'POST_FAQS';
export const POST_FAQS_SUCCESS = 'POST_FAQS_SUCCESS';
export const POST_FAQS_ERROR = 'POST_FAQS_ERROR';
export const UPDATE_FAQS = 'UPDATE_FAQS';
export const UPDATE_FAQS_SUCCESS = 'UPDATE_FAQS_SUCCESS';
export const UPDATE_FAQS_ERROR = 'UPDATE_FAQS_ERROR';
export const DELETE_FAQS = 'DELETE_FAQS';
export const DELETE_FAQS_SUCCESS = 'DELETE_FAQS_SUCCESS';
export const DELETE_FAQS_ERROR = 'DELETE_FAQS_ERROR';
