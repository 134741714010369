import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {AppDispatch, RootState} from '@/config/store';
import AvatarWithName from '@components/UI/molecules/avatar/AvatarWithName';
import {TextButtonDetails, TextSuccess} from '@components/UI/molecules/textButton/textButtons';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {
    fetchGetTermsConditions,
    getTermsConditionsDetailsName,
} from '@components/log/redux/actions/TermsConditionActions';
import {IFormTermConditions, ITableTerms} from '@log/interface';
import {Grid} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
export interface ITableTermsConditionsProps {
    json: any;
    watchForm: IFormTermConditions;
}

const TableTermsConditions: React.FC<ITableTermsConditionsProps> = ({watchForm, json}) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const termsConditions = useSelector((state: RootState) => state.binnacles.termsConditions);
    const totalPagesTerms = useSelector((state: RootState) => state.binnacles.totalPagesTerms);
    const loadingTerms = useSelector((state: RootState) => state.binnacles.loadingTerms);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const limitTerms = useSelector((state: RootState) => state.binnacles.limitTerms);
    const currentPageTerms = useSelector((state: RootState) => state.binnacles.pageTerms);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const user = watchForm.user ?? '';
        dispatch(fetchGetTermsConditions(token, value, limitTerms, {...json, user}));
    };
    const handleChangeRowsPerPage = (event: any) => {
        const user = watchForm.user ?? '';
        dispatch(fetchGetTermsConditions(token, 1, event.target.value, {...json, user}));
    };

    return (
        <>
            <TableContainerBinnance container width={'calc(100vw - 330px)'}>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={4} sx={{boxShadow: 0}}>
                        <TableCellHeadInitial>
                            {t('terms_conditions.table.headers.supplier')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('terms_conditions.table.headers.date_accept_terms_and_privacy')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('terms_conditions.table.headers.last_date_accept')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('terms_conditions.table.headers.role')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('terms_conditions.table.headers.actions')}</TableCellHead>
                    </Grid>
                </TableHeadRow>
                {loadingTerms ? (
                    // Muestra el esqueleto de carga mientras se cargan los datos
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    termsConditions?.map((item: ITableTerms) => (
                        <>
                            <Grid item xs={4}>
                                <TableCell>
                                    <AvatarWithName name={item.name} />
                                </TableCell>
                            </Grid>

                            <Grid item xs={2}>
                                <TableCell>
                                    {DateTime.fromISO(item.confirmedDate).toFormat('dd/MM/yyyy')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>
                                    {DateTime.fromISO(item.confirmedDate).toFormat('dd/MM/yyyy')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>
                                    {item.roleName && (
                                        <TextSuccess
                                            sx={{
                                                fontSize: '13px',
                                                backgroundColor: '#4986e8',
                                                color: '#fff',
                                            }}
                                        >
                                            {item.roleName}
                                        </TextSuccess>
                                    )}
                                </TableCell>
                            </Grid>

                            <Grid item xs={2}>
                                <TableCellIcons>
                                    <TextButtonDetails
                                        onClick={() => {
                                            // dispatch(getLastModuleRender('binnacles'));
                                            dispatch(
                                                getTermsConditionsDetailsName(
                                                    item.name,
                                                    item.externalId
                                                )
                                            );
                                        }}
                                    >
                                        {t('view_details')}
                                    </TextButtonDetails>
                                </TableCellIcons>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPagesTerms}
                currentPage={currentPageTerms}
                handleChange={handleChange}
                limit={limitTerms}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
};

export default TableTermsConditions;
