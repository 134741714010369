import {
    DOWNLOAD_BILLING_STATEMENT,
    DOWNLOAD_BILLING_STATEMENT_ERROR,
    DOWNLOAD_BILLING_STATEMENT_SUCCESS,
    GET_SOCIETIES,
    GET_SOCIETIES_ERROR,
    GET_SOCIETIES_SUCCESS,
    GET_SOCIETY_DETAILS,
    GET_SOCIETY_DETAILS_ERROR,
    GET_SOCIETY_DETAILS_SUCCESS,
} from '@components/billingStatement/redux/types/BillingStatementTypes';

const initialState = {
    societiesPage: 1,
    societiesLimit: 10,
    societiesTotalPages: 1,
    societiesData: [],
    societiesLoading: false,
    filters: {},
    society: null,
    detailsPage: 1,
    detailsLimit: 10,
    detailsTotalPages: 1,
    detailsData: [],
    detailsLoading: false,
    error: false,
    loadingFile: false,
    supplier: null,
};

const billingStatement = (state = initialState, action) => {
    switch (action.type) {
        case GET_SOCIETIES:
            return {
                ...state,
                societiesLoading: true,
                societiesPage: action.value.page,
                societiesLimit: action.value.limit,
                filters: action.value.filters,
                society: null,
                supplier: null,
                detailsData: [],
            };
        case GET_SOCIETIES_SUCCESS:
            return {
                ...state,
                societiesData: action.value.data,
                societiesTotalPages: action.value.metadata.totalPages,
                societiesLoading: false,
            };
        case GET_SOCIETIES_ERROR:
            return {
                ...state,
                error: action.value,
                societiesLoading: false,
            };
        case GET_SOCIETY_DETAILS:
            return {
                ...state,
                detailsLoading: true,
                society: action.value.filters.society,
                supplier: action.value.filters.supplierId,

                detailsPage: action.value.page,
                detailsLimit: action.value.limit,
            };
        case GET_SOCIETY_DETAILS_SUCCESS:
            return {
                ...state,
                detailsData: action.value.data,
                detailsTotalPages: action.value.metadata.totalPages,
                detailsLoading: false,
            };
        case GET_SOCIETY_DETAILS_ERROR:
            return {
                ...state,
                error: action.value,
                detailsLoading: false,
            };
        case DOWNLOAD_BILLING_STATEMENT:
            return {
                ...state,
                loadingFile: true,
            };
        case DOWNLOAD_BILLING_STATEMENT_SUCCESS:
            return {
                ...state,
                loadingFile: false,
            };
        case DOWNLOAD_BILLING_STATEMENT_ERROR:
            return {
                ...state,
                loadingFile: false,
                error: action.value,
            };
        default:
            return state;
    }
};

export default billingStatement;
