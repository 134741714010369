import {
    GET_MODELORAMA_BUSSINES,
    GET_MODELORAMA_BUSSINES_ERROR,
    GET_MODELORAMA_BUSSINES_SUCCESS,
    GET_MODELORAMA_ORDERS_BUSSINES,
    GET_MODELORAMA_BUSSINES_ORDERS_SUCCESS,
    GET_MODELORAMA_BUSSINES_ORDERS_ERROR,
    GET_MODELORAMA_ORDERS_FILTERS,
    CREATE_FILE_XML_MODELORAMA,
    CREATE_FILE_XML_MODELORAMA_ERROR,
    CREATE_FILE_XML_MODELORAMA_SUCCESS,
    SET_FILE_UPLOAD_STATUS,
} from '@/components/modelorama/redux/types/Modelorama';

interface IInitialState {
    bussines: [];
    orders: [];
    lineItems: [];
    count: number;
    page: number;
    totalPages: number;
    loading: boolean;
    error: boolean;
    limit: number;
    filters: IModeloramaOrderFilter | object;
    errors: string[];
    isUploadFile: boolean;
}

export interface IModeloramaOrderFilter {
    orderId: string;
}

const initialState: IInitialState = {
    bussines: [],
    orders: [],
    lineItems: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
    filters: {},
    errors: [],
    isUploadFile: false,
};

export const ErrorType = {
    AUTH: 'AUTH',
    NETWORK: 'NETWORK',
    NONE: 'NONE',
    RULE: 'RULE',
    SYSTEM: 'SYSTEM',
};

const modelorama = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_FILE_UPLOAD_STATUS:
            return {
                ...state,
                isUploadFile: action.value,
            };
        case CREATE_FILE_XML_MODELORAMA:
            return {
                ...state,
                loading: true,
                error: false,
                isUploadFile: false,
            };
        case CREATE_FILE_XML_MODELORAMA_SUCCESS:
            return {
                ...state,
                ...action.value,
                loading: false,
                isUploadFile: true,
            };
        case CREATE_FILE_XML_MODELORAMA_ERROR:
            return {
                ...state,
                ...action.value,
                error: ErrorType.RULE,
                errors: action.value.errors,
                loading: false,
                isUploadFile: false,
            };
        case GET_MODELORAMA_ORDERS_FILTERS:
            return {
                ...state,
                filters: action.value,
                loading: false,
            };
        case GET_MODELORAMA_BUSSINES:
            return {
                ...state,
                loading: true,
                isUploadFile: false,
            };
        case GET_MODELORAMA_BUSSINES_SUCCESS:
            return {
                ...state,
                bussines: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                limit: action.value.metadata.limit,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                isUploadFile: false,
            };
        case GET_MODELORAMA_BUSSINES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
                isUploadFile: false,
            };
        case GET_MODELORAMA_ORDERS_BUSSINES:
            return {
                ...state,
                loading: true,
                isUploadFile: false,
            };
        case GET_MODELORAMA_BUSSINES_ORDERS_SUCCESS:
            return {
                ...state,
                lineItems: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                limit: action.value.metadata.limit,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                isUploadFile: false,
            };
        case GET_MODELORAMA_BUSSINES_ORDERS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
                isUploadFile: false,
            };
        default:
            return state;
    }
};

export default modelorama;
