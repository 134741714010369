import {ButtonCancel, Nav} from '@components/faqs/StyledFAQ';
import {RootState} from '@config/store';
import {Box, Modal, Select, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

export const StyledSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
});

interface IUserModalProps {
    isModalOpen: boolean;
    closeModal: any;
}

interface ILabelText {
    title: string;
    values: any[] | string | number;
    width?: string;
    fieldProperty?: string;
}

const LabelText = ({title, values, fieldProperty, width = '25vw'}: ILabelText) => {
    const theme = useTheme();
    return (
        <div
            style={{
                width,
                border: `1px solid ${theme.palette.primary.dark}`,
                padding: '8px',
                borderRadius: '6px',
                position: 'relative',
                marginRight: '10px',
            }}
        >
            <span
                style={{
                    position: 'absolute',
                    fontSize: '12px',
                    top: '-10px',
                    padding: '2px',
                    backgroundColor: 'white',
                }}
            >
                {title}
            </span>
            {Array.isArray(values) ? (
                values.map((value) => {
                    return fieldProperty === undefined ? (
                        <span key={value}>{value}</span>
                    ) : (
                        <span key={value}>{value[fieldProperty]}</span>
                    );
                })
            ) : (
                <span>{values}</span>
            )}
        </div>
    );
};

const UserModalDetail = (props: IUserModalProps) => {
    const {t} = useTranslation();
    const {isModalOpen, closeModal} = props;
    const theme = useTheme();
    const user = useSelector((state: RootState) => state.user.user);

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('roles.modals.user_detail.title')}
                    </Typography>
                </Nav>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '15px 10px',
                    }}
                >
                    <Box display="flex" justifyContent="start" padding="10px">
                        {user && (
                            <LabelText
                                title={t('roles.modals.user_detail.name')}
                                values={`${user.profile?.firstLastName} ${user.profile?.secondLastName}`}
                            />
                        )}
                        {user && (
                            <LabelText
                                title={t('roles.modals.user_detail.company')}
                                values={`${user.enterprise.name}`}
                            />
                        )}
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        {user && user.roles && (
                            <LabelText
                                title={t('roles.modals.user_detail.assigned_role')}
                                values={user.roles}
                                fieldProperty={'name'}
                            />
                        )}
                        {user && (
                            <LabelText
                                title={t('roles.modals.user_detail.email')}
                                values={user.email}
                            />
                        )}
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        {user && (
                            <LabelText
                                title={t('roles.modals.user_detail.enterprise_phone')}
                                values={user.phone}
                            />
                        )}
                        {user && (
                            <LabelText
                                title={t('roles.modals.user_detail.contact_phone')}
                                values={user.phone}
                            />
                        )}
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        {user && user.country && (
                            <LabelText
                                title={t('roles.modals.user_detail.country')}
                                values={user.country.name}
                            />
                        )}
                        {user &&
                            user.company &&
                            user.company.taxProfiles &&
                            user.company.taxProfiles.length > 0 && (
                                <LabelText
                                    title={t('roles.modals.user_detail.rfc')}
                                    values={user.company.taxProfiles[0].taxId}
                                />
                            )}
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        {user &&
                            user.company &&
                            user.company.taxProfiles &&
                            user.company.taxProfiles.length > 0 && (
                                <LabelText
                                    title={t('roles.modals.user_detail.tax_regime')}
                                    values={user.company.taxProfiles[0].taxRegimes}
                                />
                            )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height: '56px',
                            alignItems: 'center',
                            margin: '20px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonCancel onClick={closeModal}>{t('close').toUpperCase()}</ButtonCancel>
                    </Box>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default UserModalDetail;
