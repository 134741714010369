import {RootState} from '@/config/store';
import i18n from '@/i18n';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

export const useTitle = () => {
    const {t} = useTranslation();
    const [title, setTitle] = useState('');
    const [path, setPath] = useState('');
    const menu = useSelector((state: RootState) => state.perfil.menu);

    useEffect(() => {
        if (path !== '') {
            const routes: Record<string, any> = {};
            menu.forEach((item: any) => {
                routes[item.value] = i18n.language === 'en' ? item.nameEN : item.name;
            });
            setTitle(routes[path]);
        }
    }, [path, t]);
    return {title, setPath};
};
