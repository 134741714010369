import * as React from 'react';

import {Box} from '@mui/material';

export interface ImageProps {
    imgSrc: string;
    alt?: string;
    width?: string;
    height?: string;
}

function Image({imgSrc, alt, width, height}: ImageProps) {
    if (!imgSrc) return null;

    return (
        <Box>
            <img src={imgSrc} alt={alt} width={width} height={height} />
        </Box>
    );
}

Image.defaultProps = {
    alt: '',
    width: '100%',
    height: '100%',
};

export default Image;
