import {SxProps} from '@mui/material';

export const customScrollbar: SxProps = {
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: '7px',
    },
    '::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: 'rgba(0,0,0,.2)',
        WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
};
