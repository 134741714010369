import IconDelete from '@/components/UI/atoms/icons/IconDelete.svg';
import IconEdit from '@/components/UI/atoms/icons/IconEdit.svg';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, FrequentQuestionsEnum} from '@/enums/permissions.enum';
import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import {getDate} from '@/utils/FormatDate';
import {
    ButtonMoreItems,
    ItemsList,
    TableCellChildren,
    TypographyStyled400,
    TypographyStyled500,
    TypographyStyled600,
    TypographyStyledUser,
} from '@components/faqs/StyledFAQ';
import LineClamp from '@components/UI/molecules/LineClamp';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {Checkbox, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
export default function FAQPopper({
    isModalOpen,
    openModal,
    item,
    editList,
    setItemDelete,
    setModifyItem,
}) {
    const {t} = useTranslation();
    const hasConfiguration = useConfigurationUrl();
    const [checked, setChecked] = useState(false);

    const [openListCountries, setOpenListCountries] = useState(false);

    useEffect(() => {
        if (!isModalOpen) {
            setChecked(false);
        }
    }, [isModalOpen]);
    const editModal = () => {
        openModal('Editar');
        setModifyItem(item);
    };

    const deleteModal = () => {
        openModal('Eliminar');
        setModifyItem(item);
    };

    const handleChange = (e) => {
        setChecked(e.target.checked);
        setItemDelete({id: item.id, checked: e.target.checked});
    };

    const getListCountries = () => {
        let auxList = '';
        item.countries.forEach((e, index) => {
            if (index !== 0) {
                if (index === item.countries.length - 1) {
                    auxList += e.name;
                } else {
                    auxList += e.name + ', ';
                }
            }
        });
        return auxList;
    };

    return (
        <>
            <Grid container columns={12}>
                {editList && (
                    <Grid item xs={1} display="grid">
                        <TableCellChildren
                            sx={{
                                borderLeft: '1px solid #C2D1D9',
                            }}
                        >
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                sx={{
                                    color: '#848F9D',
                                    '&.Mui-checked': {
                                        color: '#00A0F8',
                                    },
                                    marginLeft: '20px',
                                }}
                            />
                        </TableCellChildren>
                    </Grid>
                )}
                <Grid item xs={hasConfiguration ? 2 : 3} display="grid">
                    <TableCellChildren
                        sx={{
                            borderLeft: editList ? '0px' : '1px solid #C2D1D9',
                        }}
                    >
                        <TypographyStyled600>{item?.question}</TypographyStyled600>
                    </TableCellChildren>
                </Grid>
                <Grid item xs={hasConfiguration ? 4 : 5} display="grid">
                    <TableCellChildren>
                        <TypographyStyled500>{item?.answer}</TypographyStyled500>
                    </TableCellChildren>
                </Grid>
                <Grid item xs={1} display="grid">
                    <TableCellChildren>
                        <TypographyStyled400>
                            <LineClamp items={item.roles} />
                        </TypographyStyled400>
                    </TableCellChildren>
                </Grid>
                <Grid item xs={1} display="grid">
                    <TableCellChildren>
                        <TypographyStyled400>
                            {item?.countries?.length > 0 && item.countries[0].name}
                            {item?.countries?.length >= 2 && (
                                <>
                                    <ButtonMoreItems
                                        onClick={() => setOpenListCountries((prev) => !prev)}
                                    >
                                        (+{item.countries.length - 1})
                                    </ButtonMoreItems>
                                    {openListCountries && (
                                        <ItemsList>{getListCountries()}</ItemsList>
                                    )}
                                </>
                            )}
                        </TypographyStyled400>
                    </TableCellChildren>
                </Grid>
                {editList ? (
                    <Grid item xs={1} display="grid">
                        <TableCellChildren
                            sx={{
                                borderRight: '1px solid #C2D1D9',
                            }}
                        >
                            <Can
                                I={FrequentQuestionsEnum.EDIT}
                                a={FeatureCodeEnum.FREQUENT_QUESTIONS}
                            >
                                <img
                                    onClick={editModal}
                                    src={IconEdit}
                                    title={t('manuals.modals.edit')}
                                />
                            </Can>
                            <Can
                                I={FrequentQuestionsEnum.DELETE}
                                a={FeatureCodeEnum.FREQUENT_QUESTIONS}
                            >
                                <img
                                    onClick={deleteModal}
                                    src={IconDelete}
                                    title={t('manuals.modals.delete')}
                                />
                            </Can>
                        </TableCellChildren>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={1} display="grid">
                            <TableCellChildren>
                                <TypographyStyled400>
                                    {getDate(item?.updatedAt)}
                                </TypographyStyled400>
                            </TableCellChildren>
                        </Grid>
                        <Grid item xs={1} display="grid">
                            <TableCellChildren
                                sx={{
                                    borderRight: '1px solid #C2D1D9',
                                }}
                            >
                                <TypographyStyledUser>{item?.user?.name}</TypographyStyledUser>
                            </TableCellChildren>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
