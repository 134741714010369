import {
    GET_ORDER_RECEPT,
    GET_ORDER_RECEPT_SUCCESS,
    GET_ORDER_RECEPT_ERROR,
    GET_DOWNLOAD_ORDERSRECEPT,
    GET_DOWNLOAD_ORDERSRECEPT_SUCCESS,
    GET_DOWNLOAD_ORDERSRECEPT_ERROR,
} from '@components/orderRecept/redux/types/OrderReceptTypes';

import axios from 'axios';

export const getOrdersRecept = () => {
    return {
        type: GET_ORDER_RECEPT,
    };
};

export const getOrdersReceptSuccess = (result) => {
    return {
        type: GET_ORDER_RECEPT_SUCCESS,
        value: result.data,
    };
};

export const getOrdersReceptError = () => {
    return {
        type: GET_ORDER_RECEPT_ERROR,
    };
};

export const getOrdersReceptDownload = () => {
    return {
        type: GET_DOWNLOAD_ORDERSRECEPT,
    };
};

export const getOrdersReceptDownloadSuccess = () => {
    return {
        type: GET_DOWNLOAD_ORDERSRECEPT_SUCCESS,
    };
};

export const getOrdersReceptDownloadError = () => {
    return {
        type: GET_DOWNLOAD_ORDERSRECEPT_ERROR,
    };
};

export function fetchGetOrdersRecept(token, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = filters;
        dispatch(getOrdersRecept());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/list/filter`,
                {
                    headers: headers,
                    params: params,
                }
            );
            dispatch(getOrdersReceptSuccess(response));
        } catch (error) {
            dispatch(getOrdersReceptError());
        }
    };
}

export function fetchGetDownloadOrdersRecept(token, format, filters) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = filters;
        dispatch(getOrdersReceptDownload());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/download/order-recept/${format}`,
                {
                    headers: headers,
                    params: params,
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `purchaseorder.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            dispatch(getOrdersReceptDownloadSuccess());
        } catch (error) {
            dispatch(getOrdersReceptDownloadError());
        }
    };
}
