import {
    CURRET_TICKET_SELECTED,
    GET_TICKET,
    GET_TICKET_ERROR,
    GET_TICKET_SUCCESS,
    POST_TICKET,
    POST_TICKET_ERROR,
    POST_TICKET_SUCCESS,
} from '@/components/tickets/redux/types/TicketTypes';

const initialState = {
    tickets: [],
    loading: false,
    error: false,
    count: 0,
    page: 1,
    limit: 10,
    totalPages: 1,
    ticketSelected: {},
};

const ticketReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TICKET:
            return {
                ...state,
                loading: true,
            };
        case GET_TICKET_SUCCESS:
            return {
                ...state,
                tickets: action.value.data ?? [],
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                limit: action.value.metadata.limit,
                totalPages: action.value.metadata.totalPages,

                loading: false,
            };
        case GET_TICKET_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };

        case POST_TICKET:
            return {
                ...state,
                loading: true,
            };
        case POST_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_TICKET_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CURRET_TICKET_SELECTED:
            return {
                ...state,

                ticketSelected: action.value,
            };
        default:
            return state;
    }
};

export default ticketReducers;
