import React from 'react';

import LangContent from '@/components/terms/components/content/LangContent';
import {spanishPrivacyPolicy} from '@/components/terms/constants/privacy-policy.constant';
import {spanishTerms} from '@/components/terms/constants/terms.constant';

function SpanishContent(): React.ReactElement {
    return <LangContent lang={[...spanishTerms, ...spanishPrivacyPolicy]} />;
}

export default SpanishContent;
