import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {AppDispatch, RootState} from '@/config/store';
import AvatarWithName from '@components/UI/molecules/avatar/AvatarWithName';
import {TextButtonDetails, TextSuccess} from '@components/UI/molecules/textButton/textButtons';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import ModalViewNotifications from '@components/log/components/organisms/modals/ModalViewNotifications';
import {
    fetchGetTermsConditionsDetails,
    setInfoCurrentNotification,
} from '@components/log/redux/actions/TermsConditionDetailsActions';
import {IFormTermConditions, ITableTermsDetails} from '@log/interface';
import {Grid} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
export interface ITableTermsConditionsProps {
    json: any;
    watchForm: IFormTermConditions;
}

const TableTermsConditionsDetails: React.FC<ITableTermsConditionsProps> = ({watchForm, json}) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const nameDetails = useSelector((state: RootState) => state.binnacles.nameDetail);
    const termsConditionsDetails = useSelector(
        (state: RootState) => state.binnacles.termsConditionsDetails
    );
    const totalPagesTermsDetails = useSelector(
        (state: RootState) => state.binnacles.totalPagesTermsDetails
    );
    const loadingTermsDetails = useSelector(
        (state: RootState) => state.binnacles.loadingTermsDetails
    );
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const limitTermsDetails = useSelector((state: RootState) => state.binnacles.limitTermsDetails);
    const currentPageTermsDetails = useSelector(
        (state: RootState) => state.binnacles.pageTermsDetails
    );
    const vendorBranchOfficeId = useSelector(
        (state: RootState) => state.binnacles.vendorBranchOfficeId
    );
    const [openViewNotifications, setOpenViewNotifications] = useState(false);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const user = watchForm.user ?? '';
        dispatch(
            fetchGetTermsConditionsDetails(vendorBranchOfficeId, token, value, limitTermsDetails, {
                ...json,
                user,
            })
        );
    };

    const handleChangeRowsPerPage = (event: any) => {
        const user = watchForm.user ?? '';
        dispatch(
            fetchGetTermsConditionsDetails(vendorBranchOfficeId, token, 1, event.target.value, {
                ...json,
                user,
            })
        );
    };

    return (
        <>
            <TableContainerBinnance container width={'calc(100vw - 330px)'}>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={1} sx={{boxShadow: 0}}>
                        <TableCellHeadInitial>
                            {t('terms_conditions.table.headers.purchase_order')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('terms_conditions.table.headers.date_accept_terms_and_privacy')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('terms_conditions.table.headers.hour')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('terms_conditions.table.headers.user')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('terms_conditions.table.headers.status')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('terms_conditions.table.headers.date_notification')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('terms_conditions.table.headers.view_notification')}
                        </TableCellHead>
                    </Grid>
                </TableHeadRow>
                {loadingTermsDetails ? (
                    // Muestra el esqueleto de carga mientras se cargan los datos
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    termsConditionsDetails?.map((item: ITableTermsDetails) => (
                        <>
                            <Grid item xs={1}>
                                <TableCell>{item.referenceId}</TableCell>
                            </Grid>

                            <Grid item xs={2}>
                                <TableCell>
                                    {DateTime.fromISO(item.dateConfirmed).toFormat('dd/MM/yyyy')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>{item.hours}</TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>
                                    {item?.userNamedConfirmed && (
                                        <AvatarWithName name={item.userNamedConfirmed} />
                                    )}
                                </TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>
                                    <TextSuccess
                                        sx={{
                                            fontSize: '13px',
                                            backgroundColor:
                                                item.isTermsConditionsAccepted === 'Aceptado'
                                                    ? '#afde83'
                                                    : '#6bb9f4',
                                            color: '#000',
                                        }}
                                    >
                                        {item.isTermsConditionsAccepted}
                                    </TextSuccess>
                                </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>
                                    {DateTime.fromISO(item.dateConfirmed).toFormat('dd/MM/yyyy')}
                                </TableCell>
                            </Grid>

                            <Grid item xs={2}>
                                <TableCellIcons>
                                    <TextButtonDetails
                                        onClick={() => {
                                            dispatch(
                                                setInfoCurrentNotification({
                                                    vendorName: nameDetails,
                                                    orderReferenceId: item?.referenceId,
                                                    userFullName: item.userNamedConfirmed,
                                                    companyName: nameDetails,
                                                    acceptedTerms:
                                                        item?.isTermsConditionsAccepted ===
                                                        'Aceptado',
                                                    actionDate: DateTime.fromISO(
                                                        item?.dateConfirmed
                                                    ).toFormat('yyyy-MM-dd'),
                                                })
                                            );
                                            setOpenViewNotifications(true);
                                        }}
                                    >
                                        {t('view_details')}
                                    </TextButtonDetails>
                                </TableCellIcons>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPagesTermsDetails}
                currentPage={currentPageTermsDetails}
                handleChange={handleChange}
                limit={limitTermsDetails}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <ModalViewNotifications
                isModalOpen={openViewNotifications}
                closeModal={() => setOpenViewNotifications(false)}
                method={''}
                message={''}
                listDelete={[]}
                item={undefined}
            />
        </>
    );
};

export default TableTermsConditionsDetails;
