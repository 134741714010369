import {AllPermissions, GuestEnum} from '@/enums/permissions.enum';
import {useAbilities} from '@/hooks/useAbilities';
import {ReactNode} from 'react';

import BlankLayout from '@/components/auth/componenents/BlankLayout';
import React from 'react';
export type ACLObj = {
    action: AllPermissions;
    subject: string;
};
interface AclGuardProps {
    children: ReactNode & {acl?: ACLObj};
    aclAbilities?: ACLObj;
}

const AclGuard = (props: AclGuardProps) => {
    // ** Props
    const {aclAbilities, children} = props;

    const {abilities: ability} = useAbilities();

    if (ability && aclAbilities && ability.can(aclAbilities?.action, aclAbilities?.subject)) {
        return <>{children}</>;
    }
    if (aclAbilities && aclAbilities?.action === GuestEnum.SEE_LIST) {
        return <BlankLayout>{children}</BlankLayout>;
    }

    // Render Not Authorized component if the current user has limited access
    return (
        <BlankLayout>
            <></>{' '}
        </BlankLayout>
    );
};

export default AclGuard;
