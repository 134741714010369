import {Box, Grid} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';

import {IConProp} from '@/components/terms/interfaces/interface';
import {
    ILineItem,
    IProductDetail,
} from '@/components/terms/interfaces/terms-purchase-order.interface';
import {TableCellChildrenSmall, TypographyStyled400} from '@/components/terms/styles/styles';
import {formatMoney} from '@/utils/utils';

interface ILineItemRow {
    colProps: IConProp[];
    item: ILineItem;
}

function LineItemRow({item, colProps}: ILineItemRow): React.JSX.Element {
    const productDetails = item.productDetails && item.productDetails[0];

    const formatAmount = (productDetail: IProductDetail) => {
        const amount = productDetail.realPrice;
        const currency = productDetail.currency;
        const sign = '';
        return `${sign}${formatMoney(amount, currency)}`;
    };

    return (
        <Grid container key={item.id}>
            <Grid item xs={colProps[0].size} display="grid">
                <TableCellChildrenSmall sx={{borderLeft: '1px solid #C2D1D9'}}>
                    <Box sx={{width: '100%'}}>
                        <TypographyStyled400 sx={{textAlign: 'center'}}>
                            {item?.position ?? ''}
                        </TypographyStyled400>
                    </Box>
                </TableCellChildrenSmall>
            </Grid>

            <Grid item xs={colProps[1].size} display="grid">
                <TableCellChildrenSmall>
                    <Box sx={{width: '100%', margin: '5px'}}>
                        <TypographyStyled400 sx={{textAlign: 'center'}}>
                            {productDetails.sku?.replace(/^0+/, '') ?? ''}
                        </TypographyStyled400>
                    </Box>
                </TableCellChildrenSmall>
            </Grid>

            <Grid item xs={colProps[2].size} display="grid">
                <TableCellChildrenSmall>
                    <Box sx={{width: '100%'}}>
                        <TypographyStyled400 sx={{textAlign: 'left'}}>
                            {productDetails?.productName ?? ''}
                        </TypographyStyled400>
                    </Box>
                </TableCellChildrenSmall>
            </Grid>

            <Grid item xs={colProps[3].size} display="grid">
                <TableCellChildrenSmall>
                    <Box sx={{width: '100%'}}>
                        <TypographyStyled400 sx={{textAlign: 'center'}}>
                            {productDetails.unit && productDetails.unit !== 'undefined'
                                ? productDetails.unit
                                : ''}
                        </TypographyStyled400>
                    </Box>
                </TableCellChildrenSmall>
            </Grid>

            <Grid item xs={colProps[4].size} display="grid">
                <TableCellChildrenSmall>
                    <Box sx={{width: '100%', paddingRight: '10px'}}>
                        <TypographyStyled400 sx={{textAlign: 'right'}}>
                            {formatAmount(productDetails)}
                        </TypographyStyled400>
                    </Box>
                </TableCellChildrenSmall>
            </Grid>

            <Grid item xs={colProps[5].size} display="grid">
                <TableCellChildrenSmall>
                    <Box sx={{width: '100%'}}>
                        <TypographyStyled400 sx={{textAlign: 'center'}}>
                            {item?.deliveryDate && item.deliveryDate !== undefined
                                ? DateTime.fromJSDate(new Date(item.deliveryDate)).toFormat(
                                      'dd / LL / y'
                                  )
                                : ''}
                        </TypographyStyled400>
                    </Box>
                </TableCellChildrenSmall>
            </Grid>
        </Grid>
    );
}

export default LineItemRow;
