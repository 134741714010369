import React from 'react';

import {EnglishContent, SpanishContent} from '@/components/terms/components/content/';

interface ITermsContent {
    language?: string;
}

function TermsContent({language}: ITermsContent): React.JSX.Element {
    return language === 'en' ? <EnglishContent /> : <SpanishContent />;
}

TermsContent.defaultProps = {
    language: 'es',
};

export default TermsContent;
