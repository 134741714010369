import {yupResolver} from '@hookform/resolvers/yup';
import {Box, TextField, Tooltip} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';

import {AppDispatch, RootState} from '@/config/store';
import {IFormBillingStatement} from '@components/billingStatement/interface';
import {fetchGetSocieties} from '@components/billingStatement/redux/reducers/actions/SocietyActions';
import {Search} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

export const BillingStatementFilters: React.FC = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const limit = useSelector((state: RootState) => state.billingStatement.societiesLimit);
    const page = useSelector((state: RootState) => state.billingStatement.societiesPage);
    const schemaFormModal = yup.object({
        id: yup.string(),
        societyName: yup.string(),
        dateInit: yup.date(),
        dateEnd: yup.date(),
    });

    const {handleSubmit, control, setValue} = useForm<IFormBillingStatement>({
        resolver: yupResolver(schemaFormModal),
    });

    const onSubmit = (info: IFormBillingStatement) => {
        dispatch(
            fetchGetSocieties(token, 1, limit, {
                ...info,
                dateInit: DateTime.fromJSDate(info.dateInit).toFormat('yyyy-MM-dd'),
                dateEnd: DateTime.fromJSDate(info.dateEnd).toFormat('yyyy-MM-dd'),
            })
        );
    };

    const onChangeDate = (e: Date | null, addMonth: number = 1) => {
        if (e) {
            const addMonthAbs = Math.abs(addMonth);
            if (addMonth > 0) {
                const newDate = DateTime.fromJSDate(e).plus({months: addMonthAbs}).toJSDate();
                setValue('dateEnd', newDate, {shouldTouch: true});
            } else if (addMonth < 0) {
                const newDate = DateTime.fromJSDate(e).minus({months: addMonthAbs}).toJSDate();
                setValue('dateInit', newDate, {shouldTouch: true});
            }
        }
    };

    useEffect(() => {
        dispatch(
            fetchGetSocieties(token, page, limit, {
                dateInit: DateTime.now().minus({months: 1}).toFormat('yyyy-MM-dd'),
                dateEnd: DateTime.now().toFormat('yyyy-MM-dd'),
            })
        );
    }, [token]);

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '20px',
                    padding: '15px',
                    height: '73px',
                    background: '#fff',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <Controller
                        name="societyName"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                id="id"
                                label={`${t('billingStatement.filters.society')}`}
                                variant="outlined"
                                size="small"
                            />
                        )}
                        defaultValue={''}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                        <Controller
                            name="dateInit"
                            control={control}
                            defaultValue={DateTime.now().minus({months: 1}).toJSDate()}
                            render={({field}) => (
                                <DatePicker
                                    label={t('logbook_and_details.start_date')}
                                    value={field.value}
                                    onChange={(e) => {
                                        onChangeDate(e, 1);
                                        field.onChange(e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    height: '40px',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                        <Controller
                            name="dateEnd"
                            control={control}
                            defaultValue={DateTime.now().toJSDate()}
                            render={({field}) => (
                                <DatePicker
                                    label={t('logbook_and_details.end_date')}
                                    value={field.value}
                                    onChange={(e) => {
                                        onChangeDate(e, -1);
                                        field.onChange(e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    height: '40px',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <Tooltip title={t('components.filter_btn.title')}>
                        <LoadingButton
                            variant="outlined"
                            loading={false}
                            type="submit"
                            color="secondary"
                            sx={{
                                minWidth: '40px',
                                height: '40px',
                                padding: '5px 5px',
                            }}
                        >
                            <Search />
                        </LoadingButton>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};
