import React from 'react';

const Header = () => (
    <div style={{backgroundColor: '#000', height: '300px'}}>
        <div style={{paddingTop: '100px'}}>
            <img
                style={{width: '203.81px', height: '37.65px'}}
                src="http://cdn.mcauto-images-production.sendgrid.net/118c29486c8e22ee/35c6e3e6-edfd-4ffe-b77c-19e3e92a9d67/208x39.png"
                alt="Logo"
            />
        </div>
    </div>
);
export default Header;
