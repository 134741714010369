import {
    GET_FORGOT_PASSWORD,
    GET_FORGOT_PASSWORD_ERROR,
    GET_FORGOT_PASSWORD_SUCCESS,
    GET_HISTORY_URL,
    GET_USER_INFO,
    GET_USER_INFO_ABI,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_SUCCESS,
    GET_USER_VALIDATION_ADMIN,
    GET_USER_VALIDATION_ADMIN_ERROR,
    GET_USER_VALIDATION_ADMIN_SUCCESS,
    LOGOUT,
    NEX_STEP_SUPPLIER,
    POST_ENTERPRISE,
    POST_ENTERPRISE_ERROR,
    POST_ENTERPRISE_SUCCESS,
    POST_MDWEB,
    POST_MDWEB_ERROR,
    POST_MDWEB_SUCCESS,
    PUT_UPDATE_PASSWORD,
    PUT_UPDATE_PASSWORD_ERROR,
    PUT_UPDATE_PASSWORD_SUCCESS,
    SET_CREATE_ENTERPRISE_SUCCESS,
    SET_CSF_ALERT,
    SET_ERROR,
    SET_MD_PRELOAD_DATA,
    SET_MODAL_ALERT,
    SET_ORDER_ALERT,
} from '@/components/auth/redux/types/LoginTypes';

const initialState = {
    user: [],
    history: '',
    userId: '',
    accessToken: '',
    forgotpassword: '',
    refreshToken: '',
    loading: false,
    error: false,
    tokenflag: false,
    themeCreat: false,
    createEnterpriceSuccess: false,
    mdPreloadData: null,
    modalAlert: false,
    orderAlert: false,
    csfAlert: false,
    nextStepSupplier: false,
};

const logins = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_VALIDATION_ADMIN:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_VALIDATION_ADMIN_SUCCESS:
            return {
                ...state,
                userId: action.value.userId,
                accessToken: action.value.accessToken,
                refreshToken: action.value.refreshToken,
                loading: false,
                tokenflag: true,
                themeCreat: true,
            };
        case GET_USER_VALIDATION_ADMIN_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_USER_INFO_ABI:
            return {
                ...state,
                accessToken: action.value,
                loading: false,
                tokenflag: true,
            };
        case GET_USER_INFO:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.value,
                loading: false,
            };
        case GET_USER_INFO_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_FORGOT_PASSWORD:
            return {
                ...state,
                loading: true,
            };
        case GET_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotpassword: action.value,
                loading: false,
            };
        case GET_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case PUT_UPDATE_PASSWORD:
            return {
                ...state,
                loading: true,
            };
        case PUT_UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_ENTERPRISE:
            return {
                ...state,
                loading: true,
            };
        case POST_ENTERPRISE_SUCCESS:
            return {
                ...state,
                userId: action.value.userId,
                accessToken: action.value.accessToken,
                refreshToken: action.value.refreshToken,
                createEnterpriceSuccess: true,
                loading: false,
                tokenflag: true,
                themeCreat: true,
            };
        case POST_ENTERPRISE_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case LOGOUT: {
            window.localStorage.removeItem('persist:root');
            return {
                ...initialState,
                tokenflag: false,
                accessToken: '',
                history: '',
                loading: false,
                alert: false,
            };
        }
        case GET_HISTORY_URL:
            return {
                ...state,
                history: action.value,
                loading: true,
            };
        case SET_CREATE_ENTERPRISE_SUCCESS:
            return {
                ...state,
                createEnterpriceSuccess: action.value,
            };
        case SET_ERROR:
            return {
                ...state,
                error: false,
            };

        case SET_MD_PRELOAD_DATA:
            return {
                ...state,
                mdPreloadData: action.value,
            };
        case POST_MDWEB:
            return {
                ...state,
                loading: true,
            };
        case POST_MDWEB_SUCCESS:
            return {
                ...state,
                userId: action.value.userId,
                accessToken: action.value.accessToken,
                refreshToken: action.value.refreshToken,
                loading: false,
                tokenflag: true,
                themeCreat: true,
            };
        case POST_MDWEB_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case SET_MODAL_ALERT:
            return {
                ...state,
                modalAlert: action.value,
            };
        case SET_ORDER_ALERT:
            return {
                ...state,
                orderAlert: action.value,
            };
        case SET_CSF_ALERT:
            return {
                ...state,
                csfAlert: action.value,
            };
        case NEX_STEP_SUPPLIER:
            return {
                ...state,
                nextStepSupplier: action.value,
            };
        default:
            return state;
    }
};

export default logins;
