export const GET_LEGALAGREEMENTS = 'GET_LEGALAGREEMENTS';
export const GET_LEGALAGREEMENTS_SUCCESS = 'GET_LEGALAGREEMENTS_SUCCESS';
export const GET_LEGALAGREEMENTS_ERROR = 'GET_LEGALAGREEMENTS_ERROR';
export const POST_LEGALAGREEMENTS = 'POST_LEGALAGREEMENTS';
export const POST_LEGALAGREEMENTS_SUCCESS = 'POST_LEGALAGREEMENTS_SUCCESS';
export const POST_LEGALAGREEMENTS_ERROR = 'POST_LEGALAGREEMENTS_ERROR';
export const UPDATE_LEGALAGREEMENTS = 'UPDATE_LEGALAGREEMENTS';
export const UPDATE_LEGALAGREEMENTS_SUCCESS = 'UPDATE_LEGALAGREEMENTS_SUCCESS';
export const UPDATE_LEGALAGREEMENTS_ERROR = 'UPDATE_LEGALAGREEMENTS_ERROR';
export const DELETE_LEGALAGREEMENTS = 'DELETE_LEGALAGREEMENTS';
export const DELETE_LEGALAGREEMENTS_SUCCESS = 'DELETE_LEGALAGREEMENTS_SUCCESS';
export const DELETE_LEGALAGREEMENTS_ERROR = 'DELETE_LEGALAGREEMENTS_ERROR';
export const CREATE_FILE_LEGALAGREEMENTS = 'CREATE_FILE_LEGALAGREEMENTS';
export const CREATE_FILE_LEGALAGREEMENTS_SUCCESS = 'CREATE_FILE_LEGALAGREEMENTS_SUCCESS';
export const CREATE_FILE_LEGALAGREEMENTS_ERROR = 'CREATE_FILE_LEGALAGREEMENTS_ERROR';
