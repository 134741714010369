import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography} from '@mui/material';
import logoModelo from '@order/images/logoModelo.svg';
import logoAbiQr from '@order/images/logoAbiQr.svg';
import iconSuccess from '@order/images/iconSuccess.svg';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';
import {ButtonOrderMobile, ButtonOrder} from '@components/UI/atoms/buttons/ButtonAlternative';
import jsQR from 'jsqr';

function esDispositivoMovil() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}

const OrderSuccessGenesis = () => {
    const theme = useTheme();
    const orderSuccess = useSelector((state) => state.orderGenesis.orderSuccess);
    const error = useSelector((state) => state.orderGenesis.error);
    const errorMessage = useSelector((state) => state.orderGenesis.errorMessage);
    const orderIncomplete = useSelector((state) => state.orderGenesis.orderIncomplete);
    const orderId = useSelector((state) => state.orderGenesis.referenceId);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [cameraActive, setCameraActive] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(esDispositivoMovil());
    }, []);

    const stopVideoStream = () => {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
            track.stop();
        });

        setCameraActive(false); // Actualiza el estado para reflejar que la cámara ya no está activa
    };

    const startVideo = () => {
        navigator.mediaDevices
            .getUserMedia({video: {facingMode: {exact: 'environment'}}}) // Usar "environment" para la cámara trasera
            .then((stream) => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
                setCameraActive(true);
            })
            .catch((err) => {});
    };
    const handleQRCode = (qrData) => {
        window.location.href = qrData;
    };

    const scanQRCode = () => {
        if (!cameraActive) return;

        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (video.readyState === video.HAVE_ENOUGH_DATA) {
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: 'dontInvert',
            });
            if (code) {
                stopVideoStream(); // Detiene el video y la cámara
                handleQRCode(code.data);
                window.location.href = code.data;
                // Llama a la función de manejo de código QR con el valor del código QR
            }
        }
        requestAnimationFrame(scanQRCode);
    };

    useEffect(() => {
        if (cameraActive) {
            requestAnimationFrame(scanQRCode);
        }
    }, [cameraActive]);

    return (
        <>
            {orderSuccess && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        background: 'linear-gradient(to bottom, #D3A239, #F4DE05)',
                        padding: isMobile ? '35% 30px 0 30px' : '30px 20%',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        {' '}
                        {isMobile ? (
                            <>
                                <img src={logoModelo} width="400" />{' '}
                                <img src={logoAbiQr} width="400" />
                            </>
                        ) : (
                            <>
                                <img src={logoModelo} width="200" />{' '}
                                <img src={logoAbiQr} width="200" />
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            background: 'white',
                            borderRadius: '10px',
                            padding: isMobile ? '90px 60px' : '45px 30px',
                            marginTop: isMobile ? '80px' : '40px',
                        }}
                    >
                        <img src={iconSuccess} width="70" />
                        <Typography
                            sx={{
                                fontSize: isMobile ? '38px' : '30px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#011B34',
                                marginTop: isMobile ? '50px' : '25px',
                            }}
                        >
                            Confirmación
                        </Typography>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '33.6px' : '28px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    color: '#011B34',
                                    marginTop: isMobile ? '20px' : '10px',
                                }}
                                component="div"
                                variant="body1"
                            >
                                Se ha generado la hoja de entrada {orderSuccess.entrySheet}{' '}
                                relacionada a la orden de compra{' '}
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '33.6px' : '28px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        color: '#011B34',
                                        display: 'inline', // Esto asegura que el texto esté en la misma línea
                                    }}
                                    component="span"
                                    variant="body1"
                                >
                                    {orderSuccess.referenceId}
                                </Typography>{' '}
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '33.6px' : '28px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 400,
                                        color: '#011B34',
                                        display: 'inline', // Esto asegura que el texto esté en la misma línea
                                    }}
                                    component="span"
                                    variant="body1"
                                >
                                    con {orderSuccess.lineItems.length} posiciones.
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '28px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            color: '#3C3C3C',
                            paddingTop: isMobile ? '15%' : '30px',
                        }}
                    >
                        ¿Quieres consultar alguna orden diferente?
                    </Typography>
                    {isMobile ? (
                        <ButtonOrderMobile onClick={startVideo} disabled={cameraActive}>
                            Nueva orden
                        </ButtonOrderMobile>
                    ) : (
                        <ButtonOrder onClick={startVideo} disabled={cameraActive}>
                            Nueva orden
                        </ButtonOrder>
                    )}
                    <video
                        ref={videoRef}
                        style={{width: '100%', display: cameraActive ? 'block' : 'none'}}
                    />
                    <canvas ref={canvasRef} style={{display: 'none'}} />
                </Box>
            )}
            {error && errorMessage === 'Rechazo registrado.' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        background: 'linear-gradient(to bottom, #D3A239, #F4DE05)',
                        padding: isMobile ? '35% 30px 0 30px' : '30px 20%',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        {' '}
                        {isMobile ? (
                            <>
                                <img src={logoModelo} width="400" />{' '}
                                <img src={logoAbiQr} width="400" />
                            </>
                        ) : (
                            <>
                                <img src={logoModelo} width="200" />{' '}
                                <img src={logoAbiQr} width="200" />
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            background: 'white',
                            borderRadius: '10px',
                            padding: isMobile ? '90px 60px' : '45px 30px',
                            marginTop: isMobile ? '80px' : '40px',
                        }}
                    >
                        <img src={iconSuccess} width="70" />
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '33.6px' : '28px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    color: '#011B34',
                                    marginTop: isMobile ? '20px' : '10px',
                                }}
                                component="div"
                                variant="body1"
                            >
                                Se ha generado el rechazo de
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '33.6px' : '28px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 400,
                                        color: '#011B34',
                                        display: 'inline', // Esto asegura que el texto esté en la misma línea
                                    }}
                                    component="span"
                                    variant="body1"
                                >
                                    {' '}
                                    {orderIncomplete.lineItems.length} posiciones,
                                </Typography>{' '}
                                relacionada a la orden de compra{' '}
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '33.6px' : '28px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        color: '#011B34',
                                        display: 'inline', // Esto asegura que el texto esté en la misma línea
                                    }}
                                    component="span"
                                    variant="body1"
                                >
                                    {orderId}
                                </Typography>{' '}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '28px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            color: '#3C3C3C',
                            paddingTop: isMobile ? '15%' : '30px',
                        }}
                    >
                        ¿Quieres consultar alguna orden diferente?
                    </Typography>
                    {isMobile ? (
                        <ButtonOrderMobile onClick={startVideo} disabled={cameraActive}>
                            Nueva orden
                        </ButtonOrderMobile>
                    ) : (
                        <ButtonOrder onClick={startVideo} disabled={cameraActive}>
                            Nueva orden
                        </ButtonOrder>
                    )}
                    <video
                        ref={videoRef}
                        style={{width: '100%', display: cameraActive ? 'block' : 'none'}}
                    />
                    <canvas ref={canvasRef} style={{display: 'none'}} />
                </Box>
            )}
            {error && errorMessage !== 'Rechazo registrado.' && errorMessage !== '' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        background: 'linear-gradient(to bottom, #D3A239, #F4DE05)',
                        padding: isMobile ? '35% 30px 0 30px' : '30px 20%',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        {' '}
                        {isMobile ? (
                            <>
                                <img src={logoModelo} width="400" />{' '}
                                <img src={logoAbiQr} width="400" />
                            </>
                        ) : (
                            <>
                                <img src={logoModelo} width="200" />{' '}
                                <img src={logoAbiQr} width="200" />
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            background: 'white',
                            borderRadius: '10px',
                            padding: isMobile ? '90px 60px' : '45px 30px',
                            marginTop: isMobile ? '80px' : '40px',
                        }}
                    >
                        <img src={iconSuccess} width="70" />
                        <Typography
                            sx={{
                                fontSize: isMobile ? '38px' : '30px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#011B34',
                                marginTop: isMobile ? '50px' : '25px',
                            }}
                        >
                            Error
                        </Typography>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '33.6px' : '28px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    color: '#011B34',
                                    marginTop: isMobile ? '20px' : '10px',
                                }}
                                component="div"
                                variant="body1"
                            >
                                Se ha generado el error
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '33.6px' : '28px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 400,
                                        color: '#011B34',
                                        display: 'inline', // Esto asegura que el texto esté en la misma línea
                                    }}
                                    component="span"
                                    variant="body1"
                                >
                                    {' '}
                                    {errorMessage},
                                </Typography>{' '}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '28px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            color: '#3C3C3C',
                            paddingTop: isMobile ? '15%' : '30px',
                        }}
                    >
                        ¿Quieres consultar alguna orden diferente?
                    </Typography>
                    {isMobile ? (
                        <ButtonOrderMobile onClick={startVideo} disabled={cameraActive}>
                            Nueva orden
                        </ButtonOrderMobile>
                    ) : (
                        <ButtonOrder onClick={startVideo} disabled={cameraActive}>
                            Nueva orden
                        </ButtonOrder>
                    )}
                    <video
                        ref={videoRef}
                        style={{width: '100%', display: cameraActive ? 'block' : 'none'}}
                    />
                    <canvas ref={canvasRef} style={{display: 'none'}} />
                </Box>
            )}
        </>
    );
};

export default OrderSuccessGenesis;
