import TableTermsConditions from '@/components/log/components/organisms/TableTermsConditions';
import {FeatureCodeEnum, LogDetailsEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import ButtonDownload from '@components/log/components/atoms/ButtonDownload';
import SearchTermsConditions from '@components/log/components/molecules/SearchTermsConditions';
import {
    fetchGetTermsConditions,
    fetchGetTermsConditionsDownload,
} from '@components/log/redux/actions/TermsConditionActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {IFormTermConditions} from '@log/interface';
import {Box, Typography, useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
const DEFAULT_PAGE = 1;
const TermsConditions: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const currentPageTerms = useSelector((state: RootState) => state.binnacles.pageTerms);
    const limitTerms = useSelector((state: RootState) => state.binnacles.limitTerms);

    const {notAllowed} = useCheckAbilities();

    const schemaFormModal = yup.object({
        dateIni: yup.date().required('Fecha inicio requerido'),
        dateEnd: yup.date().required('Fecha final requerido'),
        user: yup.string(),
    });

    const {handleSubmit, control, watch} = useForm<IFormTermConditions>({
        resolver: yupResolver(schemaFormModal),
    });
    const watchForm = watch();

    const formatDateIni = new Date(watchForm.dateIni);
    const formatDateEnd = new Date(watchForm.dateEnd);

    const json = {
        dateIni: DateTime.fromJSDate(formatDateIni).toFormat('MM-dd-yyyy'),
        dateEnd: DateTime.fromJSDate(formatDateEnd).toFormat('MM-dd-yyyy'),
    };

    const onSubmit = () => {
        const user = watchForm.user ?? '';
        dispatch(fetchGetTermsConditions(token, currentPageTerms, limitTerms, {...json, user}));
    };
    const handleChangeDownload = (format: any) => {
        const formatDateIni = new Date(watchForm.dateIni);
        const formatDateEnd = new Date(watchForm.dateEnd);
        const json = {
            dateIni: DateTime.fromJSDate(formatDateIni).toFormat('MM-dd-yyyy'),
            dateEnd: DateTime.fromJSDate(formatDateEnd).toFormat('MM-dd-yyyy'),
            supplier: watchForm.user ?? '',
        };
        dispatch(fetchGetTermsConditionsDownload(token, format, json));
    };
    useEffect(() => {
        if (token) {
            const json = {
                dateIni: DateTime.now().startOf('month').toFormat('MM-dd-yyyy'),
                dateEnd: DateTime.now().toFormat('MM-dd-yyyy'),
                user: watchForm.user ?? '',
            };

            dispatch(fetchGetTermsConditions(token, DEFAULT_PAGE, limitTerms ?? 10, json));
        }
    }, [dispatch, token]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('terms_conditions.title')}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '20px',
                        padding: '15px',
                        height: '73px',
                        width: '100%',
                        background: '#fff',
                        justifyContent: 'space-between',
                    }}
                >
                    <SearchTermsConditions control={control} watchForm={watchForm} />

                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                        <ButtonDownload
                            handleChangeDownload={handleChangeDownload}
                            format="csv"
                            disabled={notAllowed(
                                FeatureCodeEnum.LOG_DETAILS,
                                LogDetailsEnum.EXPORT_EXCEL_CSV
                            )}
                        />
                        <ButtonDownload
                            handleChangeDownload={handleChangeDownload}
                            format="xlsx"
                            disabled={notAllowed(
                                FeatureCodeEnum.LOG_DETAILS,
                                LogDetailsEnum.EXPORT_EXCEL_CSV
                            )}
                        />
                    </Box>
                </Box>
                <TableTermsConditions json={json} watchForm={watchForm} />
            </Box>
        </Box>
    );
};
export default TermsConditions;
