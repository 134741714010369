import {
    AbiTaxStatusRecord,
    AllPermissions,
    AllPermissionsSettings,
    BannersSettings,
    FeatureCodeEnum,
    FiscalRequirements,
    FrequentSettingsQuestions,
    LegalAgreementsSettings,
    LogDetails,
    ManualsSettings,
    ProfilePortal,
    ROLE_PERMISSIONS,
    rulesMerchandiseReception,
    SupplierTaxStatusRecord,
    URLPermissionsSettings,
    UsersPermissions,
} from '@/enums/permissions.enum';
import {useAbilities} from '@/hooks/useAbilities';

const useCheckAbilities = () => {
    const {abilities: ability} = useAbilities();
    const check = (feature: FeatureCodeEnum, action: AllPermissions) => {
        const value = ability.can(action, feature);

        return value;
    };
    const atLeastOnePermission = () => {
        const value = AllPermissionsSettings.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOnePermissionAbiTax = () => {
        const value = AbiTaxStatusRecord.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOnePermissionMerchandiseReceptionQR = () => {
        const value = rulesMerchandiseReception.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const notCheck = (feature: FeatureCodeEnum, action: AllPermissions) => {
        const value = ability.cannot(action, feature);

        return value;
    };

    const atLeastOneURLPermissionsSettings = () => {
        const value = URLPermissionsSettings.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneManualsSettings = () => {
        const value = ManualsSettings.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneBannersSettings = () => {
        const value = BannersSettings.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneLegalAgreementsSettings = () => {
        const value = LegalAgreementsSettings.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneFrequentSettingsQuestions = () => {
        const value = FrequentSettingsQuestions.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneLogDetails = () => {
        const value = LogDetails.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneProfilePortal = () => {
        const value = ProfilePortal.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneFiscalRequirements = () => {
        const value = FiscalRequirements.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneAbiTaxStatusRecord = () => {
        const value = AbiTaxStatusRecord.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneSupplierTaxStatusRecord = () => {
        const value = SupplierTaxStatusRecord.filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    const atLeastOneRoles = () => {
        const value = [...ROLE_PERMISSIONS, ...UsersPermissions].filter((permission) => {
            return ability.can(permission.action, permission.subject);
        });
        return value.length > 0;
    };
    return {
        allowed: check,
        notAllowed: notCheck,
        atLeastOnePermission,
        atLeastOnePermissionAbiTax,
        atLeastOnePermissionMerchandiseReceptionQR,
        atLeastOneURLPermissionsSettings,
        atLeastOneManualsSettings,
        atLeastOneBannersSettings,
        atLeastOneLegalAgreementsSettings,
        atLeastOneFrequentSettingsQuestions,
        atLeastOneLogDetails,
        atLeastOneProfilePortal,
        atLeastOneFiscalRequirements,
        atLeastOneAbiTaxStatusRecord,
        atLeastOneSupplierTaxStatusRecord,
        atLeastOneRoles,
    };
};

export default useCheckAbilities;
