import useCheckAbilities from '@/hooks/useCheckAbilities';
import CSFABI from '@components/csf/csfABI/index';
import CSFSupplier from '@components/csf/csfSupplier/index';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const CSFTabs: React.FC = () => {
    const {t} = useTranslation();
    const {atLeastOnePermissionAbiTax, atLeastOneSupplierTaxStatusRecord} = useCheckAbilities();
    const [value, setValue] = useState<string>('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    useEffect(() => {
        const value = atLeastOnePermissionAbiTax() ? '1' : '2';
        setValue(value);
    }, [atLeastOnePermissionAbiTax()]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '27px 23px',
                overflow: 'auto',
            }}
        >
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {atLeastOnePermissionAbiTax() && <Tab label="AbInBev" value="1" />}
                        {atLeastOneSupplierTaxStatusRecord() && (
                            <Tab label={t('suppliers')} value="2" />
                        )}
                    </TabList>
                </Box>
                {atLeastOnePermissionAbiTax() && (
                    <TabPanel value="1">
                        <CSFABI />
                    </TabPanel>
                )}
                {atLeastOneSupplierTaxStatusRecord() && (
                    <TabPanel value="2">
                        <CSFSupplier />
                    </TabPanel>
                )}
            </TabContext>
        </Box>
    );
};

export default CSFTabs;
