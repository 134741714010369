export function getDate(ts, locateDate = true) {
    if (!ts) return '';

    let meses = [
            'Ene',
            'Feb',
            'Mar',
            'Abr',
            'May',
            'Jun',
            'Jul',
            'Ago',
            'Sep',
            'Oct',
            'Nov',
            'Dic',
        ],
        dateAux = new Date(ts),
        date,
        dateSplit,
        dia,
        mes,
        yyy;

    if (locateDate) {
        date = dateAux.toLocaleDateString('es-MX');
        dateSplit = date.split('/');
        dia = dateSplit[0];
        mes = dateSplit[1];
        yyy = dateSplit[2];
    } else {
        dateSplit = ts.split('-');
        dia = dateSplit[2];
        mes = dateSplit[1];
        yyy = dateSplit[0];
    }
    return `${dia}-${meses[mes - 1]}-${yyy}`;
}

export function parseDateFormat(fechaISO, format = 'en', slacher = '/') {
    const fecha = new Date(fechaISO);
    const year = fecha.getUTCFullYear();
    const month = String(fecha.getUTCMonth() + 1).padStart(2, '0');
    const day = String(fecha.getUTCDate()).padStart(2, '0');
    return format === 'en'
        ? `${year}${slacher}${month}${slacher}${day}`
        : `${day}${slacher}${month}${slacher}${year}`;
}

export function getTimeHour(ts) {
    if (!ts) return '';

    let dateAux = new Date(ts),
        date = dateAux.toLocaleTimeString();

    return date;
}
