import {SaveButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import IconDelete from '@/components/UI/atoms/icons/IconDelete.svg';
import IconEdit from '@/components/UI/atoms/icons/IconEdit.svg';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainer,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, ProfilePortalEnum} from '@/enums/permissions.enum';
import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import {ButtonCancel} from '@components/UI/atoms/buttons/ButtonAlternative';
import Image from '@components/perfil/components/Image/Image';
import {ModalLogo} from '@components/perfil/components/modal/ModalLogo';
import {fetchGetPerfil, fetchPutPerfil} from '@components/perfil/redux/actions/PerfilActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {styled} from '@mui/system';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40vw;
    border-radius: 10px;
`;

const Loader = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px; /* Ajusta según el tamaño del CircularProgress */
    margin-left: -12px; /* Ajusta según el tamaño del CircularProgress */
`;

const StyledBox = styled('div')(({color}) => ({
    width: '41px',
    height: '23px',
    borderRadius: '4px',
    backgroundColor: color || 'red',
}));
export const Nav = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main, // Usando color principal del tema
    height: '59px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    color: '#FFF',
    borderRadius: '3px 3px 0 0',
}));
const Perfil: React.FC = () => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const hasConfiguration = useConfigurationUrl();
    const theme = useTheme();
    interface IForm1 {
        color: string;
        catalogId: number;
    }
    interface IForm2 {
        font: string;
        catalogId: number;
    }
    interface IForm3 {
        module: string;
        catalogId: number;
        en: string;
        type: string;
    }
    interface ITable {
        id: string;
        name: string;
        nameEN: string;
        value: string;
    }

    const schemaForm1 = yup.object({
        color: yup.string(),
    });
    const schemaForm2 = yup.object({
        font: yup.string(),
    });
    const schemaForm3 = yup.object({
        module: yup.string(),
        en: yup.string(),
    });

    const {
        handleSubmit: handleSubmitForm1,
        control: controlForm1,
        setValue: setValueForm1,
    } = useForm<IForm1>({resolver: yupResolver(schemaForm1), mode: 'onChange'});

    const onSubmitForm1 = async (data: any) => {
        const json = {
            value: data.color,
        };
        try {
            await dispatch(fetchPutPerfil(token, id, data.catalogId, json));
            closeModal();
        } catch (error) {
            // Código para ejecutar si la acción falla
            // Puedes agregar lógica adicional en caso de error
        }
    };
    const {
        handleSubmit: handleSubmitForm2,
        control: controlForm2,
        setValue: setValueForm2,
    } = useForm<IForm2>({
        resolver: yupResolver(schemaForm2),
        mode: 'onChange',
    });
    const onSubmitForm2 = async (data: any) => {
        const json = {
            value: data.font,
        };
        try {
            await dispatch(fetchPutPerfil(token, id, data.catalogId, json));
            closeModalFont();
        } catch (error) {
            // Código para ejecutar si la acción falla
            // Puedes agregar lógica adicional en caso de error
        }
    };
    const {
        handleSubmit: handleSubmitForm3,
        control: controlForm3,
        setValue: setValueForm3,
    } = useForm<IForm3>({resolver: yupResolver(schemaForm3), mode: 'onChange'});
    const onSubmitForm3 = async (data: any) => {
        const json = {
            value: `${data.type}${data.module.trim()}-EN-${data.en.trim()}`,
        };
        try {
            await dispatch(fetchPutPerfil(token, id, data.catalogId, json));
            closeModalModules();
        } catch (error) {
            // Código para ejecutar si la acción falla
            // Puedes agregar lógica adicional en caso de error
        }
    };
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const id = useSelector((state: RootState) => state.logins.user.enterprise.id);
    const loading = useSelector((state: RootState) => state.perfil.loading);
    const menu = useSelector((state: RootState) => state.perfil.menu);
    const menuconfig = useSelector((state: RootState) => state.perfil.menuconfig);
    const colors = useSelector((state: RootState) => state.perfil.colors);
    const font = useSelector((state: RootState) => state.perfil.font);
    const logo = useSelector((state: RootState) => state.perfil.logo);
    const [isModalOpenColors, setModalOpenColors] = useState<boolean>(false);
    const [isModalOpenFont, setModalOpenFont] = useState<boolean>(false);
    const [isModalOpenModules, setModalOpenModules] = useState<boolean>(false);
    const [isModalOpenModulesConfig, setModalOpenModulesConfig] = useState<boolean>(false);
    const [isModalOpenSettingLogo, setModalOpenSettingLogo] = useState<boolean>(false);
    useEffect(() => {
        if (token) {
            dispatch(fetchGetPerfil(token, id));
        }
    }, [dispatch, token]);

    const openModal = () => {
        setModalOpenColors(true);
    };
    const openModalModule = () => {
        setModalOpenModules(true);
    };
    const openModalModuleConfig = () => {
        setModalOpenModulesConfig(true);
    };
    const editModal = (item: any) => {
        openModal();
        setValueForm1('color', item.value);
        setValueForm1('catalogId', item.id);
    };
    const editModalModule = (item: any, type: string) => {
        openModalModule();
        setValueForm3('type', type);
        setValueForm3('module', item.name);
        setValueForm3('en', item.nameEN);
        setValueForm3('catalogId', item.id);
    };
    const editModalModuleConfig = (item: any, type: string) => {
        openModalModuleConfig();
        setValueForm3('type', type);
        setValueForm3('module', item.name);
        setValueForm3('en', item.nameEN);
        setValueForm3('catalogId', item.id);
    };
    const openModalFont = () => {
        setModalOpenFont(true);
    };
    const editModalFont = (item: any) => {
        openModalFont();
        setValueForm2('font', item.value);
        setValueForm2('catalogId', item.id);
    };
    const closeModal = () => {
        setModalOpenColors(false);
    };
    const closeModalFont = () => {
        setModalOpenFont(false);
    };
    const closeModalModules = () => {
        setModalOpenModules(false);
        closeModalModulesConfig();
    };
    const closeModalModulesConfig = () => {
        setModalOpenModulesConfig(false);
    };
    const deleteLogo = () => {
        try {
            dispatch(
                fetchPutPerfil(token, id, logo.id, {
                    value: '',
                })
            );
        } catch (error) {
            // Código para ejecutar si la acción falla
            // Puedes agregar lógica adicional en caso de error
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '20px 23px',
                overflow: 'auto',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    Branding
                </Typography>
            </Box>

            <Grid container>
                <Grid xs={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 10px 0 10px',
                        }}
                    >
                        <Grid item xs={11} sx={{boxShadow: 0}}>
                            <TableCellHeadInitial>{t('branding.logo')}</TableCellHeadInitial>
                        </Grid>
                        <Grid item xs={1}>
                            <TableCellHeadFinal />
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 10px 0 10px',
                            flexDirection: 'column',
                            backgroundColor: '#FFFFFF',
                        }}
                    >
                        {logo && <Image src={logo.value} />}
                        <Box>
                            <img
                                src={IconEdit}
                                onClick={() => setModalOpenSettingLogo(true)}
                                alt="Editar logo"
                                style={{cursor: 'pointer'}}
                                title={t('manuals.modals.edit')}
                            />
                            <img
                                src={IconDelete}
                                onClick={deleteLogo}
                                style={{cursor: 'pointer'}}
                                alt="Eliminar logo"
                                title={t('manuals.modals.delete')}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={8}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Grid item xs={11} sx={{boxShadow: 0}}>
                            <TableCellHeadInitial>
                                {t('portal_profile.portal_theme')}
                            </TableCellHeadInitial>
                        </Grid>
                        <Grid item xs={1}>
                            <TableCellHeadFinal />
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {loading || menu === null ? (
                            // Muestra el esqueleto de carga mientras se cargan los datos
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            colors.map((item: ITable) => (
                                <>
                                    <Grid item xs={11}>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {item.value}
                                            </Typography>
                                        </TableCell>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <TableCellIcons>
                                            <Can
                                                I={ProfilePortalEnum.CHANGE_THEME}
                                                a={FeatureCodeEnum.PORTAL_PROFILE}
                                            >
                                                <img
                                                    onClick={() => {
                                                        editModal(item);
                                                    }}
                                                    src={IconEdit}
                                                    title={t('manuals.modals.edit')}
                                                />{' '}
                                            </Can>
                                        </TableCellIcons>
                                    </Grid>
                                </>
                            ))
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Grid item xs={11} sx={{boxShadow: 0}}>
                            <TableCellHeadInitial>
                                {t('portal_profile.portal_source')}
                            </TableCellHeadInitial>
                        </Grid>
                        <Grid item xs={1}>
                            <TableCellHeadFinal />
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {loading || menu === null ? (
                            // Muestra el esqueleto de carga mientras se cargan los datos
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            font.map((item: ITable) => (
                                <>
                                    <Grid item xs={11}>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {item.value}
                                            </Typography>
                                        </TableCell>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <TableCellIcons>
                                            <Can
                                                I={ProfilePortalEnum.CHANGE_SOURCE}
                                                a={FeatureCodeEnum.PORTAL_PROFILE}
                                            >
                                                <img
                                                    onClick={() => {
                                                        editModalFont(item);
                                                    }}
                                                    src={IconEdit}
                                                    title={t('manuals.modals.edit')}
                                                />{' '}
                                            </Can>
                                        </TableCellIcons>
                                    </Grid>
                                </>
                            ))
                        )}
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '10px',
                }}
            >
                <TableContainer
                    container
                    width={hasConfiguration ? 'calc(100vw - 330px)' : 'calc(100vw - 96px)'}
                >
                    <Grid item xs={11} sx={{boxShadow: 0}}>
                        <TableCellHeadInitial>
                            {t('portal_profile.module_name')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHeadFinal />
                    </Grid>
                    {loading || menu === null ? (
                        // Muestra el esqueleto de carga mientras se cargan los datos
                        <>
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                        </>
                    ) : (
                        menu.map((item: ITable) => (
                            <>
                                <Grid item xs={11}>
                                    <TableCell
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'self-start',
                                            padding: '10px 10px 10px 10px',
                                            gap: '10px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t('spanish')}:{' '}
                                            <span style={{fontWeight: 'bold'}}>{item.name}</span>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t('english')}:{' '}
                                            <span style={{fontWeight: 'bold'}}>{item.nameEN}</span>
                                        </Typography>
                                    </TableCell>
                                </Grid>

                                <Grid item xs={1}>
                                    <TableCellIcons
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <Can
                                            I={ProfilePortalEnum.RENAME_MODULES}
                                            a={FeatureCodeEnum.PORTAL_PROFILE}
                                        >
                                            <img
                                                onClick={() => {
                                                    editModalModule(item, 'MENU');
                                                }}
                                                src={IconEdit}
                                                title={t('manuals.modals.edit')}
                                            />{' '}
                                        </Can>
                                    </TableCellIcons>
                                </Grid>
                            </>
                        ))
                    )}
                </TableContainer>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: hasConfiguration ? 'calc(100vw - 330px)' : 'calc(100vw - 96px)',
                    }}
                >
                    <Grid item xs={11} sx={{boxShadow: 0}}>
                        <TableCellHeadInitial>
                            {t('portal_profile.name_configuration_menu')}
                        </TableCellHeadInitial>
                        {loading || menu === null ? (
                            // Muestra el esqueleto de carga mientras se cargan los datos
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            menuconfig.map((item: ITable) => (
                                <>
                                    <Grid item xs={12}>
                                        <TableCell
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'self-start',
                                                padding: '10px 10px 10px 10px',
                                                gap: '10px',
                                                justifyContent: 'space-between',
                                                justifyItems: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'self-start',
                                                    gap: '10px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontFamily: theme.typography.fontFamily,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {t('spanish')}:{' '}
                                                    <span style={{fontWeight: 'bold'}}>
                                                        {item.name}
                                                    </span>
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontFamily: theme.typography.fontFamily,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {t('english')}:{' '}
                                                    <span style={{fontWeight: 'bold'}}>
                                                        {item.nameEN}
                                                    </span>
                                                </Typography>
                                            </div>
                                            <img
                                                style={{marginTop: '10px'}}
                                                onClick={() => {
                                                    editModalModuleConfig(item, 'CONFIG');
                                                }}
                                                src={IconEdit}
                                                title={t('manuals.modals.edit')}
                                            />{' '}
                                        </TableCell>
                                    </Grid>
                                </>
                            ))
                        )}
                    </Grid>
                </div>
            </Box>
            <StyledModal open={isModalOpenColors} onClose={closeModal}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            Color del portal
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmitForm1(onSubmitForm1)}
                    >
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Color del portal*"
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: fieldState.error ? '22px' : '0px'}}
                                />
                            )}
                            name="color"
                            control={controlForm1}
                        />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 600,
                                color: '#323232',
                                marginTop: '10px',
                            }}
                        >
                            Color del portal
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                marginTop: '10px',
                            }}
                        >
                            <StyledBox
                                color="#E5B611"
                                onClick={() => setValueForm1('color', '#E5B611')}
                            />
                            <StyledBox
                                color="#F5E003"
                                onClick={() => {
                                    setValueForm1('color', '#F5E003');
                                }}
                            />
                            <StyledBox
                                color="#D1A33C"
                                onClick={() => {
                                    setValueForm1('color', '#D1A33C');
                                }}
                            />
                            <StyledBox
                                color="#325A6D"
                                onClick={() => {
                                    setValueForm1('color', '#325A6D');
                                }}
                            />
                            <StyledBox
                                color="#3F1F14"
                                onClick={() => {
                                    setValueForm1('color', '#3F1F14');
                                }}
                            />
                        </Box>
                        <Box sx={{display: 'flex', paddingTop: '20px'}}>
                            <ButtonCancel onClick={closeModal}>CANCELAR</ButtonCancel>
                            <SaveButton disabled={loading}>
                                {loading ? <Loader size={24} /> : 'GUARDAR'}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>
            <StyledModal open={isModalOpenFont} onClose={closeModalFont}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            Fuente del portal
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmitForm2(onSubmitForm2)}
                    >
                        <Controller
                            name="font"
                            control={controlForm2}
                            render={({field, fieldState}) => (
                                <FormControl
                                    fullWidth
                                    sx={{margin: fieldState.error ? '0px' : '7px 0px'}}
                                >
                                    <InputLabel id="demo-simple-select-label">Fuente</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        label="Fuente de portal"
                                        {...field}
                                        error={!!fieldState.error}
                                    >
                                        <MenuItem value="Arial">Arial</MenuItem>
                                        <MenuItem value="Poppins">Poppins</MenuItem>
                                        <MenuItem value="Montserrat">Montserrat</MenuItem>
                                        <MenuItem value="Roboto">Roboto</MenuItem>
                                    </Select>
                                    {fieldState.error ? (
                                        <FormHelperText error>
                                            {fieldState.error?.message}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                            )}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '40px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModalFont}>NO, CANCELAR</ButtonCancel>
                            <SaveButton disabled={loading}>
                                {loading ? <Loader size={24} /> : 'GUARDAR'}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>
            <StyledModal open={isModalOpenModules} onClose={closeModalModules}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            Menú del portal
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmitForm3(onSubmitForm3)}
                    >
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Modulo del portal* (Español)"
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: fieldState.error ? '22px' : '0px'}}
                                />
                            )}
                            name="module"
                            control={controlForm3}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Modulo del portal* (Ingles)"
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: '22px'}}
                                />
                            )}
                            name="en"
                            control={controlForm3}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '40px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModalModules}>NO, CANCELAR</ButtonCancel>
                            <SaveButton disabled={loading}>
                                {loading ? <Loader size={24} /> : 'GUARDAR'}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>
            <StyledModal open={isModalOpenModulesConfig} onClose={closeModalModulesConfig}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            Menú configuración del portal
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmitForm3(onSubmitForm3)}
                    >
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Memu configuracíon* (Español)"
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: fieldState.error ? '22px' : '0px'}}
                                />
                            )}
                            name="module"
                            control={controlForm3}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Memu configuracíon* (Ingles)"
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: '22px'}}
                                />
                            )}
                            name="en"
                            control={controlForm3}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '40px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModalModules}>NO, CANCELAR</ButtonCancel>
                            <SaveButton disabled={loading}>
                                {loading ? <Loader size={24} /> : 'GUARDAR'}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>
            <ModalLogo
                open={isModalOpenSettingLogo}
                onClose={() => setModalOpenSettingLogo(false)}
                logo={logo}
            />
        </Box>
    );
};

export default Perfil;
