import axios from 'axios';
export function saveLogAction(data, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    axios.post(`${process.env.REACT_APP_BASE_URL}/v2/log-action`, data, {
        headers,
    });
}
