import {DialogActions, SxProps, Theme} from '@mui/material';
import React from 'react';

import {ButtonCancel, SaveButton} from '@/components/faqs/StyledFAQ';
import {TypographyStyled600} from '@/components/UI/atoms/modals/Styled';

interface ButtonActionsProps {
    acceptButtonText: string;
    cancelButtonText: string;
    acceptSx?: SxProps<Theme>;
    cancelSx?: SxProps<Theme>;
    enabledAcceptButton: boolean;
    onCancel: () => void;
    onAccept: () => void;
    loading: boolean;
}

export default function ModalActions({
    acceptButtonText,
    cancelButtonText,
    acceptSx,
    cancelSx,
    enabledAcceptButton,
    onCancel,
    onAccept,
    loading,
}: ButtonActionsProps): React.JSX.Element {
    return (
        <DialogActions>
            <ButtonCancel
                sx={{
                    backgroundColor: '#921A28',
                    border: `1.5px solid #D3D3D3`,
                    color: '#FFFFFF',
                    margin: '0 10px',
                    textTransform: 'none',
                    width: '180px',
                    ...(cancelSx ? cancelSx : {}),
                }}
                disabled={loading}
                onClick={onCancel}
            >
                <TypographyStyled600 sx={{margin: '5px'}}>{cancelButtonText}</TypographyStyled600>
            </ButtonCancel>
            <SaveButton
                sx={{
                    display: 'block',
                    width: '180px',
                    ...(acceptSx ? acceptSx : {}),
                }}
                disabled={!enabledAcceptButton}
                onClick={onAccept}
            >
                <TypographyStyled600 sx={{margin: '5px'}}>{acceptButtonText}</TypographyStyled600>
            </SaveButton>
        </DialogActions>
    );
}
