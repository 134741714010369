import {createContextualCan} from '@casl/react';
import React, {createContext, memo} from 'react';

import {useAbilities} from '@/hooks/useAbilities';
import {type AnyAbility} from '@casl/ability';

const AbilityContext = createContext<AnyAbility>({} as AnyAbility);
const Can = createContextualCan(AbilityContext.Consumer);
const AbilityProvider: React.FC<{children: React.ReactNode}> = memo(({children}) => {
    const {abilities} = useAbilities();

    return <AbilityContext.Provider value={abilities}>{children}</AbilityContext.Provider>;
});

AbilityProvider.displayName = 'AbilityProvider';

export {AbilityContext, AbilityProvider, Can};
