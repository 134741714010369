import {AppDispatch, RootState} from '@/config/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, Checkbox, Typography, useTheme} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import CustomPagination from '@/components/UI/organisms/pagination/Pagination';
import {LineItemsTable} from '@/components/terms/components/LineItemsTable';
import PurchaseOrderRowLoader from '@/components/terms/components/PurchaseOrderRowLoader';
import {
    fetchGetPoLineItems,
    fetchGetPurchaseOrders,
} from '@/components/terms/redux/actions/TermsAcceptanceActions';

import {IPurchaseOrder} from '@/components/terms/interfaces/terms-purchase-order.interface';
import {
    CLOSE_PO_ACCORDION,
    SELECT_EXTERNAL_IDS,
    SELECT_PURCHASE_ORDERS,
} from '@/components/terms/redux/types/TermsAcceptanceTypes';

interface PurchaseOrdersListProps {
    pos: {
        selectedPOrders: string[];
        setSelectedPOrders: React.Dispatch<React.SetStateAction<string[]>>;
    };
    refs: {
        selectedRefs: string[];
        setSelectedRefs: React.Dispatch<React.SetStateAction<string[]>>;
    };
}

export const PurchaseOrdersList: React.FC<PurchaseOrdersListProps> = ({pos, refs}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const {
        orders,
        ordersLoading: loading,
        ordersLimit: limit,
        ordersPage: currentPage,
        ordersTotalPages: totalPages,
        detailsLimit,
        filters,
        selectedPurchaseOrders,
    } = useSelector((state: RootState) => state.poTermsAcceptance);

    const [expandedPo, setExpandedPo] = useState<string>('');
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        handleCloseAccordion();
        dispatch(fetchGetPurchaseOrders(token, value, limit, filters));
    };

    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetPurchaseOrders(token, 1, event.target.value, filters));
    };

    const handleExpand =
        (purchaseOrderId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedPo(isExpanded ? purchaseOrderId : '');
            if (isExpanded) {
                dispatch(
                    fetchGetPoLineItems({
                        token,
                        page: 1,
                        limit: detailsLimit,
                        filters: {
                            ...filters,
                            purchaseOrderId,
                        },
                    })
                );
            }
        };

    const sxHeadTitle = {
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: theme.typography.fontFamily,
        paddingRight: '10px',
    };

    const sxHeadItem = {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: theme.typography.fontFamily,
        paddingRight: '30px',
    };

    const handleAcceptedTerms = (
        event: React.ChangeEvent<HTMLInputElement>,
        purchaseOrderId: string,
        referenceId: string
    ) => {
        event.preventDefault();
        const isTermsAccepted = event.target.checked;

        if (isTermsAccepted) {
            pos.setSelectedPOrders([...pos.selectedPOrders, purchaseOrderId]);
            refs.setSelectedRefs([...refs.selectedRefs, referenceId]);
        } else {
            pos.setSelectedPOrders(pos.selectedPOrders.filter((po) => po !== purchaseOrderId));
            refs.setSelectedRefs(refs.selectedRefs.filter((ref) => ref !== referenceId));
        }
    };

    const handleCloseAccordion = () => {
        setExpandedPo('');
    };

    useEffect(() => {
        dispatch({
            type: CLOSE_PO_ACCORDION,
            value: handleCloseAccordion,
        });
    });

    useEffect(() => {
        dispatch({type: SELECT_PURCHASE_ORDERS, value: pos.selectedPOrders});
        dispatch({type: SELECT_EXTERNAL_IDS, value: refs.selectedRefs});
    }, [pos.selectedPOrders, refs.selectedRefs]);

    return (
        <Box
            sx={{
                padding: '10px 0',
            }}
        >
            {loading ? (
                <PurchaseOrderRowLoader />
            ) : (
                orders &&
                orders.map((purchaseOrder: IPurchaseOrder, index: number) => {
                    const {
                        id: purchaseOrderId,
                        referenceId,
                        buyerBranchOffice,
                        vendorCompany,
                    } = purchaseOrder;

                    const societyName = buyerBranchOffice?.name || '';
                    const vendorName = vendorCompany?.name || '';

                    return (
                        <Accordion
                            key={`${purchaseOrderId}-${index}`}
                            expanded={`${expandedPo}` === purchaseOrderId}
                            onChange={(event, isExpanded) => {
                                if ((event.target as HTMLInputElement).type !== 'checkbox') {
                                    handleExpand(purchaseOrderId)(event, isExpanded);
                                }
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: {xs: 'column', sm: 'row'},
                                        padding: '15px 15px 15px 0',
                                        background: '#fff',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Checkbox
                                        checked={selectedPurchaseOrders.includes(purchaseOrderId)}
                                        onChange={(event) =>
                                            handleAcceptedTerms(event, purchaseOrderId, referenceId)
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: {xs: 'column', sm: 'row'},
                                        padding: '15px 15px 15px 0',
                                        background: '#fff',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {xs: 'row', sm: 'column'},
                                            alignItems: {xs: 'center', sm: 'flex-start'},
                                            marginBottom: {xs: '10px', sm: '0'},
                                            flexBasis: {xs: '100%', sm: '20%', md: '20%'},
                                            minWidth: '170px',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Typography sx={sxHeadTitle}>
                                            {`${t(
                                                'terms_acceptance.table.headers.po.purchase_order'
                                            )}`}
                                            :
                                        </Typography>
                                        <Typography sx={sxHeadItem}>{referenceId}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {xs: 'row', sm: 'column'},
                                            alignItems: {xs: 'center', sm: 'flex-start'},
                                            marginBottom: {xs: '10px', sm: '0'},
                                            flexBasis: {xs: '100%', sm: '40%', md: '40%'},
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Typography sx={sxHeadTitle}>
                                            {`${t('terms_acceptance.table.headers.po.society')}`}:
                                        </Typography>
                                        <Typography sx={sxHeadItem}>{societyName}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {xs: 'row', sm: 'column'},
                                            alignItems: {xs: 'center', sm: 'flex-start'},
                                            flexBasis: {xs: '100%', sm: '40%', md: '40%'},
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Typography sx={sxHeadTitle}>{t('supplier')}:</Typography>
                                        <Typography sx={sxHeadItem}>{vendorName}</Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LineItemsTable purchaseOrderId={purchaseOrderId} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            )}
            {totalPages > 1 && (
                <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChange={handleChangePage}
                    limit={limit}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </Box>
    );
};
