'use client';
import {
    InputLabelRol,
    StyledFormControlSelect,
    StyledSelect,
} from '@/components/tickets/StyledTicket';
import {FormValues} from '@components/tickets/interfaces';
import {FormHelperText, ListItemText, MenuItem, OutlinedInput} from '@mui/material';
import React, {useState} from 'react';
import {Control, Controller, FieldErrors, UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export type SelectTypeDoubtProps = {
    setValue: UseFormSetValue<FormValues>;
    errors: FieldErrors<FormValues>;
    control: Control<FormValues, any>;
    required: boolean;
};

const doubts = [
    'Cargar/error de factura',
    'Cargar/error de constancias de situación fiscal',
    'Cargar/error complemento de pago',
    'Desbloqueo de usuario',
    'Otros',
];
const SelectTypeDoubt: React.FC<SelectTypeDoubtProps> = ({setValue, errors, control, required}) => {
    const [inputDoubt, setInputDoubt] = useState<string>('');
    const {t} = useTranslation();
    const handleChangeDoubt = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterDoubt(value);
    };
    const cleanFilterDoubt = (value: any) => {
        setInputDoubt(value);

        const newArray = doubts.find((e: string) => e === value);

        setValue('doubts', newArray ?? '');
    };

    return (
        <Controller
            render={({field, fieldState}) => (
                <StyledFormControlSelect
                    sx={{
                        marginTop: '0px',
                        marginBottom: errors?.doubts?.message ? '15px' : '30px',
                    }}
                >
                    <InputLabelRol>
                        {t('ticket.type_doubt')} {required ? '*' : ''}
                    </InputLabelRol>
                    <StyledSelect
                        {...field}
                        value={inputDoubt}
                        onChange={handleChangeDoubt}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected: any) => selected}
                        MenuProps={{
                            sx: {
                                '&& .Mui-selected': {
                                    backgroundColor: '#FFFF',
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            },
                        }}
                        fullWidth
                    >
                        {doubts.map((item: string, index: number) => (
                            <MenuItem key={index} value={item}>
                                <ListItemText primary={item} />
                            </MenuItem>
                        ))}
                    </StyledSelect>
                    {fieldState.error ? (
                        <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                    ) : null}
                </StyledFormControlSelect>
            )}
            control={control}
            name="doubts"
        />
    );
};

export default SelectTypeDoubt;
