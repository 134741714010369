import React from 'react';
import {Box} from '@mui/material';

interface OrderHeaderProps {
    isMobile: boolean;
    logoAbi: string;
}

const OrderHeader: React.FC<OrderHeaderProps> = ({isMobile, logoAbi}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                height: isMobile ? '100px' : '64px',
                background: '#000',
                boxSizing: 'border-box',
                paddingLeft: '60px',
                alignItems: 'center',
                justifyContent: isMobile ? 'flex-start' : 'center',
            }}
        >
            <img src={logoAbi} alt="Logo" />
        </Box>
    );
};

export default OrderHeader;
