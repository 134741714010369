export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const POST_USER = 'POST_USER';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_ERROR = 'POST_USER_ERROR';
export const GET_USER = 'GET_USER_INFO';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_ERROR = 'UPDATE_USER_STATUS_ERROR';
export const SET_FILTERS_USER = 'SET_FILTERS_USER';
export const SET_FILTERS_SUPPLIER = 'SET_FILTERS_SUPPLIER';
export const GET_FILTERS_USER = 'GET_FILTERS_USER';
export const GET_FILTERS_SUPPLIER = 'GET_FILTERS_SUPPLIER';
