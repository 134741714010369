import React from 'react';

const Footer = () => (
    <div>
        <p style={{fontFamily: 'Lato', fontWeight: 700, textAlign: 'center', color: '#677685'}}>
            Powered by
        </p>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <img
                src="http://cdn.mcauto-images-production.sendgrid.net/118c29486c8e22ee/27f39c8c-66e1-499c-b59f-e538d4fcfbd2/92x32.png"
                alt="Powered by"
            />
        </div>
        <p style={{fontFamily: 'Lato', fontWeight: 700, textAlign: 'center', color: '#677685'}}>
            AbInBev &reg, Derechos reservados 2024.
        </p>
        <p
            style={{
                fontFamily: 'Poppins',
                fontSize: '11px',
                fontWeight: 500,
                textAlign: 'center',
                color: '#677685',
            }}
        >
            &copy; 2024 Yaydoo
        </p>
        <div
            style={{
                height: '10px',
                background: 'linear-gradient(180deg, #d3a238 19.26%, #f5e003 100%)',
            }}
        />
    </div>
);
export default Footer;
