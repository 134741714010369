import React from 'react';
import {Box, Divider, SxProps, Typography} from '@mui/material';

import PositionsRow from '@components/orderSimply/components/PositionsRow';
import type {Position} from '@components/orderSimply/components/Positions';
import {useTranslation} from 'react-i18next';

interface PositionsListProps {
    positions: Position[];
    sx?: SxProps;
}

const PositionsList: React.FC<PositionsListProps> = ({positions, sx}): React.JSX.Element => {
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                ...sx,
            }}
        >
            {positions.length === 0 && (
                <Typography sx={{padding: '20px'}}>{t('confirmpoaction.no_positions')}</Typography>
            )}
            {positions.map((positionData, index) => (
                <Box key={`${positionData.productName}-${positionData.position}`}>
                    <PositionsRow
                        productName={positionData.productName}
                        position={positionData.position}
                        deliveryDate={positionData.deliveryDate}
                        unit={positionData.unit}
                        quantity={positionData.quantity}
                        unitPrice={positionData.unitPrice}
                    />
                    {(positions.length < 4 || positions[index + 1]) && <Divider />}
                </Box>
            ))}
        </Box>
    );
};

export default PositionsList;
