export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';
export const GET_TICKET_HOME = 'GE_TICKETa_HOME';
export const GET_TICKET_SUCCESS_HOME = 'GE_TICKET_SUCCESS_HOME';
export const GET_TICKET_ERROR_HOME = 'GE_TICKET_ERROR_HOME';
export const GET_ACCOUNT_STATUS = 'GET_ACCOUNT_STATUS';
export const GET_ACCOUNT_STATUS_SUCCESS = 'GET_ACCOUNT_STATUS_SUCCESS';
export const GET_ACCOUNT_STATUS_ERROR = 'GET_ACCOUNT_STATUS_ERROR';
export const GET_HOME_BANNERS = 'GET_HOME_BANNERS';
export const GET_HOME_BANNERS_SUCCESS = 'GET_HOME_BANNERS_SUCCESS';
export const GET_HOME_BANNERS_ERROR = 'GET_HOME_BANNERS_ERROR';
