'use client';
import {Comment as CommentType} from '@/components/tickets/interfaces';
import {fetchPatchTicket} from '@/components/tickets/redux/actions/TicketActions';
import {
    ButtonCancel,
    Loader,
    RedText,
    SaveButton,
    StyledTextField,
} from '@/components/tickets/StyledTicket';
import {AppDispatch, RootState} from '@/config/store';
import {UploadDragAndDrop} from '@components/tickets/components/inputs';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Divider, Typography, useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
export type CommentProps = {
    closeModal: () => void;
};

const schemaFormModal = yup.object({
    description: yup.string().required('Descripción requerida'),
    file: yup.mixed().required('Archivo requerido'),
});
export interface FormValues {
    id: string;
    agentResolutor: string;
    comment: CommentType;
    description: string;
    file: any;
}
const initialValues: FormValues = {
    id: '',
    agentResolutor: '',
    comment: {
        autor: '',
        message: '',
        created: DateTime.now().toJSDate(),
    },
    description: '',
    file: '',
};
const Comment: React.FC<CommentProps> = ({closeModal}) => {
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const ticket = useSelector((state: RootState) => state.tickets.ticketSelected);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const [loading] = useState<boolean>(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        setValue,
        watch,
    } = useForm<FormValues>({resolver: yupResolver(schemaFormModal), mode: 'onChange'});

    const watchForm = watch();
    const onSubmit = () => {
        dispatch(
            fetchPatchTicket(token, {
                ...watchForm,
                ...ticket,
            })
        );
        reset(initialValues);
        reset();
    };
    // const watchForm = watch();
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 30px',
                    background: '#FFF',
                }}
            >
                <Divider
                    textAlign="left"
                    sx={{
                        '&::before': {width: '0%'},
                        '&::after': {width: '100%', borderColor: '#C5D1D8'},
                        margin: '20px 0 20px 0',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '14px',
                            fontWeight: 600,
                        }}
                    >
                        Comentarios
                    </Typography>
                </Divider>
                <Box display="flex" p={1} flexWrap={'wrap'} sx={{justifyContent: 'space-between'}}>
                    <Controller
                        render={({field, fieldState}) => (
                            <StyledTextField
                                {...field}
                                value={ticket.comments[0].author}
                                id="outlined-required"
                                label="Nombre de Agente Resolutor"
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                                sx={{minWidth: '320px', marginBottom: '15px'}}
                                disabled
                            />
                        )}
                        name="agentResolutor"
                        control={control}
                    />
                </Box>
                <Box>
                    <Controller
                        render={({field, fieldState}) => (
                            <StyledTextField
                                {...field}
                                id="outlined-required"
                                label="Comentarios"
                                multiline
                                value={
                                    ticket.comments.length > 0 &&
                                    ticket.comments[ticket.comments.length - 1].message
                                }
                                rows={4}
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                                sx={{minWidth: '640px', marginBottom: '15px'}}
                                disabled
                            />
                        )}
                        name="comment"
                        control={control}
                    />
                </Box>
                <Divider
                    textAlign="left"
                    sx={{
                        '&::before': {width: '0%'},
                        '&::after': {width: '100%', borderColor: '#C5D1D8'},
                        margin: '20px 0 20px 0',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '14px',
                            fontWeight: 600,
                        }}
                    >
                        Datos a llenar <RedText>* obligatorio</RedText>
                    </Typography>
                </Divider>
                <Box display="flex" justifyContent="space-between">
                    <Controller
                        render={({field, fieldState}) => (
                            <StyledTextField
                                {...field}
                                id="outlined-required"
                                label="Descripción"
                                multiline
                                rows={4}
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                                sx={{minWidth: '320px', marginBottom: '15px'}}
                            />
                        )}
                        name="description"
                        control={control}
                    />

                    <UploadDragAndDrop setValue={setValue} errors={errors} control={control} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    height: '56px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <ButtonCancel onClick={closeModal}>CANCELAR</ButtonCancel>
                <SaveButton disabled={loading} sx={{marginRight: '30px'}}>
                    {loading ? <Loader size={24} /> : 'CONTINUAR'}
                </SaveButton>
            </Box>
        </form>
    );
};

export default Comment;
