import {ILineDetail} from '@/components/purchase-order/confirmTotal/interface';
import {
    CLEAR_PURCHASE_ORDER,
    GET_PURCHASE_ORDER,
    GET_PURCHASE_ORDER_ERROR,
    GET_PURCHASE_ORDER_SUCCESS,
    UPDATED_PURCHASE_ORDER,
    UPDATED_PURCHASE_ORDER_ERROR,
    UPDATED_PURCHASE_ORDER_SUCCESS,
} from '@/components/purchase-order/redux/types/PurchaseOrder';

interface IInitialState {
    lineItems: ILineDetail[];
    purchaseOrder: any;
    purchaseOrderUpdate: any;
    count: number;
    page: number;
    totalPages: number;
    loading: boolean;
    error: boolean;
    limit: number;
}

const initialState: IInitialState = {
    lineItems: [],
    purchaseOrder: null,
    purchaseOrderUpdate: null,
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
};

const purchaseOrderManager = (state = initialState, action: any) => {
    switch (action.type) {
        case CLEAR_PURCHASE_ORDER:
            return {
                ...state,
                purchaseOrderUpdate: null,
            };
        case GET_PURCHASE_ORDER:
            return {
                ...state,
                loading: true,
            };
        case GET_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                purchaseOrder: action.value.data,
                lineItems: action.value.data?.lineItems
                    ? action.value.data.lineItems.sort(
                          (a: ILineDetail, b: ILineDetail) => a.position - b.position
                      )
                    : [],
                loading: false,
            };
        case GET_PURCHASE_ORDER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATED_PURCHASE_ORDER:
            return {
                ...state,
                loading: true,
            };
        case UPDATED_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                purchaseOrderUpdate: action.value.data,
                loading: false,
            };
        case UPDATED_PURCHASE_ORDER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default purchaseOrderManager;
