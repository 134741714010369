'use client';
import {fetchGetCountry} from '@/components/general/country/redux/actions/CountryActions';
import {
    InputLabelRol,
    StyledFormControlSelect,
    StyledSelect,
} from '@/components/tickets/StyledTicket';
import {AppDispatch, RootState} from '@/config/store';
import {FormValues} from '@components/tickets/interfaces';
import {FormHelperText, ListItemText, MenuItem, OutlinedInput} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Control, Controller, FieldErrors, UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

export type SelectCountryProps = {
    setValue: UseFormSetValue<FormValues>;
    errors: FieldErrors<FormValues>;
    control: Control<FormValues, any>;
};
interface ICountry {
    id: number;
    countryName: string;
    countryId: string;
    status: boolean;
    createdAt: Date;
}
const SelectCountry: React.FC<SelectCountryProps> = ({setValue, errors, control}) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const [inputCountry, setInputCountry] = useState<string>('');
    const countryCatalog = useSelector((state: RootState) => state.country.country);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const handleChangeCountry = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterCountry(value);
    };
    const cleanFilterCountry = (value: any) => {
        setInputCountry(value);

        const newArray = countryCatalog.find((e: ICountry) => e.countryName === value);

        setValue('country', newArray.countryName);
    };
    useEffect(() => {
        if (token) {
            dispatch(fetchGetCountry(token));
        }
    }, [token]);

    return (
        <Controller
            render={({field, fieldState}) => (
                <StyledFormControlSelect
                    style={{margin: '10px'}}
                    sx={{
                        marginBottom: errors?.country?.message ? '20px' : '',
                    }}
                >
                    <InputLabelRol> {t('country')}</InputLabelRol>
                    <StyledSelect
                        {...field}
                        value={inputCountry}
                        onChange={handleChangeCountry}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected: any) => selected}
                        MenuProps={{
                            sx: {
                                '&& .Mui-selected': {
                                    backgroundColor: '#FFFF',
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            },
                        }}
                        fullWidth
                    >
                        {countryCatalog
                            .filter((item: ICountry) => item.countryId === 'MXN')
                            .map((item: ICountry, index: number) => (
                                <MenuItem key={index} value={item.countryName}>
                                    <ListItemText primary={item.countryName} />
                                </MenuItem>
                            ))}
                    </StyledSelect>
                    {fieldState.error ? (
                        <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                    ) : null}
                </StyledFormControlSelect>
            )}
            control={control}
            name="country"
        />
    );
};

export default SelectCountry;
