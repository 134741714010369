import WarningToast from '@/components/auth/componenents/WarningToast';
import {
    GET_FORGOT_PASSWORD,
    GET_FORGOT_PASSWORD_ERROR,
    GET_FORGOT_PASSWORD_SUCCESS,
    GET_HISTORY_URL,
    GET_USER_INFO,
    GET_USER_INFO_ABI,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_SUCCESS,
    GET_USER_VALIDATION_ADMIN,
    GET_USER_VALIDATION_ADMIN_ERROR,
    GET_USER_VALIDATION_ADMIN_SUCCESS,
    LOGOUT,
    NEX_STEP_SUPPLIER,
    POST_ENTERPRISE,
    POST_ENTERPRISE_ERROR,
    POST_ENTERPRISE_SUCCESS,
    POST_MDWEB,
    POST_MDWEB_ERROR,
    POST_MDWEB_SUCCESS,
    PUT_UPDATE_PASSWORD,
    PUT_UPDATE_PASSWORD_ERROR,
    PUT_UPDATE_PASSWORD_SUCCESS,
    SET_CREATE_ENTERPRISE_SUCCESS,
    SET_CSF_ALERT,
    SET_ERROR,
    SET_MD_PRELOAD_DATA,
    SET_MODAL_ALERT,
    SET_ORDER_ALERT,
} from '@/components/auth/redux/types/LoginTypes';
import {fetchGetCountry} from '@/components/general/country/redux/actions/CountryActions';
import {fetchGetRoles} from '@/components/general/roles/redux/actions/RolesActions';
import {saveLogAction} from '@/services/route-interceptor/log-action.service';
import {fetchGetPerfil} from '@components/perfil/redux/actions/PerfilActions';
import axios from 'axios';
import ErrorToast from '@/components/auth/componenents/ErrorToast';
import {t} from 'i18next';
export const getLogout = () => {
    return {
        type: LOGOUT,
    };
};
export const getValidateUser = () => {
    return {
        type: GET_USER_VALIDATION_ADMIN,
    };
};
export const getValidateUserSuccess = (result) => {
    return {
        type: GET_USER_VALIDATION_ADMIN_SUCCESS,
        value: result,
    };
};
export const getValidateUserError = (error, result) => {
    return {
        type: GET_USER_VALIDATION_ADMIN_ERROR,
        value: error,
        payload: result,
    };
};

export const setMDPreloadData = (result) => {
    return {
        type: SET_MD_PRELOAD_DATA,
        value: result,
    };
};
export const setNexStepSupplier = (result) => {
    return {
        type: NEX_STEP_SUPPLIER,
        value: result,
    };
};

export const postMdweb = () => {
    return {
        type: POST_MDWEB,
    };
};
export const postMdwebSuccess = (result) => {
    return {
        type: POST_MDWEB_SUCCESS,
        value: result,
    };
};
export const postMdwebError = (error, result) => {
    return {
        type: POST_MDWEB_ERROR,
        value: error,
        payload: result,
    };
};
export const fetchPostCreateMdWeb = (data) => {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch) => {
        dispatch(postMdweb());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/migration`,
                data,
                {headers}
            );
            dispatch(postMdwebSuccess(response.data.data));
        } catch (error) {
            const errorData = error.response.data;
            dispatch(postMdwebError(true));
            if (Object.prototype.hasOwnProperty.call(errorData, 'statusCode')) {
                if (['enterprise.not_found'].includes(errorData.error)) {
                    errorData.message = 'No se encontró la empresa';
                }
                WarningToast({title: errorData.message});
            }
        }
    };
};

export function getValidateUsers(data) {
    const headers = {
        Accept: 'application/json',
    };

    return async (dispatch) => {
        dispatch(getValidateUser());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/auth/login`,
                data,
                {headers}
            );
            const accessToken = response.data.data.accessToken;
            saveLogAction({module: 'auth', action: ''}, accessToken);
            dispatch(getInfoUsers(accessToken));
            dispatch(getValidateUserSuccess(response.data.data));
            dispatch(fetchGetRoles(accessToken));
            dispatch(fetchGetCountry(accessToken));
        } catch (error) {
            const errorData = error.response.data;
            if (Object.prototype.hasOwnProperty.call(errorData, 'statusCode')) {
                if (['mdweb.user_unauthorized', 'users.unauthorized'].includes(errorData.error))
                    errorData.message = 'Usuario o contraseña incorrecta';

                dispatch(getValidateUserError(errorData.message));
                WarningToast({title: errorData.message});
            }
            if (Object.prototype.hasOwnProperty.call(errorData, 'data')) {
                if (errorData.data && errorData.data.status && !errorData.data.status.hasMdWeb) {
                    dispatch(setMDPreloadData(errorData.data.preload));
                }
            }
        }
    };
}

export const getInfoUser = () => {
    return {
        type: GET_USER_INFO,
    };
};
export const getInfoUserSuccess = (result) => {
    return {
        type: GET_USER_INFO_SUCCESS,
        value: result,
    };
};
export const getInfoUserError = (error, result) => {
    return {
        type: GET_USER_INFO_ERROR,
        value: error,
        payload: result,
    };
};

export function getInfoUsers(token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getInfoUser());
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v2/users/me`, {
                headers,
            });
            dispatch(getInfoUserSuccess(response.data.data));
            dispatch(fetchGetPerfil(token, response.data.data.enterprise.id));
            dispatch(fetchOrderAlert(token));
        } catch (error) {
            dispatch(getInfoUserError(true));
        }
    };
}

export const postRecoveryPassword = () => {
    return {
        type: GET_FORGOT_PASSWORD,
    };
};
export const postRecoveryPasswordSuccess = (result) => {
    return {
        type: GET_FORGOT_PASSWORD_SUCCESS,
        value: result,
    };
};
export const postRecoveryPasswordError = (error, result) => {
    return {
        type: GET_FORGOT_PASSWORD_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostRecoveryPassword(data, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postRecoveryPassword());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/auth/recovery-password`,
                data,
                {headers}
            );
            dispatch(postRecoveryPasswordSuccess(response.data.data));
        } catch (error) {
            dispatch(postRecoveryPasswordError(true));
        }
    };
}

export const putUpdatePassword = () => {
    return {
        type: PUT_UPDATE_PASSWORD,
    };
};
export const putUpdatePasswordSuccess = (result) => {
    return {
        type: PUT_UPDATE_PASSWORD_SUCCESS,
        value: result,
    };
};
export const putUpdatePasswordError = (error, result) => {
    return {
        type: PUT_UPDATE_PASSWORD_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutUpdatePassword(data, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putUpdatePassword());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/auth/update-password`,
                data,
                {headers}
            );
            dispatch(putUpdatePasswordSuccess(response.data.data));
        } catch (error) {
            dispatch(putUpdatePasswordError(true));
        }
    };
}

export const postCreateEnterprise = () => {
    return {
        type: POST_ENTERPRISE,
    };
};
export const postCreateEnterpriseSuccess = (result) => {
    return {
        type: POST_ENTERPRISE_SUCCESS,
        value: result,
    };
};
export const postCreateEnterpriseError = (error, result) => {
    return {
        type: POST_ENTERPRISE_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostCreateEnterprise(data) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch) => {
        dispatch(postCreateEnterprise());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise`,
                data,
                {headers}
            );

            const responseData = response.data.data;
            if (Object.prototype.hasOwnProperty.call(responseData, 'preload')) {
                if (responseData && responseData.status && !responseData.status.hasMdWeb) {
                    dispatch(setMDPreloadData(responseData.preload));
                }
            } else {
                dispatch(postCreateEnterpriseSuccess(data));
            }
        } catch (error) {
            const errorData = error.response.data;

            if (Object.prototype.hasOwnProperty.call(errorData, 'statusCode')) {
                if (['enterprise.already_exists'].includes(errorData.error))
                    errorData.message = '¡Empresa ya registrada!';

                dispatch(postCreateEnterpriseError({...errorData, message: errorData.message}));
            } else {
                dispatch(postCreateEnterpriseError(error.response.data));
            }
        }
    };
}
export function fetchPostValidateEnterprise(data) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/validate-tax-id`,
                data,
                {headers}
            );

            const responseData = response.data.data;
            if (Object.prototype.hasOwnProperty.call(responseData, 'preload')) {
                if (responseData && responseData.status && !responseData.status.hasMdWeb) {
                    dispatch(setMDPreloadData(responseData.preload));
                    dispatch(setNexStepSupplier(false));
                }
            } else {
                dispatch(setNexStepSupplier(true));
            }
        } catch (error) {
            if (error.response.data.error === 'enterprise.already_exists') {
                ErrorToast({title: t('auth.toast.already_exists')});
            } else {
                dispatch(setNexStepSupplier(true));
            }
        }
    };
}

export function setError(result) {
    return {
        type: SET_ERROR,
        value: result,
    };
}
export function setCreateEnterpriseSuccess(result) {
    return {
        type: SET_CREATE_ENTERPRISE_SUCCESS,
        value: result,
    };
}

export const getValidateUserAbi = (result) => {
    return {
        type: GET_USER_INFO_ABI,
        value: result,
    };
};

export const getHistory = (result) => {
    return {
        type: GET_HISTORY_URL,
        value: result,
    };
};

export const setModalAlert = (result) => {
    return {
        type: SET_MODAL_ALERT,
        value: result,
    };
};
export const setOrderAlert = (result) => {
    return {
        type: SET_ORDER_ALERT,
        value: result,
    };
};

export const setCSFAlert = (result) => {
    return {
        type: SET_CSF_ALERT,
        value: result,
    };
};

export const fetchOrderAlert = (token) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/warnings`,
                {
                    headers,
                }
            );
            if (response.data.data.hasPendingBills === true) {
                dispatch(setModalAlert(true));
                dispatch(setOrderAlert(true));
            }
            if (response.data.data.expiredCsf === true) {
                dispatch(setModalAlert(true));
                dispatch(setCSFAlert(true));
            }
        } catch (error) {
            dispatch(getInfoUserError(true));
        }
    };
};
