import TableTermsConditionsDetails from '@/components/log/components/organisms/TableTermsConditionsDetails';
import {
    fetchGetTermsConditionsDetails,
    fetchGetTermsConditionsDetailsDownload,
} from '@/components/log/redux/actions/TermsConditionDetailsActions';
import {FeatureCodeEnum, LogDetailsEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import ButtonDownload from '@components/log/components/atoms/ButtonDownload';
import SearchTermsConditionsDetails from '@components/log/components/molecules/SearchTermsConditionsDetails';
import {hideTermsConditionsDetailsInfo} from '@components/log/redux/actions/TermsConditionActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {IFormTermConditions} from '@log/interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, IconButton, Typography, useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
const DEFAULT_PAGE = 1;
const TermsConditionsDetails: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const currentPageTermsDetails = useSelector(
        (state: RootState) => state.binnacles.pageTermsDetails
    );
    const limitTermsDetails = useSelector((state: RootState) => state.binnacles.limitTermsDetails);
    const vendorBranchOfficeId = useSelector(
        (state: RootState) => state.binnacles.vendorBranchOfficeId
    );
    const nameDetails = useSelector((state: RootState) => state.binnacles.nameDetail);

    const {notAllowed} = useCheckAbilities();

    const schemaFormModal = yup.object({
        dateIni: yup.date().required('Fecha inicio requerido'),
        dateEnd: yup.date().required('Fecha final requerido'),
        user: yup.string(),
    });

    const {handleSubmit, control, watch} = useForm<IFormTermConditions>({
        resolver: yupResolver(schemaFormModal),
    });
    const watchForm = watch();

    const formatDateIni = new Date(watchForm.dateIni);
    const formatDateEnd = new Date(watchForm.dateEnd);

    const json = {
        dateIni: DateTime.fromJSDate(formatDateIni).toFormat('MM-dd-yyyy'),
        dateEnd: DateTime.fromJSDate(formatDateEnd).toFormat('MM-dd-yyyy'),
    };

    const onSubmit = () => {
        const user = watchForm.user ?? '';
        dispatch(
            fetchGetTermsConditionsDetails(
                vendorBranchOfficeId,
                token,
                currentPageTermsDetails,
                limitTermsDetails,
                {...json, user}
            )
        );
    };
    const handleChangeDownload = (format: any) => {
        const formatDateIni = new Date(watchForm.dateIni);
        const formatDateEnd = new Date(watchForm.dateEnd);
        const json = {
            dateIni: DateTime.fromJSDate(formatDateIni).toFormat('MM-dd-yyyy'),
            dateEnd: DateTime.fromJSDate(formatDateEnd).toFormat('MM-dd-yyyy'),
            supplier: watchForm.user ?? '',
        };
        dispatch(fetchGetTermsConditionsDetailsDownload(vendorBranchOfficeId, token, format, json));
    };
    const handleGoBack = () => {
        dispatch(hideTermsConditionsDetailsInfo());
    };
    useEffect(() => {
        if (token) {
            const json = {
                dateIni: DateTime.now().startOf('month').toFormat('MM-dd-yyyy'),
                dateEnd: DateTime.now().toFormat('MM-dd-yyyy'),
                user: watchForm.user ?? '',
            };

            dispatch(
                fetchGetTermsConditionsDetails(
                    vendorBranchOfficeId,
                    token,
                    DEFAULT_PAGE,
                    limitTermsDetails ?? 10,
                    json
                )
            );
        }
    }, [dispatch, token]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '20px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <IconButton onClick={handleGoBack} aria-label="Go back">
                    <ArrowBackIcon />
                </IconButton>
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('terms_conditions.title')}: {nameDetails}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '20px',
                        padding: '15px',
                        height: '73px',
                        width: '100%',
                        background: '#fff',
                        justifyContent: 'space-between',
                    }}
                >
                    <SearchTermsConditionsDetails control={control} watchForm={watchForm} />

                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                        <ButtonDownload
                            handleChangeDownload={handleChangeDownload}
                            format="csv"
                            disabled={notAllowed(
                                FeatureCodeEnum.LOG_DETAILS,
                                LogDetailsEnum.EXPORT_EXCEL_CSV
                            )}
                        />
                        <ButtonDownload
                            handleChangeDownload={handleChangeDownload}
                            format="xlsx"
                            disabled={notAllowed(
                                FeatureCodeEnum.LOG_DETAILS,
                                LogDetailsEnum.EXPORT_EXCEL_CSV
                            )}
                        />
                    </Box>
                </Box>
                <TableTermsConditionsDetails json={json} watchForm={watchForm} />
            </Box>
        </Box>
    );
};
export default TermsConditionsDetails;
