import {
    GET_FISCALREQUIREMENTS,
    GET_FISCALREQUIREMENTS_SUCCESS,
    GET_FISCALREQUIREMENTS_ERROR,
    POST_FISCALREQUIREMENTS,
    POST_FISCALREQUIREMENTS_SUCCESS,
    POST_FISCALREQUIREMENTS_ERROR,
    UPDATE_FISCALREQUIREMENTS,
    UPDATE_FISCALREQUIREMENTS_SUCCESS,
    UPDATE_FISCALREQUIREMENTS_ERROR,
} from '@/components/taxRequirements/redux/types/TaxRequirementsTypes';

const initialState = {
    taxRequirements: '',
    loading: false,
    error: false,
};

const taxRequirements = (state = initialState, action) => {
    switch (action.type) {
        case GET_FISCALREQUIREMENTS:
            return {
                ...state,
                loading: true,
            };
        case GET_FISCALREQUIREMENTS_SUCCESS:
            return {
                ...state,
                taxRequirements: action.value,
                loading: false,
            };
        case GET_FISCALREQUIREMENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_FISCALREQUIREMENTS:
            return {
                ...state,
                loading: true,
            };
        case POST_FISCALREQUIREMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_FISCALREQUIREMENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case UPDATE_FISCALREQUIREMENTS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_FISCALREQUIREMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_FISCALREQUIREMENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default taxRequirements;
