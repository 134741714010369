import {
    GENESIS_GET_ORDER,
    GENESIS_GET_ORDER_SUCCESS,
    GENESIS_GET_ORDER_ERROR,
    GENESIS_POST_ORDER,
    GENESIS_POST_ORDER_SUCCESS,
    GENESIS_POST_ORDER_ERROR,
    GENESIS_GET_ORDER_INCOMPLETE,
    GENESIS_GET_ORDER_INCOMPLETE_SUCCESS,
    GENESIS_GET_ORDER_INCOMPLETE_ERROR,
    GENESIS_RESET_ORDER_INCOMPLETE_SUCCESS,
    GENESIS_RESET_ORDER_SUCCESS,
} from '@/components/orderGenesis/redux/types/OrderTypes';

const initialState = {
    order: null,
    orderSuccess: null,
    orderIncomplete: null,
    orderId: null,
    loading: false,
    error: false,
    loadingpost: false,
    errorMessage: '',
    referenceId: null,
};

const orderGenesis = (state = initialState, action) => {
    switch (action.type) {
        case GENESIS_GET_ORDER:
            return {
                ...state,
                order: null,
                loading: true,
                error: false,
            };
        case GENESIS_GET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.value.data,
                orderSuccess: null,
                loading: false,
            };
        case GENESIS_GET_ORDER_ERROR:
            return {
                ...state,
                error: true,
                order: null,
                loading: false,
            };
        case GENESIS_POST_ORDER:
            return {
                ...state,
                loadingpost: true,
            };
        case GENESIS_POST_ORDER_SUCCESS:
            return {
                ...state,
                orderSuccess: action.value,
                loadingpost: false,
                order: null,
                orderIncomplete: null,
                orderId: null,
            };
        case GENESIS_POST_ORDER_ERROR:
            return {
                ...state,
                error: action.value,
                order: null,
                loadingpost: false,
                errorMessage: action.message,
            };
        case GENESIS_GET_ORDER_INCOMPLETE:
            return {
                ...state,
                loading: true,
            };
        case GENESIS_GET_ORDER_INCOMPLETE_SUCCESS:
            return {
                ...state,
                orderIncomplete: action.value,
                orderId: action.order,
                loading: false,
                referenceId: action.referenceId,
            };
        case GENESIS_GET_ORDER_INCOMPLETE_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GENESIS_RESET_ORDER_INCOMPLETE_SUCCESS:
            return {
                ...state,
                orderIncomplete: null,
                loading: false,
            };
        case GENESIS_RESET_ORDER_SUCCESS:
            return {
                ...state,
                order: null,
                orderSuccess: null,
                orderIncomplete: null,
                orderId: null,
                loading: false,
            };
        default:
            return state;
    }
};

export default orderGenesis;
