import React from 'react';
import {TableRow, TableCell, Skeleton} from '@mui/material';

const withs = [270, 80, 180, 360, 80, 80];
const SkeletonRow = ({columns = 6, width = 0}) => {
    return (
        <TableRow>
            {[...Array(columns)].map((item, index) => (
                <TableCell key={index}>
                    <Skeleton variant="rectangular" width={width === 0 ? withs[index] : width} />
                </TableCell>
            ))}
        </TableRow>
    );
};

export default SkeletonRow;
