import {Card, CardContent, CardMedia, Typography, useTheme} from '@mui/material';
import React, {useEffect} from 'react';
import Carousel from 'react-material-ui-carousel';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {useTranslation} from 'react-i18next';

import defaultBanner from '@images/banner/banner.png';
import {fetchGetHomeBanners} from '@components/home/redux/actions/HomeActions';
import {traslateColumnValue} from '@/config/constants';

export type BannersProps = {
    // types...
};

const Banners: React.FC<BannersProps> = () => {
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.home.loading);
    const banners = useSelector((state: RootState) => state.home.banners);

    const init = async () => {
        if (token) {
            dispatch(fetchGetHomeBanners(token));
        }
    };
    useEffect(() => {
        init();
    }, [token]);

    const user = useSelector((state: RootState) => state.logins.user);
    const hasBanners = Array.isArray(banners) && banners.length > 0;

    const isValidLink = (url: string) => {
        const patron = new RegExp(
            '^(https?:\\/\\/)?(([a-zA-Z0-9$-_@.&+!*"(),;])+\\.)+[a-zA-Z]{2,}(\\/[a-zA-Z0-9$-_@.&+!*"(),;]*)*(\\?([a-zA-Z0-9$-_@.&+!*"(),;=]*))?(#([a-zA-Z0-9$-_@.&+!*"(),;=]*))?$'
        );
        return patron.test(url);
    };

    return (
        <>
            {user.enterprise.status === 'ACTIVE' && (
                <Carousel
                    sx={{
                        height: '350px',
                    }}
                    navButtonsAlwaysVisible={banners.length > 1}
                >
                    {!loading && hasBanners ? (
                        banners.map((banner: any) => {
                            const bannerContent = traslateColumnValue(
                                banner.content,
                                currentLanguage
                            );
                            const bannerName = traslateColumnValue(banner.name, currentLanguage);
                            return (
                                <Card key={banner.id} sx={{maxHeight: 350}}>
                                    <CardMedia
                                        sx={{
                                            height: '300px',
                                        }}
                                        component="img"
                                        image={banner.urlBanner}
                                        alt={bannerName}
                                    />
                                    <CardContent>
                                        <>
                                            {isValidLink(bannerContent) ? (
                                                <a
                                                    href={bannerContent}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: theme.palette.secondary.main,
                                                        fontSize: '24px',
                                                        fontWeight: 400,
                                                    }}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {bannerContent}
                                                </a>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        fontSize: '24px',
                                                        fontFamily: theme.typography.fontFamily,
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {bannerContent}
                                                </Typography>
                                            )}
                                        </>
                                    </CardContent>
                                </Card>
                            );
                        })
                    ) : (
                        <Typography component={'img'} src={defaultBanner} width={'100%'} />
                    )}
                </Carousel>
            )}
        </>
    );
};

export default Banners;
