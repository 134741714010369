import {
    GET_ORDER_RECEPT,
    GET_ORDER_RECEPT_SUCCESS,
    GET_ORDER_RECEPT_ERROR,
    GET_DOWNLOAD_ORDERSRECEPT,
    GET_DOWNLOAD_ORDERSRECEPT_SUCCESS,
    GET_DOWNLOAD_ORDERSRECEPT_ERROR,
} from '@components/orderRecept/redux/types/OrderReceptTypes';

const initialState = {
    list: [],
    loading: true,
    count: 0,
    page: 1,
    limit: 10,
    totalPages: 1,
    error: '',
};

const orderrecept = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER_RECEPT:
            return {
                ...state,
                loding: true,
            };
        case GET_ORDER_RECEPT_SUCCESS:
            return {
                ...state,
                list: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                limit: action.value.metadata.limit,
                loading: false,
            };
        case GET_ORDER_RECEPT_ERROR:
            return {
                ...state,
                list: [],
                loading: false,
                count: 0,
                page: 1,
                limit: 10,
                totalPages: 1,
            };
        case GET_DOWNLOAD_ORDERSRECEPT:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_ORDERSRECEPT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_DOWNLOAD_ORDERSRECEPT_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default orderrecept;
