export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_XML = 'SET_XML';
export const SET_PDF = 'SET_PDF';
export const SET_ERROR_FILES = 'SET_ERROR_FILES';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const SET_FETCH_BILLS = 'SET_FETCH_BILLS';
export const SET_FETCH_BILLS_SUCCESS = 'SET_FETCH_BILLS_SUCCESS';
export const SET_FETCH_BILLS_ERROR = 'SET_FETCH_BILLS_ERROR';
export const CLEAR_BILLS_ERROR = 'CLEAR_BILLS_ERROR';
export const SET_BILLS_ERROR_FLAG = 'SET_BILLS_ERROR_FLAG';
export const CLEAR_BILLS_ERROR_FLAG = 'SET_BILLS_ERROR_FLAG';
export const VALIDATE_COMPLEMENT = 'VALIDATE_COMPLEMENT';
export const VALIDATE_COMPLEMENT_SUCCESS = 'VALIDATE_COMPLEMENT_SUCCESS';
export const VALIDATE_COMPLEMENT_ERROR = 'VALIDATE_COMPLEMENT_ERROR';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR';
export const UPLOAD_PDF_2WM = 'UPLOAD_PDF_2WM';
export const UPLOAD_PDF_2WM_SUCCESS = 'UPLOAD_PDF_2WM_SUCCESS';
export const UPLOAD_PDF_2WM_ERROR = 'UPLOAD_PDF_2WM_ERROR';
