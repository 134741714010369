'use client';

import {fetchGetInfoHome} from '@/components/home/redux/actions/HomeActions';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
    TableRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {TextButtonDetails} from '@/components/UI/molecules/textButton/textButtons';
import {AppDispatch, RootState} from '@/config/store';
import {AccountStatusEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
export type StatusAccountsProps = {
    // types...
};

const StatusAccounts: React.FC<StatusAccountsProps> = ({}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const user = useSelector((state: RootState) => state.logins.user);

    const {allowed} = useCheckAbilities();
    const accountStatus = useSelector((state: RootState) => state.home.accountStatus);
    const loading = useSelector((state: RootState) => state.home.loading);
    const redirect = (id: any) => {
        navigate(`/billingStatement`, {state: {from: 'home'}});
    };

    const init = async () => {
        if (token) {
            const data = {
                supplierId: user.type === 'INTERNAL_USER' ? '' : user.externalId,
                initDate: '',
                endDate: '',
                page: 1,
                limit: 4,
            };
            dispatch(fetchGetInfoHome(token, data));
        }
    };

    useEffect(() => {
        init();
    }, [dispatch, token]);
    return (
        <>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 300,
                }}
            >
                {t('billingStatement.title')}
            </Typography>
            <TableContainerBinnance xs={12} container>
                <TableHeadRow container xs={12}>
                    <Grid item xs={2}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            {t('date')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('concept')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('folio')}</TableCellHead>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('balance')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHeadFinal sx={{fontSize: '9.5px'}}>
                            {t('actions')}
                        </TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {allowed(FeatureCodeEnum.ACCOUNT_STATUS, AccountStatusEnum.SEE_LIST) ? (
                    loading ? (
                        <>
                            <SkeletonRow columns={4} width={180} />
                            <SkeletonRow columns={4} width={180} />
                            <SkeletonRow columns={4} width={180} />
                        </>
                    ) : (
                        accountStatus?.map((item: any, index: any) => {
                            return (
                                <TableRow xs={12} container key={`row-account-${item.id}`}>
                                    <Grid item xs={2}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            {item.paymentDate
                                                ? DateTime.fromISO(item.paymentDate).toFormat(
                                                      'dd/MM/yyyy'
                                                  )
                                                : ''}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            {item?.type
                                                ? t('billingStatement.type_document.' + item.type)
                                                : ''}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            {item.invoiceReference}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            {item.localAmount}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell sx={{fontSize: '9.5px'}}>
                                            <TextButtonDetails
                                                type="button"
                                                onClick={redirect.bind(this, item.id)}
                                            >
                                                {t('view_details')}
                                            </TextButtonDetails>
                                        </TableCell>
                                    </Grid>
                                </TableRow>
                            );
                        })
                    )
                ) : (
                    <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Typography
                            component={'h1'}
                            sx={{
                                fontSize: '16px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                textAlign: 'center',
                            }}
                        >
                            {t('not_allowed_authorization')}
                        </Typography>
                    </Box>
                )}
            </TableContainerBinnance>
        </>
    );
};

export default StatusAccounts;
