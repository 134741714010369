import {AppDispatch, RootState} from '@/config/store';
import {Box, CircularProgress, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {fetchPutPurchaseOrder} from '@/components/purchase-order/redux/actions/PurchaceOrderActions';
import {useTranslation} from 'react-i18next';
const FullConfirmationPOSuccess = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const public_token = queryParams.get('token');
    const {internalId} = useParams();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.purchaceOrderReducers.loading);
    const error = useSelector((state: RootState) => state.purchaceOrderReducers.error);
    const purchaseOrderUpdate = useSelector(
        (state: RootState) => state.purchaceOrderReducers.purchaseOrderUpdate
    );
    const [referenceId, setReferenceId] = useState<string>('');

    useEffect(() => {
        const data = {
            type: 'CONFIRMATION_ACCORDING',
        };
        dispatch(fetchPutPurchaseOrder(public_token || token, data, internalId));
    }, [dispatch, token]);

    useEffect(() => {
        if (purchaseOrderUpdate && !loading && !error) {
            setReferenceId(purchaseOrderUpdate.referenceId);
        }
    }, [purchaseOrderUpdate]);

    const grapperBox = {
        display: 'flex',
        flexDirection: 'column',
        padding: '40px',
        height: '100%',
    };
    const cofirmTextCaptionStyle = {
        color: 'black',
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
    };

    const cofirmTextSubCaptionStyle = {
        marginTop: '25px',
        color: 'black',
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
    };

    return (
        <Box sx={grapperBox}>
            <Typography sx={cofirmTextCaptionStyle}>{t('confirmposimply.title')}</Typography>
            <Typography sx={cofirmTextSubCaptionStyle}>
                {t('confirmation')}:{' '}
                <span style={{color: theme.palette.primary.main}}>
                    {t('consultpo.table.headers.purchase_order')}: #{referenceId}
                </span>
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    border: '1px solid #C2D1D9',
                    marginTop: '20px',
                    padding: '40px',
                    alignItems: 'center',
                }}
            >
                {referenceId === '' ? (
                    <div style={{margin: '20px'}}>
                        <CircularProgress />
                    </div>
                ) : (
                    <svg
                        width="70"
                        height="69"
                        viewBox="0 0 70 69"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M32.8338 66.0172C15.2741 64.8272 1.88067 49.4865 3.0707 31.9268C4.26072 14.367 19.6014 0.973656 37.1611 2.16368C54.7209 3.3537 68.1143 18.6944 66.9242 36.2541C65.7342 53.8138 50.3935 67.2072 32.8338 66.0172ZM36.8005 7.48481C22.1674 6.49312 9.38351 17.6543 8.39182 32.2874C7.40014 46.9205 18.5613 59.7044 33.1944 60.6961C47.8275 61.6878 60.6114 50.5266 61.6031 35.8935C62.5948 21.2604 51.4336 8.47649 36.8005 7.48481ZM33.9561 45.5128C33.654 46.0269 33.3699 46.275 33.0858 46.523C32.2516 47.001 31.9855 46.983 31.4534 46.9469C30.3892 46.8748 29.8931 46.3066 29.1671 45.1883L22.2224 37.2339C21.4963 36.1155 21.5865 34.7853 22.7228 33.7931C23.5751 33.0491 25.1714 33.1573 25.9155 34.0095L31.3721 40.2594L44.769 20.0524C45.1252 18.7401 46.7396 18.5822 48.0338 19.2045C49.31 20.0928 49.4679 21.7072 48.8456 23.0014L33.9561 45.5128Z"
                            fill="#4BBC68"
                        />
                    </svg>
                )}

                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '16px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 600,
                        margin: '20px',
                    }}
                >
                    {t('confirmposimply.estimated_provider')}:
                </Typography>
                <Typography
                    sx={{
                        color: '#333333',
                        fontSize: '14px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                        width: '60%',
                        textAlign: 'center',
                    }}
                >
                    {t('confirmposimply.we_inform')}:{' '}
                    <span style={{color: 'black', fontWeight: 600}}>#{referenceId}</span>
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '40px',
                    alignItems: 'center',
                    marginTop: 'auto',
                }}
            >
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '14px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                        margin: '10px',
                    }}
                >
                    {t('confirmposimply.doubts')}{' '}
                </Typography>
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '14px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                        margin: '10px',
                    }}
                >
                    {t('confirmposimply.need_help')}{' '}
                    <span
                        style={{color: '#1890FF', textDecoration: 'underline', cursor: 'pointer'}}
                    >
                        contacto@abinveb.com
                    </span>
                </Typography>
            </Box>
        </Box>
    );
};

export default FullConfirmationPOSuccess;
