import {FeatureCodeEnum, UsersEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {IRole} from '@components/roles/interface';
import {fetchGetRoles} from '@components/roles/redux/actions/RolesActions';
import {IUser, UserStatus, UserType} from '@components/user/interface';
import {fetchPostUser, fetchPutUser} from '@components/user/redux/actions/UserActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

const InputLabelRol = styled(InputLabel)({
    '&.Mui-focused': {
        color: '#011B34',
    },
    color: '#011B34',
});

export const StyledSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
});

interface IRoleModalProps {
    isModalOpen: boolean;
    userType: UserType;
    closeModal: any;
    method: string;
}

const UserModal = (props: IRoleModalProps) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {isModalOpen, closeModal, method, userType} = props;
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.user.loading);
    // const error = useSelector((state: RootState) => state.rolesManager.error);
    const user = useSelector((state: RootState) => state.user.user);
    const roles = useSelector((state: RootState) => state.rolesManager.roles);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const {notAllowed} = useCheckAbilities();
    const [checkAllRole, setCheckAllRole] = useState(false);

    useEffect(() => {
        if (token) {
            dispatch(fetchGetRoles(token, 1, 0));
        }
    }, [dispatch, token]);

    const schemaFormModal = yup.object({
        firstLastName: yup
            .string()
            .required(t('roles.modals.user_status_messages.name_required'))
            .max(90, t('roles.modals.user_status_messages.name_max')),
        secondLastName: yup
            .string()
            .required(t('roles.modals.user_status_messages.last_name_required'))
            .max(256, t('roles.modals.user_status_messages.last_name_max')),
        externalId: yup
            .string()
            .required('ID Sharp')
            .max(256, t('roles.modals.user_status_messages.external_max')),
        email: yup
            .string()
            .email(t('roles.modals.user_status_messages.email'))
            .required(t('roles.modals.user_status_messages.email_required'))
            .max(256, t('roles.modals.user_status_messages.email_max')),
        roles: yup
            .array()
            .min(1, t('roles.modals.user_status_messages.roles_min'))
            .of(yup.string())
            .required(t('roles.modals.user_status_messages.roles_required')),
    });

    const {handleSubmit, control, reset, setValue} = useForm<IUser>({
        resolver: yupResolver(schemaFormModal),
        mode: 'onChange',
    });

    const handleStoreUser = (user: IUser) => {
        if (!token) return;
        if (method === 'post') {
            dispatch(fetchPostUser(token, user));
        } else {
            dispatch(fetchPutUser(token, user));
        }
        closeModal();
    };

    const handleChangeRole = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterRole(value);
    };
    const hanldeCheckAllRole = () => {
        setCheckAllRole((prev) => {
            cleanFilterRole(
                !prev
                    ? roles.filter((item: IRole) => item.enabled).map((item: IRole) => item.name)
                    : []
            );
            return !prev;
        });
    };
    const cleanFilterRole = (value: any) => {
        const selectedRole =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));

        let newArray = [];

        setSelectedRoles(selectedRole);

        if (selectedRole.length > 0) {
            newArray = selectedRole.map((item: string) => {
                return roles.find((e: IRole) => e.name === item);
            });
        }
        setValue(
            'roles',
            newArray.map((item: IRole) => {
                return item.id;
            })
        );
    };
    useEffect(() => {
        if (method === 'post') {
            setSelectedRoles([]);
            reset({
                id: '',
                email: '',
                role: '',
                firstLastName: '',
                secondLastName: '',
                type: userType,
                roles: [],
                externalId: '',
                status: UserStatus.ACTIVATED,
            });
        }
        if (method === 'put' && !loading) {
            const rolesMapperIds = user.roles
                ? user.roles.filter((item: IRole) => item.enabled).map((item: IRole) => item.id)
                : [];
            const rolesMapperNames = user.roles
                ? user.roles.filter((item: IRole) => item.enabled).map((item: IRole) => item.name)
                : [];
            reset({
                id: user.id,
                email: user.email,
                firstLastName: user.profile.firstLastName,
                secondLastName: user.profile.secondLastName,
                type: user.type,
                roles: rolesMapperIds,
                externalId: user.externalId,
            });
            setSelectedRoles(rolesMapperNames);
        }
    }, [isModalOpen, loading]);
    useEffect(() => {
        setCheckAllRole(
            () => selectedRoles.length === roles.filter((item: IRole) => item.enabled).length
        );
    }, [selectedRoles, roles, isModalOpen]);

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {method === 'post'
                            ? t('roles.modals.user_status.new')
                            : t('roles.modals.user_status.edit')}{' '}
                        {t('roles.modals.user_status.user')}
                        {userType === UserType.INTERNAL_USER
                            ? t('roles.modals.user_status.intern')
                            : t('roles.modals.user_status.provider')}
                    </Typography>
                </Nav>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '15px 10px',
                    }}
                    onSubmit={handleSubmit(handleStoreUser)}
                >
                    <Box display="flex" justifyContent="start" padding="10px">
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.name')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        marginRight: '10px',
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="firstLastName"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.last_name')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="secondLastName"
                            control={control}
                        />
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="ID Sharp"
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        marginRight: '10px',
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="externalId"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.email')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        width: '30vw',
                                    }}
                                />
                            )}
                            name="email"
                            control={control}
                        />
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        <Controller
                            render={({field, fieldState}) => (
                                <FormControl
                                    sx={{
                                        marginRight: '10px',
                                        width: '25vw',
                                    }}
                                >
                                    <InputLabelRol>
                                        {t('roles.modals.user_status.specific_role')}
                                    </InputLabelRol>
                                    <StyledSelect
                                        {...field}
                                        multiple
                                        value={selectedRoles}
                                        onChange={handleChangeRole}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected: any) => selected.join(', ')}
                                        error={!!fieldState.error}
                                        MenuProps={{
                                            sx: {
                                                '&& .Mui-selected': {
                                                    backgroundColor: '#FFFF',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            },
                                        }}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.USERS,
                                            UsersEnum.ASSIGN_ROLE
                                        )}
                                    >
                                        <MenuItem
                                            value={'notCheck'}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    hanldeCheckAllRole();
                                                }, 500);
                                            }}
                                        >
                                            <Checkbox
                                                checked={checkAllRole}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: theme.palette.primary.main,
                                                    },
                                                }}
                                            />
                                            <ListItemText
                                                primary={t('roles.modals.user_status.select_all')}
                                            />
                                        </MenuItem>
                                        {roles
                                            .filter((item: IRole) => item.enabled)
                                            .map((item: IRole, index: number) => (
                                                <MenuItem key={index} value={item.name}>
                                                    <Checkbox
                                                        checked={
                                                            selectedRoles.indexOf(item.name) > -1
                                                        }
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        }}
                                                    />
                                                    <ListItemText primary={item.name} />
                                                </MenuItem>
                                            ))}
                                    </StyledSelect>
                                    {fieldState.error ? (
                                        <FormHelperText error>
                                            {fieldState.error?.message}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                            )}
                            control={control}
                            name="roles"
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height: '56px',
                            alignItems: 'center',
                            margin: '20px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonCancel onClick={closeModal}>
                            {t('cancel').toUpperCase()}
                        </ButtonCancel>
                        <SaveButton disabled={loading}>
                            {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                        </SaveButton>
                    </Box>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default UserModal;
