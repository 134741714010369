import React from 'react';
import Swal from 'sweetalert2';

interface ToastProps {
    title: string;
}

const WarningToast: React.FC<ToastProps> = ({title}) => {
    Swal.fire({
        customClass: {
            container: 'swal-over-all',
        },
        icon: 'warning',
        iconColor: '#ffbd00',
        title,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    });

    return null;
};

export default WarningToast;
