import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';

type Props = {title: string; children?: React.ReactNode};

export const Title: React.FC<Props> = ({title, children}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 0',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 400,
                }}
            >
                {children}
                {title}
            </Typography>
        </Box>
    );
};
