import {styled} from '@mui/system';
import React from 'react';
import {useTranslation} from 'react-i18next';

const StyledStatus = styled('div')(({color, bgColor}) => ({theme}) => ({
    color: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: bgColor,
    fontSize: '9.5px',
    fontWeight: 600,
    fontFamily: theme?.typography?.fontFamily,
    width: '90px',
    height: '30px',
    borderRadius: '4px',
}));

const StatusTextTicket = ({status}) => {
    const {t} = useTranslation();

    const convertStatus = (status) => {
        const mapperStatus = {
            created: 'COMPLETED',
            received: 'RECEIVED',
            in_progress: 'IN_PROGRESS',
            assigned: 'ASSIGNED',
            complete: 'CREATED',
        };
        return mapperStatus[status] || 'Desconocido';
    };

    const getColor = (status) => {
        const statusColors = {
            RECEIVED: '#00ACFF',
            COMPLETED: '#4BBC68',
            IN_PROGRESS: '#F3AF40',
            ASSIGNED: '#00ACFF',
            CREATED: '#FFFFFF',
        };
        return statusColors[status];
    };

    const getColorFromHash = (status) => {
        const hashColors = {
            RECEIVED: 'rgba(0, 172, 255, 0.2)',
            COMPLETED: '#DBF2E1',
            IN_PROGRESS: 'rgba(243, 175, 64, 0.2)',
            ASSIGNED: 'rgba(0, 172, 255, 0.2)',
            CREATED: 'rgba(75, 188, 104, 0.6)',
        };

        return hashColors[status];
    };

    const bgColor = getColorFromHash(convertStatus(status));
    const color = getColor(convertStatus(status));
    const borderColor = getColor(convertStatus(status));
    const statusText = t(`home.tickets_table.status.${status}`);

    return (
        <StyledStatus color={color} bgColor={`${bgColor}`} borderColor={`${borderColor}`}>
            {statusText}
        </StyledStatus>
    );
};

export default StatusTextTicket;
