export const GET_CSFABI = 'GET_CSFABI';
export const GET_CSFABI_SUCCESS = 'GET_CSFABI_SUCCESS';
export const GET_CSFABI_ERROR = 'GET_CSFABI_ERROR';
export const GET_CSFABI_BYID = 'GET_CSFABI_BYID';
export const GET_CSFABI_BYID_SUCCESS = 'GET_CSFABI_BYID_SUCCESS';
export const GET_CSFABI_BYID_ERROR = 'GET_CSFABI_BYID_ERROR';
export const UPDATE_CSFABI = 'UPDATE_CSFABI';
export const UPDATE_CSFABI_SUCCESS = 'UPDATE_CSFABI_SUCCESS';
export const UPDATE_CSFABI_ERROR = 'UPDATE_CSFABI_ERROR';
export const CREATE_FILE_CSFABI = 'CREATE_FILE_CSFABI';
export const CREATE_FILE_CSFABI_SUCCESS = 'CREATE_FILE_CSFABI_SUCCESS';
export const CREATE_FILE_CSFABI_ERROR = 'CREATE_FILE_CSFABI_ERROR';
export const POST_CSFABI = 'POST_CSFABI';
export const POST_CSFABI_SUCCESS = 'POST_CSFABI_SUCCESS';
export const POST_CSFABI_ERROR = 'POST_CSFABI_ERROR';
export const GET_CSFABI_DOWNLOAD = 'GET_CSFABI_DOWNLOAD';
export const GET_CSFABI_DOWNLOAD_SUCCESS = 'GET_CSFABI_DOWNLOAD_SUCCESS';
export const GET_CSFABI_DOWNLOAD_ERROR = 'GET_CSFABI_DOWNLOAD_ERROR';
export const GET_DOWNLOAD_LISTCSF = 'GET_DOWNLOAD_LISTCSF';
export const GET_DOWNLOAD_LISTCSF_SUCCESS = 'GET_DOWNLOAD_LISTCSF_SUCCESS';
export const GET_DOWNLOAD_LISTCSF_ERROR = 'GET_DOWNLOAD_LISTCSF_ERROR';
export const PATCH_FILE_CSF = 'PATCH_FILE_CSF';
export const PATCH_FILE_CSF_SUCCESS = 'PATCH_FILE_CSF_SUCCESS';
export const PATCH_FILE_CSF_ERROR = 'PATCH_FILE_CSF_ERROR';
export const PUT_CSFABI = 'PUT_CSFABI';
export const PUT_CSFABI_SUCCESS = 'PUT_CSFABI_SUCCESS';
export const PUT_CSFABI_ERROR = 'PUT_CSFABI_ERROR';
