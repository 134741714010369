export const POST_URL = 'POST_URL';
export const POST_URL_SUCCESS = 'POST_URL_SUCCESS';
export const POST_URL_ERROR = 'POST_URL_ERROR';
export const GET_URL = 'GET_USER_INFO';
export const GET_URL_SUCCESS = 'GET_URL_SUCCESS';
export const GET_URL_ERROR = 'GET_URL_ERROR';
export const UPDATE_URL = 'UPDATE_URL';
export const UPDATE_URL_SUCCESS = 'UPDATE_URL_SUCCESS';
export const UPDATE_URL_ERROR = 'UPDATE_URL_ERROR';
export const DELETE_URL = 'DELETE_URL';
export const DELETE_URL_SUCCESS = 'DELETE_URL_SUCCESS';
export const DELETE_URL_ERROR = 'DELETE_URL_ERROR';
