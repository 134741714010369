import {ICandidate} from '@components/candidates/interface';
import {fetchDeleteCandidate} from '@components/candidates/redux/actions/CandidateActions';
import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {AppDispatch, RootState} from '@config/store';
import {Box, Modal, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

interface ICandidateModalProps {
    isModalOpen: boolean;
    closeModal: any;
    candidate: ICandidate | null;
}

const CandidateDeleteModal = (props: ICandidateModalProps) => {
    const {isModalOpen, closeModal, candidate} = props;
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const {t} = useTranslation();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.rolesManager.loading);

    const deleteCandidateAction = () => {
        if (token && candidate) {
            dispatch(fetchDeleteCandidate(token, candidate.id));
        }
        closeModal();
    };

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('candidates.delete_candidate')}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {' '}
                            {t('candidates.name_or_business_name')}
                        </span>{' '}
                        {candidate && candidate.socialReason}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {t('candidates.delete_lead')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {t('candidates.operation_irreversible')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}> {t('cancel').toUpperCase()}</ButtonCancel>
                    <SaveButton disabled={loading} onClick={deleteCandidateAction}>
                        {loading ? <Loader size={24} /> : t('apply').toUpperCase()}
                    </SaveButton>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default CandidateDeleteModal;
