import React, {useState, useEffect} from 'react';
import logoAbi from '@order/images/logoAbi.svg';
import {Box, Typography} from '@mui/material';
import logoModeloMini from '@order/images/logoModeloMini.svg';
import {useSelector} from 'react-redux';

function esDispositivoMovil() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}
const NotFound = ({orderData = {}}) => {
    const errorMessage = useSelector((state) => state.order.errorMessage);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(esDispositivoMovil());
    }, []);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                background: '#F1F1F1',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    height: '100px',
                    background: '#000',
                    boxSizing: 'border-box',
                    paddingLeft: '60px',
                    alignItems: 'center',
                    justifyContent: isMobile ? 'flex-start' : 'center',
                }}
            >
                <img src={logoAbi} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 70px)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: '#fff',
                        padding: isMobile ? '60px 60px 52px 60px' : '30px 20%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '45px' : '22px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#011B34',
                            }}
                        >
                            Orden de compra
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '45px' : '22px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#D1A33C',
                                marginLeft: '15px',
                            }}
                        >
                            {orderData ? `#${orderData.referenceId}` : ''}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '22.4px',
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            color: '#011B34',
                        }}
                    >
                        En este apartado encontrarás los detalles de tu orden, así cómo su estatus.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '22.4px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            color: '#011B34',
                            borderTop: '1px solid #000000',
                            marginTop: '20px',
                            paddingTop: '15px',
                        }}
                    >
                        Detalles de proveedor
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                color: '#011B34',
                                marginTop: '5px',
                            }}
                        >
                            Nro. Proveedor:
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                color: '#011B34',
                                maxWidth: '500px',
                                overflow: 'hidden',
                                marginLeft: '10px',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {orderData ? orderData.vendor.externalId : ''}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                color: '#011B34',
                                marginTop: '5px',
                            }}
                        >
                            Razón social:
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '33.6px' : '22.4px',
                                fontFamily: 'Poppins',
                                fontWeight: isMobile ? 500 : 400,
                                color: '#011B34',
                                marginLeft: '10px',
                            }}
                        >
                            {orderData ? orderData.buyer.name : ''}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: isMobile ? '60px' : '25px 20%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '38px' : '25px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            color: '#011B34',
                            alignSelf: 'flex-start',
                        }}
                    >
                        Resumen de orden
                    </Typography>
                    <img src={logoModeloMini} width={isMobile ? '600px' : '300px'} />
                    <Typography
                        sx={{
                            fontSize: isMobile ? '33.6px' : '22.4px',
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            color: '#011B34',
                        }}
                    >
                        {errorMessage}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default NotFound;
