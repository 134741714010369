import {STATUS_TICKETS} from '@/components/tickets/constants/constants';
import {Comment as CommentType, EditFormValues} from '@/components/tickets/interfaces';
import StatusTextTicket from '@/components/UI/atoms/buttons/StatusTextTicket';
import {StyledModal} from '@/components/UI/atoms/modals/Styled';
import {MediaComponent} from '@/components/UI/molecules/MediaComponent';
import {AppDispatch, RootState} from '@/config/store';
import {UploadDragAndDrop} from '@components/tickets/components/inputs';
import {fetchPatchTicket} from '@components/tickets/redux/actions/TicketActions';
import {ButtonCancel, Loader, SaveButton, StyledTextField} from '@components/tickets/StyledTicket';
import {yupResolver} from '@hookform/resolvers/yup';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
    Box,
    Container,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    SvgIcon,
    Typography,
    useTheme,
} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {getActionKey, performActionValues} from '@components/tickets/enums';

const fileCategories: Record<string, string> = {
    // Imágenes
    jpg: 'Imágenes',
    jpeg: 'Imágenes',
    png: 'Imágenes',
    gif: 'Imágenes',
    bmp: 'Imágenes',
    svg: 'Imágenes',
    webp: 'Imágenes',
    tiff: 'Imágenes',

    // Videos
    mp4: 'Video',
    mov: 'Video',
    avi: 'Video',
    mkv: 'Video',
    flv: 'Video',
    wmv: 'Video',
    webm: 'Video',

    // PDF
    pdf: 'PDF',

    // XML
    xml: 'XML',

    // CSV
    csv: 'CSV',

    // Excel
    xlsx: 'XLSX',
    xls: 'XLSX',
};
export type CommentProps = {
    closeModal: () => void;
};
export interface FormValues {
    id: string;
    agentResolutor: string;
    comment: CommentType;
    description: string;
    file: any;
}

const initialValues: EditFormValues = {
    description: '',
    file: '',
};

const HistoryRequest: React.FC<CommentProps> = ({closeModal}) => {
    const {t} = useTranslation();
    const schemaFormModal = yup.object({
        description: yup.string().required(t('banners.modal.errors.description_req')),
        file: yup.mixed().required(t('banners.modal.errors.file_required')),
    });
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const ticket = useSelector((state: RootState) => state.tickets.ticketSelected);
    const user = useSelector((state: RootState) => state.logins.user);
    const [modalPreview, setModalPreview] = useState<boolean>(false);
    const [attachment, setAttachment] = useState<any>({
        mediaType: '',
        mediaSrc: '',
        alt: '',
    });

    const token = useSelector((state: RootState) => state.logins.accessToken);
    const [loading] = useState<boolean>(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        setValue,
        watch,
    } = useForm<EditFormValues>({resolver: yupResolver(schemaFormModal), mode: 'onChange'});
    const closeModalMedia = () => {
        setModalPreview(false);
    };
    const watchForm = watch();
    const onSubmit = () => {
        dispatch(
            fetchPatchTicket(token, {
                ...watchForm,
                id: ticket.id,
            })
        );
        if (ticket.comments.length) {
            ticket.comments.push({
                autor: user.email ?? ' ',
                message: watchForm.description,
                created: DateTime.now().toJSDate(),
            });
        }
        reset(initialValues);
        reset();
    };
    const downloadFile = (item: any) => {
        const url = new URL(item);
        const fileType = fileCategories[`${url.pathname.split('.').pop()}`];
        const fileName = `${ticket.id}-${ticket.action}-${ticket.author ?? ''}.${fileType}`;
        const link = document.createElement('a');
        link.href = item;
        link.target = '_blank';

        link.download = fileName;
        link.click();
    };
    const previewAttachment = (item: any) => {
        const url = new URL(item);
        const fileType = fileCategories[`${url.pathname.split('.').pop()}`];
        if (['XLSX', 'CSV'].includes(fileType)) {
            downloadFile(item);
            return;
        }
        setAttachment({
            mediaType: fileCategories[`${url.pathname.split('.').pop()}`],
            mediaSrc: item,
            alt: '',
        });
        setModalPreview(true);
    };

    return (
        <Container style={{margin: 0, padding: '20px'}}>
            <Paper
                style={{
                    padding: 20,
                    height: '70vh',
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: theme.typography.fontFamily,
                }}
            >
                <Typography variant="h5" gutterBottom>
                    <Divider
                        textAlign="left"
                        sx={{
                            '&::before': {width: '0%'},
                            '&::after': {width: '100%', borderColor: '#C5D1D8'},
                            margin: '20px 0 20px 0',
                        }}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            gap={'10px'}
                        >
                            <StatusTextTicket status={STATUS_TICKETS[ticket.status]} />
                            <Typography
                                sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                Ticket N° {ticket.id} -{' '}
                                {t(
                                    `ticket.perform_action.${
                                        performActionValues[getActionKey(ticket.action)]
                                    }`
                                )}{' '}
                                - {ticket.author ?? ''}
                            </Typography>
                        </Box>
                    </Divider>
                </Typography>
                <Box style={{flexGrow: 1, overflowY: 'auto', marginBottom: 20}}>
                    <List>
                        {ticket?.comments &&
                            ticket?.comments.length > 0 &&
                            ticket.comments.map((message: CommentType, index: number) => (
                                <ListItem
                                    key={
                                        index +
                                        DateTime.fromJSDate(new Date(message.created)).toMillis()
                                    }
                                    style={{
                                        justifyContent: message.autor.includes('@')
                                            ? 'flex-end'
                                            : 'flex-start',
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            display={'flex'}
                                            variant="subtitle2"
                                            color={theme.palette.text.secondary}
                                            sx={{
                                                justifyContent: message.autor.includes('@')
                                                    ? 'flex-end'
                                                    : 'flex-start',
                                            }}
                                        >
                                            {message.autor.includes('@')
                                                ? message.autor.split('@')[0]
                                                : ticket.author ?? t('modal_history_ticket.agent')}
                                        </Typography>
                                        <Paper
                                            sx={{
                                                padding: '10px 15px',
                                                width: '100%',
                                                maxWidth: '500px',
                                                backgroundColor: message.autor.includes('@')
                                                    ? '#e0f7fa'
                                                    : '#eceff1',
                                                justifyContent: message.autor.includes('@')
                                                    ? 'flex-end'
                                                    : 'flex-start',
                                            }}
                                        >
                                            <ListItemText primary={message.message} />
                                            <Typography
                                                variant="caption"
                                                color={theme.palette.text.secondary}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {message?.attachments?.map((item, index) => (
                                                    <SvgIcon
                                                        key={index + DateTime.now().toMillis()}
                                                        onClick={() => previewAttachment(item)}
                                                        component={AttachFileIcon}
                                                        inheritViewBox
                                                        fontSize="small"
                                                        sx={{cursor: 'pointer'}}
                                                    />
                                                ))}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                color={theme.palette.text.secondary}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: message.autor.includes('@')
                                                        ? 'flex-start'
                                                        : 'flex-end',
                                                }}
                                            >
                                                {DateTime.fromJSDate(
                                                    new Date(message.created)
                                                ).toFormat('dd-MM-yyyy HH:mm')}
                                            </Typography>
                                        </Paper>
                                    </Box>
                                </ListItem>
                            ))}
                    </List>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
                        <Controller
                            render={({field, fieldState}) => (
                                <StyledTextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('description')}
                                    multiline
                                    rows={4}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{minWidth: '320px', marginBottom: '15px'}}
                                />
                            )}
                            name="description"
                            control={control}
                        />

                        <UploadDragAndDrop setValue={setValue} errors={errors} control={control} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height: '56px',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonCancel onClick={closeModal}>
                            {t('cancel').toUpperCase()}
                        </ButtonCancel>
                        <SaveButton disabled={loading}>
                            {loading ? <Loader size={24} /> : t('continue').toUpperCase()}
                        </SaveButton>
                    </Box>
                </form>
            </Paper>
            <StyledModal onClose={closeModalMedia} open={modalPreview}>
                <Box sx={{width: '50vw'}}>
                    <MediaComponent type={attachment.mediaType} src={attachment.mediaSrc} />
                </Box>
            </StyledModal>
        </Container>
    );
};

export default HistoryRequest;
