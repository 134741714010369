import React from 'react';
import {Box, Container} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import AvatarWithMenu from '@/components/UI/molecules/avatar/Avatar';
import LanguageSelector from '@/components/UI/molecules/language/LanguageSelector';
import {MainLogo} from '@/components/UI/molecules/images';
import TinkerBell from '@/components/notifications/components/TinkerBell';

function FullPageHeader() {
    const navigate = useNavigate();
    const handleAction = () => navigate('/home');

    return (
        <Box
            sx={{
                display: 'flex',
                background: 'white',
                justifyContent: 'space-between',
                height: '61px',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
            }}
        >
            <MainLogo action={handleAction} />
            <Container>
                <Box />
            </Container>
            <TinkerBell />
            <LanguageSelector />
            <AvatarWithMenu />
        </Box>
    );
}

export default FullPageHeader;
