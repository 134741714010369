import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {IRole} from '@components/roles/interface';
import {ISupplier, UserStatus, UserType} from '@components/user/interface';
import {fetchPostSupplier, fetchPutUser} from '@components/user/redux/actions/UserActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Modal, Select, TextField, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

export const StyledSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
});

interface IRoleModalProps {
    isModalOpen: boolean;
    userType: UserType;
    closeModal: any;
    method: string;
}

const SupplierModal = (props: IRoleModalProps) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {isModalOpen, closeModal, method, userType} = props;
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.user.loading);
    // const error = useSelector((state: RootState) => state.rolesManager.error);
    const user = useSelector((state: RootState) => state.user.user);
    const userLogin = useSelector((state: RootState) => state.logins.user);

    const roles = useSelector((state: RootState) => state.rolesManager.roles);

    const schemaFormModal = yup.object({
        firstLastName: yup
            .string()
            .required(t('roles.modals.user_status_messages.name_required'))
            .max(90, t('roles.modals.user_status_messages.name_max')),
        secondLastName: yup
            .string()
            .required(t('roles.modals.user_status_messages.last_name_required'))
            .max(256, t('roles.modals.user_status_messages.last_name_max')),
        email: yup
            .string()
            .email(t('roles.modals.user_status_messages.email'))
            .required(t('roles.modals.user_status_messages.email_required'))
            .max(256, t('roles.modals.user_status_messages.email_max')),
        password: yup
            .string()
            .matches(/[0-9]/, t('roles.modals.user_status_messages.password_number'))
            .matches(/[a-z]/, t('roles.modals.user_status_messages.password_lowcase'))
            .matches(/[A-Z]/, t('roles.modals.user_status_messages.password_uppercase'))
            .matches(/[^\w]/, t('roles.modals.user_status_messages.password_character')),
        phone: yup.number().required(t('roles.modals.user_status_messages.phone_required')),
    });

    const {handleSubmit, control, reset, setValue, watch} = useForm<ISupplier>({
        resolver: yupResolver(schemaFormModal),
        mode: 'onChange',
    });

    const handleStoreUser = (user: ISupplier) => {
        if (!token) return;
        if (method === 'post') {
            dispatch(fetchPostSupplier(token, user));
        } else {
            dispatch(fetchPutUser(token, user));
        }
        closeModal();
    };
    const watchForm = watch();
    useEffect(() => {
        switch (method) {
            case 'post': {
                reset({
                    id: '',
                    email: '',
                    role: '',
                    roles: [],
                    firstLastName: '',
                    secondLastName: '',
                    type: userType,
                    password: '',
                    phone: '',
                    status: UserStatus.ACTIVATED,
                });
                break;
            }

            case 'put': {
                if (!loading) {
                    reset({
                        id: user.id,
                        email: user.email,
                        password: user.password,
                        phone: user.phone,
                        firstLastName: user.profile.firstLastName,
                        secondLastName: user.profile.secondLastName,
                        type: user.type,
                    });
                }
                break;
            }
        }
    }, [isModalOpen, loading]);

    useEffect(() => {
        if (roles && userLogin) {
            userLogin.rolesAssign.forEach((item: any) => {
                const role = roles.find((role: IRole) => role.name === item);
                if (role) {
                    setValue('roles', [...(watchForm.roles ?? []), role.id]);
                }
            });
        }
    }, [roles, userLogin]);

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {method === 'post'
                            ? t('roles.modals.user_status.new')
                            : t('roles.modals.user_status.edit')}{' '}
                        {t('roles.modals.user_status.user')}
                        {userType === UserType.INTERNAL_USER
                            ? t('roles.modals.user_status.intern')
                            : t('roles.modals.user_status.provider')}
                    </Typography>
                </Nav>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '15px 10px',
                    }}
                    onSubmit={handleSubmit(handleStoreUser)}
                >
                    <Box display="flex" justifyContent="start" padding="10px">
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.name')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        marginRight: '10px',
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="firstLastName"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.last_name')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="secondLastName"
                            control={control}
                        />
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.email')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        marginRight: '10px',
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="email"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.password')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    type="password"
                                    sx={{
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="password"
                            control={control}
                        />
                    </Box>
                    <Box display="flex" justifyContent="start" padding="10px">
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.user_status.phone')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        marginRight: '10px',
                                        width: '25vw',
                                    }}
                                />
                            )}
                            name="phone"
                            control={control}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height: '56px',
                            alignItems: 'center',
                            margin: '20px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonCancel onClick={closeModal}>
                            {t('cancel').toUpperCase()}
                        </ButtonCancel>
                        <SaveButton disabled={loading}>
                            {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                        </SaveButton>
                    </Box>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default SupplierModal;
