interface ValidationError {
    [key: string]: {
        header: string;
        message: string;
    };
}

interface ComplementErrors {
    [key: string]: {
        message: string;
    };
}

export const errorTypes = {
    VOUCHER: 'uploadComplement.error_types.voucher',
    ITEMS: 'uploadComplement.error_types.items',
    COMPLEMENT: 'uploadComplement.error_types.complement',
    RELATED_DOC: 'uploadComplement.error_types.related_doc',
    OTHERS: 'uploadComplement.error_types.others',
};

export const getComplementError = (key: string) => {
    return complementError[key] || complementError.default;
};

export const complementError: ComplementErrors = {
    'File is not complement': {
        message: 'uploadComplement.service_errors.file_not_complement',
    },
    'Complement already exist': {
        message: 'uploadComplement.service_errors.file_already_loaded',
    },
    default: {
        message: 'uploadComplement.service_errors.default_short',
    },
};

export const getValidationError = (key: string) => {
    return validationError[key] || validationError.default;
};

export const validationError: ValidationError = {
    'cfdi.subtotal-cero-error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.subtotal_cero_error',
    },
    'cfdi.tota-error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.tota_error',
    },
    'cfdi.type-error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.tota_error',
    },
    'cfdi.exportation-error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.exportation_error',
    },
    'csf.emiter-zip-code-error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.emiter_zip_code_error',
    },
    'csf.emitter.fiscalRegime.error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.emitter_fiscalRegime',
    },
    'csf.emitter.name.error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.emitter_name',
    },
    'csf.receiver.fiscalAddress.error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.receiver_fiscalAddress',
    },
    'csf.receiver.fiscalRegime.error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.receiver_fiscalRegime',
    },
    'csf.receiver.name.error': {
        header: errorTypes.VOUCHER,
        message: 'uploadComplement.validation_errors.receiver_name',
    },
    'cfdi.product-service-key-error': {
        header: errorTypes.ITEMS,
        message: 'uploadComplement.validation_errors.product_service_key_error',
    },
    'cfdi.consept-amount-error': {
        header: errorTypes.ITEMS,
        message: 'uploadComplement.validation_errors.consept_amount_error',
    },
    'cfdi.concept-unit-value-error': {
        header: errorTypes.ITEMS,
        message: 'uploadComplement.validation_errors.concept_unit_value_error',
    },
    'cfdi.concept-description-error': {
        header: errorTypes.ITEMS,
        message: 'uploadComplement.validation_errors.concept_description_error',
    },
    'cfdi.consept-unit-key-error': {
        header: errorTypes.ITEMS,
        message: 'uploadComplement.validation_errors.consept_unit_key_error',
    },
    'cfdi.concept-sum-error': {
        header: errorTypes.ITEMS,
        message: 'uploadComplement.validation_errors.concept_sum_error',
    },
    'cfdi.concept-obj-imp-error': {
        header: errorTypes.ITEMS,
        message: 'uploadComplement.validation_errors.concept_obj_imp_error',
    },
    'cfdi.complement-version-error': {
        header: errorTypes.COMPLEMENT,
        message: 'uploadComplement.validation_errors.complement_version_error',
    },
    'cfdi.complement-payment-method-error': {
        header: errorTypes.COMPLEMENT,
        message: 'uploadComplement.validation_errors.complement_payment_method_error',
    },
    'cfdi.complement-payment-amounts-error': {
        header: errorTypes.RELATED_DOC,
        message: 'uploadComplement.validation_errors.complement_payment_amounts_error',
    },
    'cfdi.complement-payment-currency-error': {
        header: errorTypes.RELATED_DOC,
        message: 'uploadComplement.validation_errors.complement_payment_currency_error',
    },
    'bill.id-not-found-error': {
        header: errorTypes.RELATED_DOC,
        message: 'uploadComplement.validation_errors.id_not_found_error',
    },
    'bill.currency-not-match-error': {
        header: errorTypes.RELATED_DOC,
        message: 'uploadComplement.validation_errors.currency_not_match_error',
    },
    'bill.amount-not-match-error': {
        header: errorTypes.RELATED_DOC,
        message: 'uploadComplement.validation_errors.amount_not_match_error',
    },
    default: {
        header: errorTypes.OTHERS,
        message: 'uploadComplement.validation_errors.unknown_error',
    },
};
