import {
    CREATE_FILE_CSFSUPPLIER,
    CREATE_FILE_CSFSUPPLIER_ERROR,
    CREATE_FILE_CSFSUPPLIER_SUCCESS,
    GET_CSFSUPPLIER,
    GET_CSFSUPPLIER_DOWNLOAD,
    GET_CSFSUPPLIER_DOWNLOAD_ERROR,
    GET_CSFSUPPLIER_DOWNLOAD_SUCCESS,
    GET_CSFSUPPLIER_ERROR,
    GET_CSFSUPPLIER_SUCCESS,
    GET_DOWNLOAD_LISTCSF,
    GET_DOWNLOAD_LISTCSF_ERROR,
    GET_DOWNLOAD_LISTCSF_SUCCESS,
    POST_CSFSUPPLIER,
    POST_CSFSUPPLIER_ERROR,
    POST_CSFSUPPLIER_SUCCESS,
} from '@components/csf/csfSupplier/redux/types/CsfSupplierTypes';

import {setCSFAlert} from '@components/auth/redux/actions/LoginActions';
import StringUtils from '@/utils/StringUtils';
import axios from 'axios';
export const getCsfSupplier = () => {
    return {
        type: GET_CSFSUPPLIER,
    };
};

export const getCsfSupplierSuccess = (result) => {
    return {
        type: GET_CSFSUPPLIER_SUCCESS,
        value: result,
    };
};

export const getCsfSupplierError = (error, result) => {
    return {
        type: GET_CSFSUPPLIER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetCsfSupplier(token, page, limit, filters) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        const queryString = StringUtils.jsonToQueryString({
            rfc: filters?.rfc ? filters.rfc : '',
            limit,
            page,
            sortOrder: 'DESC',
            enterpriseRole: 'seller',
        });
        dispatch(getCsfSupplier());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/branch-offices${queryString}`,
                {headers}
            );
            dispatch(getCsfSupplierSuccess(response.data));
        } catch (error) {
            dispatch(getCsfSupplierError(true));
        }
    };
}

export const postCsfSupplier = () => {
    return {
        type: POST_CSFSUPPLIER,
    };
};

export const postCsfSupplierSuccess = (result) => {
    return {
        type: POST_CSFSUPPLIER_SUCCESS,
        value: result,
    };
};

export const postCsfSupplierError = (error, result) => {
    return {
        type: POST_CSFSUPPLIER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostCsfSupplier(token, id, data, file) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postCsfSupplier());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/company/${id}/tax-profile`,
                data,
                {headers}
            );
            await dispatch(fetchPostFileCsfSupplier(token, response.data.data.id, file));
            await dispatch(postCsfSupplierSuccess(response.data));
            await dispatch(fetchGetCsfSupplier(token, 1, 10));
            dispatch(setCSFAlert(false));
        } catch (error) {
            dispatch(postCsfSupplierError(true));
        }
    };
}

export const postFileCsf = () => {
    return {
        type: CREATE_FILE_CSFSUPPLIER,
    };
};

export const postFileCsfSuccess = (result) => {
    return {
        type: CREATE_FILE_CSFSUPPLIER_SUCCESS,
        value: result,
    };
};

export const postFileCsfError = (error, result) => {
    return {
        type: CREATE_FILE_CSFSUPPLIER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostFileCsfSupplier(token, id, data) {
    const formData = new FormData();
    formData.append('file', data);
    return async (dispatch) => {
        dispatch(postFileCsf());
        try {
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/v2/enterprise/company/${id}/csf`,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                data: formData,
            };
            const response = await axios.request(config);
            const responsePut = await axios.put(`${response.data.data.url}`, data, {
                headers: {'Content-Type': data.type},
            });
            dispatch(postFileCsfSuccess(responsePut.data.data));
        } catch (error) {
            dispatch(postFileCsfError(true));
        }
    };
}

export const getCsfSupplierDownload = () => {
    return {
        type: GET_CSFSUPPLIER_DOWNLOAD,
    };
};

export const getCsfSupplierDownloadSuccess = (result) => {
    return {
        type: GET_CSFSUPPLIER_DOWNLOAD_SUCCESS,
        value: result,
    };
};

export const getCsfSupplierDownloadError = (error, result) => {
    return {
        type: GET_CSFSUPPLIER_DOWNLOAD_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetCsfSupplierDownload(token, id) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getCsfSupplierDownload());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/enterprise/company/${id}`,
                method: 'GET',
                headers: headers,
            });
            const url = response.data.data.taxProfiles[0].attachment.url;
            const a = document.createElement('a');
            a.href = url;
            a.download = `CSF-${response.data.data.taxProfiles[0].taxId}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            dispatch(getCsfSupplierDownloadSuccess());
        } catch (error) {
            dispatch(getCsfSupplierDownloadError(true));
        }
    };
}

export const getDownloadListCsf = () => {
    return {
        type: GET_DOWNLOAD_LISTCSF,
    };
};

export const getDownloadListCsfSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_LISTCSF_SUCCESS,
        value: result,
    };
};

export const getDownloadListCsfError = (error) => {
    return {
        type: GET_DOWNLOAD_LISTCSF_ERROR,
        value: error,
    };
};

export function fetchGetDownloadListCsfSupplier(token, format, rfc = '') {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getDownloadListCsf());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/enterprise/branch-offices/${format}?rfc=${rfc}&enterpriseRole=seller`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `csfSupplier.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getDownloadListCsfSuccess());
        } catch (error) {
            dispatch(getDownloadListCsfError(true));
        }
    };
}
