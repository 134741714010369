/* eslint-disable no-unused-vars */
import {OrderStatusEnum} from '@/components/orderSimply/utils/orderConfirmHelpers';

export const selectChecboxAllowedStatus = [
    OrderStatusEnum.CONFIRMED,
    OrderStatusEnum.DELIVERED,
    OrderStatusEnum.PARTIALLY_DELIVERED,
    OrderStatusEnum.REJECTED_INVOICE,
];

export enum CompanyTtypes {
    MODELORAMA = 'MODELORAMA',
    ADUANAL_AGENT = 'ADUANAL_AGENT',
    ASSOCIATED = 'ASSOCIATE',
    NATIONAL = 'NATIONAL',
    FOREIGN = 'FOREIGN',
    UNDEFINED = 'UNDEFINED',
}
export const nationalModalAllowed = [
    CompanyTtypes.MODELORAMA,
    CompanyTtypes.ADUANAL_AGENT,
    CompanyTtypes.ASSOCIATED,
    CompanyTtypes.NATIONAL,
    CompanyTtypes.UNDEFINED,
];

export const isObjectEmpty = (objectName: object) => {
    return Object.keys(objectName).length === 0;
};
