'use client';
import {fetchGetInfoTickets} from '@/components/home/redux/actions/HomeActions';
import {STATUS_TICKETS} from '@/components/tickets/constants/constants';
import StatusTextTicket from '@/components/UI/atoms/buttons/StatusTextTicket';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainerBinnance,
    TableHeadRow,
    TableRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {TextButtonDetails} from '@/components/UI/molecules/textButton/textButtons';
import {AppDispatch, RootState} from '@/config/store';
import {FeatureCodeEnum, SupportStatusEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export type TicketsProps = {
    // types...
};

const Tickets: React.FC<TicketsProps> = ({}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const tickets = useSelector((state: RootState) => state.home.ticketsHome);
    const loadingTickets = useSelector((state: RootState) => state.home.loadingTickets);
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const {allowed} = useCheckAbilities();
    const redirect = (id: any) => {
        navigate(`/support/?id=${id}`);
    };
    const init = async () => {
        if (token) {
            const data = {
                limit: 4,
            };

            dispatch(fetchGetInfoTickets(token, data));
        }
    };
    useEffect(() => {
        init();
    }, [token]);

    return (
        <>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 300,
                }}
            >
                {t('home.consultation_status')}
            </Typography>
            <TableContainerBinnance xs={12} container>
                <TableHeadRow container xs={12}>
                    <Grid item xs={4}>
                        <TableCellHeadInitial sx={{fontSize: '9.5px'}}>
                            No. Ticket
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={4}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('home.assigned_to')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={4}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('components.status_selector.title')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={3}>
                        <TableCellHeadFinal sx={{fontSize: '9.5px'}}>
                            {' '}
                            {t('actions')}
                        </TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loadingTickets ? (
                    <>
                        <SkeletonRow columns={4} width={180} />
                        <SkeletonRow columns={4} width={180} />
                        <SkeletonRow columns={4} width={180} />
                    </>
                ) : allowed(FeatureCodeEnum.SUPPORT, SupportStatusEnum.SEE_LIST) ? (
                    tickets.map((item: any, index: any) => {
                        return (
                            <TableRow xs={12} container key={`row-ticket-${index}`}>
                                <Grid item xs={4}>
                                    <TableCell sx={{fontSize: '9.5px'}}>{item.id}</TableCell>
                                </Grid>
                                <Grid item xs={4}>
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        {item.author ?? '---'}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={4}>
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        <StatusTextTicket status={STATUS_TICKETS[item.status]} />
                                    </TableCell>
                                </Grid>
                                <Grid item xs={3}>
                                    <TableCell sx={{fontSize: '9.5px'}}>
                                        <TextButtonDetails
                                            type="button"
                                            onClick={redirect.bind(this, item.id)}
                                            disabled={item.comments?.length === 0 || !item.id}
                                        >
                                            {t('view_details')}
                                        </TextButtonDetails>
                                    </TableCell>
                                </Grid>
                            </TableRow>
                        );
                    })
                ) : (
                    <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Typography
                            component={'h1'}
                            sx={{
                                fontSize: '16px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                textAlign: 'center',
                            }}
                        >
                            {t('not_allowed_authorization')}
                        </Typography>
                    </Box>
                )}
            </TableContainerBinnance>
        </>
    );
};

export default Tickets;
