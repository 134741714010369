import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    useTheme,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Nav} from '@components/faqs/StyledFAQ';
import {ErrorOutlineOutlined} from '@mui/icons-material';
import {PendingActionsOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

export const UploadComplemementResultDialog = ({open, type, handleClose}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [header, setHeader] = useState('');
    const [message, setMessage] = useState('');

    const getType = (type) => {
        const options = {
            ['success']: {
                header: `${t('uploadComplement.will_take_time')}...`,
                message: t('uploadComplement.will_take_time_message'),
            },
            default: {
                header: 'Ups...',
                message: t('uploadComplement.unespected_error_message'),
            },
        };
        return options[type] || options.default;
    };

    const setModalType = () => {
        const {header, message} = getType(type);
        setHeader(header);
        setMessage(message);
    };

    useEffect(setModalType, [type, open]);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Nav>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 700,
                        color: 'white',
                        marginLeft: '10px',
                    }}
                >
                    {header}
                </Typography>
            </Nav>
            <DialogContent>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'20px'}>
                    {type === 'success' && (
                        <PendingActionsOutlined color={'warning'} sx={{fontSize: 50}} />
                    )}
                    {type !== 'success' && (
                        <ErrorOutlineOutlined color={'error'} sx={{fontSize: 50}} />
                    )}
                    <DialogContentText id="alert-dialog-description" align="justify">
                        {message}
                    </DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus variant="contained">
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
