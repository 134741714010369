import React from 'react';
import {Box, SxProps} from '@mui/material';

import {ImageWithAction} from '@/components/UI/molecules/images';
import mainLogo from '@images/mainLogo.svg';

interface MainLogoProps {
    sx?: SxProps;
    action?: () => void;
}

function MainLogo({action, sx}: MainLogoProps) {
    const alt = 'ABInBev';
    const width = '150px';
    const height = 'auto';

    return (
        <Box sx={{padding: '0 10px', ...sx}}>
            <ImageWithAction
                imgSrc={mainLogo}
                alt={alt}
                width={width}
                height={height}
                action={action}
            />
        </Box>
    );
}

MainLogo.defaultProps = {
    sx: {},
    action: () => {},
};

export default MainLogo;
