import Banners from '@/components/banners';
import {IMenuConfig, MenuItemKey} from '@/components/configuration/redux/types/ConfigurationTypes';
import FAQs from '@/components/faqs';
import LegalAgreements from '@/components/legalAgreements/index';
import LegalAgreementsAdmin from '@/components/legalAgreements/indexAdmin';
import Manuals from '@/components/manuals';
import Perfil from '@/components/perfil';
import TaxRequirements from '@/components/taxRequirements';
import Urls from '@/components/urls';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {MenuItemLight} from '@/routers/menu';
import RouteInterceptor from '@/utils/RouterInterceptor';
import Account from '@components/account';
import Logs from '@components/log';
import Roles from '@components/roles';
import {RootState} from '@config/store';
import {Box, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

const Configuration: React.FC = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const lastModuleRender = useSelector(
        (state: RootState) => state.configuration.lastModuleRender
    );
    const menuconfig = useSelector((state: RootState) => state.perfil.menuconfig);
    const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemKey | null>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const hash = location.hash ? location.hash.substring(1) : '';
    const {
        atLeastOneURLPermissionsSettings,
        atLeastOneManualsSettings,
        atLeastOneBannersSettings,
        atLeastOneLegalAgreementsSettings,
        atLeastOneFrequentSettingsQuestions,
        atLeastOneLogDetails,
        atLeastOneProfilePortal,
        atLeastOneFiscalRequirements,
        atLeastOneRoles,
    } = useCheckAbilities();

    // Objeto para mapear las opciones del menú a los componentes correspondientes
    const menuComponents: Record<MenuItemKey, JSX.Element> = {
        urls: <Urls />,
        manuals: <Manuals />,
        banners: <Banners />,
        faqs: <FAQs />,
        legalAgreements: <LegalAgreements />,
        legalAgreementsAdmin: <LegalAgreementsAdmin />,
        taxRequirements: <TaxRequirements />,
        binnacles: <Logs />,
        perfil: <Perfil />,
        roles: <Roles />,
        account: <Account />,
    };

    // Renderiza el componente correspondiente basado en el item de menú seleccionado
    const renderSelectedComponent = (): JSX.Element | null => {
        return selectedMenuItem && showMenu(selectedMenuItem) ? (
            <RouteInterceptor pathname={selectedMenuItem}>
                {menuComponents[selectedMenuItem]}
            </RouteInterceptor>
        ) : null;
    };
    const showMenu = (menu: string) => {
        switch (menu) {
            case 'urls':
                return atLeastOneURLPermissionsSettings();
            case 'manuals':
                return atLeastOneManualsSettings();
            case 'banners':
                return atLeastOneBannersSettings();
            case 'faqs':
                return atLeastOneFrequentSettingsQuestions();
            case 'legalAgreements':
                return atLeastOneLegalAgreementsSettings();

            case 'binnacles':
                return atLeastOneLogDetails();
            case 'perfil': {
                return atLeastOneProfilePortal();
            }
            case 'taxRequirements':
                return atLeastOneFiscalRequirements();
            case 'roles':
                return atLeastOneRoles();

            default:
                return true;
        }
    };
    const removeHash = () => {
        navigate(location.pathname + location.search);
    };

    useEffect(() => {
        if (hash) {
            setSelectedMenuItem(`${hash}` as MenuItemKey);
        }
    }, [location, selectedMenuItem]);

    useEffect(() => {
        if (lastModuleRender !== '') setSelectedMenuItem(lastModuleRender);
    }, [lastModuleRender]);

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    background: '#FFFFFF',
                    justifyContent: 'flex-start',
                    width: '236px',
                }}
            >
                <Typography
                    sx={{
                        color: '#101223',
                        fontSize: '14px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 600,
                        margin: '20px 30px',
                    }}
                >
                    {t('configuration.title')}{' '}
                </Typography>
                {menuconfig
                    .reduce((acc: IMenuConfig[], item: IMenuConfig) => {
                        if (acc.find((x) => x.value === item.value)) return acc;
                        acc.push(item);
                        return acc;
                    }, [])
                    .map((item: IMenuConfig) => {
                        return (
                            showMenu(item.value) && (
                                <MenuItemLight
                                    key={item.value}
                                    onClick={() => {
                                        removeHash();
                                        setSelectedMenuItem(() => item.value);
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#101223',
                                            fontSize: '14px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 500,
                                            marginLeft: '15px',
                                        }}
                                    >
                                        {i18n.language === 'en' ? item.nameEN : item.name}
                                    </Typography>
                                </MenuItemLight>
                            )
                        );
                    })}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 61px)',
                }}
            >
                <Box
                    sx={{
                        background: '#F1F1F1',
                        height: 'calc(100vh - 61px)',
                        width: 'calc(100vw - 286px)',
                    }}
                >
                    {renderSelectedComponent()}
                </Box>
            </Box>
        </Box>
    );
};

export default Configuration;
