'use client';

import IconCloseModal from '@components/UI/atoms/icons/IconCloseModal.svg';
import {ImgIconClose, Nav, StyledModal} from '@components/UI/atoms/modals/Styled';
import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';

export type GenericModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    children: React.ReactNode;
    title: string;
};

const GenericModal: React.FC<GenericModalProps> = ({isModalOpen, closeModal, title, children}) => {
    const theme = useTheme();
    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#EBF0F2',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: 'auto',
                    maxWidth: '740px',

                    zIndex: '100',
                }}
            >
                <Nav sx={{justifyContent: 'space-between'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            paddingLeft: '15px',
                        }}
                    >
                        {title}
                    </Typography>
                    <ImgIconClose
                        src={IconCloseModal}
                        onClick={closeModal}
                        sx={{paddingRight: '15px'}}
                    />
                </Nav>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '20px 30px',
                        background: '#FFF',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </StyledModal>
    );
};

export default GenericModal;
