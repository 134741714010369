import React from 'react';
import {styled} from '@mui/system';
import {useTheme} from '@mui/material';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const AvatarWithNameWrapper = styled(Stack)`
    align-items: center;
`;

const CustomAvatar = styled(Avatar)`
    background-color: ${({bgColor}) => bgColor};
    width: 33px;
    height: 33px;
    color: white;
`;

const AvatarWithName = ({name}) => {
    const theme = useTheme();
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    const initials = name
        .split(' ')
        .map((word) => word[0])
        .slice(0, 2)
        .join('')
        .toUpperCase();

    return (
        <AvatarWithNameWrapper direction="row" spacing={2}>
            <CustomAvatar bgColor={randomColor}>{initials}</CustomAvatar>
            <Typography
                sx={{
                    color: '#515151',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 500,
                    fontSize: '13px',
                }}
            >
                {name}
            </Typography>
        </AvatarWithNameWrapper>
    );
};

export default AvatarWithName;
