import React from 'react';
import {Typography} from '@mui/material';

function Paragraph({children}: {children: React.ReactNode}): React.ReactElement {
    return (
        <Typography paragraph sx={{whiteSpace: 'pre-line'}}>
            {children}
        </Typography>
    );
}

export default Paragraph;
