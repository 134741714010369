'use client';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@/components/tickets/StyledTicket';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, IconButton, Typography} from '@mui/material';
import React, {useState} from 'react';
import {Control, Controller, FieldErrors, UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export type UploadDragAndDropProps = {
    setValue: UseFormSetValue<any>;
    errors: FieldErrors<any>;
    control: Control<any, any>;
};
const ALLOWED_FILE_TYPES = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
    'text/xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'text/comma-separated-values',
    'text/csv',
    'application/csv',
];
const MAX_FILE_SIZE_MB = 10;
const UploadDragAndDrop: React.FC<UploadDragAndDropProps> = ({setValue, errors, control}) => {
    const [errorFile, setErrorFile] = useState<string>('');
    const {t} = useTranslation();
    const handleFileChange = (event: any, type: string) => {
        event.stopPropagation();
        const file = type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0];
        if (!file) {
            setErrorFile(`Archivo requerido.`);
            return;
        }
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setErrorFile('Archivo inválido. Por favor sube un archivo tipo PDF, IMG o MP4.');
            return;
        } else if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(
                `El tamaño del archivo supera los ${MAX_FILE_SIZE_MB} MB. Elija un archivo más pequeño.`
            );
            return;
        } else {
            setErrorFile('');
        }
    };
    return (
        <Controller
            name="file"
            control={control}
            defaultValue={null}
            render={({field, fieldState}) => (
                <FileUploadContainer
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                        e.preventDefault();
                        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                            const currentFiles = field.value ? field.value : [];
                            field.onChange([...currentFiles, ...e.dataTransfer.files]);
                            handleFileChange(e, 'drop');
                        }
                    }}
                    onClick={() => {
                        const fileInput = document.getElementById('fileInput');
                        if (fileInput) {
                            fileInput.click();
                        }
                    }}
                    sx={{
                        borderColor: fieldState.error ? 'red' : 'none',
                        width: '100%',
                        minWidth: '320px',
                        height: '125px',
                        marginLeft: '20px',
                    }}
                >
                    <StyledInput
                        id="fileInput"
                        type="file"
                        accept={ALLOWED_FILE_TYPES.join(',')}
                        size={MAX_FILE_SIZE_MB * 1024 * 1024}
                        onChange={(e) => {
                            const files = e.target.files ? e.target.files : null;
                            if (files) {
                                const currentFiles = field.value ? field.value : [];
                                field.onChange([...currentFiles, ...files]);
                                handleFileChange(e, 'change');
                            }
                        }}
                        multiple
                    />
                    {field.value && !errorFile ? (
                        <>
                            {[...field.value].length > 0 &&
                                [...field.value].map((file: any, index: number) => (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        key={`${file.name}-${index}`}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                mr: 1,
                                            }}
                                        >
                                            {file.name}
                                        </Typography>
                                        <IconButton
                                            aria-label="Eliminar"
                                            onClick={(e) => {
                                                const files = [...field.value].filter(
                                                    (_, i) => i !== index
                                                );
                                                field.onChange(files.length > 0 ? files : null);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                        </>
                    ) : (
                        <Box>
                            <StyledFileLabelButton variant="outlined" as="span">
                                <img src={CloudIcon} />
                                <StyledLabelFileUpload>
                                    {t('drag_upload_files')}
                                </StyledLabelFileUpload>
                            </StyledFileLabelButton>
                            <Typography variant="caption" color="error">
                                {errorFile}
                            </Typography>
                        </Box>
                    )}
                </FileUploadContainer>
            )}
        />
    );
};

export default UploadDragAndDrop;
