import {
    GET_URL,
    GET_URL_SUCCESS,
    GET_URL_ERROR,
    POST_URL,
    POST_URL_SUCCESS,
    POST_URL_ERROR,
    UPDATE_URL,
    UPDATE_URL_SUCCESS,
    UPDATE_URL_ERROR,
    DELETE_URL,
    DELETE_URL_SUCCESS,
    DELETE_URL_ERROR,
} from '@/components/urls/redux/types/UrlsTypes';

import axios from 'axios';
export const getUrls = () => {
    return {
        type: GET_URL,
    };
};
export const getUrlsSuccess = (result) => {
    return {
        type: GET_URL_SUCCESS,
        value: result,
    };
};
export const getUrlsError = (error, result) => {
    return {
        type: GET_URL_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetUrls(token, page, limit) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getUrls());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/url?limit=${limit}&page=${page}&sortOrder=DESC`,
                {headers}
            );
            dispatch(getUrlsSuccess(response.data));
        } catch (error) {
            dispatch(getUrlsError(true));
        }
    };
}

export const postUrls = () => {
    return {
        type: POST_URL,
    };
};
export const postUrlsSuccess = (result) => {
    return {
        type: POST_URL_SUCCESS,
        value: result,
    };
};
export const postUrlsError = (error, result) => {
    return {
        type: POST_URL_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostUrls(token, data) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postUrls());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v2/url`, data, {
                headers,
            });
            dispatch(postUrlsSuccess(response.data.data));
            dispatch(postUrlsError(false));
        } catch (error) {
            dispatch(postUrlsError(true));
        }
    };
}

export const putUrls = () => {
    return {
        type: UPDATE_URL,
    };
};
export const putUrlsSuccess = (result) => {
    return {
        type: UPDATE_URL_SUCCESS,
        value: result,
    };
};
export const putUrlsError = (error, result) => {
    return {
        type: UPDATE_URL_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutUrls(urlId, data, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putUrls());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/url/${urlId}`,
                data,
                {headers}
            );
            dispatch(putUrlsSuccess(response.data.data));
            dispatch(putUrlsError(false));
        } catch (error) {
            dispatch(putUrlsError(true));
        }
    };
}

export const deleteUrls = () => {
    return {
        type: DELETE_URL,
    };
};
export const deleteUrlsSuccess = (result) => {
    return {
        type: DELETE_URL_SUCCESS,
        value: result,
    };
};
export const deleteUrlsError = (error, result) => {
    return {
        type: DELETE_URL_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchDeleteUrls(urlId, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(deleteUrls());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/v2/url/${urlId}`,
                {headers}
            );
            dispatch(deleteUrlsSuccess(response.data.data));
            dispatch(deleteUrlsError(false));
        } catch (error) {
            dispatch(deleteUrlsError(true));
        }
    };
}
