import React, {useState} from 'react';
import {Grid, Box} from '@mui/material';
import IconAdd from '@/components/UI/atoms/icons/IconAdd.svg';
import {
    TableCellChildren,
    TypographyStyled500,
    TypographyStyled600,
} from '@components/faqs/StyledFAQ';

export default function FAQPopperComunication({item}) {
    const [seeAnswer, setSeeAnswer] = useState(false);
    return (
        <>
            <Grid item xs={12}>
                <TableCellChildren sx={{minHeight: '50px'}}>
                    <TypographyStyled600>{item?.question}</TypographyStyled600>
                    <Box sx={{marginLeft: 'auto'}}>
                        <img onClick={() => setSeeAnswer((prev) => !prev)} src={IconAdd} />
                    </Box>
                </TableCellChildren>
            </Grid>
            {seeAnswer && (
                <Grid item xs={12}>
                    <TableCellChildren>
                        <TypographyStyled500>{item?.answer}</TypographyStyled500>
                    </TableCellChildren>
                </Grid>
            )}
        </>
    );
}
