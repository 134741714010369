export const GET_MODULES = 'GET_PMODULES';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_ERROR = 'GET_MODULES_SUCCESS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR';
export const POST_ROLES = 'POST_ROLES';
export const POST_ROLES_SUCCESS = 'POST_ROLES_SUCCESS';
export const POST_ROLES_ERROR = 'POST_ROLES_ERROR';
export const GET_ROLES = 'GET_USER_INFO';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';
export const GET_ROLES_FILE = 'GET_ROLES_FILE';
export const GET_ROLES_FILE_SUCCESS = 'GET_ROLES_FILE_SUCCESS';
export const GET_ROLES_FILE_ERROR = 'GET_ROLES_FILE_ERROR';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const UPDATE_ROLES_SUCCESS = 'UPDATE_ROLES_SUCCESS';
export const UPDATE_ROLES_ERROR = 'UPDATE_ROLES_ERROR';
export const DELETE_ROLES = 'DELETE_ROLES';
export const DELETE_ROLES_SUCCESS = 'DELETE_ROLES_SUCCESS';
export const DELETE_ROLES_ERROR = 'DELETE_ROLES_ERROR';
export const GET_ROLE_BYID = 'GET_ROLE_BYID';
export const GET_ROLE_BYID_SUCCESS = 'GET_ROLE_BYID_SUCCESS';
export const GET_ROLE_BYID_ERROR = 'GET_ROLS_BYID_ERROR';
export const UPDATE_ROLE_STATUS = 'UPDATE_ROLE_STATUS';
export const UPDATE_ROLE_STATUS_SUCCESS = 'UPDATE_ROLE_STATUS_SUCCESS';
export const UPDATE_ROLE_STATUS_ERROR = 'UPDATE_ROLE_STATUS_ERROR';
