/* eslint-disable no-unused-vars */
export enum FeatureCodeEnum {
    MERCHANDISE_RECEIPT_QR = 'merchandise_reception_qr',
    CONFIRM_DELIVERY_SCHEDULE_INVOICE = 'confirm_delivery_schedule_invoice',
    CUSTOMS_AGENTS = 'customs_agents',
    CHECK_STATUS_ORDERS = 'check_status_orders',
    ABI_TAX_STATUS_RECORD = 'abi_tax_status_record',
    SUPPLIER_TAX_STATUS_RECORD = 'supplier_tax_status_record',
    SERVICE_NOW = 'service_now',
    MANUALS = 'manuals',
    FISCAL_REQUIREMENTS = 'fiscal_requirements',
    RECORD = 'record',
    BANNERS = 'banners',
    FREQUENT_QUESTIONS = 'frequent_questions',
    URL = 'url',
    LEGAL_AGREEMENTS = 'legal_agreements',
    LOG_DETAILS = 'log_details',
    USERS = 'users',
    ROLE_PERMISSIONS = 'role_permissions',
    PORTAL_PROFILE = 'portal_profile',
    SUPPLIER = 'supplier',
    ACCOUNT_STATUS = 'account_status',
    SUPPORT = 'support',
    CANDIDATES = 'candidates',
    HOME = 'home',
    PENDING_BILLS = 'pending_bills',
    TERMS_CONDITIONS = 'terms_conditions',
}

export enum CandidatesEnum {
    VIEW_CANDIDATES = 'view_candidates',
    VIEW_PENDING_CANDIDATES = 'view_pending_candidates',
    VIEW_APPROVED_CANDIDATES = 'view_approved_candidates',
    VIEW_REJECTED_CANDIDATES = 'view_rejected_candidates',
    PENDING_CANDIDATES = 'pending_candidates',
    APPROVED_CANDIDATES = 'approved_candidates',
    REJECTED_CANDIDATES = 'rejected_candidates',
    DELETE_CANDIDATES = 'delete_candidates',
    FILTER_CANDIDATES = 'filter_candidates',
    EXPORT_CANDIDATES = 'export_candidates',
}

export const cadidatesReception = Object.values(CandidatesEnum).map((rule) => ({
    action: rule,
    subject: 'candidates',
}));

export enum ROLES {
    INTER_USER = 'USUARIO INTERNO',
    GUEST = 'GUEST',
}

export enum MerchandiseReceptionEnum {
    INPUT_VALIDATION_QR = 'input_validation_merchandise_reception_qr',
    LIST_RECEIPTS_QR = 'list_merchandise_receipts_merchandise_reception_qr',
    EXPORT_CSV_QR = 'export_list_csv_merchandise_reception_qr',
}
export const rulesMerchandiseReception = Object.values(MerchandiseReceptionEnum).map((rule) => ({
    action: rule,
    subject: 'merchandise_reception_qr',
}));

export enum ConfirmDeliveryScheduleInvoiceEnum {
    VIEW_LIST = 'view_list_confirm_delivery_schedule_invoice',
    FIND_PO = 'find_po_confirm_delivery_schedule_invoice',
    FIND_SUPPLIER = 'find_supplier_confirm_delivery_schedule_invoice',
    FILTER = 'filter_confirm_delivery_schedule_invoice',
    REJECT_CONFIRM = 'reject_confirm_delivery_confirm_delivery_schedule_invoice',
    DOWNLOAD_PO = 'download_po_confirm_delivery_schedule_invoice',
    VIEW_ENTRY_SHEET_DETAILS = 'view_entry_sheet_details_confirm_delivery_schedule_invoice',
    UPLOAD_BILLS = 'upload_bills_confirm_delivery_schedule_invoice',
    MASSIVE_LOADS = 'massive_loads_confirm_delivery_schedule_invoice',
    LOAD_PAYMENT_ADD_ON = 'load_payment_add-on_confirm_delivery_schedule_invoice',
    DOWNLOAD_PAYMENT_PLUGIN = 'download_payment_plugin_confirm_delivery_schedule_invoice',
    EXPORT_EXCEL_CSV = 'export_list_excel_xlsx_csv_confirm_delivery_schedule_invoice',
    UPLOAD_CREDIT_NOTE = 'upload_credit_note_confirm_delivery_schedule_invoice',
    UPLOAD_RECEIPT = 'upload_receipt_confirm_delivery_schedule_invoice',
}

export const ConfirmDeliveryScheduleInvoice = Object.values(ConfirmDeliveryScheduleInvoiceEnum).map(
    (rule) => ({
        action: rule,
        subject: 'confirm_delivery_schedule_invoice',
    })
);

export enum CustomsAgentsEnum {
    VIEW_LIST = 'view_list_customs_agents',
    FIND_PO = 'find_po_customs_agents',
    FIND_SUPPLIER = 'find_supplier_customs_agents',
    FILTER = 'filter_customs_agents',
    FILTER_AA = 'filter_aa_customs_agents',
    UPLOAD_BILLS = 'upload_bills_customs_agents',
    LOAD_PAYMENT_ADD_ON = 'load_payment_add-on_customs_agents',
    DOWNLOAD_PAYMENT_PLUGIN = 'download_payment_plugin_customs_agents',
    EXPORT_EXCEL_CSV = 'export_list_excel_xlsx_csv_customs_agents',
}

export const CustomsAgents = Object.values(CustomsAgentsEnum).map((rule) => ({
    action: rule,
    subject: 'customs_agents',
}));

export enum CheckStatusOrdersEnum {
    VIEW_LIST = 'view_list_check_status_orders',
    SEARCH_PROVIDER = 'search_provider_check_status_orders',
    FILTER_STATE = 'filter_state_check_status_orders',
    UPLOAD_BILLS = 'upload_bills_check_status_orders',
    LOAD_PAYMENT_ADD_ON = 'load_payment_add-on_check_status_orders',
    DOWNLOAD_PAYMENT_PLUGIN = 'download_payment_plugin_check_status_orders',
    EXPORT_EXCEL_CSV = 'export_list_excel_xlsx_csv_check_status_orders',
}

export const CheckStatusOrders = Object.values(CheckStatusOrdersEnum).map((rule) => ({
    action: rule,
    subject: 'check_status_orders',
}));

export enum AbiTaxStatusRecordEnum {
    FIND_RFC = 'find_rfc_abi_tax_status_record',
    EXPORT_EXCEL_CSV = 'export_list_excel_xlsx_csv_abi_tax_status_record',
    LOAD = 'load_abi_tax_status_record',
    UPLOAD = 'upload_abi_tax_status_record',
    DOWNLOAD = 'download_abi_tax_status_record',
}

export const AbiTaxStatusRecord = Object.values(AbiTaxStatusRecordEnum).map((rule) => ({
    action: rule,
    subject: 'abi_tax_status_record',
}));
export enum SupplierTaxStatusRecordEnum {
    FIND_RFC = 'find_rfc_supplier_tax_status_record',
    EXPORT_EXCEL_CSV = 'export_list_excel_xlsx_csv_supplier_tax_status_record',
    LOAD = 'load_supplier_tax_status_record',
    UPLOAD = 'upload_supplier_tax_status_record',
    DOWNLOAD = 'download_supplier_tax_status_record',
}

export const SupplierTaxStatusRecord = Object.values(SupplierTaxStatusRecordEnum).map((rule) => ({
    action: rule,
    subject: 'supplier_tax_status_record',
}));

export enum ServiceNowEnum {
    SUBMIT_REQUEST = 'submit_request_service_now',
}

export const ServiceNow = Object.values(ServiceNowEnum).map((rule) => ({
    action: rule,
    subject: 'service_now',
}));

export enum ManualsSettingsEnum {
    CREATE = 'create_manuals',
    EDIT = 'edit_manuals',
    DELETE = 'delete_manuals',
}

export enum ManualsEnum {
    CREATE = 'create_manuals',
    EDIT = 'edit_manuals',
    DELETE = 'delete_manuals',
    SEE_LIST = 'see_list_manuals',
}
export const Manuals = Object.values(ManualsEnum).map((rule) => ({
    action: rule,
    subject: 'manuals',
}));
export const ManualsSettings = Object.values(ManualsSettingsEnum).map((rule) => ({
    action: rule,
    subject: 'manuals',
}));

export enum FiscalRequirementsEnum {
    VISUALIZE = 'visualize_fiscal_requirements',
    EDIT = 'edit_fiscal_requirements',
}

export const FiscalRequirements = Object.values(FiscalRequirementsEnum).map((rule) => ({
    action: rule,
    subject: 'fiscal_requirements',
}));

export enum RecordEnum {
    CONSULT = 'consult_record',
    SEARCH = 'search_record',
    EXPORT_EXCEL = 'export_list_excel_record',
}

export const RecordPermissions = Object.values(RecordEnum).map((rule) => ({
    action: rule,
    subject: 'record',
}));

export enum BannersEnum {
    CREATE = 'create_banners',
    EDIT = 'edit_banners',
    DELETE = 'delete_banners',
    SEE_LIST = 'see_list_banners',
    VISUALIZE = 'visualize_banners',
}

export enum BannersSettingsEnum {
    CREATE = 'create_banners',
    EDIT = 'edit_banners',
    DELETE = 'delete_banners',
}

export const Banners = Object.values(BannersEnum).map((rule) => ({
    action: rule,
    subject: 'banners',
}));

export const BannersSettings = Object.values(BannersSettingsEnum).map((rule) => ({
    action: rule,
    subject: 'banners',
}));

export enum FrequentQuestionsEnum {
    SEE_LIST = 'see_list_frequent_questions',
    CREATE = 'create_frequent_questions',
    EDIT = 'edit_frequent_questions',
    DELETE = 'delete_frequent_questions',
}
export enum FrequentQuestionsSettingsEnum {
    CREATE = 'create_frequent_questions',
    EDIT = 'edit_frequent_questions',
    DELETE = 'delete_frequent_questions',
}

export const FrequentQuestions = Object.values(FrequentQuestionsEnum).map((rule) => ({
    action: rule,
    subject: 'frequent_questions',
}));
export const FrequentSettingsQuestions = Object.values(FrequentQuestionsSettingsEnum).map(
    (rule) => ({
        action: rule,
        subject: 'frequent_questions',
    })
);

export enum URLenum {
    SEE_LIST = 'see_list_url',
    CREATE = 'create_url',
    EDIT = 'edit_url',
    DELETE = 'delete_url',
}
export enum URLSettingsEnum {
    CREATE = 'create_url',
    EDIT = 'edit_url',
    DELETE = 'delete_url',
}

export const URLPermissions = Object.values(URLenum).map((rule) => ({
    action: rule,
    subject: 'url',
}));
export const URLPermissionsSettings = Object.values(URLSettingsEnum).map((rule) => ({
    action: rule,
    subject: 'url',
}));

export enum LegalAgreementsEnum {
    VISUALIZE = 'visualize_legal_agreements',
    CREATE = 'create_legal_agreements',
    EDIT = 'edit_legal_agreements',
    DELETE = 'delete_legal_agreements',
}
export enum LegalAgreementsSettingsEnum {
    CREATE = 'create_legal_agreements',
    EDIT = 'edit_legal_agreements',
    DELETE = 'delete_legal_agreements',
}

export const LegalAgreements = Object.values(LegalAgreementsEnum).map((rule) => ({
    action: rule,
    subject: 'legal_agreements',
}));
export const LegalAgreementsSettings = Object.values(LegalAgreementsSettingsEnum).map((rule) => ({
    action: rule,
    subject: 'legal_agreements',
}));

export enum LogDetailsEnum {
    CONSULT = 'consult_log_details',
    FILTER_DATE = 'filter_date_log_details',
    EXPORT_EXCEL_CSV = 'export_list_excel_xlsx_csv_log_details',
}

export const LogDetails = Object.values(LogDetailsEnum).map((rule) => ({
    action: rule,
    subject: 'log_details',
}));

export enum UsersEnum {
    SEE_LIST = 'see_list_users',
    SEE_LIST_PROVIDER = 'see_list_providers_users',
    ADD_INTERNAL = 'add_internal_user_users',
    ADD_PROVIDER = 'add_provider_user_users',
    BLOCK_UNLOCK = 'block_unlock_user_users',
    BLOCK_UNLOCK_PROVIDER = 'block_unlock_user_provider_users',
    DELETE = 'delete_user_users',
    ASSIGN_ROLE = 'assign_role_users',
}

export const UsersPermissions = Object.values(UsersEnum).map((rule) => ({
    action: rule,
    subject: 'users',
}));

export enum ROLE_PERMISSIONS_ENUM {
    SEE_LIST = 'see_list_role_permissions',
}

export const ROLE_PERMISSIONS = Object.values(ROLE_PERMISSIONS_ENUM).map((rule) => ({
    action: rule,
    subject: 'role_permissions',
}));
export enum ProfilePortalEnum {
    CHANGE_LOGO = 'change_logo_portal_profile',
    CHANGE_THEME = 'change_portal_theme_portal_profile',
    CHANGE_SOURCE = 'change_portal_source_portal_profile',
    RENAME_MODULES = 'rename_modules_portal_profile',
}

export const ProfilePortal = Object.values(ProfilePortalEnum).map((rule) => ({
    action: rule,
    subject: 'portal_profile',
}));

export enum SupplierEnum {
    SEE_LIST = 'see_list_supplier',
    UPLOAD_BILL = 'upload_bill_supplier',
    EXPORT_EXCEL_CSV = 'export_list_excel_xlsx_csv_supplier',
    FILTER = 'filter_supplier_supplier',
}

export const Supplier = Object.values(SupplierEnum).map((rule) => ({
    action: rule,
    subject: 'supplier',
}));
export enum AccountStatusEnum {
    SEE_LIST = 'view_list_account_status',
    DOWNLOAD = 'download_account_status',
    FILTER = 'filter_account_status',
}

export const AccountStatus = Object.values(AccountStatusEnum).map((rule) => ({
    action: rule,
    subject: 'account_status',
}));

export enum SupportStatusEnum {
    SEE_LIST = 'see_list_support',
    CREATE = 'create_support',
    EDIT = 'edit_support',
    FILTER = 'filter_support',
}

export const Support = Object.values(SupportStatusEnum).map((rule) => ({
    action: rule,
    subject: 'support',
}));
export enum PendingBillsEnum {
    SEE_LIST = 'see_list_pending_bills',
    CREATE = 'create_pending_bills',
    EDIT = 'edit_pending_bills',
    FILTER = 'filter_pending_bills',
    SEE_INVOICES_IN_PROCESS = 'see_invoices_in_process_pending_bills',
    SEE_INVOICES_REJECTED = 'see_invoices_rejected_pending_bills',
}

export const PendingBills = Object.values(PendingBillsEnum).map((rule) => ({
    action: rule,
    subject: 'pending_bills',
}));

export enum GuestEnum {
    SEE_LIST = 'view_list_guest',
}
export enum HomeEnum {
    SEE_VIEW = 'view_home',
}

export const HomeStatus = Object.values(HomeEnum).map((rule) => ({
    action: rule,
    subject: 'home',
}));
export enum TermsConditionsEnum {
    SEE_LIST = 'view_list_terms_conditions',
    ACCEPT_REJECT_TERMS_CONDITIONS = 'accept_reject_terms_conditions',
    FILTER = 'filter_terms_conditions',
}

export const TermsConditions = Object.values(TermsConditionsEnum).map((rule) => ({
    action: rule,
    subject: FeatureCodeEnum.TERMS_CONDITIONS,
}));

export type AllPermissions =
    | MerchandiseReceptionEnum
    | ConfirmDeliveryScheduleInvoiceEnum
    | CustomsAgentsEnum
    | CheckStatusOrdersEnum
    | AbiTaxStatusRecordEnum
    | ServiceNowEnum
    | ManualsEnum
    | BannersEnum
    | FiscalRequirementsEnum
    | RecordEnum
    | BannersEnum
    | FrequentQuestionsEnum
    | URLenum
    | LegalAgreementsEnum
    | LogDetailsEnum
    | UsersEnum
    | ROLE_PERMISSIONS_ENUM
    | ProfilePortalEnum
    | SupplierEnum
    | AccountStatusEnum
    | GuestEnum
    | SupportStatusEnum
    | CandidatesEnum
    | HomeEnum
    | PendingBillsEnum
    | TermsConditionsEnum;

export const AllPermissionsSettings = [
    ...URLPermissionsSettings,
    ...LegalAgreementsSettings,
    ...FrequentSettingsQuestions,
    ...ManualsSettings,
    ...BannersSettings,
    ...ProfilePortal,
    ...ROLE_PERMISSIONS,
    ...UsersPermissions,
    ...LogDetails,
    ...PendingBills,
];
