import {AddButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainer,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {TextError, TextSuccess} from '@/components/UI/molecules/textButton/textButtons';
import RoleDeleteModal from '@/components/roles/RoleDeleteModal';
import RoleModal from '@/components/roles/RoleModal';
import RoleStatusModal from '@/components/roles/RoleStatusModal';
import {fetchGetRoleById, fetchGetRoles} from '@/components/roles/redux/actions/RolesActions';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import WarningToast from '@components/auth/componenents/WarningToast';
import {IRole} from '@components/roles/interface';
import {AppDispatch, RootState} from '@config/store';
import {Box, Button, Grid, Menu, MenuItem, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

const RolesTable: React.FC = () => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const roles = useSelector((state: RootState) => state.rolesManager.roles);
    const currentPage = useSelector((state: RootState) => state.rolesManager.page);
    const limit = useSelector((state: RootState) => state.rolesManager.limit);
    const totalPages = useSelector((state: RootState) => state.rolesManager.totalPages);
    const loading = useSelector((state: RootState) => state.rolesManager.loading);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isModalStatusOpen, setModalStatusOpen] = useState<boolean>(false);
    const [isModalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
    const [method, setMethod] = useState('post');
    const [currentRole, setCurrentRole] = useState<IRole | null>(null);
    const [anchorEls, setAnchorEls] = React.useState<{[key: string]: HTMLElement | null}>({});
    const [customRoles, setCustomRoles] = useState<any>([]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: string) => {
        setAnchorEls((prevState) => ({
            ...prevState,
            [index]: event.currentTarget,
        }));
    };

    const handleClose = (index: string) => {
        setAnchorEls((prevState) => ({
            ...prevState,
            [index]: null,
        }));
    };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (currentPage !== value) dispatch(fetchGetRoles(token, value, limit));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetRoles(token, 1, event.target.value));
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchGetRoles(token, currentPage, limit));
        }
    }, [dispatch, token]);

    const openModal = () => {
        setMethod('post');
        setModalOpen(true);
    };

    const getDataUpdated = () => {
        setTimeout(() => {
            dispatch(fetchGetRoles(token, currentPage, limit));
        }, 500);
    };

    const closeModal = () => {
        setModalOpen(false);
        getDataUpdated();
    };

    const closeStatusModal = () => {
        setModalStatusOpen(false);
        getDataUpdated();
    };

    const closeDeleteModal = () => {
        setModalDeleteOpen(false);
        getDataUpdated();
    };

    const addModal = () => {
        openModal();
    };

    const handleEditRole = (item: IRole) => {
        if (token) {
            setMethod('put');
            dispatch(fetchGetRoleById(token, item.id));
            setModalOpen(true);
        }
        handleClose(item.id);
    };

    const handleStatusRole = (item: IRole) => {
        if (token) {
            dispatch(fetchGetRoleById(token, item.id));
            setModalStatusOpen(true);
        }
        handleClose(item.id);
    };

    const handleDeleteRole = (item: IRole) => {
        setCurrentRole(item);
        setModalDeleteOpen(true);
        handleClose(item.id);
    };
    const isRolAdmin = (role: string) => {
        return role.toLowerCase().includes('admin');
    };

    const hidenActionRoles = ['Super Administrador (NO EDITAR)'];

    useEffect(() => {
        if (roles && roles.length > 0) {
            const mappedRoles = roles.map((rol: any) => {
                return {...rol, hiddenAction: hidenActionRoles.includes(rol.name)};
            });
            setCustomRoles(mappedRoles);
        }
    }, [roles]);

    return (
        <Box>
            <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'10px'}
                width={'calc(100vw - 350px)'}
            >
                <Typography
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '16px',
                        fontWeight: 400,
                    }}
                >
                    {t('roles.panels.role.title')}
                </Typography>
                <AddButton onClick={addModal}>
                    {t('roles.buttons.new_role').toUpperCase()}
                </AddButton>
            </Grid>
            <Box>
                <TableContainer width={'calc(100vw - 350px)'}>
                    <TableHeadRow container>
                        <Grid item xs={3} sx={{boxShadow: 0}}>
                            <TableCellHeadInitial>
                                {t('roles.panels.role.table.headers.name')}
                            </TableCellHeadInitial>
                        </Grid>
                        <Grid item xs={4}>
                            <TableCellHead>
                                {t('roles.panels.role.table.headers.qty_assigned_users')}
                            </TableCellHead>
                        </Grid>
                        <Grid item xs={3}>
                            <TableCellHead>
                                {t('roles.panels.role.table.headers.status')}
                            </TableCellHead>
                        </Grid>
                        <Grid item xs={2}>
                            <TableCellHeadFinal>
                                {t('roles.panels.role.table.headers.actions')}
                            </TableCellHeadFinal>
                        </Grid>
                    </TableHeadRow>
                    {loading ? (
                        <>
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                        </>
                    ) : (
                        customRoles &&
                        customRoles.map((item: IRole) => (
                            <Grid container key={item.id}>
                                <Grid item xs={3}>
                                    <TableCell>{item.name}</TableCell>
                                </Grid>

                                <Grid item xs={4}>
                                    <TableCell>{item.quantityUsers}</TableCell>
                                </Grid>
                                <Grid item xs={3}>
                                    <TableCell>
                                        {item.enabled ? (
                                            <TextSuccess sx={{fontSize: '13px'}}>
                                                {t('roles.status.active')}
                                            </TextSuccess>
                                        ) : (
                                            <TextError sx={{fontSize: '13px'}}>
                                                {t('roles.status.inactive')}
                                            </TextError>
                                        )}
                                    </TableCell>
                                </Grid>
                                <Grid item xs={2}>
                                    <TableCell>
                                        {!item.hiddenAction && (
                                            <>
                                                {' '}
                                                <Button
                                                    id={`basic-button-${item.id}`}
                                                    aria-controls={`basic-menu-${item.id}`}
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleClick(event, item.id)}
                                                    color="success"
                                                >
                                                    ...
                                                </Button>
                                                <Menu
                                                    id={`basic-menu-${item.id}`}
                                                    anchorEl={anchorEls[item.id]}
                                                    open={Boolean(anchorEls[item.id])}
                                                    onClose={() => handleClose(item.id)}
                                                    MenuListProps={{
                                                        'aria-labelledby': `basic-button-${item.id}`,
                                                    }}
                                                >
                                                    <MenuItem onClick={() => handleEditRole(item)}>
                                                        {t('roles.buttons.actions.edit')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            if (
                                                                item.quantityUsers > 0 &&
                                                                item.enabled
                                                            ) {
                                                                WarningToast({
                                                                    title: 'roles.panels.role.messages.no_deactivate_role_with_users',
                                                                });
                                                                return;
                                                            }

                                                            handleStatusRole(item);
                                                        }}
                                                        disabled={isRolAdmin(item.name)}
                                                    >
                                                        {item.enabled
                                                            ? t('roles.buttons.actions.deactivate')
                                                            : t('roles.buttons.actions.activate')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            if (item.quantityUsers > 0) {
                                                                WarningToast({
                                                                    title: t(
                                                                        'roles.panels.role.messages.no_delete_role_with_users'
                                                                    ),
                                                                });
                                                                return;
                                                            }
                                                            handleDeleteRole(item);
                                                        }}
                                                        sx={{color: 'red'}}
                                                        disabled={isRolAdmin(item.name)}
                                                    >
                                                        {t('roles.buttons.actions.delete')}
                                                    </MenuItem>
                                                </Menu>{' '}
                                            </>
                                        )}
                                    </TableCell>
                                </Grid>
                            </Grid>
                        ))
                    )}
                </TableContainer>
                <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChange={handleChange}
                    limit={limit}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>
            <RoleModal isModalOpen={isModalOpen} closeModal={closeModal} method={method} />
            <RoleStatusModal isModalOpen={isModalStatusOpen} closeModal={closeStatusModal} />
            <RoleDeleteModal
                isModalOpen={isModalDeleteOpen}
                closeModal={closeDeleteModal}
                role={currentRole}
            />
        </Box>
    );
};

export default RolesTable;
