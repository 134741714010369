import {
    GET_ACCOUNT_STATUS,
    GET_ACCOUNT_STATUS_ERROR,
    GET_ACCOUNT_STATUS_SUCCESS,
    GET_HOME_BANNERS,
    GET_HOME_BANNERS_ERROR,
    GET_HOME_BANNERS_SUCCESS,
    GET_ORDER,
    GET_ORDER_ERROR,
    GET_ORDER_SUCCESS,
    GET_TICKET_ERROR_HOME,
    GET_TICKET_HOME,
    GET_TICKET_SUCCESS_HOME,
} from '@/components/home/redux/types/HomeTypes';

const initialState = {
    orders: [],
    ticketsHome: [],
    accountStatus: [],
    banners: [],
    loading: true,
    loadingPO: true,
    loadingTickets: true,
};

const homeReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER:
            return {
                ...state,
                loadingPO: true,
            };
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                loadingPO: false,
                orders: action.value,
            };
        case GET_ORDER_ERROR:
            return {
                ...state,
                loadingPO: false,
            };
        case GET_TICKET_HOME:
            return {
                ...state,
                loadingTickets: true,
            };
        case GET_TICKET_SUCCESS_HOME:
            return {
                ...state,
                loadingTickets: false,
                ticketsHome: action.value,
            };
        case GET_TICKET_ERROR_HOME:
            return {
                ...state,
                loadingTickets: false,
            };
        case GET_ACCOUNT_STATUS:
            return {
                ...state,
                loading: true,
            };
        case GET_ACCOUNT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                accountStatus: action.value,
            };
        case GET_ACCOUNT_STATUS_ERROR:
            return {
                ...state,
                loading: false,
            };
        case GET_HOME_BANNERS:
            return {
                ...state,
                loading: true,
            };
        case GET_HOME_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                banners: action.value,
            };
        case GET_HOME_BANNERS_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default homeReducers;
