import {ButtonCancel, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {Box, FormControl, InputLabel, MenuItem, Modal, Select, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

interface IRejectProps {
    isModalOpen: boolean;
    closeModal: any;
    confirmModalRejectFn: any;
    rejectedBy: string;
    setRejectedBy: React.Dispatch<React.SetStateAction<string>>;
}

const RejectModal = (props: IRejectProps) => {
    const {t} = useTranslation();
    const {isModalOpen, closeModal, confirmModalRejectFn, rejectedBy, setRejectedBy} = props;
    const theme = useTheme();
    const handleChangeReject = (event: any) => {
        setRejectedBy(event.target.value);
    };

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('partial_conf_po.core.reject')}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <FormControl sx={{width: '400px'}}>
                        <InputLabel id="demo-simple-select-label">
                            {t('partial_conf_po.core.reason_rejection')}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={rejectedBy}
                            label="Motivo de rechazo"
                            onChange={(event) => handleChangeReject(event)}
                        >
                            <MenuItem value={'Wrong price'}>
                                {t('partial_conf_po.core.rejection_list.wrong_price')}
                            </MenuItem>
                            <MenuItem value={'Product not avilable'}>
                                {t('partial_conf_po.core.rejection_list.product_not_available')}
                            </MenuItem>
                            <MenuItem value={'Out of coverage'}>
                                {t('partial_conf_po.core.rejection_list.out_of_coverage')}
                            </MenuItem>
                            <MenuItem value={'Product does not match what was offered'}>
                                {t('partial_conf_po.core.rejection_list.product_offered_not_match')}
                            </MenuItem>
                            <MenuItem value={'Unrecognized product'}>
                                {t('partial_conf_po.core.rejection_list.unrecognized_product')}
                            </MenuItem>
                            <MenuItem value={'Discontinued product'}>
                                {t('partial_conf_po.core.rejection_list.discontinued_product')}
                            </MenuItem>
                            <MenuItem value={'Transportation not available'}>
                                {t('partial_conf_po.core.rejection_list.transpot_not_available')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}>{t('close').toUpperCase()}</ButtonCancel>
                    <SaveButton
                        sx={{padding: '10px'}}
                        disabled={rejectedBy === ''}
                        onClick={confirmModalRejectFn}
                    >
                        {t('confirm').toUpperCase()}
                    </SaveButton>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default RejectModal;
