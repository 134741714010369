import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, Controller, useWatch} from 'react-hook-form';

import {
    Box,
    Typography,
    IconButton,
    useTheme,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    MAX_FILE_SIZE_MB,
    ALLOWED_FILE_TYPES,
} from '@/components/orderSimply/utils/fileUploadConfig';
import {processFileName} from '@/components/orderSimply/utils/fileUploadHelpers';

import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';

import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import {Nav, StyledModal, ButtonCancel, SaveButton, Loader} from '@/components/faqs/StyledFAQ';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@/components/legalAgreements/StyledLegalAgreements';
import {useTranslation} from 'react-i18next';
import {sendFile} from '@/components/orderSimply/redux/actions/LoadComplementActions';
import {ErrorType} from '@/components/orderSimply/utils/format-orders';
import {RootState} from '@/config/store';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DateTime} from 'luxon';

interface Modal2WMPDFProps {
    closeAndReload?: (event?: {preventDefault: () => void}, reason?: string) => void;
    closeModal: (event?: {preventDefault: () => void}, reason?: string) => void;
    lineItemsFilter?: string[];
    isModalOpen: boolean;
    loading?: boolean;
    purchaseOrderId?: string;
    purchaseOrderStatus?: string;
    type?: string;
}

const Modal2WMPDF: React.FC<Modal2WMPDFProps> = ({
    closeAndReload,
    closeModal,
    lineItemsFilter = [],
    isModalOpen,
    loading = false,
    purchaseOrderId = '',
    purchaseOrderStatus = '',
    type = '',
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderssimply = useSelector((state: RootState) => state.orderssimply.orderssimply);
    const update2WM = useSelector((state: RootState) => state.complement.update2WM);
    const errorValidations = useSelector((state: RootState) => state.complement.errorValidations);
    const [value, setValue] = useState<string>('1');
    const [errorFile, setErrorFile] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [ranValidations, setRanValidations] = useState<boolean>(false);
    const [validationOk, setValidationOk] = useState<boolean>(false);
    const [previousErrors, setPreviousErrors] = useState<string[]>([]);
    const token = useSelector((state: any) => state.logins.accessToken);
    const errors = useSelector((state: any) => state.orderssimply.errors);
    const error = useSelector((state: any) => state.orderssimply.error);
    const currentData = useSelector((state: any) => state.orderssimply.orderssimply);
    const [lineItems, setLineItems] = useState<any[]>([]);

    const [linesAmount, setLinesAmount] = useState<{
        [key: string]: {position: string; amount: string};
    }>({});
    const [linesTax, setLinesTax] = useState<{
        [key: string]: {position: string; tax: string};
    }>({});
    const VALIDATION_NO_ERRORS: boolean = error === ErrorType.NONE;
    const VALIDATION_SUCCESS: boolean = ranValidations && VALIDATION_NO_ERRORS;
    const VALIDATION_SUCCESS_WITH_SAP_ERROR: boolean =
        ranValidations &&
        error === ErrorType.RULE &&
        Array.isArray(errors) &&
        errors.some((error: string) => error === 'sap.confirm_error');

    useEffect(() => {
        const previousErrors = currentData
            .filter((lineItem: any) => lineItem.purchase_order_id === purchaseOrderId)
            .filter((lineItem: any) => {
                const element =
                    purchaseOrderStatus === 'DELIVERED'
                        ? lineItem.entry_sheet_number
                        : lineItem.line_item_id;
                return lineItemsFilter.includes(element);
            })
            .map((lineItem: any) => lineItem.errors ?? [])
            .flat();

        if (previousErrors.length > 0) {
            setValue('0');
            setPreviousErrors(previousErrors);
        } else {
            setValue('1');
            setPreviousErrors([]);
        }
    }, [purchaseOrderId, isModalOpen]);

    useEffect(() => {
        if (!loading && Array.isArray(errors) && errors.length > 0) {
            setValidationErrors(errors);
            setPreviousErrors(errors);
            setValue('2');
        } else {
            setValidationErrors([]);
        }
        setValidationOk(VALIDATION_SUCCESS || VALIDATION_SUCCESS_WITH_SAP_ERROR);
    }, [errors, loading, VALIDATION_SUCCESS, VALIDATION_SUCCESS_WITH_SAP_ERROR]);

    useEffect(() => {
        const newFileNames = files.map((file) => {
            if (file.name.length > 22) {
                return processFileName(file.name);
            }
            return file.name;
        });
        setFileNames(newFileNames);
    }, [files]);

    const schemaFormModal = yup.object({
        file: yup.mixed().required(t('modal_load_file.required_file')),
        providerName: yup.string(),
        providerAddress: yup.string(),
        companyName: yup.string(),
        companyAddress: yup.string(),
        purchaseOrder: yup.string(),
        positions: yup.string(),
        invoiceDate: yup.date(),
        invoiceAccount: yup.string(),
        paymentType: yup.string(),
        subtotal: yup.string(),
        iva: yup.string(),
        total: yup.string(),
        donation: yup.string(),
    });

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue: setForValue,
    } = useForm({
        resolver: yupResolver(schemaFormModal),
    });

    const watchForm = watch();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleCloseAndReload = () => {
        resetData();
        if (closeAndReload) {
            closeAndReload();
        }
    };

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
        type: string
    ) => {
        event.stopPropagation();
        const file =
            type === 'drop'
                ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files[0]
                : (event.target as HTMLInputElement).files?.[0];
        if (!file) {
            return;
        }
        if (!ALLOWED_FILE_TYPES[1].includes(file.type)) {
            setErrorFile(t('modal_load_file.invalid_file'));
            return;
        }
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(
                `${t('modal_load_file.file_size_exceeds')} ${MAX_FILE_SIZE_MB} MB. ${t(
                    'modal_load_file.smaller_file'
                )}`
            );
            return;
        }
        setErrorFile('');
        const existingFileIndex = files.findIndex((f) => f.type === file.type);
        let newFiles: File[] = [];
        let newFileNames: string[] = [];

        if (existingFileIndex !== -1) {
            newFiles = [...files];
            newFiles[existingFileIndex] = file;
            newFileNames = fileNames.map((name) => {
                if (name.includes(`.${file.type}`)) {
                    return file.name;
                }
                return name;
            });
        } else {
            newFiles = [...files, file];
            newFileNames = [...fileNames, file.name];
        }
        setForValue('invoiceDate', DateTime.now().toJSDate());
        setFiles(newFiles);
        setFileNames(newFileNames);
    };

    const getPositionArray = () => {
        const resultArray = [];

        for (const key in linesAmount) {
            if (Object.prototype.hasOwnProperty.call(linesAmount, key)) {
                const {position, amount} = linesAmount[key];
                const taxAmount = Number(amount) * (Number(linesTax[key].tax) / 100);
                resultArray.push({
                    position: String(position),
                    amount: Number(amount),
                    percentTax: linesTax[key].tax,
                    taxAmount,
                });
            }
        }

        return resultArray;
    };

    const onSubmit = async (data: any) => {
        const form = new FormData();
        form.append('file', watchForm.file);
        const payload = {
            providerName: watchForm.providerName,
            providerAddress: watchForm.providerAddress,
            companyName: watchForm.companyName,
            companyAddress: watchForm.companyAddress,
            purchaseOrder: watchForm.purchaseOrder,
            positions: getPositionArray(),
            invoiceDate: DateTime.fromJSDate(data.invoiceDate).toFormat('yyyy-MM-dd'),
            invoiceAccount: watchForm.invoiceAccount,
            paymentType: watchForm.paymentType,
            subtotal: Number(watchForm.subtotal),
            iva: Number(watchForm.iva),
            total: Number(watchForm.total),
            donation: watchForm.donation ? Number(watchForm.donation) : 0,
            lineItemsFilter: lineItemsFilter,
        };
        form.append('body', JSON.stringify(payload));

        dispatch(sendFile(token, form, purchaseOrderId));
    };

    const handleCancelButton = () => {
        if (['0', '1'].includes(value)) {
            handleCloseAndReload();
        } else if (value === '2') {
            setValidationErrors([]);
            handleTabChange({} as React.ChangeEvent<Element>, '1');
        }
    };

    const cleanup = () => {
        reset({file: null});
        setValidationErrors([]);
        setValue('1');
        setPreviousErrors([]);
    };

    const resetData = () => {
        cleanup();
        setErrorFile('');
        setFiles([]);
        setFileNames([]);
        setRanValidations(false);
        setValidationOk(false);
    };

    const resetValues = () => {
        setLinesAmount({});
        setLinesTax({});
        setLineItems([]);
    };

    const hasCurrentErrors = ranValidations && validationErrors.length > 1 && ['2'].includes(value);
    const hasPreviousErrors = previousErrors.length > 1 && ['0'].includes(value);
    const hasErrors = hasCurrentErrors || hasPreviousErrors;
    const errorCount =
        validationErrors.length > 0 ? validationErrors.length : previousErrors.length;

    useEffect(() => {
        resetValues();
        const lines = orderssimply.filter((item: any) =>
            lineItemsFilter.includes(
                `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`
            )
        );

        const orders = lines.map((item: any) => item.external_id).join(', ');

        setForValue(`purchaseOrder`, orders, {shouldTouch: true});

        if (lines[0]) {
            setForValue(`providerName`, lines[0].vendor_name, {
                shouldTouch: true,
            });
            setForValue(`companyName`, lines[0].society_name, {
                shouldTouch: true,
            });
        }

        for (const line of lines) {
            const id = `${String(line.line_item_id)}-${String(line.entry_sheet_number)}`;
            setLinesAmount((prev) => ({
                ...prev,
                [id]: {amount: '0', position: line.position},
            }));
            setLinesTax((prev) => ({
                ...prev,
                [id]: {tax: '0', position: line.position},
            }));
        }

        setLineItems(lines);
    }, [lineItemsFilter]);
    useEffect(() => {
        if (update2WM) {
            handleCloseAndReload();
        }
    }, [update2WM]);

    const valorDonation = useWatch({
        control,
        name: 'donation',
        defaultValue: 0,
    });

    const valorIva = useWatch({
        control,
        name: 'iva',
        defaultValue: '',
    });

    useEffect(() => {
        let subpost = 0;

        for (const key in linesAmount) {
            if (Object.prototype.hasOwnProperty.call(linesAmount, key)) {
                subpost += parseFloat(linesAmount[key].amount);
            }
        }
        let taxes = 0;
        for (const key in linesTax) {
            if (Object.prototype.hasOwnProperty.call(linesTax, key)) {
                taxes += Number(linesAmount[key].amount) * (Number(linesTax[key].tax) / 100);
            }
        }
        setForValue('iva', Number(taxes.toFixed(2)));
        const subtotal = watchForm.donation ? Number(watchForm.donation) + subpost : subpost;
        setForValue('subtotal', Number(subtotal.toFixed(2)));

        const total = watchForm.iva ? subtotal + Number(watchForm.iva) : subtotal;

        setForValue('total', Number(total.toFixed(2)));
    }, [linesAmount, valorDonation, valorIva, linesTax]);

    const onChangeLineAmount = (event: any, idx: string) => {
        const currentLine = linesAmount[idx];

        currentLine.amount = event.target.value;

        setLinesAmount((prevSplits) => ({
            ...prevSplits,
            [idx]: currentLine,
        }));
    };
    const onChangeLineTax = (event: any, idx: string) => {
        const tax = Number(event.target.value);

        if (tax !== 0) {
            const notMatch = Object.entries(linesTax)
                .map((item) => Number(item[1].tax))
                .some((itemTax) => itemTax !== 0 && itemTax !== tax);

            if (notMatch) {
                event.preventDefault();
                return;
            }
        }

        const currentLine = linesTax[idx];

        currentLine.tax = event.target.value;

        setLinesTax((prevSplits) => ({
            ...prevSplits,
            [idx]: currentLine,
        }));
    };
    const getHeight = () => {
        if (lineItems.length === 1) return '60px';
        if (lineItems.length === 2) return '110px';
        if (lineItems.length > 2) return '160px';
    };
    return (
        <StyledModal
            open={isModalOpen}
            onClose={() => closeModal}
            disableEscapeKeyDown={true}
            disableEnforceFocus={true}
        >
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    minHeight: '501px',
                    zIndex: '100',
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {t('load')} {type}
                        </Typography>
                    </Nav>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleTabChange}>
                                {previousErrors && previousErrors.length > 0 ? (
                                    <Tab
                                        disabled={ranValidations}
                                        label={t('previous_errors')}
                                        value="0"
                                        sx={{
                                            textTransform: 'none',
                                        }}
                                    />
                                ) : null}
                                <Tab
                                    disabled={validationOk}
                                    label={t('upload_files')}
                                    value="1"
                                    sx={{textTransform: 'none'}}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    maxHeight: '600px',
                                    overflowY: 'auto',
                                }}
                            >
                                <Controller
                                    name="file"
                                    control={control}
                                    defaultValue={null}
                                    render={({field, fieldState}) => (
                                        <FileUploadContainer
                                            sx={{
                                                minHeight: '100px',
                                                margin: fieldState.error ? '0' : '10px 0',
                                                borderColor: fieldState.error ? 'red' : 'none',
                                            }}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                const file = e.dataTransfer.files[0];
                                                if (file) field.onChange(file);
                                                handleFileChange(e, 'drop');
                                            }}
                                            onClick={() =>
                                                document.getElementById('fileInput')?.click()
                                            }
                                        >
                                            <StyledInput
                                                id="fileInput"
                                                type="file"
                                                accept="application/pdf"
                                                multiple={true}
                                                onChange={(e) => {
                                                    const file = e.target.files?.[0];
                                                    if (file) field.onChange(file);
                                                    handleFileChange(e, 'change');
                                                }}
                                            />
                                            {field.value && !errorFile ? (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="subtitle2">
                                                        {t('modal_load_file.last_file')}:
                                                        {field.value.name}
                                                    </Typography>
                                                    {!loading && (
                                                        <IconButton
                                                            disabled={validationOk}
                                                            aria-label="Eliminar"
                                                            onClick={(e) => {
                                                                field.onChange(null);
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <StyledFileLabelButton
                                                        variant="outlined"
                                                        as="span"
                                                    >
                                                        <img src={CloudIcon} />
                                                        <StyledLabelFileUpload>
                                                            {t(
                                                                'confirmposimply.receipts.title_file'
                                                            )}
                                                        </StyledLabelFileUpload>
                                                    </StyledFileLabelButton>
                                                    <Typography variant="caption" color="error">
                                                        {errorFile}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </FileUploadContainer>
                                    )}
                                />
                                <Grid container spacing={0.5}>
                                    <Grid xs={12} item container sx={{marginBottom: '10px'}}>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id={`field-purchase-order`}
                                                    label={`Orden de compra*`}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name={`purchaseOrder`}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id={`providerName`}
                                                    label={`Nombre del proveedor de servicio*`}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name={`providerName`}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id={`outlined-required`}
                                                    label={`Domicilio del proveedor de servicio*`}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name={`providerAddress`}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id={`field-company-name`}
                                                    label={`Nombre de la unidad de negocio*`}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name={`companyName`}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id={`outlined-required-company-address`}
                                                    label={`Domicilio de la unidad de negocio*`}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name={`companyAddress`}
                                            control={control}
                                        />
                                    </Grid>

                                    <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id="outlined-required"
                                                    label={t('confirmposimply.receipts.sheet')}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name="invoiceAccount"
                                            control={control}
                                        />
                                        {errorValidations &&
                                            Object.prototype.hasOwnProperty.call(
                                                errorValidations,
                                                'error'
                                            ) &&
                                            errorValidations['error'] ===
                                                'purchase_order.invoice_number_account_already_exists' && (
                                                <span style={{color: 'red', marginTop: '10px'}}>
                                                    {t(errorValidations['error'])}
                                                </span>
                                            )}
                                    </Grid>
                                    <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Controller
                                                render={({field: {onChange, value}}) => (
                                                    <DatePicker
                                                        label={t(
                                                            'confirmposimply.receipts.receipt_date'
                                                        )}
                                                        value={value}
                                                        onChange={onChange}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                sx={{width: '100%'}}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                name="invoiceDate"
                                                control={control}
                                            />
                                        </LocalizationProvider>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 300,
                                                color: 'red',
                                                marginBottom: '14px',
                                            }}
                                        >
                                            {errors?.invoiceDate?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        item
                                        container
                                        sx={{
                                            background: '#e9e6e6',
                                            padding: '4px 2px 4px 2px',
                                        }}
                                    >
                                        <Grid item xs={3}>
                                            <span style={{fontWeight: 'bold'}}>
                                                {t('confirmposimply.receipts.pos')}
                                            </span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span style={{fontWeight: 'bold'}}>
                                                {t('confirmposimply.receipts.quantity')}
                                            </span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span style={{fontWeight: 'bold'}}>
                                                {' '}
                                                {t('confirmposimply.receipts.import')}
                                            </span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span style={{fontWeight: 'bold'}}>
                                                {' '}
                                                {t('confirmposimply.receipts.tax')}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        item
                                        container
                                        sx={{
                                            marginBottom: '10px',
                                            height: getHeight(),
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {lineItems &&
                                            lineItems.map((item, index) => {
                                                return (
                                                    <Grid
                                                        container
                                                        key={index}
                                                        sx={{marginTop: '10px'}}
                                                    >
                                                        <Grid item xs={3}>
                                                            <span
                                                                style={{
                                                                    padding: '4px 2px 4px 2px',
                                                                }}
                                                            >
                                                                {item.position}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <span
                                                                style={{
                                                                    padding: '4px 2px 4px 2px',
                                                                }}
                                                            >
                                                                {Number(item.entry_received) ||
                                                                    Number(item.quantity) ||
                                                                    0}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label={t('amount')}
                                                                size="small"
                                                                sx={{width: '95%'}}
                                                                variant="outlined"
                                                                onChange={(event) =>
                                                                    onChangeLineAmount(
                                                                        event,
                                                                        `${String(
                                                                            item.line_item_id
                                                                        )}-${String(
                                                                            item.entry_sheet_number
                                                                        )}`
                                                                    )
                                                                }
                                                                value={
                                                                    linesAmount[
                                                                        `${String(
                                                                            item.line_item_id
                                                                        )}-${String(
                                                                            item.entry_sheet_number
                                                                        )}`
                                                                    ]?.amount
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Controller
                                                                name="year"
                                                                control={control}
                                                                defaultValue={0}
                                                                render={({field, fieldState}) => (
                                                                    <FormControl
                                                                        sx={{
                                                                            marginLeft: '10px',
                                                                            height: '40px',
                                                                        }}
                                                                    >
                                                                        <InputLabel>
                                                                            Impuesto
                                                                        </InputLabel>
                                                                        <Select
                                                                            label="Impuesto"
                                                                            {...field}
                                                                            error={
                                                                                !!fieldState.error
                                                                            }
                                                                            sx={{
                                                                                width: '110px',
                                                                                height: '40px',
                                                                            }}
                                                                            onChange={(event) =>
                                                                                onChangeLineTax(
                                                                                    event,
                                                                                    `${String(
                                                                                        item.line_item_id
                                                                                    )}-${String(
                                                                                        item.entry_sheet_number
                                                                                    )}`
                                                                                )
                                                                            }
                                                                            value={
                                                                                linesTax[
                                                                                    `${String(
                                                                                        item.line_item_id
                                                                                    )}-${String(
                                                                                        item.entry_sheet_number
                                                                                    )}`
                                                                                ]?.tax
                                                                            }
                                                                        >
                                                                            <MenuItem value={0}>
                                                                                0 %
                                                                            </MenuItem>
                                                                            <MenuItem value={8}>
                                                                                8 %
                                                                            </MenuItem>
                                                                            <MenuItem value={16}>
                                                                                16 %
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                    <Grid xs={4} item container>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id="outlined-required"
                                                    label={t('confirmposimply.receipts.iva')}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name="iva"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid xs={4} item container sx={{marginBottom: '10px'}}>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id="outlined-required"
                                                    label={t('confirmposimply.receipts.subtotal')}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name="subtotal"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid xs={4} item container>
                                        <Controller
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    id="outlined-required"
                                                    label={t('confirmposimply.receipts.amount')}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            name="total"
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>
                    </TabContext>
                    <Box
                        sx={{
                            paddingX: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                            {hasErrors && (
                                <StatusMessage
                                    message={`${t('modal_load_file.total_errors')}: ${errorCount}`}
                                    color="#f50057"
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '60px',
                                alignItems: 'center',
                                justifySelf: 'flex-end',
                            }}
                        >
                            <ButtonCancel
                                sx={{
                                    color: validationOk ? '#D3D3D3' : null,
                                    border: `1.5px solid ${validationOk ? '#D3D3D3' : null}`,
                                }}
                                disabled={validationOk}
                                onClick={() => handleCancelButton()}
                            >
                                {['0', '1'].includes(value)
                                    ? t('cancel').toUpperCase()
                                    : t('back').toUpperCase()}
                            </ButtonCancel>

                            <SaveButton
                                sx={{
                                    padding: '10px',
                                    display: `${validationOk ? 'none' : 'block'}`,
                                }}
                                disabled={loading}
                                onClick={() => setValue('1')}
                            >
                                {t('confirmposimply.receipts.upload')}
                            </SaveButton>
                        </Box>
                    </Box>
                </form>
                {loading && <Loader size={24} />}
            </Box>
        </StyledModal>
    );
};

export default React.memo(Modal2WMPDF);
