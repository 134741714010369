import React, {useState} from 'react';
import XMLViewer from 'react-xml-viewer';
// Definir una interfaz para las props
interface MediaComponentProps {
    type: 'Imágenes' | 'Video' | 'PDF' | 'XML' | 'CSV' | 'XLSX';
    src: string;
    alt?: string; // Hacer 'alt' opcional
}

const MediaComponent: React.FC<MediaComponentProps> = ({type, src, alt = ''}) => {
    switch (type) {
        case 'Imágenes':
            return <img src={src} style={{maxWidth: '100%', height: 'auto'}} />;
        case 'Video':
            return (
                <video controls style={{maxWidth: '100%', height: 'auto'}}>
                    <source src={src} />
                    Tu navegador no soporta videos.
                </video>
            );
        case 'PDF':
            return (
                <embed src={src} type="application/pdf" style={{width: '100%', height: '500px'}} />
            );

        case 'XML': {
            const [xml, setXml] = useState('');
            const customTheme = {
                attributeKeyColor: '#0074D9',
                attributeValueColor: '#2ECC40',
            };

            fetch(src)
                .then((res) => res.text())
                .then((text) => {
                    setXml(text);
                });

            return (
                <div style={{backgroundColor: '#fff', padding: 16}}>
                    <XMLViewer xml={xml} theme={customTheme} collapsible />
                </div>
            );
        }
        default:
            return (
                <div style={{backgroundColor: '#fff'}}>
                    <p>Tipo de medio no soportado.</p>
                </div>
            );
    }
};

export default MediaComponent;
