import {getLogout} from '@/components/auth/redux/actions/LoginActions';
import {AppDispatch} from '@config/store';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {styled} from '@mui/system';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {setLoadProfileOff} from '@/components/perfil/redux/actions/PerfilActions';

// Styled Components para personalizar el Avatar
const CustomAvatar = styled(Avatar)`
    cursor: pointer;
`;

const AvatarMenu: React.FC = () => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        dispatch(setLoadProfileOff());
        dispatch(getLogout());
    };

    const gotoPerfil = () => {
        handleMenuClose();
        navigate('/configuration/' + '#' + 'account');
    };

    return (
        <>
            <CustomAvatar
                alt="User Avatar"
                src="/path-to-your-avatar-image.jpg" // Ruta a la imagen de tu avatar
                onClick={handleMenuClick}
            />

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>

                <MenuItem onClick={gotoPerfil}>{t('profile')}</MenuItem>
            </Menu>
        </>
    );
};

const AvatarWithMenu: React.FC = () => {
    return (
        <IconButton color="inherit">
            <AvatarMenu />
        </IconButton>
    );
};

export default AvatarWithMenu;
