import React from 'react';
import {Box, Typography} from '@mui/material';
import logoYaydoo from '@login/images/logoYaydoo.svg';

const AuthFooter: React.FC = () => {
    return (
        <Box
            sx={{
                minHeight: '70px',
                background: '#000000',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFFFFF',
            }}
        >
            <Typography
                sx={{
                    fontSize: '14px',
                    fontFamily: 'Helvetica',
                    fontWeight: 700,
                    color: '#FFF',
                    marginRight: '15px',
                }}
            >
                powered by{' '}
            </Typography>{' '}
            <img src={logoYaydoo} />
        </Box>
    );
};

export default AuthFooter;
