export const processFileName = (name: string) => {
    const extension = name.substring(name.lastIndexOf('.'));
    const baseName = name.substring(0, name.lastIndexOf('.'));
    const targetLength = 22 - extension.length;
    const calculateTrimRange = Math.floor((targetLength - 3) / 2);
    const firstPart = baseName.substring(0, calculateTrimRange);
    const lastPart = baseName.substring(baseName.length - calculateTrimRange);

    return `${firstPart}...${lastPart}${extension}`;
};
