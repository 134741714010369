export const GET_CSFSUPPLIER = 'GET_CSFSUPPLIER';
export const GET_CSFSUPPLIER_SUCCESS = 'GET_CSFSUPPLIER_SUCCESS';
export const GET_CSFSUPPLIER_ERROR = 'GET_CSFSUPPLIER_ERROR';
export const POST_CSFSUPPLIER = 'POST_CSFSUPPLIER';
export const POST_CSFSUPPLIER_SUCCESS = 'POST_CSFSUPPLIER_SUCCESS';
export const POST_CSFSUPPLIER_ERROR = 'POST_CSFSUPPLIER_ERROR';
export const CREATE_FILE_CSFSUPPLIER = 'CREATE_FILE_CSFSUPPLIER';
export const CREATE_FILE_CSFSUPPLIER_SUCCESS = 'CREATE_FILE_CSFSUPPLIER_SUCCESS';
export const CREATE_FILE_CSFSUPPLIER_ERROR = 'CREATE_FILE_CSFSUPPLIER_ERROR';
export const GET_CSFSUPPLIER_DOWNLOAD = 'GET_CSFSUPPLIER_DOWNLOAD';
export const GET_CSFSUPPLIER_DOWNLOAD_SUCCESS = 'GET_CSFSUPPLIER_DOWNLOAD_SUCCESS';
export const GET_CSFSUPPLIER_DOWNLOAD_ERROR = 'GET_CSFSUPPLIER_DOWNLOAD_ERROR';
export const GET_DOWNLOAD_LISTCSF = 'GET_DOWNLOAD_LISTCSF';
export const GET_DOWNLOAD_LISTCSF_SUCCESS = 'GET_DOWNLOAD_LISTCSF_SUCCESS';
export const GET_DOWNLOAD_LISTCSF_ERROR = 'GET_DOWNLOAD_LISTCSF_ERROR';
