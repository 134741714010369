import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {IModule, IPermission, IRole} from '@components/roles/interface';
import {
    fetchGeModules,
    fetchPostRoles,
    fetchPutRoles,
} from '@components/roles/redux/actions/RolesActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Checkbox, Modal, TextField, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

const DivDivider = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
`;

const BorderDivider = styled('div')`
    border-bottom: 1px solid #a3b0bb;
    margin: 10px;
    width: 100%;
`;

const ModuleContainer = styled('div')`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 10px;
    height: 50vh;
    overflow-y: auto;
`;

interface IRoleModalProps {
    isModalOpen: boolean;
    closeModal: any;
    method: string;
}

const Divider = (errors: any) => {
    const {t} = useTranslation();
    return (
        <DivDivider>
            <span>{t('roles.modals.create_rol.permissions')}</span>
            <BorderDivider />
        </DivDivider>
    );
};
const RoleModal = (props: IRoleModalProps) => {
    const {t, i18n} = useTranslation();
    const {isModalOpen, closeModal, method} = props;
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.rolesManager.loading);
    // const error = useSelector((state: RootState) => state.rolesManager.error);
    const role = useSelector((state: RootState) => state.rolesManager.role);
    const modules = useSelector((state: RootState) => state.rolesManager.modules);
    const [selected, setSelected] = useState<number[]>([]);
    const [checkedItems, setCheckedItems] = useState<{[key: number]: boolean}>({});
    const [checkedModules, setCheckedModules] = useState<{[key: number]: boolean}>({});
    const [allCheckedItems, setAllCheckedItems] = useState<boolean>(false);

    const schemaFormModal = yup.object({
        name: yup
            .string()
            .required(t('roles.modals.create_role_messages.name_required'))
            .max(90, t('roles.modals.create_role_messages.name_max')),
        description: yup
            .string()
            .required(t('roles.modals.create_role_messages.description_required'))
            .max(256, t('roles.modals.create_role_messages.description_max')),
        permissions: yup
            .array()
            .min(1, t('roles.modals.create_role_messages.permissions'))
            .of(yup.string())
            .required(t('roles.modals.create_role_messages.permissions_required')),
    });

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {errors},
    } = useForm<IRole>({
        resolver: yupResolver(schemaFormModal),
        mode: 'onChange',
    });

    const handleStoreRole = (role: IRole) => {
        if (!token) return;
        if (method === 'post') {
            dispatch(fetchPostRoles(token, role));
        } else {
            dispatch(fetchPutRoles(token, role));
        }
        closeModal();
    };

    const setPermissionCollection = (moduleId: number, id: number, event: any) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [id]: event.target.checked,
        }));
        if (event.target.checked) {
            setSelected([...selected, id]);
            setValue('permissions', [...selected, id]);
            const module = modules.find((module: IModule) => module.id === moduleId);
            const possiblePermissions = module.permissions.map((item: any) => item.id);
            checkedModules[moduleId] = possiblePermissions.every((item: number) =>
                [...selected, id].includes(item)
            );
            setCheckedModules(checkedModules);
        } else {
            const currentSelected = selected.filter((item: number) => item !== id);
            checkedModules[moduleId] = false;
            setCheckedModules(checkedModules);
            setSelected(currentSelected);
            setValue('permissions', currentSelected);
        }
    };

    const selectAllPermission = (event: any) => {
        if (event.target.checked) {
            const allPermissionsIds = modules
                .flatMap((module: IModule) => module.permissions)
                .map((item: any) => item.id);
            setSelected(allPermissionsIds);
            const newCheckedItems: {[key: number]: boolean} = {};
            allPermissionsIds.forEach((item: number) => {
                newCheckedItems[item] = true;
            });
            setCheckedItems(newCheckedItems);
            const newCheckedModules: {[key: number]: boolean} = {};
            modules.forEach((item: IModule) => {
                newCheckedModules[item.id] = true;
            });
            setCheckedModules(newCheckedModules);
            setValue('permissions', allPermissionsIds);
        } else {
            setSelected([]);
            setCheckedItems({});
            setCheckedModules({});
            setValue('permissions', []);
        }
    };

    const selectAllPermissionByModule = (moduleId: number, event: any) => {
        const module = modules.find((module: IModule) => module.id === moduleId);
        const selectedPermissions = module.permissions.map((item: any) => item.id);
        if (event.target.checked) {
            const currentPermissions = [...new Set([...selected, ...selectedPermissions])];
            setSelected(currentPermissions);
            setValue('permissions', currentPermissions);
            const newCheckedItems: {[key: number]: boolean} = {};
            selectedPermissions.forEach((item: any) => {
                newCheckedItems[item] = true;
            });
            setCheckedItems({...checkedItems, ...newCheckedItems});
            if (module.id in checkedModules) {
                checkedModules[module.id] = true;
                setCheckedModules(checkedModules);
            } else {
                const newCheckedModules: {[key: number]: boolean} = {};
                newCheckedModules[module.id] = true;
                setCheckedModules({...checkedModules, ...newCheckedModules});
            }
        } else {
            const currentPermissions = selected.filter(
                (item: number) => !selectedPermissions.includes(item)
            );
            setSelected(currentPermissions);
            setValue('permissions', currentPermissions);
            selectedPermissions.forEach((item: any) => {
                checkedItems[item] = false;
            });
            setCheckedItems(checkedItems);
            const newCheckedModules: {[key: number]: boolean} = {};
            newCheckedModules[module.id] = false;
            setCheckedModules({...checkedModules, ...newCheckedModules});
        }
    };

    useEffect(() => {
        if (modules && modules.length > 0) {
            const allPermissions = modules.flatMap((module: IModule) => module.permissions);
            setAllCheckedItems(allPermissions.length === selected.length);
        }
    }, [selected, modules]);

    useEffect(() => {
        if (method === 'post') {
            setCheckedItems({});
            setSelected([]);
            reset({
                name: '',
                description: '',
                permissions: [],
            });
            setSelected([]);
            setCheckedModules({});
        }
        if (method === 'put' && !loading) {
            setValue('id', role.id);
            setValue('name', role.name);
            setValue('description', role.description);
            setValue('permissions', role.permissions);
            setSelected(role.permissions);
            const newCheckedItems: {[key: number]: boolean} = {};
            role.permissions.forEach((item: number) => {
                newCheckedItems[item] = true;
            });
            setCheckedItems(newCheckedItems);
            const newCheckedModules: {[key: number]: boolean} = {};
            modules.forEach((module: IModule) => {
                const possiblePermissions = module.permissions.map((item: any) => item.id);
                newCheckedModules[module.id] = possiblePermissions.every((item: number) =>
                    role.permissions.includes(item)
                );
            });
            setCheckedModules(newCheckedModules);
        }
    }, [isModalOpen, loading]);
    useEffect(() => {
        if (token) {
            dispatch(fetchGeModules(token));
        }
    }, [dispatch, token]);

    const getLanguageDescription = (des: string) => {
        if (des.includes('-EN-')) {
            const name = des.split('-EN-')[0];
            const en = des.split('-EN-')[1];
            return i18n.language === 'en' ? en : name;
        }
        return des;
    };
    const permissionTranslations: {[key: string]: string} = {
        'Levanta una solicitud': t('roles.modals.create_role_permission.submit_request'),
        'Editar una solicitud': t('roles.modals.create_role_permission.edit_request'),
        'Ver solicitudes': t('roles.modals.create_role_permission.see_request'),
        'Visualizar Home': t('roles.modals.create_role_permission.see_home'),
        'Cargar Recibo': t('roles.modals.create_role_permission.upload_file'),
        'Cargar Nota de credito': t('roles.modals.create_role_permission.upload_credit_note'),
        'Cargas Masivas': t('roles.modals.create_role_permission.bulk_uploads'),
        'Confirmar / Rechazar entrega': t(
            'roles.modals.create_role_permission.confirm_rejected_delivery'
        ),
        'Descargar PO': t('roles.modals.create_role_permission.download_po'),
        'Validación de entradas': t('roles.modals.create_role_permission.see_detail_paper'),
        'Visualizar detalle de Hoja de Entrada': t(
            'roles.modals.create_role_permission.see_detail_paper'
        ),
        'Visualizar Candidatos': t('roles.modals.create_role_permission.see_candidates'),
        'Ver listado Pendientes': t('roles.modals.create_role_permission.see_list_pendings'),
        'Ver listado Rechazados': t('roles.modals.create_role_permission.see_list_rejected'),
        'Ver listado Aprobados': t('roles.modals.create_role_permission.see_list_approved'),
        Aprobar: t('roles.modals.create_role_permission.approved'),
        Rechazar: t('roles.modals.create_role_permission.rejected'),
        Exportar: t('roles.modals.create_role_permission.export'),
        'Ver facturas en proceso': t('roles.modals.create_role_permission.see_invoice_process'),
        'Ver facturas rechazadas': t('roles.modals.create_role_permission.see_invoice_rejected'),
        'Buscar por PO': t('roles.modals.create_role_permission.search_po'),
        'Cargar facturas': t('roles.modals.create_role_permission.upload_invoices'),
        'Cargar Complemento de pago': t(
            'roles.modals.create_role_permission.upload_complement_payment'
        ),
        'Buscar por Proveedor': t('roles.modals.create_role_permission.search_provider'),
        'Filtrar (estado)': t('roles.modals.create_role_permission.filter_status'),
        'Descargar complemento de pago': t(
            'roles.modals.create_role_permission.download_complement_payment'
        ),
        'Exportar listado a Excel (.csv y .xlsx)': t(
            'roles.modals.create_role_permission.export_list_excel_xls'
        ),
        'Valiación de entradas': t('roles.modals.create_role_permission.entry_validation'),
        'Listado de Recepciones de mercancía': t(
            'roles.modals.create_role_permission.list_receipts'
        ),
        'Agregar Usuarios Internos': t('roles.modals.create_role_permission.add_internal_users'),
        'Agregar Usuarios Proveedor': t('roles.modals.create_role_permission.add_users_provider'),
        'Bloquear y desbloquear Usuario': t(
            'roles.modals.create_role_permission.block_unblock_user'
        ),
        'Eliminar Usuario': t('roles.modals.create_role_permission.delete_user'),
        'Asignar rol': t('roles.modals.create_role_permission.assign_role'),
        'Cambiar logo': t('roles.modals.create_role_permission.change_logo'),
        'Cambiar Tema de Portal': t('roles.modals.create_role_permission.change_theme_portal'),
        'Cambiar Fuente de Portal': t('roles.modals.create_role_permission.change_font_portal'),
        'Cambiar nombre módulos': t('roles.modals.create_role_permission.change_name_modules'),
        'Filtrar por Proveedor': t('roles.modals.create_role_permission.filter_provider'),
        'Cargar factura': t('roles.modals.create_role_permission.upload_invoice'),
        Filtrar: t('roles.modals.create_role_permission.filter'),
        Cargar: t('roles.modals.create_role_permission.upload'),
        'Buscar por RFC': t('roles.modals.create_role_permission.search_rfc'),
        Actualizar: t('roles.modals.create_role_permission.update'),
        Descargar: t('roles.modals.create_role_permission.download'),
        'Exportar listado en Excel xlsx y csv': t(
            'roles.modals.create_role_permission.export_list_excel_xlsx'
        ),
        'Filtrar por fecha': t('roles.modals.create_role_permission.filter_for_date'),
        Eliminar: t('roles.modals.create_role_permission.delete'),
        Crear: t('roles.modals.create_role_permission.create'),
        Visualizar: t('roles.modals.create_role_permission.see'),
        'Ver listado': t('roles.modals.create_role_permission.see_list'),
        'Exportar listado excel': t('roles.modals.create_role_permission.export_list'),
        Busqueda: t('roles.modals.create_role_permission.search'),
        Consultar: t('roles.modals.create_role_permission.consult'),
        Editar: t('roles.modals.create_role_permission.edit'),
        consultar: t('roles.modals.create_role_permission.consults'),
    };
    const getTranslatedPermissionName = (permissionName: string): string => {
        return permissionTranslations[permissionName] || permissionName;
    };
    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {method === 'post'
                            ? t('roles.modals.create_rol.new')
                            : t('roles.modals.create_rol.edit')}
                    </Typography>
                </Nav>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '15px 10px',
                    }}
                    onSubmit={handleSubmit(handleStoreRole)}
                >
                    <Box display="flex" justifyContent="start" padding="10px">
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.create_rol.role_name')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        marginRight: '10px',
                                    }}
                                />
                            )}
                            name="name"
                            control={control}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={t('roles.modals.create_rol.description')}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        width: '60%',
                                    }}
                                />
                            )}
                            name="description"
                            control={control}
                        />
                    </Box>
                    <Divider />
                    {errors && errors.permissions && (
                        <span
                            style={{
                                margin: '0 0 4px 10px',
                                color: '#d32f2f',
                                fontSize: '0.75rem',
                            }}
                        >
                            {errors.permissions.message}
                        </span>
                    )}
                    <Box>
                        <Checkbox
                            onChange={(event) => {
                                selectAllPermission(event);
                            }}
                            checked={allCheckedItems}
                        />
                        <span
                            style={{
                                color: 'black',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                        >
                            {t('roles.modals.create_rol.select_all')}
                        </span>
                    </Box>
                    <ModuleContainer>
                        {modules &&
                            modules.length > 0 &&
                            modules.map((module: IModule) => {
                                return (
                                    <div
                                        key={module.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderBottom: '1px solid',

                                                borderColor: '#C5D1D8',
                                            }}
                                        >
                                            <div>
                                                <Checkbox
                                                    onChange={(event) => {
                                                        selectAllPermissionByModule(
                                                            module.id,
                                                            event
                                                        );
                                                    }}
                                                    checked={checkedModules[module.id] || false}
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    color: 'black',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {getLanguageDescription(module.description)}
                                            </span>
                                        </Box>
                                        {module.permissions &&
                                            module.permissions.map((permission: IPermission) => {
                                                return (
                                                    <Box key={permission.id}>
                                                        <Checkbox
                                                            onChange={(event) =>
                                                                setPermissionCollection(
                                                                    module.id,
                                                                    permission.id,
                                                                    event
                                                                )
                                                            }
                                                            checked={
                                                                checkedItems[permission.id] || false
                                                            }
                                                        />
                                                        <span
                                                            style={{
                                                                color: 'black',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            {getTranslatedPermissionName(
                                                                permission.name
                                                            )}
                                                        </span>
                                                    </Box>
                                                );
                                            })}
                                    </div>
                                );
                            })}
                    </ModuleContainer>
                    <Box
                        sx={{
                            display: 'flex',
                            height: '56px',
                            alignItems: 'center',
                            margin: '20px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonCancel onClick={closeModal}>
                            {t('cancel').toUpperCase()}
                        </ButtonCancel>
                        <SaveButton disabled={loading}>
                            {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                        </SaveButton>
                    </Box>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default RoleModal;
