import PurchaseOrder from '@/components/home/components/PurchaseOrder/PurchaseOrder';
import StatusAccounts from '@/components/home/components/StatusAccounts/StatusAccounts';
import Tickets from '@/components/home/components/Tickets/Tickets';
import {FeatureCodeEnum, HomeEnum} from '@/enums/permissions.enum';
import {ACLObj} from '@components/auth/guard/AclGuard';
import {RootState} from '@config/store';
import {Box, Card, CardContent, Grid, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Banners from '@components/home/components/Banners/Banners';
const Home: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();

    const theme = useTheme();
    const user = useSelector((state: RootState) => state.logins.user);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                //height: '150%',
                padding: '15px 23px',
            }}
        >
            {user.enterprise.status === 'ACTIVE' && (
                <Card>
                    <Banners />
                    <CardContent sx={{overflowY: 'auto'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 400,
                                    }}
                                >
                                    {t('home.wellcome')}
                                </Typography>
                            </Grid>
                            <Grid item display={'flex'} alignItems={'flex-start'} xs={6}>
                                <Grid container>
                                    <PurchaseOrder />
                                </Grid>
                            </Grid>
                            <Grid item display={'flex'} alignItems={'flex-start'} xs={6}>
                                <Grid container>
                                    <Tickets />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} item xs={12}>
                                <StatusAccounts />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
            {user.enterprise.status === 'PENDING' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90vh',
                        padding: '20px 23px',
                    }}
                >
                    <iframe src="https://www.orimi.com/pdf-test.pdf" height="100%" />
                </Box>
            )}
        </Box>
    );
};

Home.acl = {
    subject: FeatureCodeEnum.HOME,
    action: HomeEnum.SEE_VIEW,
};
export default Home;
