import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {styled, useTheme} from '@mui/material/styles';
import {
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {fetchGetPurchaseOrder} from '@/components/purchase-order/redux/actions/PurchaceOrderActions';
import {AppDispatch, RootState} from '@config/store';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    IItemSplit,
    ILineDetail,
    IErrorSplit,
    IItemSplitMarket,
} from '@/components/purchase-order/confirmPartial/interface';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers';
import BtnReject from '@/components/purchase-order/confirmPartial/BtnReject';
import {useTranslation} from 'react-i18next';
import BtnSplit from '@/components/purchase-order/confirmPartial/BtnSplit';

interface IConfirmPurchaseOrderPartialTableProps {
    internalId?: string;
    rejects: {[key: string]: string};
    setRejects: React.Dispatch<
        React.SetStateAction<{
            [key: string]: string;
        }>
    >;
    splits: {[key: string]: IItemSplitMarket[]};
    setSplit: React.Dispatch<
        React.SetStateAction<{
            [key: string]: IItemSplitMarket[];
        }>
    >;
    lines: {[key: string]: IItemSplit};
    setLines: React.Dispatch<
        React.SetStateAction<{
            [key: string]: IItemSplit;
        }>
    >;
    errorsLines: IErrorSplit[];
    setErrorsLines: React.Dispatch<React.SetStateAction<IErrorSplit[]>>;
    errorsSplits: IErrorSplit[];
    setErrorsSplit: React.Dispatch<React.SetStateAction<IErrorSplit[]>>;
}

const ConfirmPurchaseOrderPartialTableMarket: React.FC<IConfirmPurchaseOrderPartialTableProps> = ({
    ...props
}) => {
    const {
        internalId,
        rejects,
        setRejects,
        lines,
        setLines,
        errorsLines,
        setErrorsLines,
        splits,
        setSplit,
        setErrorsSplit,
        errorsSplits,
    } = props;

    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const lineItems = useSelector((state: RootState) => state.purchaceOrderReducers.lineItems);
    const loading = useSelector((state: RootState) => state.purchaceOrderReducers.loading);
    const inputLinesRefs = useRef<(HTMLInputElement | null)[][]>([]);
    const [populate, setPopulate] = useState(false);
    const [showIsReject, setShowIsReject] = useState<string[]>([]);
    const [showIsSplit, setShowIsSplit] = useState<string[]>([]);
    const inputRefs = useRef<(HTMLInputElement | null)[][]>([]);

    useEffect(() => {
        if (token && internalId) {
            setPopulate(false);
            dispatch(fetchGetPurchaseOrder(token, internalId));
        }
    }, [dispatch, token, internalId]);

    const populateInputRefs = () => {
        lineItems.forEach(() => {
            const group: (HTMLInputElement | null)[] = [null, null, null];
            inputLinesRefs.current.push(group);
        });
        setPopulate(true);
    };

    const populateRejects = () => {
        for (const reject in rejects) {
            setShowIsReject((prevRejects) => [...prevRejects, reject]);
        }
    };

    useEffect(() => {
        if (lineItems && lineItems.length > 0) populateInputRefs();
    }, [lineItems]);

    useEffect(() => {
        if (rejects && Object.entries(rejects).length > 0) populateRejects();
    }, [rejects]);

    useEffect(() => {
        Object.keys(splits).forEach((id) => {
            checkSplitHasBatch(splits[id], id);
        });
    }, [i18n.language]);

    const TableContainer = styled(Grid)(({width = 'calc(100vw - 80px)'}: {width?: string}) => ({
        width: width,
    }));

    const handleChangeReject = (event: SelectChangeEvent<string>, id: string) => {
        setRejects((prevRejects) => ({
            ...prevRejects,
            [id]: event.target.value,
        }));
    };

    const getIsReject = (id: string) => {
        return showIsReject.find((item: string) => item === id);
    };

    const addIsRejects = (id: string) => {
        setShowIsReject([...showIsReject, id]);
    };

    const deleteIsRejects = (id: string) => {
        const currentRejects = showIsReject.filter((item: string) => item !== id);
        setShowIsReject(currentRejects);
        setRejects((prevRejects) => {
            const updatedRejects = {...prevRejects};
            delete updatedRejects[id];
            return updatedRejects;
        });
    };

    const onChangeInputValue = (
        event: any,
        item: ILineDetail,
        idx: number,
        field: string,
        position: number
    ) => {
        setErrorsLines([]);
        if (event === null) return;

        const currentLine = lines[item.id];
        currentLine[field] = event.target.value;

        setLines((prevSplits) => ({
            ...prevSplits,
            [item.id]: currentLine,
        }));

        if (field === 'quantitySupplied') {
            cuantityErrorController(Number(event.target.value), item);
            cuantityLowController(Number(event.target.value), item);
        }

        setTimeout(() => focusInputLine(idx, position), 100);
    };

    const cuantityLowController = (quantityDelivery: number, item: ILineDetail) => {
        if (quantityDelivery < item.quantity) {
            setShowIsReject([...showIsReject, item.id]);
        } else {
            deleteIsRejects(item.id);
        }
    };

    const cuantityErrorController = (quantityDelivery: number, item: ILineDetail) => {
        if (quantityDelivery > item.quantity) {
            const found = errorsLines.find((error) => error.id === item.id);
            if (!found) {
                setErrorsLines([
                    ...errorsLines,
                    {
                        id: item.id,
                        message: t('confirmposimply.quantity_equal_less_requested'),
                    },
                ]);
            }
        } else {
            const errors = errorsLines.filter((error) => error.id !== item.id);
            setErrorsLines(errors);
        }
    };

    const onChangeLineDate = (event: Date | null, itemId: string) => {
        if (event === null) return;

        const currentLine = lines[itemId];
        currentLine.deliveryDate = new Date(event);

        if (currentLine.expirationDate < currentLine.deliveryDate) {
            currentLine.expirationDate = currentLine.deliveryDate;
        }

        setLines((prevSplits) => ({
            ...prevSplits,
            [itemId]: currentLine,
        }));
    };

    const onChangeLineDateHead = (event: Date | null, itemId: string, field: string) => {
        if (event === null) return;

        const currentLine = lines[itemId];
        currentLine['field'] = new Date(event);

        setLines((prevSplits) => ({
            ...prevSplits,
            [itemId]: currentLine,
        }));
    };

    const itemHasLinesError = (itemId: string) => {
        const errorItem = errorsLines.find((item) => item.id === itemId);
        if (errorItem) {
            return {
                ...errorItem,
                message: t(errorItem.message),
            };
        }
        return null;
    };

    const focusInputLine = (index: number, position: number) => {
        if (inputLinesRefs.current[index][position]) {
            inputLinesRefs.current[index][position]?.focus();
        }
    };

    const styleCellData = {padding: '25px 0 25px 15px'};

    const getIsSplit = (id: string) => {
        return showIsSplit.find((item: string) => item === id);
    };

    const addIsSplits = (id: string) => {
        setShowIsSplit([...showIsSplit, id]);
        if (!splits[id] || splits[id].length === 0) {
            setSplit((prevSplits) => ({
                ...prevSplits,
                [id]: [
                    {
                        batch: '',
                        quantitySupplied: 0,
                        expirationDate: new Date(),
                        buildDate: new Date(),
                    },
                ],
            }));
            inputRefs.current.push([null, null]);
        }
    };

    const addSplits = (itemId: string) => {
        const currentSplits = [
            ...splits[itemId],
            {
                batch: '',
                quantitySupplied: 0,
                expirationDate: new Date(),
                buildDate: new Date(),
            },
        ];
        setSplit((prevSplits) => ({
            ...prevSplits,
            [itemId]: currentSplits,
        }));
        inputRefs.current.push([null, null]);
    };

    const focusInput = (index: number, position: number) => {
        if (inputRefs.current[index][position]) {
            inputRefs.current[index][position]?.focus();
        }
    };

    const onChangeAmount = (
        event: any,
        item: ILineDetail,
        field: string,
        currenIndex: number,
        position: number
    ) => {
        if (event === null) return;

        const currentSplit = splits[item.id][currenIndex];

        currentSplit[field] =
            field === 'quantitySupplied' ? Number(event.target.value) : event.target.value;

        const others = splits[item.id].map((item, index) => {
            if (index === currenIndex) {
                return currentSplit;
            }
            return item;
        });

        setSplit((prevSplits) => ({
            ...prevSplits,
            [item.id]: others,
        }));
        cuantityErrorSplitController(others, item);
        checkSplitHasBatch(others, item.id);
        setTimeout(() => focusInput(currenIndex, position), 100);
    };

    const checkSplitHasBatch = (split: IItemSplitMarket[], id: string) => {
        const isEmpty = split.some((item: IItemSplitMarket) => item.batch === '');
        if (isEmpty) {
            setErrorsLines((prevErrors) => [
                ...prevErrors.filter((error) => error.id !== id),
                {
                    id: id,
                    message: t('partial_conf_po.core.required_number_lote'),
                },
            ]);
        } else {
            setErrorsLines((prevErrors) => prevErrors.filter((error) => error.id !== id));
        }
    };

    const cuantityErrorSplitController = (split: IItemSplitMarket[], item: ILineDetail) => {
        const currentQuantity = split.reduce((carry, quan) => {
            return carry + quan.quantitySupplied;
        }, 0);
        if (currentQuantity > item.quantity) {
            const found = errorsSplits.find((error) => error.id === item.id);
            if (!found) {
                setErrorsSplit([
                    ...errorsSplits,
                    {
                        id: item.id,
                        message: t('confirmposimply.amount_equal_requested'),
                    },
                ]);
            }
        } else {
            const errors = errorsSplits.filter((error) => error.id !== item.id);
            setErrorsSplit(errors);
        }
    };

    const onChangeDate = (
        event: Date | null,
        itemId: string,
        currenIndex: number,
        field: string
    ) => {
        if (event === null) return;

        const currentSplit = splits[itemId][currenIndex];

        currentSplit[field] = new Date(event);

        const currentSplits = splits[itemId].filter((_, index) => index !== currenIndex);

        const newsSplits = [...currentSplits, currentSplit];

        setSplit((prevSplits) => ({
            ...prevSplits,
            [itemId]: prevSplits[itemId]
                ? newsSplits
                : [
                      {
                          batch: '',
                          quantitySupplied: 0,
                          expirationDate: new Date(),
                          buildDate: new Date(),
                      },
                  ],
        }));
    };

    const deleteSplit = (item: ILineDetail, currenIndex: number) => {
        const currentSplits = splits[item.id].filter((_, index) => index !== currenIndex);
        setSplit((prevSplits) => ({
            ...prevSplits,
            [item.id]: currentSplits,
        }));
        if (currentSplits.length === 0) {
            setShowIsSplit(showIsSplit.filter((itemShow) => itemShow !== item.id));
        }
        deleteIsRejects(item.id);
    };

    const getIsSplitReject = (item: ILineDetail) => {
        const currentSupplied = splits[item.id].reduce((carry, quan) => {
            return carry + quan.quantitySupplied;
        }, 0);
        return currentSupplied < item.quantity;
    };

    const itemHasSplitError = (itemId: string) => {
        return errorsSplits.find((item) => item.id === itemId);
    };

    return (
        <TableContainer>
            <TableHeadRow container>
                <Grid item xs={0.3}>
                    <TableCellHeadInitial>Pos</TableCellHeadInitial>
                </Grid>
                <Grid item xs={1}>
                    <TableCellHead>SKU - {t('description')}</TableCellHead>
                </Grid>
                <Grid item xs={0.5}>
                    <TableCellHead>{t('requested_amount')}</TableCellHead>
                </Grid>
                <Grid item xs={0.5}>
                    <TableCellHead> {t('unit')}</TableCellHead>
                </Grid>
                <Grid item xs={1}>
                    <TableCellHead>{t('quantity_supplied')}</TableCellHead>
                </Grid>
                <Grid item xs={1.7}>
                    <TableCellHead>{t('receptpo.table.headers.delivery_date')}</TableCellHead>
                </Grid>
                <Grid item xs={1}>
                    <TableCellHead>Lote</TableCellHead>
                </Grid>
                <Grid item xs={1.5}>
                    <TableCellHead>{t('partial_conf_po.core.fab_date')}</TableCellHead>
                </Grid>
                <Grid item xs={1.5}>
                    <TableCellHead>{t('date_expiry')}</TableCellHead>
                </Grid>
                <Grid item xs={1}>
                    <TableCellHead>{t('unit_price')}</TableCellHead>
                </Grid>
                <Grid item xs={1}>
                    <TableCellHead>{t('amount')}</TableCellHead>
                </Grid>
                <Grid item xs={1}>
                    <TableCellHeadFinal>{t('actions')}</TableCellHeadFinal>
                </Grid>
            </TableHeadRow>
            {loading ? (
                <>
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                </>
            ) : (
                <Box sx={{height: '65vh', overflowY: 'auto'}}>
                    {populate &&
                        lineItems &&
                        lineItems.map((item: ILineDetail, idx: number) => (
                            <Box
                                key={item.id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '0',
                                    borderBottom: '1px solid #C2D1D9',
                                    boxShadow: 'none',
                                    minHeight: '50px',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    color: '#515151',
                                    background: 'white',
                                    overflowWrap: 'anywhere',
                                }}
                            >
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '0',
                                        boxShadow: 'none',
                                        minHeight: '50px',
                                    }}
                                >
                                    <Grid item xs={0.3} sx={styleCellData}>
                                        {item.position}
                                    </Grid>
                                    <Grid item xs={1} sx={styleCellData}>
                                        {item.productDetails[0].productName}
                                    </Grid>
                                    <Grid item xs={0.5} sx={styleCellData}>
                                        {item.quantity}
                                    </Grid>
                                    <Grid item xs={0.5} sx={styleCellData}>
                                        {item.productDetails[0].unit}
                                    </Grid>

                                    <Grid item xs={1} sx={styleCellData}>
                                        <TextField
                                            id="outlined-basic"
                                            label={t('quantity_supplied')}
                                            size="small"
                                            variant="outlined"
                                            disabled={!!getIsSplit(item.id)}
                                            value={lines[item.id]?.quantitySupplied}
                                            onChange={(event) =>
                                                onChangeInputValue(
                                                    event,
                                                    item,
                                                    idx,
                                                    'quantitySupplied',
                                                    0
                                                )
                                            }
                                            inputRef={(input) =>
                                                (inputLinesRefs.current[idx][0] = input)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1.7} sx={styleCellData}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label={t('receptpo.table.headers.delivery_date')}
                                                value={lines[item.id]?.deliveryDate}
                                                onChange={(event) =>
                                                    onChangeLineDate(event, item.id)
                                                }
                                                disabled={item.quantityDelivery >= 2}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '40px',
                                                                marginRight: '10px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1} sx={styleCellData}>
                                        <TextField
                                            id="outlined-basic"
                                            label=""
                                            size="small"
                                            variant="outlined"
                                            value={lines[item.id]?.batch}
                                            onChange={(event) =>
                                                onChangeInputValue(event, item, idx, 'batch', 1)
                                            }
                                            inputRef={(input) =>
                                                (inputLinesRefs.current[idx][1] = input)
                                            }
                                            disabled={!!getIsSplit(item.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5} sx={styleCellData}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label={t('partial_conf_po.core.fab_date')}
                                                value={lines[item.id]?.buildDate}
                                                onChange={(event) =>
                                                    onChangeLineDateHead(
                                                        event,
                                                        item.id,
                                                        'buildDate'
                                                    )
                                                }
                                                disabled={
                                                    item.quantityDelivery >= 2 ||
                                                    !!getIsSplit(item.id)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '40px',
                                                                marginRight: '10px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1.5} sx={styleCellData}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label={t('date_expiry')}
                                                value={lines[item.id]?.expirationDate}
                                                onChange={(event) =>
                                                    onChangeLineDateHead(
                                                        event,
                                                        item.id,
                                                        'expirationDate'
                                                    )
                                                }
                                                disabled={
                                                    item.quantityDelivery >= 2 ||
                                                    !!getIsSplit(item.id)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '40px',
                                                                marginRight: '10px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                                minDate={lines[item.id]?.deliveryDate}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1} sx={styleCellData}>
                                        <span style={{marginLeft: '10px'}}>
                                            {lines[item.id]?.unitPrice}
                                        </span>
                                    </Grid>
                                    <Grid item xs={1} sx={styleCellData}>
                                        {(
                                            lines[item.id]?.unitPrice *
                                            lines[item.id]?.quantitySupplied
                                        ).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 2,
                                        })}
                                    </Grid>
                                    <Grid item xs={1} sx={{display: 'flex'}}>
                                        {!getIsSplit(item.id) && !getIsReject(item.id) && (
                                            <BtnSplit actionFn={() => addIsSplits(item.id)} />
                                        )}
                                        {!getIsSplit(item.id) && !getIsReject(item.id) && (
                                            <BtnReject actionFn={() => addIsRejects(item.id)} />
                                        )}
                                    </Grid>
                                </Grid>
                                {itemHasLinesError(item.id) && (
                                    <Typography
                                        sx={{
                                            color: '#921A28',
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 400,
                                            margin: '0 10px 10px 10px',
                                        }}
                                    >
                                        {itemHasLinesError(item.id)?.message}
                                    </Typography>
                                )}
                                {item.quantityDelivery >= 2 && (
                                    <Typography
                                        sx={{
                                            color: '#921A28',
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 400,
                                            margin: '0 10px 10px 10px',
                                        }}
                                    >
                                        {t('partial_conf_po.core.only_date_twice')}
                                    </Typography>
                                )}
                                {getIsReject(item.id) && !getIsSplit(item.id) && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: '10px 0 20px 0',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#921A28',
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 400,
                                                margin: '0 10px 10px 10px',
                                            }}
                                        >
                                            {t('reject_product')}
                                        </Typography>
                                        <FormControl sx={{width: '400px'}}>
                                            <InputLabel id="demo-simple-select-label">
                                                {t('partial_conf_po.core.reason_rejection')}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={rejects[item.id]}
                                                label={t('partial_conf_po.core.reason_rejection')}
                                                onChange={(event) =>
                                                    handleChangeReject(event, item.id)
                                                }
                                            >
                                                <MenuItem value={'Wrong price'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.wrong_price'
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={'Product not avilable'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.product_not_available'
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={'Out of coverage'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.out_of_coverage'
                                                    )}
                                                </MenuItem>
                                                <MenuItem
                                                    value={
                                                        'Product does not match what was offered'
                                                    }
                                                >
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.product_offered_not_match'
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={'Unrecognized product'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.unrecognized_product'
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={'Discontinued product'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.discontinued_product'
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={'Transportation not available'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.transpot_not_available'
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={'Incomplete inventory'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.incomplete_inventory'
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={'Delivery date change'}>
                                                    {t(
                                                        'partial_conf_po.core.rejection_list.delivery_date_change'
                                                    )}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <BtnReject actionFn={() => deleteIsRejects(item.id)} />
                                    </Box>
                                )}
                                {getIsSplit(item.id) && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '10px 0 20px 0',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.main,
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 400,
                                                margin: '0 10px 10px 10px',
                                            }}
                                        >
                                            {t('partial_conf_po.core.partial_delivery')}
                                        </Typography>
                                        {splits[item.id] &&
                                            splits[item.id].map((split, index) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            margin: '0 10px 10px 10px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '10px',
                                                        }}
                                                    >
                                                        <span>
                                                            {t('partial_conf_po.core.delivery')}{' '}
                                                            {index + 1}
                                                        </span>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <TextField
                                                                sx={{
                                                                    height: '40px',
                                                                    marginRight: '10px',
                                                                }}
                                                                id="outlined-basic"
                                                                label="Lote"
                                                                size="small"
                                                                variant="outlined"
                                                                value={split.batch}
                                                                onChange={(event) =>
                                                                    onChangeAmount(
                                                                        event,
                                                                        item,
                                                                        'batch',
                                                                        index,
                                                                        0
                                                                    )
                                                                }
                                                                inputRef={(input) =>
                                                                    (inputRefs.current[index][0] =
                                                                        input)
                                                                }
                                                            />
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDateFns}
                                                            >
                                                                <DatePicker
                                                                    label={t(
                                                                        'partial_conf_po.core.due_date'
                                                                    )}
                                                                    value={split.expirationDate}
                                                                    onChange={(event) =>
                                                                        onChangeDate(
                                                                            event,
                                                                            item.id,
                                                                            index,
                                                                            'expirationDate'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        item.quantityDelivery >= 2
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            sx={{
                                                                                '& .MuiInputBase-root':
                                                                                    {
                                                                                        height: '40px',
                                                                                    },
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                            <BtnReject
                                                                actionFn={() =>
                                                                    deleteSplit(item, index)
                                                                }
                                                            />
                                                            {splits[item.id].length - 1 === index &&
                                                                getIsSplitReject(item) && (
                                                                    <Button
                                                                        onClick={() =>
                                                                            addSplits(item.id)
                                                                        }
                                                                        color="info"
                                                                        variant="outlined"
                                                                    >
                                                                        {t(
                                                                            'partial_conf_po.core.add'
                                                                        )}{' '}
                                                                        +
                                                                    </Button>
                                                                )}
                                                        </Box>
                                                        <Box>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDateFns}
                                                            >
                                                                <DatePicker
                                                                    label={t(
                                                                        'partial_conf_po.core.fab_date'
                                                                    )}
                                                                    value={split.buildDate}
                                                                    onChange={(event) =>
                                                                        onChangeDate(
                                                                            event,
                                                                            item.id,
                                                                            index,
                                                                            'buildDate'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        item.quantityDelivery >= 2
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            sx={{
                                                                                '& .MuiInputBase-root':
                                                                                    {
                                                                                        height: '40px',
                                                                                        marginRight:
                                                                                            '10px',
                                                                                    },
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label={t(
                                                                    'partial_conf_po.core.total_lote'
                                                                )}
                                                                size="small"
                                                                variant="outlined"
                                                                value={split.quantitySupplied}
                                                                onChange={(event) =>
                                                                    onChangeAmount(
                                                                        event,
                                                                        item,
                                                                        'quantitySupplied',
                                                                        index,
                                                                        1
                                                                    )
                                                                }
                                                                inputRef={(input) =>
                                                                    (inputRefs.current[index][1] =
                                                                        input)
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        {getIsSplitReject(item) && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: '',
                                                    margin: '10px 0 20px 20px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#921A28',
                                                        fontSize: '12px',
                                                        fontFamily: theme.typography.fontFamily,
                                                        fontWeight: 400,
                                                        margin: '0 10px 10px 10px',
                                                    }}
                                                >
                                                    {t('partial_conf_po.core.reject_product')}
                                                </Typography>
                                                <FormControl sx={{width: '400px'}}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        {t('partial_conf_po.core.reason_rejection')}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={rejects[item.id]}
                                                        label="Motivo de rechazo"
                                                        onChange={(event) =>
                                                            handleChangeReject(event, item.id)
                                                        }
                                                    >
                                                        <MenuItem value={'Wrong price'}>
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.wrong_price'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem value={'Product not avilable'}>
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.product_not_available'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem value={'Out of coverage'}>
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.out_of_coverage'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={
                                                                'Product does not match what was offered'
                                                            }
                                                        >
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.product_offered_not_match'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem value={'Unrecognized product'}>
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.unrecognized_product'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem value={'Discontinued product'}>
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.discontinued_product'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'Transportation not available'}
                                                        >
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.transpot_not_available'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem value={'Incomplete inventory'}>
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.incomplete_inventory'
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem value={'Delivery date change'}>
                                                            {t(
                                                                'partial_conf_po.core.rejection_list.delivery_date_change'
                                                            )}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        )}
                                        {itemHasSplitError(item.id) && (
                                            <Typography
                                                sx={{
                                                    color: '#921A28',
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 400,
                                                    margin: '0 10px 10px 10px',
                                                }}
                                            >
                                                {itemHasSplitError(item.id)?.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        ))}
                </Box>
            )}
        </TableContainer>
    );
};

export default ConfirmPurchaseOrderPartialTableMarket;
