import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {RootState} from '@config/store';
import {Box, Modal, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 60px;
`;

interface IConfirmPOModal {
    isModalOpen: boolean;
    closeModal: any;
    updatedOrderFn: () => void;
}

const ConfirmPOPartialModal = (props: IConfirmPOModal) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {isModalOpen, closeModal, updatedOrderFn} = props;
    const loading = useSelector((state: RootState) => state.purchaceOrderReducers.loading);
    const error = useSelector((state: RootState) => state.purchaceOrderReducers.error);
    const purchaseOrderUpdate = useSelector(
        (state: RootState) => state.purchaceOrderReducers.purchaseOrderUpdate
    );
    const [isConfirm, setIsConfirm] = useState(false);

    useEffect(() => {
        if (purchaseOrderUpdate && !loading && !error) {
            setIsConfirm(true);
        }
    }, [purchaseOrderUpdate]);

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('partial_conf_po.core.modal_confirm_partial.accept_partial')}
                    </Typography>
                </Nav>
                {isConfirm ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '20px 100px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'black',
                                margin: '20px 0px 20px 0',
                            }}
                        >
                            {t('partial_conf_po.core.modal_confirm_partial.confirm_partial')}
                        </Typography>
                        <svg
                            width="70"
                            height="69"
                            viewBox="0 0 70 69"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M32.8338 66.0172C15.2741 64.8272 1.88067 49.4865 3.0707 31.9268C4.26072 14.367 19.6014 0.973656 37.1611 2.16368C54.7209 3.3537 68.1143 18.6944 66.9242 36.2541C65.7342 53.8138 50.3935 67.2072 32.8338 66.0172ZM36.8005 7.48481C22.1674 6.49312 9.38351 17.6543 8.39182 32.2874C7.40014 46.9205 18.5613 59.7044 33.1944 60.6961C47.8275 61.6878 60.6114 50.5266 61.6031 35.8935C62.5948 21.2604 51.4336 8.47649 36.8005 7.48481ZM33.9561 45.5128C33.654 46.0269 33.3699 46.275 33.0858 46.523C32.2516 47.001 31.9855 46.983 31.4534 46.9469C30.3892 46.8748 29.8931 46.3066 29.1671 45.1883L22.2224 37.2339C21.4963 36.1155 21.5865 34.7853 22.7228 33.7931C23.5751 33.0491 25.1714 33.1573 25.9155 34.0095L31.3721 40.2594L44.769 20.0524C45.1252 18.7401 46.7396 18.5822 48.0338 19.2045C49.31 20.0928 49.4679 21.7072 48.8456 23.0014L33.9561 45.5128Z"
                                fill="#4BBC68"
                            />
                        </svg>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 500,
                                color: 'black',
                                marginLeft: '10px',
                                marginTop: '20px',
                            }}
                        >
                            {t('partial_conf_po.core.modal_confirm_partial.edit_partial')}
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                            padding: '20px 140px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'black',
                                marginLeft: '10px',
                            }}
                        >
                            {t(
                                'partial_conf_po.core.modal_confirm_partial.confirm_delivery_partial'
                            )}
                        </Typography>
                        <svg
                            width="102"
                            height="102"
                            viewBox="0 0 102 102"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M51 97.2188C56.2812 97.2188 60.5625 92.9375 60.5625 87.6562C60.5625 82.375 56.2812 78.0938 51 78.0938C45.7188 78.0938 41.4375 82.375 41.4375 87.6562C41.4375 92.9375 45.7188 97.2188 51 97.2188Z"
                                fill="#F9C33D"
                            />
                            <path
                                d="M54.1875 96.661C53.1661 97.0329 52.087 97.2217 51 97.2188C48.4639 97.2188 46.0316 96.2113 44.2383 94.418C42.445 92.6247 41.4375 90.1924 41.4375 87.6563C41.4375 85.1202 42.445 82.6879 44.2383 80.8946C46.0316 79.1013 48.4639 78.0938 51 78.0938C52.087 78.0909 53.1661 78.2797 54.1875 78.6516C52.3306 79.3152 50.7243 80.5367 49.5886 82.1487C48.4528 83.7607 47.8432 85.6844 47.8432 87.6563C47.8432 89.6282 48.4528 91.5519 49.5886 93.1639C50.7243 94.7759 52.3306 95.9973 54.1875 96.661Z"
                                fill="#F7A83E"
                            />
                            <path
                                d="M51 17.5312C54.5208 17.5312 57.375 14.6771 57.375 11.1562C57.375 7.63543 54.5208 4.78125 51 4.78125C47.4792 4.78125 44.625 7.63543 44.625 11.1562C44.625 14.6771 47.4792 17.5312 51 17.5312Z"
                                fill="#F9C33D"
                            />
                            <path
                                d="M57.3754 11.9209C57.2339 12.9974 56.8199 14.02 56.1725 14.8916C55.5251 15.7633 54.6657 16.4551 53.676 16.9016C52.6863 17.348 51.5989 17.5343 50.517 17.4427C49.4351 17.3512 48.3945 16.9848 47.4939 16.3783C46.5932 15.7718 45.8625 14.9454 45.3708 13.9773C44.8791 13.0092 44.6428 11.9316 44.6844 10.8466C44.7259 9.76163 45.0438 8.70523 45.6081 7.77758C46.1723 6.84992 46.9642 6.08175 47.9085 5.5459C47.8579 5.81967 47.826 6.09656 47.8129 6.37465C47.8084 7.49584 48.0997 8.59838 48.6574 9.57104C49.2151 10.5437 50.0195 11.3521 50.9894 11.9146C51.9592 12.4771 53.0603 12.7739 54.1815 12.775C55.3027 12.7761 56.4044 12.4815 57.3754 11.9209Z"
                                fill="#F7A83E"
                            />
                            <path
                                d="M23.9062 65.3431V38.9347C23.9062 23.9057 35.8275 11.2353 50.8566 11.1557C54.4266 11.1368 57.9652 11.8236 61.2689 13.1767C64.5727 14.5299 67.5764 16.5226 70.1075 19.0403C72.6386 21.5581 74.6472 24.5513 76.0177 27.8478C77.3883 31.1444 78.0938 34.6793 78.0938 38.2494V65.3431C78.0938 67.8793 79.1012 70.3115 80.8945 72.1049C82.6879 73.8982 85.1201 74.9056 87.6562 74.9056H90.8438C92.5345 74.9056 94.156 75.5773 95.3516 76.7728C96.5471 77.9684 97.2188 79.5899 97.2188 81.2806C97.2188 82.9714 96.5471 84.5929 95.3516 85.7885C94.156 86.984 92.5345 87.6556 90.8438 87.6556H11.1562C9.46549 87.6556 7.84399 86.984 6.64844 85.7885C5.4529 84.5929 4.78125 82.9714 4.78125 81.2806C4.78125 79.5899 5.4529 77.9684 6.64844 76.7728C7.84399 75.5773 9.46549 74.9056 11.1562 74.9056H14.3438C16.8799 74.9056 19.3121 73.8982 21.1055 72.1049C22.8988 70.3115 23.9062 67.8793 23.9062 65.3431Z"
                                fill="#F9C33D"
                            />
                            <path
                                d="M74.9062 49.4062C87.2291 49.4062 97.2188 39.4166 97.2188 27.0938C97.2188 14.7709 87.2291 4.78125 74.9062 4.78125C62.5834 4.78125 52.5938 14.7709 52.5938 27.0938C52.5938 39.4166 62.5834 49.4062 74.9062 49.4062Z"
                                fill="#F7616C"
                            />
                            <path
                                d="M92.8824 40.29C90.9843 42.894 88.5447 45.056 85.7313 46.6273C82.918 48.1986 79.7976 49.1418 76.585 49.3922C73.3723 49.6425 70.1435 49.194 67.1207 48.0775C64.0979 46.961 61.3528 45.203 59.0742 42.9244C56.7957 40.6458 55.0377 37.9007 53.9212 34.8779C52.8047 31.8551 52.3562 28.6263 52.6065 25.4137C52.8568 22.201 53.8001 19.0807 55.3714 16.2673C56.9426 13.454 59.1046 11.0143 61.7087 9.11621C58.5758 13.4142 57.0813 18.69 57.4945 23.9925C57.9077 29.295 60.2014 34.2756 63.9623 38.0364C67.7231 41.7972 72.7036 44.091 78.0062 44.5042C83.3087 44.9173 88.5844 43.4228 92.8824 40.29Z"
                                fill="#EA3D53"
                            />
                            <path
                                d="M78.0938 14.3438C78.0938 12.5833 76.6667 11.1562 74.9062 11.1562C73.1458 11.1562 71.7188 12.5833 71.7188 14.3438V27.0938C71.7188 28.8542 73.1458 30.2812 74.9062 30.2812C76.6667 30.2812 78.0938 28.8542 78.0938 27.0938V14.3438Z"
                                fill="white"
                            />
                            <path
                                d="M74.9062 43.0312C76.6667 43.0312 78.0938 41.6042 78.0938 39.8438C78.0938 38.0833 76.6667 36.6562 74.9062 36.6562C73.1458 36.6562 71.7188 38.0833 71.7188 39.8438C71.7188 41.6042 73.1458 43.0312 74.9062 43.0312Z"
                                fill="white"
                            />
                            <path
                                d="M97.0116 82.8744C96.7419 83.9682 96.1927 84.9732 95.4178 85.7909C94.8178 86.3904 94.1041 86.8641 93.3187 87.1843C92.5333 87.5045 91.6919 87.6647 90.8438 87.6556H11.1562C9.46549 87.6556 7.84399 86.984 6.64844 85.7884C5.4529 84.5929 4.78125 82.9714 4.78125 81.2806C4.78125 79.5899 5.4529 77.9684 6.64844 76.7728C7.84399 75.5773 9.46549 74.9056 11.1562 74.9056H14.3438C16.8799 74.9056 19.3121 73.8981 21.1055 72.1048C22.8988 70.3115 23.9062 67.8793 23.9062 65.3431V38.9347C23.9062 23.9056 35.8275 11.2353 50.8566 11.1556C52.5621 11.1469 54.2646 11.3017 55.9406 11.6178C52.4254 14.2293 49.5709 17.628 47.6059 21.5415C45.6409 25.455 44.62 29.7743 44.625 34.1534V60.5619C44.625 63.098 43.6175 65.5303 41.8242 67.3236C40.0309 69.1169 37.5986 70.1244 35.0625 70.1244H31.875C30.1842 70.1244 28.5627 70.796 27.3672 71.9916C26.1717 73.1871 25.5 74.8086 25.5 76.4994C25.5 78.1901 26.1717 79.8116 27.3672 81.0072C28.5627 82.2027 30.1842 82.8744 31.875 82.8744H97.0116Z"
                                fill="#F7A83E"
                            />
                        </svg>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 500,
                                color: 'black',
                                marginLeft: '10px',
                            }}
                        >
                            {t('partial_conf_po.core.modal_confirm_partial.aconfirm_partial')}{' '}
                            <b>
                                {' '}
                                {t('partial_conf_po.core.modal_confirm_partial.delivery_partial')}
                            </b>
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 500,
                                color: 'black',
                                marginLeft: '10px',
                            }}
                        >
                            {t(
                                'partial_conf_po.core.modal_confirm_partial.message_confirm_partial'
                            )}
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}>{t('close').toUpperCase()}</ButtonCancel>
                    {!isConfirm && (
                        <SaveButton disabled={loading} onClick={updatedOrderFn}>
                            {loading ? (
                                <Loader size={24} color="info" />
                            ) : (
                                t('continue').toUpperCase()
                            )}
                        </SaveButton>
                    )}
                </Box>
            </Box>
        </StyledModal>
    );
};

export default ConfirmPOPartialModal;
