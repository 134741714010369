import {
    GET_CSFSUPPLIER,
    GET_CSFSUPPLIER_SUCCESS,
    GET_CSFSUPPLIER_ERROR,
    POST_CSFSUPPLIER,
    POST_CSFSUPPLIER_SUCCESS,
    POST_CSFSUPPLIER_ERROR,
    CREATE_FILE_CSFSUPPLIER,
    CREATE_FILE_CSFSUPPLIER_SUCCESS,
    CREATE_FILE_CSFSUPPLIER_ERROR,
    GET_CSFSUPPLIER_DOWNLOAD,
    GET_CSFSUPPLIER_DOWNLOAD_SUCCESS,
    GET_CSFSUPPLIER_DOWNLOAD_ERROR,
    GET_DOWNLOAD_LISTCSF,
    GET_DOWNLOAD_LISTCSF_SUCCESS,
    GET_DOWNLOAD_LISTCSF_ERROR,
} from '@components/csf/csfSupplier/redux/types/CsfSupplierTypes';

const initialState = {
    csfSupplier: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
    limit: 10,
};

const csfSupplier = (state = initialState, action) => {
    switch (action.type) {
        case GET_CSFSUPPLIER:
            return {
                ...state,
                loading: true,
            };
        case GET_CSFSUPPLIER_SUCCESS:
            return {
                ...state,
                csfSupplier: action.value.data,
                count: action.value.metadata.count,
                page: action.value.metadata.currentPage,
                totalPages: action.value.metadata.totalPages,
                loading: false,
                limit: action.value.metadata.limit,
            };
        case GET_CSFSUPPLIER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_CSFSUPPLIER:
            return {
                ...state,
                loading: true,
            };
        case POST_CSFSUPPLIER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case POST_CSFSUPPLIER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CREATE_FILE_CSFSUPPLIER:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FILE_CSFSUPPLIER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CREATE_FILE_CSFSUPPLIER_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_CSFSUPPLIER_DOWNLOAD:
            return {
                ...state,
                loading: true,
            };
        case GET_CSFSUPPLIER_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_CSFSUPPLIER_DOWNLOAD_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_DOWNLOAD_LISTCSF:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_LISTCSF_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_DOWNLOAD_LISTCSF_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default csfSupplier;
