'use client';
import React from 'react';
interface TableCell {
    value: string;
    rowSpan?: number;
}
export type TableParagraphProps = {
    header: string[];
    body: TableCell[][];
};

const TableParagraph: React.FC<TableParagraphProps> = ({header, body}) => {
    return (
        <table
            style={{
                border: '1px solid black',
                width: '100%',
                borderCollapse: 'collapse',
            }}
        >
            <thead>
                <tr>
                    {header.map((title, idx) => (
                        <th
                            key={idx}
                            style={{
                                border: '1px solid black',
                                padding: '8px',
                                backgroundColor: '#d3d3d3',
                                textAlign: 'center',
                            }}
                        >
                            {title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {body.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => {
                            // Si la celda es parte de un grupo, evita renderizar celdas repetidas
                            if (
                                cellIndex === 1 &&
                                rowIndex > 0 &&
                                body[rowIndex - 1][cellIndex].rowSpan
                            ) {
                                return null;
                            }

                            return (
                                <td
                                    key={cellIndex}
                                    rowSpan={cell.rowSpan || 1}
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        verticalAlign: 'center',
                                    }}
                                >
                                    {cell.value}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableParagraph;
