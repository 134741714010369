import {
    Button,
    CircularProgress,
    InputLabel,
    Modal as MuiModal,
    Select as MuiSelect,
    Typography,
} from '@mui/material';
import {styled} from '@mui/system';

const Modal = styled(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40vw;
    border-radius: 10px;
`;

const Loader = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px; /* Ajusta según el tamaño del CircularProgress */
    margin-left: -12px; /* Ajusta según el tamaño del CircularProgress */
`;

const FileUploadContainer = styled('div')({
    border: '1px solid #c5d1d8',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    width: '614px',
});
const ImgIcon = styled('img')`
    height: 40px;
    margin-bottom: 30px;
`;

const Nav = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    height: '59px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    color: '#FFF',
    borderRadius: '3px 3px 0 0',
}));

const FileLabelButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Input = styled('input')`
    display: none;
`;

const InputLabelRol = styled(InputLabel)({
    '&.Mui-focused': {
        color: '#011B34',
    },
    color: '#011B34',
});

const LabelFileUpload = styled(Typography)`
    font-family: Mulish;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
`;

const Select = styled(MuiSelect)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
});

export {
    FileLabelButton,
    FileUploadContainer,
    ImgIcon,
    Input,
    InputLabelRol,
    LabelFileUpload,
    Loader,
    Modal,
    Nav,
    Select,
};
