import {GET_LAST_MODULE_RENDER} from '@/components/configuration/redux/types/ConfigurationTypes';

const initialState = {
    lastModuleRender: '',
};

const configuration = (state = initialState, action) => {
    switch (action.type) {
        case GET_LAST_MODULE_RENDER:
            return {
                ...state,
                lastModuleRender: action.value,
            };

        default:
            return state;
    }
};

export default configuration;
