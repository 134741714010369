import {
    GET_DOWNLOAD_TERMS_CONDITIONS,
    GET_DOWNLOAD_TERMS_CONDITIONS_ERROR,
    GET_DOWNLOAD_TERMS_CONDITIONS_SUCCESS,
    GET_TERMS_CONDITIONS,
    GET_TERMS_CONDITIONS_DETAILS_INFO,
    GET_TERMS_CONDITIONS_ERROR,
    GET_TERMS_CONDITIONS_SUCCESS,
    HIDE_TERMS_CONDITIONS_DETAILS_INFO,
} from '@/components/log/redux/types/BinnaclesTypes';
import StringUtils from '@utils/StringUtils';
import axios from 'axios';
export const getTermsConditionsDetailsName = (name, id) => {
    return (dispatch) => {
        dispatch({
            type: GET_TERMS_CONDITIONS_DETAILS_INFO,
            showTermconditions: true,
            vendorBranchOfficeId: id,
            name: name,
        });
    };
};
export const hideTermsConditionsDetailsInfo = () => {
    return {
        type: HIDE_TERMS_CONDITIONS_DETAILS_INFO,
    };
};
export const getTermsConditions = () => {
    return {
        type: GET_TERMS_CONDITIONS,
    };
};
export const getTermsConditionsSuccess = (result) => {
    return {
        type: GET_TERMS_CONDITIONS_SUCCESS,
        value: result,
    };
};
export const getTermsConditionsError = (error) => {
    return {
        type: GET_TERMS_CONDITIONS_ERROR,
        value: error,
    };
};

export function fetchGetTermsConditions(token, page, limit, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
                supplier: filters.user,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getTermsConditions());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/binnacles/terms-conditions${queryString}`,
                {headers}
            );
            dispatch(getTermsConditionsSuccess(response.data));
        } catch (error) {
            dispatch(getTermsConditionsError(true));
        }
    };
}

export const getTermsConditionsDownload = () => {
    return {
        type: GET_DOWNLOAD_TERMS_CONDITIONS,
    };
};
export const getTermsConditionsDownloadSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_TERMS_CONDITIONS_SUCCESS,
        value: result,
    };
};
export const getTermsConditionsDownloadError = (error) => {
    return {
        type: GET_DOWNLOAD_TERMS_CONDITIONS_ERROR,
        value: error,
    };
};

const typeData = {
    csv: 'text/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export function fetchGetTermsConditionsDownload(token, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                ...filters,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getTermsConditionsDownload());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/binnacles/terms-conditions/download/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(
                new Blob([response.data], {type: `${typeData[format]};charset=utf-8`})
            );

            const a = document.createElement('a');
            a.href = url;
            a.download = `terms-conditions.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getTermsConditionsDownloadSuccess());
        } catch (error) {
            dispatch(getTermsConditionsDownloadError(true));
        }
    };
}
