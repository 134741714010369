import {ButtonAlternative} from '@/components/UI/atoms/buttons/ButtonAlternative';
import {ColorButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import {AppDispatch} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import background from '@login/images/laptop_abi.svg';
import logoAbi from '@login/images/logoAbi.svg';
import {fetchPostRecoveryPassword} from '@login/redux/actions/LoginActions';
import {Box, Typography, useTheme} from '@mui/material';
import {customEmailValidation} from '@utils/customEmailValidation';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import AuthFooter from '@/components/UI/organisms/footer/AuthFooter';

const ForgotPassword = () => {
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    interface IFormRecoveryPassword {
        email: string;
    }
    const schemaFormModal = yup.object({
        email: customEmailValidation('Correo no válido')
            .min(3, 'Longitud mínima de 3 caracteres')
            .max(256, 'Longitud máxima de 256 caracteres')
            .required('Correo requerido'),
    });

    const {
        handleSubmit,
        formState: {},
    } = useForm<IFormRecoveryPassword>({resolver: yupResolver(schemaFormModal)});
    const onSubmit = (data: IFormRecoveryPassword) => {
        const json = {
            email: data.email,
        };

        dispatch(fetchPostRecoveryPassword(json));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            }}
        >
            <Box
                sx={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    '@media (min-height: 800px)': {
                        height: 'calc(100vh - 70px)',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '51vw',
                        background: theme.palette.primary.main,
                        paddingTop: '80px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#FFFFFF',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 500,
                        }}
                    >
                        Bienvenido al
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '40px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                        }}
                    >
                        Portal de proveedores
                    </Typography>
                    <img src={background} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '20vh',
                        width: '49vw',
                    }}
                >
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <img width="184px" src={logoAbi} />
                        <Typography
                            sx={{
                                fontSize: '23px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                marginTop: '40px',
                            }}
                        >
                            Recuperar contraseña{' '}
                        </Typography>
                        {/* <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 400,
                                margin: ' 10px 0 28px 0 ',
                            }}
                        >
                            Ingresa el correo electrónico con el que te registrarte y te enviaremos
                            un enlace para restablecerla tu contraseña.{' '}
                        </Typography> */}
                        {/* <Controller
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Correo electrónico"
                                    size="small"
                                />
                            )}
                            name="email"
                            control={control}
                        /> */}
                        {/* <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '18px',
                            }}
                        >
                            {errors?.email?.message}
                        </Typography> */}
                        <ColorButton
                            onClick={() => {
                                const url = 'https://mdweb.ab-inbev.com:9443/forgot-password';
                                window.open(url, '_blank');
                            }}
                        >
                            Recuperar
                        </ColorButton>
                        <ButtonAlternative
                            onClick={() => {
                                window.location.href = 'auth/login';
                            }}
                        >
                            Iniciar sesión{' '}
                        </ButtonAlternative>
                    </Box>
                </Box>
            </Box>
            <AuthFooter />
        </Box>
    );
};

export default ForgotPassword;
