import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainerBinnanceDetails,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {
    TextButton,
    TextButtonDetails,
    TextError,
    TextSuccess,
} from '@components/UI/molecules/textButton/textButtons';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {TypographyStyledUser} from '@components/faqs/StyledFAQ';
import {
    fetchGetBinnaclesDetails,
    fetchGetBinnaclesDownloadById,
} from '@components/log/redux/actions/BinnaclesActions';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, Grid, IconButton, Typography, useTheme} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
const DEFAULT_PAGE = 1;
const BinnaclesDetails: React.FC = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    interface ITable {
        startTime: string;
        endTime: string;
        module: string;
        country: string;
        lastAccessDate: string;
        countAccess: string;
        externalId: string;
        email: string;
    }
    interface IFormDate {
        dateIni: Date;
        dateEnd: Date;
    }
    const {userId} = useParams();
    const {t} = useTranslation();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const detailsbinnacles = useSelector((state: RootState) => state.binnacles.detailsbinnacles);
    const name = useSelector((state: RootState) => state.binnacles.name);
    const types = useSelector((state: RootState) => state.binnacles.types);
    const detailscurrentPage = useSelector((state: RootState) => state.binnacles.detailspage);
    const detailslimit = useSelector((state: RootState) => state.binnacles.detailslimit);
    const detailstotalPages = useSelector((state: RootState) => state.binnacles.detailstotalPages);
    const loading = useSelector((state: RootState) => state.binnacles.loading);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetBinnaclesDetails(token, userId, value, detailslimit, json));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(
            fetchGetBinnaclesDetails(token, userId, detailscurrentPage, event.target.value, json)
        );
    };
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/configuration'); // Esta función navegará a la página anterior en el historial
    };
    const schemaFormModal = yup.object({
        dateIni: yup.date().required('Fecha inicio requerido'),
        dateEnd: yup.date().required('Fecha final requerido'),
    });

    const {handleSubmit, control, watch} = useForm<IFormDate>({
        resolver: yupResolver(schemaFormModal),
    });
    const handleChangeDownload = (format: any) => {
        const watchForm = watch();
        const json = {
            dateIni: DateTime.fromJSDate(watchForm.dateIni).toFormat('MM-dd-yyyy'),
            dateEnd: DateTime.fromJSDate(watchForm.dateEnd).toFormat('MM-dd-yyyy'),
        };
        dispatch(fetchGetBinnaclesDownloadById(token, userId, format, json));
    };
    const watchForm = watch();
    const json = {
        dateIni: DateTime.fromJSDate(watchForm.dateIni).toFormat('MM-dd-yyyy'),
        dateEnd: DateTime.fromJSDate(watchForm.dateEnd).toFormat('MM-dd-yyyy'),
    };
    const onSubmit = () => {
        dispatch(fetchGetBinnaclesDetails(token, userId, detailscurrentPage, detailslimit, json));
    };
    useEffect(() => {
        if (token) {
            const json = {
                dateIni: DateTime.now().startOf('month').toFormat('MM-dd-yyyy'),
                dateEnd: DateTime.now().toFormat('MM-dd-yyyy'),
            };
            dispatch(fetchGetBinnaclesDetails(token, userId, DEFAULT_PAGE, detailslimit, json));
        }
    }, [dispatch, token]);

    const removeAccents = (str: string) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '20px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <IconButton onClick={handleGoBack} aria-label="Go back">
                    <ArrowBackIcon />
                </IconButton>
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('logbook_and_details.details')}:
                </Typography>
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 500,
                        marginLeft: '10px',
                    }}
                >
                    {name}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '20px',
                        padding: '15px',
                        height: '73px',
                        width: '100%',
                        background: '#fff',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateIni"
                                control={control}
                                defaultValue={DateTime.now().startOf('month').toJSDate()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.start_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        maxDate={new Date()}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEnd"
                                control={control}
                                defaultValue={DateTime.now().toJSDate()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.end_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        minDate={watchForm.dateIni}
                                        maxDate={DateTime.now().toJSDate()}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextButton>{t('components.filter_btn.title').toUpperCase()}</TextButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                        <TextButtonDetails
                            onClick={() => {
                                handleChangeDownload('csv');
                            }}
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={IconDownload} />
                                <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                    CSV
                                </TypographyStyledUser>
                            </div>
                        </TextButtonDetails>
                        <TextButtonDetails
                            onClick={() => {
                                handleChangeDownload('xlsx');
                            }}
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={IconDownload} />
                                <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                    XLSX
                                </TypographyStyledUser>
                            </div>
                        </TextButtonDetails>
                    </Box>
                </Box>
            </Box>
            <TableContainerBinnanceDetails container>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={1}>
                        <TableCellHeadInitial>
                            {t('logbook_and_details.last_access_date')}
                        </TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('logbook_and_details.first_hour_authentication')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>
                            {t('logbook_and_details.latest_authentication')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>ID SAP</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>ID {t('user')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('country')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('logbook_and_details.module.title')}</TableCellHead>
                    </Grid>

                    <Grid item xs={1}>
                        <TableCellHead>{t('logbook_and_details.authentications')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHeadFinal>{t('type')}</TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    // Muestra el esqueleto de carga mientras se cargan los datos
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    detailsbinnacles.map((item: ITable) => (
                        <>
                            <Grid item xs={1}>
                                <TableCell>
                                    {DateTime.fromISO(
                                        new Date(item.lastAccessDate).toISOString()
                                    ).toFormat('dd/MM/yyyy')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>
                                    {DateTime.fromFormat(
                                        item.startTime,
                                        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
                                    ).toFormat('HH:mm:ss')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>
                                    {DateTime.fromFormat(
                                        item.endTime,
                                        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
                                    ).toFormat('HH:mm:ss')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>{item.externalId} </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>{item.email} </TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>{item.country} </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>
                                    {t(`logbook_and_details.module.${removeAccents(item.module)}`)}
                                </TableCell>
                            </Grid>

                            <Grid item xs={1}>
                                <TableCell>{item.countAccess}</TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellIcons>
                                    {types === 'Externo' ? (
                                        <TextError>{types}</TextError>
                                    ) : (
                                        <TextSuccess>{types}</TextSuccess>
                                    )}
                                </TableCellIcons>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainerBinnanceDetails>
            <CustomPagination
                totalPages={detailstotalPages}
                currentPage={detailscurrentPage}
                handleChange={handleChange}
                limit={detailslimit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};
export default BinnaclesDetails;
