import * as React from 'react';

import {Link} from '@mui/material';
import Image, {ImageProps} from '@/components/UI/atoms/images/Image';

interface ImageWithLinkProps extends ImageProps {
    url: string;
    target?: string;
}

function ImageWithLink({imgSrc, alt, width, height, url, target}: ImageWithLinkProps) {
    if (!imgSrc) return null;

    return (
        <Link component="a" href={url} target={target} rel="noopener noreferrer">
            <Image imgSrc={imgSrc} alt={alt} width={width} height={height} />
        </Link>
    );
}

ImageWithLink.defaultProps = {
    url: '#',
    target: '_blank',
};

export default ImageWithLink;
