import {
    GET_BINNACLES,
    GET_BINNACLES_BYID,
    GET_BINNACLES_BYID_ERROR,
    GET_BINNACLES_BYID_SUCCESS,
    GET_BINNACLES_DETAILS,
    GET_BINNACLES_ERROR,
    GET_BINNACLES_SUCCESS,
    GET_DOWNLOAD_BINNACLES,
    GET_DOWNLOAD_BINNACLES_BYID,
    GET_DOWNLOAD_BINNACLES_BYID_ERROR,
    GET_DOWNLOAD_BINNACLES_BYID_SUCCESS,
    GET_DOWNLOAD_BINNACLES_ERROR,
    GET_DOWNLOAD_BINNACLES_SUCCESS,
    GET_DOWNLOAD_TERMS_CONDITIONS,
    GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS,
    GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_ERROR,
    GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_SUCCESS,
    GET_DOWNLOAD_TERMS_CONDITIONS_ERROR,
    GET_DOWNLOAD_TERMS_CONDITIONS_SUCCESS,
    GET_TERMS_CONDITIONS,
    GET_TERMS_CONDITIONS_DETAILS,
    GET_TERMS_CONDITIONS_DETAILS_ERROR,
    GET_TERMS_CONDITIONS_DETAILS_INFO,
    GET_TERMS_CONDITIONS_DETAILS_SUCCESS,
    GET_TERMS_CONDITIONS_ERROR,
    GET_TERMS_CONDITIONS_SUCCESS,
    HIDE_TERMS_CONDITIONS_DETAILS_INFO,
    SET_CURRENT_INFO_NOTIFICATION,
} from '@/components/log/redux/types/BinnaclesTypes';
export const initialTermsConditionsState = {
    termsConditions: [],
    countTerms: 0,
    pageTerms: 1,
    limitTerms: 10,
    loadingTerms: false,
    totalPagesTerms: 1,
    errorTerms: false,
};
export const initialTermsConditionsDetailsState = {
    termsConditionsDetails: [],
    countTermsDetails: 0,
    pageTermsDetails: 1,
    limitTermsDetails: 10,
    loadingTermsDetails: false,
    totalPagesTermsDetails: 1,
    errorTermsDetails: false,
};
const initialState = {
    binnacles: [],
    count: 0,
    page: 1,
    limit: 10,
    typeUsers: null,
    totalPages: 1,
    detailsbinnacles: [],
    detailscount: 0,
    detailspage: 1,
    detailslimit: 10,
    detailstotalPages: 1,
    loading: false,
    error: false,
    types: '',
    name: '',
    firstRegistrationDate: null,
    latestRegistrationDate: null,
    ...initialTermsConditionsState,
    ...initialTermsConditionsDetailsState,
    showTermconditions: false,
    vendorBranchOfficeId: '',
    nameDetail: '',
    viewCurrenNofitication: {
        vendorName: '',
        orderReferenceId: '',
        userFullName: '',
        companyName: '',
        actionDate: '',
        acceptedTerms: false,
    },
};

const binnacles = (state = initialState, action) => {
    switch (action.type) {
        case GET_TERMS_CONDITIONS:
            return {
                ...state,
                loadingTerms: true,
            };
        case GET_TERMS_CONDITIONS_DETAILS:
            return {
                ...state,
                loadingTermsDetails: true,
            };
        case GET_BINNACLES:
            return {
                ...state,
                loading: true,
            };
        case GET_TERMS_CONDITIONS_SUCCESS:
            return {
                ...state,
                termsConditions: action.value.data,
                countTerms: action.value.metadata.count,
                pageTerms: action.value.metadata.currentPage,
                limitTerms: action.value.metadata.limit,
                totalPagesTerms: action.value.metadata.totalPages,
                loadingTerms: false,
            };
        case GET_TERMS_CONDITIONS_DETAILS_SUCCESS:
            return {
                ...state,
                termsConditionsDetails: action.value.data,
                countTermsDetails: action.value.metadata.count,
                pageTermsDetails: action.value.metadata.currentPage,
                limitTermsDetails: action.value.metadata.limit,
                totalPagesTermsDetails: action.value.metadata.totalPages,
                loadingTermsDetails: false,
            };
        case GET_BINNACLES_SUCCESS:
            return {
                ...state,
                binnacles: action.value.data,
                count: action.value.metadata.count,
                typeUsers: action.value.metadata.typeUsers,
                page: action.value.metadata.currentPage,
                limit: action.value.metadata.limit,
                totalPages: action.value.metadata.totalPages,
                firstRegistrationDate: action.value.metadata.firstRegistrationDate,
                latestRegistrationDate: action.value.metadata.latestRegistrationDate,
                loading: false,
            };
        case GET_BINNACLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_TERMS_CONDITIONS_ERROR:
            return {
                ...state,
                errorTerms: action.value,
                loadingTerms: false,
            };
        case GET_TERMS_CONDITIONS_DETAILS_ERROR:
            return {
                ...state,
                errorTermsDetails: action.value,
                loadingTermsdetails: false,
            };
        case GET_BINNACLES_BYID:
            return {
                ...state,
                loading: true,
            };
        case GET_BINNACLES_BYID_SUCCESS:
            return {
                ...state,
                detailsbinnacles: action.value.data,
                detailscount: action.value.metadata.count,
                detailspage: action.value.metadata.currentPage,
                detailslimit: action.value.metadata.limit,
                detailstotalPages: action.value.metadata.totalPages,
                loading: false,
            };
        case GET_BINNACLES_BYID_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_BINNACLES_DETAILS:
            return {
                ...state,
                types: action.types,
                name: action.name,
            };
        case GET_TERMS_CONDITIONS_DETAILS_INFO:
            return {
                ...state,
                showTermconditions: action.showTermconditions,
                vendorBranchOfficeId: action.vendorBranchOfficeId,
                nameDetail: action.name,
            };
        case HIDE_TERMS_CONDITIONS_DETAILS_INFO:
            return {
                ...state,
                showTermconditions: false,
                vendorBranchOfficeId: '',
                nameDetail: '',
            };
        case GET_DOWNLOAD_BINNACLES:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_BINNACLES_SUCCESS:
            return {
                ...state,
            };
        case GET_DOWNLOAD_BINNACLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_DOWNLOAD_TERMS_CONDITIONS:
            return {
                ...state,
                loadingTerms: true,
            };
        case GET_DOWNLOAD_TERMS_CONDITIONS_SUCCESS:
            return {
                ...state,
            };
        case GET_DOWNLOAD_TERMS_CONDITIONS_ERROR:
            return {
                ...state,
                errorTerms: action.value,
                loadingTerms: false,
            };
        case GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS:
            return {
                ...state,
                loadingTermsDetails: true,
            };
        case GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_SUCCESS:
            return {
                ...state,
            };
        case GET_DOWNLOAD_TERMS_CONDITIONS_DETAILS_ERROR:
            return {
                ...state,
                errorTermsDetails: action.value,
                loadingTermsDetails: false,
            };
        case GET_DOWNLOAD_BINNACLES_BYID:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_BINNACLES_BYID_SUCCESS:
            return {
                ...state,
            };
        case GET_DOWNLOAD_BINNACLES_BYID_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };

        case SET_CURRENT_INFO_NOTIFICATION:
            return {
                ...state,
                viewCurrenNofitication: action.value,
            };
        default:
            return state;
    }
};

export default binnacles;
