import {Button, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

export const Container = styled('div')({
    position: 'relative',
    display: 'inline-block',
});
export const ButtonMoreItems = styled(Button)({
    '&:hover': {
        cursor: 'pointer',
    },
    color: '#00ACFF',
    textAlign: 'left',
    backgroundColor: 'white',
    border: 'none',
    padding: 0,
    minWidth: '40px',
    fontSize: '12px',
    position: 'relative',
});
export const ItemsList = styled('div')`
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    height: auto;
    width: auto;
    max-width: 200px;
    min-width: 200px;
    color: black;
    overflow: auto;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    margin-top: 8px;
`;
export const TypographyStyled400 = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    fontWeight: 400,
}));
