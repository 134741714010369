'use client';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@/components/UI/atoms/inputs/UploadDragAndDrop/styled-component/StyledUploadDragAndDrop';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, IconButton, Typography} from '@mui/material';
import React, {useState} from 'react';
import {Control, Controller, FieldErrors} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export type UploadDragAndDropProps = {
    control: Control<any, any>;
    name: string;
    errors?: FieldErrors<any>;
    MaxFileSizeMb?: number;
    accept?: string[];
    multiple?: boolean;
    showBorder?: boolean;
};

export const ALLOWED_FILE_TYPES_XML = ['text/xml'];
export const ALLOWED_FILE_TYPES_VIDEO = ['video/mp4', 'video/quicktime'];
export const ALLOWED_FILE_TYPES_PDF = ['application/pdf'];
export const ALLOWED_FILE_TYPES_IMAGES = ['image/jpeg', 'image/png'];
export const ALLOWED_FILE_TYPE_ZIP = [
    'application/zip',
    'application/octet-stream',
    'application/x-zip-compressed',
    'multipart/x-zip',
];
export const MAX_FILE_SIZE_MB = 10;
const ACCEPT_ALL_FILES = [
    ...ALLOWED_FILE_TYPES_XML,
    ...ALLOWED_FILE_TYPES_VIDEO,
    ...ALLOWED_FILE_TYPES_PDF,
    ...ALLOWED_FILE_TYPES_IMAGES,
    ...ALLOWED_FILE_TYPE_ZIP,
];

const textRequired: Record<string, string> = {
    'text/xml': 'XML',
    'application/zip': 'ZIP',
    'image/jpeg': 'IMG',
    'image/png': 'IMG',
    'application/pdf': 'PDF',
    'video/mp4': 'MP4',
    'video/quicktime': 'QUICKTIME',
};

const UploadDragAndDrop: React.FC<UploadDragAndDropProps> = ({
    name,
    control,
    multiple = false,
    accept = ACCEPT_ALL_FILES,
    MaxFileSizeMb = MAX_FILE_SIZE_MB,
    showBorder = true,
}) => {
    const [errorFile, setErrorFile] = useState<string>('');
    const {t} = useTranslation();

    const handleFileChange = (event: any, type: string) => {
        event.stopPropagation();
        const file = type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0];
        if (!file) {
            setErrorFile(`Archivo requerido.`);
            throw new Error('Archivo requerido.');
        }
        if (!accept.includes(file.type)) {
            setErrorFile(
                `Archivo inválido. Por favor sube un archivo tipo ${accept
                    .map((item: string) => textRequired[item])
                    .join(', ')}.`
            );
            throw new Error(
                `Archivo inválido. Por favor sube un archivo tipo ${accept
                    .map((item: string) => textRequired[item])
                    .join(', ')}.`
            );
        } else if (file.size > MaxFileSizeMb * 1024 * 1024) {
            setErrorFile(
                `El tamaño del archivo supera los ${MaxFileSizeMb} MB. Elija un archivo más pequeño.`
            );
            throw new Error(
                `El tamaño del archivo supera los ${MaxFileSizeMb} MB. Elija un archivo más pequeño.`
            );
        } else {
            setErrorFile('');
        }
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({field, fieldState}) => (
                <FileUploadContainer
                    style={{border: showBorder ? '1px solid #c5d1d8' : 'none'}}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                        e.preventDefault();
                        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                            try {
                                handleFileChange(e, 'drop');
                                const currentFiles = field.value ? field.value : [];
                                field.onChange([...currentFiles, ...e.dataTransfer.files]);
                            } catch (e) {
                                return;
                            }
                        }
                    }}
                    onClick={() => {
                        const fileInput = document.getElementById('fileInput');
                        if (fileInput) {
                            fileInput.click();
                        }
                    }}
                    sx={{
                        borderColor: fieldState.error ? 'red' : 'none',
                        width: '100%',
                        minWidth: '320px',
                        height: '150px',
                    }}
                >
                    <StyledInput
                        id="fileInput"
                        type="file"
                        accept={accept.join(',')}
                        size={MaxFileSizeMb * 1024 * 1024}
                        onChange={(e) => {
                            const files = e.target.files ? e.target.files : null;
                            if (files) {
                                try {
                                    const currentFiles = field.value ? field.value : [];
                                    field.onChange([...currentFiles, ...files]);
                                    handleFileChange(e, 'change');
                                } catch (e) {
                                    return;
                                }
                            }
                        }}
                        multiple={multiple}
                    />
                    {field.value && !errorFile ? (
                        <>
                            {[...field.value].length > 0 &&
                                [...field.value].map((file: any, index: number) => (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        key={`${file.name}-${index}`}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                mr: 1,
                                            }}
                                        >
                                            {file.name}
                                        </Typography>
                                        <IconButton
                                            aria-label="Eliminar"
                                            onClick={(e) => {
                                                const files = [...field.value].filter(
                                                    (_, i) => i !== index
                                                );
                                                field.onChange(files.length > 0 ? files : null);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                        </>
                    ) : (
                        <Box>
                            <StyledFileLabelButton variant="outlined" as="span">
                                <img src={CloudIcon} />
                                <StyledLabelFileUpload>
                                    {t('drag_upload_files')}
                                </StyledLabelFileUpload>
                            </StyledFileLabelButton>
                            <Typography variant="caption" color="error">
                                {errorFile}
                            </Typography>
                        </Box>
                    )}
                </FileUploadContainer>
            )}
        />
    );
};

export default UploadDragAndDrop;
