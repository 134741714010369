import {
    GET_ROLES_SUCCESS_OLD,
    GET_ROLES_ERROR_OLD,
    GET_ROLES_OLD,
} from '@/components/general/roles/redux/types/RolesTypes';

import axios from 'axios';
export const getRoles = () => {
    return {
        type: GET_ROLES_OLD,
    };
};
export const getRolesSuccess = (result) => {
    return {
        type: GET_ROLES_SUCCESS_OLD,
        value: result,
    };
};
export const getRolesError = (error) => {
    return {
        type: GET_ROLES_ERROR_OLD,
        value: error,
    };
};

export function fetchGetRoles(token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getRoles());
        try {
            await axios.get(`${process.env.REACT_APP_BASE_URL}/v2/catalogs/roles`, {headers});
            dispatch(
                getRolesSuccess([
                    {
                        id: 1,
                        rol: 'ADMINISTRADOR',
                        value: 'ADMIN',
                        status: true,
                    },
                    {
                        id: 2,
                        rol: 'PROVEEDOR',
                        value: 'SUPPLIER',
                        status: true,
                    },
                    {
                        id: 3,
                        rol: 'APROBADOR',
                        value: 'APPROVER',
                        status: true,
                    },
                    {
                        id: 4,
                        rol: 'USUARIO_INTERNO',
                        value: 'INTERNAL_USER',
                        status: true,
                    },
                ])
            );
        } catch (error) {
            dispatch(getRolesError(true));
        }
    };
}
