import {
    GET_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    POST_ORDER,
    POST_ORDER_SUCCESS,
    POST_ORDER_ERROR,
    GET_ORDER_INCOMPLETE,
    GET_ORDER_INCOMPLETE_SUCCESS,
    GET_ORDER_INCOMPLETE_ERROR,
    RESET_ORDER_INCOMPLETE_SUCCESS,
    RESET_ORDER_SUCCESS,
} from '@/components/order/redux/types/OrderTypes';

const initialState = {
    order: null,
    orderSuccess: null,
    orderIncomplete: null,
    orderId: null,
    loading: false,
    error: false,
    loadingpost: false,
    errorMessage: '',
    referenceId: null,
};

const order = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER:
            return {
                ...state,
                order: null,
                loading: true,
                error: false,
            };
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.value.data,
                orderSuccess: null,
                loading: false,
            };
        case GET_ORDER_ERROR:
            return {
                ...state,
                error: true,
                order: null,
                loading: false,
            };
        case POST_ORDER:
            return {
                ...state,
                loadingpost: true,
            };
        case POST_ORDER_SUCCESS:
            return {
                ...state,
                orderSuccess: action.value,
                loadingpost: false,
                order: null,
                orderIncomplete: null,
                orderId: null,
            };
        case POST_ORDER_ERROR:
            return {
                ...state,
                error: action.value,
                order: null,
                loadingpost: false,
                errorMessage: action.message,
            };
        case GET_ORDER_INCOMPLETE:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDER_INCOMPLETE_SUCCESS:
            return {
                ...state,
                orderIncomplete: action.value,
                orderId: action.order,
                loading: false,
                referenceId: action.referenceId,
            };
        case GET_ORDER_INCOMPLETE_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case RESET_ORDER_INCOMPLETE_SUCCESS:
            return {
                ...state,
                orderIncomplete: null,
                loading: false,
            };
        case RESET_ORDER_SUCCESS:
            return {
                ...state,
                order: null,
                orderSuccess: null,
                orderIncomplete: null,
                orderId: null,
                loading: false,
            };
        default:
            return state;
    }
};

export default order;
