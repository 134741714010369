import {TextButton} from '@/components/UI/molecules/textButton/textButtons';
import {IFormTermConditions} from '@/components/log/interface';
import {Box, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React from 'react';
import {Control, Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
export interface ISearchTermsConditionsProp {
    control: Control<IFormTermConditions, any>;
    watchForm: IFormTermConditions;
}
const SearchTermsConditionsDetails: React.FC<ISearchTermsConditionsProp> = ({
    control,
    watchForm,
}) => {
    const {t} = useTranslation();
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    return (
        <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '5px'}}>
            <Controller
                name="user"
                control={control}
                render={({field: {onChange, value}}) => {
                    return (
                        <TextField
                            value={value}
                            onChange={onChange}
                            label={t('user')}
                            size="small"
                        />
                    );
                }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                <Controller
                    name="dateIni"
                    control={control}
                    defaultValue={DateTime.now().startOf('month').toJSDate()}
                    render={({field: {onChange, value}}) => (
                        <DatePicker
                            label={t('logbook_and_details.start_date')}
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: '40px',
                                        },
                                    }}
                                />
                            )}
                            maxDate={new Date()}
                        />
                    )}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                <Controller
                    name="dateEnd"
                    control={control}
                    defaultValue={DateTime.now().toJSDate()}
                    render={({field: {onChange, value}}) => (
                        <DatePicker
                            label={t('logbook_and_details.end_date')}
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: '40px',
                                        },
                                    }}
                                />
                            )}
                            minDate={watchForm.dateIni}
                            maxDate={DateTime.now().toJSDate()}
                        />
                    )}
                />
            </LocalizationProvider>
            <TextButton>{t('components.filter_btn.title').toUpperCase()}</TextButton>
        </Box>
    );
};
export default SearchTermsConditionsDetails;
