import {
    GET_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    POST_ORDER,
    POST_ORDER_SUCCESS,
    POST_ORDER_ERROR,
    GET_ORDER_INCOMPLETE_SUCCESS,
    RESET_ORDER_INCOMPLETE_SUCCESS,
    RESET_ORDER_SUCCESS,
} from '@/components/order/redux/types/OrderTypes';

import axios from 'axios';
export const getOrder = () => {
    return {
        type: GET_ORDER,
    };
};
export const getOrderSuccess = (result) => {
    return {
        type: GET_ORDER_SUCCESS,
        value: result,
    };
};
export const getOrderError = (error, result) => {
    return {
        type: GET_ORDER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetOrder(order, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getOrder());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${order}`,
                {headers}
            );
            dispatch(getOrderSuccess(response.data));
        } catch (error) {
            dispatch(getOrderError(true));
        }
    };
}

export const postOrder = () => {
    return {
        type: POST_ORDER,
    };
};
export const postOrderSuccess = (result) => {
    return {
        type: POST_ORDER_SUCCESS,
        value: result,
    };
};
export const postOrderError = (error, message, result) => {
    return {
        type: POST_ORDER_ERROR,
        value: error,
        payload: result,
        message: message,
    };
};

export function fetchPostOrder(orderId, data, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postOrder());
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${orderId}`,
                data,
                {
                    headers,
                }
            );
            if (response.data.error) {
                window.location.href = '/ordersuccess';
                dispatch(postOrderError(true, response.data.message));
            } else {
                window.location.href = '/ordersuccess';
                dispatch(postOrderSuccess(response.data.data));
                dispatch(postOrderError(false, ''));
            }
        } catch (error) {
            dispatch(postOrderError(true, ''));
        }
    };
}

export const getOrderIncompleteSuccess = (result, order, referenceId) => {
    return {
        type: GET_ORDER_INCOMPLETE_SUCCESS,
        value: result,
        order: order,
        referenceId: referenceId,
    };
};
export const resetOrderIncompleteSuccess = () => {
    return {
        type: RESET_ORDER_INCOMPLETE_SUCCESS,
    };
};

export const resetOrderSuccess = () => {
    return {
        type: RESET_ORDER_SUCCESS,
    };
};
