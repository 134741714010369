import axios from 'axios';

import StringUtils from '@/utils/StringUtils';
import {
    GET_PURCHASE_ORDERS,
    GET_PURCHASE_ORDERS_ERROR,
    GET_PURCHASE_ORDERS_SUCCESS,
    GET_PO_LINE_ITEMS,
    GET_PO_LINE_ITEMS_ERROR,
    GET_PO_LINE_ITEMS_SUCCESS,
    SELECT_ALL_PURCHASE_ORDERS,
    SELECT_PURCHASE_ORDERS,
    SELECT_EXTERNAL_IDS,
    HIDE_TERMS_MODAL,
    SHOW_TERMS_MODAL,
    CLOSE_PO_ACCORDION,
} from '@components/terms/redux/types/TermsAcceptanceTypes';

export const getPurchaseOrders = (result) => {
    return {
        type: GET_PURCHASE_ORDERS,
        value: result,
    };
};

export const getPurchaseOrdersSuccess = (result) => {
    return {
        type: GET_PURCHASE_ORDERS_SUCCESS,
        value: {
            ...result,
            data: result.data.map((item) => ({
                ...item,
            })),
        },
    };
};

export const getPurchaseOrdersError = (error) => {
    return {
        type: GET_PURCHASE_ORDERS_ERROR,
        value: error,
    };
};

export const getPoLineItems = (result) => {
    return {
        type: GET_PO_LINE_ITEMS,
        value: result,
    };
};

export const getPoLineItemsSuccess = (result) => {
    return {
        type: GET_PO_LINE_ITEMS_SUCCESS,
        value: result,
    };
};

export const getPoLineItemsError = (error) => {
    return {
        type: GET_PO_LINE_ITEMS_ERROR,
        value: error,
    };
};

/**
 * Fetches the line items for a given purchase order.
 *
 * @param {Object} param0 - The parameters for the request.
 * @param {string} param0.token - The authorization token.
 * @param {number} param0.page - The page number for pagination.
 * @param {number} param0.limit - The number of items per page.
 * @param {Object} [param0.filters={}] - The filters for the request.
 * @param {string} [param0.filters.purchaseOrderId] - The ID of the purchase order.
 * @param {string} [param0.filters.referenceId] - The PO reference ID.
 * @param {string} [param0.filters.dateInit] - The initial date for filtering.
 * @param {string} [param0.filters.dateEnd] - The end date for filtering.
 * @returns {Function} The dispatch function to trigger the Redux actions.
 */
export function fetchGetPurchaseOrders(token, page, limit, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        const params = {
            referenceId: filters.referenceId ?? '',
            initDate: filters.dateInit,
            endDate: filters.dateEnd,
            page,
            limit,
        };
        dispatch(getPurchaseOrders({page, limit, filters}));

        const queryString = StringUtils.jsonToQueryString(params);
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getPurchaseOrdersSuccess(data));
        } catch (error) {
            dispatch(getPurchaseOrdersError(true));
        }
    };
}

/**
 * Fetches the line items for a given purchase order.
 *
 * @param {Object} param0 - The parameters for the request.
 * @param {string} param0.token - The authorization token.
 * @param {number} param0.page - The page number for pagination.
 * @param {number} param0.limit - The number of items per page.
 * @param {Object} [param0.filters={}] - The filters for the request.
 * @param {string} [param0.filters.dateInit] - The initial date for filtering.
 * @param {string} [param0.filters.dateEnd] - The end date for filtering.
 * @returns {Function} The dispatch function to trigger the Redux actions.
 */
export function fetchGetPoLineItems({token, page, limit, filters = {}}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        const params = {
            page,
            limit,
        };
        dispatch(getPoLineItems({page, limit, filters}));
        const queryString = StringUtils.jsonToQueryString(params);
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${filters.purchaseOrderId}/line-items${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getPoLineItemsSuccess(data));
        } catch (error) {
            dispatch(getPoLineItemsError(error));
        }
    };
}

export const displayTermsModal = () => {
    return {
        type: SHOW_TERMS_MODAL,
    };
};

export const hideTermsModal = () => {
    return {
        type: HIDE_TERMS_MODAL,
    };
};

/**
 * Selects all purchase orders.
 * @param {boolean} value - The value to set.
 * @returns {Object} The action object.
 */
export const selectAllPurchaseOrders = (value) => {
    return {
        type: SELECT_ALL_PURCHASE_ORDERS,
        value,
    };
};

/**
 * Updates selected purchase orders.
 *
 * @param {Array<string>} value - The array of selected purchase orders.
 * @returns {Object} The action object.
 */
export const updateSelectedPurchaseOrders = (value) => {
    return {
        type: SELECT_PURCHASE_ORDERS,
        value,
    };
};

/**
 * Updates selected external IDs.
 *
 * @param {Array<string>} value - The array of selected purchase orders.
 * @returns {Object} The action object.
 */
export const updateSelectedExternalIds = (value) => {
    return {
        type: SELECT_EXTERNAL_IDS,
        value,
    };
};

/**
 * Closes the accordion.
 * @param {function} value - The function to close the accordion.
 * @returns {Object} The action object.
 */
export const closeAccordion = (value) => {
    return {
        type: CLOSE_PO_ACCORDION,
        value,
    };
};
