import {
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_ERROR,
    GET_COUNTRY,
} from '@/components/general/country/redux/types/CountryTypes';

const initialState = {
    country: [],
    count: 0,
    page: 1,
    totalPages: 1,
    loading: false,
    error: false,
};

const country = (state = initialState, action) => {
    switch (action.type) {
        case GET_COUNTRY:
            return {
                ...state,
                loading: true,
            };
        case GET_COUNTRY_SUCCESS:
            return {
                ...state,
                country: action.value.data,
                loading: false,
            };
        case GET_COUNTRY_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default country;
