export const GET_LAST_MODULE_RENDER = 'GET_LAST_MODULE_RENDER';
export type MenuItemKey =
    | 'urls'
    | 'manuals'
    | 'banners'
    | 'faqs'
    | 'legalAgreements'
    | 'legalAgreementsAdmin'
    | 'binnacles'
    | 'perfil'
    | 'taxRequirements'
    | 'roles'
    | 'account';
export interface IMenuConfig {
    id: number;
    name: string;
    nameEN: string;
    value: MenuItemKey;
}
