import {EditFormValues, FormValues, IFilter} from '@/components/tickets/interfaces';
import {
    CURRET_TICKET_SELECTED,
    GET_TICKET,
    GET_TICKET_ERROR,
    GET_TICKET_SUCCESS,
    POST_TICKET,
    POST_TICKET_ERROR,
    POST_TICKET_SUCCESS,
} from '@/components/tickets/redux/types/TicketTypes';
import StringUtils from '@/utils/StringUtils';
import axios from 'axios';

export const getTickets = () => {
    return {
        type: GET_TICKET,
    };
};

export const getTicketsSuccess = (result: any) => {
    return {
        type: GET_TICKET_SUCCESS,
        value: result,
    };
};

export const getTicketsError = (error: any, result?: any) => {
    return {
        type: GET_TICKET_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetTickets(token: string, page = 1, limit = 10, filters: IFilter = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        const params = {
            page,
            limit,
            ...filters,
            status: filters.status ? `${filters.status}`.toLowerCase() : '',
        };
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getTickets());

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/v2/ticket${queryString}`, {
                headers: headers,
            })
            .then(({data}) => {
                dispatch(getTicketsSuccess(data));
            })
            .catch((error) => {
                dispatch(getTicketsError(false));
            });
    };
}
export function fetchGetTicket(token: string, id: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/v2/ticket/${id}`, {
                headers: headers,
            })
            .then(({data}) => {
                dispatch(currentTicketSelected(data.data));
            })
            .catch((error) => {
                dispatch(getTicketsError(false));
            });
    };
}

export const postTicket = () => {
    return {
        type: POST_TICKET,
    };
};
export const postTicketSuccess = () => {
    return {
        type: POST_TICKET_SUCCESS,
    };
};

export const postTicketError = (error: any, result?: any) => {
    return {
        type: POST_TICKET_ERROR,
        value: error,
        payload: result,
    };
};

export const currentTicketSelected = (ticket: any) => {
    return {
        type: CURRET_TICKET_SELECTED,
        value: ticket,
    };
};

let cancelToken: any = null;
export function fetchPostTicket(token: string, values: FormValues) {
    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
    };
    return async (dispatch: any) => {
        if (cancelToken) {
            cancelToken.cancel('Request canceled due to a new request.');
        }
        cancelToken = axios.CancelToken.source();
        dispatch(postTicket());
        const formData = new FormData();
        formData.append('country', values.country);
        formData.append('action', values.actions);
        formData.append('billNumber', values.numberInvoice);
        formData.append('description', values.description);
        formData.append('purchaseOrder', values.purchaseOrder);
        formData.append('entry', values.entrySheet);
        formData.append('doubts', values.doubts);
        formData.append('society', values.society);
        if (values.datePayment) formData.append('datePayment', values.datePayment.toISOString());

        formData.append('importAmount', values.importAmount);
        formData.append('codeAcreedor', values.codeAcreedor);
        Array.from(values.file as FileList).forEach((file: File) => {
            formData.append('file', file);
        });

        axios
            .post(`${process.env.REACT_APP_BASE_URL}/v2/ticket`, formData, {
                headers,
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                dispatch(postTicketSuccess());
                dispatch(fetchGetTickets(token));
                dispatch(postTicketError(false));
            })
            .catch((error) => {
                dispatch(postTicketError(true));
            });
    };
}
export function fetchPatchTicket(token: string, values: EditFormValues) {
    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
    };
    return async (dispatch: any) => {
        dispatch(postTicket());
        const formData = new FormData();

        formData.append('comment', values.description);

        Array.from(values.file as FileList).forEach((file: File) => {
            formData.append('file', file);
        });

        axios
            .patch(`${process.env.REACT_APP_BASE_URL}/v2/ticket/${values.id}`, formData, {
                headers,
            })
            .then((response) => {
                dispatch(postTicketSuccess());
                dispatch(fetchGetTickets(token));
                dispatch(postTicketError(false));
            })
            .catch((error) => {
                dispatch(postTicketError(true));
            });
    };
}
